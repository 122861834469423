import * as React from 'react';

export function Lines40x100({}) {
	return <div className="field">
		<svg viewBox="0 0 1024 2304">
			<svg className="markings" x="19.25%" y="16.66%" width="61.5%" height="66.67%">
				<svg className="tenYards" x="0%" y="0%" height="10%">
					<line className="yardline" x1="0" x2="100%" y1="50%" y2="50%" />
					<line className="yardline" x1="0" x2="100%" y1="100%" y2="100%" />
				</svg>
				<svg className="tenYards" x="0%" y="10%" height="10%">
					<line className="yardline" x1="0" x2="100%" y1="50%" y2="50%" />
					<line className="yardline" x1="0" x2="100%" y1="100%" y2="100%" />
				</svg>
				<svg className="tenYards" x="0%" y="20%" height="10%">
					<line className="yardline" x1="0" x2="100%" y1="50%" y2="50%" />
					<line className="yardline" x1="0" x2="100%" y1="100%" y2="100%" />
				</svg>
				<svg className="tenYards" x="0%" y="30%" height="10%">
					<line className="yardline" x1="0" x2="100%" y1="50%" y2="50%" />
					<line className="yardline" x1="0" x2="100%" y1="100%" y2="100%" />
				</svg>
				<svg className="tenYards" x="0%" y="40%" height="10%">
					<line className="yardline" x1="0" x2="100%" y1="50%" y2="50%" />
					<line className="yardline" x1="0" x2="100%" y1="100%" y2="100%" />
				</svg>
				<svg className="tenYards" x="0%" y="50%" height="10%">
					<line className="yardline" x1="0" x2="100%" y1="50%" y2="50%" />
					<line className="yardline" x1="0" x2="100%" y1="100%" y2="100%" />
				</svg>
				<svg className="tenYards" x="0%" y="60%" height="10%">
					<line className="yardline" x1="0" x2="100%" y1="50%" y2="50%" />
					<line className="yardline" x1="0" x2="100%" y1="100%" y2="100%" />
				</svg>
				<svg className="tenYards" x="0%" y="70%" height="10%">
					<line className="yardline" x1="0" x2="100%" y1="50%" y2="50%" />
					<line className="yardline" x1="0" x2="100%" y1="100%" y2="100%" />
				</svg>
				<svg className="tenYards" x="0%" y="80%" height="10%">
					<line className="yardline" x1="0" x2="100%" y1="50%" y2="50%" />
					<line className="yardline" x1="0" x2="100%" y1="100%" y2="100%" />
				</svg>
				<svg className="tenYards" x="0%" y="90%" height="10%">
					<line className="yardline" x1="0" x2="100%" y1="50%" y2="50%" />
				</svg>
			</svg>
		</svg>
	</div>;
}

import { createId } from 'playmaker-team-common/dist/shared/createId';
import { Emitter } from 'playmaker-team-common/dist/shared/emitter';
import { ISocketMessage } from 'playmaker-team-common/dist/shared/interfaces';

// for testing
const hasWindow = (typeof window !== 'undefined');
const location = hasWindow ? window.location : {};
const WebSocket = hasWindow ? (window as any).WebSocket : function() {
	this.close = function() {};
};

let _socket;
let _connectTimeout;
let _lastAlive;
let _keepAlive = false;
const _emitter = new Emitter();
const EVENTS = {
	CONNECTED: 'connected',
	DISCONNECTED: 'disconnected',
	ERROR: 'error',
	MESSAGE: 'message',
};

function _tryConnect(url: string, version: string, clientId: string,  retryInterval: number) {
	clearTimeout(_connectTimeout);

	if (!_socket) {
		try {
			const webSocket = new WebSocket(`${url}?cid=${clientId}`, version);
			const socket = {
				isConnected: false,
				lastAlive: 0,
				aliveInteval: null, // this is needed to keep ALB from closing the web socket connection
				pingMs: 0,
				webSocket,
			};

			webSocket.onopen = (e) => {
				socket.isConnected = true;
				socket.lastAlive = Date.now();
				_emitter.trigger(EVENTS.CONNECTED);
				socket.aliveInteval = setInterval(() => {
					socket.pingMs = Date.now();

					if (socket.pingMs - socket.lastAlive > 60000) { // leave a little breather room between the interval and the test time
						console.log('closing due to lastAlive timeout');
						webSocket.close();
					} else {
						webSocket.send(JSON.stringify({ type: 'ping' }));
					}
				}, 50000);
			};

			webSocket.onclose = (e) => {
				clearInterval(socket.aliveInteval);
				socket.isConnected = false;
				if (socket === _socket) {
					_socket = null;
				}

				_emitter.trigger(EVENTS.DISCONNECTED, { code: e.code, reason: e.reason});

				if (_keepAlive && retryInterval > 0) {
					_connectTimeout = setTimeout(() => {
						_tryConnect(url, version, clientId, retryInterval);
					}, retryInterval);
				}
			};

			webSocket.onmessage = (e) => {
				const message = JSON.parse(e.data);

				socket.lastAlive = Date.now();
				if (message) {
					if (message.type === 'pong') {
						message.payload = { delayMs: socket.lastAlive - socket.pingMs };
					}

					_emitter.trigger(EVENTS.MESSAGE, message);
				}
			};

			webSocket.onerror = (e) => {
				_emitter.trigger(EVENTS.ERROR, e); // e.data might be useful
			};

			_socket = socket;
		} catch (err) {
			console.warn(err);
		}
	}
}

export function connect(url: string, version: string, clientId: string, retryInterval = 5000) {
	const retry = Math.max(0, retryInterval);
	_keepAlive = (retry > 0);
	_tryConnect(url, version, clientId, retry);
}

export function disconnect() {
	_keepAlive = false;
	clearTimeout(_connectTimeout);

	if (_socket) {
		_socket.webSocket.close();
		_socket = null;
	}
}

export function send(message: ISocketMessage): boolean {
	if (isConnected()) {
		message.id = message.id || createId();

		_socket.webSocket.send(JSON.stringify(message));

		return true;
	}

	return false;
}

export function isConnected(): boolean {
	return !!(_socket && _socket.isConnected);
}

export function on(message: string, handler: Function) {
	_emitter.on(message, handler);
}

export function off(handler: Function) {
	_emitter.off(handler);
}

import { assign, map } from 'lodash';
import * as React from 'react';
import { IAlert } from '../store';

export enum PageState {
	DEFAULT, IN, OUT,
}

interface PageProps {
	alerts?: IAlert[];
	children: any;
	pageClasses?: string[];
	pageState: PageState;
	alwaysRender?: boolean;
}

class Page extends React.Component<PageProps, any> {
	private _lastPageState = PageState.DEFAULT;
	private _hideTimer;

	constructor(props) {
		super(props);
		this.state = { hiding: false };
	}

	public render() {
		const {children, alerts= [], pageClasses= [], pageState, alwaysRender, ...props} = this.props;
		const { hiding, showing } = this.state;
		const child = React.Children.only(children);
		const isElement = typeof child.type === 'string';
		const childProps = isElement ? { alerts } : { alerts, isVisible: pageState === PageState.IN };
		const coreClasses = ['view'];

		switch (pageState) {
		case PageState.IN:
			coreClasses.push('in');
			break;

		case PageState.OUT:
			coreClasses.push('out');

			break;

		default:
			// does nothing
			break;
		}

		return <div className={ coreClasses.concat(pageClasses).join(' ') }>
			{ (!alwaysRender && pageState === PageState.DEFAULT) ? null : React.cloneElement(child, assign(childProps, props)) }
		</div>;
	}
}

interface PageContainerProps {
	activePage: number;
	alwaysRender?: boolean;
	children: any;
	commonPageClasses?: string[];
	uniquePageClasses?: string[];
}

class PageContainer extends React.Component<PageContainerProps> {

	constructor(props) {
		super(props);

	}

	public getPageState(key) {
		const { activePage } = this.props;

		if (key === activePage) {
			return PageState.IN;
		} else if (key < activePage) {
			return PageState.OUT;
		}

		return PageState.DEFAULT;
	}

	public render() {
		const { alwaysRender = false, children, uniquePageClasses, commonPageClasses } = this.props;

		return React.Children.map(children, (child, i) => {
			const pageClasses = uniquePageClasses && uniquePageClasses[i]? [uniquePageClasses[i]].concat(commonPageClasses): commonPageClasses;
			return <Page key={ i } pageState={ this.getPageState(i)} alwaysRender={ alwaysRender } pageClasses={ pageClasses }>{ child }</Page>;
		});
	}
}

interface PageableProps {
	children: any;
	classNames?: string[];
	labels?: string[];
	uniquePageClasses?: string[];
}

interface PageableState {
	activePage: number;
}

class Pageable extends React.Component<PageableProps, PageableState> {
	constructor(props) {
		super(props);

		this.state = {
			activePage: 0,
		};
	}

	public gotoPage(page: number, e?) {

		if (e) {
			e.preventDefault();
		}

		this.setState((previousState) => {
			const newState = assign({}, previousState) as PageableState;

			newState.activePage = page;

			return newState;
		});
	}

	public render() {
		const { classNames = [], labels = [], uniquePageClasses = [], children } = this.props;
		const { activePage } = this.state;
		const pageCount = React.Children.count(children);
		const buttons = [];

		for (let i = 0; i < pageCount; i ++) {
			buttons.push(<label key={ i } className={ activePage === i ? 'on' : ''} onClick={ this.gotoPage.bind(this, i) }><span>{ labels[i] }</span></label>);
		}

		return <React.Fragment>
			<div className={ ['content pageable'].concat(classNames).join(' ')}>
				<PageContainer activePage={ activePage } alwaysRender={ true } uniquePageClasses={uniquePageClasses}>{ children }</PageContainer>
			</div>
			<header className="paging">
				<div className="radio tabs">
					{ buttons }
				</div>
			</header>
		</React.Fragment>;
	}
}

export { Page, Pageable, PageContainer };

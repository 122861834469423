import { assign, forEach, functions } from 'lodash';
import { IModel, StringDictionary } from 'playmaker-team-common/dist/shared/interfaces';
import { createFactory } from 'playmaker-team-common/dist/shared/modelFactory';
import { default as validator } from 'playmaker-team-common/dist/shared/validator';
import { default as valueFilters } from 'playmaker-team-common/dist/shared/valueFilters';

export interface ISize {
	width: number;
	height: number;
}

export interface IPoint {
	x: number;
	y: number;
}

export interface IVector extends IPoint {
	makeConcrete: (size: ISize, floored?: boolean) => void;
	makeAbstract: (size: ISize, floored?: boolean) => void;
	magnitude: () => number;
	angle: () => number;
	normalize: () => void;
	subtract: (vector: IVector | IPoint) => void;
	add: (vector: IVector | IPoint) => void;
	distance: (vector: IVector | IPoint) => number;
	scale: (factor: number) => void;
	rotate: (radians: number) => void;
	rotateByDegrees: (degrees: number) => void;
	interpolate: (vector: IVector | IPoint, percent: number) => void;
	limit: (minX: number, maxX: number, minY: number, maxY: number) => void;
	equals: (vector: IVector | IPoint) => boolean;
	hasData: () => boolean;
}

const vectorModel = {
	makeConcrete(size: ISize, floored = false) {
		const point = vectorUtil.toConcretePoint(this, size, floored);

		this.x = point.x;
		this.y = point.y;
	},

	makeAbstract(size: ISize, floored = false) {
		const point = vectorUtil.toAbstractPoint(this, size, floored);

		this.x = point.x;
		this.y = point.y;
	},

	magnitude() {
		return vectorUtil.magnitude(this);
	},

	angle() {
		return vectorUtil.angle(this);
	},

	normalize() {
		const magnitude = vectorUtil.magnitude(this);

		this.x = magnitude === 0 ? 0 : this.x / magnitude;
		this.y = magnitude === 0 ? 0 : this.y / magnitude;
	},

	subtract(vector: IVector | IPoint) {
		const result = vectorUtil.subtract(this, vector);

		this.x = result.x;
		this.y = result.y;
	},

	add(vector: IVector | IPoint) {
		const result = vectorUtil.add(this, vector);

		this.x = result.x;
		this.y = result.y;
	},

	distance(vector: IVector | IPoint) {
		return vectorUtil.distance(this, vector);
	},

	scale(factor: number) {
		const result = vectorUtil.scale(this, factor);

		this.x = result.x;
		this.y = result.y;
	},

	rotate(radians: number) {
		const result = vectorUtil.rotate(this, radians);

		this.x = result.x;
		this.y = result.y;
	},

	rotateByDegrees(degrees: number) {
		const result = vectorUtil.rotateByDegrees(this, degrees);

		this.x = result.x;
		this.y = result.y;
	},

	interpolate(vector: IVector | IPoint, percent: number) {
		const result = vectorUtil.interpolate(this, vector, percent);

		this.x = result.x;
		this.y = result.y;
	},

	limit(minX: number, maxX: number, minY: number, maxY: number) {
		this.x = vectorUtil.limit(this.x, minX, maxX);
		this.y = vectorUtil.limit(this.y, minY, maxY);
	},

	equals(vector: IVector | IPoint) {
		return vectorUtil.areEqual(this, vector);
	},

	hasData() {
		return typeof this.x === 'number' && typeof this.y === 'number';
	},
};

export const vectorUtil = {
	limit(value, min, max) {
		return Math.max(min, Math.min(max, value));
	},

	// createRelativeToSize: function (point, size) {
	// 	return self.createRelative(point.x, point.y, size);
	// },

	// createRelativeToElement: function (point, $element) {
	// 	var size = {
	// 		width: $element.width(),
	// 		height: $element.height(),
	// 	};

	// 	var offset = $element.offset();
	// 	var x = point.x - offset.left;
	// 	var y = point.y - offset.top;
	// 	return self.createRelative(x, y, size);
	// },

	// toAbsoluteInElement: function (point, $element, floord) {
	// 	var size = {
	// 		width: $element.width(),
	// 		height: $element.height(),
	// 	};
	// 	return self.toAbsolute(point, size);
	// },

	toVector(point: IPoint): IVector {
		const result = (point as any);

		if (!(point as any).magnitude) {
			forEach(functions(vectorModel), (x) => {
				result[x] = vectorModel[x].bind(result);
			});
		}

		return result as IVector;
	},

	toConcretePoint(vector: IVector | IPoint, size: ISize, floored = false): IPoint {
		const x = (floored) ? Math.floor(vector.x * size.width) : vector.x * size.width;
		const y = (floored) ? Math.floor(vector.y * size.height) : vector.y * size.height;

		return {x, y};
	},

	toAbstractPoint(vector: IVector | IPoint, size: ISize, floored = false): IPoint {
		const x = vectorUtil.limit(vector.x / size.width, -1, 1);
		const y = vectorUtil.limit(vector.y / size.height, -1, 1);

		return {x, y};
	},

	magnitude(vector: IVector | IPoint) {
		return Math.sqrt((vector.x * vector.x) + (vector.y * vector.y));
	},

	angle(vector: IVector | IPoint) {
		return Math.atan2(vector.y, vector.x);
	},

	normalize(vector: IVector | IPoint) {
		const magnitude = vectorUtil.magnitude(vector);

		return magnitude === 0 ? { x: 0, y: 0 } : { x: vector.x / magnitude, y: vector.y / magnitude };
	},

	subtract(vector1: IVector | IPoint, vector2: IVector | IPoint): IPoint {
		return { x: vector1.x - vector2.x, y: vector1.y - vector2.y };
	},

	add(vector1: IVector | IPoint, vector2: IVector | IPoint): IPoint {
		return { x: vector1.x + vector2.x, y: vector1.y + vector2.y };
	},

	distance(vector1: IVector | IPoint, vector2: IVector | IPoint) {
		const vector = vectorUtil.subtract(vector1, vector2);
		return vectorUtil.magnitude(vector);
	},

	scale(vector: IVector | IPoint, factor: number): IPoint {
		return { x: vector.x * factor, y: vector.y * factor };
	},

	rotateByDegrees(vector: IVector | IPoint, degrees: number): IPoint {
		return vectorUtil.rotate(vector, 2 * Math.PI * (degrees / 360));
	},

	rotate(vector: IVector | IPoint, radians: number): IPoint {
		const cos = Math.cos(radians);
		const sin = Math.sin(radians);
		const x = (vector.x * cos) - (vector.y * sin);
		const y = (vector.y * cos) + (vector.x * sin);

		return { x, y };
	},

	interpolate(vector1: IVector | IPoint, vector2: IVector | IPoint, percent): IPoint {
		const x = (vector2.x - vector1.x) * percent + vector1.x;
		const y = (vector2.y - vector1.y) * percent + vector1.y;

		return { x, y };
	},

	areEqual(vector1: IVector | IPoint, vector2: IVector | IPoint) {
		return vector1.x === vector2.x && vector1.y === vector2.y;
	},
};

const vectorFactory = createFactory<IVector>('Vector', assign({}, vectorModel, {
	x: {
		setFilters: valueFilters.toNumber,
	},
	y: {
		setFilters: valueFilters.toNumber,
	},
}));

export default vectorFactory;

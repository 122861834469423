import { map, sortBy } from 'lodash';
import * as React from 'react';
import * as actions from '../actions';
import {getTeamPermissions } from '../authorizationHelper';
import { current as getCurrentContext } from '../componentContext';
import { ITeam } from '../models/team';
import { IUser } from '../models/user';
import * as store from '../store';
import { _s, StringKey } from '../strings';
import * as viewManager from '../viewManager';
import { AccountCreate } from './accountCreate';
import { BillingModal } from './billing';
import { CachedImage } from './cachedImage';
import { DiagnosticsModal } from './diagnostics';
import { InviteModal } from './inviteModal';
import { Login } from './login';
import { ProfileEditModal } from './profileEdit';
import { TeamEditModal } from './teamEdit';
import { SupportModal } from './support/supportModal';

interface Props {
	appState: store.IAppState;
}

const LoggedInMenu = ({ appState, editProfile, editTeam, manageBilling, openDiagnostics, openJoin, login }) => {
	const { model, viewState } = appState;
	const { currentUser, variant } = getCurrentContext();
	const teams = sortBy(model.teams, ['name']);
	const isAdmin = currentUser && currentUser.role === 'admin';
	const teamLinks = map(teams, (team: ITeam) => {
		const teamPermissions = getTeamPermissions({ team });
		const teamColor = `#${team.settings.color}`;
		const clickHandler = (e) => {
			actions.toggleMainMenu();
			actions.setCurrentTeam(team);
		};

		return <div className="teamButtons" key={ team.id }>
			<a className="button" onClick={ clickHandler }>
				<span className="icon photo team" style={{ backgroundColor: teamColor }}>
					<CachedImage key={ team.settings.logoUrl || 'empty' } src={ team.settings.logoUrl } />
					<span>{ team.abbreviation }</span>
				</span>
				<span>{ team.name }</span>
				<span className="icon navigate"></span>
			</a>
			<div className="teamActions">
				{ teamPermissions && teamPermissions.canUpdate ? <a className="button" style={{ borderLeftColor: teamColor }} onClick={ editTeam.bind(this, team) }><span className="icon infoSmall"></span><span>{ _s(StringKey.TEAM_INFO) }</span></a> : null }
				{ teamPermissions && teamPermissions.canManageBilling ? <a className="button" style={{ borderLeftColor: teamColor }} onClick={ manageBilling.bind(this, team) }><span className="icon billingSmall"></span><span>{ _s(StringKey.MANAGE_BILLING) }</span></a> : null }
			</div>
		</div>;
	});

	const openAccountSummary = (e) => {
		actions.pushModal({
			component: SupportModal,
			props: () => ({ classNames: ['supportTool'] }),
		});
	};

	return <React.Fragment>
		<header>
			<a className="button" onClick={ editProfile.bind(this, currentUser) }>
				<span className="icon photo profile">
					<span>{ currentUser && currentUser.initials }</span>
					<CachedImage key={ (currentUser && currentUser.profile.avatarUrl) || 'empty' }  src={ currentUser && currentUser.profile.avatarUrl } />
				</span>
				<span>{ currentUser && currentUser.fullName }</span>
				<span className="icon infoSmall"></span>
			</a>
		</header>
		<div className="content">
			<div className="list scrollable">
				{ teamLinks }
				<a className="button" onClick={ (e) => actions.browseTo(`https://${window.location.hostname}/feedback/${variant}.html`) }><span className="icon feedbackSmall"></span><span>{ _s(StringKey.FEEDBACK) }</span></a>
				<a className="button" onClick={ (e) => actions.browseTo(variant === 'flag' ? 'https://support.wearetrue.com/hc/en-us/categories/360002082751-Flag-Football-Playmaker-X' : 'https://support.wearetrue.com/hc/en-us/categories/360002071872-Tackle-Football-Playmaker-X') }><span className="icon supportSmall"></span><span>{ _s(StringKey.SUPPORT) }</span></a>
				<a className="button" onClick={ openDiagnostics }><span className="icon diagnosticsSmall"></span><span>{ _s(StringKey.DIAGNOSTICS) }</span></a>
				<a className="button" onClick={ openJoin }><span className="icon joinTeamSmall"></span><span>{ _s(StringKey.ACCEPT_INVITE) }</span></a>
				{ isAdmin ? <a className="button" onClick={ openAccountSummary }><span className="icon supportTool"></span><span>Support Tool</span></a> : null }
				{ isAdmin ? <a className="button" onClick={ login }><span className="icon impersonate"></span><span>Impersonate</span></a> : null }
				{ viewState.isImpersonating ? <a className="button" onClick={ () => actions.unimpersonate() }><span className="icon unimpersonate"></span><span>Unimpersonate</span></a> : null }
				<a className="button" onClick={ actions.logout }><span className="icon logOutSmall"></span><span>{ _s(StringKey.LOG_OUT) }</span></a>
			</div>
		</div>
		<a className="button" onClick={ actions.toggleMainMenu }><span className="icon close"></span></a>
	</React.Fragment>;
};

const LoggedOutMenu = ({ createTeam = null, openJoin = null, login = null, openDiagnostics = null }) => {
	const { variant } = getCurrentContext();

	return <React.Fragment>
		<div className="content">
			<div className="list scrollable">
				<a className="button" onClick={ login }><span className="icon logInSmall"></span><span>{ _s(StringKey.LOG_IN) }</span></a>
				<a className="button" onClick={ createTeam }><span className="icon freeTrialSmall"></span><span>{ _s(StringKey.START_A_FREE_TRIAL) }</span></a>
				<a className="button" onClick={ openJoin }><span className="icon joinTeamSmall"></span><span>{ _s(StringKey.ACCEPT_INVITE) }</span></a>
				<a className="button" onClick={ (e) => actions.browseTo(variant === 'flag' ? 'https://support.wearetrue.com/hc/en-us/categories/360002082751-Flag-Football-Playmaker-X' : 'https://support.wearetrue.com/hc/en-us/categories/360002071872-Tackle-Football-Playmaker-X') }><span className="icon supportSmall"></span><span>{ _s(StringKey.SUPPORT) }</span></a>
				<a className="button" onClick={ openDiagnostics }><span className="icon diagnosticsSmall"></span><span>{ _s(StringKey.DIAGNOSTICS) }</span></a>
			</div>
		</div>
		<a className="button" onClick={ actions.toggleMainMenu }><span className="icon close"></span></a>
	</React.Fragment>;
};

export class MainMenu extends React.Component<Props> {
	constructor(props: Props) {
		super(props);
	}

	public editTeam(team: ITeam, e) {
		viewManager.pushModal({
			component: TeamEditModal,
			props: () => ({ appState: store.appState(), team }),
			screenName: 'TeamEdit',
		});
	}

	public editProfile(user: IUser, e) {
		actions.pushModal({
			component: ProfileEditModal,
			props: () => ({ appState: store.appState(), user }),
		});
	}

	public manageBilling(team: ITeam, e) {
		viewManager.pushModal({
			component: BillingModal,
			props: () => ({ appState: store.appState(), teamId: team.id }),
			screenName: 'Billing',
		});
	}

	public openDiagnostics(team: ITeam, e) {
		viewManager.pushModal({
			component: DiagnosticsModal,
			props: () => ({ appState: store.appState() }),
			screenName: 'Diagnostics',
		});
	}

	public createTeam(e?) {
		actions.pushModal({
			component: AccountCreate,
			props: () => ({ appState: store.appState() }),
		});
	}

	public openJoin(e?) {
		actions.pushModal({
			component: InviteModal,
			props: () => ({ viewState: store.appState().viewState }),
		});
	}

	public openLogin(e?) {
		actions.pushModal({
			component: Login,
			props: () => ({ viewState: store.appState().viewState, classNames: ['login'] }),
		});
	}

	public render() {
		const { appState } = this.props;
		const { viewState } = appState;

		return <div className={ viewState.mainMenu.expanded ? 'view menu in' : 'view menu out' }>
			{ viewState.currentUserId ? <LoggedInMenu appState={ appState } editTeam={ this.editTeam } editProfile={ this.editProfile } manageBilling={ this.manageBilling } openDiagnostics={ this.openDiagnostics } openJoin={ this.openJoin } login={ this.openLogin } /> : <LoggedOutMenu createTeam={ this.createTeam } openJoin={ this.openJoin } login={ this.openLogin } openDiagnostics={ this.openDiagnostics }  />}
		</div>;
	}
}

import { assign } from 'lodash';
import { createFactory } from 'playmaker-team-common/dist/shared/modelFactory';
import { default as diagramModel, GamePhase, IDiagram } from './diagramModel';
import { IPlayer, playerModel } from './player';
import valueFilters from 'playmaker-team-common/dist/shared/valueFilters';
import validator from 'playmaker-team-common/dist/shared/validator';

export type IRouteTree = IDiagram;

export const routeTreePlayerFactory = createFactory<IPlayer>('RouteTreePlayer', assign({}, playerModel, {
	label: {
		setFilters: [valueFilters.toString],
		rules: validator.match(/[\w\d\s]{1,}/, false)
	},
}));

export default createFactory<IRouteTree>('RouteTree', assign({}, diagramModel, {
	ballLocation: {
		defaultValue: { x: 0.5, y: 0.5 }
	},
	phase: {
		defaultValue: GamePhase.Offense
	},
	posture: {
		defaultValue: GamePhase.Offense
	},
	mates: {
		setFilters: valueFilters.toModelSet(routeTreePlayerFactory),
		rules: validator.validModel(routeTreePlayerFactory.rules()),
		defaultValue: {},
	},
}));

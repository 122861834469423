import { map } from 'lodash';
import * as React from 'react';
import { current as getCurrentContext } from '../componentContext';
import { GamePhase } from '../models/diagramModel';
import { IPlay } from '../models/play';
import { IPlaybook } from '../models/playbook';
import { SystemFeature } from '../models/subscriptionPlan';
import { _s, StringKey } from '../strings';
import { CachedImage } from './cachedImage';
import { PrintControl, PrintRouteTreeDiagram } from './printControl';
import { IRouteTree } from '../models/routeTree';

interface Props {
	fieldOptions: any;
	optionValues: {[key: string]: any};
	playbook: IPlaybook;
	routeTree: IRouteTree;
}

const SponsorContent = () => {
	const { currentSubscription, sponsorLogoPrintUrl } = getCurrentContext();

	if (!sponsorLogoPrintUrl || !currentSubscription.hasFeature(SystemFeature.print)) {
		return null;
	}

	return <div className="sponsor">
		<div className="tribute">{ _s(StringKey.PRINTING_POWERED_BY) }</div>
		<span className="icon photo"><CachedImage src={ sponsorLogoPrintUrl } /></span>
	</div>;
};

export class PrintRouteTree extends React.Component<Props> {
	constructor(props) {
		super(props);
	}

	public render() {
		const { optionValues, playbook, routeTree } = this.props;

		return <PrintControl optionValues={ optionValues } playbook={ playbook }>{ (params) => {
			const { className } = params;
			const classNames = [className, 'playbook routeTreePrint playName playNumber'];
			const groupSize = optionValues.layout.groupSize;
			const { currentTeam } = getCurrentContext();
			const logoImg = currentTeam.settings.logoUrl ? <CachedImage className="logo"  key={ currentTeam.settings.logoUrl || 'empty' } src={ currentTeam.settings.logoUrl } /> : null;
			const teamColor = currentTeam && `#${currentTeam.settings.color}`;
			const printPages = [];
			const diagrams = [];

			diagrams.push(<PrintRouteTreeDiagram key={ routeTree.id } routeTree={ routeTree } />);

			for(const mate of routeTree.mates.values.sort((a,b) => a.sortIndex - b.sortIndex)) {
				diagrams.push(<PrintRouteTreeDiagram key={ mate.id } mateId={ mate.id } routeTree={ routeTree } />);
			}

			// const diagrams = map(pagePlays, (play: IPlay) =>  <PrintRouteTreeDiagram key={ play.id } play={ play } playCount={ playCount++ } fieldOptions={ fieldOptions} getPersonnelInfo={ getPersonnelInfo && getPersonnelInfo.bind(this, play) } />);

			const diagramPageCount = Math.ceil((routeTree.mates.count + 1) / groupSize);

			for (let i = 0; i < diagramPageCount; i ++) {
				const pageContent = diagrams.slice(i * groupSize, (i + 1) * groupSize);

				printPages.push(<div key={ `page_${i}`} className="printPage"><section>{ pageContent }</section><SponsorContent /></div>);
			}

			// console.log(`groupSize ${groupSize}`);
			classNames.push(map(optionValues, (v) => v.className).join(' '));

			return <div className={ classNames.join(' ') }>

				<div className="printPage cover">
					<div className="title">
						<div className="logo">
							{ logoImg }
							<div className="border"></div>
						</div>
						<div className="teamName" style={{ backgroundColor: teamColor }}>{ currentTeam.name }</div>
						<div className="phase">{ _s(StringKey.ROUTE_TREE) }</div>
						<div className="titleField">
							<div className="titleHash">
								<div></div>
								<div></div>
							</div>
							<div className="titleHash">
								<div></div>
								<div></div>
							</div>
							<div className="titleHash">
								<div></div>
								<div></div>
							</div>
							<div className="titleHash">
								<div></div>
								<div></div>
							</div>
							<div className="titleLine">
								<div></div>
							</div>
							<div className="titleHash">
								<div></div>
								<div></div>
							</div>
							<div className="titleHash">
								<div></div>
								<div></div>
							</div>
							<div className="titleHash">
								<div></div>
								<div></div>
							</div>
							<div className="titleHash">
								<div></div>
								<div></div>
							</div>
							<div className="titleLine mid">
								<div></div>
							</div>
							<div className="titleHash">
								<div></div>
								<div></div>
							</div>
							<div className="titleHash">
								<div></div>
								<div></div>
							</div>
							<div className="titleHash">
								<div></div>
								<div></div>
							</div>
							<div className="titleHash">
								<div></div>
								<div></div>
							</div>
							<div className="titleLine">
								<div></div>
							</div>
							<div className="titleHash">
								<div></div>
								<div></div>
							</div>
							<div className="titleHash">
								<div></div>
								<div></div>
							</div>
							<div className="titleHash">
								<div></div>
								<div></div>
							</div>
							<div className="titleHash">
								<div></div>
								<div></div>
							</div>
						</div>
					</div>
					<footer>
						<div className="playmaker"></div>
						<div className="timestamp">{ new Date().toLocaleString() }</div>
					</footer>
				</div>

				{ printPages }

			</div>;
		}}</PrintControl>;

	}
}

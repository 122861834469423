import { assign, forEach, map, startCase } from 'lodash';
// import { default as addressFactory } from '../models/address';
import { default as valueFilters } from 'playmaker-team-common/dist/shared/valueFilters';
import * as React from 'react';
import * as actions from '../actions';
import { default as teamFactory, ITeam } from '../models/team';
import * as store from '../store';
import { _s, StringKey } from '../strings';
import * as viewManager from '../viewManager';
import { AlertList } from './alert';
import { Avatar } from './avatar';
import { Spinner } from './spinner';

interface Props {
	alertList: any;
	appState: store.IAppState;
	cancelAction?: () => void;
	confirmAction: () => void;
	confirmActionLabel?: string;
	confirmActionClassName: string;
	doValidation?: boolean;
	addMode: boolean;
	team: ITeam;
	onTeamChange: (path: string, val: any) => void;
}

interface State {
}

export class TeamEdit extends React.Component<Props, State> {
	private onNameChange;
	private onAbbreviationChange;
	private onSettingsColorChange;

	constructor(props) {
		super(props);

		forEach(['name', 'abbreviation', 'settings/color'], (path) => {
			const pathParts = map(path.split('/'), (part) => {
				return startCase(part).replace(/\s/g, '');
			});
			const handlerName = `on${pathParts.join('')}Change`;
			// console.log(handlerName);

			this[handlerName] = this.onChange.bind(this, path);
		});

		this.onLogo = this.onLogo.bind(this);
		this.onLogoRemoved = this.onLogoRemoved.bind(this);
		this.onCustomColorChange = this.onCustomColorChange.bind(this);
	}

	public onLogo(dataUrl: string) {
		this.props.onTeamChange('/settings/logoUrl', dataUrl);
	}

	public onLogoRemoved() {
		this.props.onTeamChange('/settings/logoUrl', undefined);
	}

	public onCustomColorChange(e) {
		let newVal = (e.type === 'blur') ? valueFilters.clean(e.target.value) : e.target.value;

		if (e.type === 'blur' && !/[\da-f]{6}/i.test(newVal)) {
			newVal = '';
		}

		this.props.onTeamChange('/settings/color', newVal);
	}

	public onChange(path, e) {
		let newVal;

		if (e.target.name === 'teamColor') {
			if (e.target.checked) {
				newVal = e.target.value;
			} else {
				return;
			}
		} else {
			newVal = (e.type === 'blur') ? valueFilters.clean(e.target.value) : e.target.value;
		}

		this.props.onTeamChange(path, newVal);
	}

	public formatCustomColor(color: string) {
		if (color.indexOf('#') === 0) {
			// does nothing?
		}
	}

	public render() {
		const { alertList, appState, cancelAction, confirmAction, confirmActionClassName, confirmActionLabel, team } = this.props;
		// const address = team.address;
		const teamColors = appState.viewState.settings.teamColors;
		const colorControls = map(teamColors, (c) => {
			const hex = c.hex || c; // TODO: remove this after sufficient time for clients to upgrade to new schema
			const checked = team.settings.color === hex;

			return <div key={ hex } className="color">
				<label className={ checked ? 'on' : ''}><span style={{ backgroundColor: `#${hex}` }}></span><input type="radio" name="teamColor" value={ hex } checked={ checked } onChange={ this.onSettingsColorChange } /></label>
			</div>;
		});

		return <React.Fragment>
			<header>
				{ cancelAction && <div className="actions"><a className="button" onClick={ cancelAction }><span className="icon cancel"></span></a></div> }
				<div className="title">{ _s(StringKey.TEAM_INFO) }</div>
				<div className="actions">
					<a className={ confirmAction ? 'button' : 'button disabled' } onClick={ confirmAction }>{ confirmActionLabel ? <span>{ confirmActionLabel }</span> : null }<span className={ confirmActionClassName }></span></a>
				</div>
			</header>
			{ alertList }
			<div className="content scrollable">
				<div className="inner compact">
					<input className={ (!!team.name && !team.isValid('name')) ? 'invalid' : '' } type="text" placeholder={ _s(StringKey.TEAM_NAME) } value={ team.viewValue('name') } onChange={ this.onNameChange } onBlur={ this.onNameChange } required />
					<input className={ (!! team.abbreviation && !team.isValid('abbreviation')) ? 'invalid' : '' } type="text" name="" placeholder={ _s(StringKey.ABBREVIATION_4_LETTERS_MAX) } value={ team.viewValue('abbreviation') } onChange={ this.onAbbreviationChange } onBlur={ this.onAbbreviationChange } required />
					<h2>{ _s(StringKey.TEAM_COLOR) }</h2>
					<div className="radio colors team">
						{ colorControls }
						{ /**
								<div className="custom">
									<CustomInput className={ isCustomColor && !team.settings.isValid('color')? 'invalid': '' } placeholder="Custom Hex Color in #000000 format" value={ isCustomColor ? team.settings.color: '' } onChange={ this.onCustomColorChange } onBlur={ this.onCustomColorChange } /> }
									<div className="color teamColorCustom">
										<label><span style={{ backgroundColor: isCustomColor && team.settings.isValid('color')? `#${team.settings.color}`: null }}></span></label>
									</div>
								</div>
								**/}
					</div>

					<h2>{ _s(StringKey.OPTIONAL_LOGO) }</h2>
					<Avatar className="logo" canEdit={ true } imageWidth={ 512 } imageHeight={ 512 } imageUrl={ team.settings.logoUrl || ''} onImage={ this.onLogo } onRemoveImage={ this.onLogoRemoved } />
				</div>
			</div>
		</React.Fragment>;
	}
}

interface TeamEditModalProps {
	alerts: store.IAlert[];
	appState: store.IAppState;
	team: ITeam;
}

interface TeamEditModalState {
	mutatedTeam: ITeam;
	saving?: boolean;
}

export class TeamEditModal extends React.Component<TeamEditModalProps, TeamEditModalState> {
	constructor(props: TeamEditModalProps) {
		super(props);

		this.state = {
			mutatedTeam: teamFactory(props.team),
		};

		this.onTeamChange = this.onTeamChange.bind(this);
		this.save = this.save.bind(this);
	}

	public onTeamChange(path, value) {
		this.setState((prevState) => {
			const newState = assign({}, prevState) as TeamEditModalState;

			if (path === '/settings/logoUrl' && newState.mutatedTeam.settings.logoUrl) {
				actions.clearCachedImage(newState.mutatedTeam.settings.logoUrl);
			}

			// return a new instance so the child component is sure to update
			newState.mutatedTeam = teamFactory(newState.mutatedTeam);
			newState.mutatedTeam.setAt(path, value);

			return newState;
		});
	}

	public async save() {
		const { mutatedTeam, saving } = this.state;

		if (!saving) {
			this.setState({ saving: true }, async () => {
				actions.clearAlerts();

				if (actions.validateTeam(mutatedTeam)) {
					await actions.saveTeam(mutatedTeam, `team-update-${mutatedTeam.id}`);
					this.setState({ saving: false });
					viewManager.popModal();
				} else {
					this.setState({ saving: false });
				}
			});

		}
	}

	public render() {
		const { appState, alerts } = this.props;
		const { mutatedTeam, saving } = this.state;
		const alertList = <AlertList alerts={ alerts } />;

		return <div className="view">
			{ saving ? <Spinner /> : null }
			<TeamEdit alertList={ alertList } appState={ appState } addMode={ false } cancelAction={ viewManager.popModal } confirmAction={ this.save } confirmActionClassName="icon ok" team={ mutatedTeam } onTeamChange={ this.onTeamChange } />
		</div>;
	}

}

import { assign, findIndex, forEach } from 'lodash';
import * as React from 'react';
import * as actions from '../actions';
import { IPoint, vectorUtil } from '../models/vector';
import * as store from '../store';
import { ITouch, supportsPointer, supportsTouch, touchFromEvent} from '../touchHelper';

interface IDropTarget {
	canDrop: (data: store.IDragData) => boolean;
	element: Element;
	onDrop?: (data: store.IDragData) => void;
}

let startSpec: store.IDragSpec;
let trackedTouch: ITouch;
const dropTargets: IDropTarget[] = [];

export interface IDragSourceParams {
	handleTouchStart?: (spec: store.IDragSpec, e: React.TouchEvent) => void;
	handlePointerDown?: (spec: store.IDragSpec, e: React.PointerEvent) => void;
	handleMouseDown?: (spec: store.IDragSpec, e: React.MouseEvent) => void;
	isDragging?: boolean;
}

interface DragSourceProps {
	children: (params: IDragSourceParams) => React.ReactElement<any>;
}

interface DragSourceState {
	isDragging?: boolean;
}

function getDragLayout(touch: ITouch, currentTarget: Element): IPoint {
	const boundingRect = currentTarget.getBoundingClientRect();

	return { x: touch.clientX - boundingRect.left, y: touch.clientY - boundingRect.top };
}

export const DragContext = React.createContext<store.IDragSpec>({ component: null, data: null });

export class DragSource extends React.Component<DragSourceProps, DragSourceState> {
	public static contextType = DragContext;
	public context!: React.ContextType<typeof DragContext>;

	constructor(props) {
		super(props);

		this.handleStart = this.handleStart.bind(this);

		this.state = { isDragging: false };
	}

	public handleStart(spec: store.IDragSpec, e: React.PointerEvent | React.TouchEvent | React.MouseEvent) {
		const touch: ITouch = touchFromEvent(e);

		if (e.type === 'touchstart') {
			e.preventDefault();
		}

		e.stopPropagation();

		if (!trackedTouch) {
			// const dragOffset = spec.getDragLayout? spec.getDragLayout(touch, e.currentTarget): getDragLayout(touch, e.currentTarget);
			const dragEnd = spec.dragEnd && spec.dragEnd.bind(spec);

			trackedTouch = touch;

			spec.data.layout = spec.getDragLayout ? spec.getDragLayout(touch, e.currentTarget) : getDragLayout(touch, e.currentTarget);

			const scrollContainer = spec.data.layout.scrollContainer;
			if (scrollContainer) {
				scrollContainer.classList.add('dragSorting');
			}

			// document.body.style.pointerEvents = 'none';

			spec.dragEnd = (didDrop: boolean, data: store.IDragData) => {
				// store.off(this._handleDragSpecChange);
				if (scrollContainer) {
					scrollContainer.classList.remove('dragSorting');
				}
				if (dragEnd) {
					dragEnd(didDrop, data);
				}
				this.setState({ isDragging: false });
			};

			startSpec = spec;

			if (e.type === 'mousedown' || (e as React.PointerEvent).pointerType === 'mouse') {
				document.addEventListener('mousemove', handleTouchMove);
				document.addEventListener('mouseup', handleTouchEnd);
			} else {
				document.addEventListener('touchmove', handleTouchMove, { passive: false }); // see https://stackoverflow.com/a/9251721
				document.addEventListener('touchend', handleTouchEnd, { passive: false });
			}

			if (spec.dragStart) {
				spec.dragStart();
			}

			this.setState({ isDragging: true });
		}
	}

	public render() {
		const { children } = this.props;
		const { isDragging } = this.state;

		return children({
			handleTouchStart: !supportsPointer && supportsTouch ? this.handleStart : undefined,
			handlePointerDown: supportsPointer ? this.handleStart : undefined,
			handleMouseDown: !supportsPointer && !supportsTouch ? this.handleStart : undefined,
			isDragging,
		});
	}
}

export interface IDropTargetParams {
	addDropTarget: (element: Element, canDrop: (data: store.IDragData) => boolean, onDrop?: (data: store.IDragData) => void) => void;
	removeDropTarget: (element) => void;
	activeDragSpec?: store.IDragSpec;
}

interface DropTargetProps {
	children: (params: IDropTargetParams) => React.ReactElement<any>;
}

interface DropTargetState {
}

export class DropTarget extends React.Component<DropTargetProps, DropTargetState> {
	public static contextType = DragContext;
	public context!: React.ContextType<typeof DragContext>;

	private _dropTarget;

	constructor(props) {
		super(props);

		this.addDropTarget = this.addDropTarget.bind(this);
	}

	public addDropTarget(element: Element, canDrop: (data: store.IDragData) => boolean, onDrop: (data: store.IDragData) => void) {
		this._dropTarget = addDropTarget(element, canDrop, onDrop);
	}

	public render() {
		const { children } = this.props;
		const dragSpec = this.context;

		return children({
			addDropTarget: this.addDropTarget,
			removeDropTarget,
			activeDragSpec: this._dropTarget && isOver(this._dropTarget, dragSpec) ? dragSpec : undefined,
		});
	}
}

interface DragDropProps {
	children: (params: IDragSourceParams & IDropTargetParams) => any;
}

export class DragDrop extends React.Component<DragDropProps, any> {
	public render() {
		const { children } = this.props;

		return <DragSource>{(dragParams: IDragSourceParams) => {
			return <DropTarget>{(dropParams: IDropTargetParams) => {
				return children(assign({}, dragParams, dropParams));
			}}</DropTarget>;
		}}</DragSource>;
	}
}

function handleTouchMove(e: MouseEvent | TouchEvent) {
	const touch: ITouch = trackedTouch = touchFromEvent(e, trackedTouch && trackedTouch.sourceType, trackedTouch && trackedTouch.identifier);

	// console.log('dnd touch move', e.target);

	if (touch) {
		if (e.type === 'touchmove') {
			e.preventDefault();
		}

		trackedTouch = touch;

		const state = store.appState();
		const spec: store.IDragSpec = assign({}, state.viewState.dragSpec || startSpec);
		const layout = spec.data.layout || {};

		if (layout.constrain) {
			if (layout.constrain === 'y') {
				spec.data.clientX = touch.clientX;
				spec.data.clientY = spec.data.clientY || touch.clientY;
			}
			if (layout.constrain === 'x') {
				spec.data.clientY = touch.clientY;
				spec.data.clientX = spec.data.clientX || touch.clientX;
			}
		} else {
			spec.data.clientX = touch.clientX;
			spec.data.clientY = touch.clientY;
		}
		// console.log('touchMove', spec);

		if (layout.scrollContainer) {
			const containerBounds = layout.scrollContainer.getBoundingClientRect();

			if (spec.data.clientX > containerBounds.right) {
				spec.data.clientX = containerBounds.right;
			} else if (spec.data.clientX < containerBounds.left) {
				spec.data.clientX = containerBounds.left;
			}

			if (spec.data.clientY <= (containerBounds.top + (layout.height / 2))) {
				// spec.data.clientY = containerBounds.top;

				clearInterval(spec.data.layout.scrollInterval);
				spec.data.layout.scrollInterval = setInterval(() => {
					layout.scrollContainer.scrollTop -= 20;
				}, 60);
			} else if (spec.data.clientY >= (containerBounds.bottom - (layout.height / 2))) {
				// spec.data.clientY = containerBounds.bottom;

				clearInterval(spec.data.layout.scrollInterval);
				spec.data.layout.scrollInterval = setInterval(() => {
					layout.scrollContainer.scrollTop += 20;
				}, 60);
			} else {
				clearInterval(spec.data.layout.scrollInterval);
			}
		}

		actions.setDragSpec(spec);
	}
}

function handleTouchEnd(e: MouseEvent | TouchEvent) {
	const touch: ITouch = trackedTouch = touchFromEvent(e, trackedTouch && trackedTouch.sourceType, trackedTouch && trackedTouch.identifier);
	const dragSpec = store.appState().viewState.dragSpec;
	let dropTarget;

	if (e.type === 'touchmove') {
		e.preventDefault();
	}

	// console.log('dnd touch end', e.target);

	for (const t of dropTargets) {
		if (isOver(t, dragSpec)) {
			dropTarget = t;
			break;
		}
	}

	trackedTouch = null;
	startSpec = null;
	actions.setDragSpec(null);

	document.removeEventListener('mousemove', handleTouchMove);
	document.removeEventListener('mouseup', handleTouchEnd);
	document.removeEventListener('touchmove', handleTouchMove);
	document.removeEventListener('touchend', handleTouchEnd);

	if (dragSpec) {
		if (dragSpec.data.layout && dragSpec.data.layout.scrollInterval) {
			clearInterval(dragSpec.data.layout.scrollInterval);
		}
		endDrag(dragSpec, dropTarget);
	}
}

function addDropTarget(element: Element, canDrop: (data: store.IDragData) => boolean, onDrop: (data: store.IDragData) => void) {
	const dropTarget = { element, canDrop, onDrop };

	removeDropTarget(element); // be sure we don't have duplicates

	dropTargets.unshift(dropTarget);

	return dropTarget;
}

function removeDropTarget(element: Element) {
	const idx = findIndex(dropTargets, { element });

	if (idx !== -1) {
		dropTargets.splice(idx, 1);
	}
}

function isOver(dropTarget: IDropTarget, dragSpec: store.IDragSpec): boolean {
	if (dropTarget && dragSpec && typeof document !== 'undefined' && dropTarget.canDrop(dragSpec.data)) {
		let hitElement = document.elementFromPoint(dragSpec.data.clientX, dragSpec.data.clientY);

		// console.log(dropTarget);
		// console.log(dragSpec.data);
		// console.log(hitElement);

		while (hitElement && hitElement !== document.body) {
			if (hitElement === dropTarget.element) {
				return true;
			}

			hitElement = hitElement.parentElement;
		}
	}

	return false;
}

function endDrag(spec: store.IDragSpec, dropTarget) {
	const data = assign({}, spec.data);
	let didDrop = false;

	if (dropTarget && dropTarget.canDrop(data)) {
		if (dropTarget.onDrop) {
			dropTarget.onDrop(data);
		}
		didDrop = true;
	}

	spec.dragEnd && spec.dragEnd(didDrop, data);
}

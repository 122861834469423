import { assign } from 'lodash';
import { IModel } from 'playmaker-team-common/dist/shared/interfaces';
import { createFactory } from 'playmaker-team-common/dist/shared/modelFactory';
import { default as validator } from 'playmaker-team-common/dist/shared/validator';
import { default as valueFilters } from 'playmaker-team-common/dist/shared/valueFilters';
import { default as rootModel, IRootModel } from './rootModel';

export const enum PlaybookAccess {
	None,
	View,
	Edit,
}

export interface IPlaybookMember extends IRootModel {
	access: PlaybookAccess;
	playbookId: string;
	teamMemberId: string;
}

export default createFactory<IPlaybookMember>('PlaybookMember', assign({}, rootModel, {
	access: {
		setFilters: valueFilters.toNumber,
		rules: (val) => [PlaybookAccess.None, PlaybookAccess.View, PlaybookAccess.Edit].indexOf(val) !== -1 || 'Invalid access setting',
	},
	playbookId: {
		setFilters: valueFilters.clean,
		rules: validator.required(),
	},
	teamMemberId: {
		setFilters: valueFilters.clean,
		rules: validator.required(),
	},
}));

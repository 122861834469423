import { assign, filter, find, map, sortBy } from 'lodash';
import { IPatchOperation, PatchOpType } from 'playmaker-team-common/dist/shared/interfaces';
import { default as valueFilters } from 'playmaker-team-common/dist/shared/valueFilters';
import * as React from 'react';
import * as actions from '../actions';
import { getPlaybookPermissions } from '../authorizationHelper';
import { current as getCurrentContext } from '../componentContext';
import { GamePhase } from '../models/diagramModel';
import { IFormation } from '../models/formation';
import { default as playFactory, IPlay } from '../models/play';
import { IPlaybook } from '../models/playbook';
import { IPlayer } from '../models/player';
import { ITag } from '../models/tag';
import { AlertMode, AlertSeverity, IAlert, INetworkInfo } from '../store';
import { _s, StringKey } from '../strings';
import * as viewManager from '../viewManager';
import { AlertList } from './alert';
import { NetworkContext } from './contexts';
import { Pageable } from './page';
import { PlaybookSelector } from './playbookSelector';
import { PlayerPosition } from './playerPosition';
import { Spinner } from './spinner';

interface Props {
	categories: ITag[];
	formations: IFormation[];
	isNew: boolean;
	onChange: (patch: IPatchOperation[]) => void;
	onDelete: () => void;
	onCopy: () => void;
	onCopyTo: (playbooks: IPlaybook[]) => void;
	play: IPlay;
}

export class PlayInfo extends React.Component<Props> {
	public static contextType = NetworkContext;
	public context!: React.ContextType<typeof NetworkContext>;

	constructor(props: Props) {
		super(props);

		this.handleCopyClick = this.handleCopyClick.bind(this);
		this.handleDeleteClick = this.handleDeleteClick.bind(this);
		this.handleCategoriesClick = this.handleCategoriesClick.bind(this);
		this.handleFormationsClick = this.handleFormationsClick.bind(this);
		this.handleCopyClick = this.handleCopyClick.bind(this);
		this.handleCopyToClick = this.handleCopyToClick.bind(this);

		this.state = { isCopying: false };
	}

	public handleCategoriesClick() {
		const { play } = this.props;
		const { currentPlaybook } = getCurrentContext();

		actions.popModal();
		viewManager.pushPath(`/playbook/${currentPlaybook.id}/categories/${play.phase}` );
	}

	public handleFormationsClick() {
		const { play } = this.props;
		const { currentPlaybook } = getCurrentContext();

		actions.popModal();
		viewManager.pushPath(`/playbook/${currentPlaybook.id}/templates/${play.phase}/formations` );
	}

	public handleChange(path: string, e) {
		const { formations, onChange, play } = this.props;
		const value = (e.type === 'blur') ? valueFilters.clean(e.target.value) : e.target.value;
		const patch = [{ op: PatchOpType.replace, path, value }];

		if (path === '/formation' && play.phase === GamePhase.SpecialTeams) {
			const formation = find(formations, { label: value });

			patch.push({ op: PatchOpType.replace, path: '/unit', value: formation.unit });
		}

		onChange(patch);
	}

	public handleCategoryClick(id: string) {
		const { onChange, play } = this.props;
		const categories = [].concat(play.categoryList);
		const itemIndex = categories.indexOf(id);

		if (itemIndex === -1) {
			categories.push(id);
		} else {
			categories.splice(itemIndex, 1);
		}

		onChange([{ op: PatchOpType.replace, path: '/categories', value: categories.join(',') }]);
	}

	public handleCopyToClick() {
		const { play, onCopyTo } = this.props;
		const { currentPlaybook, playbooks: contextPlaybooks, teamPermissions } = getCurrentContext();
		const playbooks = sortBy(filter(contextPlaybooks, (playbook: IPlaybook) => {
			const permissions = getPlaybookPermissions({ playbook, teamPermissions });

			return permissions.canUpdate && playbook.id !== currentPlaybook.id && playbook.playersPerSide === currentPlaybook.playersPerSide;
		}), 'name');

		if (playbooks.length) {
			actions.pushModal({
				component: PlaybookSelector,
				props: {
					cancelLabel: _s(StringKey.CANCEL),
					description: _s(StringKey.SELECT_PLAYBOOKS),
					okLabel: _s(StringKey.COPY),
					onCancel: actions.popModal,
					onOk: (pbs: IPlaybook[]) => {
						actions.popModal();

						onCopyTo(pbs);
					},
					playbooks,
					title: _s(StringKey.COPY_PLAY),
					classNames: ['prompt'],
				},
			});
		} else {
			const alertId = `unable-to-copy-${play.id}`;
			actions.pushAlert({
				id: alertId,
				title: _s(StringKey.UNABLE_TO_COPY_PLAY_TITLE),
				message: _s(StringKey.UNABLE_TO_COPY_PLAY_MESSAGE),
				mode: AlertMode.prompt,
				severity: AlertSeverity.info,
				actions: [{
					label: _s(StringKey.OK),
					action: () => { actions.deleteAlert(alertId); },
				}],
			});
		}
	}

	public handleCopyClick() {
		const { onCopy } = this.props;

		onCopy();
	}

	public handleDeleteClick() {
		const { play, onDelete } = this.props;
		const alertId = `delete-${play.id}`;

		const deleteAction = () => {
			actions.deleteAlert(alertId);
			onDelete();
		};

		const cancelAction = () => {
			actions.deleteAlert(alertId);
		};

		actions.pushAlert({
			id: alertId,
			title: _s(StringKey.DELETE_PLAY_QUESTION),
			message: _s(StringKey.DELETE_PLAY_PROMPT_MESSAGE),
			mode: AlertMode.prompt,
			severity: AlertSeverity.confirmation,
			actions: [{
				label: _s(StringKey.DELETE_PLAY),
				className: 'delete',
				action: deleteAction,
			}, {
				label: _s(StringKey.CANCEL),
				className: 'cancel',
				action: cancelAction,
			}],
		});
	}

	public render() {
		const { categories, formations, isNew, play} = this.props;
		const networkInfo = this.context;
		const canSelectFormation = isNew || play.phase !== GamePhase.SpecialTeams;
		const categoryItems = map(sortBy(categories, 'sortIndex'), (c: ITag) => {
			const isChecked = play.categoryList.indexOf(c.id) !== -1;

			return <div key={ c.id } className="checkbox">
				<label className={ isChecked ? 'on' : '' } onClick={ this.handleCategoryClick.bind(this, c.id) }><span className="icon checkmark"></span><span>{ c.label }</span></label>
			</div>;
		});
		const formationItems = map(sortBy(filter(formations, (f) => !!f.label), 'sortIndex'), (f: IFormation) => {
			const isChecked = play.formation === f.label;

			return <div key={ f.id } className="checkbox">
				<label className={ isChecked ? 'on' : '' }><span className="icon checkmark"></span><span>{ f.label }</span><input type="radio" name="selectFormation" value={ f.viewValue('label') } checked={ isChecked } onChange={ canSelectFormation ? this.handleChange.bind(this, '/formation') : () => {} } /></label>
			</div>;
		});

		return <Pageable labels={ [_s(StringKey.INFO), _s(StringKey.NOTES)] }>
			<div className="content playInfo">
				<div className="inner">
					<input type="text" placeholder={ _s(StringKey.PLAY_NAME) } value={ play.viewValue('label') } onChange={ this.handleChange.bind(this, '/label') } onBlur={ this.handleChange.bind(this, '/label') }  />
					<div className="columns">
						<div className="group tackleOnly">
							<header><div className="actions"><a className="button" onClick={ this.handleFormationsClick }><span>{ _s(StringKey.FORMATION) }</span><span className="icon edit"></span></a></div></header>
							<div className={ `list scrollable${canSelectFormation ? '' : ' disabled'}` }>
								{ formationItems }
							</div>
						</div>
						<div className="group">
							<header><div className="actions"><a className="button" onClick={ this.handleCategoriesClick }><span>{ _s(StringKey.CATEGORIES) }</span><span className="icon edit"></span></a></div></header>
							<div className="list scrollable">
								{ categoryItems }
							</div>
						</div>
					</div>
					{ !isNew ? <div className="actions">
						<a className="button basic" onClick={ this.handleCopyClick }><span>{ _s(StringKey.COPY) }</span></a>
						<a className="button basic" onClick={ this.handleCopyToClick }><span>{ _s(StringKey.COPY_TO) }</span></a>
						<a className="button basic delete" onClick={ this.handleDeleteClick }><span>{ _s(StringKey.DELETE) }</span></a>
					</div> : null }
				</div>
			</div>
			<div className="content">
				<div className="inner">
					{ !isNew ? <PlayMetadata networkInfo={ networkInfo } play={ play } /> : null }
					<div className="notes scrollable">
						<textarea placeholder={ _s(StringKey.OPTIONAL_PLAY_NOTES )} value={ play.viewValue('notes') } onChange={ this.handleChange.bind(this, '/notes') } onBlur={ this.handleChange.bind(this, '/notes') }></textarea>
						<div className="positionNotes">
							{
								map(sortBy(play.mates.values, 'sortIndex').reverse(), (mate: IPlayer) => {
									return 	<div className="positionNote" key={mate.id}>
										<input type="text" placeholder={ _s(StringKey.POSITION_NOTE) } value={ mate.viewValue('note') } onChange={ this.handleChange.bind(this, `/mates/${mate.id}/note`) } onBlur={ this.handleChange.bind(this, `/mates/${mate.id}/note`) }  />
										<div className="positionIcon">
											<svg className="icon" viewBox="0 0 30 30"><svg className="player" x="50%" y="50%"><PlayerPosition player={ mate } color={ `color${mate.color}` } active={ false } isOpponent={ false } /></svg></svg>
										</div>
									</div>;
								})
							}
						</div>
					</div>
				</div>
			</div>
		</Pageable>;
	}
}

const PlayInfoViewer = ({ play }: { play: IPlay }) => {
	return <div className="view">
		<div className="content scrollable">
			<div className="inner">
				<div className="notes readOnly">
					<div className="generalNote">
						{ play.notes || _s(StringKey.NO_NOTES) }
					</div>
					<div className="positionNotes">
						{
							map(sortBy(filter(play.mates.values, (m) => !!m.note), 'sortIndex').reverse(), (mate: IPlayer) => {
								return 	<div className="positionNote" key={mate.id}>
									<div className="text">{ mate.viewValue('note') }</div>
									<div className="positionIcon">
										<svg className="icon" viewBox="0 0 30 30"><svg className="player" x="50%" y="50%"><PlayerPosition player={ mate } color={ `color${mate.color}` } active={ false } isOpponent={ false } /></svg></svg>
									</div>
								</div>;
							})
						}
					</div>
				</div>
			</div>
		</div>
	</div>;
};

const PlayMetadata = ({ networkInfo, play }: {networkInfo: INetworkInfo, play: IPlay} ) => {

	const { currentSubscription, currentTeam } = getCurrentContext();
	const hasSubscription = currentSubscription && currentSubscription.isActive();
	let statusClass = 'icon cloud disconnected';
	let infoDiv;

	if (hasSubscription) {
		if (networkInfo.canAccessApi) {
			const teamMember = find(currentTeam.members.values, { userId: play.lastMutatorId });
			const statusText = _s(StringKey.MODIFIED_BY_TEMPLATE).replace('{date}', play.dateLastMutated ? play.dateLastMutated.toLocaleString() : '-').replace('{name}', teamMember ? teamMember.fullName : '-');

			statusClass = 'icon cloud';
			infoDiv = <div className="info" dangerouslySetInnerHTML={ { __html: statusText}}></div>;
		} else {
			infoDiv = <div className="info">{ _s(StringKey.PLAY_INFO_NOT_CONNECTED) }</div>;
		}
	} else {
		infoDiv = <div className="info">{ _s(StringKey.PLAY_INFO_FREE) }</div>;
	}

	return <div className="metaData">
		<div className="status"><span className={ statusClass }></span></div>
		{ infoDiv }
	</div>;
};

interface ContainerProps {
	alerts?: IAlert[];
	categories: ITag[];
	formations: IFormation[];
	isNew: boolean;
	play: IPlay;
	savePlay: (play: IPlay, onComplete: () => void) => void;
}

interface ContainerState {
	mutatedPlay: IPlay;
	processing: boolean;
}

class PlayInfoContainer extends React.Component<ContainerProps, ContainerState> {
	constructor(props: ContainerProps) {
		super(props);

		this.state = { mutatedPlay: playFactory(props.play), processing: false };

		this.handleChange = this.handleChange.bind(this);
		this.handleCopyTo = this.handleCopyTo.bind(this);
	}

	public handleChange(patch: IPatchOperation[]) {
		const { formations, isNew} = this.props;

		this.setState((prevState) => {
			const newState = assign({}, prevState) as ContainerState;

			// clone to be sure children updated based on props changing
			newState.mutatedPlay = playFactory(newState.mutatedPlay);
			newState.mutatedPlay.applyPatch(patch);

			const formationOp: IPatchOperation = find(patch, (op) => op.path === '/formation');

			if (formationOp && isNew) {
				const formation: IFormation = find(formations, { label: formationOp.value });

				newState.mutatedPlay.mates = formation.mates.clone();
				newState.mutatedPlay.posture = formation.posture;
				newState.mutatedPlay.annotations = formation.annotations.clone();
				newState.mutatedPlay.ballLocation = formation.ballLocation;
				newState.mutatedPlay.containerOffset = formation.containerOffset;
			}

			return newState;
		});
	}

	public async handleCopyTo(playbooks: IPlaybook[]) {
		const { mutatedPlay, processing } = this.state;
		const alertId = `copy-play-${mutatedPlay.id}`;

		if(!processing) {
			this.setState({processing: true}, async () => {
				await actions.copyPlays([mutatedPlay], playbooks);

				this.setState({processing: false}, () => {
					actions.popModal();

					actions.pushAlert({
						id: alertId,
						title: _s(StringKey.COPY_COMPLETE),
						message: _s(StringKey.PLAYS_COPIED),
						mode: AlertMode.prompt,
						severity: AlertSeverity.info,
						actions: [{
							label: _s(StringKey.OK),
							action: () => { actions.deleteAlert(alertId); },
						}],
					});
				});
			});
		}
	}

	public render() {
		return null;
	}
}

export class PlayInfoModal extends PlayInfoContainer {
	constructor(props: ContainerProps) {
		super(props);

		this.handleCopy = this.handleCopy.bind(this);
		this.handleSave = this.handleSave.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
	}

	public async handleCopy() {
		const { mutatedPlay, processing } = this.state;
		const alertId = `copy-play-${mutatedPlay.id}`;
		const { currentPlaybook } = getCurrentContext();

		if(!processing) {
			this.setState({processing: true}, async () => {
				await actions.copyPlays([mutatedPlay], [currentPlaybook]);

				this.setState({processing: false}, () => {
					actions.popModal();

					actions.pushAlert({
						id: alertId,
						title: _s(StringKey.COPY_COMPLETE),
						message: _s(StringKey.PLAY_COPIED),
						mode: AlertMode.prompt,
						severity: AlertSeverity.info,
						actions: [{
							label: _s(StringKey.OK),
							action: () => { actions.deleteAlert(alertId); },
						}],
					});
				});
			});
		}
	}

	public handleDelete() {
		const { play } = this.props;
		const { processing } = this.state;

		if(!processing) {
			this.setState({processing: true}, async () => {
				await actions.deletePlay(play);

				this.setState({processing: false}, () => {
					viewManager.popModal();
				});
			});
		}
	}

	public handleSave() {
		const { savePlay } = this.props;
		const { mutatedPlay, processing } = this.state;

		if(!processing && actions.validatePlay(mutatedPlay)) {
			this.setState({processing: true}, () => {
				savePlay(mutatedPlay, () => {
					this.setState({processing: false});
				});
			});
		}
	}

	public render() {
		const { alerts, categories, formations, isNew} = this.props;
		const { playbookPermissions } = getCurrentContext();
		const { mutatedPlay, processing } = this.state;
		const title = isNew ? _s(StringKey.NEW_PLAY) : _s(StringKey.PLAY);

		return	<div className="view">
			{ processing? <Spinner delayMs={150} />: null }
			<header>
				<div className="actions">
					<a className="button" onClick={ viewManager.popModal }><span className="icon cancel"></span></a>
				</div>
				<div className="title">{ title }</div>
				{ !playbookPermissions.canUpdate ? null : <div className="actions">
					<a className={ actions.validatePlay(mutatedPlay, false) ? 'button' : 'button disabled' } onClick={ this.handleSave }><span className="icon ok"></span></a>
				</div>
				}
			</header>
			<AlertList alerts={ alerts } />
			{ playbookPermissions.canUpdate ? <PlayInfo categories={ categories } formations={ formations } isNew={ isNew } onCopy={ this.handleCopy } onCopyTo={ this.handleCopyTo } onChange={ this.handleChange } onDelete={ this.handleDelete } play={ mutatedPlay } /> : <PlayInfoViewer play={ mutatedPlay } /> }
		</div>;
	}
}

export class PlayPanelInfo extends PlayInfoContainer {
	constructor(props: ContainerProps) {
		super(props);

		this.handleCopy = this.handleCopy.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
		this.handleSave = this.handleSave.bind(this);
	}

	public async handleCopy() {
		const { mutatedPlay, processing } = this.state;
		const decorationId = `copy-play-${mutatedPlay.id}`;
		const { currentPlaybook } = getCurrentContext();

		if(!processing) {
			this.setState({processing: true}, async () => {
				const copied = await actions.copyPlays([mutatedPlay], [currentPlaybook]);

				actions.popPanel();
				actions.pushDecoration({
					component: () => <div className="graphic copyItem"><div className="item original"></div><div className="item copy"></div></div>,
					id: decorationId,
					props: {},
				});

				viewManager.rewritePath(`/playbook/${currentPlaybook.id}/plays/${copied[0].id}`);

				setTimeout(() => {
					actions.deleteDecoration(decorationId);
				}, 1100);
			});
		}
	}

	public async handleDelete() {
		const { play } = this.props;
		const { processing } = this.state;

		if(!processing) {
			this.setState({processing: true}, async () => {
				await actions.deletePlay(play);
				actions.popPanel();
				viewManager.rewritePath(`/playbook/${play.playbookId}`);
			});
		}
	}

	public handleSave() {
		const { savePlay } = this.props;
		const { mutatedPlay } = this.state;
		const { playbookPermissions } = getCurrentContext();

		if (playbookPermissions.canUpdate) {
			if (actions.validatePlay(mutatedPlay)) {
				savePlay(mutatedPlay, () => {});
				viewManager.popPanel();
			}
		} else {
			viewManager.popPanel();
		}
	}

	public render() {
		const { alerts, categories, formations, isNew} = this.props;
		const { mutatedPlay, processing } = this.state;
		const { playbookPermissions } = getCurrentContext();

		return 	<div className="view">
			{ processing? <Spinner delayMs={150} />: null }
			<header>
				<div className="title">{ _s(StringKey.PLAY_INFO) }</div>
				<div className="actions">
					<a className={ actions.validatePlay(mutatedPlay, false) ? 'button' : 'button disabled' } onClick={ this.handleSave }><span className="icon ok"></span></a>
				</div>
			</header>
			<AlertList alerts={ alerts } />
			{ playbookPermissions.canUpdate ? <PlayInfo categories={ categories } formations={ formations } isNew={ isNew } onCopy={ this.handleCopy } onCopyTo={ this.handleCopyTo } onChange={ this.handleChange } onDelete={ this.handleDelete } play={ mutatedPlay } /> : <PlayInfoViewer play={ mutatedPlay } /> }
		</div>;
	}
}

import { find } from 'lodash';
import * as store from './store';
import { createEmptyPlaybookPermissions, createEmptyTeamPermissions, getPlaybookPermissions, getTeamPermissions, getUserPlaybooks, hasPlaybooksPage, hasValidSubscription, IPlaybookPermissions, ITeamPermissions } from './authorizationHelper';
import { GamePhase } from './models/diagramModel';
import { IFormation } from './models/formation';
import { IPlay } from './models/play';
import { IPlaybook } from './models/playbook';
import { ISubscription } from './models/subscription';
import { ITeam } from './models/team';
import { IUser } from './models/user';
import { IAppState, IModelState, IPlatformInfo, IViewState } from './store';

export interface IComponentContext {
	currentFormation?: IFormation;
	currentPhase?: GamePhase;
	currentPlay?: IPlay;
	currentPlaybook?: IPlaybook;
	currentSubscription?: ISubscription;
	currentTeam?: ITeam;
	currentUser?: IUser;
	hasPlaybooksPage?: boolean;
	platform?: IPlatformInfo;
	playbookPermissions?: IPlaybookPermissions;
	teamPermissions?: ITeamPermissions;
	playbooks?: IPlaybook[];
	sponsorLogoUrl?: string;
	sponsorLogoPrintUrl?: string;
	model: IModelState;
	viewState: IViewState;
	variant?: 'flag' | 'tackle';
}

class ContextHander implements ProxyHandler<IComponentContext> {
	public get(target: IComponentContext, name: string | number | symbol) {
		return Reflect.get(target, name);
	}
	public set(target: IComponentContext, name: string | number | symbol, value) {
		if (name === 'playbookPermissions') {
			return Reflect.set(target, name, value);
		}
		return false;
	}
}

let _innerContext: IComponentContext = store.appState();
export function populate(appState: IAppState, redirectToRoot: () => void) {
	const target: IComponentContext = { viewState: appState.viewState, model: appState.model, teamPermissions: createEmptyTeamPermissions(), playbookPermissions: createEmptyPlaybookPermissions(), playbooks: [], currentTeam: {} as ITeam, currentUser: {} as IUser };
	const currentTeamId = appState.viewState.currentTeamId;
	const newContext = new Proxy<IComponentContext>(target, new ContextHander());

	target.platform = target.viewState.platform;
	target.variant = target.viewState.config.variant;
	target.currentTeam = appState.model.teams && appState.model.teams[currentTeamId];
	target.currentUser = appState.model.users && appState.model.users[appState.viewState.currentUserId];

	if (target.currentTeam) {
		if (target.currentUser) {
			target.teamPermissions = getTeamPermissions({ team: target.currentTeam, user: target.currentUser });

			target.playbooks = getUserPlaybooks({ team: target.currentTeam, teamPermissions: target.teamPermissions, user: target.currentUser });
			target.hasPlaybooksPage = !target.playbooks.length || hasPlaybooksPage({ playbooks: target.playbooks, team: target.currentTeam, teamPermissions: target.teamPermissions, user: target.currentUser });
		}

		if (target.currentTeam.currentSubscription) {
			target.currentSubscription = target.currentTeam.currentSubscription;

			if (!(target.currentSubscription as any)._valid && !hasValidSubscription(target.currentTeam!)) {
				target.currentSubscription.supportsFeature = target.currentSubscription.hasFeature = target.currentSubscription.isActive = () => false;
				target.currentSubscription.hasEnded = target.currentSubscription.hasExpired = target.currentSubscription.isDue = () => true;
			} else {
				(target.currentSubscription as any)._valid = true;
			}

			if (target.currentSubscription && target.currentSubscription.settings && target.currentSubscription.settings.assets) {
				const logoAsset = find(target.currentSubscription.settings.assets, { key: 'sponsorLogo'});
				const printAsset = find(target.currentSubscription.settings.assets, { key: 'sponsorLogoPrint'});

				target.sponsorLogoUrl = logoAsset && logoAsset.url;
				target.sponsorLogoPrintUrl = (printAsset && printAsset.url) || target.sponsorLogoUrl;
			}
		}

	}

	if (appState.viewState.currentRoute) {
		const currentRoute = appState.viewState.currentRoute;
		const currentPlaybookId = currentRoute.params.playbookid;

		target.currentFormation = find(appState.model.formations, { id: currentRoute.params.formationid });
		target.currentPhase = currentRoute.params.phase ? Number(currentRoute.params.phase) : undefined;
		target.currentPlay = find(appState.model.plays, { id: currentRoute.params.playid });
		target.currentPlaybook = target.currentTeam && find(target.currentTeam.playbooks, { id: currentPlaybookId });

		if (target.teamPermissions && target.currentPlaybook) {
			target.playbookPermissions = getPlaybookPermissions({ playbook: target.currentPlaybook, teamPermissions: target.teamPermissions, user: target.currentUser });
		}

		// re-route the system if user access has been revoked
		if (currentTeamId && !target.currentTeam && currentRoute.route.path !== '/') {
			redirectToRoot();
		} else if (currentPlaybookId && target.currentPlaybook && (!target.playbookPermissions || !target.playbookPermissions.canView) && currentRoute.route.path !== '/') {
			redirectToRoot();
		}
	}

	_innerContext = newContext;
}

function getCurrent() {
	return _innerContext;
}

export { getCurrent as current };

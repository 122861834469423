import * as React from 'react';

export function Lines53x120({}) {
	return <div className="field">
		<svg viewBox="0 0 1024 2304">
			<svg className="markings" x="10%" y="10%" width="80%" height="80%">
				<svg className="tenYards" x="0%" y="0%" height="8.35%">
					<line className="yardline" x1="0" x2="100%" y1="50%" y2="50%"></line>
					<line className="yardline" x1="0" x2="100%" y1="100%" y2="100%"></line>
				</svg>
				<svg className="tenYards" x="0%" y="8.35%" height="8.33%">
					<line className="yardline" x1="0" x2="100%" y1="50%" y2="50%"></line>
					<line className="yardline" x1="0" x2="100%" y1="100%" y2="100%"></line>
				</svg>
				<svg className="tenYards" x="0%" y="16.68%" height="8.33%">
					<line className="yardline" x1="0" x2="100%" y1="50%" y2="50%"></line>
					<line className="yardline" x1="0" x2="100%" y1="100%" y2="100%"></line>
				</svg>
				<svg className="tenYards" x="0%" y="25.01%" height="8.33%">
					<line className="yardline" x1="0" x2="100%" y1="50%" y2="50%"></line>
					<line className="yardline" x1="0" x2="100%" y1="100%" y2="100%"></line>
				</svg>
				<svg className="tenYards" x="0%" y="33.34%" height="8.33%">
					<line className="yardline" x1="0" x2="100%" y1="50%" y2="50%"></line>
					<line className="yardline" x1="0" x2="100%" y1="100%" y2="100%"></line>
				</svg>
				<svg className="tenYards" x="0%" y="41.67%" height="8.33%">
					<line className="yardline" x1="0" x2="100%" y1="50%" y2="50%"></line>
					<line className="yardline" x1="0" x2="100%" y1="100%" y2="100%"></line>
				</svg>
				<svg className="tenYards" x="0%" y="50%" height="8.33%">
					<line className="yardline" x1="0" x2="100%" y1="50%" y2="50%"></line>
					<line className="yardline" x1="0" x2="100%" y1="100%" y2="100%"></line>
				</svg>
				<svg className="tenYards" x="0%" y="58.33%" height="8.33%">
					<line className="yardline" x1="0" x2="100%" y1="50%" y2="50%"></line>
					<line className="yardline" x1="0" x2="100%" y1="100%" y2="100%"></line>
				</svg>
				<svg className="tenYards" x="0%" y="66.66%" height="8.33%">
					<line className="yardline" x1="0" x2="100%" y1="50%" y2="50%"></line>
					<line className="yardline" x1="0" x2="100%" y1="100%" y2="100%"></line>
				</svg>
				<svg className="tenYards" x="0%" y="74.99%" height="8.33%">
					<line className="yardline" x1="0" x2="100%" y1="50%" y2="50%"></line>
					<line className="yardline" x1="0" x2="100%" y1="100%" y2="100%"></line>
				</svg>
				<svg className="tenYards" x="0%" y="83.32%" height="8.33%">
					<line className="yardline" x1="0" x2="100%" y1="50%" y2="50%"></line>
					<line className="yardline" x1="0" x2="100%" y1="100%" y2="100%"></line>
				</svg>
				<svg className="tenYards" x="0%" y="91.65%" height="8.35%">
					<line className="yardline" x1="0" x2="100%" y1="50%" y2="50%"></line>
				</svg>
			</svg>
		</svg>
	</div>;
}

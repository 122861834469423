import React, { useEffect, useState } from 'react';

export function Spinner({ cancelAction, className = '', label, noShade, showInner, delayMs }: { cancelAction?: () => void, className?: string, label?: string, noShade?: boolean, showInner?: boolean, delayMs?: number}) {
	const [isHidden, setIsHidden] = useState(true);
	const loaderStyle = noShade ? { backgroundColor: 'transparent' } : null;

	useEffect(() => {
		if(delayMs) {
			setTimeout(() => {
				setIsHidden(false);
			}, delayMs)
		}
		else {
			setIsHidden(false);
		}
	}, []);

	return !isHidden? <div className={ `loader ${className}` } style={ loaderStyle }>
		<div className="graphics">
			{ showInner ? <div className="spinner inner"></div> : null }
			<div className="spinner"></div>
		</div>
		{ label ? <div className="label">{ label }</div> : null }

		{ cancelAction ? <a className="link small" onClick={ () => cancelAction() }>Cancel</a> : null }
	</div>: null;

	// return <div className="loader" style={ loaderStyle }><div className="spinner"></div>{ label }</div>;
}

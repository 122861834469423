import { assign } from 'lodash';
import { IModel, StringDictionary } from 'playmaker-team-common/dist/shared/interfaces';
import { createFactory } from 'playmaker-team-common/dist/shared/modelFactory';
import { default as validator } from 'playmaker-team-common/dist/shared/validator';
import { default as valueFilters } from 'playmaker-team-common/dist/shared/valueFilters';
import { PlayerZoneShape } from './player';
import { default as routeFactory, IRoute } from './route';
import { default as routedDiagramItemModel, IRoutedDiagramItem } from './routedDiagramItemModel';
import { default as sortableModel, ISortable } from './sortableModel';

export enum AnnotationType {
	Ball,
	Option,
	Text,
	BallAnimatable,
}

export interface IAnnotation extends IRoutedDiagramItem, ISortable {
	color: number;
	label: string;
	subType: AnnotationType;
	zoneScale?: number;
	zoneShape?: PlayerZoneShape;
}

const validateRoute = validator.validModel(routeFactory.rules());

export default createFactory<IAnnotation>('Annotation', assign({}, routedDiagramItemModel, sortableModel, {
	color: {
		setFilters: valueFilters.toNumber,
	},
	label: {
		setFilters: (val) => val === '' ? undefined : val,
	},
	// override route to handle annotations that don't support a route
	route: {
		rules: function(val, target, property) { this.subType === AnnotationType.Text ? undefined : validateRoute(val, target, property) },
	},
	_set_route(val) {
		if (this.subType === AnnotationType.Text) {
			return;
		}

		const innerVal = this.__getBackingValue('route');

		innerVal.hasBeenSet = (typeof val !== 'undefined');
		innerVal.value = routeFactory(val);
	},
	_get_route(val) {
		if (this.subType === AnnotationType.Text) {
			return undefined;
		}
		const innerVal = this.__getBackingValue('route');

		if (!innerVal.hasBeenSet) {
			this._set_route(val);
		}

		return innerVal.value;
	},
	subType: {
		setFilters: valueFilters.toNumber,
	},
	zoneScale: {
		setFilters: valueFilters.toNumber,
	},
	zoneShape: {
		setFilters: valueFilters.toNumber,
	},
	maxRouteSections: {
		nonSerialized: true,
		writable: false,
	},
	_get_maxRouteSections() {
		return this.subType === AnnotationType.Ball ? 1 : -1;
	},
}));

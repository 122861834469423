import { assign, filter, find, forEach, last, map } from 'lodash';
import { default as router, IRoute, IRouteEnterParams } from 'playmaker-team-common/dist/client/router';
import * as React from 'react';
import { createRoot } from 'react-dom/client';
import { TransitionGroup } from 'react-transition-group';
import { bootstrap } from './actions';
import * as actions from './actions';
import { current as getCurrentContext, populate as populateContext } from './componentContext';
import { Categories } from './components/categories';
import { ConnectionStatusPrompt } from './components/connectionStatus';
import { NetworkContext } from './components/contexts';
import { Dashboard } from './components/dashboard';
import { DecorationTransition } from './components/decoration';
import { DragContext } from './components/dnd';
import { Formation } from './components/formation';
import { ModalTransition } from './components/modal';
import { Onboarding } from './components/onboarding';
import { Page, PageState } from './components/page';
import { PersonnelGroups } from './components/personnelGroups';
import { Play } from './components/play';
import { PlaybookPlays } from './components/playbookPlays';
import { Playbooks } from './components/playbooks';
import { Prompt } from './components/prompt';
import { Spinner } from './components/spinner';
import { TeamCenter } from './components/teamCenter';
import { TooltipTransition } from './components/tooltip';
import * as logger from './logger';
import * as store from './store';
import { _s, StringKey } from './strings';
import { onStoreMutating as processRouteTooltips } from './tooltipManager';
import * as viewManager from './viewManager';
import { Templates } from './components/templates';
import { RouteTreeRoute } from './components/routeTree';
import { PlayFilterProvider } from './components/playFilterProvider';

// pull configuration from the global PlaymakerTeam object and get the appRoot element
const playmakerTeam = (window as any).PlaymakerTeam;
const config = playmakerTeam.config;
const appRoot = document.getElementById('appRoot');
const reactRoot = createRoot(appRoot);

(window as any).PlaymakerTeam = undefined;

interface IAppRoute extends IRoute {
	className?: string;
	screenName: string;
}

const _playRoute = {
	path: `${config.virtualRoot}/playbook/:playbookid/plays/:playid`,
	component: Play,
	className: 'play',
	screenName: 'Play',
	onEnter: (args: IRouteEnterParams) => {
		if (store.appState().viewState.lastViewedPlayId !== args.next.params.playid) {
			actions.setLastViewedPlayId(args.next.params.playid);
		}
		return true; // proceed
	},
};

const _formationRoute = {
	path: `${config.virtualRoot}/playbook/:playbookid/formations/:phase/:formationid`,
	component: Formation,
	className: 'play formation',
	screenName: 'Formation',
};

const _routeTreeRoute = {
	path: `${config.virtualRoot}/playbook/:playbookid/routetree/:phase/:mateid?`,
	component: RouteTreeRoute,
	className: 'play routeTreeRoute',
	screenName: 'RouteTree',
};

const _diagramRoutes: IRoute[] = [_playRoute, _formationRoute, _routeTreeRoute];

const mainRoutes: IAppRoute[] = [{
	path: `${config.virtualRoot || '/'}`,
	component: (props) => {
		const { viewState, model } = store.appState();
		const teams = filter(model.teams, () => true);

		return viewState.currentUserId && teams.length ? <Dashboard { ...props } /> : <Onboarding { ...props } />;
	},
	className: 'dashboard',
	screenName: 'Dashboard',
	onEnter: () => {
		if (store.appState().viewState.lastViewedPlayId) {
			actions.setLastViewedPlayId('');
		}
		return true; // proceed
	},
}, {
	path: `${config.virtualRoot}/playbook/`,
	component: Playbooks,
	className: 'playbooks gallery',
	screenName: 'Playbooks',
}, {
	path: `${config.virtualRoot}/playbook/:playbookid`,
	component: PlaybookPlays,
	className: 'gallery',
	screenName: 'Playbook',
	onEnter: () => {
		if (store.appState().viewState.lastCreatedDiagramId) {
			actions.setLastCreatedDiagramId('');
		}
		return true; // proceed
	},
}, {
// 	path: `${config.virtualRoot}/playbook/:playbookid/formations/:phase`,
// 	component: Formations,
// 	className: 'gallery',
// 	screenName: 'Formations',
// }, {
	path: `${config.virtualRoot}/playbook/:playbookid/templates/:phase/:templatepage`,
	component: Templates,
	className: 'gallery',
	screenName: 'Templates',
	onEnter: () => {
		if (store.appState().viewState.lastCreatedDiagramId) {
			actions.setLastCreatedDiagramId('');
		}
		return true; // proceed
	},
}, {
	path: `${config.virtualRoot}/playbook/:playbookid/categories/:phase`,
	component: Categories,
	className: 'gallery',
	screenName: 'Categories',
}, _formationRoute,  _playRoute, _routeTreeRoute, {
	path: `${config.virtualRoot}/playbook/:playbookid/plays/:playid/roster/`,
	component: TeamCenter,
	className: 'gallery',
	screenName: 'PlayRoster',
}, {
	path: `${config.virtualRoot}/playbook/:playbookid/plays/:playid/personnel/:phase`,
	component: PersonnelGroups,
	className: 'gallery',
	screenName: 'PersonnelGroups',
}, {
	path: `${config.virtualRoot}/team-center`,
	component: TeamCenter,
	className: 'gallery',
	screenName: 'TeamCenter',
}];

router.routes = mainRoutes;

const DragItem = ({  modalIndex, spec }) => {
	if (!spec) {
		return null;
	}

	const Component = spec.component;
	const dProps = typeof spec.props === 'function' ? spec.props() : spec.props;
	const layout = spec.data.layout || { x: 0, y: 0};

	return <div className={ spec.containerClassName } style={{ zIndex: (200 + modalIndex), position: 'absolute', left: spec.data.clientX - layout.x, top: spec.data.clientY - layout.y, pointerEvents: 'none', transition: 'unset', width: layout.width && (layout.width + 'px'), height: layout.height && (layout.height + 'px')}}><Component { ...dProps } /></div>;
};

interface Props {
	appState: store.IAppState;
}

class App extends React.Component<Props> {
	constructor(props) {
		super(props);
	}

	public getPageState(route: IRoute) {
		if (viewManager.isOnTop(route)) {
			return PageState.IN;
		} else if (viewManager.isInStack(route)) {
			return PageState.OUT;
		} else {
			return PageState.DEFAULT;
		}
	}

	public render() {
		const { viewState, currentTeam, currentUser } = getCurrentContext();
		const topModalAlertHandler = last(filter( viewState.modals, { supportsAlerts: true }));
		const alerts = filter( viewState.alerts, { mode: store.AlertMode.default });
		const isDiagramDragging = viewState.currentRoute && viewState.dragSpec && _diagramRoutes.indexOf(viewState.currentRoute.route) !== -1;
		const contentClasses = ['content'];
		let modalIndex = 0;
		const panels: any[] = map( viewState.panels, (d) => {
			const Component = d.component;
			const mProps = typeof d.props === 'function' ? d.props() : d.props;

			mProps.index = modalIndex ++;

			return <ModalTransition isDiagramDragging={ isDiagramDragging } key={ d.id } { ...mProps }><Component /></ModalTransition>;
		});
		const modals: any[] = map( viewState.modals, (d) => {
			const Component = d.component;
			const mProps = typeof d.props === 'function' ? d.props() : d.props;

			mProps.index = modalIndex ++;

			if (d === topModalAlertHandler) {
				mProps.alerts = alerts.length ? alerts : [];
			}

			return <ModalTransition key={ d.id } { ...assign({ id: d.id }, mProps) }><Component /></ModalTransition>;
		});
		const tooltips: any[] = map( viewState.tooltips, (d) => {
			const tProps = assign({ index: modalIndex }, d );

			return <TooltipTransition key={ tProps.id } { ...tProps } />;
		});
		const decorations: any[] = map( viewState.decorations, (d) => {
			const Component = d.component;
			const mProps = typeof d.props === 'function' ? d.props() : d.props;

			mProps.classNames = mProps.classNames || [];
			if(mProps.classNames.indexOf('modal') === -1) {
				mProps.classNames.unshift('modal');
			}

			mProps.index = modalIndex ++;

			return <DecorationTransition isDiagramDragging={ isDiagramDragging } key={ d.id } { ...mProps }><Component /></DecorationTransition>;
		});

		forEach(filter( viewState.alerts, { mode: store.AlertMode.prompt }), (prompt: store.IAlert) => {
			modals.push(<ModalTransition key={ prompt.id } classNames={ ['prompt'] } index={ modalIndex ++ }><Prompt title={ prompt.title} message={ prompt.message } severity={ prompt.severity } id={ prompt.id } actions={ prompt.actions } /></ModalTransition>);
		});

		let activeRoute;
		const pages = mainRoutes.map((r) => {
			const Component = r.component;
			const pageState = this.getPageState(r);
			const pageAlerts = (pageState === PageState.IN && !topModalAlertHandler) ? alerts.length ? alerts : [] : [];

			if (pageState === PageState.IN) {
				activeRoute = r;
			}

			return <Page key={ r.path as string } alerts={ pageAlerts } pageState={ pageState } pageClasses={ ['page', r.className] } { ...this.props }>
				<Component />
			</Page>;
		});

		// route panic!
		if (!activeRoute) {
			console.log('route panic!!!');
			setTimeout(() => viewManager.reset(), 0);
		}

		if (currentUser && currentUser.profile.backgroundMode === 'light') {
			contentClasses.push('light');
		}

		if (isDiagramDragging) {
			contentClasses.push('diagramDragging');
		}

		if (currentTeam && currentTeam.settings.color) {
			const colorDef = find(viewState.settings.teamColors, (c) => c.hex === currentTeam.settings.color);

			if (colorDef && colorDef.contentClass) {
				contentClasses.push(colorDef.contentClass);
			}
		}

		return <NetworkContext.Provider value={  viewState.api }>
			<DragContext.Provider value={  viewState.dragSpec }>
				<React.Fragment>
					<TransitionGroup component={ null }>
						{ panels }
						{ modals }
						{ tooltips }
						{ decorations }
					</TransitionGroup>
					<PlayFilterProvider {...this.props}>
						<div className={ contentClasses.join(' ') }>
							{ pages }
							<header></header>
						</div>
					</PlayFilterProvider>
					<DragItem modalIndex={ modalIndex ++ } spec={  viewState.dragSpec } />
				</React.Fragment>
			</DragContext.Provider>
		</NetworkContext.Provider>;
	}
}

const MobileBrowserPrompt = () => {
	const { viewState, platform } = getCurrentContext();

	return <div className="view">
		<header>
			<div className="title">Playmaker X</div>
			<div className="actions">
				<a className="button" onClick={ actions.popModal }><span className="icon ok"></span></a>
			</div>
		</header>
		<div className="content scrollable">
			<div className="inner compact">
				<h3>Manage Account &amp; Billing</h3>
				<div className="tile fallbackOption"><span className="icon mobileFallbackLogin"></span><a className="button" onClick={ actions.popModal }><span>Continue to Log In</span></a></div>
			</div>
			<div className="inner compact">
				<h3>Use the App to Design &amp; Print Plays</h3>
				<div className="tile fallbackOption"><span className={ `icon mobileFallbackDownload ${platform.os}` } ></span><a className="button" href={ viewState.config.appStoreUrl }></a></div>
				{ /* <a className="link" href="">Already have the app installed? Tap here.</a> */ }
			</div>
		</div>
	</div>;
};

const Bootstrap = () => {
	const { viewState } = getCurrentContext();
	let modalIndex = 0;
	const modals: any[] = [];
	forEach(filter( viewState.alerts, { mode: store.AlertMode.prompt }), (prompt: store.IAlert) => {
		modals.push(<ModalTransition key={ prompt.id } classNames={ ['prompt'] } index={ modalIndex ++ }><Prompt title={ prompt.title} message={ prompt.message } severity={ prompt.severity } id={ prompt.id } actions={ prompt.actions } /></ModalTransition>);
	});

	return <TransitionGroup component={ null }>
		<Spinner className="appLoading" label={ viewState.bootstrapMessage || '' }/>
		{ modals }
	</TransitionGroup>;
};

class ErrorTrap extends React.Component<Props, any> {

	public static getDerivedStateFromError() {
		return { hasError: true };
	}
	constructor(props) {
		super(props);

		this.state = { hasError: false };
	}

	public componentDidCatch(error, errorInfo) {
		logger.logError(`error [${logger.getErrorMessage(error)}] componentStack [${errorInfo && errorInfo.componentStack}]`, true);
	}

	public render() {
		const { hasError } = this.state;
		const { appState } = this.props;

		if (hasError) {
			const reload = async (e) => {
				// if (this.props.appState.viewState.api.isOnline) {
				// 	try {
				// 		await actions.logout();
				// 	} catch (err) {}
				// }

				viewManager.reloadApp(e);
			};

			return 	<div className="content">
				<div className="view in page dashboard">
					<div className="content scrollable">
						<div className="fallback">
							<h2>{ _s(StringKey.WE_ARE_SORRY) }</h2>
							<p>{ _s(StringKey.ERROR_TRAP_MESSAGE) }</p>
							<p>
								<a className="button basic dark" onClick={ reload }><span className="icon tryAgain"></span><span>{ _s(StringKey.RESTART) }</span></a>
							</p>
							<p className="deemphasized">
								{ _s(StringKey.STILL_HAVING_TROUBLE) } &nbsp; <a className="link underlined" href={ `mailto:support@${window.location.hostname.replace('www.', '')}` }>{ _s(StringKey.CONTACT_SUPPORT) }</a>
							</p>
						</div>
					</div>
				</div>
			</div>;
		} else {
			return appState.viewState.bootstrapping ? <Bootstrap /> : <App { ...this.props } />;
		}
	}
}

bootstrap(config, viewManager.parseQuery()).then(() => {

	function render() {
		const appState = store.appState();

		// passing appState as a prop ensures store mutations trigger re-render
		reactRoot.render(<ErrorTrap appState={ appState } />);
	}

	router.onRouteChange(({current}) => {
		// render();
		store.mutate((appState) => {
			appState.viewState = assign({}, appState.viewState);
			appState.viewState.currentRoute = current;

			if (appState.viewState.mainMenu.expanded) {
				appState.viewState.mainMenu = assign({}, appState.viewState.mainMenu);
				appState.viewState.mainMenu.expanded = false;
			}
		});
	});

	store.on(store.MUTATING, ({ oldState, newState }: { oldState: store.IAppState, newState: store.IAppState }) => {
		// the only thing that triggers a re-render is store mutation - setting context here to be sure it is available for the tooltip manager

		populateContext(newState, async () => {
			// this is called when permissions have been revoked and we need to redirect to the root
			await actions.clearCurrentTeam();
			viewManager.reset();
		});

		processRouteTooltips({ oldState, newState });
	});

	store.on(store.MUTATED, () => {
		render();
	});

	// be sure the context is populated first so that viewManager can use it
	populateContext(store.appState(), viewManager.reset);
	viewManager.init({ MobileBrowserPrompt, ConnectionStatusPrompt });
});

export default App;

import { default as router } from 'playmaker-team-common/dist/client/router';
import * as React from 'react';
import * as actions from '../actions';
import * as store from '../store';
import { _s, StringKey } from '../strings';
import { AccountCreate } from './accountCreate';
import { AlertList } from './alert';
import { ConnectionStatus } from './connectionStatus';
import { InviteModal } from './inviteModal';
import { Login } from './login';
import { MainMenu } from './mainMenu';
import { Page, PageState } from './page';

interface Props {
	alerts?: store.IAlert[];
	appState: store.IAppState;
}

interface State {

}

export class Onboarding extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);
	}

	public createTeam(e?) {
		actions.pushModal({
			component: AccountCreate,
			props: () => ({ appState: store.appState() }),
		});
	}

	public openJoin(e?) {
		actions.pushModal({
			component: InviteModal,
			props: () => ({ viewState: store.appState().viewState }),
		});
	}

	public openLogin(e?) {
		actions.pushModal({
			component: Login,
			props: () => ({ viewState: store.appState().viewState, classNames: ['login'] }),
		});
	}

	public render() {
		const { alerts= [], appState } = this.props;
		const { viewState } = appState;
		const { variant } = viewState.config;

		return <div className="content">
			<MainMenu appState={ appState } />
			<div className="view">
				<svg className="teamBackground">
					<defs>
						<linearGradient id="grad1" x1="0%" y1="100%" x2="50%" y2="60%">
							<stop offset="0%" style={ { stopColor: variant === 'tackle' ? '#009961' : '#0039c9', stopOpacity: 0.8 } } />
							<stop offset="100%" style={ { stopColor: variant === 'tackle' ? '#00998d' : '#0039c9', stopOpacity: 0 } } />
						</linearGradient>
					</defs>
					<rect width="100%" height="100%" fill="url(#grad1)" />
				</svg>
				<header>
					<div className="actions">
						<a className="button" onClick={ actions.toggleMainMenu }><span className={ viewState.mainMenu.expanded ? 'icon close' : 'icon menu' }></span></a>
						<a className="button hide"><span className="icon close"></span></a>
					</div>
					<div className="logo">
						<div className="type playmaker"></div>
						<svg className="mark">
							<path className="markColor" d="M1.29,5.7a.64.64,0,0,0,.63-.64V2.16L17.76,18a.59.59,0,0,0,.44.19.6.6,0,0,0,.45-.19.62.62,0,0,0,0-.89L2.82,1.27h2.9A.64.64,0,0,0,6.35.63.63.63,0,0,0,5.72,0H1.29a.69.69,0,0,0-.24.05A.6.6,0,0,0,.71.39.52.52,0,0,0,.67.6a0,0,0,0,0,0,0V5.06A.64.64,0,0,0,1.29,5.7Z"/>
							<path className="markColor" d="M13.39,8.59a.59.59,0,0,0,.44-.19l6.25-6.24v2.9a.63.63,0,1,0,1.26,0V.63a0,0,0,0,0,0,0,.52.52,0,0,0,0-.21A.6.6,0,0,0,21,.05a.69.69,0,0,0-.24,0H16.28a.63.63,0,0,0-.63.63.64.64,0,0,0,.63.64h2.9L12.94,7.51a.62.62,0,0,0,0,.89A.6.6,0,0,0,13.39,8.59Z"/>
							<path className="markColor" d="M8.11,12.34,3.35,17.1a.62.62,0,0,0,0,.89.6.6,0,0,0,.45.19A.59.59,0,0,0,4.24,18L9,13.24a.63.63,0,0,0,0-.9A.62.62,0,0,0,8.11,12.34Z"/>
							<path className="markColor" d="M7,16.91a.64.64,0,0,0-.63.64A2.54,2.54,0,1,1,3.8,15a.63.63,0,0,0,0-1.26,3.8,3.8,0,1,0,3.79,3.8A.64.64,0,0,0,7,16.91Z"/>
							<path className="markColor" d="M18.2,13.75a.63.63,0,0,0,0,1.26,2.54,2.54,0,1,1-2.53,2.54.63.63,0,1,0-1.26,0,3.8,3.8,0,1,0,3.79-3.8Z"/>
						</svg>
						<div className={ `type ${variant}`}></div>
					</div>
					<div className="actions">
						<ConnectionStatus />
					</div>
				</header>
				<AlertList alerts={ alerts } />
				<div className="content">
					<div className="hud">
						<div className="anim animField">
							<div className="anim animFieldLines">
								<svg className="line group8">
									<line x1="0%" x2="100%" y1="50%" y2="50%"></line>
								</svg>
								<svg className="line group7">
									<line x1="0%" x2="100%" y1="50%" y2="50%"></line>
								</svg>
								<svg className="line group6">
									<line x1="0%" x2="100%" y1="50%" y2="50%"></line>
									<svg className="numbers" y="10px">
										<svg x="0"><g><text>G</text></g></svg>
										<svg x="100%"></svg>
									</svg>
								</svg>
								<svg className="line group5">
									<line x1="0%" x2="100%" y1="50%" y2="50%"></line>
								</svg>
								<svg className="line group4">
									<line x1="0%" x2="100%" y1="50%" y2="50%"></line>
									<svg className="numbers">
										<svg x="0" y="2px"><g><text>1 &nbsp; 0</text><polygon className="up" points="0,0 -5,0 -2.5,-10"/></g></svg>
										<svg x="100%"></svg>
									</svg>
								</svg>
								<svg className="line group3">
									<line x1="0%" x2="100%" y1="50%" y2="50%"></line>
								</svg>
								<svg className="line group2">
									<line x1="0%" x2="100%" y1="50%" y2="50%"></line>
									<svg className="numbers">
										<svg x="0"></svg>
										<svg x="100%"><g><text>2 &nbsp; 0</text><polygon className="up" points="0,0 -5,0 -2.5,-10"/></g></svg>
									</svg>
								</svg>
								<svg className="line middle">
									<line x1="0%" x2="100%" y1="50%" y2="50%"></line>
									<svg className="numbers withTeamName">
										<svg x="0"><g><text className="teamName"></text></g></svg>
										<svg x="100%"></svg>
									</svg>
								</svg>
								<svg className="line group2">
									<line x1="0%" x2="100%" y1="50%" y2="50%"></line>
									<svg className="numbers withTeamName">
										<svg x="0"></svg>
										<svg x="100%"></svg>
									</svg>
								</svg>
								<svg className="line group3">
									<line x1="0%" x2="100%" y1="50%" y2="50%"></line>
								</svg>
								<svg className="line group4">
									<line x1="0%" x2="100%" y1="50%" y2="50%"></line>
									<svg className="numbers">
										<svg x="0"></svg>
										<svg x="100%"><g><text>4 &nbsp; 0</text><polygon className="down" points="0,0 -5,0 -2.5,10"/></g></svg>
									</svg>
								</svg>
								<svg className="line group5">
									<line x1="0%" x2="100%" y1="50%" y2="50%"></line>
								</svg>
								<svg className="line group6">
									<line x1="0%" x2="100%" y1="50%" y2="50%"></line>
									<svg className="numbers">
										<svg x="0"><g><text>5 &nbsp; 0</text></g></svg>
										<svg x="100%"></svg>
									</svg>
								</svg>
								<svg className="line group7">
									<line x1="0%" x2="100%" y1="50%" y2="50%"></line>
								</svg>
								<svg className="line group8">
									<line x1="0%" x2="100%" y1="50%" y2="50%"></line>
								</svg>
							</div>
							<div className="anim animHash middle">
								<div className="hashes">
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
								</div>
								<div className="hashes">
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
								</div>
							</div>
							<div className="anim animHash outside">
								<div className="hashes left">
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
								</div>
								<div className="hashes right">
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
									<div className="hash"></div>
								</div>
							</div>
						</div>

						<div className="anim animSides">

							{ !viewState.currentUserId ? <div className="anim side left">
								<div className="hudGraphic square">
									<div className="foreground">
										<svg className="border">
											<svg>
												<svg className="bracket">
													<g className="base">
														<rect width="140%" height="140%" x="-20%" y="-20%" rx="3" ry="3"></rect>
													</g>
													<g className="rotating">
														<rect width="140%" height="140%" x="-20%" y="-20%" rx="3" ry="3"></rect>
														<line x1="-35%" x2="-25%" y1="50%" y2="50%"></line>
														<line x1="125%" x2="135%" y1="50%" y2="50%"></line>
													</g>
												</svg>
											</svg>
											<rect width="100%" height="100%" rx="3" ry="3"></rect>
										</svg>
										<span className="icon logIn"></span>
										<div className="label"><span className="title">{ _s(StringKey.LOG_IN) }</span></div>
										<a className="button" onClick={ this.openLogin }></a>
									</div>
								</div>
							</div> : null }

							<div className="anim side right">
								<div className="hudGraphic circle">
									<div className="foreground">
										<svg className="border" viewBox="0 0 100 100">
											<svg>
												<svg className="bracket">
													<g className="base">
														<circle cx="50" cy="50" r="70"></circle>
													</g>
													<g className="rotating">
														<circle cx="50" cy="50" r="70"></circle>
														<g className="deltas" width="140" height="140">
															<svg x="50" y="-32">
																<path transform="rotate(180)" d="M.51-3.72,4.65,3.1a.59.59,0,0,1-.2.81A.56.56,0,0,1,4.14,4H-4.14a.59.59,0,0,1-.6-.59.56.56,0,0,1,.09-.31L-.51-3.72a.61.61,0,0,1,.82-.19A.61.61,0,0,1,.51-3.72Z"/>
															</svg>
															<svg x="50" y="132">
																<path transform="translate(0,132%)" d="M.51-3.72,4.65,3.1a.59.59,0,0,1-.2.81A.56.56,0,0,1,4.14,4H-4.14a.59.59,0,0,1-.6-.59.56.56,0,0,1,.09-.31L-.51-3.72a.61.61,0,0,1,.82-.19A.61.61,0,0,1,.51-3.72Z"/>
															</svg>
														</g>
													</g>
												</svg>
											</svg>
											<circle cx="50" cy="50" r="50"/>
										</svg>
										<span className="icon joinTeam"></span>
										<div className="label"><span className="title">{ _s(StringKey.ACCEPT_INVITE) }</span></div>
										<a className="button" onClick={ this.openJoin }></a>
									</div>
								</div>
							</div>
						</div>

						<div className="animCenter">

							<div className="anim">
								<div className="brackets">
									<div className="borderBase"></div>
									<div className="bracket">
										<div></div>
									</div>
									<div className="bracket">
										<div></div>
									</div>
								</div>
								<div className="logo">
									<div className="type playmaker"></div>
									<svg className="mark"><path className="markColor" d="M1.29,5.7a.64.64,0,0,0,.63-.64V2.16L17.76,18a.59.59,0,0,0,.44.19.6.6,0,0,0,.45-.19.62.62,0,0,0,0-.89L2.82,1.27h2.9A.64.64,0,0,0,6.35.63.63.63,0,0,0,5.72,0H1.29a.69.69,0,0,0-.24.05A.6.6,0,0,0,.71.39.52.52,0,0,0,.67.6a0,0,0,0,0,0,0V5.06A.64.64,0,0,0,1.29,5.7Z"></path><path className="markColor" d="M13.39,8.59a.59.59,0,0,0,.44-.19l6.25-6.24v2.9a.63.63,0,1,0,1.26,0V.63a0,0,0,0,0,0,0,.52.52,0,0,0,0-.21A.6.6,0,0,0,21,.05a.69.69,0,0,0-.24,0H16.28a.63.63,0,0,0-.63.63.64.64,0,0,0,.63.64h2.9L12.94,7.51a.62.62,0,0,0,0,.89A.6.6,0,0,0,13.39,8.59Z"></path><path className="markColor" d="M8.11,12.34,3.35,17.1a.62.62,0,0,0,0,.89.6.6,0,0,0,.45.19A.59.59,0,0,0,4.24,18L9,13.24a.63.63,0,0,0,0-.9A.62.62,0,0,0,8.11,12.34Z"></path><path className="markColor" d="M7,16.91a.64.64,0,0,0-.63.64A2.54,2.54,0,1,1,3.8,15a.63.63,0,0,0,0-1.26,3.8,3.8,0,1,0,3.79,3.8A.64.64,0,0,0,7,16.91Z"></path><path className="markColor" d="M18.2,13.75a.63.63,0,0,0,0,1.26,2.54,2.54,0,1,1-2.53,2.54.63.63,0,1,0-1.26,0,3.8,3.8,0,1,0,3.79-3.8Z"></path></svg>
									<div className="type flag"></div>
									<div className="type tackle"></div>
								</div>
							</div>

							<div className="hudGraphic playbook">
								<div className="foreground">
									<div className="animDiagram">
										<svg className="animPlay" viewBox="0 0 220 280">
											<path className="endcap" d="M100 162 110 152 120 162"></path>
											<path className="routeLine1" d="M110 162 83 142 83 32"></path>
											<path className="routeLine2" d="M110 162 83 142 83 98 178 34"></path>
											<path className="routeLine3" d="M110 162 83 142 83 98 20 98"></path>
											<g className="positionDefense"><path d="M100 114 120 134"></path><path d="M120 114 100 134"></path></g>
											<circle className="positionOffenseStart" cx="110" cy="162" r="4"></circle>
											<circle className="positionOffense" cx="110" cy="162" r="12"></circle>
											<g className="ball"><path transform="translate(85,160)" d="M18.82,5.18c-6.6-6.6-16-5.6-17.63-4s-2.61,11,4,17.62,16,5.6,17.62,4S25.43,11.78,18.82,5.18Z"/><path transform="translate(85,160)" d="M16,16,8,8"/></g>
										</svg>
									</div>
									<svg className="border">
										<rect width="100%" height="100%"  rx="3" ry="3" />
									</svg>
									<div className="label">
										<span className="subtitle flagOnly">{ _s(StringKey.FREE_PLAYBOOK) }</span>
										<span className="title">{ _s(StringKey.START_A_FREE_TRIAL) }</span>
									</div>
									<a className="button" onClick={ this.createTeam }></a>
								</div>
							</div>

						</div>

					</div>
				</div>
			</div>
		</div>;
	}
}

import { filter } from 'lodash';
import * as React from 'react';
import { Transition } from 'react-transition-group';
import { IPoint, vectorUtil } from '../models/vector';

interface Props {
	children: any;
	classNames?: string[];
	index?: number;
	offset?: any;
	isDiagramDragging?: boolean;
}

interface State {

}

const MODAL_DRAG_MARGIN = 0;

export class ModalTransition extends React.Component<Props, State> {
	private _container;
	private _view;
	private _viewBaseTop;

	constructor(props) {
		super(props);

		this._container = React.createRef();
		this._view = React.createRef();
	}

	public render() {
		const {children, ...props} = this.props;

		return (<Transition timeout={{ enter: 10, exit: 210 }} appear={ true } { ...props }>{ (state) => {
			let { classNames= [], index = 0, isDiagramDragging = false, offset= {x: 0, y: 0} } = props;

			classNames = filter(classNames, (c) => ['modal', 'in', 'out'].indexOf(c) === -1);
			classNames.unshift('modal');

			// console.log(state);

			if (['entered'].indexOf(state) !== -1) {
				classNames.push('in');
			} else {
				classNames.push('out');
			}

			if (isDiagramDragging) {
				classNames.push('diagramDragging');
			}

			// constrain to container
			if ((offset.x || offset.y) && this._container && this._container.current && this._view && this._view.current) {
				const containerRect = this._container.current.getBoundingClientRect();
				const viewRect = this._view.current.getBoundingClientRect();
				const horizontalMargin = ((containerRect.width - (MODAL_DRAG_MARGIN * 2)) - viewRect.width) / 2;
				const heightDiff = ((containerRect.height - (MODAL_DRAG_MARGIN * 2)) - viewRect.height);

				if (typeof this._viewBaseTop === 'undefined') {
					this._viewBaseTop = (viewRect.top - offset.y);
				}

				const topOffset = (this._viewBaseTop - containerRect.top);

				// console.log(`container height: ${containerRect.height}, view height: ${viewRect.height}`);

				offset.x = vectorUtil.limit(offset.x, -horizontalMargin, horizontalMargin); // assumes the window is centered when not translated
				offset.y = vectorUtil.limit(offset.y, -(topOffset - MODAL_DRAG_MARGIN), heightDiff - topOffset);
			}

			return <div className={ classNames.join(' ') } style={{ zIndex: 200 + index }} ref={ this._container }>
				<div className="window" style={{ transform: `translateX(${offset.x}px) translateY(${offset.y}px)` }}>
					<div className="view" ref={ this._view }>
						<header></header>
						<div className="content">
							{ !!children && React.cloneElement(children as any, props)}
						</div>
					</div>
				</div>
			</div>;
		} }</Transition>);
	}
}

import { map, sortBy } from 'lodash';
import * as React from 'react';
import * as actions from '../actions';
import { current as getCurrentContext } from '../componentContext';
import { IRouteTree } from '../models/routeTree';
import { AlertMode, AlertSeverity, IDragData, IDragSpec } from '../store';
import * as viewManager from '../viewManager';
import { DragDrop, IDragSourceParams, IDropTargetParams } from './dnd';
import { RouteTree } from './routeTree';
import { StringKey, _s } from '../strings';
import { RouteTreeContext } from './routeTreeProvider';

function RouteTreeThumbnail({ 
	className = 'tile routeTree', 
	allowSort,
	routeTree, 
	routeTreeCount,
	mateId,
	onInfoClick, 
	onPointerDown, 
	onTouchStart, 
	onMouseDown,
	onDrop,
	addDropTarget,
	removeDropTarget
}: 
{
	allowSort: boolean,
	className:string, 
	routeTree: IRouteTree, 
	routeTreeCount: number,
	mateId?: string,
	onInfoClick?: any, 
	onPointerDown?: any, 
	onTouchStart?: any, 
	onMouseDown?: any,
	onDrop?: any,
	addDropTarget?: any,
	removeDropTarget?: any,
	// renderSnapshot?: boolean
}) {
	const isComposite = typeof mateId === 'undefined';
	const _dropTarget = React.useRef<HTMLElement>()
	const canDrop = React.useCallback((data: IDragData) => {
		return data.type === 'thumbnail' && data.info.mate && data.info.mate.id !== mateId;
	}, [mateId]);

	const handleDrop = React.useCallback((data: IDragData) => {
		if (onDrop) {
			onDrop(data.info.mate.id, mateId);
		}
	}, [mateId, onDrop])

	const setDropTarget = React.useCallback((el) => {
		if (!addDropTarget) {
			return;
		}

		if (_dropTarget.current) {
			removeDropTarget(_dropTarget.current);
		}

		_dropTarget.current = el;

		if (_dropTarget.current) {
			addDropTarget(_dropTarget.current, canDrop, handleDrop);
		}
	}, [addDropTarget, removeDropTarget]);

	return <div className={ className } ref={ setDropTarget }>
		<div className="inner">
			<a className="button" onClick={ () => viewManager.pushPath(`/playbook/${routeTree.playbookId}/routeTree/${routeTree.phase}/${mateId || ''}`) }></a>
			<div className="thumbnail">
				<RouteTree mateId={ mateId } routeTree={ routeTree } nonInteractive={ true } useRenderLabels={ true } />
			</div>
			<div className="label">{ isComposite? routeTree.label: routeTree.mates[mateId].label }</div>
			<div className="actions">
				{ !isComposite? <a className="button"><span className="badge">{ routeTreeCount }</span></a>: null }
				{ allowSort? <a className="button" onPointerDown={ onPointerDown } onTouchStart={ onTouchStart } onMouseDown={ onMouseDown } style={{ touchAction: 'none' }} ><span className="icon sortItem" ></span></a>: null }
				<a className="button" onClick={ onInfoClick } id={ `routeTree-info.${mateId || ''}` }><span className="icon infoSmall"></span></a>
			</div>
		</div>
	</div>;
}

// function onRouteTreeSaved(routeTree:IRouteTree, isNew: boolean) {
// 	if(isNew) {
// 		viewManager.pushPath(`/playbook/${routeTree.playbookId}/routeTrees/${routeTree.phase}/${routeTree.id}`);
// 	}
// }

export function RouteTreeGallery() {
	const { currentRouteTree, openInfo, handleRouteTreeDrop } = React.useContext(RouteTreeContext);
	const { currentUser, currentPlaybook, playbookPermissions } = getCurrentContext();

	const handleInfoClick = React.useCallback((e) => {
		const parts = e.currentTarget.id.split('.');
		const id = parts[1];

		openInfo({mateId: id});
	}, [openInfo]);

	const handleAddClick = React.useCallback(() => {
		if(currentRouteTree.mates.count < 20) {
			openInfo({isNewRoute: true});
		}
		else {
			const alertId = 'route-limit-alert';
			actions.pushAlert({
				id: alertId,
				title: _s(StringKey.ROUTE_LIMIT_REACHED_ALERT),
				message: _s(StringKey.ROUTE_LIMIT_REACHED_MESSAGE),
				mode: AlertMode.prompt,
				severity: AlertSeverity.info,
				actions: [{
					label: _s(StringKey.OK),
					action: () => actions.deleteAlert(alertId)
				}],
			});
		}
	}, [openInfo]);

	if(!currentRouteTree) {
		return null;
	}

	const thumbnails = map(sortBy(currentRouteTree.mates.values, 'sortIndex'), (mate, index) => {
		const sortIndex = index;
		const lastViewed = false;

		const dragSpec: IDragSpec = {
			containerClassName: (currentUser.profile.backgroundMode === 'light') ? 'light' : '',
			component: () => <RouteTreeThumbnail className={ `tile routeTreeRoute dragging ${currentRouteTree.getClassName()}` } allowSort={ true } mateId={ mate.id } routeTree={ currentRouteTree } routeTreeCount={ sortIndex } />,
			getDragLayout: (touch, currentTarget) => {
				const thumbnailElement = currentTarget.parentElement.parentElement.parentElement;
				const thumnailRect = thumbnailElement.getBoundingClientRect();
				const targetRect = currentTarget.getBoundingClientRect();

				return { x: thumnailRect.width * 0.4, y: targetRect.height * 0.4, width: thumnailRect.width * 0.8, height: thumnailRect.height * 0.8, scrollContainer: thumbnailElement.parentElement.parentElement };
			},
			data: {
				type: 'thumbnail',
				info: {
					mate,
				},
			},
		};

		return <DragDrop key={ mate.id }>{
			(params: IDragSourceParams & IDropTargetParams) => {
				const { activeDragSpec, handleMouseDown, handlePointerDown, handleTouchStart, ...dragProps } = params;
				const classNames = lastViewed ? ['tile routeTreeRoute selected', currentRouteTree.getClassName()] : ['tile routeTreeRoute', currentRouteTree.getClassName()];

				if (activeDragSpec && activeDragSpec.data.info.mate !== mate) {
					const dragSpecSortIndex = mate.sortIndex;
					classNames.push('dropTarget');
					classNames.push(dragSpecSortIndex < sortIndex ? 'bottom' : 'top');
				}

				return <RouteTreeThumbnail
					key={ `${mate.id}.${Date.now()}` }
					allowSort={ playbookPermissions?.canUpdate }
					className={ classNames.join(' ') }
					mateId={ mate.id }
					onInfoClick={ handleInfoClick }
					routeTree={ currentRouteTree }
					routeTreeCount={ sortIndex }
					onDrop={ handleRouteTreeDrop }
					onPointerDown={ handlePointerDown && handlePointerDown.bind(null, dragSpec) }
					onTouchStart={ handleTouchStart && handleTouchStart.bind(null, dragSpec) }
					onMouseDown={ handleMouseDown && handleMouseDown.bind(null, dragSpec) }
					{...dragProps } />;
			}
		}</DragDrop>;

	});

	return <div className="content scrollable">
		<div className="routeTree">
			<RouteTreeThumbnail
				key={ currentPlaybook.id}
				allowSort={ false }
				className={ `tile routeTreeComposite ${currentRouteTree.getClassName()}` }
				onInfoClick={ handleInfoClick }
				routeTree={ currentRouteTree }
				routeTreeCount={ 0 }
			/>
			<div className="tile routeTreeRoute add"><div className="inner"><a className="button" onClick={handleAddClick}></a><span className="icon addRoute"></span><div className="label">{_s(StringKey.NEW_ROUTE)}</div></div></div>
			{ thumbnails }
		</div>
	</div>;
}

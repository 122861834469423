import { assign, find, map } from 'lodash';
import { IPatchOperation, PatchOpType } from 'playmaker-team-common/dist/shared/interfaces';
import * as React from 'react';
import * as actions from '../actions';
import { AnnotationType, default as annotationFactory, IAnnotation } from '../models/annotation';
import { IPlay } from '../models/play';
import { EndCapType, LineStyle } from '../models/routeSection';
import { IPoint, ISize, IVector, vectorUtil } from '../models/vector';
import { _s, StringKey } from '../strings';
import * as viewManager from '../viewManager';
import { DragSource, DropTarget, IDragSourceParams } from './dnd';

// <img src='data:image/svg+xml;utf8,<svg ... > ... </svg>'>

interface Props {
	play: IPlay;
	updateDiagram: (patch: IPatchOperation[]) => void;
}

interface State {

}

class AnnotationButton extends React.Component<any, any> {
	private _dragSpec;

	constructor(props) {
		super(props);

		const { annotationType, updateDiagram } = props;
		const typeName = AnnotationType[annotationType];

		this._dragSpec = {
			component: (prps) => <a className="button" { ... prps }><span className={ `icon annotation${typeName}` }></span></a>,
			data: {
				type: 'annotation',
			},
			dragEnd: (didDrop: boolean, data) => {
				if (didDrop) {
					const fieldBounds = data.info.fieldBounds;
					const yOffset = data.info.yOffset;
					const dropPoint: IPoint = { x: data.clientX - fieldBounds.left, y: data.clientY - fieldBounds.top };
					const loc = vectorUtil.toAbstractPoint(dropPoint, { width: fieldBounds.width, height: fieldBounds.height });
					loc.y -= yOffset;
					// (DR) forcing dashed line style on model after dashed rendering mysteriously stopped working on static ball annotations
					const annotation: IAnnotation = annotationFactory({ color: 4, routeColor: 4, label: annotationType === AnnotationType.Text ? [_s(StringKey.TEXT)] : undefined, loc, subType: annotationType, endCapType: EndCapType.arrow, lineStyle: AnnotationType.Ball? LineStyle.dash: LineStyle.fill  });

					updateDiagram([{ path: `/annotations/${annotation.id}`, op: PatchOpType.add, value: annotation }]);
				}
			},
		};
	}

	public render() {
		const { annotationType } = this.props;
		const typeName = AnnotationType[annotationType];

		return <DragSource>{ (params: IDragSourceParams) => {
			return <a className="button" onPointerDown={ params.handlePointerDown && params.handlePointerDown.bind(this, this._dragSpec) } onTouchStart={ params.handleTouchStart && params.handleTouchStart.bind(this, this._dragSpec) } onMouseDown={ params.handleMouseDown && params.handleMouseDown.bind(this, this._dragSpec) }>
				<span className={ `icon annotation${typeName}` }></span>
			</a>;
		}}</DragSource>;
	}
}

// panel, small
export class PlayPanelAnnotations extends React.Component<Props, State> {

	public render() {
		const { play, updateDiagram } = this.props;
		const animatedBall = find(play.annotations, { subType: AnnotationType.BallAnimatable });

		return <div className="view">
			<header>
				<div className="title">{ _s(StringKey.ANNOTATIONS) }</div>
				<div className="actions">
					<a className="button" onClick={ viewManager.popPanel }><span className="icon ok"></span></a>
				</div>
			</header>
			<div className="content annotations">
				<div className="inner">
					<div>
						<h3>{ _s(StringKey.DRAG_ONTO_FIELD) }</h3>
						<AnnotationButton annotationType={ AnnotationType.Option } updateDiagram={ updateDiagram } />
						<AnnotationButton annotationType={ AnnotationType.Ball } updateDiagram={ updateDiagram } />
						{ animatedBall ? <a className="button disabled"><span className={ `icon annotationBallAnimatable` }></span></a> : <AnnotationButton annotationType={ AnnotationType.BallAnimatable } updateDiagram={ updateDiagram } /> }
						<AnnotationButton annotationType={ AnnotationType.Text } updateDiagram={ updateDiagram } />
					</div>
				</div>
			</div>
			<a className="button" onClick={ viewManager.popPanel }><span className="icon collapse"></span></a>
		</div>;
	}
}

import * as React from 'react';

export function Lines65x150({}) {
	return <div className="field">
		<svg viewBox="0 0 1024 2304">
			<svg className="markings" x="0%" y="0%" width="100%" height="100%">
				<svg className="tenYards" x="0%" y="0%" height="6.645%">
					<line className="yardline" x1="0" x2="100%" y1="50%" y2="50%" />
					<line className="yardline" x1="0" x2="100%" y1="100%" y2="100%" />
				</svg>
				<svg className="tenYards" x="0%" y="6.645%" height="6.67%">
					<line className="yardline" x1="0" x2="100%" y1="50%" y2="50%" />
					<line className="yardline" x1="0" x2="100%" y1="100%" y2="100%" />
				</svg>
				<svg className="tenYards" x="0%" y="13.315%" height="6.67%">
					<line className="yardline" x1="0" x2="100%" y1="50%" y2="50%" />
					<line className="yardline" x1="0" x2="100%" y1="100%" y2="100%" />
				</svg>
				<svg className="tenYards" x="0%" y="19.985%" height="6.67%">
					<line className="yardline" x1="0" x2="100%" y1="50%" y2="50%" />
					<line className="yardline" x1="0" x2="100%" y1="100%" y2="100%" />
				</svg>
				<svg className="tenYards" x="0%" y="26.655%" height="6.67%">
					<line className="yardline" x1="0" x2="100%" y1="50%" y2="50%" />
					<line className="yardline" x1="0" x2="100%" y1="100%" y2="100%" />
				</svg>
				<svg className="tenYards" x="0%" y="33.325%" height="6.67%">
					<line className="yardline" x1="0" x2="100%" y1="50%" y2="50%" />
					<line className="yardline" x1="0" x2="100%" y1="100%" y2="100%" />
				</svg>
				<svg className="tenYards" x="0%" y="39.995%" height="6.67%">
					<line className="yardline" x1="0" x2="100%" y1="50%" y2="50%" />
					<line className="yardline" x1="0" x2="100%" y1="100%" y2="100%" />
				</svg>
				<svg className="tenYards" x="0%" y="46.665%" height="3.335%">
					<line className="yardline" x1="0" x2="100%" y1="100%" y2="100%" />
				</svg>
				<svg className="tenYards" x="0%" y="50%" height="3.335%">
					<line className="yardline" x1="0" x2="100%" y1="100%" y2="100%" />
				</svg>
				<svg className="tenYards" x="0%" y="53.335%" height="6.67%">
					<line className="yardline" x1="0" x2="100%" y1="50%" y2="50%" />
					<line className="yardline" x1="0" x2="100%" y1="100%" y2="100%" />
				</svg>
				<svg className="tenYards" x="0%" y="60.005%" height="6.67%">
					<line className="yardline" x1="0" x2="100%" y1="50%" y2="50%" />
					<line className="yardline" x1="0" x2="100%" y1="100%" y2="100%" />
				</svg>
				<svg className="tenYards" x="0%" y="66.675%" height="6.67%">
					<line className="yardline" x1="0" x2="100%" y1="50%" y2="50%" />
					<line className="yardline" x1="0" x2="100%" y1="100%" y2="100%" />
				</svg>
				<svg className="tenYards" x="0%" y="73.345%" height="6.67%">
					<line className="yardline" x1="0" x2="100%" y1="50%" y2="50%" />
					<line className="yardline" x1="0" x2="100%" y1="100%" y2="100%" />
				</svg>
				<svg className="tenYards" x="0%" y="80.015%" height="6.67%">
					<line className="yardline" x1="0" x2="100%" y1="50%" y2="50%" />
					<line className="yardline" x1="0" x2="100%" y1="100%" y2="100%" />
				</svg>
				<svg className="tenYards" x="0%" y="86.685%" height="6.67%">
					<line className="yardline" x1="0" x2="100%" y1="50%" y2="50%" />
					<line className="yardline" x1="0" x2="100%" y1="100%" y2="100%" />
				</svg>
				<svg className="endzone" x="0%" y="93.355%" height="6.645%">
					<line className="yardline" x1="0" x2="100%" y1="50%" y2="50%" />
				</svg>
			</svg>
		</svg>
	</div>;
}

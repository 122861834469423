import { assign } from 'lodash';
import { createId } from 'playmaker-team-common/dist/shared/createId';
import { createFactory } from 'playmaker-team-common/dist/shared/modelFactory';
import { default as validator } from 'playmaker-team-common/dist/shared/validator';
import { default as valueFilters } from 'playmaker-team-common/dist/shared/valueFilters';
import { stripDashes } from './coreModel';
import { GamePhase } from './diagramModel';
import { default as sortableModel, ISortable } from './sortableModel';

export interface ITag extends ISortable {
	id?: string;
	color?: number;
	label: string;
	note: string;
	phase: GamePhase;
}

export default createFactory<ITag>('Tag', assign({}, sortableModel, {
	id: {
		writable: false,
		defaultValue: () => {
			return stripDashes(createId());
		},
	},
	color: {
		setFilters: [valueFilters.toNumber]
	},
	label: {
		rules: validator.required(),
	},
	note: {
	},
	phase: {

	},
}));

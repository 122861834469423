import { difference, filter, find, first, forEach, map, sortBy } from 'lodash';
import * as React from 'react';
import * as actions from '../actions';
import { current as getCurrentContext } from '../componentContext';
import { GamePhase } from '../models/diagramModel';
import { IFormation } from '../models/formation';
import { default as playFactory, IPlay } from '../models/play';
import { default as playbookFactory, IPlaybook } from '../models/playbook';
import { IPlayList } from '../models/playList';
import { ISortable } from '../models/sortableModel';
import { ITag } from '../models/tag';
import { default as teamFactory } from '../models/team';
import * as store from '../store';
import { _s, StringKey } from '../strings';
import * as viewManager from '../viewManager';
import { AlertList } from './alert';
import { CategoryButtonList, getUncategorizedCategory } from './categoryButtonList';
import { ConfirmModal } from './confirmModal';
import { ConnectionStatus } from './connectionStatus';
import { DeleteConfirmModal } from './deleteConfirmModal';
import { FormationButtonList } from './formationButtonList';
import { Page, PageState } from './page';
import { PhaseTabs } from './phaseTabs';
import { PlaybookEdit } from './playbookEdit';
import { PlayGallery } from './playGallery';
import { PlayInfoModal } from './playInfo';
import { PlayList } from './playList';
import { PrintModal } from './print';
import { Spinner } from './spinner';
import { PlayFilterContext } from './playFilterProvider';
import { HelpButton } from './helpButton';
import { SaveBatchIntent } from '../apiGateway';

interface Props {
	alerts?: store.IAlert[];
	appState: store.IAppState;
	isVisible: boolean;
}

interface State {
	hasMounted: boolean;
	processing?: boolean;
	processingLabel?: string;
}

const SHOW_AS_LIST_PLAYER_THRESHOLD = 120 * 11;

export const PlaybookPlays = React.memo(({ alerts }: Props) => {
	const [state, setState] = React.useState<State>({ hasMounted: false });
	const playFilterContext = React.useContext(PlayFilterContext);
	
	// didMount
	React.useEffect(() => {
		const { currentPlaybook, model, viewState } = getCurrentContext();

		if (currentPlaybook && !viewState.showPlaysAsList) {
			const plays = filter(model.plays, { playbookId: currentPlaybook?.id, phase: playFilterContext.phase });
			const playersPerSide = currentPlaybook?.playersPerSide || 0;

			if ((plays.length * playersPerSide) > SHOW_AS_LIST_PLAYER_THRESHOLD) {
				actions.toggleShowPlaysAsList();
			}
		}

		setTimeout(() => {
			setState({ ...state, hasMounted: true });
		}, 220);

	}, []);

	async function savePlaybook(playbook: IPlaybook, intent: SaveBatchIntent) {
		const { currentTeam, playbookPermissions } = getCurrentContext();
		const existingPlaybook = currentTeam.playbooks[playbook.id];
		const mutatedTeam = teamFactory(currentTeam);

		if (!playbookPermissions.canUpdate) {
			return;
		}

		if (!existingPlaybook) {
			mutatedTeam.playbooks.add(playbook);
		} else {
			mutatedTeam.playbooks[playbook.id] = playbook;
		}

		if (actions.validateTeam(mutatedTeam)) {
			await actions.saveTeam(mutatedTeam, intent);
			viewManager.popModal();
		}
	}

	function archivePlaybook(playbook: IPlaybook, onComplete: () => void) {
		const modalId = `archive-${playbook.id}`;
		const doArchive = async () => {
			actions.deleteModal(modalId);

			const success = await actions.archivePlaybook(playbook);

			if (onComplete) {
				onComplete();
			}

			if (success) {
				actions.popModal();
				viewManager.popReplace();
			}
		};

		actions.pushModal({
			id: modalId,
			component: ConfirmModal,
			props: () => {
				return {
					classNames: ['prompt'],
					title: _s(StringKey.ARCHIVE_PLAYBOOK),
					message: _s(StringKey.ARCHIVED_PLAYBOOKS_NOT_VISIBLE_MESSAGE),
					confirmAction: {
						label: _s(StringKey.ARCHIVE_PLAYBOOK),
						action: doArchive,
					},
					onCancelClick: () => {
						if (onComplete) {
							onComplete();
						}
						actions.deleteModal(modalId);
					},
				};
			},
		});
	}

	function deletePlaybook(playbook: IPlaybook) {
		const modalId = `delete-${playbook.id}`;
		const { currentTeam, teamPermissions } = getCurrentContext();

		if (!teamPermissions.canRemovePlaybooks) {
			return;
		}

		const deleteAction = async () => {
			actions.deleteModal(modalId);

			const mutatedTeam = teamFactory(currentTeam);

			mutatedTeam.playbooks.remove(playbook.id);

			if (actions.validateTeam(mutatedTeam)) {
				actions.saveTeam(mutatedTeam, `playbook-delete-${mutatedTeam.id}`);
				actions.popModal();
				viewManager.popReplace();
			}
		};

		actions.pushModal({
			id: modalId,
			component: DeleteConfirmModal,
			props: () => {
				return {
					classNames: ['prompt'],
					title: _s(StringKey.DELETE_PLAYBOOK_QUESTION),
					message: _s(StringKey.DELETE_PLAYBOOK_PROMPT_MESSAGE),
					deleteAction: {
						label: _s(StringKey.DELETE_PLAYBOOK),
						className: 'delete',
						action: deleteAction,
					},
					onCancelClick: () => actions.deleteModal(modalId),
				};
			},
		});
	}

	function openInfo(playId: string) {
		const { phase } = playFilterContext;
		const { currentPlaybook: playbook, model } = getCurrentContext();
		const formations = filter(model.formations, { playbookId: playbook.id, phase });
		const play = model.plays[playId];

		viewManager.pushModal({
			component: PlayInfoModal,
			props: () => {
				return {
					categories: filter(playbook.settings.categories.values, { phase }),
					formations,
					play,
					isNew: false,
					savePlay: savePlay,
				};
			},
			supportsAlerts: true,
			screenName: 'PlayInfo',
		});
	}

	async function savePlay(play: IPlay & ISortable, onComplete: () => void) {
		const { activeCategory, activeFormation, phase } = playFilterContext;
		const {currentPlaybook: playbook, model, playbookPermissions } = getCurrentContext();
		const existingPlay: IPlay = model.plays[play.id];
		const playListId = (activeFormation && activeFormation.id) || (activeCategory && activeCategory.id);

		play.playbookId = playbook.id;
		play.teamId = playbook.teamId;

		if (!playbookPermissions.canUpdate) {
			onComplete();
			return;
		}

		if (!existingPlay) {

			play.sortIndex = filter(model.plays, { playbookId: playbook.id, phase }).length;

			await actions.addPlay(play);

			if (playListId) {
				const mutatedPlaybook = playbookFactory(playbook);
				const playList = mutatedPlaybook.getPlayList(playListId, true);

				playList.push(play.id);

				await savePlaybook(mutatedPlaybook, `playbook-update-addtoplaylist-${mutatedPlaybook.teamId}`);
			}

			onComplete();
			await actions.popModal();
			setTimeout(() => viewManager.pushPath(`/playbook/${playbook.id}/plays/${play.id}`), 60);

		} else {
			const patch = play.getPatch(existingPlay);
			const mutatedPlaybook = playbookFactory(playbook);

			await actions.savePlay(play);

			if (existingPlay.formation !== play.formation) {
				const existingFormation = find(model.formations, { playbookId: playbook.id, phase, label: existingPlay.formation });
				const updatedFormation = find(model.formations, { playbookId: playbook.id, phase, label: play.formation });
				const existingPlayList: IPlayList = existingFormation && mutatedPlaybook.getPlayList(existingFormation.id);
				const updatedPlayList = updatedFormation && mutatedPlaybook.getPlayList(updatedFormation.id);

				if (existingPlayList) {
					existingPlayList.remove(play.id);
				}

				if (updatedPlayList) {
					updatedPlayList.push(play.id);
				}
			}

			if (find(patch, (op) => op.path.indexOf('categories') === 0)) {
				const existingCategories = play.categoryList;
				const updatedCategories = play.categoryList;
				const removed = difference(existingCategories, updatedCategories);
				const added = difference(updatedCategories, existingCategories);

				forEach(removed, (id) => {
					const playList: IPlayList = mutatedPlaybook.getPlayList(id);

					if (playList) {
						playList.remove(id);
					}
				});

				forEach(added, (id) => {
					const playList: IPlayList = mutatedPlaybook.getPlayList(id);

					if (playList) {
						playList.push(id);
					}
				});
			}

			await savePlaybook(mutatedPlaybook, `playbook-update-updateplaylist-${mutatedPlaybook.teamId}`);

			onComplete();
			viewManager.popModal();
		}
	}

	function updateSelectedItems(updatedItems: string[]) {
		playFilterContext.setSelectedItems(updatedItems);
	}

	async function copyPlaysTo(selectedPlays: IPlay[], playbooks: IPlaybook[]) {
		const { processing } = state;
		const alertId = 'copy-plays-to';

		if (selectedPlays.length && !processing) {

			setState({...state, processing: true});

			await actions.copyPlays(selectedPlays, playbooks);
			actions.pushAlert({
				id: alertId,
				title: _s(StringKey.COPY_COMPLETE),
				message: _s(StringKey.PLAYS_COPIED),
				mode: store.AlertMode.prompt,
				severity: store.AlertSeverity.info,
				actions: [{
					label: _s(StringKey.OK),
					action: () => { actions.deleteAlert(alertId); },
				}],
			});

			playFilterContext.setSelectedItems([]);

			setTimeout(() => {
				setState({ ...state, processing: false });
			}, 10);
		}
	}

	async function deletePlays(selectedPlays: IPlay[]) {
		const { processing } = state;

		if (selectedPlays.length && !processing) {
			setState( { ...state, processing: true});

			await actions.deletePlays(selectedPlays);

			playFilterContext.setSelectedItems([]);

			setTimeout(() => {
				setState({ ...state, processing: false });
			}, 10);
		}
	}

	function handleAddClick() {
		const { currentPlaybook: playbook, currentTeam, model, playbookPermissions } = getCurrentContext();
		const { activeCategory, activeFormation, mode, phase } = playFilterContext;
		const formations = sortBy(filter(model.formations, { playbookId: playbook.id, phase }), 'sortIndex');
		const plays = filter(model.plays, { playbookId: playbook.id, phase });
		const initialPersonnelGroup = first(sortBy(filter(currentTeam.settings.personnelGroups.values, { phase, playersPerSide: playbook.playersPerSide}), 'sortIndex'));
		const initialFormation = activeFormation || (formations.length ? formations[0] : undefined);
		const uncategorizedCategory = getUncategorizedCategory();

		if (!playbookPermissions.canUpdate) {
			return;
		}

		const play = playFactory({
			activePersonnelGroup: initialPersonnelGroup && initialPersonnelGroup.id,
			label: `${_s(StringKey.PLAY)} ${plays.length + 1}`,
			categories: activeCategory && mode === 'categories' && activeCategory.id !== uncategorizedCategory.id ? activeCategory.id : undefined,
			ballLocation: initialFormation && initialFormation.ballLocation.toDocument(),
			formation: initialFormation && initialFormation.label,
			posture: initialFormation && initialFormation.posture,
			containerOffset: initialFormation?.containerOffset,
			phase,
			unit: initialFormation && initialFormation.unit,
			playersPerSide: playbook.playersPerSide,
			fieldKey: playbook.settings.fieldKey,
			playbookId: playbook.id,
		});

		play.annotations = initialFormation && initialFormation.annotations.clone();
		play.mates = initialFormation && initialFormation.mates.clone();

		// support for position notes on formations requires that we clear them here
		for(const mate of play.mates.values) {
			mate.note = '';
		}

		viewManager.pushModal({
			component: PlayInfoModal,
			props: () => {
				const { viewState } = getCurrentContext();
				return {
					canAccessApi: viewState.api.canAccessApi,
					categories: filter(playbook.settings.categories.values, { phase }),
					formations,
					play,
					isNew: true,
					savePlay,
				};
			},
			supportsAlerts: true,
			screenName: 'PlayInfo',
		});
	}


	async function handleItemDrop(sourceId: string, targetId: string) {
		const { playlistId } = playFilterContext;
		const { currentPlaybook: playbook, model, playbookPermissions } = getCurrentContext();
		const mutatedPlaybook = playbookFactory(playbook);
		const playList = mutatedPlaybook.getPlayList(playlistId, true);
		const source = model.plays[sourceId];
		const target = model.plays[targetId];

		if (!playbookPermissions.canUpdate) {
			return;
		}

		actions.setLastViewedPlayId('');

		if (playList) {
			// get the currently visible list of plays
			const sortedPlays = playFilterContext.getPlays();
			const sourceIndex = sortedPlays.indexOf(source);
			const targetIndex = sortedPlays.indexOf(target);

			if (sourceIndex !== targetIndex ) {
				sortedPlays.splice(sourceIndex, 1);
				sortedPlays.splice(targetIndex, 0, source);
			}

			playList.playIds = map(sortedPlays, (p) => p.id).join(',');

			await savePlaybook(mutatedPlaybook, `play-sort-${mutatedPlaybook.teamId}`);
		} else {
			// sort the plays themselves
			const plays = filter(model.plays, { playbookId: playbook.id });
			const targetSortIndex = target.sortIndex;
			let index = 0;
			const mutatedPlays: IPlay[] = [];
			const sortedMap = map(plays, (play) => {
				const sortIndex = play === source ? (source.sortIndex > targetSortIndex) ? targetSortIndex - 0.5 : targetSortIndex + 0.5 : play.sortIndex;

				return { id: play.id, sortIndex };
			});

			forEach(sortBy(sortedMap, 'sortIndex'), (item) => {
				const play = model.plays[item.id];

				if (play.sortIndex !== index) {
					const mutated = playFactory(play.toDocument());

					mutated.sortIndex = index;
					mutatedPlays.push(mutated);
				}

				index++;

			});

			await actions.savePlays(mutatedPlays);
		}

		actions.setLastViewedPlayId(sourceId);
	}

	const handleFormationEditClick = () => {
		const { currentPlaybook, variant, playbookPermissions } = getCurrentContext();
		const { phase } = playFilterContext;
		const templatePage = (variant === 'flag' && !playbookPermissions.canUpdate)? 'routetree': 'formations'

		viewManager.pushPath(`/playbook/${currentPlaybook.id}/templates/${phase}/${templatePage}`);
	}

	const handleInfoClick = () => {
		const { currentPlaybook, playbookPermissions } = getCurrentContext();

		if (!playbookPermissions.canUpdate) {
			return;
		}

		actions.pushModal({
			component: PlaybookEdit,
			props: () => ({ playbook: currentPlaybook, archivePlaybook, savePlaybook: (pb:IPlaybook) => savePlaybook(pb, `playbook-update-${pb.teamId}`), deletePlaybook }),
		});
	}

	const handlePrintClick = () => {
		actions.pushModal({
			component: PrintModal,
			props: () => ({ appState: store.appState() }),
		});
	}


	const handlePhaseChange = (phase: GamePhase) => {
		playFilterContext.setPhase(phase);
	}

	const handleCategoryClick = (category: ITag) => {
		playFilterContext.setActiveCategory(category);
	}

	const handleAllCategoriesClick = () => {
		playFilterContext.setActiveCategory(undefined);
	}

	const handleFormationClick = (formation: IFormation) => {
		playFilterContext.setActiveFormation(formation);
	}

	const handleAllFormationsClick = () => {
		playFilterContext.setActiveFormation(undefined);
	}

	const handleExpanderClick = (mode: string) => {
		playFilterContext.setMode(mode);
	}


	const { currentPlaybook: playbook, model, playbookPermissions, variant, viewState } = getCurrentContext();
	const { activeFormation, activeCategory, mode, phase, selectedItems } = playFilterContext;
	const { hasMounted, processing, processingLabel } = state;
	const phaseFormations = filter(model.formations, { phase, playbookId: playbook.id });
	const phasePlays = filter(model.plays, { phase, playbookId: playbook.id });
	const phaseLabel = phase === GamePhase.Offense ? _s(StringKey.ALL_OFFENSIVE_PLAYS) : phase === GamePhase.Defense ? _s(StringKey.ALL_DEFENSIVE_PLAYS) : _s(StringKey.ALL_SPECIAL_TEAMS_PLAYS);
	const addLabel = phase === GamePhase.Offense ? _s(StringKey.NEW_OFFENSIVE_PLAY) : phase === GamePhase.Defense ? _s(StringKey.NEW_DEFENSIVE_PLAY) : _s(StringKey.NEW_SPECIAL_TEAMS_PLAY);
	const filteredPlays = playFilterContext.getPlays();
	const fieldOptions = find(viewState.settings.fieldOptions, { key: playbook.settings.fieldKey });
	const lastViewedPlayId =  viewState.lastViewedPlayId;

	return <React.Fragment>
		{ processing ? <Spinner label={ processingLabel } /> : null }
		<header>
			<div className="actions">
				<a className="button" onClick={ viewManager.popPath }><span className="icon back"></span><span>{ playbook.name }</span></a>
			</div>
			<div className="actions">
				{ !playbookPermissions.canUpdate ? null : <a className="button" onClick={ handleInfoClick }><span className="icon info"></span></a> }
				{ variant === 'flag' && phase !== GamePhase.Offense && !playbookPermissions.canUpdate ? null : <a className="button" onClick={ handleFormationEditClick }><span className="icon templates"></span></a> }
				<a className={ `button ${viewState.showPlaysAsList ? 'on' : ''}` } onClick={ actions.toggleShowPlaysAsList }><span className="icon listView"></span></a>
				<a className="button" onClick={ handlePrintClick }><span className="icon print"></span></a>
			</div>
			<div className="actions">
				<HelpButton flagUrl="https://support.wearetrue.com/hc/en-us/categories/360002082751-Flag-Football-Playmaker-X" tackleUrl="https://support.wearetrue.com/hc/en-us/categories/360002071872-Tackle-Football-Playmaker-X" />
				<ConnectionStatus />
			</div>
		</header>
		<AlertList alerts={ alerts } />
		<div className="content">
			<div className="view sidebar">
				<header>
					<PhaseTabs activePhase={ phase } onPhaseChange={ handlePhaseChange } variant={ variant } />
				</header>
				<div className="content">
					<div className={ `group tackleOnly${mode !== 'formations' ? ' collapsed' : ''}` }>
						<header>
							<div className="actions">
								<a className="button formationsButton" onClick={ variant === 'flag' ? null : () => handleExpanderClick('formations') }><span>{ _s(StringKey.FORMATIONS) }</span></a>
								{ playbookPermissions.canUpdate ? <a className="button" onClick={ () => viewManager.pushPath(`/playbook/${playbook.id}/templates/${phase}/formations` ) } id={ mode === 'formations' ? 'tooltip-target-playbookCustomizeFormations' : null }><span className="icon edit"></span></a> : null }
							</div>
						</header>
						<FormationButtonList
							activeFormations={ activeFormation ? [activeFormation.id] : [] }
							allFormationsActive={ false }
							formations={ phaseFormations }
							plays={ phasePlays }
							onFormationClick={ handleFormationClick }
							onAllFormationsClick={ handleAllFormationsClick } />
					</div>
					<div className={ `group${mode !== 'categories' ? ' collapsed' : ''}` }>
						<header>
							<div className="actions">
								<a className="button" onClick={ variant === 'flag' ? null : () => handleExpanderClick('categories') }><span>{ _s(StringKey.CATEGORIES) }</span></a>
								{ playbookPermissions.canUpdate ? <a className="button" onClick={ () => viewManager.pushPath(`/playbook/${playbook.id}/categories/${phase}` ) } id={ mode === 'categories' ? 'tooltip-target-playbookCustomizeCategories' : null }><span className="icon edit"></span></a> : null }
							</div>
						</header>
						<CategoryButtonList
							activeCategories={  activeCategory ? [activeCategory.id] : [] }
							allCategoriesLabel={ variant === 'flag' ? phaseLabel : null }
							allCategoriesActive={ !activeCategory }
							categories={ filter(playbook.settings.categories.values, (c) => c.phase === phase ).concat(getUncategorizedCategory()) }
							plays={ phasePlays }
							onCategoryClick={ handleCategoryClick }
							onAllCategoriesClick={ handleAllCategoriesClick } />
					</div>
				</div>
			</div>
			<div className="view">
				<div className="content swappable">

					<Page pageState={ viewState.showPlaysAsList ? PageState.OUT : PageState.IN } alwaysRender={ true }>
						{ !hasMounted ? <div className="view in"><div className="content scrollable"><div className="plays">{ phasePlays.length > 30 ? <Spinner noShade={ true } /> : null }</div></div></div> : <PlayGallery
							addLabel={ addLabel }
							lastViewedPlayId={ lastViewedPlayId }
							onAddClick={ handleAddClick }
							openInfo={ openInfo }
							onItemDrop={ handleItemDrop }
							plays={ filteredPlays }
							fieldOptions={ fieldOptions } />
						}
					</Page>

					<Page pageState={ viewState.showPlaysAsList ? PageState.IN : PageState.OUT } alwaysRender={ true }>
						<PlayList
							addLabel={ addLabel }
							copyPlaysTo={ copyPlaysTo }
							deletePlays={ deletePlays }
							lastViewedPlayId={ lastViewedPlayId }
							onAddClick={ handleAddClick }
							openInfo={ openInfo }
							onItemDrop={ handleItemDrop }
							plays={ filteredPlays }
							selectedItems={ selectedItems }
							updateSelectedItems={ updateSelectedItems } />
					</Page>

				</div>
			</div>
		</div>
		<footer>
			<div className="actions">
				<a className="button" onClick={ handleInfoClick }><span className="icon info"></span></a>
				{ variant === 'flag' && phase !== GamePhase.Offense && !playbookPermissions.canUpdate ? null : <a className="button" onClick={ handleFormationEditClick }><span className="icon templates"></span></a> }
				<a className={ `button ${viewState.showPlaysAsList ? 'on' : ''}` } onClick={ actions.toggleShowPlaysAsList }><span className="icon listView"></span></a>
				<a className="button" onClick={ handlePrintClick }><span className="icon print"></span></a>
			</div>
		</footer>
	</React.Fragment>;

}, (prevProps, nextProps) => { // shouldComponentUpdate: true = skip
	return !prevProps.isVisible && !nextProps.isVisible
});
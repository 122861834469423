import { channelProvider, IMessageChannel, IMessageChannelMessage } from 'playmaker-team-common/dist/client/messageChannel';
import { Emitter } from 'playmaker-team-common/dist/shared/emitter';

const _emitter = new Emitter();
let _nativeBridgeChannel: IMessageChannel;
let _metadata;
let _channelPromise;

export async function init(scriptVersion: string) {
	_channelPromise = channelProvider({ type: 'native', scriptVersion: '1.0.0'})({ name: 'nativeBridge'});
	const channel = await _channelPromise;

	if (channel && channel.metadata) {
		_nativeBridgeChannel = channel;
		_metadata = channel.metadata;

		_nativeBridgeChannel.on('message', (message: IMessageChannelMessage) => {
			_emitter.trigger(message.type, message.payload);
		});
	}

	return !!_nativeBridgeChannel;
}

export async function waitForChannel(): Promise<boolean> {
	await _channelPromise;

	return !!_nativeBridgeChannel;
}

export function getMetadata() {
	return _metadata;
}

export function off(handler: Function) {
	_emitter.off(handler);
}

export function on(message: string, handler: Function) {
	_emitter.on(message, handler);
}

export function isConnected() {
	return !!_nativeBridgeChannel;
}

export function print() {
	_send({
		payload: {},
		rsvp: false,
		type: 'app.print',
	});
}

export function browseTo(url: string) {
	_send({
		payload: { url },
		rsvp: false,
		type: 'app.loadexternal',
	});
}

export function notifyBootstrapped() {
	_send({
		payload: {},
		rsvp: false,
		type: 'app.bootstrapped',
	});
}

export async function fetchIapProducts(productIds: string[]) {
	return await _send({
		payload: { productIds },
		rsvp: true,
		type: 'app.iap.fetch',
	});
}

export async function fetchOrphanedIaps() {
	return await _send({
		payload: { },
		rsvp: true,
		type: 'app.iap.fetch.orphaned',
	});
}

export async function makeIap(productId: string, teamId: string) {
	return await _send({
		payload: { productId, teamId },
		rsvp: true,
		type: 'app.iap.purchase',
	});
}

export async function finishIap(transactionId: string) {
	return await _send({
		payload: { transactionId },
		rsvp: true,
		type: 'app.iap.finish',
	});
}

export async function debug(text: string) {
	return await _send({
		payload: { text },
		rsvp: false,
		type: 'app.debug',
	});
}

async function _send(message: IMessageChannelMessage) {
	let result;

	if (_nativeBridgeChannel) {
		result = await _nativeBridgeChannel.send(message);
	}

	return result;
}

import * as React from 'react';
import { StringKey, _s } from "../strings";
import valueFilters from "playmaker-team-common/dist/shared/valueFilters";
import { default as routeTreeFactory, IRouteTree } from "../models/routeTree";
import * as viewManager from '../viewManager';
import * as actions from '../actions';
import { IPatchOperation, PatchOpType } from '../models/jsonPatch';
import { default as playerFactory, IPlayer, PlayerShading } from '../models/player';
import { AlertList } from './alert';
import { IAlert, AlertMode, AlertSeverity } from '../store';
import { EndCapType, LineStyle } from '../models/routeSection';
import { Spinner } from './spinner';

type SaveRouteTreeInfoCallback = (routeTree: IRouteTree, immediate?: boolean) => void;
type SaveRouteTreeModalCallback = (routeTree: IRouteTree, onComplete?: () => void) => void;
type DeleteRouteCallback = (mateId: string, onComplete?: () => void) => void;

function RouteInfoViewer({ note }: { note: string}) {
	return <div className="content scrollable">
		<div className="inner">
			<div className="notes readOnly">
				<div className="generalNote">
					{ note || _s(StringKey.NO_NOTES) }
				</div>
			</div>
		</div>
	</div>;
}

function EditRouteInfo({ routeTree, mateId, onChange, onDelete }: { routeTree: IRouteTree, mateId?: string, onChange: (patchOp: IPatchOperation) => void, onDelete?: () => void }) {
	const mate = typeof mateId === 'undefined'? null: routeTree.mates[mateId];
	const handleChange = React.useCallback((path, e) => {
		const newVal = e.type === 'blur' ? valueFilters.clean(e.target.value) : e.target.value;

		if(path === 'label') {
			onChange({op: PatchOpType.replace, path: mate? `/mates/${mateId}/label`: '', value: newVal });
		}
		else if(path === 'note') {
			onChange({op: PatchOpType.replace, path: mate? `/mates/${mateId}/note`: '/notes', value: newVal });
		}

	}, [routeTree, mateId]);
	const handleDelete = React.useCallback(() => {
		const alertId = `delete-route-${mateId}`;
		const deleteAction = () => {
			actions.deleteAlert(alertId);
			onDelete();
		};

		const cancelAction = () => {
			actions.deleteAlert(alertId);
		};

		actions.pushAlert({
			id: alertId,
			title: _s(StringKey.DELETE_ROUTE_QUESTION),
			message: _s(StringKey.DELETE_ROUTE_PROMPT_MESSAGE),
			mode: AlertMode.prompt,
			severity: AlertSeverity.confirmation,
			actions: [{
				label: _s(StringKey.DELETE_ROUTE),
				className: 'delete',
				action: deleteAction,
			}, {
				label: _s(StringKey.CANCEL),
				className: 'cancel',
				action: cancelAction,
			}],
		});
	}, [onDelete]);

	return <div className="content">
		<div className="inner">
			{ mate? <input type="text" className={ mate.isValid('label')? '': 'invalid'} placeholder={ _s(StringKey.ROUTE_NAME) } value={ mate.viewValue('label') } onChange={ e => handleChange('label', e) } onBlur={ e => handleChange('label', e) } />: null }
			<textarea placeholder={ mate? _s(StringKey.OPTIONAL_ROUTE_NOTES): _s(StringKey.OPTIONAL_ROUTE_TREE_NOTES) } onChange={ e => handleChange('note', e) } onBlur={ e => handleChange('note', e) } value={ mate? mate.viewValue('note'): routeTree.viewValue('notes') } />
			{ mateId && onDelete? <div className="actions"><a className="button basic delete" onClick={handleDelete}><span>{_s(StringKey.DELETE)}</span></a></div>: null }
		</div>
	</div>;
}

export function RouteTreeInfoModal({ routeTree, mateId, isNewRoute, saveRouteTree, deleteRoute }: { routeTree: IRouteTree, mateId?: string, isNewRoute?: boolean, saveRouteTree?: SaveRouteTreeModalCallback, deleteRoute: DeleteRouteCallback }) {
	const isRoute = !!mateId || isNewRoute;
	const title = !saveRouteTree ? isRoute? _s(StringKey.ROUTE): _s(StringKey.ROUTE_TREE) : isRoute? _s(isNewRoute? StringKey.NEW_ROUTE: StringKey.ROUTE_INFO): _s(StringKey.ROUTE_TREE_INFO);
	const [mutatedRouteTree, setMutatedRouteTree] = React.useState(() => routeTreeFactory(routeTree));
	const [mateStateId, setMateStateId] = React.useState(mateId);
	const [processing, setIsProcessing] = React.useState(false);
	const mate = mateStateId && mutatedRouteTree.mates[mateStateId] as IPlayer;

	if(mateStateId && !mate) {
		return null;
	}

	React.useEffect(() => {
		if(isNewRoute) {
			const newRouteTree = routeTreeFactory(mutatedRouteTree);
			const sortIndex = newRouteTree.mates.count;
			const mate = playerFactory({
				color: 4,
				loc: { x: 0.5, y: 0.5 },
				endCapType: EndCapType.arrow,
				lineStyle: LineStyle.fill,
				shading: PlayerShading.none,
				sortIndex});
			newRouteTree.mates.add(mate)
			newRouteTree.applyPatch([{op: PatchOpType.replace, path: `/mates/${mate.id}/label`, value: `${_s(StringKey.ROUTE)} ${sortIndex}` }]);

			setMutatedRouteTree(newRouteTree);
			setMateStateId(mate.id);
		}
	}, [isNewRoute]);

	const handleSave = React.useCallback(() => {
		if(!processing) {
			setIsProcessing(true)
			saveRouteTree(mutatedRouteTree, () => {
				setIsProcessing(false);
			});
		}
	}, [saveRouteTree, mutatedRouteTree, processing]);

	const handleDelete = React.useCallback(() => {
		if(!processing) {
			setIsProcessing(true)
			deleteRoute(mate?.id, () => {
				setIsProcessing(false);
			});
		}
	}, [deleteRoute, mate?.id, processing]);

	const handleChange = React.useCallback((patchOp: IPatchOperation) => {
		const target = routeTreeFactory(mutatedRouteTree);

		target.applyPatch([patchOp]);

		setMutatedRouteTree(target);

	}, [mutatedRouteTree]);

	return <div className="view">
		{processing? <Spinner delayMs={150} /> : null }
		<header>
			<div className="actions">
				<a className="button" onClick={ viewManager.popModal }><span className="icon cancel"></span></a>
			</div>
			<div className="title">{ title }</div>
			<div className="actions">
				{ saveRouteTree? <a className={ !mate || mate.isValid() ? 'button' : 'button disabled' } onClick={ handleSave }><span className="icon ok"></span></a>: null }
			</div>
		</header>
		{ !saveRouteTree? <RouteInfoViewer note={ mate? mate.note: routeTree.notes } />: <EditRouteInfo routeTree={mutatedRouteTree} onChange={handleChange} onDelete={isNewRoute? undefined: handleDelete} mateId={ mate?.id } /> }
	</div>
}

export function PlayPanelRouteTreeInfo({ alerts, routeTree, mateId, saveRouteTree }: { alerts?: IAlert[], routeTree: IRouteTree, mateId?: string, saveRouteTree?: SaveRouteTreeInfoCallback }) {
	const title = mateId? _s(StringKey.ROUTE_INFO): _s(StringKey.ROUTE_TREE_INFO);
	const [mutatedRouteTree, setMutatedRouteTree] = React.useState(() => routeTreeFactory(routeTree));
	const mate = mateId && mutatedRouteTree.mates[mateId] as IPlayer;

	const handleSave = React.useCallback(() => {
		if(saveRouteTree) {
			saveRouteTree(mutatedRouteTree);
		}
		actions.popPanel();
	}, [saveRouteTree, mutatedRouteTree]);

	const handleDelete = React.useCallback(() => {
		if(saveRouteTree) {
			mutatedRouteTree.mates.remove(mateId);
			saveRouteTree(mutatedRouteTree, true);
		}
		setTimeout(() => {
			actions.popPanel();
			viewManager.popPath();
		}, 60);
		
		// viewManager.rewritePath(`/playbook/${play.playbookId}`);
	}, [saveRouteTree, mutatedRouteTree]);

	const handleChange = React.useCallback((patchOp: IPatchOperation) => {
		const target = routeTreeFactory(mutatedRouteTree);

		target.applyPatch([patchOp]);

		setMutatedRouteTree(target);

	}, [mutatedRouteTree]);

	return <div className="view">
		<header>
			<div className="title">{ title }</div>
			<div className="actions">
				<a className={ !mate || mate.isValid() ? 'button' : 'button disabled' } onClick={ handleSave }><span className="icon ok"></span></a>
			</div>
		</header>
		<AlertList alerts={ alerts } />
		{ !saveRouteTree? <RouteInfoViewer note={ mate? mate.note: routeTree.notes } />: <EditRouteInfo routeTree={mutatedRouteTree} onChange={handleChange} onDelete={handleDelete} mateId={ mateId } /> }
	</div>;
}
import React = require("react");

export function SnapGrid() {
	return <div className="grid">
		<svg viewBox="0 0 1024 2304">
			<svg x="0%" y="0%" width="100%" height="100%">
				<line x1="0%" x2="100%" y1="0.642%" y2="0.642%"></line>
				<line x1="0%" x2="100%" y1="1.309%" y2="1.309%"></line>
				<line x1="0%" x2="100%" y1="1.976%" y2="1.976%"></line>
				<line x1="0%" x2="100%" y1="2.643%" y2="2.643%"></line>
				<line x1="0%" x2="100%" y1="3.31%" y2="3.31%"></line>
				<line x1="0%" x2="100%" y1="3.977%" y2="3.977%"></line>
				<line x1="0%" x2="100%" y1="4.644%" y2="4.644%"></line>
				<line x1="0%" x2="100%" y1="5.311%" y2="5.311%"></line>
				<line x1="0%" x2="100%" y1="5.978%" y2="5.978%"></line>
				<line x1="0%" x2="100%" y1="6.645%" y2="6.645%"></line>
				<line x1="0%" x2="100%" y1="7.312%" y2="7.312%"></line>
				<line x1="0%" x2="100%" y1="7.979%" y2="7.979%"></line>
				<line x1="0%" x2="100%" y1="8.646%" y2="8.646%"></line>
				<line x1="0%" x2="100%" y1="9.313%" y2="9.313%"></line>
				<line x1="0%" x2="100%" y1="9.98%" y2="9.98%"></line>
				<line x1="0%" x2="100%" y1="10.647%" y2="10.647%"></line>
				<line x1="0%" x2="100%" y1="11.314%" y2="11.314%"></line>
				<line x1="0%" x2="100%" y1="11.981%" y2="11.981%"></line>
				<line x1="0%" x2="100%" y1="12.648%" y2="12.648%"></line>
				<line x1="0%" x2="100%" y1="13.315%" y2="13.315%"></line>
				<line x1="0%" x2="100%" y1="13.982%" y2="13.982%"></line>
				<line x1="0%" x2="100%" y1="14.649%" y2="14.649%"></line>
				<line x1="0%" x2="100%" y1="15.316%" y2="15.316%"></line>
				<line x1="0%" x2="100%" y1="15.983%" y2="15.983%"></line>
				<line x1="0%" x2="100%" y1="16.65%" y2="16.65%"></line>
				<line x1="0%" x2="100%" y1="17.317%" y2="17.317%"></line>
				<line x1="0%" x2="100%" y1="17.984%" y2="17.984%"></line>
				<line x1="0%" x2="100%" y1="18.651%" y2="18.651%"></line>
				<line x1="0%" x2="100%" y1="19.318%" y2="19.318%"></line>
				<line x1="0%" x2="100%" y1="19.985%" y2="19.985%"></line>
				<line x1="0%" x2="100%" y1="20.652%" y2="20.652%"></line>
				<line x1="0%" x2="100%" y1="21.319%" y2="21.319%"></line>
				<line x1="0%" x2="100%" y1="21.986%" y2="21.986%"></line>
				<line x1="0%" x2="100%" y1="22.653%" y2="22.653%"></line>
				<line x1="0%" x2="100%" y1="23.32%" y2="23.32%"></line>
				<line x1="0%" x2="100%" y1="23.987%" y2="23.987%"></line>
				<line x1="0%" x2="100%" y1="24.654%" y2="24.654%"></line>
				<line x1="0%" x2="100%" y1="25.321%" y2="25.321%"></line>
				<line x1="0%" x2="100%" y1="25.988%" y2="25.988%"></line>
				<line x1="0%" x2="100%" y1="26.655%" y2="26.655%"></line>
				<line x1="0%" x2="100%" y1="27.322%" y2="27.322%"></line>
				<line x1="0%" x2="100%" y1="27.989%" y2="27.989%"></line>
				<line x1="0%" x2="100%" y1="28.656%" y2="28.656%"></line>
				<line x1="0%" x2="100%" y1="29.323%" y2="29.323%"></line>
				<line x1="0%" x2="100%" y1="29.99%" y2="29.99%"></line>
				<line x1="0%" x2="100%" y1="30.657%" y2="30.657%"></line>
				<line x1="0%" x2="100%" y1="31.324%" y2="31.324%"></line>
				<line x1="0%" x2="100%" y1="31.991%" y2="31.991%"></line>
				<line x1="0%" x2="100%" y1="32.658%" y2="32.658%"></line>
				<line x1="0%" x2="100%" y1="33.325%" y2="33.325%"></line>
				<line x1="0%" x2="100%" y1="33.992%" y2="33.992%"></line>
				<line x1="0%" x2="100%" y1="34.659%" y2="34.659%"></line>
				<line x1="0%" x2="100%" y1="35.326%" y2="35.326%"></line>
				<line x1="0%" x2="100%" y1="35.993%" y2="35.993%"></line>
				<line x1="0%" x2="100%" y1="36.66%" y2="36.66%"></line>
				<line x1="0%" x2="100%" y1="37.327%" y2="37.327%"></line>
				<line x1="0%" x2="100%" y1="37.994%" y2="37.994%"></line>
				<line x1="0%" x2="100%" y1="38.661%" y2="38.661%"></line>
				<line x1="0%" x2="100%" y1="39.328%" y2="39.328%"></line>
				<line x1="0%" x2="100%" y1="39.995%" y2="39.995%"></line>
				<line x1="0%" x2="100%" y1="40.662%" y2="40.662%"></line>
				<line x1="0%" x2="100%" y1="41.329%" y2="41.329%"></line>
				<line x1="0%" x2="100%" y1="41.996%" y2="41.996%"></line>
				<line x1="0%" x2="100%" y1="42.663%" y2="42.663%"></line>
				<line x1="0%" x2="100%" y1="43.33%" y2="43.33%"></line>
				<line x1="0%" x2="100%" y1="43.997%" y2="43.997%"></line>
				<line x1="0%" x2="100%" y1="44.664%" y2="44.664%"></line>
				<line x1="0%" x2="100%" y1="45.331%" y2="45.331%"></line>
				<line x1="0%" x2="100%" y1="45.998%" y2="45.998%"></line>
				<line x1="0%" x2="100%" y1="46.665%" y2="46.665%"></line>
				<line x1="0%" x2="100%" y1="47.332%" y2="47.332%"></line>
				<line x1="0%" x2="100%" y1="47.999%" y2="47.999%"></line>
				<line x1="0%" x2="100%" y1="48.666%" y2="48.666%"></line>
				<line x1="0%" x2="100%" y1="49.333%" y2="49.333%"></line>
				<line x1="0%" x2="100%" y1="50%" y2="50%"></line>
				<line x1="0%" x2="100%" y1="50.667%" y2="50.667%"></line>
				<line x1="0%" x2="100%" y1="51.334%" y2="51.334%"></line>
				<line x1="0%" x2="100%" y1="52.001%" y2="52.001%"></line>
				<line x1="0%" x2="100%" y1="52.668%" y2="52.668%"></line>
				<line x1="0%" x2="100%" y1="53.335%" y2="53.335%"></line>
				<line x1="0%" x2="100%" y1="54.002%" y2="54.002%"></line>
				<line x1="0%" x2="100%" y1="54.669%" y2="54.669%"></line>
				<line x1="0%" x2="100%" y1="55.336%" y2="55.336%"></line>
				<line x1="0%" x2="100%" y1="56.003%" y2="56.003%"></line>
				<line x1="0%" x2="100%" y1="56.67%" y2="56.67%"></line>
				<line x1="0%" x2="100%" y1="57.337%" y2="57.337%"></line>
				<line x1="0%" x2="100%" y1="58.004%" y2="58.004%"></line>
				<line x1="0%" x2="100%" y1="58.671%" y2="58.671%"></line>
				<line x1="0%" x2="100%" y1="59.338%" y2="59.338%"></line>
				<line x1="0%" x2="100%" y1="60.005%" y2="60.005%"></line>
				<line x1="0%" x2="100%" y1="60.672%" y2="60.672%"></line>
				<line x1="0%" x2="100%" y1="61.339%" y2="61.339%"></line>
				<line x1="0%" x2="100%" y1="62.006%" y2="62.006%"></line>
				<line x1="0%" x2="100%" y1="62.673%" y2="62.673%"></line>
				<line x1="0%" x2="100%" y1="63.34%" y2="63.34%"></line>
				<line x1="0%" x2="100%" y1="64.007%" y2="64.007%"></line>
				<line x1="0%" x2="100%" y1="64.674%" y2="64.674%"></line>
				<line x1="0%" x2="100%" y1="65.341%" y2="65.341%"></line>
				<line x1="0%" x2="100%" y1="66.008%" y2="66.008%"></line>
				<line x1="0%" x2="100%" y1="66.675%" y2="66.675%"></line>
				<line x1="0%" x2="100%" y1="67.342%" y2="67.342%"></line>
				<line x1="0%" x2="100%" y1="68.009%" y2="68.009%"></line>
				<line x1="0%" x2="100%" y1="68.676%" y2="68.676%"></line>
				<line x1="0%" x2="100%" y1="69.343%" y2="69.343%"></line>
				<line x1="0%" x2="100%" y1="70.01%" y2="70.01%"></line>
				<line x1="0%" x2="100%" y1="70.677%" y2="70.677%"></line>
				<line x1="0%" x2="100%" y1="71.344%" y2="71.344%"></line>
				<line x1="0%" x2="100%" y1="72.011%" y2="72.011%"></line>
				<line x1="0%" x2="100%" y1="72.678%" y2="72.678%"></line>
				<line x1="0%" x2="100%" y1="73.345%" y2="73.345%"></line>
				<line x1="0%" x2="100%" y1="74.012%" y2="74.012%"></line>
				<line x1="0%" x2="100%" y1="74.679%" y2="74.679%"></line>
				<line x1="0%" x2="100%" y1="75.346%" y2="75.346%"></line>
				<line x1="0%" x2="100%" y1="76.013%" y2="76.013%"></line>
				<line x1="0%" x2="100%" y1="76.68%" y2="76.68%"></line>
				<line x1="0%" x2="100%" y1="77.347%" y2="77.347%"></line>
				<line x1="0%" x2="100%" y1="78.014%" y2="78.014%"></line>
				<line x1="0%" x2="100%" y1="78.681%" y2="78.681%"></line>
				<line x1="0%" x2="100%" y1="79.348%" y2="79.348%"></line>
				<line x1="0%" x2="100%" y1="80.015%" y2="80.015%"></line>
				<line x1="0%" x2="100%" y1="80.682%" y2="80.682%"></line>
				<line x1="0%" x2="100%" y1="81.349%" y2="81.349%"></line>
				<line x1="0%" x2="100%" y1="82.016%" y2="82.016%"></line>
				<line x1="0%" x2="100%" y1="82.683%" y2="82.683%"></line>
				<line x1="0%" x2="100%" y1="83.35%" y2="83.35%"></line>
				<line x1="0%" x2="100%" y1="84.017%" y2="84.017%"></line>
				<line x1="0%" x2="100%" y1="84.684%" y2="84.684%"></line>
				<line x1="0%" x2="100%" y1="85.351%" y2="85.351%"></line>
				<line x1="0%" x2="100%" y1="86.018%" y2="86.018%"></line>
				<line x1="0%" x2="100%" y1="86.685%" y2="86.685%"></line>
				<line x1="0%" x2="100%" y1="87.352%" y2="87.352%"></line>
				<line x1="0%" x2="100%" y1="88.019%" y2="88.019%"></line>
				<line x1="0%" x2="100%" y1="88.686%" y2="88.686%"></line>
				<line x1="0%" x2="100%" y1="89.353%" y2="89.353%"></line>
				<line x1="0%" x2="100%" y1="90.02%" y2="90.02%"></line>
				<line x1="0%" x2="100%" y1="90.687%" y2="90.687%"></line>
				<line x1="0%" x2="100%" y1="91.354%" y2="91.354%"></line>
				<line x1="0%" x2="100%" y1="92.021%" y2="92.021%"></line>
				<line x1="0%" x2="100%" y1="92.688%" y2="92.688%"></line>
				<line x1="0%" x2="100%" y1="93.355%" y2="93.355%"></line>
				<line x1="0%" x2="100%" y1="94.022%" y2="94.022%"></line>
				<line x1="0%" x2="100%" y1="94.689%" y2="94.689%"></line>
				<line x1="0%" x2="100%" y1="95.356%" y2="95.356%"></line>
				<line x1="0%" x2="100%" y1="96.023%" y2="96.023%"></line>
				<line x1="0%" x2="100%" y1="96.69%" y2="96.69%"></line>
				<line x1="0%" x2="100%" y1="97.357%" y2="97.357%"></line>
				<line x1="0%" x2="100%" y1="98.024%" y2="98.024%"></line>
				<line x1="0%" x2="100%" y1="98.691%" y2="98.691%"></line>
				<line x1="0%" x2="100%" y1="99.358%" y2="99.358%"></line>

				<line x1="0.769%" x2="0.769%" y1="0%" y2="100%"></line>
				<line x1="2.308%" x2="2.308%" y1="0%" y2="100%"></line>
				<line x1="3.846%" x2="3.846%" y1="0%" y2="100%"></line>
				<line x1="5.385%" x2="5.385%" y1="0%" y2="100%"></line>
				<line x1="6.923%" x2="6.923%" y1="0%" y2="100%"></line>
				<line x1="8.462%" x2="8.462%" y1="0%" y2="100%"></line>
				<line x1="10.000%" x2="10.000%" y1="0%" y2="100%"></line>
				<line x1="11.538%" x2="11.538%" y1="0%" y2="100%"></line>
				<line x1="13.077%" x2="13.077%" y1="0%" y2="100%"></line>
				<line x1="14.615%" x2="14.615%" y1="0%" y2="100%"></line>
				<line x1="16.154%" x2="16.154%" y1="0%" y2="100%"></line>
				<line x1="17.692%" x2="17.692%" y1="0%" y2="100%"></line>
				<line x1="19.231%" x2="19.231%" y1="0%" y2="100%"></line>
				<line x1="20.769%" x2="20.769%" y1="0%" y2="100%"></line>
				<line x1="22.308%" x2="22.308%" y1="0%" y2="100%"></line>
				<line x1="23.846%" x2="23.846%" y1="0%" y2="100%"></line>
				<line x1="25.385%" x2="25.385%" y1="0%" y2="100%"></line>
				<line x1="26.923%" x2="26.923%" y1="0%" y2="100%"></line>
				<line x1="28.462%" x2="28.462%" y1="0%" y2="100%"></line>
				<line x1="30.000%" x2="30.000%" y1="0%" y2="100%"></line>
				<line x1="31.538%" x2="31.538%" y1="0%" y2="100%"></line>
				<line x1="33.077%" x2="33.077%" y1="0%" y2="100%"></line>
				<line x1="34.615%" x2="34.615%" y1="0%" y2="100%"></line>
				<line x1="36.154%" x2="36.154%" y1="0%" y2="100%"></line>
				<line x1="37.692%" x2="37.692%" y1="0%" y2="100%"></line>
				<line x1="39.231%" x2="39.231%" y1="0%" y2="100%"></line>
				<line x1="40.769%" x2="40.769%" y1="0%" y2="100%"></line>
				<line x1="42.308%" x2="42.308%" y1="0%" y2="100%"></line>
				<line x1="43.846%" x2="43.846%" y1="0%" y2="100%"></line>
				<line x1="45.385%" x2="45.385%" y1="0%" y2="100%"></line>
				<line x1="46.923%" x2="46.923%" y1="0%" y2="100%"></line>
				<line x1="48.462%" x2="48.462%" y1="0%" y2="100%"></line>
				<line x1="50%" x2="50%" y1="0%" y2="100%"></line>
				<line x1="51.538%" x2="51.538%" y1="0%" y2="100%"></line>
				<line x1="53.077%" x2="53.077%" y1="0%" y2="100%"></line>
				<line x1="54.615%" x2="54.615%" y1="0%" y2="100%"></line>
				<line x1="56.154%" x2="56.154%" y1="0%" y2="100%"></line>
				<line x1="57.692%" x2="57.692%" y1="0%" y2="100%"></line>
				<line x1="59.231%" x2="59.231%" y1="0%" y2="100%"></line>
				<line x1="60.769%" x2="60.769%" y1="0%" y2="100%"></line>
				<line x1="62.308%" x2="62.308%" y1="0%" y2="100%"></line>
				<line x1="63.846%" x2="63.846%" y1="0%" y2="100%"></line>
				<line x1="65.385%" x2="65.385%" y1="0%" y2="100%"></line>
				<line x1="66.923%" x2="66.923%" y1="0%" y2="100%"></line>
				<line x1="68.462%" x2="68.462%" y1="0%" y2="100%"></line>
				<line x1="70.000%" x2="70.000%" y1="0%" y2="100%"></line>
				<line x1="71.538%" x2="71.538%" y1="0%" y2="100%"></line>
				<line x1="73.077%" x2="73.077%" y1="0%" y2="100%"></line>
				<line x1="74.615%" x2="74.615%" y1="0%" y2="100%"></line>
				<line x1="76.154%" x2="76.154%" y1="0%" y2="100%"></line>
				<line x1="77.692%" x2="77.692%" y1="0%" y2="100%"></line>
				<line x1="79.231%" x2="79.231%" y1="0%" y2="100%"></line>
				<line x1="80.769%" x2="80.769%" y1="0%" y2="100%"></line>
				<line x1="82.308%" x2="82.308%" y1="0%" y2="100%"></line>
				<line x1="83.846%" x2="83.846%" y1="0%" y2="100%"></line>
				<line x1="85.385%" x2="85.385%" y1="0%" y2="100%"></line>
				<line x1="86.923%" x2="86.923%" y1="0%" y2="100%"></line>
				<line x1="88.462%" x2="88.462%" y1="0%" y2="100%"></line>
				<line x1="90.000%" x2="90.000%" y1="0%" y2="100%"></line>
				<line x1="91.538%" x2="91.538%" y1="0%" y2="100%"></line>
				<line x1="93.077%" x2="93.077%" y1="0%" y2="100%"></line>
				<line x1="94.615%" x2="94.615%" y1="0%" y2="100%"></line>
				<line x1="96.154%" x2="96.154%" y1="0%" y2="100%"></line>
				<line x1="97.692%" x2="97.692%" y1="0%" y2="100%"></line>
				<line x1="99.231%" x2="99.231%" y1="0%" y2="100%"></line>
			</svg>
		</svg>
	</div> 
}
import { map } from 'lodash';
import * as React from 'react';
import * as systemActions from '../actions';
import { IAction } from '../store';
import { _s, StringKey } from '../strings';
import { CachedImage } from './cachedImage';

// Button className variations include cancel, save, delete
export function SponsorModal({ id, tribute, message, logoUrl, actions }: {id?: string, tribute: string, message: string, logoUrl: string, actions?}) {
	const buttons = map(actions, (a) => <a key={ `action-${a.label}`} className={ `button basic ${a.className}` } onClick={ (e) => systemActions.browseTo(a.url) }><span>{ a.label }</span></a>);

	return <div className="view page in">
		<header>
			<div className="actions">
				<a className="button" onClick={ (e) => systemActions.deleteModal(id) }><span className="icon cancel"></span></a>
			</div>
			<div className="title">{ _s(StringKey.SPONSOR) }</div>
		</header>
		<div className="content scrollable">
			<div className="inner">
				<a><CachedImage className="sponsorLogo" src={ logoUrl } /></a>
				<p>{ tribute }</p>
				<p>{ message }</p>
				<div className="actions">{ buttons }</div>
			</div>
		</div>
	</div>;
}

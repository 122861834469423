import { assign, find, startCase } from 'lodash';
import * as dateHelper from 'playmaker-team-common/dist/shared/dateHelper';
import { IModel, StringDictionary } from 'playmaker-team-common/dist/shared/interfaces';
import { createFactory } from 'playmaker-team-common/dist/shared/modelFactory';
import { ModelSet } from 'playmaker-team-common/dist/shared/modelSet';
import { default as validator } from 'playmaker-team-common/dist/shared/validator';
import { default as valueFilters } from 'playmaker-team-common/dist/shared/valueFilters';
import { default as rootModel, IRootModel } from './rootModel';
import { SystemFeature } from './subscriptionPlan';
import { ISubscriptionUpgrade } from './subscriptionUpgrade';

export interface ISubscriptionPlanUpgrade extends IRootModel {
	key: string;
	name: string;
	description: string;
	features: string;
	subscriptionPlanId: string;
	price: number;
	settings?: StringDictionary;
	featureSort: number;
	hasFeature: (feature: SystemFeature) => boolean;
	isFreeTrial: () => boolean;
}

export default createFactory<ISubscriptionPlanUpgrade>('SubscriptionPlanUpgrade', assign({}, rootModel, {
	key: {
		setFilters: valueFilters.clean,
	},
	name: {
		setFilters: valueFilters.clean,
	},
	description: {
		setFilters: valueFilters.clean,
	},
	features: {
		setFilters: valueFilters.clean,
		defaultValue: '',
	},
	price: {
		setFilters: valueFilters.toNumber,
		rules: [validator.inRange(0, Number.MAX_VALUE)],
		defaultValue: 0,
	},
	subscriptionPlanId: {
		setFilters: valueFilters.clean,
	},
	settings: {
	},
	featureSort: {
		nonSerialized: true,
		writeable: false,
	},
	_get_featureSort() {
		if (this.hasFeature(SystemFeature.collaboration)) {
			return 3;
		}
		if (this.hasFeature(SystemFeature.print)) {
			return 2;
		}

		return 1;
	},
	hasFeature(feature: string) {
		const featureList = this.features.split(',');

		return featureList.indexOf(feature) !== -1;
	},
	isFreeTrial() {
		return false;
	},
}));

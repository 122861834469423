import { assign } from 'lodash';
import { IModel, StringDictionary } from 'playmaker-team-common/dist/shared/interfaces';
import { createFactory } from 'playmaker-team-common/dist/shared/modelFactory';
import { default as validator } from 'playmaker-team-common/dist/shared/validator';
import { default as valueFilters } from 'playmaker-team-common/dist/shared/valueFilters';
import { default as rootModel, IRootModel } from './rootModel';
import { SystemFeature } from './subscriptionPlan';

export interface ISubscriptionUpgrade extends IRootModel {
	subscriptionId: string;
	subscriptionPlanUpgradeId: string;
	name: string;
	description: string;
	features: string;
	paymentAmount: number;
	settings?: StringDictionary;
	hasFeature: (feature: SystemFeature) => boolean;
	isFreeTrial: () => boolean;
}

export default createFactory<ISubscriptionUpgrade>('SubscriptionUpgrade', assign({}, rootModel, {
	subscriptionId: {
		setFilters: valueFilters.clean,
	},
	subscriptionPlanUpgradeId: {
		setFilters: valueFilters.clean,
	},
	name: {
		setFilters: valueFilters.clean,
	},
	description: {
		setFilters: valueFilters.clean,
	},
	features: {
		setFilters: valueFilters.clean,
		defaultValue: '',
	},
	paymentAmount: {
		setFilters: valueFilters.toNumber,
		rules: validator.inRange(0, Number.MAX_VALUE),
	},
	settings: {
	},
	hasFeature(feature: string) {
		const featureList = this.features.split(',');

		return featureList.indexOf(feature) !== -1;
	},
	isFreeTrial() {
		return false;
	},
}));

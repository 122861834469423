import { camelCase, map } from 'lodash';
import * as React from 'react';
import { GamePhase } from '../models/diagramModel';

interface Props {
	activePhase: GamePhase;
	onPhaseChange: (phase: GamePhase) => void;
	variant: 'flag' | 'tackle';
}

interface State {

}

export class PhaseTabs extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);

		this.handlePhaseChange = this.handlePhaseChange.bind(this);
	}

	public handlePhaseChange(e) {
		this.props.onPhaseChange(Number(e.target.value));
	}

	public render() {
		const { activePhase, variant } = this.props;
		const phases = variant === 'tackle' ? [GamePhase.Offense, GamePhase.Defense, GamePhase.SpecialTeams] : [GamePhase.Offense, GamePhase.Defense];

		return <div className="radio">{
			map(phases, (phase) => {
				const phaseName = GamePhase[phase];

				return (<label key={ phase } className={ activePhase === phase ? 'on' : '' }><span className={ `icon ${camelCase(phaseName)}` }></span><input type="radio" name="phase" value={ phase } checked={ activePhase === phase } onChange={ this.handlePhaseChange } /></label>);
			})
		}</div>;
	}
}

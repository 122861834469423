import { filter, map, sortBy } from 'lodash';
import * as React from 'react';
import * as actions from '../actions';
import { getPlaybookPermissions } from '../authorizationHelper';
import { current as getCurrentContext } from '../componentContext';
import { GamePhase } from '../models/diagramModel';
import { IPlay } from '../models/play';
import { IPlaybook } from '../models/playbook';
import { ISortable } from '../models/sortableModel';
import { AlertMode, AlertSeverity, IDragData, IDragSpec } from '../store';
import { _s, StringKey } from '../strings';
import * as viewManager from '../viewManager';
import { DeleteConfirmModal } from './deleteConfirmModal';
import { DragDrop, IDragSourceParams, IDropTargetParams } from './dnd';
import { PlaybookSelector } from './playbookSelector';

interface Props {
	addLabel: string;
	isVisible?: boolean;
	lastViewedPlayId: string;
	plays: (IPlay & ISortable)[];
	onAddClick: (e) => void;
	onItemDrop: (sourceId: string, targetId: string) => void;
	openInfo: (playId: string) => void;
	copyPlaysTo: (plays: IPlay[], playbooks: IPlaybook[]) => void;
	deletePlays: (selectedPlays: IPlay[]) => void;
	selectedItems: string[];
	updateSelectedItems: (items: string[]) => void;
}

interface State {
}

class PlayRow extends React.Component<any, any> {
	private _dropTarget;

	constructor(props) {
		super(props);

		this.canDrop = this.canDrop.bind(this);
		this.onDrop = this.onDrop.bind(this);

		this.setDropTarget = this.setDropTarget.bind(this);
	}

	public canDrop(data: IDragData) {
		const { play } = this.props;

		return data.type === 'row' && data.info.play && data.info.play.id !== play.id;
	}

	public onDrop(data: IDragData) {
		const { play, onDrop } = this.props;

		if (onDrop) {
			onDrop(data.info.play.id, play.id);
		}
	}

	public setDropTarget(el) {
		const { addDropTarget, removeDropTarget } = this.props;

		if (!addDropTarget) {
			return;
		}

		if (this._dropTarget) {
			removeDropTarget(this._dropTarget);
		}

		this._dropTarget = el;

		if (this._dropTarget) {
			addDropTarget(this._dropTarget, this.canDrop, this.onDrop);
		}
	}

	public render() {
		const { classNames, index, isSelected, play, onSelectedChange, onInfoClick, onPointerDown, onTouchStart, onMouseDown } = this.props;
		const { currentPlaybook, playbookPermissions } = getCurrentContext();
		const playbookId = currentPlaybook.id;

		return <div className={ classNames.join(' ') } ref={ this.setDropTarget }>
			<span onClick={ viewManager.pushPath.bind(this, `/playbook/${playbookId}/plays/${play.id}` ) }><strong>{ play.formation }</strong> { play.label }</span>
			<div className="checkbox">
				<label className={ isSelected ? 'on' : '' }><span className="icon badge checkmark">{ index + 1 }</span><input type="checkbox" checked={ isSelected } value={ play.id } onChange={ onSelectedChange } /></label>
			</div>
			<a className="button" onClick={ onInfoClick } id={ `play-info.${play.id}` }><span className="icon infoSmall"></span></a>
			{ playbookPermissions.canUpdate ? <a className="button" onPointerDown={ onPointerDown } onTouchStart={ onTouchStart } onMouseDown={ onMouseDown }><span className="icon sortItem"></span></a> : null }
		</div>;
	}
}

export class PlayList extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);

		this.handleInfoClick = this.handleInfoClick.bind(this);
		this.handleSelectAll = this.handleSelectAll.bind(this);
		this.handlePlaySelected = this.handlePlaySelected.bind(this);

		this.handleCopyClick = this.handleCopyClick.bind(this);
		this.handleCopyToClick = this.handleCopyToClick.bind(this);
		this.handleDeleteClick = this.handleDeleteClick.bind(this);
	}

	public handleInfoClick(e) {
		const { openInfo } = this.props;
		const parts = e.currentTarget.id.split('.');
		const id = parts[1];

		openInfo(id);
	}

	public handleCopyClick(e) {
		const { copyPlaysTo, plays, selectedItems } = this.props;
		const { currentPlaybook } = getCurrentContext();
		const alertId = 'copy-plays';
		const selectedPlays = filter(plays, (p) => selectedItems.indexOf(p.id) !== -1);

		if (selectedPlays.length) {
			copyPlaysTo(selectedPlays, [currentPlaybook]);
		}
	}

	public handleDeleteClick(e) {
		const { deletePlays, plays, selectedItems } = this.props;
		const selectedPlays = filter(plays, (p) => selectedItems.indexOf(p.id) !== -1);

		if (selectedPlays.length) {
			const modalId = 'delete-plays';

			const deleteAction = () => {
				actions.deleteModal(modalId);
				deletePlays(selectedPlays);
			};

			actions.pushModal({
				id: modalId,
				component: DeleteConfirmModal,
				props: () => {
					return {
						classNames: ['prompt'],
						title: _s(StringKey.DELETE_PLAYS_QUESTION),
						message: _s(StringKey.DELETE_PLAYS_PROMPT_MESSAGE),
						deleteAction: {
							label: _s(StringKey.DELETE_PLAYS),
							className: 'delete',
							action: deleteAction,
						},
						onCancelClick: () => actions.deleteModal(modalId),
					};
				},
			});
		}
	}

	public handleCopyToClick(e) {
		const { copyPlaysTo, plays, selectedItems } = this.props;
		const selectedPlays = filter(plays, (p) => selectedItems.indexOf(p.id) !== -1);
		const { currentPlaybook, playbooks: contextPlaybooks, teamPermissions } = getCurrentContext();
		const playbooks = sortBy(filter(contextPlaybooks, (playbook: IPlaybook) => {
			const permissions = getPlaybookPermissions({ playbook, teamPermissions });

			return permissions.canUpdate && playbook.id !== currentPlaybook.id && playbook.playersPerSide === currentPlaybook.playersPerSide;
		}), 'name');

		if (playbooks.length) {
			actions.pushModal({
				component: PlaybookSelector,
				props: {
					cancelLabel: _s(StringKey.CANCEL),
					description: _s(StringKey.SELECT_PLAYBOOKS),
					okLabel: _s(StringKey.COPY),
					onCancel: actions.popModal,
					onOk: (pbs: IPlaybook[]) => {
						actions.popModal();

						copyPlaysTo(selectedPlays, pbs);
					},
					playbooks,
					title: _s(StringKey.COPY_PLAYS),
				},
			});
		} else {
			const alertId = 'unable-to-copy-plays';
			actions.pushAlert({
				id: alertId,
				title: _s(StringKey.UNABLE_TO_COPY_PLAY_TITLE),
				message: _s(StringKey.UNABLE_TO_COPY_PLAY_MESSAGE),
				mode: AlertMode.prompt,
				severity: AlertSeverity.info,
				actions: [{
					label: _s(StringKey.OK),
					action: () => { actions.deleteAlert(alertId); },
				}],
			});
		}
	}

	public handleSelectAll(e) {
		const { playbookPermissions } = getCurrentContext();

		if (!playbookPermissions.canUpdate) {
			return;
		}

		const { plays, selectedItems, updateSelectedItems } = this.props;
		let updatedItems = [];

		if (selectedItems.length < plays.length) {
			updatedItems = map(plays, (p) => p.id);
		}

		updateSelectedItems(updatedItems);
	}

	public handlePlaySelected(e) {
		const { playbookPermissions } = getCurrentContext();

		if (!playbookPermissions.canUpdate) {
			return;
		}

		const { selectedItems, updateSelectedItems } = this.props;
		const updatedItems = selectedItems.concat();
		const itemIndex = updatedItems.indexOf(e.target.value);

		if (itemIndex === -1) {
			updatedItems.push(e.target.value);
		} else {
			updatedItems.splice(itemIndex, 1);
		}

		updateSelectedItems(updatedItems);
	}

	public render() {
		if (!this.props.isVisible) {
			return null;
		}

		const { addLabel, lastViewedPlayId, onAddClick, onItemDrop, plays, selectedItems } = this.props;
		const { playbookPermissions } = getCurrentContext();
		let index = 0;
		const rows = map(plays, (play: IPlay & ISortable) => {
			const sortIndex = index;
			const itemIndex = index ++;
			const lastViewed = lastViewedPlayId === play.id;
			const dragSpec: IDragSpec = {
				component: (props) => <div className="group playTable"><PlayRow play={ play } classNames={ ['row', 'dragging'] } index={ itemIndex } /></div> ,
				getDragLayout: (touch, currentTarget) => {
					const parent = currentTarget.parentElement;
					const boundingRect = parent.getBoundingClientRect();

					return { x: touch.clientX - boundingRect.left, y: touch.clientY - boundingRect.top, width: boundingRect.width, height: boundingRect.height, constrain: 'x', scrollContainer: parent.parentElement };
				},
				dragStart: () => {
					actions.setLastViewedPlayId('');
				},
				data: {
					type: 'row',
					info: {
						play,
					},
				},
			};

			return <DragDrop key={ play.id }>{
				(params: IDragSourceParams & IDropTargetParams) => {
					const { activeDragSpec, handleMouseDown, handlePointerDown, handleTouchStart, ...dragProps } = params;
					const classNames = lastViewed ? ['row selected'] : ['row'];

					if (activeDragSpec && activeDragSpec.data.info.play !== play) {
						const dragSpecSortIndex = plays.indexOf(activeDragSpec.data.info.play);
						classNames.push('dropTarget');
						classNames.push(dragSpecSortIndex < sortIndex ? 'bottom' : 'top');
					}

					return <PlayRow
						play={ play}
						classNames={ classNames }
						index={ itemIndex }
						isSelected={ selectedItems.indexOf(play.id) !== -1}
						onInfoClick={ this.handleInfoClick }
						onDrop={ onItemDrop }
						onSelectedChange={ this.handlePlaySelected }
						onPointerDown={ handlePointerDown && handlePointerDown.bind(this, dragSpec) }
						onTouchStart={ handleTouchStart && handleTouchStart.bind(this, dragSpec) }
						onMouseDown={ handleMouseDown && handleMouseDown.bind(this, dragSpec) }
						{...dragProps }
					/>;
				}
			}</DragDrop>;
		});

		return <div className="content">
			<div className="group playTable">
				<div className="list scrollable">
					<header>
						<div className="row">
							<div className="checkbox">
								<label className={ rows.length > 0 && rows.length === selectedItems.length ? 'on' : '' } onClick={ this.handleSelectAll }><span className="icon badge checkmark"></span></label>
							</div>
							<span>{ _s(StringKey.PLAY) }</span>
						</div>
					</header>
					{ !playbookPermissions.canUpdate ? null : <div className="row">
						<a className="button add" onClick={ onAddClick }><span className="icon addPlay"></span><span>{ addLabel }</span></a>
					</div>
					}
					{ rows }
				</div>
				<footer className={ selectedItems.length ? 'multiSelect in' : 'multiSelect' }>
					<div className="row">
						<a className="button basic" onClick={ this.handleCopyClick }><span>{ _s(StringKey.COPY) }</span></a>
						<a className="button basic" onClick={ this.handleCopyToClick }><span>{ _s(StringKey.COPY_TO) }</span></a>
						<a className="button basic delete" onClick={ this.handleDeleteClick }><span>{ _s(StringKey.DELETE) }</span></a>
					</div>
				</footer>
			</div>
		</div>;
	}
}

import { assign, filter } from 'lodash';
import { IModel } from 'playmaker-team-common/dist/shared/interfaces';
import { createFactory } from 'playmaker-team-common/dist/shared/modelFactory';
import { ModelSet } from 'playmaker-team-common/dist/shared/modelSet';
import { default as validator } from 'playmaker-team-common/dist/shared/validator';
import { default as valueFilters } from 'playmaker-team-common/dist/shared/valueFilters';
import { default as addressFactory, IAddress } from './address';
import { default as playbookFactory, IPlaybook } from './playbook';
import { default as rootModel, IRootModel } from './rootModel';
import { default as subscriptionFactory, ISubscription } from './subscription';
import { default as teamMemberFactory, ITeamMember, TeamRole } from './teamMember';
import { default as teamSettingsFactory, ITeamSettings } from './teamSettings';

export const enum SubscriptionState {
	None, // There has never been a Subscription
	Expired, // There is an expired Subscription
	PastDue, // There is an unpaid Subscription in the grace period
	Current, // There is an Current Subscription
}

export interface ITeam extends IRootModel {
	abbreviation: string;
	hasHadSubscription: boolean;
	isCollaborative: boolean;
	currentSubscription?: ISubscription;
	pendingSubscription?: ISubscription;
	members: ModelSet<IModel & ITeamMember>;
	name: string;
	playbooks: ModelSet<IModel & IPlaybook>;
	settings: IModel & ITeamSettings;
	subscriptionState: SubscriptionState;
	_cs?: string;
	getAdmins: () => IModel & ITeamMember;
}

export default createFactory<ITeam>('Team', assign({}, rootModel, {
	abbreviation: {
		setFilters: (val: string) => val && val.substr(0, 4).toUpperCase(),
		rules: validator.required('A Team Abbreviation is required'),
	},
	currentSubscription: {
		setFilters: valueFilters.toModel<ISubscription>(subscriptionFactory),
	},
	pendingSubscription: {
		setFilters: valueFilters.toModel<ISubscription>(subscriptionFactory),
	},
	hasHadSubscription: {
		writable: false,
		nonSerialized: true,
	},
	_get_hasHadSubscription() {
		return !!this.currentSubscription;
	},
	isCollaborative: {
		writable: false,
		nonSerialized: true,
	},
	_get_isCollaborative() {
		return this.subscriptionState > SubscriptionState.Expired;
	},
	members: {
		setFilters: valueFilters.toModelSet(teamMemberFactory),
		rules: validator.validModel(teamMemberFactory.rules()),
		defaultValue: {},
	},
	name: {
		rules: validator.required('A Team Name is required'),
	},
	playbooks: {
		setFilters: valueFilters.toModelSet(playbookFactory),
		rules: validator.validModel(playbookFactory.rules()),
		defaultValue: {},
	},
	settings: {
		setFilters: valueFilters.toModel<ITeamSettings>(teamSettingsFactory),
		rules: validator.validModel(teamSettingsFactory.rules()),
		defaultValue: {},
	},
	subscriptionState: {
		writable: false,
		nonSerialized: true,
	},
	_cs: {

	},
	_get_subscriptionState() {
		const currentSubscription: ISubscription = this.currentSubscription;

		if (currentSubscription) {
			if (currentSubscription.hasEnded() || currentSubscription.hasExpired()) {
				return SubscriptionState.Expired;
			}

			return SubscriptionState.Current;
		}

		return SubscriptionState.None;
	},
	getAdmins() {
		return filter(this.members.values, { role: TeamRole.Owner });
	},

}));

import * as React from 'react';

export function Lines30x70({}) {
	return <div className="field">
		<svg viewBox="0 0 1024 2304">
			<svg className="markings" x="23%" y="26.67%" width="54%" height="46.67%">
				<svg className="tenYards" x="0%" y="0%" width="100%" height="14.3%">
					<line className="yardline" x1="0" x2="100%" y1="50%" y2="50%"></line>
					<line className="yardline" x1="0" x2="100%" y1="100%" y2="100%"></line>
				</svg>
				<svg className="tenYards" x="0%" y="14.3%" width="100%" height="14.3%">
					<line className="yardline" x1="0" x2="100%" y1="50%" y2="50%" />
					<line className="yardline" x1="0" x2="100%" y1="100%" y2="100%" />
				</svg>
				<svg className="tenYards" x="0%" y="28.6%" width="100%" height="14.3%">
					<line className="yardline" x1="0" x2="100%" y1="50%" y2="50%" />
					<line className="yardline" x1="0" x2="100%" y1="100%" y2="100%" />
				</svg>
				<svg className="tenYards" x="0%" y="42.9%" width="100%" height="14.2%">
					<line className="yardline" x1="0" x2="100%" y1="50%" y2="50%" />
					<line className="yardline" x1="0" x2="100%" y1="100%" y2="100%" />
				</svg>
				<svg className="tenYards" x="0%" y="57.1%" width="100%" height="14.3%">
					<line className="yardline" x1="0" x2="100%" y1="50%" y2="50%" />
					<line className="yardline" x1="0" x2="100%" y1="100%" y2="100%" />
				</svg>
				<svg className="tenYards" x="0%" y="71.4%" width="100%" height="14.3%">
					<line className="yardline" x1="0" x2="100%" y1="50%" y2="50%" />
					<line className="yardline" x1="0" x2="100%" y1="100%" y2="100%" />
				</svg>
				<svg className="tenYards" x="0%" y="85.7%" width="100%" height="14.3%">
					<line className="yardline" x1="0" x2="100%" y1="50%" y2="50%" />
				</svg>
			</svg>
		</svg>
	</div>;
}

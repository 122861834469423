import { assign } from 'lodash';
import * as validator from 'playmaker-team-common/dist/shared/validator';
import * as React from 'react';
import * as actions from '../actions';
import * as logger from '../logger';
import { IAlert } from '../store';
import * as store from '../store';
import { _s, StringKey } from '../strings';
import * as viewManager from '../viewManager';
import { AlertList } from './alert';
import { Page, PageState } from './page';
import { Spinner } from './spinner';

export interface ICredentials {
	emailAddress: string;
	password: string;
}

interface Props {
	alerts: store.IAlert[];
	viewState: store.IViewState;
}

interface State {
	credentials: ICredentials;
	activePage: number;
	processing?: boolean;
}

export const LoginPage = ({ alertContainer, cancelClass= 'icon cancel', credentials, onForgotPassword = null, message = null, onCancel = viewManager.popModal, onLogin, onKeyPress, updateCredentials }) => {
	const enabled = !!credentials.emailAddress && credentials.password;

	return <React.Fragment>
		<header>
			<div className="actions">
				<a className="button" onClick={ onCancel }><span className={ cancelClass }></span></a>
			</div>
			<div className="title">{ _s(StringKey.LOG_IN) }</div>
		</header>
		{ alertContainer }
		<div className="content scrollable">
			<div className="inner compact" onKeyPress={ onKeyPress }>
				{ message && <p>{ message }</p> }
				<div className="appVersion">
					<div className="logoLogin"></div>
				</div>
				<input type="email" value={ credentials.emailAddress } placeholder={ _s(StringKey.EMAIL_ADDRESS) } onChange={ (e) => updateCredentials('emailAddress', e.target.value) } required />
				<input type="password" placeholder={ _s(StringKey.PASSWORD) } value={ credentials.password } onChange={ (e) => updateCredentials('password', e.target.value) } required />
				<div className="actions">
					<a className={ enabled ? 'button basic fullWidth' : 'button basic fullWidth disabled' } onClick={ onLogin }><span className="icon"></span><span>{ _s(StringKey.LOG_IN) }</span><span className="icon navigate"></span></a>
				</div>
				{ onForgotPassword && <a className="link forgotPassword" onClick={ onForgotPassword }><span>{ _s(StringKey.FORGOT_PASSWORD_QUESTION) }</span></a> }
				<a className="link otherApp tackle" onClick={ (e) => actions.browseTo(`https://www.tacklefootballplaymaker.com/app`) }><span>{ _s(StringKey.LOOKING_FOR_TACKLE_APP) }</span></a>
				<a className="link otherApp flag" onClick={ (e) => actions.browseTo(`https://www.flagfootballplaymaker.com/app`) }><span>{ _s(StringKey.LOOKING_FOR_FLAG_APP) }</span></a>
			</div>
		</div>
	</React.Fragment>;
};

export const ResetPasswordPage = ({ alertContainer, credentials, onResetPassword, onBack, onKeyPress, updateCredentials }) => {
	const enabled = !!credentials.emailAddress;

	return <React.Fragment>
		<header>
			<div className="actions">
				<a className="button" onClick={ onBack }><span className="icon previous"></span></a>
			</div>
			<div className="title">{ _s(StringKey.RESET_PASSWORD) }</div>
		</header>
		{ alertContainer }
		<div className="content scrollable">
			<div className="inner compact" onKeyPress={ onKeyPress }>
				<input type="email" value={ credentials.emailAddress } placeholder={ _s(StringKey.EMAIL_ADDRESS) } onChange={ (e) => updateCredentials('emailAddress', e.target.value) } required />
				<div className="actions">
					<a className={ enabled ? 'button basic fullWidth' : 'button basic fullWidth disabled' } onClick={ onResetPassword }><span className="icon"></span><span>{ _s(StringKey.RESET_PASSWORD) }</span><span className="icon navigate"></span></a>
				</div>
			</div>
		</div>
	</React.Fragment>;
};

export class Login extends React.Component<Props, State> {
	constructor(props) {
		super(props);

		this.state = { credentials: { emailAddress: '', password: '' }, activePage: 0 };

		this.onLoginClick = this.onLoginClick.bind(this);
		this.updateCredentials = this.updateCredentials.bind(this);
		this.resetPassword = this.resetPassword.bind(this);
		this.onEnterKeyPress = this.onEnterKeyPress.bind(this);

		this.logScreen();
	}

	public getPageState(key) {
		const { activePage } = this.state;

		if (key === activePage) {
			return PageState.IN;
		} else if (key < activePage) {
			return PageState.OUT;
		}

		return PageState.DEFAULT;
	}

	public logScreen() {
		const { activePage } = this.state;

		switch (activePage) {
		case 0:
			logger.logScreen('Login');
			break;
		case 1:
			logger.logScreen('ResetPassword');
			break;
		case 2:
			logger.logScreen('EnterTempPassword');
			break;

		default:
			// code...
			break;
		}
	}

	public updateCredentials(key: string, value: string) {
		this.setState((previousState) => {
			const newState = assign({}, previousState) as State;

			newState.credentials[key] = value;

			return newState;
		});
	}

	public async resetPassword(e) {
		const { credentials } = this.state;

		if (credentials.emailAddress) {
			this.setState({ processing: true }, async () => {
				actions.clearAlerts();
				await actions.requestPasswordReset(credentials.emailAddress);

				const alerts = store.appState().viewState.alerts;
				if (alerts.length === 0) {
					this.goToPage(2);
				}

				this.setState({ processing: false });
			});
		}
	}

	public async onLoginClick(e) {
		const { credentials } = this.state;

		if (credentials.emailAddress && credentials.password) {
			this.setState({ processing: true }, async () => {
				actions.clearAlerts();
				await actions.login(credentials.emailAddress, credentials.password);

				if (store.appState().viewState.currentUserId) {
					viewManager.popModal();
				}

				this.setState({ processing: false });
			});
		}
	}

	public onEnterKeyPress(e) {
		const { activePage } = this.state;

		if (e.key === 'Enter') {
			if (activePage === 1) {
				this.resetPassword(e);
			} else {
				this.onLoginClick(e);
			}
		}
	}

	public goToPage(page: number) {
		this.setState((previousState) => {
			const newState = assign({}, previousState) as State;

			newState.activePage = page;

			return newState;
		}, () => { this.logScreen(); });
	}

	public render() {
		const { alerts } = this.props;
		const { credentials, processing } = this.state;
		const alertContainer = <AlertList alerts={ alerts } />;

		return <React.Fragment>
			{ processing ? <Spinner /> : null }
			<Page key="page0" pageClasses={['page']} pageState={ this.getPageState(0) }>
				<LoginPage alertContainer={ alertContainer } credentials={ credentials } onLogin={ this.onLoginClick } updateCredentials={ this.updateCredentials } onForgotPassword={ this.goToPage.bind(this, 1) } onKeyPress={ this.onEnterKeyPress } />
			</Page>
			<Page key="page1" pageClasses={['page']} pageState={ this.getPageState(1) }>
				<ResetPasswordPage alertContainer={ alertContainer } credentials={ credentials } onBack={ this.goToPage.bind(this, 0) } onResetPassword={ this.resetPassword } updateCredentials={ this.updateCredentials } onKeyPress={ this.onEnterKeyPress } />
			</Page>
			<Page key="page2" pageClasses={['page']} pageState={ this.getPageState(2) }>
				<LoginPage alertContainer={ alertContainer } credentials={ credentials } onLogin={ this.onLoginClick } updateCredentials={ this.updateCredentials } message={ _s(StringKey.TEMPORARY_PASSWORD_SENT_MESSAGE) } onKeyPress={ this.onEnterKeyPress } />
			</Page>
		</React.Fragment>;
	}
}

import { Action } from 'history';
import { default as router, IRoute, IRouteMatchState } from 'playmaker-team-common/dist/client/router';
import * as actions from './actions';
import { current as getCurrentContext } from './componentContext';
import * as logger from './logger';
import { appState, IModal } from './store';

let _routeStack: string[] = [];
let _modalScreenStack: string[] = [];
let _virtualRoot = null;
let _modals: {[key: string]: any };

function getVirtualRoot() {
	if (_virtualRoot === null) {
		const { viewState } = getCurrentContext();
		const config = viewState.config;

		_virtualRoot = config.virtualRoot || '/';
	}

	return _virtualRoot;
}

function resolvePath(path) {
	const virtualRoot = getVirtualRoot();

	if (virtualRoot === '/') {
		return path;
	}

	return getVirtualRoot() + path;
}

function isNonWrappedMobile() {
	const { viewState } = getCurrentContext();
	const { platform } = viewState;

	return !platform.isWrapped && ['ios', 'android'].indexOf(platform.os) !== -1;
}

function isInvalidSubscription() {
	const { currentSubscription } = getCurrentContext();

	return !currentSubscription || !currentSubscription.isActive();
}

function canNavigate(path) {
	if (path !== getVirtualRoot() && path !== resolvePath('/')) {
		if (isNonWrappedMobile()) {
			actions.pushModal({
				component: _modals.MobileBrowserPrompt,
			});

			return false;
		}

		if (isInvalidSubscription()) {
			const { currentUser } = getCurrentContext();

			if (currentUser) {
				pushModal({
					component: _modals.ConnectionStatusPrompt,
					props: () => ({ appState: appState(), classNames: ['prompt'] }),
					screenName: 'ConnectionStatus',
				});
			}

			return false;
		}
	}

	return true;
}

export function parseQuery() {
	const search = router.history.location.search;
	const result: {[key: string]: any} = {};
	const params = search ? search.substr(1).split('&') : [];

	for (const param of params) {
		const parts = param.split('=');

		result[parts[0]] = parts.length > 1 ? parts[1] : true;
	}

	return result;
}

export function init(modals) {
	_modals = modals;

	if (!canNavigate(router.history.location.pathname)) {
		reset();

		return;
	}

	_routeStack.push(router.history.location.pathname);
	router.setLocation(router.history.location, Action.Push);
}

export function pushPath(path: string, e?) {
	const resolvedPath = resolvePath(path);

	if (canNavigate(resolvedPath)) {
		_routeStack.push(resolvedPath);
		router.pushHistory(resolvedPath);
		// console.log('pushPath', _routeStack);
	}
}

export function popPath(e?) {
	_routeStack.pop();

	if (!_routeStack.length) {
		_routeStack.push(getVirtualRoot());
	}

	const path = _routeStack[_routeStack.length - 1];

	router.pushHistory(path);
	// console.log('popPath', _routeStack);
}

export function popReplace(e?) {
	_routeStack.pop();

	if (!_routeStack.length) {
		_routeStack.push(getVirtualRoot());
	}

	const path = _routeStack[_routeStack.length - 1];

	router.replaceHistory(path);
	// console.log('popReplace', _routeStack);
}

export function reset(e?) {
	const resolvedPath = resolvePath('/');

	_routeStack = [resolvedPath];
	router.replaceHistory(resolvedPath);
	// console.log('rewritePath', _routeStack);
}

export function reloadApp(e?) {
	const initialSearch = appState().viewState.initialLocationSearch;
	const resolvedPath = `${location.protocol}//${location.host}${resolvePath('/')}${initialSearch}`;

	if (location.href === resolvedPath) {
		location.reload();
	} else {
		location.href = resolvedPath;
	}
}

export function rewritePath(path: string, e?) {
	const resolvedPath = resolvePath(path);

	if (canNavigate(resolvedPath)) {
		if (_routeStack.length) {
			_routeStack.splice(_routeStack.length - 1, 1, resolvedPath);
			router.replaceHistory(resolvedPath);
		} else {
			_routeStack.push(resolvedPath);
			router.replaceHistory(resolvedPath);
		}
		// console.log('rewritePath', _routeStack);
	}
}

export function isOnTop(route: IRoute): boolean {
	const stackLength = _routeStack.length;

	if (stackLength && route && route.regex) {
		const top = _routeStack[stackLength - 1];

		return route.regex.test(top);
	}

	return false;
}

export function isInStack(route: IRoute): boolean {
	const stackLength = _routeStack.length;

	if (route && route.regex) {
		for (let i = stackLength - 1; i >= 0; i--) {
			const path = _routeStack[i];

			if (route.regex.test(path)) {
				return true;
			}
		}
	}

	return route.path === resolvePath('/'); // this ensures the root route won't enter from the right
}

export function pushModal(modal: IModal) {
	if (!modal.screenName) {
		throw new Error('View Manager only supports modals with a screenName');
	}

	actions.pushModal(modal);
	trackModalPush(modal);
}

export function popModal(e?, modalId?: string) {
	if(modalId) {
		actions.deleteModal(modalId);
	}
	else {
		actions.popModal(e);
	}
	trackModalPop();
}

export function pushPanel(panel: IModal) {
	if (!panel.screenName) {
		throw new Error('View Manager only supports panels with a screenName');
	}

	actions.pushPanel(panel);
	trackModalPush(panel);
}

export function popPanel(e?) {
	actions.popPanel(e);
	trackModalPop();
}

function trackModalPush(modal: IModal) {
	_modalScreenStack.push(modal.screenName);
	logScreen(modal.screenName);
}

function trackModalPop() {

	_modalScreenStack.pop();
	if (_modalScreenStack.length) {
		logScreen(_modalScreenStack[_modalScreenStack.length - 1]);
	} else {
		const { viewState } = getCurrentContext();
		const currentRoute = viewState.currentRoute;
		const screenName = currentRoute && currentRoute.route && (currentRoute.route as any).screenName;

		if (screenName) {
			logScreen(screenName);
		}
	}
}

function logScreen(screenName: string) {
	logger.logScreen(screenName);
}

// try to stay in sync with the browser nav
router.onRouteChange(({location, action, previous, current}) => {
	const route = current.route;
	const screenName = (route as any).screenName;

	if (screenName) {
		logScreen(screenName);
		_modalScreenStack = [screenName];
	}

	if (!isOnTop(route)) {
		const stackLength = _routeStack.length;
		let matchedIndex = -1;

		if (route && route.regex) {
			for (let i = stackLength - 1; i >= 0; i--) {
				const path = _routeStack[i];

				if (route.regex.test(path)) {
					matchedIndex = i;
					break;
				}
			}
		}

		if (matchedIndex !== -1) {
			_routeStack = _routeStack.slice(0, matchedIndex + 1);
		} else {
			_routeStack.push(current.location.pathname);
		}
		// console.log('onRouteChange', _routeStack);
	}
});

import { map } from 'lodash';
import * as React from 'react';
import { IPlaybook } from '../models/playbook';

interface Props {
	cancelLabel: string;
	description: string;
	okLabel: string;
	onCancel: (e?) => void;
	onOk: (playbooks: IPlaybook[]) => void;
	playbooks: IPlaybook[];
	title: string;
}

interface State {
	selectedItems: string[];
}

export class PlaybookSelector extends React.Component<Props, State> {
	constructor(props) {
		super(props);

		this.handleOkClick = this.handleOkClick.bind(this);

		this.state = { selectedItems: [] };
	}

	public handleOkClick(e) {
		const { onOk, playbooks } = this.props;
		const { selectedItems } = this.state;
		const selectedPlaybooks = [];

		for (const playbook of playbooks) {
			if (selectedItems.indexOf(playbook.id) !== -1) {
				selectedPlaybooks.push(playbook);
			}
		}

		if (selectedPlaybooks.length) {
			onOk(selectedPlaybooks);
		}
	}

	public handlePlaybookSelected(id, e) {
		const { selectedItems } = this.state;
		const updatedItems = selectedItems.concat();
		const itemIndex = updatedItems.indexOf(id);

		if (itemIndex === -1) {
			updatedItems.push(id);
		} else {
			updatedItems.splice(itemIndex, 1);
		}

		this.setState({ selectedItems: updatedItems });
	}

	public render() {
		const { cancelLabel, description, okLabel, onCancel, playbooks, title } = this.props;
		const { selectedItems } = this.state;

		return <div className="view">
			<header>
				<div className="title">{ title }</div>
			</header>
			<div className="content scrollable">
				<div className="inner">
					<p>{ description }</p>

					<div className="group" style={ { height: '130px', marginBottom: '20px'} }>
						<div className="list scrollable">
							{
								map(playbooks, (playbook: IPlaybook) => {
									const isSelected = selectedItems.indexOf(playbook.id) !== -1;

									return <div key={ playbook.id } className="checkbox" onClick={ this.handlePlaybookSelected.bind(this, playbook.id) }><label className={ isSelected ? 'on' : null }><span className="icon checkmark"></span><span>{ playbook.name }</span></label></div>;
								})
							}
						</div>
					</div>

					<div className="actions">
						<a className="button basic cancel" onClick={ onCancel }><span>{ cancelLabel }</span></a>
						<a className={ selectedItems.length ? 'button basic' : 'button basic disabled'} onClick={ this.handleOkClick }><span>{ okLabel }</span></a>
					</div>
				</div>
			</div>
		</div>;
	}
}

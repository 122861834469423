import { assign, filter, find, map, sortBy } from 'lodash';
import * as React from 'react';
import {loadStripe, Stripe} from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import * as actions from '../actions';
import { current as getCurrentContext } from '../componentContext';
import * as logger from '../logger';
import { ISubscription } from '../models/subscription';
import { ISubscriptionPlan, SystemFeature } from '../models/subscriptionPlan';
import { ISubscriptionPlanUpgrade } from '../models/subscriptionPlanUpgrade';
import { ISubscriptionUpgrade } from '../models/subscriptionUpgrade';
import { ITeam } from '../models/team';
import * as store from '../store';
import { _s, StringKey } from '../strings';
import * as viewManager from '../viewManager';
import { AlertList } from './alert';
import { CachedImage } from './cachedImage';
import { CardForm } from './cardForm';
import { Page, PageState } from './page';
import { Spinner } from './spinner';
import { TransferOwnershipTeamMemberSelector } from './transferOwnershipModal';

const PlanTileDescription = ({ item }: { item: ISubscription | ISubscriptionPlan | ISubscriptionUpgrade | ISubscriptionPlanUpgrade}) => {
	return <div className="description" dangerouslySetInnerHTML={{ __html: item.description }} />;
};

const PlanTileIcon = ({ item, currentSubscription }: { item: ISubscription | ISubscriptionPlan | ISubscriptionUpgrade | ISubscriptionPlanUpgrade, currentSubscription: ISubscription}) => {
	let sponsorLogoUrl: string;

	if (currentSubscription && currentSubscription.settings && currentSubscription.settings.assets) {
		const logoAsset = find(currentSubscription.settings.assets, { key: 'sponsorLogo'});

		sponsorLogoUrl = logoAsset && logoAsset.url;
	}

	if (item.id === currentSubscription.id && sponsorLogoUrl) {
		return <div className="icon photo" style={{ background: 'transparent' }}><CachedImage key={ sponsorLogoUrl } src={ sponsorLogoUrl } /></div>;
	} else if (item.hasFeature(SystemFeature.collaboration)) {
		return <div className="icon subscriptionPlanTeam"></div>;
	} else if (item.hasFeature(SystemFeature.print)) {
		return <div className="icon subscriptionPlanPrint"></div>;
	} else {
		return <div className="icon subscriptionPlanPaperless"></div>;
	}
};

const PlanTileDetail = ({ item, dueDate, expirationDate, remainingDays }: { item: ISubscriptionPlan | ISubscription | ISubscriptionUpgrade | ISubscriptionPlanUpgrade, dueDate?: Date, expirationDate?: Date, remainingDays: number}) => {
	if (dueDate || expirationDate) {
		let statusTemplate;
		let targetDate;

		if (remainingDays <= 0) {
			statusTemplate = _s(StringKey.BILLING_PLAN_EXPIRED_ON_TEMPLATE);
			targetDate = (expirationDate || dueDate);
		} else if (dueDate) {
			statusTemplate = _s(StringKey.BILLING_PLAN_RENEWS_ON_TEMPLATE);
			targetDate = dueDate;
		} else {
			statusTemplate = _s(StringKey.BILLING_PLAN_EXPIRES_ON_TEMPLATE);
			targetDate = expirationDate;
		}

		return <div className="detail" dangerouslySetInnerHTML={ {__html: statusTemplate.replace('{date}', targetDate.toLocaleDateString()) }}></div>;
	} else {
		const planOrUpgrade = (item as any);

		if (planOrUpgrade.iapProduct) {
			return <div className="detail">{ planOrUpgrade.iapProduct.priceDisplay }<span>/{ _s(StringKey.MONTH).toLowerCase()}</span></div>;
		} else {
			const price = planOrUpgrade.proratedPrice || planOrUpgrade.price;

			return planOrUpgrade.proratedPrice ? <div className="detail">${ price }</div> : <div className="detail">${ price } USD<span>/{ _s(StringKey.MONTH).toLowerCase()}</span></div>;
		}

	}
};

const PlanTile = ({ item, dueDate, expirationDate, onClick, currentSubscription }: { item: ISubscription | ISubscriptionPlan | ISubscriptionUpgrade | ISubscriptionPlanUpgrade, dueDate?: Date, expirationDate?: Date, onClick?: any, currentSubscription: ISubscription }) => {
	const isSubscription = !!(item as ISubscription).teamId;
	const isSubscriptionUpgrade = !!(item as ISubscriptionUpgrade).subscriptionId;
	const subscription: ISubscription = isSubscription ? item as ISubscription : null;
	const remainingDays = subscription ? Math.max(subscription.getRemainingDays(), 0) : 1;
	const expireTemplate = remainingDays <= 0 ? _s(StringKey.BILLING_PLAN_EXPIRED_ON_TEMPLATE) : _s(StringKey.BILLING_PLAN_EXPIRES_ON_TEMPLATE);

	if (item.isFreeTrial()) {
		return <div className="tile subscriptionPlan current">
			<div className="branding">
				<div className="icon subscriptionPlanFreeTrial"></div>
				<div className="label">{ item.name }</div>
			</div>
			<PlanTileDescription item={ item } />
			<div className="detail" dangerouslySetInnerHTML={ {__html: expireTemplate.replace('{date}', expirationDate.toLocaleDateString()) }}></div>
		</div>;
	} else {
		return <div className={ isSubscription || isSubscriptionUpgrade ? 'tile subscriptionPlan current' : 'tile subscriptionPlan' }>
			<div className="branding">
				<PlanTileIcon item={ item } currentSubscription={ currentSubscription } />
				<div className="label">{ item.name }</div>
			</div>
			<PlanTileDescription item={ (item as any).subscriptionPlan || item } />
			<PlanTileDetail item={ item } dueDate={ dueDate } expirationDate={ expirationDate } remainingDays={ remainingDays } />
			{ subscription || !onClick ? null : <a className="button" onClick={ () => onClick() }></a> }
		</div>;
	}
};

// these are sponsored plans, promo codes and product purchase plans
const CurrentFixedDurationPlan = ({ currentSubscription, gotoPayment }: { currentSubscription: ISubscription, gotoPayment: any }) => {
	// gotoPayment will take them to the proper paged based on the current plan and device context
	const subscriptionPlan: ISubscriptionPlan = currentSubscription.subscriptionPlan;
	const planUpgrades = subscriptionPlan ? sortBy(subscriptionPlan.upgrades.values, 'featureSort') : [];
	let maxFeatureSort = currentSubscription.featureSort;
	const activeUpgrades = map(filter(planUpgrades, (pu) => !!find(currentSubscription.upgrades.values, { subscriptionPlanUpgradeId: pu.id })), (pu) => {
		if (pu.featureSort > maxFeatureSort) {
			maxFeatureSort = pu.featureSort;
		}
		return find(currentSubscription.upgrades.values, { subscriptionPlanUpgradeId: pu.id });
	});
	const availableUpgrades = map(filter(planUpgrades, (pu) => pu.featureSort > maxFeatureSort && !find(currentSubscription.upgrades.values, { subscriptionPlanUpgradeId: pu.id })), (pu) => {
		pu.proratedPrice = subscriptionPlan.getProratedUpgradePrice(pu, currentSubscription);

		return pu;
	});

	return <div className="inner compact">
		<h2>{ _s(StringKey.BILLING_CURRENT_PLAN) }</h2>

		<PlanTile item={ currentSubscription } currentSubscription={ currentSubscription } dueDate={ currentSubscription.dueDate} expirationDate={ currentSubscription.expirationDate } />

		{ activeUpgrades.length ? <h2>{ _s(StringKey.BILLING_ACTIVE_UPGRADES) }</h2> : null }

		{ map(activeUpgrades, (item) => <PlanTile key={ item.id } item={ item } currentSubscription={ currentSubscription } dueDate={ currentSubscription.dueDate} expirationDate={ currentSubscription.expirationDate } />) }

		{ availableUpgrades.length ? <h2>{ _s(StringKey.BILLING_ADD_FEATURES) }</h2> : null }

		{ map(availableUpgrades, (item) => <PlanTile key={ item.id } item={ item } currentSubscription={ currentSubscription } onClick={ gotoPayment.bind(this, { plan: subscriptionPlan, planUpgrade: item }) }/>) }

		<h2 className="cancelMessage">{ _s(StringKey.BILLING_PAUSE_ACCOUNT) }</h2>
		<p>{ _s(StringKey.BILLING_PAUSE_ACCOUNT_EXPIRING_MESSAGE) }</p>
	</div>;
};

const CurrentFreeTrial = ({ currentSubscription, gotoPayment, gotoPromo, plans}: { currentSubscription: ISubscription, gotoPayment: any, gotoPromo: any, plans: ISubscriptionPlan[] }) => {
	// gotoPayment will take them to the proper paged based on the current plan and device context
	return <div className="inner compact">
		<PlanTile item={ currentSubscription } currentSubscription={ currentSubscription } dueDate={ currentSubscription.dueDate} expirationDate={ currentSubscription.expirationDate } />

		{ map(plans, (item) => <PlanTile key={ item.id } item={ item } currentSubscription={ currentSubscription } onClick={ gotoPayment.bind(this, { plan: item }) }/>) }

		<p><a className="link" onClick={ gotoPromo }><strong>{ _s(StringKey.BILLING_HAVE_AN_UPGRADE_CODE) }</strong></a></p>
	</div>;
};

const CurrentExpiredPlan = ({ currentSubscription, gotoPayment, gotoPromo, plans}: { currentSubscription: ISubscription, gotoPayment: any, gotoPromo: any, plans: ISubscriptionPlan[] }) => {
	// gotoPayment will take them to the proper paged based on the current plan and device context
	const initiateTransfer = () => {
		viewManager.pushModal({
			component: TransferOwnershipTeamMemberSelector,
			props: { classNames: ['prompt'] },
			screenName: 'TransferOwnershipTeamMemberSelector'
		});
	}
	return <div className="inner compact">
		{ map(plans, (item) => <PlanTile key={ item.id } item={ item } currentSubscription={ currentSubscription } onClick={ gotoPayment.bind(this, { plan: item }) }/>) }

		<p><a className="link" onClick={ gotoPromo }><strong>{ _s(StringKey.BILLING_HAVE_AN_UPGRADE_CODE) }</strong></a></p>

		<p><a className="link small" onClick={initiateTransfer}>{ _s(StringKey.TRANSFER_OWNERSHIP) }</a></p>
	</div>;
};

const CurrentPaidPlan = ({ currentSubscription, doPause, isTrolledGarden, pendingSubscription, gotoPayment, gotoPlanChange }: { currentSubscription: ISubscription, doPause: any, isTrolledGarden: boolean, pendingSubscription?: ISubscription, gotoPayment: any, gotoPlanChange: any }) => {
	if (!currentSubscription) {
		return null;
	}

	const iapTroll = currentSubscription.getIapTroll();
	const paymentMethodHasProblem = (currentSubscription.paymentMethod && currentSubscription.paymentMethod.hasExpired()) || currentSubscription.isDue() || (!iapTroll && !currentSubscription.expirationDate && !currentSubscription.paymentMethod);

	return <div className="inner compact">
		<h2>{ _s(StringKey.BILLING_CURRENT_PLAN) }</h2>

		<PlanTile item={ currentSubscription } currentSubscription={ currentSubscription } dueDate={ currentSubscription.dueDate} expirationDate={ currentSubscription.expirationDate } />

		{ isTrolledGarden || !iapTroll ? null : <p><a className="button basic" onClick={ gotoPlanChange }>{ _s(StringKey.BILLING_RENEW_OR_UPGRADE) }</a></p> }

		{ isTrolledGarden || iapTroll ? null : <p><a className="link small" onClick={ gotoPlanChange }>{ pendingSubscription ? _s(StringKey.BILLING_SELECT_PLAN) : _s(StringKey.BILLING_CHANGE_PLAN) }</a></p> }

		{ iapTroll || !!currentSubscription.expirationDate ? null :  <h2>{ _s(StringKey.BILLING_PAYMENT_METHOD) }</h2> }

		{ iapTroll || !!currentSubscription.expirationDate ? null :  <div className={ paymentMethodHasProblem ?  'tile paymentMethod error' : 'tile paymentMethod' }>
			<div className="description">{ (currentSubscription.paymentMethod && currentSubscription.paymentMethod.name) || _s(StringKey.ADD_PAYMENT_METHOD) }</div>
			<a className="button" onClick={ gotoPayment.bind(this, null) }></a>
		</div> }

		{ iapTroll || !!currentSubscription.expirationDate ? null : <p><a className="link small" onClick={ gotoPayment.bind(this, null) }>{ _s(StringKey.EDIT) }</a></p> }

		{ iapTroll ? <React.Fragment><h2 className="cancelMessage">{ _s(StringKey.BILLING_PAUSE_ACCOUNT) }</h2><p>{ _s(StringKey.BILLING_PAUSE_ACCOUNT_EXPIRING_MESSAGE) }</p></React.Fragment> :  <a className="button basic" onClick={ () => doPause() }>{ currentSubscription.dueDate || pendingSubscription ? _s(StringKey.BILLING_PAUSE_ACCOUNT) :  _s(StringKey.BILLING_UPAUSE_ACCOUNT) }</a> }
	</div>;
};

const CurrentPlanPage = ({ alertContainer, cancelAction, doPause, currentSubscription, gotoPlanChange, gotoPayment, gotoPromo, isTrolledGarden, pendingSubscription, plans, title, unclaimedIapContent }: { alertContainer: any, cancelAction: any, doPause: any, currentSubscription: ISubscription, gotoPlanChange: any, gotoPayment: any, gotoPromo: any, isTrolledGarden: boolean, pendingSubscription: ISubscription, plans: ISubscriptionPlan[], title: string, unclaimedIapContent: any }) => {
	if (!currentSubscription) {
		return null;
	}

	let content;
	const alerts = [];
	const iapTroll = currentSubscription.getIapTroll();

	if (unclaimedIapContent) {
		content = unclaimedIapContent;
	} else if (currentSubscription.hasExpired() || currentSubscription.hasEnded()) {
		alerts.push({ id: 'billing-plan-expired', message: _s(StringKey.BILLING_PLAN_EXPIRED_MESSAGE), severity: store.AlertSeverity.error });
		content = <CurrentExpiredPlan currentSubscription={ currentSubscription } gotoPayment={ gotoPayment } gotoPromo={ gotoPromo} plans={ plans } />;
	} else if (currentSubscription.isFreeTrial()) {
		content = <CurrentFreeTrial currentSubscription={ currentSubscription } gotoPayment={ gotoPayment } gotoPromo={ gotoPromo} plans={ plans } />;
	} else if (currentSubscription.subscriptionPlan || currentSubscription.isPromo()) { // only subscriptions that have plans with upgrades have their subscriptionPlan populated (sponsored and product purchases)
		content = <CurrentFixedDurationPlan currentSubscription={ currentSubscription } gotoPayment={ gotoPayment } />;
	}  else {
		content = <CurrentPaidPlan currentSubscription={ currentSubscription } doPause={ doPause } isTrolledGarden={ isTrolledGarden } pendingSubscription={ pendingSubscription } gotoPayment={ gotoPayment } gotoPlanChange={ gotoPlanChange } />;

		if (!isTrolledGarden) {
			if (currentSubscription.isDue()) {
				alerts.push({ id: 'billing-renew-failed', message: _s(StringKey.BILLING_AUTO_RENEW_FAILED_MESSAGE), severity: store.AlertSeverity.error, actions: [{ label: '', action: gotoPayment.bind(this, null) }]  });
			} else if (currentSubscription.paymentMethod && currentSubscription.paymentMethod.hasExpired() && !currentSubscription.expirationDate) {
				alerts.push({ id: 'billing-payment-expired',  message: _s(StringKey.BILLING_PAYMENT_METHOD_PROBLEM_MESSAGE), severity: store.AlertSeverity.error, actions: [{ label: '', action: gotoPayment.bind(this, null) }] });
			}

			if (pendingSubscription) {
				alerts.push({ id: 'billing-pending-subscription', message: _s(StringKey.BILLING_PENDING_DOWNGRADE_MESSAGE_TEMPLATE).replace('{plan_name}', pendingSubscription.name).replace('{date}', currentSubscription.expirationDate.toLocaleDateString()), severity: store.AlertSeverity.info });
			} else if (currentSubscription.expirationDate && !iapTroll) {
				alerts.push({ id: 'billing-plan-paused', message: _s(StringKey.BILLING_PLAN_PAUSED_UNPAUSE_MESSAGE), severity: store.AlertSeverity.info, actions: [{ label: '', action: doPause }] });
			}
		}
	}

	return <React.Fragment>
		<header>
			<div className="actions">
				<a className="button" onClick={ cancelAction }><span className="icon cancel"></span></a>
			</div>
			<div className="title">{ title }</div>
		</header>
		{
			alerts.length ? <AlertList alerts={ alerts } preventDismiss={ true } /> : null
		}
		{ alertContainer }
		<div className="content scrollable">
			{ content }
		</div>
	</React.Fragment>;
};

// can only be reached outside a trolled garden
const ChangePlanPage = ({ alertContainer, backAction, currentSubscription, pendingSubscription, gotoDowngrade, gotoPayment, gotoUpgrade, upgrade, plans }: { alertContainer: any, backAction: any, currentSubscription: ISubscription, pendingSubscription?: ISubscription, gotoDowngrade: any, gotoPayment: any, gotoUpgrade: any, upgrade: any, plans: ISubscriptionPlan[]}) => {
	const iapTroll = currentSubscription.getIapTroll();

	return <React.Fragment>
		<header>
			<div className="actions">
				<a className="button" onClick={ backAction }><span className="icon back"></span></a>
			</div>
			<div className="title">{ pendingSubscription ? _s(StringKey.BILLING_SELECT_PLAN) : _s(StringKey.BILLING_CHANGE_PLAN) }</div>
		</header>
		{ alertContainer }
		<div className="content scrollable">
			{ currentSubscription ? <div className="inner compact">
				{
					map(plans, (item: ISubscriptionPlan) => {
						let clickHandler = null;
						let tileItem: any = item;
						let dueDate;
						let expirationDate;

						if (currentSubscription.featureSort > item.featureSort) {
							clickHandler = (pendingSubscription && pendingSubscription.featureSort === item.featureSort) ? backAction : iapTroll ? null : gotoDowngrade.bind(this, { plan: item }); // we don't currently support downgrading from an iap plan
						} else if (currentSubscription.featureSort < item.featureSort || (iapTroll && currentSubscription.featureSort === item.featureSort)) {
							clickHandler = iapTroll || !currentSubscription.paymentMethod || currentSubscription.paymentMethod.hasExpired() ? gotoPayment.bind(this, { plan: item }) : gotoUpgrade.bind(this, { plan: item });
						} else {
							if (pendingSubscription) {
								clickHandler = () => upgrade(item);
							} else {
								tileItem = currentSubscription;
								dueDate = currentSubscription.dueDate;
								expirationDate = currentSubscription.expirationDate;
							}
						}

						return <PlanTile key={ tileItem.id } currentSubscription={ currentSubscription } dueDate={ dueDate} expirationDate={ expirationDate } item={ tileItem } onClick={ clickHandler }/>;
					})
				}
			</div> : null }
		</div>
	</React.Fragment>;
};

// can only be reached outside a trolled garden
const DowngradePage = ({ alertContainer, currentSubscription, downgrade, backAction, plan }: { alertContainer: any, currentSubscription: ISubscription, downgrade: any, backAction: any, plan: ISubscriptionPlan }) => {
	const date = currentSubscription && (currentSubscription.dueDate || currentSubscription.expirationDate);

	return <React.Fragment>
		<header>
			<div className="actions">
				<a className="button" onClick={ backAction }><span className="icon back"></span></a>
			</div>
			<div className="title">{ _s(StringKey.BILLING_DOWNGRADE) }</div>
		</header>
		{ alertContainer }
		<div className="content scrollable">
			{ plan && date ? <div className="inner compact">

				<h2>
					{ plan.name }<br/>
					<span>{ _s(StringKey.BILLING_PRICE_DESCRIPTION_TEMPLATE).replace('{price}', plan.price.toFixed(0)) }</span>
				</h2>

				<a className="button basic payment" onClick={ () => downgrade(plan) }>{ _s(StringKey.BILLING_DOWNGRADE) }</a>

				<p>{ _s(StringKey.BILLING_DOWNGRADE_DESCRIPTION_TEMPLATE).replace('{date}', date.toLocaleDateString() )}</p>

			</div> : null }
		</div>

	</React.Fragment>;
};

// can only be reached outside a trolled garden
const UpgradePage = ({ alertContainer, currentSubscription, upgrade, backAction, plan }: { alertContainer: any, currentSubscription: ISubscription, upgrade: any, backAction: any, plan: ISubscriptionPlan }) => {

	return <React.Fragment>
		<header>
			<div className="actions">
				<a className="button" onClick={ backAction }><span className="icon back"></span></a>
			</div>
			<div className="title">{ _s(StringKey.UPGRADE) }</div>
		</header>
		{ alertContainer }
		<div className="content scrollable">
			{ plan && currentSubscription ? <div className="inner compact">

				<h2>
					{ plan.name }<br/>
					<span>{ _s(StringKey.BILLING_PRICE_DESCRIPTION_TEMPLATE).replace('{price}', plan.price.toFixed(0)) }</span>
				</h2>

				<a className="button basic payment" onClick={ () => upgrade(plan) }>{ _s(StringKey.UPGRADE_NOW) }</a>

				<p>{ _s(StringKey.BILLING_PLAN_UPGRADE_DESCRIPTION)}</p>

			</div> : null }
		</div>

	</React.Fragment>;
};

const PausePage = ({ alertContainer, currentSubscription, doPause, backAction }: { alertContainer: any, currentSubscription: ISubscription, doPause: any, backAction: any }) => {
	if (!currentSubscription) {
		return null;
	}

	return <React.Fragment>
		<header>
			<div className="actions">
				<a className="button" onClick={ backAction }><span className="icon back"></span></a>
			</div>
			<div className="title">{ _s(StringKey.BILLING_PAUSE_ACCOUNT) }</div>
		</header>
		{ alertContainer }
		<div className="content scrollable">
			<div className="inner compact">

				<h2>
					<span dangerouslySetInnerHTML={{ __html: _s(StringKey.BILLING_PAUSE_ACCOUNT_CONFIRM_TEMPLATE).replace('{date}', (currentSubscription.dueDate || currentSubscription.expirationDate).toLocaleDateString()) }} />
				</h2>

				<a className="button basic" onClick={ () => doPause() }>{ _s(StringKey.BILLING_PAUSE_ACCOUNT) }</a>

			</div>
		</div>

	</React.Fragment>;
};

const MakePaymentHeader = ({ currentSubscription, plan, planUpgrade }) => {
	const name = (planUpgrade && planUpgrade.name) || plan.name;
	const price = (planUpgrade && planUpgrade.proratedPrice) || plan.price;

	return 	<h2>
		{ name }<br/>
		<span>{ planUpgrade ? _s(StringKey.BILLING_UPGRADE_PRICE_DESCRIPTION_TEMPLATE).replace('{price}', price).replace('{date}', currentSubscription.expirationDate.toLocaleDateString()) : _s(StringKey.BILLING_PRICE_DESCRIPTION_TEMPLATE).replace('{price}', price) }</span>
	</h2>;
};

// can only be reached outside a trolled garden
let _stripePromise:Promise<Stripe>;
function PaymentPage({ alertContainer, currentSubscription, backAction, plan, planUpgrade, stripeKey, onUpgradeClick, onUpdateClick }) {
	const [ state, setState ] = React.useState(() => { return { ccFormComplete: false, ccFormDirty: false }});
	// delay loading stripe until the payment page is opened
	_stripePromise = _stripePromise?? loadStripe(stripeKey);

	let header;
	let upgradeDescription;
	let buttonLabel;

	if (!currentSubscription) {
		return null;
	}

	const handleCardChange = (e) => {
		if (e.source === 'card') {// this is a card element event
			setState(Object.assign({}, state, {
				ccFormComplete: e.complete,
				ccFormDirty: !e.empty,
			}));
		}
	}

	const doUpgrade = (createToken: () => Promise<any>) => {
		const { ccFormComplete } = state;

		if (ccFormComplete) {
			onUpgradeClick(plan, planUpgrade, createToken);
		}
	}

	const doUpdate = (createToken: () => Promise<any>) => {
		const { ccFormComplete } = state;

		if (ccFormComplete) {
			onUpdateClick(createToken);
		}
	}

	if (plan) {
		header = <MakePaymentHeader currentSubscription={ currentSubscription } plan={ plan } planUpgrade={ planUpgrade } />;
		buttonLabel = _s(StringKey.UPGRADE_NOW);
		if (!currentSubscription.hasExpired() && currentSubscription.paymentAmount && !planUpgrade) {
			upgradeDescription = <p>{ _s(StringKey.BILLING_PLAN_UPGRADE_DESCRIPTION)}</p>;
		}
	} else {
		header = currentSubscription.isDue() ? <MakePaymentHeader currentSubscription={ currentSubscription } plan={ { name: currentSubscription.name, price: currentSubscription.paymentAmount} } planUpgrade={ null } /> : <p>{ _s(StringKey.BILLING_PAYMENT_METHOD_CHANGE_DESCRIPTION) }</p>;
		buttonLabel = currentSubscription.isDue() ? _s(StringKey.BILLING_PAY_NOW) : _s(StringKey.SAVE_CHANGES);
	}

	return <Elements stripe={ _stripePromise } options={{
		fonts: [{
			cssSrc: 'https://fonts.googleapis.com/css?family=Barlow+Condensed',
			family: 'Barlow Condensed',
			style: 'normal',
		}],
	}}>
		<React.Fragment>
			<header>
				<div className="actions">
					<a className="button" onClick={ backAction }><span className="icon back"></span></a>
				</div>
				<div className="title">{ _s(StringKey.PAYMENT) }</div>
			</header>
			{ alertContainer }
			<div className="content scrollable">
				<div className="inner compact">

					{ header }

					<CardForm buttonLabel={ buttonLabel } onButtonClick={ plan ? doUpgrade : doUpdate } onChange={ handleCardChange } />

					{ upgradeDescription }

					<div className="securePayment">
						<div className="icon securePaymentLock"></div>
						<div className="poweredBy">Secure payment powered by</div>
						<div className="icon securePaymentStripe"></div>
					</div>

				</div>
			</div>

		</React.Fragment>
	</Elements>;
}

const PaymentPageIAP = ({ alertContainer, currentSubscription, backAction, processIap, plan }) => {
	if (!currentSubscription) {
		return null;
	}

	return <React.Fragment>
		<header>
			<div className="actions">
				<a className="button" onClick={ backAction }><span className="icon back"></span></a>
			</div>
			<div className="title">{ _s(StringKey.PAYMENT) }</div>
		</header>
		{ alertContainer }
		<div className="content scrollable">
			<div className="inner compact">

				<h2>
					{ plan.name }<br/>
					<span>{ plan.iapProduct.priceDisplay }/month</span>
				</h2>

				<a className="button basic payment" onClick={ () => processIap(plan) }><span>{ _s(StringKey.BILLING_PAY_NOW) }</span></a>

			</div>
		</div>

	</React.Fragment>;
};

// can only be reached outside a trolled garden
class PromoPage extends React.Component<any, any> {
	constructor(props) {
		super(props);

		this._handleRedeemClick = this._handleRedeemClick.bind(this);
		this._handleCodeChange = this._handleCodeChange.bind(this);

		this.state = { code: '' };
	}

	public _handleRedeemClick() {
		const { redeemCode } = this.props;
		const { code } = this.state;

		if (code && redeemCode) {
			redeemCode(code);
		}
	}

	public _handleCodeChange(e) {
		const newVal = e.target.value;

		this.setState({ code: newVal} );
	}

	public render() {
		const { alertContainer, backAction } = this.props;
		const { code } = this.state;
		const buttonClasses = ['button basic payment'];

		if (!code) {
			buttonClasses.push('disabled');
		}

		return <React.Fragment>
			<header>
				<div className="actions">
					<a className="button" onClick={ backAction }><span className="icon back"></span></a>
				</div>
				<div className="title">{ _s(StringKey.BILLING_UPGRADE_CODE) }</div>
			</header>
			{ alertContainer }
			<div className="content scrollable">
				<div className="inner compact">

					<p>{ _s(StringKey.BILLING_UPGRADE_CODE_ENTRY_MESSAGE) }</p>

					<input type="text" placeholder={ _s(StringKey.BILLING_ENTER_CODE) } value={ code } onChange={ this._handleCodeChange } />

					<a className={ buttonClasses.join(' ') } onClick={ this._handleRedeemClick }><span>{ _s(StringKey.BILLING_REDEEM) }</span></a>

				</div>
			</div>

		</React.Fragment>;
	}
}

const TrolledGardenPage = ({ backAction, title }) => {
	const { variant } = getCurrentContext();

	if (variant === 'flag') {
		return <React.Fragment>
			<header>
				<div className="actions">
					<a className="button" onClick={ backAction }><span className="icon back"></span></a>
				</div>
				<div className="title">{ title }</div>
			</header>
			<div className="content scrollable">
				<div className="inner compact">
					<p>{ _s(StringKey.BILLING_IOS_MANAGE_DESCRIPTION) }</p>
				</div>
			</div>

		</React.Fragment>;
	} else {
		return <React.Fragment>
			<header>
				<div className="actions">
					<a className="button" onClick={ backAction }><span className="icon back"></span></a>
				</div>
				<div className="title">{ title }</div>
			</header>
			<div className="content scrollable">
				<div className="inner compact">
					<p>{ _s(StringKey.BILLING_IOS_MANAGE_DESCRIPTION) }</p>
				</div>
			</div>

		</React.Fragment>;
	}

};

const UnclaimedIapContent = ({ claimIap }: { claimIap: any }) => {
	return <div className="inner compact">

		<p>&nbsp;</p>
		<p>{ _s(StringKey.BILLING_UNCLAIMED_IAP_MESSAGE) }</p>
		<a className="button basic" onClick={ claimIap }><span>{ _s(StringKey.BILLING_UNCLAIMED_IAP_ACTION) }</span></a>

		<p>&nbsp;</p>
		<p className="deemphasized">
			{ _s(StringKey.STILL_HAVING_TROUBLE) } &nbsp; <a className="link underlined" href={`mailto:support@${window.location.hostname.replace('www.', '')}` }>{ _s(StringKey.CONTACT_SUPPORT) }</a>
		</p>

	</div>;
};

interface BillingProps {
	alertContainer: any;
	cancelAction: () => void;
	plans: ISubscriptionPlan[];
	stripeKey: string;
	team: ITeam;
}

interface BillingState {
	pageStack: number[];
	processing?: boolean;
	pageProps?: any;
	orphanedIaps?: [];
}

export class Billing extends React.Component<BillingProps, BillingState> {

	constructor(props) {
		super(props);

		this.popPage = this.popPage.bind(this);
		this._redeemPromo = this._redeemPromo.bind(this);
		this._processUpgrade = this._processUpgrade.bind(this);
		this._processDowngrade = this._processDowngrade.bind(this);
		this._processPaymentUpdate = this._processPaymentUpdate.bind(this);
		this._processPause = this._processPause.bind(this);
		this._processIap = this._processIap.bind(this);

		this.state = {
			pageStack: [0],
		};

		this.logScreen();
	}

	public componentDidMount() {
		// be sure the plans are as fresh as they get
		actions.fetchPlans();
		actions.fetchOrphanedIaps().then(() => {
			const { viewState } = getCurrentContext();

			this.setState({ orphanedIaps: viewState.orphanedIaps });
		});
	}

	public getPageState(key) {
		const { pageStack } = this.state;
		const top = pageStack[pageStack.length - 1];

		if (key === top) {
			return PageState.IN;
		} else if (pageStack.indexOf(key) !== -1) {
			return PageState.OUT;
		}

		return PageState.DEFAULT;
	}

	public logScreen() {
		const { platform } = getCurrentContext();
		const { pageStack } = this.state;
		const top = pageStack[pageStack.length - 1];

		switch (top) {
		case 0:
			logger.logScreen(platform.isTrolledGarden ? 'BillingTroll' : 'Billing');
			break;
		case 1:
			logger.logScreen(platform.isTrolledGarden ? 'ChangePlanTroll' : 'ChangePlan');
			break;
		case 2:
			logger.logScreen(platform.isTrolledGarden ? 'RedeemPromoTroll' : 'RedeemPromo');
			break;
		case 3:
			logger.logScreen(platform.isTrolledGarden ? 'DowngradePlanTroll' : 'DowngradePlan');
			break;
		case 4:
			logger.logScreen(platform.isTrolledGarden ? 'UpgradePlanTroll' : 'UpgradePlan');
			break;
		case 5:
			logger.logScreen(platform.isTrolledGarden ? 'PausePlanTroll' : 'PausePlan');
			break;
		case 20:
			logger.logScreen(platform.isTrolledGarden ? 'PaymentMethodTroll' : 'PaymentMethod');
			break;
		default:
			// code...
			break;
		}
	}

	public popPage() {
		this.setState((previousState) => {
			const newState = assign({}, previousState) as BillingState;

			newState.pageStack.pop();

			if (newState.pageStack.length === 1) {
				newState.pageProps = null;
			}

			return newState;
		}, () => { this.logScreen(); });
	}

	public goToPage(page: number, pageProps: any) {
		this.setState((previousState) => {
			const newState = assign({}, previousState) as BillingState;
			const pageIndex = newState.pageStack.indexOf(page);

			if (pageIndex === -1) {
				newState.pageStack.push(page);
			} else if (pageIndex < newState.pageStack.length - 1) {
				newState.pageStack.splice(pageIndex + 1);
			}

			newState.pageProps = pageProps;

			return newState;
		}, () => { this.logScreen(); });
	}

	public async _redeemPromo(code: string) {
		const { processing } = this.state;

		if (!processing && code) {
			actions.clearAlerts();

			this.setState({ processing: true }, async () => {
				let pageStack = this.state.pageStack;
				let pageProps = this.state.pageProps;
				const result = await actions.redeemCode(code);

				if (result && !result.error) {
					pageStack = [0];
					pageProps = null;
				}

				this.setState({ processing: false, pageStack, pageProps }, () => {
					if (result) {
						actions.processRedemptionResult(result);
					}
				});
			});
		}
	}

	public async _processUpgrade(plan: ISubscriptionPlan, planUpgrade?: ISubscriptionPlanUpgrade, createToken?: () => Promise<any>) {
		const { team } = this.props;
		const { processing } = this.state;

		if (!processing) {
			actions.clearAlerts();

			this.setState({ processing: true }, async () => {
				let paymentData: any = {};
				let token;
				let tokenError;
				let pageStack = this.state.pageStack;
				let messages;

				if (createToken) {
					try {
						token = await createToken();
					} catch (err) {
						tokenError = err;
					}
				}

				if (!tokenError) {
					if (token) {
						paymentData.providerToken = token.id;
						paymentData.paymentType = token.card.funding;
						paymentData.country = token.card.country;
						paymentData.postalCode = token.card.address_zip;
						paymentData.name = _s(StringKey.BILLING_CARD_NAME_TEMPLATE).replace('{brand}', token.card.brand).replace('{last4}', token.card.last4);
					} else {
						paymentData = team.currentSubscription.paymentMethod.toDocument();
					}

					if (planUpgrade) {
						const proratedPrice = team.currentSubscription.subscriptionPlan.getProratedUpgradePrice(planUpgrade, team.currentSubscription);

						messages = await actions.upgradeSubscription(plan, { expectedPrice: proratedPrice, teamId: team.id, paymentData, upgradeId: planUpgrade.id });
					} else {
						messages = await actions.addSubscription(plan, { expectedPrice: plan.price, teamId: team.id, paymentData });
					}

					if (messages && messages.length) {
						actions.pushAlert({ message: [_s(StringKey.BILLING_PAYMENT_FAILED_MESSAGE)].concat(messages, [_s(StringKey.BILLING_PAYMENT_FAILED_ADVICE)]).join(' &bull; '), title: 'Failed', severity: store.AlertSeverity.error });
					} else if (messages) {
						pageStack = [0];
						actions.pushAlert({ message: _s(StringKey.BILLING_SUBSCRIPTION_SUCCESS_MESSAGE), mode: store.AlertMode.prompt, title: _s(StringKey.SUCCESS), severity: store.AlertSeverity.confirmation });
					}
				} else {
					if (tokenError.type === 'card_error') {
						actions.pushAlert({ message: [_s(StringKey.BILLING_PAYMENT_DECLINED_MESSAGE), tokenError.message, _s(StringKey.BILLING_PAYMENT_FAILED_ADVICE)].join(' &bull; '), title: 'Failed', severity: store.AlertSeverity.error });
					} else {
						actions.pushAlert({ message: [_s(StringKey.BILLING_PAYMENT_FAILED_MESSAGE), _s(StringKey.BILLING_PAYMENT_FAILED_ADVICE)].join(' &bull; '), title: 'Failed', severity: store.AlertSeverity.error });
					}
				}

				this.setState({ processing: false, pageStack });
			});
		}
	}

	public async _processDowngrade(plan: ISubscriptionPlan) {
		const { team } = this.props;
		const { processing } = this.state;

		if (!processing) {
			actions.clearAlerts();

			this.setState({ processing: true }, async () => {
				const paymentData: any = {};
				let pageStack = this.state.pageStack;
				const messages = await actions.addSubscription(plan, { expectedPrice: plan.price, teamId: team.id, paymentData });

				if (messages && messages.length) {
					actions.pushAlert({ message: _s(StringKey.BILLING_DOWNGRADE_FAILED_MESSAGE), title: 'Failed', severity: store.AlertSeverity.error });
				} else if (messages) {
					pageStack = [0];
					actions.pushAlert({ message: _s(StringKey.BILLING_DOWNGRADE_SUCCESS_MESSAGE), mode: store.AlertMode.prompt, title: _s(StringKey.SUCCESS), severity: store.AlertSeverity.confirmation });
				}

				this.setState({ processing: false, pageStack });
			});
		}
	}

	public async _processPaymentUpdate(createToken: () => Promise<any>) {
		const { team } = this.props;
		const { processing } = this.state;

		if (!processing && !!createToken) {
			actions.clearAlerts();

			this.setState({ processing: true }, async () => {
				const token = await createToken();
				const isDue = team.currentSubscription.isDue();
				const paymentData: any = { providerToken: token.id, paymentType: token.card.funding };
				const messages = await actions.updateSubscriptionPayment(team.currentSubscription, paymentData);
				let pageStack = this.state.pageStack;

				if (messages && messages.length) {
					actions.pushAlert({ message: messages.join(' &bull; '), title: 'Failed', severity: store.AlertSeverity.error });
				} else if (messages) {
					pageStack = [0];
					actions.pushAlert({ message: isDue ? _s(StringKey.BILLING_SUBSCRIPTION_SUCCESS_MESSAGE) : _s(StringKey.BILLING_PAYMENT_METHOD_SAVED_MESSAGE), mode: store.AlertMode.prompt, title: _s(StringKey.SUCCESS), severity: store.AlertSeverity.confirmation });
				}

				this.setState({ processing: false, pageStack });
			});
		}
	}

	public async _processIap(plan: ISubscriptionPlan) {
		const { team } = this.props;
		const { orphanedIaps, processing } = this.state;

		if (!processing) {
			actions.clearAlerts();

			this.setState({ processing: true }, async () => {
				const orphaned = orphanedIaps && find(orphanedIaps, (iap) => (iap.teamId === team.id || !iap.teamId) && iap.productId === plan.iapProduct.id);
				const isValid = !!orphaned || await actions.isValidIapPlan(plan);
				let pageStack = this.state.pageStack;

				if (isValid) {
					const result = orphaned || await actions.makeIap(plan.iapProduct.id, team.id);
					let messages;

					if (result.error) {
						actions.pushAlert({ message: result.error, title: 'Failed', severity: store.AlertSeverity.error });
					} else {
						messages = await actions.addSubscription(plan, { expectedPrice: plan.price, teamId: team.id, trollReceipt: result });
					}

					if (messages && messages.length) {
						actions.pushAlert({ message: _s(StringKey.BILLING_PAYMENT_FAILED_MESSAGE), title: 'Failed', severity: store.AlertSeverity.error });
					} else if (messages) {
						pageStack = [0];
						actions.finishIap(result.transactionId);
						actions.pushAlert({ message: _s(StringKey.BILLING_SUBSCRIPTION_SUCCESS_MESSAGE), mode: store.AlertMode.prompt, title: _s(StringKey.SUCCESS), severity: store.AlertSeverity.confirmation });
					}
				} else {
					actions.pushAlert({ message: 'Invalid plan.', title: 'Failed', severity: store.AlertSeverity.error });
				}

				this.setState({ processing: false, pageStack, orphanedIaps: orphaned ? null : orphanedIaps });
			});
		}
	}

	public async _processPause() {
		const { team } = this.props;
		const currentSubscription = team.currentSubscription;
		const pendingSubscription = team.pendingSubscription && team.pendingSubscription.teamId ? team.pendingSubscription : null;
		const { processing } = this.state;

		if (!processing) {
			actions.clearAlerts();

			this.setState({ processing: true }, async () => {
				const pause = !!(currentSubscription.dueDate || pendingSubscription);
				const messages = await actions.pauseSubscription(currentSubscription, pendingSubscription, pause);
				let pageStack = this.state.pageStack;

				if (messages && messages.length) {
					actions.pushAlert({ message: messages.join(' &bull; '), title: 'Failed', severity: store.AlertSeverity.error });
				} else if (messages) {
					pageStack = [0];
					actions.pushAlert({ message: pause ? _s(StringKey.BILLING_PLAN_PAUSED_TEMPLATE).replace('{date}', (team.currentSubscription.dueDate || team.currentSubscription.expirationDate).toLocaleDateString()) : _s(StringKey.BILLING_PLAN_UNPAUSED_MESSAGE), mode: store.AlertMode.prompt, title: _s(StringKey.SUCCESS), severity: store.AlertSeverity.confirmation });
				}

				this.setState({ processing: false, pageStack });
			});
		}
	}

	public _onChange(key, e) {
		const update = {};

		update[key] = e.target.value;

		this.setState(update);
	}

	public render() {
		const { alertContainer, cancelAction, plans = [], stripeKey, team } = this.props;
		const { orphanedIaps, pageProps, processing } = this.state;
		const { platform } = getCurrentContext();
		const currentSubscription = team.currentSubscription;
		const pendingSubscription = team.pendingSubscription && team.pendingSubscription.teamId ? team.pendingSubscription : null;
		const showTrollPlans = platform.isTrolledGarden && platform.supportsIap && (!!currentSubscription.getIapTroll() || currentSubscription.isFreeTrial() || currentSubscription.hasExpired() || currentSubscription.hasEnded());
		const validPlans = showTrollPlans ? filter(plans, (p) => !!p.getIapTroll()) : filter(plans, (p) => !p.getIapTroll());
		const orphaned = orphanedIaps && find(orphanedIaps, (iap) => (iap.teamId === team.id || !iap.teamId) && validPlans.some((plan) => iap.productId === plan.iapProduct.id));
		const resolvedProcessIap = orphaned ? this._processIap.bind(this, validPlans.find((plan) => plan.iapProduct.id === orphaned.productId)) : this._processIap;
		const unclaimedIapContent = orphaned ? <UnclaimedIapContent claimIap={ resolvedProcessIap } /> : null;
		let resolvedPaymentPage = null;

		if (platform.isTrolledGarden) {
			if (showTrollPlans) {
				resolvedPaymentPage = <PaymentPageIAP alertContainer={ alertContainer } backAction={ this.popPage } currentSubscription={ currentSubscription } processIap={ resolvedProcessIap } { ...pageProps } />;
			} else {
				resolvedPaymentPage = <TrolledGardenPage backAction={ this.popPage } title={ _s(StringKey.PAYMENT)} />;
			}
		} else {
			resolvedPaymentPage = <PaymentPage alertContainer={ alertContainer } backAction={ this.popPage } currentSubscription={ currentSubscription } { ...pageProps } onUpgradeClick={ this._processUpgrade } onUpdateClick={ this._processPaymentUpdate} stripeKey={ stripeKey } />;
		}

		return <React.Fragment>
			{ processing ? <Spinner /> : null }

			<Page key="page0" pageClasses={['page']} pageState={ this.getPageState(0) }>
				<CurrentPlanPage alertContainer={ alertContainer } cancelAction={ cancelAction } currentSubscription={ currentSubscription } doPause={ currentSubscription.expirationDate && !pendingSubscription ? this._processPause : this.goToPage.bind(this, 5) } gotoPayment={ this.goToPage.bind(this, 20) } gotoPlanChange={ this.goToPage.bind(this, 1, null) } gotoPromo={ this.goToPage.bind(this, 2, null) } isTrolledGarden={ platform.isTrolledGarden } pendingSubscription={ pendingSubscription } title={ _s(StringKey.BILLING_MANAGE_PLAN) } plans={ validPlans } unclaimedIapContent={ unclaimedIapContent }  />
			</Page>
			<Page key="page1" pageClasses={['page']} pageState={ this.getPageState(1) }>
				{ platform.isTrolledGarden ? <TrolledGardenPage backAction={ this.popPage } title={ _s(StringKey.BILLING_CHANGE_PLAN)} /> : <ChangePlanPage alertContainer={ alertContainer } backAction={ this.popPage } currentSubscription={ currentSubscription } pendingSubscription={ pendingSubscription } gotoPayment={ this.goToPage.bind(this, 20) } gotoDowngrade={ this.goToPage.bind(this, 3) } gotoUpgrade={ this.goToPage.bind(this, 4) } upgrade={ this._processUpgrade }  plans={ validPlans } /> }
			</Page>
			<Page key="page2" pageClasses={['page']} pageState={ this.getPageState(2) }>
				{ platform.isTrolledGarden ? <TrolledGardenPage backAction={ this.popPage } title={ _s(StringKey.BILLING_REDEEM)} /> :  <PromoPage alertContainer={ alertContainer } backAction={ this.popPage } redeemCode={ this._redeemPromo } /> }
			</Page>
			<Page key="page3" pageClasses={['page']} pageState={ this.getPageState(3) }>
				{ platform.isTrolledGarden ? <TrolledGardenPage backAction={ this.popPage } title={ _s(StringKey.BILLING_DOWNGRADE)} /> :  <DowngradePage alertContainer={ alertContainer } backAction={ this.popPage } currentSubscription={ currentSubscription } downgrade={ this._processDowngrade } { ...pageProps } /> }
			</Page>
			<Page key="page4" pageClasses={['page']} pageState={ this.getPageState(4) }>
				{ platform.isTrolledGarden ? <TrolledGardenPage backAction={ this.popPage } title={ _s(StringKey.UPGRADE)} /> :  <UpgradePage alertContainer={ alertContainer } backAction={ this.popPage } currentSubscription={ currentSubscription } upgrade={ this._processUpgrade } { ...pageProps } /> }
			</Page>
			<Page key="page5" pageClasses={['page']} pageState={ this.getPageState(5) }>
				<PausePage alertContainer={ alertContainer } backAction={ this.popPage } currentSubscription={ currentSubscription } doPause={ this._processPause } />
			</Page>
			<Page key="page20" pageClasses={['page']} pageState={ this.getPageState(20) }>
				{ resolvedPaymentPage }
			</Page>
		</React.Fragment>;
	}
}

interface BillingModalProps {
	alerts: store.IAlert[];
	appState: store.IAppState;
	teamId: string;
}

export function BillingModal({ alerts, appState, teamId}: BillingModalProps) {
	const alertContainer = <AlertList alerts={ alerts } />;
	const team = appState.model.teams[teamId];

	return <Billing alertContainer={ alertContainer } cancelAction={ viewManager.popModal } plans={ appState.viewState.availableSubscriptionPlans } team={ team } stripeKey={ appState.viewState.config.stripeKey } />;
}

import * as React from 'react';
import * as actions from '../actions';
import * as localImage from '../localImage';

interface Props extends React.ImgHTMLAttributes<HTMLImageElement> {
	letterBox?: boolean;
	preloadOnly?: boolean;
}

interface State {
	imageSrc?: string;
}

export class CachedImage extends React.PureComponent<Props, State> {
	private _unmounting;

	constructor(props) {
		super(props);

		const isDataUrl = props.src && props.src.indexOf('data:') === 0;
		const dataUrl = isDataUrl ? props.src : props.src ? actions.getCachedImage(props.src) : null;

		this.state = {
			imageSrc: dataUrl || '',
		};

		this.setImageSource();
	}

	public componentWillUnmount() {
		this._unmounting = true;
	}

	public async setImageSource() {
		const src = this.props.src;

		if (this.state.imageSrc || !src) {
			return;
		}

		const image = localImage.create(src);
		let dataUrl: string;

		if (this.props.width && this.props.height) {
			dataUrl = await image.fitToBounds(Number(this.props.width), Number(this.props.height), !!this.props.letterBox);
		} else {
			dataUrl = await image.getDataUrl();
		}

		actions.storeCachedImage(src, dataUrl);

		if (!this._unmounting) {
			this.setState({ imageSrc: dataUrl });
		}
	}

	public render() {
		const { src, preloadOnly, ...passthroughProps } = this.props;
		const { imageSrc } = this.state;
		const styles = preloadOnly ? { position: 'absolute', top: '-1px', opacity: 0, width: '1px', height: '1px', pointerEvents: 'none' } as any : null;

		return (!!preloadOnly && !!imageSrc) ? null : <img src={ imageSrc || src } style={ styles } { ...passthroughProps } />;
	}
}

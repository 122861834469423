import { createId } from 'playmaker-team-common/dist/shared/createId';
import { IModel, StringDictionary } from 'playmaker-team-common/dist/shared/interfaces';
import { createFactory } from 'playmaker-team-common/dist/shared/modelFactory';
import { default as validator } from 'playmaker-team-common/dist/shared/validator';
import { default as valueFilters } from 'playmaker-team-common/dist/shared/valueFilters';
import { stripDashes } from './coreModel';
import { default as routeFactory, IRoute } from './route';
import { default as sortableModel, ISortable } from './sortableModel';
import { default as vectorFactory, IVector } from './vector';

export interface IDiagramItem extends IModel {
	id: string;
	loc: IVector; // 'location is a reserved word in dynamodb. seriously!'
}

// Dynamodb chokes when Update Expressions contain hyphens - so be sure our ids don't have hyphens
export default {
	id: {
		writable: false,
		defaultValue: () => {
			return stripDashes(createId());
		},
	},
	loc: {
		setFilters: valueFilters.toModel<IVector>(vectorFactory),
		rules: validator.validModel(vectorFactory.rules()),
		defaultValue: {},
	},
};

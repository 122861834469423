import { assign } from 'lodash';
import { ModelSet } from 'playmaker-team-common/dist/shared/modelSet';
import { default as validator } from 'playmaker-team-common/dist/shared/validator';
import { default as valueFilters } from 'playmaker-team-common/dist/shared/valueFilters';
import { default as annotationFactory, IAnnotation } from './annotation';
import { default as playerFactory, IPlayer, PlayerRoles, PlayerShading } from './player';
import { default as rootModel, IRootModel } from './rootModel';
import { default as vectorFactory, IVector } from './vector';

export enum DiagramRenderFlags {
	none = 0,
	showField = 1 << 0,
	showOpponents = 1 << 1,
	showAnnotations = 1 << 2,
	interactive = 1 << 3,
	interactiveRoutes = 1 << 4,
	locked = 1 << 5,
	hideMates = 1 << 6,
	hideNonRouteAnnotations = 1 << 7,
	showAnimations = 1 << 8,
	showSnapGrid = 1 << 9,
	renderDiagnotics = 1 << 10
}

export enum GamePhase {
	Offense,
	Defense,
	SpecialTeams,
}

export enum SpecialTeamsUnit {
	None,
	Kick,
	Punt,
	FieldGoal,
}

export const enum PlayerLabelMode {
	None,
	FirstName,
	LastName,
	Initials,
	Number,
}

type DiagramContainerOffset = 0 | 5 | -5; // positive moves the container down

export interface IDiagram extends IRootModel {
	annotations: ModelSet<IAnnotation>;
	ballLocation: IVector;
	mates: ModelSet<IPlayer>;
	label: string;
	notes: string;
	phase: GamePhase;
	playbookId: string;
	posture: GamePhase;
	renderFlags: DiagramRenderFlags;
	schemaVersion: number;
	teamId?: string;
	unit: SpecialTeamsUnit;
	containerOffset?: DiagramContainerOffset;
	flipHorizontally: () => void;
	flipVertically: () => void;
	getPhaseClass: () => string;
	getPostureClass: () => string;
	getUnitClass: () => string;
	getContainerOffsetClass: () => string;
	getClassName: () => string;
	// matches: (other:IDiagram) => boolean;
}

export default assign({}, rootModel, {
	annotations: {
		setFilters: valueFilters.toModelSet(annotationFactory),
		rules: validator.validModel(annotationFactory.rules()),
		defaultValue: {},
	},
	ballLocation: {
		setFilters: valueFilters.toModel<IVector>(vectorFactory),
		rules: validator.validModel(vectorFactory.rules()),
		defaultValue: {},
	},
	label: {
		rules: validator.required(),
	},
	mates: {
		setFilters: valueFilters.toModelSet(playerFactory),
		rules: validator.validModel(playerFactory.rules()),
		defaultValue: {},
	},
	notes: {
		getFilters: [(val) => !val ? undefined : val],
	},
	phase: {
		setFilters: [valueFilters.toNumber, function(val) {
			if (val === GamePhase.Offense || val === GamePhase.Defense) {
				this.posture = val;
			}
			return val;
		}],
	},
	playbookId: {
	},
	posture: {
		setFilters: valueFilters.toNumber,
		rules: [(val) => {
			if (val === GamePhase) { return 'Posture cannot be Special Teams'; }
		}],
	},
	renderFlags: {
		setFilters: valueFilters.toNumber,
		defaultValue: DiagramRenderFlags.none,
	},
	schemaVersion: {
		setFilters: valueFilters.toNumber,
		defaultValue: 2,
	},
	teamId: {
	},
	unit: {
		setFilters: [valueFilters.toNumber],
		getFilters: [(val) => val || 0],
		defaultValue: SpecialTeamsUnit.None,
		rules: [(val) => {
			if (typeof val === 'undefined') {
				return;
			}

			if ([SpecialTeamsUnit.None, SpecialTeamsUnit.Kick, SpecialTeamsUnit.Punt, SpecialTeamsUnit.FieldGoal].indexOf(val) === -1) {
				return 'Invalid unit';
			}
		}],
	},
	containerOffset: {
		setFilters: valueFilters.toNumber,
		defaultValue: 0,
	},
	flipVertically() {
		const ballLocation = this.ballLocation;

		for (const mate of this.mates.values) {
			let diff = -(mate.loc.y - ballLocation.y);
			mate.loc.y = ballLocation.y + diff;

			diff = -(mate.loc.x - ballLocation.x);
			mate.loc.x = ballLocation.x + diff;
			mate.shading = mate.shading === PlayerShading.left ? PlayerShading.right : mate.shading === PlayerShading.right ? PlayerShading.left : mate.shading;
		}
	},
	flipHorizontally() {
		flipPlayersHorizontally(this.mates.values, this.ballLocation);

		for (const annotation of this.annotations.values) {
			const diff = -(annotation.loc.x - this.ballLocation.x);
			annotation.loc.x = this.ballLocation.x + diff;

			if (annotation.flipHorizontally) {
				annotation.flipHorizontally();
			}
		}

		if (this.opponents) {
			flipPlayersHorizontally(this.opponents.values, this.ballLocation);
		}
	},
	// matches(other: IDiagram) {
	// 	const otherMates = sortBy(other.mates.values, ['sortIndex']);
	// 	const mates = sortBy(this.mates.values, ['sortIndex']);
	// 	const otherAnnotations = sortBy(other.annotations.values, ['sortIndex']);
	// 	const annotations = sortBy(this.annotations.values, ['sortIndex']);

	// 	if(otherMates.length !== mates.length || otherAnnotations.length !== annotations.length) {
	// 		return false;
	// 	}

	// 	for(let i = 0; i < otherMates.length; i ++) {
	// 		const otherMate = otherMates[i] as IPlayer;
	// 		const mate = mates[i] as IPlayer;

	// 		if(!otherMate.loc.equals(mate.loc)) {
	// 			return false;
	// 		}
	// 	}
	// }
	getPhaseClass() {
		if (typeof this.phase === "undefined") {
			return '';
		}

		return `phase${GamePhase[this.phase]}`;
	},
	getPostureClass() {
		if (typeof this.posture === "undefined") {
			return '';
		}

		return `posture${GamePhase[this.posture]}`;
	},
	getUnitClass() {
		if (typeof this.unit === "undefined") {
			return '';
		}

		return `unit${SpecialTeamsUnit[this.unit]}`;
	},
	getContainerOffsetClass() {
		if (!this.containerOffset) {
			return '';
		}

		const direction = this.containerOffset > 0? 'Plus': 'Minus';

		return `offset${direction}${Math.abs(this.containerOffset)}`;
	},
	getClassName() {
		if (this.phase === GamePhase.SpecialTeams) {
			return [this.getPhaseClass(), this.getPostureClass(), this.getUnitClass()].filter(c => !!c).join(' ');
		} else {
			return [this.getPhaseClass(), this.getPostureClass(), this.getContainerOffsetClass()].filter(c => !!c).join(' ');
		}
	},
});

function flipPlayersHorizontally(players: IPlayer[], ballLocation: IVector) {
	let player: IPlayer;
	const flippedPlayers = [];
	const processPlayer = (player, diff) => {
		player.loc.x = ballLocation.x + diff;
		player.shading = player.shading === PlayerShading.left ? PlayerShading.right : player.shading === PlayerShading.right ? PlayerShading.left : player.shading;
		if (player.flipHorizontally) {
			player.flipHorizontally();
		}
	};

	for (player of players) {
		if (flippedPlayers.indexOf(player) !== -1) {
			continue;
		}

		let diff = -(player.loc.x - ballLocation.x);
		const lineRole = player.lineRole();
		const complement = player.isRole(PlayerRoles.guard) || player.isRole(PlayerRoles.tackle) ? players.find((m) => ((m.role & lineRole) === lineRole || (m.role & lineRole) === lineRole) && m !== player) : undefined;

		if (complement) {
			const complementDiff = -(complement.loc.x - ballLocation.x);
			const complementDoc = complement.toDocument(undefined, ['/id', '/label', '/sortIndex']);
			const playerDoc = player.toDocument(undefined, ['/id', '/label', '/sortIndex']);

			player.fromDocument(complementDoc);
			complement.fromDocument(playerDoc);

			processPlayer(complement, diff);

			diff = complementDiff;

			// const complementDiff = -(complement.loc.x - ballLocation.x);
			// const complementShading = complement.shading;

			// complement.loc.x = ballLocation.x + diff;
			// player.loc.x = ballLocation.x + complementDiff;

			// complement.shading = player.shading === PlayerShading.left ? PlayerShading.right : player.shading === PlayerShading.right ? PlayerShading.left : player.shading;
			// player.shading = complementShading === PlayerShading.left ? PlayerShading.right : complementShading === PlayerShading.right ? PlayerShading.left : complementShading;

			flippedPlayers.push(complement);
		}
		// else {
		// 	player.loc.x = ballLocation.x + diff;
		// 	player.shading = player.shading === PlayerShading.left ? PlayerShading.right : player.shading === PlayerShading.right ? PlayerShading.left : player.shading;
		// }

		processPlayer(player, diff);

		// if (player.flipHorizontally) {
		// 	player.flipHorizontally();
		// }

		flippedPlayers.push(player);
	}
}
import { map, sortBy } from 'lodash';
import * as React from 'react';
import * as actions from '../actions';
import { current as getCurrentContext } from '../componentContext';
import { DiagramRenderFlags, GamePhase } from '../models/diagramModel';
import { IPlay } from '../models/play';
import { ISortable } from '../models/sortableModel';
import { IDragData, IDragSpec } from '../store';
import * as viewManager from '../viewManager';
import { DiagramControl } from './diagramControl';
import { DragDrop, IDragSourceParams, IDropTargetParams } from './dnd';

interface Props {
	addLabel: string;
	fieldOptions: any;
	isVisible?: boolean;
	lastViewedPlayId: string;
	plays: (IPlay & ISortable)[];
	onItemDrop: (sourceId: string, targetId: string) => void;
	onAddClick: (e) => void;
	openInfo: (playId: string) => void;
}

interface State {
}

class PlayThumbnail extends React.Component<any, any> {
	private _dropTarget;
	private _goToPlay;
	private _rerenderTimeout;

	constructor(props) {
		super(props);

		this.canDrop = this.canDrop.bind(this);
		this.onDrop = this.onDrop.bind(this);

		this.setDropTarget = this.setDropTarget.bind(this);

		this._goToPlay = viewManager.pushPath.bind(this, `/playbook/${props.play.playbookId}/plays/${props.play.id}`);
		this.state = { renderAll: false };
	}

	public componentDidMount() {
		this._rerenderTimeout = setTimeout(() => {
			this._rerenderTimeout = null;
			this.setState({ renderAll: true });
		}, 50);
	}

	public componentWillUnmount() {
		if (this._rerenderTimeout) {
			clearTimeout(this._rerenderTimeout);
		}
	}

	public canDrop(data: IDragData) {
		const { play } = this.props;

		return data.type === 'thumbnail' && data.info.play && data.info.play.id !== play.id;
	}

	public onDrop(data: IDragData) {
		const { play, onDrop } = this.props;

		if (onDrop) {
			onDrop(data.info.play.id, play.id);
		}
	}

	public setDropTarget(el) {
		const { addDropTarget, removeDropTarget } = this.props;

		if (!addDropTarget) {
			return;
		}

		if (this._dropTarget) {
			removeDropTarget(this._dropTarget);
		}

		this._dropTarget = el;

		if (this._dropTarget) {
			addDropTarget(this._dropTarget, this.canDrop, this.onDrop);
		}
	}

	public render() {
		const { className = 'tile play', fieldOptions, play, playCount, onInfoClick, onPointerDown, onTouchStart, onMouseDown } = this.props;
		const { renderAll } = this.state;
		// const mates:[] = sortBy(play.mates.values, 'sortIndex');
		const { playbookPermissions } = getCurrentContext();
		let renderFlags = DiagramRenderFlags.showAnnotations | DiagramRenderFlags.hideNonRouteAnnotations | play.renderFlags;

		renderFlags &= ~DiagramRenderFlags.showOpponents;

		if (!renderAll) {
			renderFlags |= DiagramRenderFlags.hideMates;
			renderFlags &= ~DiagramRenderFlags.showAnnotations;
		}

		return <div className={ className } ref={ this.setDropTarget }>
			<div className="inner">
				<a className="button" onClick={ this._goToPlay  }></a>
				<div className="thumbnail">
					<DiagramControl ballLocation={ play.ballLocation } diagram={ play } fieldOptions={ fieldOptions } lineOfScrimage={ 0.5 } renderFlags={ renderFlags } />
				</div>
				<div className="label"><strong>{ play.formation }</strong>{ play.label }</div>
				<div className="actions">
					<a className="button"><span className="badge">{ playCount }</span></a>
					{ playbookPermissions.canUpdate ? <a className="button" id={ playCount === 1 ? 'tooltip-target-playbookRearrangePlays' : null } style={{ touchAction: 'none' }} onPointerDown={ onPointerDown } onTouchStart={ onTouchStart } onMouseDown={ onMouseDown }><span className="icon sortItem"></span></a> : null }
					<a className="button" onClick={ onInfoClick } id={ `play-info.${play.id}` }><span className="icon infoSmall"></span></a>
				</div>
			</div>
		</div>;
	}
}

export class PlayGallery extends React.Component<Props, State> {
	private _pendingRenders = 0;

	constructor(props: Props) {
		super(props);

		this.handleInfoClick = this.handleInfoClick.bind(this);
		this.state = { plays: [] };
	}

	public handleInfoClick(e) {
		const { openInfo } = this.props;
		const parts = e.currentTarget.id.split('.');
		const id = parts[1];

		openInfo(id);
	}

	public render() {
		if (!this.props.isVisible) {
			return null;
		}

		const { addLabel, lastViewedPlayId, onAddClick, onItemDrop, fieldOptions, plays } = this.props;
		const { currentUser, playbookPermissions } = getCurrentContext();
		let index = 0;
		const thumbnails = map(plays, (play) => {
			const sortIndex = index ++;
			const playCount = index;
			const lastViewed = lastViewedPlayId === play.id;
			const { currentUser } = getCurrentContext();

			const dragSpec: IDragSpec = {
				containerClassName: (currentUser.profile.backgroundMode === 'light') ? 'light' : '',
				component: () => <PlayThumbnail className={ `tile dragging ${play.getClassName()}` } fieldOptions={ fieldOptions } play={ play } playCount={ playCount } />,
				getDragLayout: (touch, currentTarget) => {
					const thumbnailElement = currentTarget.parentElement.parentElement.parentElement;
					const thumnailRect = thumbnailElement.getBoundingClientRect();
					const targetRect = currentTarget.getBoundingClientRect();

					return { x: thumnailRect.width * 0.4, y: targetRect.height * 0.4, width: thumnailRect.width * 0.8, height: thumnailRect.height * 0.8, scrollContainer: thumbnailElement.parentElement.parentElement };
				},
				dragStart: () => {
					actions.setLastViewedPlayId('');
				},
				data: {
					type: 'thumbnail',
					info: {
						play,
					},
				},
			};

			return <DragDrop key={ play.id }>{
				(params: IDragSourceParams & IDropTargetParams) => {
					const { activeDragSpec, handleMouseDown, handlePointerDown, handleTouchStart, ...dragProps } = params;
					const classNames = lastViewed ? ['tile selected', play.getClassName()] : ['tile', play.getClassName()];

					if (activeDragSpec && activeDragSpec.data.info.play !== play) {
						const dragSpecSortIndex = plays.indexOf(activeDragSpec.data.info.play);
						classNames.push('dropTarget');
						classNames.push(dragSpecSortIndex < sortIndex ? 'bottom' : 'top');
						// console.log(`${dragSpecSortIndex}, ${sortIndex}, ${classNames.join(' ')}`);
					}

					return <PlayThumbnail
						key={ play.id }
						className={ classNames.join(' ') }
						fieldOptions={ fieldOptions }
						onInfoClick={ this.handleInfoClick }
						play={ play }
						playCount={ playCount }
						onDrop={ onItemDrop }
						onPointerDown={ handlePointerDown && handlePointerDown.bind(this, dragSpec) }
						onTouchStart={ handleTouchStart && handleTouchStart.bind(this, dragSpec) }
						onMouseDown={ handleMouseDown && handleMouseDown.bind(this, dragSpec) }
						{...dragProps } />;
				}
			}</DragDrop>;

		});

		return <div className="content scrollable">
			<div className="plays">

				{ !playbookPermissions.canUpdate ? null : <div className="tile play add">
					<div className="inner">
						<a className="button" onClick={ onAddClick }></a>
						<span className="icon addPlay"></span>
						<div className="label">{ addLabel }</div>
					</div>
				</div>
				}
				{ thumbnails }
			</div>
		</div>;
	}
}

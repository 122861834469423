import { assign } from 'lodash';
import { createFactory } from 'playmaker-team-common/dist/shared/modelFactory';
import { ModelSet } from 'playmaker-team-common/dist/shared/modelSet';
import { default as validator } from 'playmaker-team-common/dist/shared/validator';
import { default as valueFilters } from 'playmaker-team-common/dist/shared/valueFilters';
import { default as diagramModel, DiagramRenderFlags, GamePhase, IDiagram } from './diagramModel';
import { default as personnelGroupFactory, IPersonnelGroup } from './personnelGroup';
import { default as playerFactory, IPlayer } from './player';
import { default as sortableModel, ISortable } from './sortableModel';

export interface IPlay extends IDiagram, ISortable {
	activePersonnelGroup: string;
	categories: string;
	categoryList: string[];
	formation: string;
	opponentsChanged?: boolean;
	opponents: ModelSet<IPlayer>;
	personnelGroup: IPersonnelGroup;
	playersPerSide: number;
	showOpponents: boolean;
}

export default createFactory<IPlay & ISortable>('Play', assign({}, diagramModel, sortableModel, {
	activePersonnelGroup: {
		setFilters: valueFilters.clean,
	},
	categories: {
		setFilters: [valueFilters.clean, (val) => val === '' ? undefined : val],
	},
	categoryList: {
		writable: false,
		nonSerialized: true,
	},
	_get_categoryList() {
		return this.categories ? this.categories.split(',') : [];
	},
	formation: {
		setFilters: valueFilters.clean,
		rules: validator.required(),
	},
	opponentsChanged: {
		setFilters: valueFilters.toBoolean,
	},
	opponents: {
		setFilters: valueFilters.toModelSet(playerFactory),
		rules: validator.validModel(playerFactory.rules()),
		defaultValue: {},
	},
	personnelGroup: {
		setFilters: valueFilters.toModel(personnelGroupFactory),
		getFilters: [function(val) { if (val && !val.playersPerSide) val.playersPerSide = this.playersPerSide; return val; }],
		rules: validator.validModel(playerFactory.rules()),
		defaultValue: {},
	},
	playersPerSide: {
		setFilters: valueFilters.toNumber,
	},
	showOpponents: {
		nonSerialized: true,
	},
	_set_showOpponents(val: boolean) {
		this.renderFlags = val ? (this.renderFlags | DiagramRenderFlags.showOpponents) : (this.renderFlags & ~DiagramRenderFlags.showOpponents);
	},
	_get_showOpponents() {
		return this.renderFlags & DiagramRenderFlags.showOpponents;
	},
	getClassName() {
		if (this.phase === GamePhase.SpecialTeams) {
			return ['play', this.getPhaseClass(), this.getPostureClass(), this.getUnitClass()].filter(c => !!c).join(' ');
		} else {
			return ['play', this.getPhaseClass(), this.getPostureClass(), this.getContainerOffsetClass()].filter(c => !!c).join(' ');
		}
	},
}));

import { map } from 'lodash';
import * as React from 'react';
import * as systemActions from '../actions';
import { AlertSeverity, IAction, IAlert } from '../store';
import { _s, StringKey } from '../strings';
import { Prompt } from './prompt';

interface Props {
	title: string;
	message: string;
	placeholder: string;
	onCancelClick: () => void;
	deleteAction: IAction;
}

interface State {
	inputText: string;
}

export class DeleteConfirmModal extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);

		this.onDeleteClick = this.onDeleteClick.bind(this);
		this.onTextChange = this.onTextChange.bind(this);

		this.state = {
			inputText: '',
		};
	}

	public onTextChange(e) {
		this.setState( { inputText: e.target.value });
	}

	public onDeleteClick(e) {
		if (this._canDelete()) {
			this.props.deleteAction.action();
		}
	}

	public _canDelete() {
		return this.state.inputText.toLowerCase() === _s(StringKey.DELETE).toLowerCase();
	}

	public render() {
		const { title, message, placeholder, deleteAction, onCancelClick } = this.props;
		const { inputText } = this.state;
		const actions = [{ label: deleteAction.label, action: this.onDeleteClick, className: !this._canDelete() ? `${deleteAction.className} disabled` : deleteAction.className }, { label: _s(StringKey.CANCEL), action: onCancelClick, className: 'cancel' }];
		const buttons = map(actions, (action: IAction) => <a key={ `action-${action.label}`} className={ `button basic ${action.className}` } onClick={ action.action } ><span>{ action.label }</span></a>);

		return <div className="view riskConfirmation delete">
			<header>
				<div className="title">{ title }</div>
			</header>
			<div className="content scrollable">
				<div className="inner">
					<div className="icon attention delete"></div>
					<p>{ message }</p>

					<input type="text" placeholder={ _s(StringKey.TYPE_DELETE_HERE) } required value={ inputText } onChange={ this.onTextChange } />

					<div className="actions">
						{ buttons }
					</div>
				</div>
			</div>
		</div>;
	}
}

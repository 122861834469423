import { find, map } from 'lodash';
import { IPatchOperation, PatchOpType } from 'playmaker-team-common/dist/shared/interfaces';
import * as React from 'react';
import * as actions from '../actions';
import { current as getCurrentContext } from '../componentContext';
import { GamePhase, IDiagram } from '../models/diagramModel';
import { IFormation } from '../models/formation';
import { IPlay } from '../models/play';
import { IPlayer } from '../models/player';
import { IUserProfile } from '../models/userProfile';
import { AlertMode, AlertSeverity } from '../store';
import { _s, StringKey } from '../strings';
import * as viewManager from '../viewManager';
import { IRouteTree } from '../models/routeTree';

interface Props {
	ballOnOptions?: any[];
	diagram: IDiagram;
	opponentFormations: IDiagram[];
	userProfile: IUserProfile;
	updateDiagram: (patch: IPatchOperation[]) => void;
	updateProfile: (pointer: string, value: any) => void;
}

export class PlayPanelFieldOptions extends React.Component<Props> {
	private _selectedFormation;

	constructor(props: Props) {
		super(props);

		this.handleFormationChange = this.handleFormationChange.bind(this);
		this.handleShowOpponentsChange = this.handleShowOpponentsChange.bind(this);
		this.handleContainerOffsetChange = this.handleContainerOffsetChange.bind(this);
	}

	public handleShowOpponentsChange(e) {
		const { diagram, opponentFormations, updateDiagram } = this.props;
		const checked = e.target.value === '1';
		const patch = checked && !(diagram as IPlay).opponents.count && opponentFormations.length ? this.getOpponentsPatch(opponentFormations[0]) : [];

		patch.push({ path: '/showOpponents', op: PatchOpType.replace , value: checked });

		updateDiagram(patch);
	}

	public handleContainerOffsetChange(e) {
		const { updateDiagram } = this.props;
		const value = Number(e.target.value)

		updateDiagram([{ path: '/containerOffset', op: PatchOpType.replace , value }]);
	}

	public setOpponents(formation: IDiagram) {
		const { updateDiagram } = this.props;

		updateDiagram(this.getOpponentsPatch(formation));
	}

	public getOpponentsPatch(formation: IDiagram) {
		const { diagram } = this.props;
		const opponents = [];

		if (formation) {
			formation.flipVertically();
			const offset = { x: diagram.ballLocation.x - formation.ballLocation.x, y: diagram.ballLocation.y - formation.ballLocation.y };

			for (const opponent of formation.mates.values) {
				const cloned: IPlayer = opponent.clone();

				cloned.loc.add(offset);
				cloned.color = cloned.routeColor = 9;

				cloned.loc.limit(0, 1, 0, diagram.ballLocation.y);

				opponents.push(cloned);
			}

			// reset it to be sure this isn't persisted
			formation.flipVertically();
		}
		return [{ path: '/opponents', op: PatchOpType.replace, value: opponents }, { path: '/opponentsChanged', op: PatchOpType.replace, value: false }];
	}

	public handleFormationChange(e) {
		const { diagram, opponentFormations } = this.props;
		const newVal = e.target.value;
		const hasChanges = (diagram as IPlay).opponentsChanged;
		const newFormation = newVal === 'none' ? null : find(opponentFormations, { id: newVal });

		if (hasChanges) {
			// prompt
			actions.pushAlert({
				message: _s(StringKey.CHANGE_OPPONENT_FORMATION_ALERT_MESSAGE),
				mode: AlertMode.prompt,
				severity: AlertSeverity.confirmation,
				title: _s(StringKey.CHANGE_OPPONENT_FORMATION_ALERT),
				actions: [{
					label: _s(StringKey.CANCEL),
					action: actions.popAlert,
				}, {
					label: _s(StringKey.OK),
					action: () => {
						this.setOpponents(newFormation);
						this._selectedFormation = newVal;
						setTimeout(actions.popAlert, 0);
					},
				}],
			});
		} else {
			this.setOpponents(newFormation);
			this._selectedFormation = newVal;
		}
	}

	public render() {
		const { playbookPermissions } = getCurrentContext();
		const { ballOnOptions, diagram, opponentFormations, updateDiagram, updateProfile, userProfile } = this.props;
		const play: IPlay = playbookPermissions.canUpdate && diagram.getModelName() === 'Play' ? diagram as IPlay : null;
		const formation: IFormation = playbookPermissions.canUpdate && diagram.getModelName() === 'Formation' ? diagram as IFormation : null;
		const routeTree: IRouteTree = playbookPermissions.canUpdate && diagram.getModelName() === 'RouteTree' ? diagram as IRouteTree : null;
		const lineOfScrimage = ballOnOptions && (find(ballOnOptions, (opt) => opt.offset === diagram.ballLocation.y) || find(ballOnOptions, { isDefault: true }));
		const ballOnList = ballOnOptions && map(ballOnOptions, (opt) => {
			return <div key={ opt.key } className="checkbox">
				<label className={ opt.key === lineOfScrimage.key ? 'on' : '' }><span className="icon checkmark"></span><span>{ opt.label }</span><input type="radio" name="ballOn" checked={ opt.key === lineOfScrimage.key } onChange={ () => updateDiagram([{ path: '/ballLocation/y', op: PatchOpType.replace , value: opt.offset }]) }  /></label>
			</div>;
		});
		const formationItems = !play ? [] : map(opponentFormations, (formation: IDiagram) => {
			return <div key={ formation.id } className="checkbox">
				<label className={ this._selectedFormation === formation.id ? 'on' : '' }><span className="icon checkmark"></span><span>{ formation.label
				}</span><input type="radio" name="opponentFormation" value={ formation.id } onChange={ this.handleFormationChange } checked={ this._selectedFormation === formation.id } /></label>
			</div>;
		});

		if (play) {
			formationItems.push(<div key="opp-form-none" className="checkbox">
				<label className={ this._selectedFormation === 'none' ? 'on' : '' }><span className="icon checkmark"></span><span>{ _s(StringKey.NONE) }</span><input type="radio" name="opponentFormation" value="none" onChange={ this.handleFormationChange } checked={ this._selectedFormation === 'none' } /></label>
			</div>);
		}

		return <div className="view">
			<header>
				<div className="title">{ _s(StringKey.FIELD_OPTIONS) }</div>
				<div className="actions">
					<a className="button" onClick={ viewManager.popPanel }><span className="icon ok"></span></a>
				</div>
			</header>
			<div className="content fieldOptions">
				<div className="inner">
					<div className="columns">
						<div className="group fieldSettings">
							<div className="radio">
								<label className={ !userProfile.backgroundMode ? 'on' : '' }><span className="icon fieldBackgroundDark"></span><span>{ _s(StringKey.DARK_FIELD) }</span><input type="radio" checked={ !userProfile.backgroundMode } onChange={ () => updateProfile('/backgroundMode', undefined) } value="0" /></label>
								<label className={ userProfile.backgroundMode === 'light' ? 'on' : '' }><span className="icon fieldBackgroundLight"></span><span>{ _s(StringKey.LIGHT) }</span><input type="radio" checked={ userProfile.backgroundMode === 'light' } onChange={ () => updateProfile('/backgroundMode', 'light') } value="1" /></label>
							</div>
							{ play || formation || routeTree?.mates.count === 1 ? <div className="radio">
								<label className={ !userProfile.snapToGrid ? 'on' : '' }><span className="icon fieldGridDontSnap"></span><span>{ _s(StringKey.DONT_SNAP) }</span><input type="radio" checked={ !userProfile.snapToGrid } onChange={ () => updateProfile('/snapToGrid', false) } value="0" /></label>
								<label className={ userProfile.snapToGrid ? 'on' : '' }><span className="icon fieldGridSnap"></span><span>{ _s(StringKey.SNAP_TO_GRID) }</span><input type="radio" checked={ userProfile.snapToGrid } onChange={ () => updateProfile('/snapToGrid', true) } value="1" /></label>
							</div>: null }
							{ !play ? null : <div className="radio">
								<label className={!play.showOpponents ? 'on' : '' }><span className="icon fieldHideOpponent"></span><span>{ _s(StringKey.HIDE_OPPONENT) }</span><input type="radio" checked={ !play.showOpponents } onChange={ this.handleShowOpponentsChange } value="0" /></label>
								<label className={ play.showOpponents ? 'on' : '' }><span className="icon fieldShowOpponent"></span><span>{ _s(StringKey.SHOW) }</span><input type="radio" checked={ !!play.showOpponents } onChange={ this.handleShowOpponentsChange } value="1" /></label>
							</div>}
							{ (play || formation) && diagram.phase !== GamePhase.SpecialTeams ? <div className="radio">
								<label className={ diagram.containerOffset === 5 ? 'on' : '' }><span className="icon fieldOffsetPlus5"></span><span>{ _s(StringKey.LESS_BACKFIELD) }</span><input type="radio" checked={ diagram.containerOffset === 5 } onChange={ this.handleContainerOffsetChange } value="5" /></label>
								<label className={ !diagram.containerOffset ? 'on' : '' }><span className="icon fieldOffset0"></span><span>{ _s(StringKey.STANDARD) }</span><input type="radio" checked={ !diagram.containerOffset } onChange={ this.handleContainerOffsetChange } value="0" /></label>
								<label className={ diagram.containerOffset === -5 ? 'on' : '' }><span className="icon fieldOffsetMinus5"></span><span>{ _s(StringKey.MORE_BACKFIELD) }</span><input type="radio" checked={ diagram.containerOffset === -5 } onChange={ this.handleContainerOffsetChange } value="-5" /></label>
							</div> : null}
						</div>
						<div className="columns">
							{ !play || opponentFormations.length === 1 ? null : <div className={ play.showOpponents ? 'group fieldOpponent tackleOnly' : 'group fieldOpponent tackleOnly disabled' }>
								<header><div className="title">{ _s(StringKey.OPPONENT_FORMATION) }</div></header>
								<div className="list scrollable">
									{ formationItems }
								</div>
							</div> }
							{ play || formation? <div className={ ballOnList ? 'group fieldPosition' : 'group disabled' }>
								<header><div className="title">{ _s(StringKey.BALL_ON) }</div></header>
								<div className="list scrollable">
									{ ballOnList }
								</div>
							</div>: null }
						</div>
					</div>
				</div>
			</div>
			<a className="button" onClick={ viewManager.popPanel }><span className="icon collapse"></span></a>
		</div>;
	}
}

import { forEach, map, sortBy } from 'lodash';
import * as React from 'react';
import { current as getCurrentContext } from '../componentContext';
import { IFormation } from '../models/formation';
import { IPlay } from '../models/play';
import { IPlaybook } from '../models/playbook';
import { SystemFeature } from '../models/subscriptionPlan';
import { ITag } from '../models/tag';
import { _s, StringKey } from '../strings';
import { CachedImage } from './cachedImage';
import { PrintControl, PrintPlay } from './printControl';

interface Props {
	categories: ITag[];
	fieldOptions: any;
	formations: IFormation[];
	optionValues: {[key: string]: any};
	playbook: IPlaybook;
	plays: IPlay[];
}

const _pageSize = 6;

const SponsorContent = () => {
	const { currentSubscription, sponsorLogoPrintUrl } = getCurrentContext();

	if (!sponsorLogoPrintUrl || !currentSubscription.hasFeature(SystemFeature.print)) {
		return null;
	}

	return <div className="sponsor">
		<div className="tribute">{ _s(StringKey.PRINTING_POWERED_BY) }</div>
		<span className="icon photo"><CachedImage src={ sponsorLogoPrintUrl } /></span>
	</div>;
};

function PrintPage({ sections }) {
	return <div className="printPage">

		<style type="text/css" dangerouslySetInnerHTML={ {__html: '@page {size: letter portrait;}' } } />

		<div className="branding">
			<SponsorContent />
			<div className="playmaker">
				<div className="logo"></div>
				<div className="product">WRISTBAND INSERTS</div>
			</div>
		</div>
		<div className="boundingBox"></div>
		{ sections }
		<div className="instructions">
			<span>FOLD UP, FOLD DOWN &amp; TEAR ALONG PERFORATED LINES</span><span>USE PRINT CALIBRATION IN THE APP TO ADJUST ALIGNMENT</span>
		</div>
	</div>;
}

export class PrintPMXWristbands extends React.Component<Props> {
	constructor(props) {
		super(props);
	}

	public render() {
		const { categories, fieldOptions, formations, optionValues, playbook, plays } = this.props;

		return <PrintControl optionValues={ optionValues } playbook={ playbook }>{ (params) => {
			const { className, buildPlayLists } = params;
			const classNames = [className, 'wristbands perfInserts'];
			const copies = Number(optionValues.copies.key);
			const groupSize = optionValues.layout.groupSize;
			const type = optionValues.layout.type;
			const sections = [];
			const pages = [];
			const { categoryList, formationList } = buildPlayLists(categories, formations, plays);
			let playCount = 0;

			if (type === 'diagram') {
				let groupPlays = [];
				if (categoryList.length) {
					forEach(categoryList, (c) => {
						groupPlays = groupPlays.concat(c.plays);
					});
				} else if (formationList.length) {
					forEach(formationList, (f) => {
						groupPlays = groupPlays.concat(f.plays);
					});
				}

				const sectionCount = Math.ceil(groupPlays.length / groupSize);
				for (let i = 0; i < copies; i ++) {
					playCount = 0;
					for (let j = 0; j < sectionCount; j ++) {
						const sectionPlays = groupPlays.slice(j * groupSize, (j + 1) * groupSize);
						const playContent = map(sectionPlays, (play: IPlay) =>  <PrintPlay key={ playCount } play={ play } playCount={ playCount++ } fieldOptions={ fieldOptions } />);

						sections.push(<section key={ `${i}-${j}` }>{ playContent }</section>);
					}
				}
			} else if (type === 'list') {
				const items = [];

				if (categoryList.length) {
					let colorNumber = 0;

					forEach(sortBy(categoryList, 'sortIndex'), (c) => {
						if (categoryList.length > 1) {
							items.push({ categoryName: c.label, className: `category color${colorNumber}` });
						}

						forEach(c.plays, (p) => items.push({ className: `play color${colorNumber}`, play: p }));

						if (categoryList.length > 1) {
							colorNumber = colorNumber === 8 ? 0 : colorNumber + 1;
						}
					});
				} else if (formationList.length) {
					forEach(formationList, (f) => {
						forEach(f.plays, (p) => items.push({ play: p }));
					});
				}

				const sectionCount = Math.ceil(items.length / groupSize);
				for (let i = 0; i < copies; i ++) {
					playCount = 0;
					for (let j = 0; j < sectionCount; j ++) {
						const sectionItems = items.slice(j * groupSize, (j + 1) * groupSize);
						const playContent = map(sectionItems, (item) => {
							if (item.categoryName) {
								return <div key={ item.categoryName }className={ item.className }><div className="info"><span>{ item.categoryName }</span></div></div>;
							} else {
								const play = item.play as IPlay;

								return  <PrintPlay className={ item.className } key={ playCount } play={ play } playCount={ playCount++ } fieldOptions={ fieldOptions} />;
							}

						});

						sections.push(<section key={ j }>{ playContent }</section>);
					}
				}
			}

			// console.log(`groupSize ${groupSize}`);
			classNames.push(map(optionValues, (v) => v.className).join(' '));

			const pageCount = Math.ceil(sections.length / _pageSize);

			for (let i = 0; i < pageCount; i ++) {
				const pageSections = sections.slice(i * _pageSize, (i + 1) * _pageSize);

				pages.push(<PrintPage key={ i } sections={pageSections } />);
			}

			return <div className={ classNames.join(' ') }>
				{ pages }
			</div>;
		}}</PrintControl>;

	}
}

export class PrintPMXCalibration extends React.Component<Props> {
	constructor(props) {
		super(props);
	}

	public render() {
		const { optionValues, playbook } = this.props;

		return <PrintControl optionValues={ optionValues } playbook={ playbook } >{ (params) => {
			const { className } = params;
			const classNames = [className, 'wristbands perfInserts calibration'];

			return <div className={ classNames.join(' ') }>
				<div className="printPage">

					<style type="text/css" dangerouslySetInnerHTML={ {__html: '@page {size: letter portrait;}' } } />

					<div className="branding">
						<div className="playmaker">
							<div className="logo"></div>
							<div className="product">PRINTER CALIBRATION</div>
						</div>
					</div>

					<section>
						<div className="corner">
							<div>This corner should be 1 inch from<br/>the top &amp; right edges of the page</div>
						</div>
					</section>
					<section></section>
					<section>
						<div>
							<ol>
								<li>Test print on 8.5x11 inch paper</li>
								<li>Use a ruler to measure the<br/><span className="green">green rectangle</span> &amp; <span className="red">red corners</span></li>
								<li>If needed, adjust your printer margin or % size settings</li>
							</ol>
						</div>
					</section>
					<section></section>
					<section>
						<div>This rectangle should be<br/>2.25 inch wide x 4.5 inch tall</div>
					</section>
					<section>
						<div className="corner">
							<div>This corner should be 1 inch from<br/>the left &amp; bottom edges of the page</div>
						</div>
					</section>

				</div>
			</div>;
		}}</PrintControl>;

	}
}

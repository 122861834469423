import { assign, filter, find, forEach, map, reduce, sortBy } from 'lodash';
import { default as valueFilters } from 'playmaker-team-common/dist/shared/valueFilters';
import * as React from 'react';
import * as actions from '../actions';
import { current as getCurrentContext } from '../componentContext';
import { GamePhase } from '../models/diagramModel';
import { default as playbookFactory, IPlaybook } from '../models/playbook';
import { ISortable } from '../models/sortableModel';
import { default as tagFactory, ITag } from '../models/tag';
import { default as teamFactory, ITeam } from '../models/team';
import * as store from '../store';
import { IDragData } from '../store';
import { _s, StringKey } from '../strings';
import * as viewManager from '../viewManager';
import { AlertList } from './alert';
import { ConnectionStatus } from './connectionStatus';
import { DragDrop, IDragSourceParams, IDropTargetParams } from './dnd';
import { Pageable } from './page';
import { HelpButton } from './helpButton';

interface Props {
	alerts?: store.IAlert[];
	appState: store.IAppState;
}

interface State {
	playbook: IPlaybook;
	pendingCategory: any;
	selectedItems: string[];
	team: ITeam;
}

const COLORS = [1, 2, 3, 13, 5, 6, 7, 8, 10, 11, 12, 4, 14, 15, 9, 16];

class EditCategory extends React.Component<any, any> {
	constructor(props) {
		super(props);

		this.state = {
			category: props.category,
		};
	}
	public handleChange(path, e) {
		const category = tagFactory(this.state.category);
		const newVal = e.type === 'blur' ? valueFilters.clean(e.target.value) : e.target.value;

		category.setAt(path, newVal);

		this.setState({ category }, () => {
			this.props.handleChange(category);
		});
	}

	public render() {
		const { deleteCategory } = this.props;
		const { category } = this.state;
		const handleClick = () => {
			if (category.isValid()) {
				viewManager.popModal();
			}
		};

		const colorOptions = map(COLORS, (color) => {
			const isChecked = category?.color === color;

			return <div key={ color } className={ `color color${color}` }>
				<label className={ isChecked ? 'on' : '' }><span></span><input type="radio" value={ color } checked={ isChecked } onChange={ this.handleChange.bind(this, 'color') } /></label>
			</div>;
		});

		return <div className="view">
			<header>
				<div className="title">{ _s(StringKey.EDIT_CATEGORY) }</div>
				<div className="actions">
					<a className="button" onClick={ handleClick }><span className="icon ok"></span></a>
				</div>
			</header>
			<Pageable labels={ [_s(StringKey.INFO), _s(StringKey.NOTES)] }>
				<div className="content categoryInfo">
					<div className="inner">
						<input type="text" placeholder={ _s(StringKey.CATEGORY_NAME) } value={ category.label } onChange={ this.handleChange.bind(this, 'label') } onBlur={ this.handleChange.bind(this, 'label') } required />
						<h2>{ _s(StringKey.CUSTOM_PRINT_COLOR_CODING) } <HelpButton flagUrl="https://support.wearetrue.com/hc/en-us/articles/29599633017869-Custom-Color-Coding-for-Categories" tackleUrl="https://support.wearetrue.com/hc/en-us/articles/29581709256845-Custom-Color-Coding-for-Categories-Formations" /></h2>
						<div className="radio colors printColorCoding">
							{ colorOptions }
						</div>
						<div className="actions">
							<a className="button basic delete" onClick={ deleteCategory }><span>{ _s(StringKey.DELETE) }</span></a>
						</div>
					</div>
				</div>
				<div className="content categoryInfo">
					<div className="inner">
						<textarea placeholder={ _s(StringKey.OPTIONAL_CATEGORY_NOTES) } onChange={ this.handleChange.bind(this, 'note') } onBlur={ this.handleChange.bind(this, 'note') }>{ category.note }</textarea>
					</div>
				</div>
			
			</Pageable>
			
		</div>;
	}
}

class CategoryRow extends React.Component<any, any> {
	private _dropTarget;

	constructor(props) {
		super(props);

		this.canDrop = this.canDrop.bind(this);
		this.onDrop = this.onDrop.bind(this);

		this.setDropTarget = this.setDropTarget.bind(this);
	}

	public canDrop(data: IDragData) {
		const { category } = this.props;

		return data.type === 'row' && data.info.category && data.info.category.id !== category.id;
	}

	public onDrop(data: IDragData) {
		const { category, onDrop } = this.props;

		if (onDrop) {
			onDrop(data.info.category.id, category.id);
		}
	}

	public setDropTarget(el) {
		const { addDropTarget, removeDropTarget } = this.props;

		if (!addDropTarget) {
			return;
		}

		if (this._dropTarget) {
			removeDropTarget(this._dropTarget);
		}

		this._dropTarget = el;

		if (this._dropTarget) {
			addDropTarget(this._dropTarget, this.canDrop, this.onDrop);
		}
	}

	public render() {
		const { category, classNames, isSelected, onSelectedChange, onInfoClick, onPointerDown, onTouchStart, onMouseDown, rowNumber } = this.props;

		if(category?.color) {
			classNames.push(`color${category.color}`);
		}

		return <div className={ classNames.join(' ') } ref={ this.setDropTarget }>
			<div className="checkbox">
				<label className={ isSelected ? 'on' : '' }><span className="icon badge checkmark">{ rowNumber }</span><input type="checkbox" checked={ isSelected } value={ category.id } onChange={ onSelectedChange } /></label>
			</div>
			<span>{ category.label }</span>
			<a className="button" onClick={ onInfoClick }><span className="icon infoSmall"></span></a>
			<a className="button" onPointerDown={ onPointerDown } onTouchStart={ onTouchStart } onMouseDown={ onMouseDown } style={{ touchAction: 'none' }}><span className="icon sortItem"></span></a>
		</div>;
	}
}

// TODO: be sure categories are filtered by phase (and playersPerSide?)
export class Categories extends React.Component<Props, State> {

	constructor(props) {
		super(props);

		this.state = this.getStateFromProps(props);

		this.handleAddClick = this.handleAddClick.bind(this);
		this.handleBackClick = this.handleBackClick.bind(this);
		this.handleItemSelected = this.handleItemSelected.bind(this);
		this.handleSelectAll = this.handleSelectAll.bind(this);
		this.handleDeleteClick = this.handleDeleteClick.bind(this);
		this.handleItemDrop = this.handleItemDrop.bind(this);
		this.handleChange = this.handleChange.bind(this);
	}

	public getStateFromProps(props: Props) {
		const { appState } = props;
		const { model, viewState } = appState;
		const { currentPlaybook } = getCurrentContext();
		const team: ITeam = teamFactory(model.teams[viewState.currentTeamId]);
		const playbook: IPlaybook = team.playbooks[currentPlaybook.id];

		return { playbook, pendingCategory: this.createPendingCategory(playbook), selectedItems: [], team };
	}

	public createPendingCategory(playbook: IPlaybook) {
		const { currentPhase } = getCurrentContext();
		const sortIndex = Math.ceil(reduce(playbook.settings.categories.values, (max, cat) => ((cat.sortIndex || 0) > max) ?  (cat.sortIndex || 0) : max, 0)) + 1;
		return tagFactory({ phase: currentPhase, sortIndex });
	}

	public async handleBackClick() {
		const { playbook, team } = this.state;

		team.playbooks[playbook.id] = playbook;

		viewManager.popPath();

		await actions.saveTeam(team, `category-update-${team.id}`);
	}

	public handleChange(updatedCategory) {
		const { playbook } = this.state;
		const mutatedPlaybook = playbookFactory(playbook);

		const existingCategory = find(mutatedPlaybook.settings.categories, { id: updatedCategory.id });

		if (existingCategory) {
			mutatedPlaybook.settings.categories[updatedCategory.id] = updatedCategory;
		}

		this.setState({ playbook: mutatedPlaybook });
	}

	public handleAddClick() {
		const { pendingCategory, playbook } = this.state;
		const { currentPhase } = getCurrentContext();
		const mutatedPlaybook = playbookFactory(playbook);

		pendingCategory.phase = currentPhase; // ensures a phase when this category is added
		if (pendingCategory.isValid()) {
			mutatedPlaybook.settings.categories.add(pendingCategory);
			// mutatedPlaybook.settings.playLists.add({
			// 	relatedId: pendingCategory.id,
			// 	playIds: '',
			// });
			this.setState({ pendingCategory: this.createPendingCategory(mutatedPlaybook), playbook: mutatedPlaybook});
		}
	}

	public handleInfoClick(id) {
		viewManager.pushModal({
			component: EditCategory,
			props: () => {
				const { playbook } = this.state;
				const category = find(playbook.settings.categories, { id });

				return { category, handleChange: this.handleChange, deleteCategory: this.deleteCategory.bind(this, id) };
			},
			screenName: 'EditCategory',
		});
	}

	public handleItemSelected(e) {
		const { selectedItems } = this.state;
		const updatedItems = selectedItems.concat();
		const itemIndex = updatedItems.indexOf(e.target.value);

		if (itemIndex === -1) {
			updatedItems.push(e.target.value);
		} else {
			updatedItems.splice(itemIndex, 1);
		}

		this.setState({ selectedItems: updatedItems });
	}

	public handleSelectAll() {
		const { playbook, selectedItems } = this.state;
		const phaseCategories = this.getCategories(playbook);
		let updatedItems = [];

		if (selectedItems.length < phaseCategories.length) {
			updatedItems = map(phaseCategories, (c) => c.id);
		}

		this.setState({ selectedItems: updatedItems });
	}

	public handleDeleteClick() {
		const { playbook, selectedItems } = this.state;
		const mutatedPlaybook = playbookFactory(playbook);

		forEach(selectedItems, (item) => {
			mutatedPlaybook.settings.categories.remove(item);
		});

		this.setState({ playbook: mutatedPlaybook, selectedItems: [] });
	}

	public deleteCategory(id) {
		const { playbook } = this.state;
		const mutatedPlaybook = playbookFactory(playbook);

		mutatedPlaybook.settings.categories.remove(id);

		this.setState({ playbook: mutatedPlaybook });

		actions.popModal();
	}

	public handleItemDrop(sourceId: string, targetId: string) {
		this.setState((prevState: State) => {
			const newState: State = assign({}, prevState);
			const { playbook } = newState;
			const categories = playbook.settings.categories.values;
			const source = playbook.settings.categories[sourceId];
			const target = playbook.settings.categories[targetId];
			const targetSortIndex = target.sortIndex;
			let index = 0;

			source.sortIndex = (source.sortIndex > targetSortIndex) ? targetSortIndex - 0.5 : targetSortIndex + 0.5;

			forEach(sortBy(categories, 'sortIndex'), (category: ISortable) => {
				category.sortIndex = index++;
			});

			return newState;
		});
	}

	public getCategories(playbook: IPlaybook) {
		const { currentPhase } = getCurrentContext();
		return filter(playbook.settings.categories.values, { phase: currentPhase });
	}

	public render() {
		const { alerts= [] } = this.props;
		const { pendingCategory, playbook, selectedItems } = this.state;
		const { currentPhase } = getCurrentContext();
		const phaseLabelString = currentPhase === GamePhase.Offense ? _s(StringKey.OFFENSIVE_CATEGORIES) : currentPhase === GamePhase.Defense ? _s(StringKey.DEFENSIVE_CATEGORIES) : _s(StringKey.SPECIAL_TEAMS_CATEGORIES);
		const addChangeHandler = (e) => {
			pendingCategory.label = e.type === 'blur' ? valueFilters.clean(e.target.value) : e.target.value;

			this.handleChange(pendingCategory);
		};
		let rowNumber = 1;

		const rows = map(sortBy(this.getCategories(playbook), 'sortIndex'), (category: ITag & ISortable) => {
			const itemRowNumber = rowNumber ++;
			const isSelected = selectedItems.indexOf(category.id) !== -1;
			const dragSpec = {
				component: () => <div className="group"><CategoryRow category={ category} classNames={ ['row', 'dragging'] } isSelected={ isSelected } rowNumber={ itemRowNumber } /></div> ,
				getDragLayout: (touch, currentTarget) => {
					const parent = currentTarget.parentElement;
					const boundingRect = parent.getBoundingClientRect();

					return { x: touch.clientX - boundingRect.left, y: touch.clientY - boundingRect.top, width: boundingRect.width, height: boundingRect.height, constrain: 'x', scrollContainer: parent.parentElement };
				},
				data: {
					type: 'row',
					info: {
						category,
					},
				},
			};

			return <DragDrop key={ category.id }>{
				(params: IDragSourceParams & IDropTargetParams) => {
					const { activeDragSpec, handleMouseDown, handlePointerDown, handleTouchStart, ...dragProps } = params;
					const classNames = ['row'];

					if (activeDragSpec) {
						classNames.push('dropTarget');
						classNames.push(activeDragSpec.data.info.category.sortIndex < category.sortIndex ? 'bottom' : 'top');
					}

					return <CategoryRow
						category={ category}
						classNames={ classNames }
						isSelected={ isSelected }
						rowNumber={ itemRowNumber }
						onSelectedChange={ this.handleItemSelected }
						onInfoClick={ this.handleInfoClick.bind(this, category.id) }
						onDrop={ this.handleItemDrop }
						onPointerDown={ handlePointerDown && handlePointerDown.bind(this, dragSpec) }
						onTouchStart={ handleTouchStart && handleTouchStart.bind(this, dragSpec) }
						onMouseDown={ handleMouseDown && handleMouseDown.bind(this, dragSpec) }
						{ ...dragProps }
					/>;
				}
			}</DragDrop>;
		});

		return <React.Fragment>
			<header>
				<div className="actions">
					<a className="button" onClick={ this.handleBackClick }><span className="icon back"></span><span>{ phaseLabelString }</span></a>
				</div>
				<div className="actions">
					<ConnectionStatus />
				</div>
			</header>
			<AlertList alerts={ alerts } />
			<div className="content">
				<div className="view">
					<div className="content scrollable">
						<div className="row quickAdd">
							<input type="text" placeholder={ _s(StringKey.ENTER_NEW_CATEGORY_NAME) } value={ pendingCategory.label || '' } onChange={ addChangeHandler } onBlur={ addChangeHandler } required />
							<a className="button" onClick={ this.handleAddClick }><span className="icon addItem"></span></a>
						</div>
						<div className="group categoryTable">
							<div className="list">
								<header>
									<div className="row">
										<div className="checkbox">
											<label className={ rows.length > 0 && rows.length === selectedItems.length ? 'on' : '' } onClick={ this.handleSelectAll }><span className="icon badge checkmark"></span></label>
										</div>
										<span>{ _s(StringKey.CATEGORY) }</span>
									</div>
								</header>
								{ rows }
							</div>
						</div>
					</div>

					{ /* Set footer class to "in" when one or more list item has been selected */ }
					<footer className={ selectedItems.length ? 'multiSelect in' : 'multiSelect' }>
						<div className="row">
							<a className="button basic delete" onClick={ this.handleDeleteClick }><span>{ _s(StringKey.DELETE) }</span></a>
						</div>
					</footer>
				</div>
			</div>
		</React.Fragment>;
	}
}

import { forEach, map } from 'lodash';
import * as React from 'react';
import { current as getCurrentContext } from '../componentContext';
import { IFormation } from '../models/formation';
import { IPlay } from '../models/play';
import { IPlaybook } from '../models/playbook';
import { SystemFeature } from '../models/subscriptionPlan';
import { ITag } from '../models/tag';
import { _s, StringKey } from '../strings';
import { CachedImage } from './cachedImage';
import { PrintControl, PrintPlay } from './printControl';

interface Props {
	categories: ITag[];
	fieldOptions: any;
	formations: IFormation[];
	optionValues: {[key: string]: any};
	playbook: IPlaybook;
	plays: IPlay[];
}

const SponsorContent = () => {
	const { currentSubscription, sponsorLogoPrintUrl } = getCurrentContext();

	if (!sponsorLogoPrintUrl || !currentSubscription.hasFeature(SystemFeature.print)) {
		return null;
	}

	return <section>
		<div className="sponsor">
			<div className="tribute">{ _s(StringKey.PRINTING_POWERED_BY) }</div>
			<span className="icon photo"><CachedImage src={ sponsorLogoPrintUrl } /></span>
		</div>
	</section>;
};

interface IPrintGroupItem { 
	play: IPlay,
	group: ITag | IFormation
}

export class PrintWristbands extends React.Component<Props> {
	constructor(props) {
		super(props);
	}

	public render() {
		const { categories, fieldOptions, formations, optionValues, playbook, plays } = this.props;

		return <PrintControl optionValues={ optionValues } playbook={ playbook }>{ (params) => {
			const { className, buildPlayLists } = params;
			const classNames = [className, 'wristbands'];
			const copies = Number(optionValues.copies.key);
			const groupSize = optionValues.layout.groupSize;
			// const type = optionValues.layout.type;
			const playColorCoding = optionValues.playColorCoding?.key;
			const { categoryList, formationList } = buildPlayLists(categories, formations, plays);
			const sections = [];
			let playCount = 0;

			let groupPlays:IPrintGroupItem[] = [];

			if (categoryList.length) {
				forEach(categoryList, (c) => {
					groupPlays = groupPlays.concat(c.plays.map(p => { return {play: p, group: c };}));
				});
			} else if (formationList.length) {
				forEach(formationList, (f) => {
					groupPlays = groupPlays.concat(f.plays.map(p => { return {play: p, group: f };}));
				});
			}

			const sectionCount = Math.ceil(groupPlays.length / groupSize);

			for (let i = 0; i < copies; i ++) {
				playCount = 0;
				for (let j = 0; j < sectionCount; j ++) {
					const sectionPlays = groupPlays.slice(j * groupSize, (j + 1) * groupSize);

					const playContent = map(sectionPlays, (item: IPrintGroupItem) =>  {
						const classes = ['printPlay'];

						if(playColorCoding === 'custom' && item.group.color) {
							classes.push(`color${item.group.color}`);
						}

						return <PrintPlay key={ playCount } play={ item.play } className={classes.join(' ')} playCount={ playCount++ } fieldOptions={ fieldOptions } />;
					});

					sections.push(<section key={ `${i}-${j}` }>{ playContent }</section>);
				}
			}

			// console.log(`groupSize ${groupSize}`);
			classNames.push(map(optionValues, (v) => v.className).join(' '));

			return <div className={ classNames.join(' ') }>
				<div className="printPage">
					<SponsorContent />
					{ sections }
				</div>
			</div>;
		}}</PrintControl>;

	}
}

import { assign, filter, find, findIndex } from 'lodash';
import { IRouteMatchState } from 'playmaker-team-common/dist/client/router';
import { deleteTooltip } from './actions';
import { IPlaybookPermissions, ITeamPermissions } from './authorizationHelper';
import { current as getCurrentContext } from './componentContext';
import { IPlaybook } from './models/playbook';
import { ITeam } from './models/team';
import { IUser } from './models/user';
import { HelpSwitches, IAppState, ITooltip, mutate as mutateStore } from './store';
import { _s, StringKey } from './strings';

type tooltipTrigger = ({ currentTeam, currentUser, currentPlaybook, currentRoute, previousRoute, oldState, newState, teamPermissions, playbookPermissions }:
	{ currentTeam: ITeam, currentUser: IUser, currentPlaybook: IPlaybook, currentRoute: IRouteMatchState, previousRoute: IRouteMatchState, oldState: IAppState, newState: IAppState, teamPermissions: ITeamPermissions, playbookPermissions: IPlaybookPermissions }) => void;

const _tooltipTriggers: tooltipTrigger[] = [
	playbookCustomizeFormationsAndCategories,
	playbookRearrangePlays,
	playEditPosition,
];

export function onStoreMutating({ oldState, newState }: { oldState: IAppState, newState: IAppState }) {
	// const oldRoute = oldState.viewState.currentRoute;
	// const newRoute = newState.viewState.currentRoute;
	const { currentPlaybook, currentTeam, currentUser, playbookPermissions, teamPermissions } = getCurrentContext();

	if (currentTeam && currentUser) {
		if (newState.viewState.currentRoute) {
			const currentRoute = newState.viewState.currentRoute;
			const previousRoute = oldState.viewState.currentRoute;

			newState.viewState = assign({}, newState.viewState);

			// route change, clear all of the tooltips
			if (currentRoute && !previousRoute || currentRoute.route.path !== previousRoute.route.path) {
				newState.viewState.tooltips = [];
			}

			if (playbookPermissions) {
				if (playbookPermissions.canUpdate) {
					for (const trigger of _tooltipTriggers) {
						trigger({ currentTeam, currentUser, currentPlaybook, currentRoute, previousRoute, oldState, newState, teamPermissions, playbookPermissions });
					}
				}
			}
		}
	}
}

export function pushTooltip(tooltip: ITooltip, condition?: (appState: IAppState) => boolean) {
	mutateStore((appState) => {
		if (!condition || condition(appState)) {
			appState.viewState = assign({}, appState.viewState);
			appState.viewState.tooltips = appState.viewState.tooltips.concat();

			const newTooltip = assign({ initialDelay: 400 }, tooltip);

			_pushTooltip(newTooltip, appState);
		}
	});
}

function playbookCustomizeFormationsAndCategories({ currentTeam, currentUser, currentPlaybook, currentRoute, previousRoute, oldState, newState, teamPermissions, playbookPermissions }:
	{ currentTeam: ITeam, currentUser: IUser, currentPlaybook: IPlaybook, currentRoute: IRouteMatchState, previousRoute: IRouteMatchState, oldState: IAppState, newState: IAppState, teamPermissions: ITeamPermissions, playbookPermissions: IPlaybookPermissions }) {
	const switchSet = (newState.viewState.helpSwitches & HelpSwitches.playbookCustomizeFormationsAndCategories) === HelpSwitches.playbookCustomizeFormationsAndCategories;
	const isEntering = isEnteringRoute(currentRoute, previousRoute, '/playbook/:playbookid');

	if (!switchSet && isEntering && filter(newState.model.plays, { playbookId: currentPlaybook.id }).length > 0) {
		_pushTooltip({
			id: 'playbookCustomizeFormations',
			className: 'down',
			text: _s(StringKey.TOOLTIP_PLAYBOOK_CUSTOMIZE_FORMATIONS),
			helpSwitch: HelpSwitches.playbookCustomizeFormationsAndCategories,
			requiresTarget: true,

		}, newState);

		_pushTooltip({
			id: 'playbookCustomizeCategories',
			className: 'down',
			text: _s(StringKey.TOOLTIP_PLAYBOOK_CUSTOMIZE_CATEGORIES),
			helpSwitch: HelpSwitches.playbookCustomizeFormationsAndCategories,
			requiresTarget: true,

		}, newState);
	}

	return false;
}

function playbookRearrangePlays({ currentTeam, currentUser, currentPlaybook, currentRoute, previousRoute, oldState, newState, teamPermissions, playbookPermissions }:
	{ currentTeam: ITeam, currentUser: IUser, currentPlaybook: IPlaybook, currentRoute: IRouteMatchState, previousRoute: IRouteMatchState, oldState: IAppState, newState: IAppState, teamPermissions: ITeamPermissions, playbookPermissions: IPlaybookPermissions }) {
	const customizeFormationsAndCategoriesSwitchSet = (newState.viewState.helpSwitches & HelpSwitches.playbookCustomizeFormationsAndCategories) === HelpSwitches.playbookCustomizeFormationsAndCategories;
	const switchSet = (newState.viewState.helpSwitches & HelpSwitches.playbookRearrangePlays) === HelpSwitches.playbookRearrangePlays;
	const isEntering = isEnteringRoute(currentRoute, previousRoute, '/playbook/:playbookid');

	if (customizeFormationsAndCategoriesSwitchSet && !switchSet && isEntering && filter(newState.model.plays, { playbookId: currentPlaybook.id, phase: 0 }).length > 1) {
		_pushTooltip({
			id: 'playbookRearrangePlays',
			className: 'down',
			text: _s(StringKey.TOOLTIP_PLAYBOOK_REARRANGE_PLAYS),
			helpSwitch: HelpSwitches.playbookRearrangePlays,
			requiresTarget: true,

		}, newState);
	}

	return false;
}

function playEditPosition({ currentTeam, currentUser, currentPlaybook, currentRoute, previousRoute, oldState, newState, teamPermissions, playbookPermissions }:
	{ currentTeam: ITeam, currentUser: IUser, currentPlaybook: IPlaybook, currentRoute: IRouteMatchState, previousRoute: IRouteMatchState, oldState: IAppState, newState: IAppState, teamPermissions: ITeamPermissions, playbookPermissions: IPlaybookPermissions }) {
	const switchSet = (newState.viewState.helpSwitches & HelpSwitches.playEditPosition) === HelpSwitches.playEditPosition;
	const isEntering = isEnteringRoute(currentRoute, previousRoute, '/playbook/:playbookid/plays/:playid');

	if (!switchSet && isEntering && filter(newState.model.plays, { playbookId: currentPlaybook.id }).length > 0) {
		_pushTooltip({
			id: 'playEditPosition',
			className: 'floating',
			text: _s(StringKey.TOOLTIP_DIAGRAM_EDIT_POSITION),
			requiresTarget: false,

		}, newState);
	}
}

function isEnteringRoute(currentRoute: IRouteMatchState, previousRoute: IRouteMatchState, path: string) {
	return currentRoute.route.path === path && (!previousRoute || previousRoute.route.path !== path);
}

function _pushTooltip(tooltip: ITooltip, appState: IAppState) {
	const id = tooltip.id;

	appState.viewState.tooltips.push(tooltip);

	setTimeout(() => deleteTooltip(id), 4200);
}

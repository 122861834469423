import { filter, find } from 'lodash';
import * as cryptoUtil from './cryptUtil';
import { IPlaybook } from './models/playbook';
import { PlaybookAccess } from './models/playbookMember';
import { ISubscription } from './models/subscription';
import { ITeam } from './models/team';
import { TeamRole } from './models/teamMember';
import { IUser } from './models/user';
import * as store from './store';

interface IBasePermissions {
	canView: boolean;
	canUpdate: boolean;
	canManageMembers: boolean;
	memberId: string;
}

export interface ITeamPermissions extends IBasePermissions {
	canAddPlaybooks: boolean;
	canRemovePlaybooks: boolean;
	canTransferPlaybooks: boolean;
	canUpdatePlaybooks: boolean;
	canChangeAdmins: boolean;
	canManageBilling: boolean;
}

export type IPlaybookPermissions = IBasePermissions;

export function createEmptyTeamPermissions(): ITeamPermissions {
	return {
		canAddPlaybooks: false,
		canRemovePlaybooks: false,
		canTransferPlaybooks: false,
		canUpdatePlaybooks: false,
		canView: false,
		canUpdate: false,
		canManageBilling: false,
		canManageMembers: false,
		canChangeAdmins: false,
		memberId: undefined,
	};
}

export function createEmptyPlaybookPermissions(): IPlaybookPermissions {
	return {
		canView: false,
		canUpdate: false,
		canManageMembers: false,
		memberId: undefined,
	};
}

export function getTeamPermissions({ team, user }: { team?: ITeam, user?: IUser } = {}): ITeamPermissions {
	const permissions = createEmptyTeamPermissions();
	const appState = store.appState();
	const { model, viewState } = appState;

	team = team || find(model.teams, { id: viewState.currentTeamId });
	user = user || model.users[viewState.currentUserId];

	if (user && team && team.members.count) {
		const member = find(team.members.values, { userId: user.id });
		const isAdmin = user.role === 'admin';

		permissions.canView = (isAdmin || (member && member.role >= TeamRole.None));
		permissions.canUpdate = (isAdmin || (member && member.role >= TeamRole.Staff));
		permissions.canManageMembers = (isAdmin || (member && member.role >= TeamRole.Staff));
		permissions.canChangeAdmins = (isAdmin || (member && member.role === TeamRole.Owner));
		permissions.canManageBilling = (isAdmin || (member && member.role === TeamRole.Owner));
		permissions.canAddPlaybooks = (isAdmin || (member && member.role >= TeamRole.Staff));
		permissions.canRemovePlaybooks = (isAdmin || (member && member.role >= TeamRole.Staff));
		permissions.canTransferPlaybooks = (isAdmin || (member && member.role >= TeamRole.Staff));
		permissions.canUpdatePlaybooks = (isAdmin || (member && member.role >= TeamRole.Staff));
		permissions.memberId = member && member.id;
	}

	return permissions;
}

export function getPlaybookPermissions({ playbook, teamPermissions, user}: { playbook: IPlaybook, teamPermissions?: ITeamPermissions, user?: IUser }): IPlaybookPermissions {
	const permissions = createEmptyPlaybookPermissions();
	const appState = store.appState();
	const { model, viewState } = appState;

	user = user || model.users[viewState.currentUserId];
	teamPermissions = teamPermissions || getTeamPermissions({ user });

	if (user && playbook) {
		const playbookAccess = find(playbook.permissions.values, { teamMemberId: teamPermissions.memberId });
		const isAdmin = user.role === 'admin' || teamPermissions.canChangeAdmins;

		permissions.canView = (isAdmin || (playbookAccess && playbookAccess.access > PlaybookAccess.None));
		permissions.canUpdate = (isAdmin || (playbookAccess && playbookAccess.access >= PlaybookAccess.Edit));
		permissions.canManageMembers = isAdmin || teamPermissions.canManageMembers;
		permissions.memberId = teamPermissions.memberId;
	}

	return permissions;
}

export function getUserPlaybooks({ team, teamPermissions, user}: { team?: ITeam, teamPermissions?: ITeamPermissions, user?: IUser }) {
	const appState = store.appState();
	const { model, viewState } = appState;

	team = team || find(model.teams, { id: viewState.currentTeamId });
	user = user || model.users[viewState.currentUserId];
	teamPermissions = teamPermissions || getTeamPermissions({ team, user });

	return filter(team.playbooks.values, (playbook) => { const perms = getPlaybookPermissions({ playbook, teamPermissions}); return perms.canView; }) as IPlaybook[];
}

export function hasPlaybooksPage({ playbooks, team, teamPermissions, user}: { playbooks?: IPlaybook[],  team?: ITeam, teamPermissions?: ITeamPermissions, user?: IUser }) {
	const appState = store.appState();
	const { model, viewState } = appState;

	team = team || find(model.teams, { id: viewState.currentTeamId });
	user = user || model.users[viewState.currentUserId];
	teamPermissions = teamPermissions || getTeamPermissions({ team, user });
	playbooks = playbooks || getUserPlaybooks({ team, teamPermissions, user });

	return (teamPermissions.canAddPlaybooks || playbooks.length > 1);
}

export function hasValidSubscription(team: ITeam) {
	return cryptoUtil.verify(JSON.stringify(team.currentSubscription.toSignatureMessage()), team._cs);
}

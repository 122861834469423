import { assign, forEach, map, startCase } from 'lodash';
import { default as valueFilters } from 'playmaker-team-common/dist/shared/valueFilters';
import * as React from 'react';
import { current as getCurrentContext } from '../componentContext';
import { SystemFeature } from '../models/subscriptionPlan';
import { default as teamMemberFactory, ITeamMember, TeamRole } from '../models/teamMember';
import * as store from '../store';
import { _s, StringKey } from '../strings';
import * as viewManager from '../viewManager';
import { AlertList } from './alert';
import { Avatar } from './avatar';
import { TransferOwnershipModal } from './transferOwnershipModal';
import { getTeamPermissions } from '../authorizationHelper';
import { Spinner } from './spinner';

interface Props {
	alertList: any;
	accessAlert: any;
	cancelAction: () => void;
	confirmAction: () => void;
	confirmActionLabel?: string;
	confirmActionClassName: string;
	isNew: boolean;
	teamMember?: ITeamMember;
	onTeamMemberChange: (path: string, val: any) => void;
	deleteMembers: (teamMembers: ITeamMember[]) => void;
	sendInvites: (teamMembers: ITeamMember[]) => Promise<void>;
}

interface State {

}

export class TeamMemberEdit extends React.Component<Props, State> {
	private onEmailChange;
	private onFirstNameChange;
	private onJerseyNumberChange;
	private onLastNameChange;
	private onPositionChange;
	private roles;

	constructor(props) {
		super(props);

		const { teamMember } = props;
		const { currentTeam } = getCurrentContext();
		const teamPermissions = getTeamPermissions({ team: currentTeam });

		forEach(['email', 'firstName', 'jerseyNumber', 'lastName', 'position'], (path) => {
			const pathParts = map(path.split('/'), (part) => {
				return startCase(part).replace(/\s/g, '');
			});
			const handlerName = `on${pathParts.join('')}Change`;
			// console.log(handlerName);

			this[handlerName] = this.onChange.bind(this, path);
		});

		this.onAvatar = this.onAvatar.bind(this);
		this.onAvatarRemoved = this.onAvatarRemoved.bind(this);
		this.onDeleteClick = this.onDeleteClick.bind(this);
		this.onSendInviteClick = this.onSendInviteClick.bind(this);
		this.onRoleChange = this.onRoleChange.bind(this);

		this.roles =  (teamMember?.userId && teamPermissions.canManageBilling) || teamMember.role === TeamRole.Owner? {
			[TeamRole.Owner]: _s(StringKey.BILLING_ADMIN),
			[TeamRole.Staff]: _s(StringKey.STAFF),
			[TeamRole.Player]: _s(StringKey.PLAYER),
		}: {
			[TeamRole.Staff]: _s(StringKey.STAFF),
			[TeamRole.Player]: _s(StringKey.PLAYER),
		};
	}

	public onAvatar(dataUrl: string) {
		this.props.onTeamMemberChange('/avatarUrl', dataUrl);
	}

	public onAvatarRemoved() {
		this.props.onTeamMemberChange('/avatarUrl', undefined);
	}

	public onChange(path, e) {
		let newVal;

		if (e.target.name === 'role') {
			if (e.target.checked) {
				newVal = e.target.value;
			} else {
				return;
			}
		} else {
			newVal = (e.type === 'blur') ? valueFilters.clean(e.target.value) : e.target.value;
		}

		this.props.onTeamMemberChange(path, newVal);
	}

	public onRoleChange(e) {
		if (e.target.checked) {
			const { teamMember } = this.props;
			const newVal = Number(e.target.value);
			const oldVal = teamMember.role;

			this.props.onTeamMemberChange('role', newVal);

			if(newVal === TeamRole.Owner) {
				setTimeout(() => {
					viewManager.pushModal({
						component: TransferOwnershipModal,
						props: { classNames: ['prompt'], newOwner: this.props.teamMember, onCancel: () => {
							viewManager.popModal();
							this.props.onTeamMemberChange('role', oldVal);
						} },
						screenName: 'TransferOwnershipFromMemberEdit'
					});
				}, 60);
			}
		}
	}

	public onDeleteClick() {
		const { deleteMembers, teamMember } = this.props;
		const { currentUser } = getCurrentContext();
		const isCurrentUser = teamMember.userId && teamMember.userId === currentUser.id;
		const canDelete = !isCurrentUser && teamMember.role !== TeamRole.Owner;

		if (canDelete && deleteMembers) {
			deleteMembers([teamMember]);
		}
	}

	public async onSendInviteClick() {
		const { sendInvites, teamMember } = this.props;

		if (sendInvites) {
			await sendInvites([teamMember]);
		}
	}

	public render() {
		const { alertList, accessAlert, cancelAction, confirmAction, confirmActionLabel, deleteMembers, teamMember } = this.props;
		const { currentSubscription, currentUser } = getCurrentContext();
		const playerMode = teamMember.role === TeamRole.Player;
		const isCurrentUser = teamMember.userId && teamMember.userId === currentUser.id;
		const accessDisabled = !currentSubscription || !currentSubscription.isActive() || !currentSubscription.supportsFeature(SystemFeature.collaboration);
		const canDelete = !isCurrentUser && teamMember.role !== TeamRole.Owner;
		let inviteButton = null;
		const inputClasses = {
			email: (!!teamMember.email && !teamMember.isValid('email')) ? ['invalid'] : [],
			firstName:  (!!teamMember.firstName && !teamMember.isValid('firstName')) ? ['invalid'] : [],
			lastName:  (!!teamMember.lastName && !teamMember.isValid('lastName')) ? ['invalid'] : [],
			jerseyNumber:  (!!teamMember.jerseyNumber && !teamMember.isValid('jerseyNumber')) ? ['invalid'] : [],
			position:  (!!teamMember.position && !teamMember.isValid('position')) ? ['invalid'] : [],
		};
		const roleControls = map(this.roles, (l, k) => {
			const key = `memberRole${k}`;
			const roleVal = Number(k);
			const checked = teamMember.role === roleVal;
			const rollClasses = checked ? ['on'] : [];

			if (isCurrentUser || teamMember.role === TeamRole.Owner) {
				rollClasses.push('disabled');
			}

			return <label key={ key } className={ rollClasses.join(' ') }><span>{ l }</span><input type="radio" name="role" id={ key } value={ k } onChange={ isCurrentUser ? undefined : this.onRoleChange } checked={ checked } /></label>;
		});

		if (accessDisabled) {
			inviteButton = <a className="button basic locked"><span>{ _s(StringKey.SEND_INVITE) }</span></a>;
		} else if (teamMember.userId || !teamMember.email) {
			inviteButton = <a className="button basic disabled"><span>{ _s(StringKey.SEND_INVITE) }</span></a>;
		} else if (!teamMember.userId && !teamMember.inviteCode) {
			inviteButton = <a className="button basic" onClick={ this.onSendInviteClick }><span>{ _s(StringKey.SEND_INVITE) }</span></a>;
		} else if (!teamMember.userId && teamMember.inviteCode) {
			inviteButton = <a className="button basic" onClick={ this.onSendInviteClick }><span>{ _s(StringKey.RESEND_INVITE) }</span></a>;
		}

		if (isCurrentUser) {
			inputClasses.email.push('disabled');
			inputClasses.firstName.push('disabled');
			inputClasses.lastName.push('disabled');
		}

		return <React.Fragment>
			<header>
				{ cancelAction && <div className="actions"><a className="button" onClick={ cancelAction }><span className="icon cancel"></span></a></div> }
				<div className="title">{ _s(StringKey.TEAM_MEMBER) }</div>
				<div className="actions">
					<a className="button" onClick={ confirmAction }>{ confirmActionLabel ? <span>{ confirmActionLabel }</span> : null }<span className="icon ok"></span></a>
				</div>
			</header>
			{ alertList }
			<div className="alerts" id="alerts">
				{ accessAlert }
			</div>
			<div className="content scrollable">
				<div className="inner compact editTeamMember">
					<input className={ inputClasses.firstName.join(' ') } type="text" placeholder={ _s(StringKey.FIRST_NAME) } value={ isCurrentUser ? currentUser.viewValue('firstName') : teamMember.viewValue('firstName') } onChange={ isCurrentUser ? undefined : this.onFirstNameChange } onBlur={ isCurrentUser ? undefined : this.onFirstNameChange } required />
					<input className={ inputClasses.lastName.join(' ')} type="text" placeholder={ _s(StringKey.LAST_NAME) } value={ isCurrentUser ? currentUser.viewValue('lastName') : teamMember.viewValue('lastName') } onChange={ isCurrentUser ? undefined : this.onLastNameChange } onBlur={ isCurrentUser ? undefined : this.onLastNameChange } />
					<input className={ inputClasses.email.join(' ') } type="email" placeholder={ _s(StringKey.EMAIL_ADDRESS) } value={ isCurrentUser ? currentUser.viewValue('email') : teamMember.viewValue('email') } onChange={ isCurrentUser ? undefined : this.onEmailChange } onBlur={ isCurrentUser ? undefined : this.onEmailChange } required={ teamMember.role === TeamRole.Owner } />
					<div className="radio">
						{ roleControls }
					</div>
					<input className={ inputClasses.position.join(' ') } type="text" placeholder={ playerMode ? _s(StringKey.POSITION) : _s(StringKey.TITLE) } value={ teamMember.viewValue('position') } onChange={ this.onPositionChange } onBlur={ this.onPositionChange } />
					{ playerMode ? <input className={ inputClasses.jerseyNumber.join(' ') } type="text" placeholder={ _s(StringKey.JERSEY_NUMBER) } value={ teamMember.viewValue('jerseyNumber') } onChange={ this.onJerseyNumberChange } onBlur={ this.onJerseyNumberChange } /> : null }
					{ isCurrentUser ? null : <div className="actions">
						{ inviteButton }
						<a className={ canDelete && deleteMembers ? 'button basic delete' : 'button basic delete disabled' } onClick={ this.onDeleteClick }><span>{ _s(StringKey.DELETE) }</span></a>
					</div> }
				</div>
				{ teamMember.avatarUrl && !isCurrentUser ? <div className="inner compact">
					<h2>{ _s(StringKey.PHOTO) }</h2>
					<Avatar canEdit={ isCurrentUser } className="profile" imageWidth={ 256 } imageHeight={ 256 } imageUrl={ isCurrentUser ? currentUser.profile.viewValue('avatarUrl') :  teamMember.viewValue('avatarUrl') } onImage={ this.onAvatar } onRemoveImage={ this.onAvatarRemoved } />
				</div> : null
				}
			</div>
		</React.Fragment>;
	}
}

interface TeamMemberEditModalProps {
	alerts: store.IAlert[];
	accessAlert: any;
	appState: store.IAppState;
	teamMember: ITeamMember;
	modalId: string;
	saveTeamMember: (teamMember: ITeamMember) => Promise<boolean>;
	deleteMembers: (teamMembers: ITeamMember[]) => Promise<boolean>;
	sendInvites: (teamMembers: ITeamMember[]) => Promise<void>;
}

interface TeamMemberEditModalState {
	mutatedTeamMember: ITeamMember;
	processing: boolean;
}

export class TeamMemberEditModal extends React.Component<TeamMemberEditModalProps, TeamMemberEditModalState> {
	constructor(props: TeamMemberEditModalProps) {
		super(props);

		this.state = {
			mutatedTeamMember: teamMemberFactory(props.teamMember),
			processing: false
		};

		this.onTeamMemberChange = this.onTeamMemberChange.bind(this);
		this.handleSave = this.handleSave.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
	}

	public onTeamMemberChange(path, value) {
		const { teamPermissions } = getCurrentContext();

		if (path === 'role') {
			if (Number(value) === TeamRole.Owner && !teamPermissions.canChangeAdmins) {
				return;
			}
		}

		this.setState((prevState) => {
			const newState = assign({}, prevState) as TeamMemberEditModalState;

			if (newState.mutatedTeamMember.role !== TeamRole.Owner || teamPermissions.canChangeAdmins)  {
				// return a new instance so the child component is sure to update
				newState.mutatedTeamMember = teamMemberFactory(newState.mutatedTeamMember);
				newState.mutatedTeamMember.setAt(path, value);
			}

			return newState;
		});
	}

	public async handleSave() {
		const { saveTeamMember, modalId  } = this.props;
		const { mutatedTeamMember } = this.state;

		this.setState({processing: true});
		if (await saveTeamMember(mutatedTeamMember)) {
			this.setState({processing: false}, () => {
				viewManager.popModal(null, modalId);
			});
		}
	}

	public async handleDelete(teamMembers: ITeamMember[]) {
		const { deleteMembers, modalId  } = this.props;

		this.setState({processing: true});
		if (await deleteMembers(teamMembers)) {
			this.setState({processing: false}, () => {
				viewManager.popModal(null, modalId);
			});
		}
	}

	public render() {
		const { accessAlert, alerts, deleteMembers, saveTeamMember, sendInvites, modalId } = this.props;
		const { mutatedTeamMember, processing} = this.state;
		const { currentTeam, currentUser } = getCurrentContext();
		const isNew = !currentTeam.members[mutatedTeamMember.id];
		const alertList = <AlertList alerts={ alerts } />;
		const doSendInvites = async (members) => {
			this.setState({processing: true});
			if (await saveTeamMember(mutatedTeamMember)) {
				await sendInvites(members);
				this.setState({processing: false}, () => {
					viewManager.popModal(null, modalId);
				});
			}
		};

		return <div className="view">
			{ processing? <Spinner delayMs={150}/>: null }
			<TeamMemberEdit
				accessAlert={ accessAlert }
				alertList={ alertList }
				cancelAction={ viewManager.popModal }
				confirmAction={ this.handleSave }
				confirmActionClassName="icon ok"
				deleteMembers={ deleteMembers && mutatedTeamMember.userId !== currentUser.id ? this.handleDelete : undefined }
				isNew={ isNew }
				teamMember={ mutatedTeamMember }
				onTeamMemberChange={ this.onTeamMemberChange }
				sendInvites={ doSendInvites } />
		</div>;
	}

}

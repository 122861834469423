import { forEach, map, sortBy } from 'lodash';
import * as React from 'react';
import { current as getCurrentContext } from '../componentContext';
import { IFormation } from '../models/formation';
import { IPlay } from '../models/play';
import { IPlaybook } from '../models/playbook';
import { SystemFeature } from '../models/subscriptionPlan';
import { ITag } from '../models/tag';
import { _s, StringKey } from '../strings';
import { CachedImage } from './cachedImage';
import { PrintControl, PrintPlay } from './printControl';

interface Props {
	categories: ITag[];
	fieldOptions: any;
	formations: IFormation[];
	optionValues: {[key: string]: any};
	playbook: IPlaybook;
	plays: IPlay[];
}

const SponsorContent = () => {
	const { currentSubscription, sponsorLogoPrintUrl } = getCurrentContext();

	if (!sponsorLogoPrintUrl || !currentSubscription.hasFeature(SystemFeature.print)) {
		return null;
	}

	return <section>
		<div className="sponsor">
			<div className="tribute">{ _s(StringKey.PRINTING_POWERED_BY) }</div>
			<span className="icon photo"><CachedImage src={ sponsorLogoPrintUrl } /></span>
		</div>
	</section>;
};

export class PrintWristbandsList extends React.Component<Props> {
	constructor(props) {
		super(props);
	}

	public render() {
		const { categories, fieldOptions, formations, optionValues, playbook, plays } = this.props;

		return <PrintControl optionValues={ optionValues } playbook={ playbook }>{ (params) => {
			const { className, buildPlayLists } = params;
			const classNames = [className, 'wristbands'];
			const copies = Number(optionValues.copies.key);
			const groupSize = optionValues.layout.groupSize;
			const playColorCoding = optionValues.playColorCoding?.key;
			// const type = optionValues.layout.type;
			const { categoryList, formationList } = buildPlayLists(categories, formations, plays);
			const sections = [];
			let playCount = 0;

			const items = [];

			if (categoryList.length) {
				let autoColor = 0;

				forEach(sortBy(categoryList, 'sortIndex'), (c) => {
					const color = playColorCoding === 'auto'? autoColor: c.color || 0;

					if (categoryList.length > 1) {
						items.push({ categoryName: c.label, className: `category color${color}` });
					}

					forEach(c.plays, (p) => items.push({ className: `printPlay color${color}`, play: p }));

					if (categoryList.length > 1) {
						autoColor = autoColor === 8 ? 0 : autoColor + 1;
					}
				});
			} else if (formationList.length) {
				let autoColor = 0;

				forEach(sortBy(formationList, 'sortIndex'), (f) => {
					const color = playColorCoding === 'auto'? autoColor: f.color || 0;

					forEach(f.plays, (p) => items.push({ className: `printPlay color${color}`, play: p }));

					if (formationList.length > 1) {
						autoColor = autoColor === 8 ? 0 : autoColor + 1;
					}
				});
			}

			const sectionCount = Math.ceil(items.length / groupSize);

			for (let i = 0; i < copies; i ++) {
				playCount = 0;
				for (let j = 0; j < sectionCount; j ++) {
					const sectionItems = items.slice(j * groupSize, (j + 1) * groupSize);
					const playContent = map(sectionItems, (item) => {
						if (item.categoryName) {
							return <div key={ item.categoryName }className={ item.className }><div className="info"><span>{ item.categoryName }</span></div></div>;
						} else {
							const play = item.play as IPlay;

							return  <PrintPlay className={ item.className } key={ playCount } play={ play } playCount={ playCount++ } fieldOptions={ fieldOptions} />;
						}

					});

					sections.push(<section key={ `${i}-${j}` }>{ playContent }</section>);
				}
			}

			// console.log(`groupSize ${groupSize}`);
			classNames.push(map(optionValues, (v) => v.className).join(' '));

			return <div className={ classNames.join(' ') }>
				<div className="printPage">
					<SponsorContent />
					{ sections }
				</div>
			</div>;
		}}</PrintControl>;

	}
}

import { map } from 'lodash';
import * as React from 'react';
import * as systemActions from '../actions';
import { AlertSeverity, IAlert } from '../store';

function Alert({ id, severity, message, preventDismiss, action }: {id: string, severity: AlertSeverity, message: string, preventDismiss?: boolean, action: any }) {
	return <div className={ `alert ${AlertSeverity[severity]}`}>
		<span className={ `icon ${AlertSeverity[severity]}`}></span><span onClick={ action ? (e) => action() : null } dangerouslySetInnerHTML={ { __html: message } }></span>
		{ !preventDismiss ? <a className="button" onClick={ () => systemActions.deleteAlert(id) }><span className="icon dismiss"></span></a> : null }
	</div>;
}

function AlertList({ alerts = [], preventDismiss = false }: { alerts?: IAlert[], preventDismiss?: boolean }) {
	let alertContainer = null;

	if (alerts.length) {
		const alertItems = map(alerts, (alert: IAlert) => <Alert key={ alert.id } id={ alert.id } message={ alert.message } severity={ alert.severity } preventDismiss={ preventDismiss } action={ alert.actions && alert.actions.length ? alert.actions[0].action : null } />);

		alertContainer = <div className="alerts">{ alertItems }</div>;
	}

	return alertContainer;
}

export { Alert, AlertList };

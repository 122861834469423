import { filter, first, forEach, map, sortBy } from 'lodash';
import * as React from 'react';
import { current as getCurrentContext } from '../componentContext';
import { ISortable } from '../models/sortableModel';
import { GamePhase } from '../models/diagramModel';
import { IAppState } from '../store';
import { IFormation } from '../models/formation';
import { ITag } from '../models/tag';
import { getUncategorizedCategory } from './categoryButtonList';
import { IPlay } from '../models/play';

interface Props {
	appState: IAppState;
    children: any
}

export enum PlayFilterMode {
	formations = 'formations',
	categories = 'categories'
}

interface PlayFilterState {
	playbookId?: string;
	phase: GamePhase;
    mode: PlayFilterMode;
    phaseFormation: Record<GamePhase, string>;
	phaseCategory: Record<GamePhase, string>;
	playlistId?: string;
    selectedItems: string[];
}

interface PlayFilterContextState extends Omit<PlayFilterState, 'phaseFormation' | 'phaseCategory' | 'playbook'> {
	activeCategory?: ITag,
	activeFormation?: IFormation,
    setMode(mode: string): void;
	setPhase(phase: GamePhase): void;
	setActiveCategory(category: ITag): void;
	setActiveFormation(formation: IFormation): void;
	setSelectedItems(items: string[]): void;
    getPlays(): (IPlay & ISortable)[];
	reset(): void;
}

const NO_OP = () => undefined;
const NULL_CONTEXT = {
	phase: GamePhase.Offense,
	mode: PlayFilterMode.categories,
	selectedItems: [],
	setMode: NO_OP,
	setPhase: NO_OP,
	setActiveCategory: NO_OP,
	setActiveFormation: NO_OP,
	setSelectedItems: NO_OP,
	getPlays: NO_OP,
	reset: NO_OP
};
export const PlayFilterContext = React.createContext<PlayFilterContextState>(NULL_CONTEXT);

function initFilterState(): PlayFilterState {
	const { currentPlaybook, variant, model } = getCurrentContext();
	const uncategorizedCategory = getUncategorizedCategory();
	const firstOffenseFormation = currentPlaybook && first(sortBy(filter(model.formations, { playbookId: currentPlaybook.id, phase: GamePhase.Offense }), 'sortIndex'));
	const firstDefenseFormation = currentPlaybook && first(sortBy(filter(model.formations, { playbookId: currentPlaybook.id, phase: GamePhase.Defense }), 'sortIndex'));
	const firstSpecialTeamsFormation = currentPlaybook && first(sortBy(filter(model.formations, { playbookId: currentPlaybook.id, phase: GamePhase.SpecialTeams }), 'sortIndex'));
	const mode = variant !== 'flag' ? PlayFilterMode.formations : PlayFilterMode.categories;
	const phase = GamePhase.Offense;
	const phaseFormation: any = {};
	let playlistId = '';

	phaseFormation[GamePhase.Offense] = (firstOffenseFormation && firstOffenseFormation.id) || '';
	phaseFormation[GamePhase.Defense] = (firstDefenseFormation && firstDefenseFormation.id) || '';
	phaseFormation[GamePhase.SpecialTeams] = (firstSpecialTeamsFormation && firstSpecialTeamsFormation.id) || '';

	const phaseCategory: any = {};

	if (variant === 'tackle') {
		const firstOffenseCategory = currentPlaybook && first(sortBy(filter(currentPlaybook.settings.categories.values, { phase: GamePhase.Offense}), 'sortIndex'));
		const firstDefenseCategory = currentPlaybook && first(sortBy(filter(currentPlaybook.settings.categories.values, { phase: GamePhase.Defense}), 'sortIndex'));
		const firstSpecialTeamsCategory = currentPlaybook && first(sortBy(filter(currentPlaybook.settings.categories.values, { phase: GamePhase.SpecialTeams}), 'sortIndex'));

		phaseCategory[GamePhase.Offense] = (firstOffenseCategory && firstOffenseCategory.id) || '';
		phaseCategory[GamePhase.Defense] = (firstDefenseCategory && firstDefenseCategory.id) || '';
		phaseCategory[GamePhase.SpecialTeams] = (firstSpecialTeamsCategory && firstSpecialTeamsCategory.id) || '';
	} else {
		phaseCategory[GamePhase.Offense] = '';
		phaseCategory[GamePhase.Defense] = '';
		phaseCategory[GamePhase.SpecialTeams] = '';
	}

	if (mode === PlayFilterMode.formations) {
		playlistId = phaseFormation[phase];
	} else if (phaseCategory[phase] && phaseCategory[phase] !== uncategorizedCategory?.id) {
		playlistId = phaseCategory[phase];
	}

	return {
		mode,
		phase,
		phaseCategory, 
		phaseFormation,
		playlistId,
		selectedItems: []
	}
}

interface PlayFilterAction {
	type: 'reset' | 'setActiveCategory' | 'setActiveFormation' | 'setMode' | 'setPhase' | 'setSelectedItems',
	data?: any
}
function playFilterReducer(state: PlayFilterState, action: PlayFilterAction) {
	let result = state;

	switch(action.type) {
	case 'reset':
		result = initFilterState();
		break;
	case 'setActiveCategory':
		if(action.data?.id !== state.phaseCategory[state.phase]) {
			const phaseCategory = { ...state.phaseCategory };
			const uncategorizedCategory = getUncategorizedCategory();
			let playlistId = state.playlistId;

			phaseCategory[state.phase] = action.data?.id || '';

			if (state.mode === PlayFilterMode.formations) {
				playlistId = state.phaseFormation[state.phase];
			} else if (phaseCategory[state.phase] && phaseCategory[state.phase] !== uncategorizedCategory?.id) {
				playlistId = phaseCategory[state.phase];
			}

			result = { ...state, playlistId, phaseCategory, selectedItems: [] };
		}
		break;
	case 'setActiveFormation':
		if(action.data?.id !== state.phaseFormation[state.phase]) {
			const phaseFormation = { ...state.phaseFormation };
			const uncategorizedCategory = getUncategorizedCategory();
			let playlistId = state.playlistId;

			phaseFormation[state.phase] = action.data?.id || '';

			if (state.mode === PlayFilterMode.formations) {
				playlistId = phaseFormation[state.phase];
			} else if (state.phaseCategory[state.phase] && state.phaseCategory[state.phase] !== uncategorizedCategory?.id) {
				playlistId = state.phaseCategory[state.phase];
			}

			result = { ...state, playlistId, phaseFormation, selectedItems: [] };
		}
		break;
	case 'setSelectedItems':
		return { ...state, selectedItems: action.data };
	case 'setMode':
		if(action.data !== state.mode) {
			const uncategorizedCategory = getUncategorizedCategory();
			let playlistId = state.playlistId;

			if (action.data === PlayFilterMode.formations) {
				playlistId = state.phaseFormation[state.phase];
			} else if (state.phaseCategory[state.phase] && state.phaseCategory[state.phase] !== uncategorizedCategory?.id) {
				playlistId = state.phaseCategory[state.phase];
			}

			result = { ...state, playlistId, mode: action.data, selectedItems: [] };
		}
		break;
	case 'setPhase':
		if(action.data !== state.phase) {
			const phase = action.data;
			const uncategorizedCategory = getUncategorizedCategory();
			let playlistId = state.playlistId;

			if (state.mode === PlayFilterMode.formations) {
				playlistId = state.phaseFormation[phase];
			} else if (state.phaseCategory[phase] && state.phaseCategory[phase] !== uncategorizedCategory?.id) {
				playlistId = state.phaseCategory[phase];
			}
			
			result =  { ...state, phase, playlistId, selectedItems: [] };
		}
		break;
	}

	return result;
}

export function PlayFilterProvider({ appState, children }: Props) {
	const [state, dispatch] = React.useReducer<typeof playFilterReducer, PlayFilterState>(playFilterReducer, undefined, () => initFilterState());
	const { currentPlaybook } = getCurrentContext();
	const currentPlaybookId = currentPlaybook?.id;
	const { model } = appState;
	const contextState = React.useMemo(() => {
		const activeFormation = model.formations[state.phaseFormation[state.phase]];
		const activeCategoryId = state.phaseCategory[state.phase];
		const activeCategory = activeCategoryId === getUncategorizedCategory().id? getUncategorizedCategory(): currentPlaybook?.settings.categories[state.phaseCategory[state.phase]];

		return {
			...state,
			activeCategory,
			activeFormation,
			phaseFormation: undefined,
			phaseCategory: undefined,
			setMode(mode: string){
				dispatch({ type: 'setMode', data: mode });
			},
			setPhase(phase: GamePhase) {
				dispatch({ type: 'setPhase', data: phase });
			},
			setActiveCategory(category: ITag) {
				dispatch({ type: 'setActiveCategory', data: category });
			},
			setActiveFormation(formation: IFormation) {
				dispatch({ type: 'setActiveFormation', data: formation });
			},
			setSelectedItems(items: string[]) {
				dispatch({ type: 'setSelectedItems', data: items });
			},
			reset() {
				dispatch({ type: 'reset' })
			},
			getPlays() {
				const { model: { formations, plays }, variant, currentPlaybook } = getCurrentContext();
				const phasePlays = filter(plays, { playbookId: currentPlaybook?.id, phase: state.phase });
				const playList = currentPlaybook?.getPlayList(state.playlistId, true);
				let filteredPlays = phasePlays;
	
				if (state.mode === 'formations') {
					const activeFormation = formations[state.phaseFormation[state.phase]];
	
					filteredPlays = activeFormation ? filter(phasePlays, (p) => p.formation && activeFormation.label && p.formation.toLowerCase() === activeFormation.label.toLowerCase()) : [];
				} else if (state.mode === 'categories') {
					const uncategorizedCategory = getUncategorizedCategory();
					let filterCategory = currentPlaybook?.settings.categories[state.phaseCategory[state.phase]];
	
					if (variant === 'tackle') {
						filterCategory = filterCategory || uncategorizedCategory;
					}
	
					if (filterCategory) {
						filteredPlays =  filter(phasePlays, (p: IPlay) => p.categoryList.indexOf(filterCategory.id) !== -1 || (!p.categoryList.length && filterCategory.id === uncategorizedCategory.id));
					}
				}
	
				if (playList) {
					const ids = playList.playIdList;
					let result = [];
					const missing = [];
		
					forEach(filteredPlays, (play) => {
						const index = ids.indexOf(play.id);
						if (index !== -1) {
							result.push({ index, play });
						} else {
							missing.push(play);
						}
					});
		
					result = map(sortBy(result, 'index'), (r) => r.play);
		
					return result.concat(sortBy(missing, 'sortIndex'));
				} else {
					return sortBy(filteredPlays, 'sortIndex');
				}
			},
		};

	}, [state, model, currentPlaybook]);

	React.useEffect(() => {
		dispatch({ type: 'reset'});
	}, [currentPlaybookId])

	return <PlayFilterContext.Provider value={ contextState }>
		{ children }
	</PlayFilterContext.Provider>
}
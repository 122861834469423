import { assign, forEach, pickBy } from 'lodash';
import { createId } from 'playmaker-team-common/dist/shared/createId';
import { Emitter } from 'playmaker-team-common/dist/shared/emitter';
import { IFetchBatch, ISaveBatch, ISaveBatchResult, ISocketMessage, StringDictionary } from 'playmaker-team-common/dist/shared/interfaces';
import * as clientStorage from './clientStorage';
import * as socketClient from './socketClient';
import { ITeamMember } from './models/teamMember';

type IntentEntity = 'category' | 'formation' | 'member' | 'personnel' | 'playbook' | 'play' | 'routetree' | 'subscription' | 'team' | 'user';
type IntentAction = 'assign' | 'create' | 'update' | 'delete' | 'upgrade' | 'import' | 'invite' | 'apply' | 'copy' | 'sort' | 'upsize';

export type SaveBatchIntent = `${IntentEntity}-${IntentAction}${string}`;
export interface IAppSaveBatch extends ISaveBatch {
	intent: SaveBatchIntent
}

const _uniqueClientId = createId(); // identifies this client as unique accross multiple logins per user
const SEND_TIMEOUT = (1000 * 60 * 5);
let _onlineState = false;
let _fetchRoot = `${location.protocol}//${location.host.replace('3001', '3002')}`; // replace is for dev

// socket RSVP timeout
setInterval(_timeoutCallbacks, 1000 * 60);
let _pingMaxMs = 40 * 1000;
let _pingMinMs = 20 * 1000;
// ping timer when there is no web socket connection
let _pingTimeout;
const _socketReponseCallback = {};
let _socketStatePromise: Promise<void>;
let _resolveSocketState: () => void;

// emitter handles channel subscriptions
const _emitter = new Emitter();
let _metadata: Record<string, any> = {};

export function init({ appVersion, env }: { appVersion: string, env: 'development' | 'production'}) {
	_metadata.appVersion = appVersion;
	_metadata.env = env;

	if (env !== 'development') {
		_fetchRoot = `${_fetchRoot}/api`;
	}

	_ping({ initialized: true, socketConnected: false });
}

export function updateMetadata(metadata: Record<string, any>) {
	_metadata = Object.assign(_metadata, metadata);
}

export async function connect() {
	// We're trying to work as an collaborative customer, connect more quickly if you can
	_pingMaxMs = 15 * 1000;
	_pingMinMs = 5 * 1000;

	// if someone else is trying to manage socket state - let them continue on their way
	if (_resolveSocketState) {
		_resolveSocketState();
	}

	if (socketClient.isConnected()) {
		return Promise.resolve();
	} else if (!_socketStatePromise) {
		_socketStatePromise = new Promise((resolve) => {
			_resolveSocketState = () => {
				_resolveSocketState = null;
				_socketStatePromise = null;

				resolve();
			};
		});
		const protocol = (location as any).protocol === 'https:' ? 'wss:' : 'ws:';
		const host = location.host.replace('3001', '3002'); // replace is for dev
		const path = _metadata.env === 'development' ? '/' : '/api/';
		socketClient.connect(`${protocol}//${host}${path}`, 'v1', _uniqueClientId, 0); // don't auto-reconnect
	}

	return _socketStatePromise;
}

export async function disconnect() {
	// We're trying to work as a non-collaborative customer, connect less quickly
	_pingMaxMs = 120 * 1000;
	_pingMinMs = 60 * 1000;

	// if someone else is trying to manage socket state - let them continue on their way
	if (_resolveSocketState) {
		_resolveSocketState();
	}

	if (!socketClient.isConnected()) {
		await _onDisconnected(-1, 'Already disconnected');
		return Promise.resolve();
	} else if (!_socketStatePromise) {
		_socketStatePromise = new Promise((resolve) => {
			_resolveSocketState = () => {
				_resolveSocketState = null;
				_socketStatePromise = null;

				resolve();
			};
		});
		socketClient.disconnect();
	}

	return _socketStatePromise;
}

export async function login(email: string, password: string, inviteCode?: string) {
	const fullMessage = await _buildFullMessage({type: 'fetch.token', payload: { email, password, inviteCode }, rsvp: true });

	if (socketClient.isConnected()) {
		return await _sendSocketMessage(fullMessage, true, true);
	} else {
		return await _doFetch(`${_fetchRoot}/v1/account/token`, fullMessage, true, true);
	}
}

export async function logOut() {
	const fullMessage = await _buildFullMessage({ type: 'logout', payload: null, rsvp: true });

	if (socketClient.isConnected()) {
		return await _sendSocketMessage(fullMessage, false);
	} else {
		return await _doFetch(`${_fetchRoot}/v1/account/logout`, fullMessage, false);
	}
}

export async function fetchUser(id?: string) {
	const fullMessage = await _buildFullMessage({ type: 'fetch.user', payload: null, params: { id }, rsvp: true } );

	if (socketClient.isConnected()) {
		return await _sendSocketMessage(fullMessage);
	} else {
		return await _doFetch(`${_fetchRoot}/v1/user/${id}`, fullMessage);
	}
}

export async function updatePassword(oldPassword: string, newPassword: string) {
	const fullMessage = await _buildFullMessage({ type: 'password.update', payload: { oldPassword, newPassword }, rsvp: true });

	if (socketClient.isConnected()) {
		return await _sendSocketMessage(fullMessage);
	} else {
		return await _doFetch(`${_fetchRoot}/v1/user/updatepassword`, fullMessage);
	}
}

export async function requestPasswordReset(email: string) {
	const fullMessage = await _buildFullMessage({ type: 'password.reset', payload: { email }, rsvp: true });

	if (socketClient.isConnected()) {
		return await _sendSocketMessage(fullMessage, false);
	} else {
		return await _doFetch(`${_fetchRoot}/v1/user/resetpassword`, fullMessage, false);
	}
}

export async function fetchTeams() {
	const fullMessage = await _buildFullMessage({ type: 'fetch.teams', payload: null, rsvp: true });

	return await _doFetch(`${_fetchRoot}/v1/teams`, fullMessage);
}

export async function fetchTeam(teamId: string) {
	const fullMessage = await _buildFullMessage({ type: 'fetch.team', payload: null, params: { teamId }, rsvp: true });

	if (socketClient.isConnected()) {
		return await _sendSocketMessage(fullMessage);
	} else {
		return await _doFetch(`${_fetchRoot}/v1/team/${teamId}`, fullMessage);
	}
}

export async function fetchPlays(playbookIds: string[]) {
	const fullMessage = await _buildFullMessage({ type: 'fetch.plays', payload: null, params: { playbookIds }, rsvp: true });

	return await _doFetch(`${_fetchRoot}/v1/plays/${playbookIds.join(',')}`, fullMessage);
}

export async function fetchPlay(playId: string, playbookId: string) {
	const key = [playbookId, playId].join(',');
	const fullMessage = await _buildFullMessage({ type: 'fetch.play', payload: null, params: { playId: key }, rsvp: true });

	if (socketClient.isConnected()) {
		return await _sendSocketMessage(fullMessage);
	} else {
		return await _doFetch(`${_fetchRoot}/v1/play/${key}`, fullMessage);
	}
}

export async function fetchFormations(playbookIds: string[]) {
	const fullMessage = await _buildFullMessage({ type: 'fetch.formations', payload: null, params: { playbookIds }, rsvp: true });

	return await _doFetch(`${_fetchRoot}/v1/formations/${playbookIds.join(',')}`, fullMessage);
}

export async function fetchFormation(formationId: string, playbookId: string) {
	const key = [playbookId, formationId].join(',');
	const fullMessage = await _buildFullMessage({ type: 'fetch.formation', payload: null, params: { formationId: key }, rsvp: true });

	if (socketClient.isConnected()) {
		return await _sendSocketMessage(fullMessage);
	} else {
		return await _doFetch(`${_fetchRoot}/v1/formation/${key}`, fullMessage);
	}
}

export async function fetchConfig() {
	const fullMessage = await _buildFullMessage({ type: 'fetch.config', payload: null, params: { }, rsvp: true });

	if (socketClient.isConnected()) {
		return await _sendSocketMessage(fullMessage, false);
	} else {
		return await _doFetch(`${_fetchRoot}/v1/system/config`, fullMessage, false);
	}
}

export async function fetchPlans() {
	const fullMessage = await _buildFullMessage({ type: 'fetch.activeSubscriptionPlans', payload: null, rsvp: true });

	return await _doFetch(`${_fetchRoot}/v1/subscriptionplans/active`, fullMessage);
}

export async function fetchPlan(code: string) {
	const fullMessage = await _buildFullMessage({ type: 'fetch.subscriptionPlan', payload: null, params: { code }, rsvp: true });

	if (socketClient.isConnected()) {
		return await _sendSocketMessage(fullMessage);
	} else {
		return await _doFetch(`${_fetchRoot}/v1/subscriptionplan/${code}`, fullMessage);
	}
}

export async function fetchInvite(code: string) {
	const fullMessage = await _buildFullMessage({ type: 'fetch.invite', payload: null, params: { code }, rsvp: true});

	if (socketClient.isConnected()) {
		return await _sendSocketMessage(fullMessage, true);
	} else {
		return await _doFetch(`${_fetchRoot}/v1/invite/${code}`, fullMessage, true);
	}
}

export async function fetch(fetchBatch: IFetchBatch) {
	const fullMessage = await _buildFullMessage({ type: 'fetch', payload: { fetchBatch }, rsvp: true });

	return await _doFetch(`${_fetchRoot}/v1/fetch`, fullMessage);
}

/** BEGIN SUPPORT */

export async function _supportAccountSummary(email: string) {
	const fullMessage = await _buildFullMessage({ type: 'fetch-account-summary', payload: undefined, rsvp: true });

	return await _doFetch(`${_fetchRoot}/v1/support/account-summary/${email}`, fullMessage);
}

export async function _supportPurgeAccount(userId: string) {
	const fullMessage = await _buildFullMessage({ type: 'purge-account', payload: undefined, rsvp: true });

	return await _doFetch(`${_fetchRoot}/v1/support/purge-account/${userId}`, fullMessage);
}

export async function _supportExpireSubscription(email: string, subscriptionId: string) {
	const fullMessage = await _buildFullMessage({ type: 'expire-subscription', payload: undefined, rsvp: true });

	return await _doFetch(`${_fetchRoot}/v1/support/expire-subscription/${email}/${subscriptionId}`, fullMessage);
}

export async function _supportRemoveTeamMember(supportUserId: string, teamId: string, teamMemberId: string) {
	const fullMessage = await _buildFullMessage({ type: 'remove-member', payload: undefined, rsvp: true });

	return await _doFetch(`${_fetchRoot}/v1/support/remove-member/${supportUserId}/${teamId}/${teamMemberId}`, fullMessage);
}

export async function _supportPauseSubscription(email: string, subscriptionId: string) {
	const fullMessage = await _buildFullMessage({ type: 'pause-subscription', payload: undefined, rsvp: true });

	return await _doFetch(`${_fetchRoot}/v1/support/pause-subscription/${email}/${subscriptionId}`, fullMessage);
}

export async function _supportUnPauseSubscription(email: string, subscriptionId: string) {
	const fullMessage = await _buildFullMessage({ type: 'unpause-subscription', payload: undefined, rsvp: true });

	return await _doFetch(`${_fetchRoot}/v1/support/unpause-subscription/${email}/${subscriptionId}`, fullMessage);
}

export async function _supportTransferOwnership(teamId: string, newOwner: ITeamMember) {
	const fullMessage = await _buildFullMessage({ type: 'transfer-ownership', payload: { newOwner }, rsvp: true });

	return await _doFetch(`${_fetchRoot}/v1/team/transfer-ownership/${teamId}`, fullMessage);
}

/** END SUPPORT */

export async function redeemItem(code: string, data?: Record<string, any>) {
	const fullMessage = await _buildFullMessage({ type: 'redeem', payload: assign({}, data), params: { code }, rsvp: true });

	if (socketClient.isConnected()) {
		return await _sendSocketMessage(fullMessage, true);
	} else {
		return await _doFetch(`${_fetchRoot}/v1/redeem/${code}`, fullMessage, true);
	}
}

export async function save(saveBatch: IAppSaveBatch): Promise<ISaveBatchResult> {
	const fullMessage = await _buildFullMessage({ type: 'save', payload: { saveBatch }, rsvp: true });

	if (socketClient.isConnected()) {
		return await _sendSocketMessage(fullMessage) as any as ISaveBatchResult;
	} else {
		return await _doFetch(`${_fetchRoot}/v1/save`, fullMessage) as any as ISaveBatchResult;
	}
}

export async function undelete(playbookId: string) {
	const fullMessage = await _buildFullMessage({ type: 'undelete.playbook', payload: null, rsvp: true });

	return await _doFetch(`${_fetchRoot}/v1/support/undelete-playbook/${playbookId}`, fullMessage);
}

export async function archivePlaybook(playbookId: string) {
	const fullMessage = await _buildFullMessage({ type: 'playbook.archive', payload: null, rsvp: true });

	return await _doFetch(`${_fetchRoot}/v1/playbook/${playbookId}/archive`, fullMessage);
}

export async function unarchivePlaybook(playbookId: string) {
	const fullMessage = await _buildFullMessage({ type: 'playbook.archive', payload: null, rsvp: true });

	return await _doFetch(`${_fetchRoot}/v1/playbook/${playbookId}/unarchive`, fullMessage);
}

export function on(message: string, handler: (data: Record<string, any>) => void): void {
	_emitter.on(message, handler);
}

export function off(handler: (data: Record<string, any>) => void): void {
	_emitter.off(handler);
}

window.addEventListener('offline', async () => {
	_broadcastNetworkStatus();
});

window.addEventListener('online', async () => {
	_broadcastNetworkStatus();
});

socketClient.on('connected', async () => {
	await _sendStatus({ socketConnected: true, canAccessApi: true, isOnline: true });

	if (_resolveSocketState) {
		_resolveSocketState();
	}

	_sendSocketMessage({ type: 'team.subscribe', payload: {} });
});

socketClient.on('disconnected', async (e: { code: number, reason: string}) => {
	await _onDisconnected(e.code, e.reason);
});

socketClient.on('error', (e) => {
	// TODO: do we something with this?
	console.log('webSocket error', e.data);
});

socketClient.on('message', async (message: ISocketMessage) => {
	const statusPayload: Record<string, any> = { canAccessApi: true, isOnline: true, socketConnected: true };

	if (message.responseTo) {
		const callback = _socketReponseCallback[message.responseTo];
		if (callback) {
			callback.exec(message);
		}
	} else {

		if (message.type === 'pong') {
			statusPayload.pingMs = message.payload.delayMs;
			statusPayload.currentVersion = message.payload.version;
		} else {
			_emitter.trigger(message.type, message.payload);
		}

	}
	_sendStatus(statusPayload);
});

async function _buildFullMessage(message: ISocketMessage): Promise<ISocketMessage> {
	const currentTeamId = await clientStorage.getItem(clientStorage.CURRENT_TEAM_ID_STORAGE_KEY);

	return assign({ id: createId(), metadata: { appVersion: _metadata?.appVersion, clientPlatform: _metadata?.platform, currentTeamId}}, message);
}

async function _getDefaultHeaders(authorize: boolean) {
	const headers: StringDictionary = {
		'Content-Type': 'application/json',
		'pmt-cid': _uniqueClientId
	};

	if (authorize) {
		const authToken = await clientStorage.getItem(clientStorage.AUTH_TOKEN_STORAGE_KEY);

		if (authToken) {
			headers.Authorization = `Bearer ${authToken}`;

		}
	}

	return headers;
}

async function _doFetch(url: string, message: ISocketMessage, authorize = true, loggingIn = false): Promise<Record<string, any>> {
	const headers = await _getDefaultHeaders(authorize);
	let responsePayload: Record<string, any> | undefined;

	if (message.metadata) {
		if (message.metadata.appVersion) {
			headers['pmt-appVersion'] = message.metadata.appVersion;
		}
		if (message.metadata.apiVersion) {
			headers['pmt-apiVersion'] = message.metadata.apiVersion;
		}
		if (message.metadata.clientPlatform) {
			headers['pmt-clientPlatform'] = message.metadata.clientPlatform;
		}
		if (message.metadata.currentTeamId) {
			headers['pmt-team-id'] = message.metadata.currentTeamId;
		}
	}

	try {
		const response = await fetchWithTimeout(url, {
			method: message.payload ? 'POST' : 'GET',
			body: message.payload && JSON.stringify(message.payload),
			headers,
			timeout: 90000,
		});

		if (response.ok) {
			responsePayload = await response.json();

			responsePayload = message.rsvp ? responsePayload : undefined;
			await _broadcastNetworkStatus({ canAccessApi: true });
		} else if (response.status === 404) {
			responsePayload = { error: { message: 'Not Found', source: 'server', type: 'UNKNOWN', status: response.status, sourceMessage: response.statusText } };
			await _broadcastNetworkStatus({ canAccessApi: true });
		} else {
			try {
				let errorPayload = await response.json();
				errorPayload = assign({ error: {} }, errorPayload);

				errorPayload.error.status = response.status;

				responsePayload = errorPayload;

			} catch (err) {
				responsePayload = { error: { message: 'Server Error', source: 'server', type: 'UNKNOWN', sourceMessage: message && message.id} };
			}
		}
	} catch (err) {
		responsePayload = { error: { message: err.message, source: 'client', type: 'SEND_FAILURE', sourceMessage: message && message.id} };
	}

	if (responsePayload) {
		if (authorize && !loggingIn) {
			if (responsePayload.error && (responsePayload.error as any).status === 401) {
				_broadcastNetworkStatus({ authFailure: (responsePayload.error as any).status });
			} else {
				_broadcastNetworkStatus({ authFailure: null });
			}
		}

		return responsePayload;
	}
}

async function _sendSocketMessage(message: ISocketMessage, authorize = true, loggingIn = false): Promise<Record<string, any>>  {
	message.authToken = authorize ? await clientStorage.getItem(clientStorage.AUTH_TOKEN_STORAGE_KEY) as string : undefined;
	message.payload = message.payload || {}; // default to empty object

	return new Promise((resolve) => {
		if (message.rsvp) {
			_socketReponseCallback[message.id] = { timestamp: Date.now(), exec: (responseMessage: ISocketMessage) => {
				delete _socketReponseCallback[message.id];

				if (authorize && !loggingIn) {
					if (responseMessage.payload.error && responseMessage.payload.error.status === 401) {
						_broadcastNetworkStatus({ authFailure: responseMessage.payload.error.status });
					} else {
						_broadcastNetworkStatus({ authFailure: null });
					}
				}

				resolve(responseMessage.payload);
			}};
		}

		try {
			socketClient.send(message);
		} catch (err) {
			if (message.rsvp) {
				delete _socketReponseCallback[message.id];
			}

			resolve({ error: { message: err.message, source: 'client', type: 'SEND_FAILURE', sourceMessage: message && message.id} });
		}
	});
}

function _onDisconnected(code: number, reason: string) {
	_disconnectCallbacks();

	_broadcastNetworkStatus({ socketConnected: false, code, reason });

	if (_resolveSocketState) {
		_resolveSocketState();
	}
}

function _disconnectCallbacks() {
	forEach(_socketReponseCallback, (callback: any) => {
		callback.exec({ type: 'error', payload: { error: { message: 'Connection Closed', source: 'client', type: 'CONNECTION_CLOSED' }}});
	});
}

function _timeoutCallbacks() {
	const cutoff = Date.now() - SEND_TIMEOUT;

	forEach(_socketReponseCallback, (callback: any) => {
		if (callback.timestamp < cutoff) {
			callback.exec({ type: 'error', payload: { error: { message: 'Send Timeout', source: 'client', type: 'SEND_TIMEOUT' }}});
		}
	});
}

async function _ping(additionalData = {}, timeout = 5000) {
	clearTimeout(_pingTimeout);

	const isOnline = typeof navigator !== 'undefined' && navigator.onLine;
	const ms = Math.floor(Math.random() * (_pingMaxMs - _pingMinMs + 1) + _pingMinMs);
	let canAccessApi = false;
	let pingMs;
	let currentVersion;

	if (!socketClient.isConnected()) {
		if (isOnline) {
			try {
				const startMs = Date.now();
				const response = await fetchWithTimeout(`${_fetchRoot}/v1/system/ping`, { timeout });
				const { version } = await response.json();

				currentVersion = version;

				pingMs = Date.now() - startMs;
				canAccessApi = true;
			} catch (err) {
				// just swallow
			}
		}
		_broadcastNetworkStatus(assign({ canAccessApi, pingMs, currentVersion }, additionalData));
	}

	_pingTimeout = setTimeout(_ping, ms);
}

async function fetchWithTimeout(resource, options: StringDictionary = {}) {
	const { timeout = 8000 } = options;

	const controller = new AbortController();
	const id = setTimeout(() => controller.abort(), timeout);

	const response = await window.fetch(resource, {...options, signal: controller.signal});

	clearTimeout(id);

	return response;
}

async function _broadcastNetworkStatus(additionalData = {}) {
	const hasNavigator = typeof navigator !== 'undefined';
	const isOnline =  hasNavigator && navigator.onLine;

	if (_onlineState !== isOnline) {
		_onlineState = isOnline;
		_ping(additionalData);
	} else {
		_sendStatus(assign({ isOnline }, additionalData));
	}
}

async function _sendStatus({ authFailure, canAccessApi, code, socketConnected, initialized, isOnline, pingMs, reason, currentVersion }: { authFailure?: any, canAccessApi?: boolean, code?: number, socketConnected?: boolean, initialized?: boolean, isOnline?: boolean, pingMs?: number, reason?: string, currentVersion?: string }) {
	const data = pickBy({ authFailure, canAccessApi, code, currentVersion, socketConnected, initialized, isOnline, pingMs, reason }, (val) => {
		return !(typeof val === 'undefined');
	});

	_emitter.trigger('api.status', data);
}

import { filter, keys, map } from 'lodash';
import { IPatchOperation, PatchOpType } from 'playmaker-team-common/dist/shared/interfaces';
import { default as valueFilters } from 'playmaker-team-common/dist/shared/valueFilters';
import * as React from 'react';
import * as actions from '../actions';
import { AnnotationType, default as annotationFactory, IAnnotation } from '../models/annotation';
import { IPoint, vectorUtil } from '../models/vector';
import * as touchHelper from '../touchHelper';

interface Props {
	modalId: string;
	offset: IPoint;
	diagramItem: IAnnotation;
	updateItem: (patch: IPatchOperation[]) => void;
	deleteItem: (annotation: IAnnotation) => void;
	onClose: (e?) => void;
}

interface State {
	factory: (doc?: any) => IAnnotation;
}

export class AnnotationToolbarBall extends React.Component<Props, State> {
	private _trackedTouch: any;
	private _dragTarget: any;

	constructor(props: Props) {
		super(props);

		this.state = {
			factory: annotationFactory,
		};

		this.handleDragEnd = this.handleDragEnd.bind(this);
		this.handleDragMove = this.handleDragMove.bind(this);
		this.handleDragStart = this.handleDragStart.bind(this);

		this.handleLabelChange = this.handleLabelChange.bind(this);
		this.handleRemoveClick = this.handleRemoveClick.bind(this);

		this._dragTarget = React.createRef();
	}

	public handleDragStart(e: React.MouseEvent | React.TouchEvent) {
		const touch: touchHelper.ITouch = touchHelper.touchFromEvent(e);

		if (touch) {
			document.addEventListener('mousemove', this.handleDragMove);
			document.addEventListener('touchmove', this.handleDragMove);
			document.addEventListener('mouseup', this.handleDragEnd);
			document.addEventListener('touchend', this.handleDragEnd);

			this._trackedTouch = touch;

			actions.setRouteInteractionDisabled(true);
		}
	}

	public handleDragMove(e: MouseEvent | TouchEvent) {
		const { modalId, offset } = this.props;
		const touch: touchHelper.ITouch = touchHelper.touchFromEvent(e, e.type, this._trackedTouch && this._trackedTouch.identifier);

		if (touch && this._dragTarget && this._dragTarget.current) {
			const delta = touchHelper.getTouchVector(this._trackedTouch, touch);
			const updatedOffset = vectorUtil.add(offset, delta);

			this._trackedTouch = touch;

			actions.updateModalProps(modalId, { offset: updatedOffset });
		}
	}

	public handleDragEnd(e: MouseEvent | TouchEvent) {
		// const touch: touchHelper.ITouch = touchHelper.touchFromEvent(e, this._trackedTouch.sourceType, this._trackedTouch.identifier);

		document.removeEventListener('mousemove', this.handleDragMove);
		document.removeEventListener('touchmove', this.handleDragMove);
		document.removeEventListener('mouseup', this.handleDragEnd);
		document.removeEventListener('touchend', this.handleDragEnd);

		this.handleDragMove(e);

		this._trackedTouch = null;

		actions.setRouteInteractionDisabled(false);
	}

	public handleLabelChange(e) {
		const { updateItem } = this.props;
		const newVal: string = valueFilters.clean(e.target.value);

		updateItem([{ path: '/label', value: newVal.substr(0, 2), op: PatchOpType.replace }]);
	}

	public handleRemoveClick(e) {
		const { deleteItem, diagramItem, updateItem } = this.props;
		const { factory } = this.state;
		const mutatedItem = factory(diagramItem);
		const removed = mutatedItem.route.popSection();

		if (removed) {
			updateItem(mutatedItem.getPatch(diagramItem));
		} else {
			deleteItem(diagramItem);
		}
	}

	public render() {
		const { diagramItem, onClose } = this.props;
		const removeClassName = diagramItem.route.sections.values.length ? 'icon toolRemove' : 'icon toolDelete';

		return <div className="view">
			<header>
				<div className="actions">
					<a className="button" onMouseDown={ this.handleDragStart } onTouchStart={ this.handleDragStart } ref={ this._dragTarget }><span className="icon drag"></span></a>
				</div>
				<div className="actions tools">
					<a className="button" onClick={ this.handleRemoveClick }><span className={ removeClassName }></span></a>
				</div>
				<div className="actions">
					<a className="button" onClick={ onClose }><span className="icon ok"></span></a>
				</div>
			</header>
			<div className="content positionTools in">
				<div className="inner">
					<input type="text" placeholder=""  value={ diagramItem.viewValue('label') } onChange={ this.handleLabelChange }/>
				</div>
			</div>
		</div>;
	}
}

import { assign, debounce, filter, find, findIndex, forEach } from 'lodash';
import { IPatchOperation, PatchOpType, StringDictionary } from 'playmaker-team-common/dist/shared/interfaces';
import * as React from 'react';
import * as actions from '../actions';
import { current as getCurrentContext } from '../componentContext';
import * as logger from '../logger';
import { AnnotationType, IAnnotation } from '../models/annotation';
import { IDiagramItem } from '../models/diagramItemModel';
import { DiagramRenderFlags, GamePhase, IDiagram, PlayerLabelMode, SpecialTeamsUnit } from '../models/diagramModel';
import { IFormation } from '../models/formation';
import { IPersonnelGroup } from '../models/personnelGroup';
import { IPlay } from '../models/play';
import { IPlayer, PlayerRoles, PlayerSymbol } from '../models/player';
import { AnimationSpeed, IRoutedDiagramItem } from '../models/routedDiagramItemModel';
import { default as routeSectionFactory, IRouteSection, LineStyle } from '../models/routeSection';
import { ISortable } from '../models/sortableModel';
import { default as teamFactory, ITeam } from '../models/team';
import { ITeamMember, TeamRole } from '../models/teamMember';
import { default as userFactory } from '../models/user';
import { IPoint, IVector, vectorUtil } from '../models/vector';
import * as store from '../store';
import { _s, StringKey } from '../strings';
import { pushTooltip } from '../tooltipManager';
import * as viewManager from '../viewManager';
import { AlertList } from './alert';
import { AnimationToolbar } from './animationToolbar';
import { AnnotationToolbarBall } from './annotationToolbarBall';
import { AnnotationToolbarText } from './annotationToolbarText';
import { ConnectionStatus } from './connectionStatus';
import { BallLocationPlayer, DiagramControl } from './diagramControl';
import { PlayPanelFormationInfo } from './formationInfo';
import { PlayPanelRouteTreeInfo } from './routeInfo';
import { PlayPanelInfo } from './playInfo';
import { PlayPanelAnnotations } from './playPanelAnnotations';
import { PlayPanelFieldOptions } from './playPanelFieldOptions';
import { PlayPanelPositions } from './playPanelPositions';
import { IRouteTree } from '../models/routeTree';
import { PlayFilterContext } from './playFilterProvider';
import { Spinner } from './spinner';

interface Props {
	alerts: any[];
	className?: string;
	initialLocked?: boolean;
	fieldKey: string;
	posture: GamePhase;
	storeModel: any;
	decorations?: IDiagramItem[];
	modelFactory: (data: any) => (IFormation | IPlay | IRouteTree);
	saveModel: (model: IFormation | IPlay | IRouteTree) => void;
	PlayerToolbar: any;
	renderFlags: DiagramRenderFlags;
}

interface State {
	activeItem?: { item: IDiagramItem, tapCount: number, hasMoved: boolean };
	activeRouteSection?: IRouteSection;
	isPlay: boolean;
	modelName: string;
	isLocked: boolean;
	stateModel: IFormation | IPlay | IRouteTree;
	currentTeam: ITeam;
	fieldOptions: any;
	clipBounds: { top: number, right: number, bottom: number, left: number };
	routeDurations: { presnap: number, full: number, diagramItems: { [key: string]: { presnap: number, full: number } } };
	animationState: 'playing' | 'paused' | 'ended' | 'initial' | 'none';
}

let diagramModalCount = 0;
const diagramItemModalId = 'play-item-toolbar';
const diagramItemModalIds = [];
const diagramPanelId = 'diagram-panel';

// const ballLocationPlayer = playerFactory({ color: 0, symbol: PlayerSymbol.ball, role: PlayerRoles.center });

function getAnimationVelocity(item: IRoutedDiagramItem) {
	const { viewState } = getCurrentContext();
	const { config } = viewState;
	const { animation = { defaultVelocity: .008, minFactor: 1.2, maxFactor: .8} } = config;

	if (item.animationSpeed === AnimationSpeed.minimum) {
		return animation.minFactor * animation.defaultVelocity;
	} else if (item.animationSpeed === AnimationSpeed.maximum) {
		return animation.maxFactor * animation.defaultVelocity;
	}

	return animation.defaultVelocity;
}

function BackButton({ isPlay, modelName, stateModel }: Pick<State, 'isPlay' | 'modelName' | 'stateModel'>) {
	if(modelName === 'RouteTree') {
		const isComposite = stateModel.mates.count > 1;
		const firstMate = stateModel.mates.count? stateModel.mates.values[0]: null;

		return <a className="button" onClick={ viewManager.popPath }><span className="icon back"></span><span className="play"><strong className="formationName">{ isComposite ? '': stateModel.label }</strong>{ isComposite ? stateModel.label : `${firstMate?.sortIndex} • ${firstMate?.label}` }</span></a>
	}
	else {
		return <a className="button" onClick={ viewManager.popPath }><span className="icon back"></span><span className="play"><strong className="formationName">{ isPlay ? (stateModel as IPlay).formation : stateModel.label }</strong>{ isPlay ? stateModel.label : null }</span></a>
	}
}

function ApplyTemplateModal({formation}: { formation: IFormation}) {
	const { model: { plays }, variant } = getCurrentContext();
	const [colorSelected, setColorSelected] = React.useState(false);
	const [labelSelected, setlabelSelected] = React.useState(false);
	const [shadingSelected, setShadingSelected] = React.useState(false);
	const [processing, setProcessing] = React.useState(false);
	const formationPlays = filter(plays, { playbookId: formation.playbookId, formation: formation.label, phase: formation.phase });
	const doUpdate = async () => {
		setProcessing(true);

		await actions.applyFormation(formation, { label: labelSelected, color: colorSelected, shading: shadingSelected, location: false });

		setProcessing(false);

		actions.popModal();
	}

	return <div className="view">
		{ processing? <Spinner />: null }
		<header>
			<div className="title">{_s(StringKey.UPDATE_PLAYS_TITLE)}</div>
		</header>
		<div className="content scrollable">
			<div className="inner">
				<p>{_s(variant === 'tackle'? StringKey.UPDATE_PLAYS_DESCRIPTION_TACKLE: StringKey.UPDATE_PLAYS_DESCRIPTION_FLAG).replace('{count}', formationPlays.length).replace('{formationName}', formation.label) }</p>

				<div className="options">
				
					<div className="checkbox checkboxBasic"><label className={ colorSelected? 'on': undefined}><span className="icon badge checkmark"></span><span>{_s(StringKey.POSITION_COLORS)}</span><input type="checkbox" checked={ colorSelected } value="color" onChange={ () => setColorSelected(!colorSelected)} /></label></div>
					<div className="checkbox checkboxBasic"><label className={ labelSelected? 'on': undefined}><span className="icon badge checkmark"></span><span>{_s(StringKey.POSITION_LABELS)}</span><input type="checkbox" checked={ labelSelected } value="label" onChange={ () => setlabelSelected(!labelSelected)}/></label></div>
					<div className="checkbox checkboxBasic"><label className={ shadingSelected? 'on': undefined}><span className="icon badge checkmark"></span><span>{_s(StringKey.POSITION_ICON_SHADING)}</span><input type="checkbox" checked={ shadingSelected } value="shading" onChange={ () => setShadingSelected(!shadingSelected)}/></label></div>
				</div>

				<div className="actions">
					<a className={ colorSelected || labelSelected || shadingSelected? 'button basic': 'button basic disabled'} onClick={ doUpdate }><span>{ _s(StringKey.UPDATE_PLAYS_COUNT).replace('{count}', formationPlays.length) }</span></a>
					<a className="button basic cancel" onClick={ actions.popModal }><span>{ _s(StringKey.CANCEL)}</span></a>
				</div>
			</div>
		</div>
	</div>
}

function ApplyFormationButton({ diagram }: { diagram: IDiagram }) {
	const showModal = () => {
		actions.pushModal({
			component: ApplyTemplateModal,
			props: { classNames: ['prompt updatePlays'], formation: diagram },
		});
	};

	return <a className="button" onClick={ showModal }><span className="icon update"></span></a>;
}

let navDecorationTimeoutHandle;
function DiagramNavigation({ diagram }: { diagram: IDiagram }) {
	const playFilterContext = React.useContext(PlayFilterContext);
	const contextPlays = playFilterContext.getPlays();
	const currentIndex = findIndex(contextPlays, { id: diagram?.id });
	const previousPlay = currentIndex > 0? contextPlays[currentIndex - 1]: null;
	const nextPlay = currentIndex !== -1 && currentIndex < contextPlays.length - 1? contextPlays[currentIndex + 1]: null;

	const handleNavigation = (playId: string) => {
		const { model: { plays }, currentPlaybook } = getCurrentContext();
		const decorationId = 'play-nav-play-change';
		const play = find(plays, { id: playId });
		const playProps = { formation: play.formation, label: play.label };

		clearTimeout(navDecorationTimeoutHandle);
		actions.deleteDecoration(decorationId);

		actions.pushDecoration({
			component: (props) => <div className="info">
				<div className='container'>
					<span className="tackleOnly">{props.formation}</span>
					<span>{ props.label }</span>
				</div>
			</div>,
			id: decorationId,
			props: { ...playProps, classNames: ['overlay'] },
		});

		viewManager.rewritePath(`/playbook/${currentPlaybook.id}/plays/${playId}`);

		navDecorationTimeoutHandle = setTimeout(() => {
			actions.deleteDecoration(decorationId);
		}, 2000);
	}
	
	return currentIndex === -1? null: <div className="diagramNavigation">
		<a className={ previousPlay? 'button': 'button disabled' } onClick={ previousPlay? () => handleNavigation(previousPlay.id): undefined}><span className="icon navigatePrevious"></span></a>
		<a className={ nextPlay? 'button': 'button disabled'} onClick={ nextPlay? () => handleNavigation(nextPlay.id): undefined}><span className="icon navigateNext"></span></a>
	</div>
}

export class Diagram extends React.Component<Props, State> {
	private _debouncedSave;
	private _unsyncedChangePaths: string[] = [];
	private getPersonnelInfo_1 = null;
	private getPersonnelInfo_2 = null;
	private getPersonnelInfo_3 = null;
	private getPersonnelInfo_4 = null;

	constructor(props: Props) {
		super(props);

		const state = this.getStateFromProps(props);

		state.isLocked = !!props.initialLocked;
		state.animationState = 'none';
		state.routeDurations = { presnap: 0, full: 0, diagramItems: {} };

		this.state = state;

		this.getPersonnelInfo_1 = this.getPersonnelInfo.bind(this, PlayerLabelMode.FirstName);
		this.getPersonnelInfo_2 = this.getPersonnelInfo.bind(this, PlayerLabelMode.LastName);
		this.getPersonnelInfo_3 = this.getPersonnelInfo.bind(this, PlayerLabelMode.Initials);
		this.getPersonnelInfo_4 = this.getPersonnelInfo.bind(this, PlayerLabelMode.Number);
		this.handleAnimateClick = this.handleAnimateClick.bind(this);
		this.handleItemTouchStart = this.handleItemTouchStart.bind(this);
		this.handleItemTouchEnd = this.handleItemTouchEnd.bind(this);
		this.handleItemMove = this.handleItemMove.bind(this);
		this.handleRouteTouchStart = this.handleRouteTouchStart.bind(this);
		this.handleRouteMove = this.handleRouteMove.bind(this);
		this.handleRouteTouchEnd = this.handleRouteTouchEnd.bind(this);
		this.handleActiveItemChange = this.handleActiveItemChange.bind(this);
		this.handleModelChange = this.handleModelChange.bind(this);
		this.handleFlipClick = this.handleFlipClick.bind(this);
		this.handleLockClick = this.handleLockClick.bind(this);
		this.handleFieldOptionsClick = this.handleFieldOptionsClick.bind(this);
		this.handleAnnotationsClick = this.handleAnnotationsClick.bind(this);
		this.handleRosterClick = this.handleRosterClick.bind(this);
		this.handleInfoClick = this.handleInfoClick.bind(this);
		this.handleProfileChange = this.handleProfileChange.bind(this);
		this.handleDeleteAnnotation = this.handleDeleteAnnotation.bind(this);
		this.handlePersonnelDrop = this.handlePersonnelDrop.bind(this);
		this._onAnimationEnd = this._onAnimationEnd.bind(this);
		this.saveModel = this.saveModel.bind(this);
		this.setRouteLength = this.setRouteLength.bind(this);
		this._debouncedSave = debounce(this.save.bind(this), 500);
	}

	public componentWillUnmount() {
		this._deleteDiagramItemModals();
		actions.deletePanel(diagramPanelId);
	}

	public UNSAFE_componentWillReceiveProps(nextProps: Props) {
		const stateModel = this.state && this.state.stateModel;
		const storeModel = nextProps.storeModel;
		const { currentUser } = getCurrentContext();

		if (stateModel && (stateModel.id !== nextProps.storeModel.id || (stateModel.itemVersion < storeModel.itemVersion && stateModel.lastModifiedById === currentUser.id))) {
			this._unsyncedChangePaths = [];
		}

		if (!stateModel || stateModel.id !== nextProps.storeModel.id || (stateModel.itemVersion < storeModel.itemVersion && storeModel.lastModifiedById !== currentUser.id)) {
			this.updateState(this.getStateFromProps(nextProps), () => actions.updatePanelProps(diagramPanelId, { diagram: this.state.stateModel, formation: this.state.stateModel, play: this.state.stateModel }) );
		}
	}

	public getStateFromProps(props: Props): State {
		const { fieldKey, modelFactory, storeModel } = props;
		const { currentTeam, viewState } = getCurrentContext();
		const state: State = assign({}, this.state);
		const stateModel = modelFactory((state.stateModel && state.stateModel.toDocument()) || storeModel.toDocument());
		const patch = storeModel.getPatch(stateModel);

		filter(patch, (p) => this._unsyncedChangePaths.indexOf(p.path) === -1);
		stateModel.applyPatch(patch);
		state.stateModel = stateModel;
		state.modelName = stateModel.getModelName();
		state.isPlay = state.modelName === 'Play';
		state.fieldOptions = find(viewState.settings.fieldOptions, { key: fieldKey });
		state.clipBounds = {
			top: (1 - state.fieldOptions.drawingScaleHeight) / 2,
			right: 1 - ((1 - state.fieldOptions.drawingScaleWidth) / 2),
			bottom: 1 - ((1 - state.fieldOptions.drawingScaleHeight) / 2),
			left: (1 - state.fieldOptions.drawingScaleWidth) / 2,
		};
		state.currentTeam = currentTeam;

		this._unsyncedChangePaths = [];

		return state;
	}

	public getPersonnelInfo(mode: PlayerLabelMode, player: IPlayer & ISortable) {
		const { currentTeam, isPlay, stateModel } = this.state;
		const activePersonnelGroup: IPersonnelGroup = find(currentTeam.settings.personnelGroups.values, { id: (stateModel as IPlay).activePersonnelGroup }) || (stateModel as IPlay).personnelGroup;
		const teamMember: ITeamMember = find(currentTeam.members.values, { id: activePersonnelGroup[`player${player.sortIndex}`] });

		if (!isPlay || !teamMember || mode === PlayerLabelMode.None) {
			return null;
		}

		switch (mode) {
		case PlayerLabelMode.FirstName:
			return { teamMember, label: teamMember.firstName };

		case PlayerLabelMode.LastName:
			return { teamMember, label: teamMember.lastName };

		case PlayerLabelMode.Initials:
			return { teamMember, label: teamMember.initials };

		case PlayerLabelMode.Number:
			return { teamMember, label: teamMember.jerseyNumber };

		default:
			return null;
		}
	}

	public handleAnnotationsClick() {
		const { handleModelChange } = this;

		this.handleItemTouchEnd(true, () => {
			viewManager.pushPanel({
				id: diagramPanelId,
				component: PlayPanelAnnotations,
				props: () => {
					const { stateModel: play } = this.state;

					return {
						classNames: ['panel small'],
						play,
						updateDiagram: handleModelChange,
					};
				},
				screenName: 'PlayAnnotationPanel',
			});
			this.updateState({ isLocked: false } );
		});
	}

	public handleRosterClick() {
		const { handleModelChange, handleProfileChange } = this;

		this.handleItemTouchEnd(true, () => {
			const { stateModel } = this.state;
			const currentPlay = stateModel as IPlay;

			const onOpen = () => {
				const { currentTeam } = this.state;
				const playerCount = filter(currentTeam.members.values, (m: ITeamMember) => m.role === TeamRole.Player).length;
				const activePersonnelGroup: IPersonnelGroup = find(currentTeam.settings.personnelGroups.values, { id: currentPlay.activePersonnelGroup }) || currentPlay.personnelGroup;
				pushTooltip({
					id: 'rosterAddPlayers',
					className: 'right',
					text: _s(StringKey.TOOLTIP_ROSTER_ADD_PLAYERS),
					requiresTarget: true,
				}, () => !playerCount);
				pushTooltip({
					id: 'playAssignPositions',
					className: 'down',
					text: _s(StringKey.TOOLTIP_DIAGRAM_ASSIGN_POSITIONS),
					requiresTarget: true,
				}, () => !activePersonnelGroup.hasAssignedPlayers());
			};

			viewManager.pushPanel({
				id: diagramPanelId,
				component: PlayPanelPositions,
				props: () => {
					const { currentTeam, stateModel: play } = this.state;
					const { currentUser } = getCurrentContext();

					return {
						classNames: ['panel'],
						onOpen,
						play,
						team: currentTeam,
						updatePlay: handleModelChange,
						updateProfile: handleProfileChange,
						userProfile: currentUser.profile,
						onPersonnelDrop: this.handlePersonnelDrop,
					};
				},
				screenName: 'PlayRosterPanel',
			});
		});
	}

	public handleFlipClick() {
		const { activeItem } = this.state;
		const mutatedModel = this._getMutatedModel();
		const newActiveItem = activeItem && assign({}, activeItem, { item: mutatedModel.mates[activeItem.item.id] || mutatedModel.annotations[activeItem.item.id] || (mutatedModel as IPlay).opponents[activeItem.item.id] });

		try {
			mutatedModel.flipHorizontally();

			// console.log(activeItem);
			// console.log(newActiveItem);

			this.updateState({ activeItem: newActiveItem, stateModel: mutatedModel }, this.saveModel);
		} catch (err) {
			logger.logError(`failed to flip diagram id: ${mutatedModel && mutatedModel.id}`, false);
		}
	}

	public handleLockClick() {
		const { isLocked, stateModel } = this.state;
		const { currentPlaybook } = getCurrentContext();

		if(stateModel.getModelName() === 'RouteTree' && stateModel.mates.count > currentPlaybook.playersPerSide) {
			const text = _s(StringKey.TOOLTIP_ROUTE_TREE_LOCKED);
			pushTooltip({
				id: 'playLocked',
				className: 'up red',
				text,
				requiresTarget: true,
				initialDelay: 0,
			});
	
			pushTooltip({
				id: 'playLockedMobile',
				className: 'down red',
				text,
				requiresTarget: true,
				initialDelay: 0,
			});
		}
		else {
			this.handleItemTouchEnd(true, () => {
				this.updateState({ isLocked: !isLocked} );
				actions.deleteTooltip('playLocked');
				actions.deleteTooltip('playLockedMobile');
			});
		}
	}

	public handleDeleteAnnotation(annotation: IAnnotation) {
		this.handleModelChange([{ path: `/annotations/${annotation.id}`, op: PatchOpType.remove }]);
		this.handleItemTouchEnd(true);
	}

	public handleFieldOptionsClick() {
		this.handleItemTouchEnd(true, () => {
			const { viewState } = getCurrentContext();
			const playbookId = (viewState.currentRoute.params as any).playbookid;

			viewManager.pushPanel({
				id: diagramPanelId,
				component: PlayPanelFieldOptions,
				props: () => {
					const { stateModel, fieldOptions } = this.state;
					const { currentUser, model } = getCurrentContext();
					const ballOnOptions = fieldOptions.ballOnOptions;
					const { handleModelChange, handleProfileChange } = this;
					const opponentFilter: { playbookId: string, phase?: GamePhase, posture?: GamePhase, unit?: SpecialTeamsUnit } = { playbookId };

					if (stateModel.phase === GamePhase.SpecialTeams) {
						opponentFilter.phase = GamePhase.SpecialTeams;
						opponentFilter.unit = stateModel.unit;
						opponentFilter.posture = stateModel.posture === GamePhase.Offense ? GamePhase.Defense : GamePhase.Offense;
					} else {
						opponentFilter.phase = stateModel.phase === GamePhase.Offense ? GamePhase.Defense : GamePhase.Offense;
					}

					return {
						ballOnOptions,
						classNames: ['panel'],
						diagram: stateModel,
						opponentFormations: filter(model.formations, opponentFilter),
						updateDiagram: handleModelChange,
						updateProfile: handleProfileChange,
						userProfile: currentUser.profile,
					};
				},
				screenName: 'PlayFieldOptionsPanel',
			});
		});
	}

	public handleInfoClick() {
		this.handleItemTouchEnd(true, () => {
			const { isPlay, modelName, stateModel } = this.state;
			const { playbookPermissions } = getCurrentContext();
			const classNames = playbookPermissions.canUpdate ? ['panel'] : ['panel readOnly'];
			const doSave = (updated: IPlay | IFormation | IRouteTree, immediate = false) => {
				const mutatedModel = this._getMutatedModel();
				const patch = updated.getPatch(mutatedModel);

				if (patch.length) {
					mutatedModel.applyPatch(patch);

					this.updateState({ stateModel: mutatedModel }, immediate? this.save: this.saveModel);
				}
			};

			if (isPlay) {
				viewManager.pushPanel({
					id: diagramPanelId,
					component: PlayPanelInfo,
					props: () => {
						const { currentPlaybook, model } = getCurrentContext();
						const formations = filter(model.formations, { playbookId: currentPlaybook.id, phase: stateModel.phase });

						return {
							categories: filter(currentPlaybook.settings.categories.values, { phase: stateModel.phase }),
							formations,
							play: stateModel,
							isNew: false,
							savePlay: doSave,
							classNames,
						};
					},
					screenName: 'PlayInfo',
				});
			} 
			else if(modelName === 'RouteTree') {
				const mateId = stateModel.mates.count === 1? stateModel.mates.values[0].id: undefined;
				viewManager.pushPanel({
					id: diagramPanelId,
					component: PlayPanelRouteTreeInfo,
					props: () => {
						return {
							routeTree: stateModel,
							mateId,
							saveRouteTree: playbookPermissions?.canUpdate? doSave: undefined,
							classNames,
						};
					},
					screenName: 'RouteTreeInfo',
				});
				
			} 
			else {
				viewManager.pushPanel({
					id: diagramPanelId,
					component: PlayPanelFormationInfo,
					props: () => {
						return {
							formation: stateModel,
							isNew: false,
							saveFormation: doSave,
							classNames,
						};
					},
					screenName: 'FormationInfo',
				});
			}
		});
	}

	public handleAnimateClick() {
		const { handleModelChange } = this;
		const { renderFlags } = this.props;
		const { viewState: { diagramConfig }} = getCurrentContext();
		const { animationState, stateModel } = this.state;

		if (animationState === 'none') {
			this.handleItemTouchEnd(true, () => {
				this._pushDiagramItemModal(
					AnimationToolbar,
					{
						
						offset: diagramConfig.animationModalOffset || { x: 0, y: 68 },
						classNames: ['draggable', 'toolbar', 'medium', 'animationModal'],
						play: stateModel,
						state: 'initial',
						begin: () => this._setAnimationState('playing', true),
						reset: () => this._setAnimationState('initial'),
						pause: () => this._setAnimationState('paused'),
						unpause: () => this._setAnimationState('playing'),
						onClose: () => this.updateState({ animationState: 'none' }, () => this._deleteDiagramItemModals()),
						onOpen: () => {
							this.updateState({ animationState: 'initial' }, () => {
								this._updateDiagramItemModalProps({ state: this.state.animationState });
								setTimeout(() => {
									this._setAnimationState('playing', true);
								}, 50);
							});
						},
						showOpponents: !!(renderFlags & DiagramRenderFlags.showOpponents),
						updatePlay: (patch) => {
							this._setAnimationState('initial');
							handleModelChange(patch, () => {
								this._updateDiagramItemModalProps({ play: this.state.stateModel, state: 'paused' });
							});
						},
					});
			});
		} else {
			this.updateState({ animationState: 'none' }, () => this._deleteDiagramItemModals());
		}
	}

	public handleItemTouchStart(item: IDiagramItem) {
		const { PlayerToolbar, posture } = this.props;
		const { viewState: { diagramConfig }} = getCurrentContext();
		const { activeItem, stateModel } = this.state;
		let isMate = !!stateModel.mates[item.id];
		const modelType = stateModel.getModelName();
		const itemType = item.getModelName();
		let defaultOffset = { x: 0, y: 0 };
		let existingToolbar: store.IModal = actions.getModal(this._getDiagramItemModalId());
		let Toolbar;

		const getClassNames = () => {
			const classNames = ['draggable', 'toolbar'];

			if (modelType === 'Formation') {
				classNames.push('compact');
			}

			if (itemType === 'Annotation') {
				if ([AnnotationType.Ball, AnnotationType.BallAnimatable, AnnotationType.Text].indexOf((item as IAnnotation).subType) !== -1) {
					classNames.push('compact small');
				}
			}

			return classNames;
		};

		const showToolbar = () => {
			if (Toolbar) {
				// console.log('showing toolbar');
				const classNames = getClassNames();

				if (existingToolbar ) {
					this._updateDiagramItemModalProps({ classNames, diagram: stateModel, diagramItem: item, posture: playerPosture });
				} else {
					let onOpen = null;
					if (itemType === 'Player') {
						actions.setHelpSwitch(store.HelpSwitches.playEditPosition);
						onOpen = () => {
							pushTooltip({
								id: 'playDrawRoute',
								className: 'floating',
								text: _s(StringKey.TOOLTIP_DIAGRAM_DRAW_ROUTE),
								requiresTarget: false,
							}, (appState) => (appState.viewState.helpSwitches & store.HelpSwitches.playDrawRoute) === 0);

							pushTooltip({
								id: 'playDragToolbar',
								className: 'left',
								text: _s(StringKey.TOOLTIP_DIAGRAM_DRAG_TOOLBAR),
								requiresTarget: true,
								helpSwitch: store.HelpSwitches.playDragToolbar,
							}, (appState) => (item as IRoutedDiagramItem).route.sections.values.length && (appState.viewState.helpSwitches & store.HelpSwitches.playDragToolbar) === 0);

							pushTooltip({
								id: 'playAdvancedOptions',
								className: 'left',
								text: _s(StringKey.TOOLTIP_DIAGRAM_ADVANCED_OPTIONS),
								requiresTarget: true,
								helpSwitch: store.HelpSwitches.playAdvancedOptions,
							}, (appState) => (appState.viewState.helpSwitches & store.HelpSwitches.playDragToolbar) === store.HelpSwitches.playDragToolbar && (appState.viewState.helpSwitches & store.HelpSwitches.playAdvancedOptions) === 0);
						};
					}

					if (actions.getPanel(diagramPanelId)) {
						viewManager.popPanel();
					}

					this._pushDiagramItemModal(
						Toolbar,
						{
							offset: diagramConfig.itemModalOffset || defaultOffset,
							classNames,
							diagram: stateModel,
							deleteItem: this.handleDeleteAnnotation,
							posture: playerPosture,
							diagramItem: item,
							updateItem: this.handleActiveItemChange,
							onClose: () => this.handleItemTouchEnd(true),
							onOpen,
						});

				}
			}
		};

		if (item.id === BallLocationPlayer.id) {
			// does nothing?
		} else if (['Player', 'RouteTreePlayer'].indexOf(itemType) !== -1 || [AnnotationType.Option, AnnotationType.BallAnimatable].indexOf((item as IAnnotation).subType) !== -1) {
			Toolbar = PlayerToolbar;
			defaultOffset = { x: 0, y: 100000 };
			isMate = isMate || [AnnotationType.Option, AnnotationType.BallAnimatable].indexOf((item as IAnnotation).subType) !== -1;
		} else if (itemType === 'Annotation') {
			switch ((item as IAnnotation).subType) {
			case AnnotationType.Ball:
			case AnnotationType.BallAnimatable:
				Toolbar = AnnotationToolbarBall;
				break;
			case AnnotationType.Text:
				Toolbar = AnnotationToolbarText;
				break;
			}
		}

		if (!Toolbar || (existingToolbar && existingToolbar.component !== Toolbar)) {
			// console.log('deleting existingToolbar');
			this._deleteDiagramItemModals();
			existingToolbar = undefined;
		}

		// console.log(`touch start`);

		if (!activeItem || !activeItem.item || activeItem.item.id !== item.id) {
			this.updateState({ activeItem: { item, tapCount: 0, hasMoved: false }, animationState: 'none' }, showToolbar);
		} else {
			this.updateState({ activeItem: { item, tapCount: activeItem.tapCount + 1, hasMoved: false }, animationState: 'none' }, showToolbar);
		}

		const playerPosture = isMate ? posture : posture === GamePhase.Offense ? GamePhase.Defense : GamePhase.Offense;
	}

	public handleItemTouchEnd(force = false, callback: () => void = () => null) {
		const { activeItem } = this.state;
		// console.log(`touch end, force ${force}`);

		if (force || (activeItem && activeItem.tapCount > 0)) {

			this.updateState({ activeItem: null, animationState: 'none' }, () => {
				// console.log('delete modal from touch end');
				this._deleteDiagramItemModals();
				callback();
			});
		} else {
			this.updateState({ activeItem: { item: activeItem.item, tapCount: activeItem.tapCount, hasMoved: false } }, callback);
		}
	}

	public _getMutatedModel() {
		const { modelFactory } = this.props;
		const { stateModel } = this.state;

		return modelFactory(stateModel);
	}

	public _getBallLocationInfo() {
		const { fieldOptions, stateModel } = this.state;
		const ballOnOption = (fieldOptions && stateModel.ballLocation && find(fieldOptions.ballOnOptions, (opt) => opt.offset === stateModel.ballLocation.y));

		return { ballOnOption, ballLocation: stateModel.ballLocation, lineOfScrimage: ballOnOption ? stateModel.ballLocation.y : 0.5 };
	}

	public handleItemMove(location: IVector) {
		const { activeItem, clipBounds, isPlay } = this.state;
		const { currentUser, viewState } = getCurrentContext();
		const distance = location.distance(activeItem.item.loc);
		const mutatedModel = this._getMutatedModel();
		const modelType = activeItem.item.getModelName();
		let mutatedItem: IDiagramItem;
		let hasMoved = false;
		let abortUpdate = false;

		// console.log('touch move');

		if (distance > 0) {
			hasMoved = true;
		}

		const { lineOfScrimage, ballLocation } = this._getBallLocationInfo();
		const ballLocationY = ballLocation.y; // ballOnOption? ballLocation.y: lineOfScrimage;
		const yOffset = lineOfScrimage - ballLocationY;
		const clipY = { top: clipBounds.top - yOffset, bottom: clipBounds.bottom - yOffset };

		// console.log({ ballLocationY, lineOfScrimage, yOffset, clipY });

		if (currentUser.profile.snapToGrid) {
			if (viewState.settings.snapToGridSizeH) {
				location.x = viewState.settings.snapToGridSizeH * Math.round(location.x / viewState.settings.snapToGridSizeH);
			}
			if (viewState.settings.snapToGridSizeV) {
				location.y = viewState.settings.snapToGridSizeV * Math.round(location.y / viewState.settings.snapToGridSizeV);
			}
		}

		let isOpponent = false;
		switch (modelType) {
		case 'Player':

			mutatedItem = (activeItem.item.id === BallLocationPlayer.id) ? BallLocationPlayer : mutatedModel.mates[activeItem.item.id];
			if (!mutatedItem && isPlay) {
				mutatedItem = (mutatedModel as IPlay).opponents[activeItem.item.id];
				isOpponent = !!mutatedItem;

				if (mutatedItem) {
					(mutatedModel as IPlay).opponentsChanged = true;
				}
			}

			location.limit(clipBounds.left, clipBounds.right, isOpponent ? clipY.top : ballLocationY, isOpponent ? ballLocationY : clipY.bottom);

			if (((mutatedItem as IPlayer).role & PlayerRoles.center) === PlayerRoles.center) {
				const deltaX = location.x - mutatedItem.loc.x;

				forEach(mutatedModel.mates.values, (mate: IPlayer) => {
					const newX = mate.loc.x + deltaX;

					if (newX >= clipBounds.left && newX <= clipBounds.right) {
						mate.loc.x = newX;
						mate.loc.limit(clipBounds.left, clipBounds.right, ballLocationY, clipY.bottom);
					} else {
						abortUpdate = true;
					}
				});

				forEach(mutatedModel.annotations.values, (annotation: IAnnotation) => {
					const newX = annotation.loc.x + deltaX;

					if (newX >= clipBounds.left && newX <= clipBounds.right) {
						annotation.loc.x = newX;
						annotation.loc.limit(clipBounds.left, clipBounds.right, clipY.top, clipY.bottom);
					} else {
						abortUpdate = true;
					}
				});

				if (isPlay) {
					forEach((mutatedModel as IPlay).opponents.values, (opponent: IPlayer) => {
						const newX = opponent.loc.x + deltaX;

						if (newX >= clipBounds.left && newX <= clipBounds.right) {
							opponent.loc.x = newX;
							opponent.loc.limit(clipBounds.left, clipBounds.right, clipY.top, ballLocationY);
						} else {
							abortUpdate = true;
						}
					});
				}

				mutatedModel.ballLocation.x = vectorUtil.limit(ballLocation.x + deltaX, clipBounds.left, clipBounds.right);
			} else {
				mutatedItem.loc.x = location.x;
				mutatedItem.loc.y = location.y;
			}
			break;

		case 'Annotation':
			mutatedItem = mutatedModel.annotations[activeItem.item.id];

			location.limit(clipBounds.left, clipBounds.right, clipY.top, clipY.bottom);

			mutatedItem.loc.x = location.x;
			mutatedItem.loc.y = location.y;

			break;

		default:
			break;
		}

		if (!abortUpdate) {
			this.updateState({ activeItem: { item: mutatedItem, hasMoved, tapCount: hasMoved ? 0 : activeItem.tapCount }, stateModel: mutatedModel }, this.saveModel);
		}
	}

	public handleRouteTouchStart(touchPoint: IPoint) {
		const { activeItem, modelName } = this.state;
		const { currentUser, viewState } = getCurrentContext();

		if (activeItem && !viewState.disableRouteInteraction) {
			if ((activeItem.item as IRoutedDiagramItem).route) {
				const mutatedModel = this._getMutatedModel();
				const mutatedItem: IRoutedDiagramItem = mutatedModel.mates[activeItem.item.id] || (mutatedModel as IPlay).opponents[activeItem.item.id]  || mutatedModel.annotations[activeItem.item.id];

				if (mutatedItem) {
					if (currentUser.profile.snapToGrid) {
						if (viewState.settings.snapToGridSizeH) {
							touchPoint.x = viewState.settings.snapToGridSizeH * Math.round(touchPoint.x / viewState.settings.snapToGridSizeH);
						}
						if (viewState.settings.snapToGridSizeV) {
							touchPoint.y = viewState.settings.snapToGridSizeV * Math.round(touchPoint.y / viewState.settings.snapToGridSizeV);
						}
					}

					const canAddRoute = mutatedItem.maxRouteSections === -1 || mutatedItem.route.sections.count < mutatedItem.maxRouteSections;
					const routeSection: IRouteSection = canAddRoute ? routeSectionFactory({ loc: touchPoint, sortIndex: mutatedItem.route.sections.count }) : mutatedItem.route.last();

					if (canAddRoute) {
						actions.setHelpSwitch(store.HelpSwitches.playDrawRoute);
						if (modelName === 'Annotation' && ((activeItem.item as IAnnotation).subType === AnnotationType.Ball || (activeItem.item as IAnnotation).subType === AnnotationType.BallAnimatable)) {
							routeSection.lineStyle = LineStyle.dash;
						}
						mutatedItem.route.sections.add(routeSection);
					} else {
						routeSection.loc.x = touchPoint.x;
						routeSection.loc.y = touchPoint.y;
					}

					this.updateState({ activeItem: assign({}, activeItem, { item: mutatedItem}), activeRouteSection: routeSection, stateModel: mutatedModel }, () => {
						this.saveModel();
						this._updateDiagramItemModalProps({ diagramItem: mutatedItem });
					});
				}
			}
		}
	}

	public handleRouteMove(touchPoint: IPoint) {
		const { activeItem, activeRouteSection, isPlay } = this.state;
		const { currentUser, viewState } = getCurrentContext();

		if (activeItem && activeRouteSection && !viewState.disableRouteInteraction) {
			if ((activeItem.item as IRoutedDiagramItem).route) {
				const mutatedModel  = this._getMutatedModel();
				let mutatedItem: IRoutedDiagramItem = mutatedModel.mates[activeItem.item.id] || mutatedModel.annotations[activeItem.item.id];

				if (!mutatedItem && isPlay) {
					mutatedItem = (mutatedModel as IPlay).opponents[activeItem.item.id];
					if (mutatedItem) {
						(mutatedModel as IPlay).opponentsChanged = true;
					}
				}

				if (mutatedItem) {
					const routeSection: IRouteSection = mutatedItem.route.sections[activeRouteSection.id];

					if (routeSection) {
						if (currentUser.profile.snapToGrid) {
							if (viewState.settings.snapToGridSizeH) {
								touchPoint.x = viewState.settings.snapToGridSizeH * Math.round(touchPoint.x / viewState.settings.snapToGridSizeH);
							}
							if (viewState.settings.snapToGridSizeV) {
								touchPoint.y = viewState.settings.snapToGridSizeV * Math.round(touchPoint.y / viewState.settings.snapToGridSizeV);
							}
						}

						routeSection.loc.x = touchPoint.x;
						routeSection.loc.y = touchPoint.y;

						this.updateState({ activeItem: assign({}, activeItem, { item: mutatedItem }), activeRouteSection: routeSection, stateModel: mutatedModel }, this.saveModel);
					}
				}
			}
		}
	}

	public handleRouteTouchEnd() {
		const { activeRouteSection } = this.state;

		if (activeRouteSection) {
			this.updateState({ activeRouteSection: null });
		}
	}

	public handleActiveItemChange(patch: IPatchOperation[]) {
		const { posture } = this.props;
		const { activeItem, isPlay } = this.state;

		if (activeItem) {
			const mutatedModel = this._getMutatedModel();
			const modelType = activeItem.item.getModelName();
			let mutatedItem: IDiagramItem;
			const routeColorOp: IPatchOperation = find(patch, { path: '/routeColor' });
			const colorOp: IPatchOperation = find(patch, { path: '/color' });
			const symbolOp: IPatchOperation = find(patch, { path: '/symbol' });

			let isOpponent = false;
			switch (modelType) {
			case 'RouteTreePlayer':
			case 'Player':

				mutatedItem = mutatedModel.mates[activeItem.item.id];

				if (!mutatedItem) {
					isOpponent = true;
					mutatedItem = (mutatedModel as IPlay).opponents[activeItem.item.id];

					if (mutatedItem && isPlay) {
						(mutatedModel as IPlay).opponentsChanged = true;
					}
				}

				if (routeColorOp && Number(routeColorOp.value) === (mutatedItem as IPlayer).color) {
					routeColorOp.value = undefined;
				}

				if (colorOp && Number(colorOp.value) === (mutatedItem as IPlayer).routeColor) {
					patch.push({ op: PatchOpType.replace, path: '/routeColor', value: undefined });
				}

				if (symbolOp && symbolOp.value === PlayerSymbol.star) {
					const items = isOpponent ? (mutatedModel as IPlay).opponents.values : mutatedModel.mates.values;

					forEach(filter(items, { symbol: PlayerSymbol.star}), (p: IPlayer) => {
						p.symbol = p.getDefaultSymbol(isOpponent ? posture === GamePhase.Offense ? GamePhase.Defense : GamePhase.Offense : posture);
					});
				}

				break;

			case 'Annotation':
				mutatedItem = mutatedModel.annotations[activeItem.item.id];

				// if(routeColorOp && Number(routeColorOp.value) === (mutatedItem as IPlayer).color) {
				// 	routeColorOp.value = undefined;
				// }

				break;

			default:
				break;
			}

			mutatedItem.applyPatch(patch);

			this.updateState({ activeItem: assign({}, activeItem, { item: mutatedItem}), stateModel: mutatedModel }, () => {
				this.saveModel();
				this._updateDiagramItemModalProps({ diagramItem: mutatedItem });
			});
		}
	}

	public handleModelChange(patch: IPatchOperation[], onUpdate?: () => void) {
		const { isPlay } = this.state;

		const mutatedModel = this._getMutatedModel();
		const addAnnotationOp = find(patch, (op) => op.path.indexOf('/annotations/') === 0 && op.op === PatchOpType.add);
		const ballLocationOp = find(patch, { path: '/ballLocation/y', op: 'replace' });

		if (addAnnotationOp && addAnnotationOp.value.subType === AnnotationType.Ball) {
			const ballCount = filter(mutatedModel.annotations.values, { subType: AnnotationType.Ball }).length + 1;

			addAnnotationOp.value.label = ballCount.toString();
		}

		if (ballLocationOp) {
			const deltaY = ballLocationOp.value - mutatedModel.ballLocation.y;

			forEach(mutatedModel.mates.values, (mate: IPlayer) => {
				mate.loc.y += deltaY;
				mate.loc.limit(0, 1, ballLocationOp.value, 1);
			});

			forEach(mutatedModel.annotations.values, (annotation: IAnnotation) => {
				annotation.loc.y += deltaY;
				annotation.loc.limit(0, 1, 0, 1);
			});

			if (isPlay) {
				forEach((mutatedModel as IPlay).opponents.values, (opponent: IPlayer) => {
					opponent.loc.y += deltaY;
					opponent.loc.limit(0, 1, 0, ballLocationOp.value);
				});
			}
		}

		mutatedModel.applyPatch(patch);

		for (const p of patch) {
			if (this._unsyncedChangePaths.indexOf(p.path) === -1) {
				this._unsyncedChangePaths.push(p.path);
			}
		}

		this.updateState({ stateModel: mutatedModel }, () => {
			this.saveModel();
			if (addAnnotationOp) {
				this.handleItemTouchStart(mutatedModel.annotations[addAnnotationOp.value.id]);
			} else {
				actions.updatePanelProps(diagramPanelId, { diagram: mutatedModel, play: mutatedModel, formation: mutatedModel });
			}

			if (onUpdate) {
				onUpdate();
			}
		});
	}

	public handleProfileChange(pointer: string, value: any) {
		const { currentUser } = getCurrentContext();
		const mutatedUser = userFactory(currentUser);

		mutatedUser.profile.setAt(pointer, value);

		actions.saveUser(mutatedUser).then(() => {
			actions.updatePanelProps(diagramPanelId, { userProfile: mutatedUser.profile });
		});
	}

	public async handlePersonnelDrop(data) {
		const { currentTeam, stateModel } = this.state;
		const { currentUser } = getCurrentContext();
		const activePersonnelGroup: IPersonnelGroup = find(currentTeam.settings.personnelGroups.values, { id: (stateModel as IPlay).activePersonnelGroup }) || (stateModel as IPlay).personnelGroup;
		const { player, teamMember }: { player: (IPlayer & ISortable), teamMember: ITeamMember } = data;
		const patch = activePersonnelGroup.getMemberIndexUpdatePatch(teamMember.id, player.sortIndex);

		if (player.sortIndex < 0 || (player.sortIndex === null && !data.isLabel)) {
			return;
		}

		if (currentUser && currentUser.profile.playerLabelMode === PlayerLabelMode.None) {
			this.handleProfileChange('/playerLabelMode', PlayerLabelMode.FirstName.toString());
		}

		if (activePersonnelGroup === (stateModel as IPlay).personnelGroup) {
			forEach(patch, (op: IPatchOperation) => {
				op.path = `/personnelGroup${op.path}`;
			});

			this.handleModelChange(patch);
		} else {
			forEach(patch, (op: IPatchOperation) => {
				op.path = `/settings/personnelGroups/${activePersonnelGroup.id}${op.path}`;
			});

			const mutatedTeam = teamFactory(currentTeam);

			mutatedTeam.applyPatch(patch);

			// don't await this to allow for better UI responsiveness
			actions.saveTeam(mutatedTeam, `personnel-assign-${mutatedTeam.id}`);
			this.updateState({ stateModel: this._getMutatedModel(), currentTeam: mutatedTeam }); // HACK!!!! this forces a refresh of the view
		}
		// const positionKey = `player${player.sortIndex}`;
		// const currentMemberId:number = activePersonnelGroup[positionKey];

	}

	public setRouteLength(routedItem: IRoutedDiagramItem, length: number, type: string) {
		const { routeDurations } = this.state;
		const velocity = getAnimationVelocity(routedItem);
		const existingDurations = routeDurations.diagramItems[routedItem.id] || { full: 0, presnap: 0 };
		const existingDuration = existingDurations[type] || 0;
		const newDuration = (length * velocity);

		if (existingDuration !== newDuration) {
			const mutatedDurations = assign({}, routeDurations);

			mutatedDurations.diagramItems[routedItem.id] = assign(existingDurations, routeDurations.diagramItems[routedItem.id]);

			mutatedDurations.diagramItems[routedItem.id][type] = newDuration;

			mutatedDurations.full = mutatedDurations.presnap = 0;

			forEach(mutatedDurations.diagramItems, (playerDurations) => {
				if (playerDurations.full > mutatedDurations.full) {
					mutatedDurations.full = playerDurations.full;
				}
				if (playerDurations.presnap > mutatedDurations.presnap) {
					mutatedDurations.presnap = playerDurations.presnap;
				}
			});

			this.setState({ routeDurations: mutatedDurations });
		}
	}

	public updateState(mutation: StringDictionary, callback?: () => void) {
		this.setState(mutation as any, callback);
	}

	public saveModel() {
		this._debouncedSave();
	}

	public save(mutatedModel?: typeof this.state.stateModel) {
		const { modelFactory, saveModel } = this.props;
		const { stateModel } = this.state;

		mutatedModel = mutatedModel || modelFactory(stateModel);

		// console.log('saving', mutatedModel);

		saveModel(mutatedModel);
	}

	public render() {
		const { alerts= [], className, decorations, renderFlags } = this.props;
		const { activeItem, activeRouteSection, animationState, fieldOptions, isPlay, isLocked, modelName, routeDurations, stateModel} = this.state;
		const renderDiagramItem = activeItem && activeItem.item;
		let flags = renderFlags;
		const { currentUser, playbookPermissions } = getCurrentContext();
		const { ballLocation, lineOfScrimage } = this._getBallLocationInfo();
		const getPersonnelInfo = this[`getPersonnelInfo_${currentUser.profile.playerLabelMode}`];
		const isRouteTree = 'RouteTree' === modelName;
		const isCompositeRouteTree = (isRouteTree && stateModel.mates.count > 1)

		if (isLocked) {
			flags = flags & ~DiagramRenderFlags.interactive;
			flags = flags & ~DiagramRenderFlags.interactiveRoutes;
			flags = flags | DiagramRenderFlags.locked;
		}

		if (animationState !== 'none') {
			flags = flags | DiagramRenderFlags.showAnimations;
		}

		return <React.Fragment>
			<header>
				<div className="actions">
					<BackButton { ...this.state } />
				</div>
				<div className="actions">
					<a className="button formationTackleOnly" onClick={ this.handleInfoClick }><span className="icon info"></span></a>
					{ ['Play', 'RouteTree'].indexOf(modelName) === -1 || !playbookPermissions.canUpdate ? null : <a className={ isLocked ? 'button on' : 'button' } onClick={ this.handleLockClick } id="tooltip-target-playLocked"><span className="icon playLocked"></span></a> }
					<a className="button" onClick={ this.handleFieldOptionsClick }><span className="icon playField"></span></a>
					{ ['Formation'].indexOf(modelName) !== -1 && playbookPermissions.canUpdate? <ApplyFormationButton diagram={ stateModel} />: null}
					{ !isPlay || !playbookPermissions.canUpdate ? null : <a className="button" onClick={ this.handleFlipClick }><span className="icon playFlip"></span></a> }
					{ !isPlay || !playbookPermissions.canUpdate ? null : <a className="button" onClick={ this.handleAnnotationsClick }><span className="icon playAnnotation"></span></a> }
					{ !isPlay || !playbookPermissions.canUpdate ? null : <a className="button" onClick={ this.handleRosterClick }><span className="icon playRoster"></span></a> }
					{ isPlay || (isRouteTree && !isCompositeRouteTree) ? <a className={ `button${(flags & DiagramRenderFlags.showAnimations) ? ' on' : ''}` } onClick={ this.handleAnimateClick }><span className="icon playAnimation"></span></a>: null }
				</div>
				<div className="actions">
					<ConnectionStatus />
				</div>
			</header>
			<AlertList alerts={ alerts } />
			<div className="content">
				<DiagramControl
					className={ className }
					diagram={ stateModel }
					decorations={ decorations }
					activeItem={ renderDiagramItem }
					activeRouteSection={ activeRouteSection }
					ballLocation={ ballLocation }
					fieldOptions= { fieldOptions }
					getPersonnelInfo={ isPlay ? getPersonnelInfo : undefined }
					isMoving={ activeItem && activeItem.hasMoved }
					lineOfScrimage={ lineOfScrimage }
					onItemMove={ this.handleItemMove }
					onItemTouchStart={ this.handleItemTouchStart }
					onItemTouchEnd={ this.handleItemTouchEnd }
					onRouteTouchStart={ this.handleRouteTouchStart }
					onRouteTouchEnd={ this.handleRouteTouchEnd }
					onRouteMove={ this.handleRouteMove }
					onPersonnelDrop={ this.handlePersonnelDrop }
					renderFlags={ flags }
					routeDurations={ routeDurations }
					setRouteLength={ this.setRouteLength }
				/>
			</div>
			<DiagramNavigation diagram={ stateModel } />
			<footer>
				<div className="actions">
					<a className="button formationTackleOnly" onClick={ this.handleInfoClick }><span className="icon info"></span></a>
					{ (!isPlay && !isRouteTree) || !playbookPermissions.canUpdate ? null : <a className={ isLocked ? 'button on' : 'button' } onClick={ this.handleLockClick } id="tooltip-target-playLockedMobile"><span className="icon playLocked"></span></a> }
					{ !playbookPermissions.canUpdate ? null : <a className="button" onClick={ this.handleFieldOptionsClick }><span className="icon playField"></span></a> }
					{ ['Formation'].indexOf(modelName) !== -1 && playbookPermissions.canUpdate? <ApplyFormationButton diagram={ stateModel} />: null}
					{ !isPlay || !playbookPermissions.canUpdate ? null : <a className="button" onClick={ this.handleFlipClick }><span className="icon playFlip"></span></a> }
					{ !isPlay || !playbookPermissions.canUpdate ? null : <a className="button" onClick={ this.handleAnnotationsClick }><span className="icon playAnnotation"></span></a> }
					{ !isPlay || !playbookPermissions.canUpdate ? null : <a className="button" onClick={ this.handleRosterClick }><span className="icon playRoster"></span></a> }
					{ isPlay || (['RouteTree'].indexOf(modelName) === 0 && stateModel.mates.count === 1) ? <a className={ `button${(flags & DiagramRenderFlags.showAnimations) ? ' on' : ''}` } onClick={ this.handleAnimateClick }><span className="icon playAnimation"></span></a>: null }
				</div>
			</footer>
		</React.Fragment>;
	}

	private _pushDiagramItemModal(component, props) {
		const id = `${diagramItemModalId}-${diagramModalCount++}`;

		props.modalId = id;
		actions.pushModal({ id, component, props });

		this._deleteDiagramItemModals();

		// console.log(`pushing modal: ${id} onto ${diagramItemModalIds.length}`);

		diagramItemModalIds.push(id);
	}

	private _deleteDiagramItemModals() {
		while (diagramItemModalIds.length) {
			const id = diagramItemModalIds.pop();

			actions.deleteModal(id);
		}
	}

	private _getDiagramItemModalId() {
		if (diagramItemModalIds.length) {
			return diagramItemModalIds[diagramItemModalIds.length - 1];
		}
	}

	private _updateDiagramItemModalProps(props) {
		actions.updateModalProps(this._getDiagramItemModalId(), props);
	}

	private _onAnimationEnd() {
		// console.log(`_onAnimationEnd ${e.target.id}, ${this.state.animationState}`);
		this._setAnimationState('ended');
	}

	private _setAnimationState(desiredState: 'initial' | 'playing' | 'paused' | 'ended', reset = false) {
		const { animationState, routeDurations } = this.state;
		const animations = document.querySelectorAll('.animRouteMotion') as NodeListOf<any>;
		const diagramItems = document.querySelectorAll('svg.animatedDiagramItem') as NodeListOf<any>;
		let longestRoute = null;

		// console.log(`desiredState: ${desiredState}, ${reset} - currentState: ${animationState}`);

		for (const animation of animations) {
			animation.removeEventListener('endEvent', this._onAnimationEnd);
		}

		if (desiredState === 'playing') {
			const startFromOrigin = reset || ['initial', 'ended'].indexOf(animationState) !== -1;

			if (startFromOrigin) {
				for (const diagramItem of diagramItems) {
					diagramItem.setCurrentTime(0);
				}
			}

			for (const animation of animations) {
				const itemId = animation.id.split('-')[1];
				const diagramItemRouteLengths = routeDurations.diagramItems[itemId];

				if (startFromOrigin && diagramItemRouteLengths) {
					animation.beginElementAt(routeDurations.presnap - diagramItemRouteLengths.presnap);
				}

				if (!longestRoute || longestRoute.postSnap < (diagramItemRouteLengths.full - diagramItemRouteLengths.presnap)) {
					longestRoute = { animation, postSnap: (diagramItemRouteLengths.full - diagramItemRouteLengths.presnap) };
				}
			}

			for (const diagramItem of diagramItems) {
				if (diagramItem.animationsPaused()) {
					diagramItem.unpauseAnimations();
				}
			}
		} else if (desiredState === 'paused') {
			for (const diagramItem of diagramItems) {
				if (!diagramItem.animationsPaused()) {
					diagramItem.pauseAnimations();
				}
			}
		} else if (desiredState === 'initial') {
			for (const diagramItem of diagramItems) {
				diagramItem.setCurrentTime(0);

				if (diagramItem.animationsPaused()) {
					diagramItem.unpauseAnimations();
				}

				diagramItem.pauseAnimations();
			}
		}

		if (desiredState !== animationState) {
			this.setState({ animationState: desiredState }, () => {
				this._updateDiagramItemModalProps({ state: desiredState });
				if (longestRoute) {
					longestRoute.animation.addEventListener('endEvent', this._onAnimationEnd);
				}
			});
		} else if (reset && longestRoute) {
			setTimeout(() => longestRoute.animation.addEventListener('endEvent', this._onAnimationEnd), 0);
		}
	}
}

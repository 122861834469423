const imageMimeTest = /^(?:image\/bmp|image\/cis\-cod|image\/gif|image\/ief|image\/jpeg|image\/jpeg|image\/jpeg|image\/pipeg|image\/png|image\/svg\+xml|image\/tiff|image\/x\-cmu\-raster|image\/x\-cmx|image\/x\-icon|image\/x\-portable\-anymap|image\/x\-portable\-bitmap|image\/x\-portable\-graymap|image\/x\-portable\-pixmap|image\/x\-rgb|image\/x\-xbitmap|image\/x\-xpixmap|image\/x\-xwindowdump)$/i;
const dataUrlParser = /^data:([^,]*),(.*)$/i;

export interface IParsedDataUrl {
	spec: string;
	data: string;
}

export interface ILocalImage {
	getDataUrl: () => PromiseLike<string>;
	getImage: () => PromiseLike<HTMLImageElement>;
	fitToBounds: (width: number, height: number, letterbox: boolean) =>  PromiseLike<string>;
}

export function isValidImage(file: File): boolean {
	return imageMimeTest.test(file.type);
}

export function parseDataUrl(dataUrl: string): IParsedDataUrl {
	const match = dataUrlParser.exec(dataUrl);

	return {
		spec: match[1],
		data: match[2],
	};
}

export function create(fileOrUrl: string | File | HTMLImageElement): ILocalImage {
	return new LocalImage(fileOrUrl);
}

class LocalImage implements ILocalImage {
	private _sourceType: string;
	private _imagePromise: PromiseLike<HTMLImageElement>;
	private _dataUrlPromise: PromiseLike<string>;
	private _dataUrlProvider: () => PromiseLike<string>;

	constructor(fileOrUrl: string | File | HTMLImageElement) {
		if (typeof fileOrUrl === 'string') {
			this._sourceType = fileOrUrl.indexOf('data:') === 0 ? 'dataurl' : 'web';
		} else if ((fileOrUrl as HTMLImageElement).src) {
			this._sourceType = 'img';
		} else if (fileOrUrl.name) {
			this._sourceType = 'file';
		}

		const initPromise = this._getUrl(fileOrUrl);
		this._imagePromise = initPromise.then((result) => {
			return this._getImage(result);
		});

		this._dataUrlProvider = () => {
			return this._getDataUrl(fileOrUrl);
		};
	}

	public getDataUrl(): PromiseLike<string> {
		return this._dataUrlProvider();
	}

	public getImage(): PromiseLike<HTMLImageElement> {
		return this._imagePromise;
	}

	public fitToBounds(width: number, height: number, letterbox: boolean): PromiseLike<string> {
		return this.getImage().then(function(img: HTMLImageElement) {
			const canvas = document.createElement('canvas');
			const imageAspect = img.width / img.height;
			const canvasAspect = width / height;
			let size;
			let offset;

			canvas.width = width;
			canvas.height = height;
			const ctx = canvas.getContext('2d');

			// ctx.fillStyle = '#ffffff';
			// ctx.fillRect(0, 0, width, height);
			if (letterbox) {
				size = (imageAspect > canvasAspect) ? {
					width,
					height: (width / imageAspect),
				} : {
					width: (height * imageAspect),
					height,
				};
				offset = (imageAspect > canvasAspect) ? {
					x: 0,
					y: (height - size.height) / 2,
				} : {
					x: (width - size.width) / 2,
					y: 0,
				};
				ctx.drawImage(img, 0, 0, img.width, img.height, offset.x, offset.y, size.width, size.height);
			} else {
				size = (imageAspect > canvasAspect) ? {
					width: (img.height * canvasAspect),
					height: img.height,
				} : {
					width: img.width,
					height: (img.width / canvasAspect),
				};
				offset = (imageAspect > canvasAspect) ? {
					x: (img.width - size.width) / 2,
					y: 0,
				} : {
					x: 0,
					y: (img.height - size.height) / 2,
				};
				ctx.drawImage(img, offset.x, offset.y, size.width, size.height, 0, 0, width, height);
			}

			const dataUrl = canvas.toDataURL('image/png');

			return dataUrl;
		});
	}

	public _getDataUrl(fileOrUrl: string | File | HTMLImageElement): PromiseLike<string> {
		if (!this._dataUrlPromise) {
			this._dataUrlPromise = this.getImage().then((img) => {
				const canvas = document.createElement('canvas');
				canvas.width = img.naturalWidth;
				canvas.height = img.naturalHeight;
				canvas.getContext('2d').drawImage(img, 0, 0);

				return canvas.toDataURL('image/png');
			});

			// this._dataUrlPromise = new Promise((resolve, reject) => {
			// 	if (this._sourceType === 'dataurl') {
			// 		resolve(fileOrUrl as string);
			// 	}
			// 	else if(this._sourceType === 'img') {
			// 		const canvas = document.createElement('canvas');
			// 		const img = fileOrUrl as HTMLImageElement;

			// 		canvas.width = img.naturalWidth;
			// 		canvas.height = img.naturalHeight;
			// 		canvas.getContext('2d').drawImage(img, 0, 0);

			// 		resolve(canvas.toDataURL('image/png'));
			// 	}
			// 	else if(this._sourceType === 'web') {
			// 		this._imagePromise.then(img => {
			// 			const canvas = document.createElement('canvas');

			// 			canvas.width = img.naturalWidth;
			// 			canvas.height = img.naturalHeight;
			// 			canvas.getContext('2d').drawImage(img, 0, 0);

			// 			resolve(canvas.toDataURL('image/png'));
			// 		});
			// 	}
			// 	else if (this._sourceType === 'file') {
			// 		if (FileReader) {
			// 			const reader = new FileReader();

			// 			reader.onload = function () {
			// 				resolve(reader.result as string);
			// 			};
			// 			reader.readAsDataURL(fileOrUrl as File);
			// 		}
			// 		else {
			// 			reject(new Error('Local image processing is not supported by this browser.'));
			// 		}
			// 	}
			// 	else {
			// 		reject(new Error('Invalid or missing image.'));
			// 	}
			// });
		}

		return this._dataUrlPromise;
	}

	public _getUrl(fileOrUrl: string | File | HTMLImageElement): PromiseLike<string> {
		if (this._sourceType === 'file') {
			return _getOrientation(fileOrUrl as File).then((orientation: number) => {
				const url = URL.createObjectURL(fileOrUrl as any);
				if (orientation <= 1) {
					return url;
				} else {
					return this._getImage(url).then((img) => {
						return _normalizeOrientation(img, orientation).then((blob) => {
							return URL.createObjectURL(blob);
						});
					});
				}
			});
		} else if (this._sourceType === 'web') {
			return Promise.resolve(fileOrUrl as string);
		} else if (this._sourceType === 'img') {
			return Promise.resolve((fileOrUrl as HTMLImageElement).src);
		}
		return this._getDataUrl(fileOrUrl);
	}

	public _getImage(url: string): PromiseLike<HTMLImageElement> {
		const sourceType = this._sourceType;
		const revoke = () => {
			if (sourceType === 'file') {
				URL.revokeObjectURL(url);
			}
		};

		return new Promise((resolve, reject) => {
			const img = new Image();

			img.crossOrigin = 'Anonymous';

			img.onload = () => {
				revoke();
				resolve(img);
			};
			img.onerror = () => {
				reject(new Error('Failed to load ' + url));
			};

			img.src = url;
		});
	}
}

// see: https://stackoverflow.com/questions/7584794/accessing-jpeg-exif-rotation-data-in-javascript-on-the-client-side
function _getOrientation(file: File): PromiseLike<number> {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();

		reader.onload = (event: ProgressEvent) => {

			if (!event.target) {
				return resolve(-3);
			}

			const fr = event.target as FileReader;
			const view = new DataView(fr.result as ArrayBuffer);

			if (view.getUint16(0, false) !== 0xFFD8) {
				return resolve(-2);
			}

			const length = view.byteLength;
			let offset = 2;

			while (offset < length) {
				if (view.getUint16(offset + 2, false) <= 8) {
					return resolve(-1);
				}
				const marker = view.getUint16(offset, false);
				offset += 2;

				if (marker === 0xFFE1) {
					if (view.getUint32(offset += 2, false) !== 0x45786966) {
						return resolve(-1);
					}

					const little = view.getUint16(offset += 6, false) === 0x4949;
					offset += view.getUint32(offset + 4, little);
					const tags = view.getUint16(offset, little);
					offset += 2;

					for (let i = 0; i < tags; i++) {
						if (view.getUint16(offset + (i * 12), little) === 0x0112) {
							return resolve(view.getUint16(offset + (i * 12) + 8, little));
						}
					}
				} else if ((marker & 0xFF00) !== 0xFF00) {
					break;
				} else {
					offset += view.getUint16(offset, false);
				}
			}

			return resolve(-1);
		};

		reader.readAsArrayBuffer(file);
	});
}

function _normalizeOrientation(img: HTMLImageElement, orientation: number): PromiseLike<Blob> {
	return new Promise((resolve, reject) => {
		const canvas = document.createElement('canvas');
		const offset = { x: 0, y: 0};
		const width = img.naturalWidth;
		const height = img.naturalHeight;
		const ctx = canvas.getContext('2d');

		if (4 < orientation && orientation < 9) {
			canvas.width = height;
			canvas.height = width;
		} else {
			canvas.width = width;
			canvas.height = height;
		}

		// transform context before drawing image
		switch (orientation) {
		case 2:
			ctx.transform(-1, 0, 0, 1, width, 0);
			break;
		case 3:
			ctx.transform(-1, 0, 0, -1, width, height);
			break;
		case 4:
			ctx.transform(1, 0, 0, -1, 0, height);
			break;
		case 5:
			ctx.transform(0, 1, 1, 0, 0, 0);
			break;
		case 6:
			ctx.transform(0, 1, -1, 0, height, 0);
			break;
		case 7:
			ctx.transform(0, -1, -1, 0, height, width);
			break;
		case 8:
			ctx.transform(0, -1, 1, 0, 0, width);
			break;
		default:
			break;
		}

		ctx.drawImage(img, 0, 0);

		canvas.toBlob(resolve);
	});
}

import { assign } from 'lodash';
import { createFactory } from 'playmaker-team-common/dist/shared/modelFactory';
import { ModelSet } from 'playmaker-team-common/dist/shared/modelSet';
import { default as validator } from 'playmaker-team-common/dist/shared/validator';
import { default as valueFilters } from 'playmaker-team-common/dist/shared/valueFilters';
import { default as diagramItemModel, IDiagramItem } from './diagramItemModel';
import { default as sortableModel, ISortable } from './sortableModel';

export const enum EndCapType {
	none,
	arrow,
	bar,
	dot,
}

export const enum LineStyle {
	fill,
	dash,
	motion,
}

export interface IRouteSection extends IDiagramItem, ISortable {
	endCapType: EndCapType;
	lineStyle: LineStyle;
}

export default createFactory<IRouteSection>('RouteSection', assign({}, diagramItemModel, sortableModel, {
	endCapType: {
		setFilters: valueFilters.toNumber,
	},
	lineStyle: {
		setFilters: valueFilters.toNumber,
	},
}));

import { assign } from 'lodash';
import { createId } from 'playmaker-team-common/dist/shared/createId';
import { IPatchOperation, PatchOpType } from 'playmaker-team-common/dist/shared/interfaces';
import { createFactory } from 'playmaker-team-common/dist/shared/modelFactory';
import { default as validator } from 'playmaker-team-common/dist/shared/validator';
import { default as valueFilters } from 'playmaker-team-common/dist/shared/valueFilters';
import { default as coreModel, ICoreModel, stripDashes } from './coreModel';
import { GamePhase } from './diagramModel';
import { default as rootModel, IRootModel } from './rootModel';
import { default as sortableModel, ISortable } from './sortableModel';

export interface IPersonnelGroup extends ICoreModel, ISortable {
	player0?: string;
	player1?: string;
	player2?: string;
	player3?: string;
	player4?: string;
	player5?: string;
	player6?: string;
	player7?: string;
	player8?: string;
	player9?: string;
	player10?: string;
	player11?: string;
	label?: string;
	note?: string;
	phase: GamePhase;
	playersPerSide: number;
	getMemberIndex: (memberId: string) => number;
	getMemberIndexUpdatePatch: (memberId: string, index: number) => IPatchOperation[];
	hasAssignedPlayers: () => boolean;
}

export default createFactory<IPersonnelGroup>('PersonnelGroup', assign({}, coreModel, sortableModel, {
	id: {
		writable: false,
		defaultValue: () => {
			return stripDashes(createId());
		},
	},
	player0: {
		setFilters: valueFilters.clean,
	},
	player1: {
		setFilters: valueFilters.clean,
	},
	player2: {
		setFilters: valueFilters.clean,
	},
	player3: {
		setFilters: valueFilters.clean,
	},
	player4: {
		setFilters: valueFilters.clean,
	},
	player5: {
		setFilters: valueFilters.clean,
	},
	player6: {
		setFilters: valueFilters.clean,
	},
	player7: {
		setFilters: valueFilters.clean,
	},
	player8: {
		setFilters: valueFilters.clean,
	},
	player9: {
		setFilters: valueFilters.clean,
	},
	player10: {
		setFilters: valueFilters.clean,
	},
	player11: {
		setFilters: valueFilters.clean,
	},
	label: {
	},
	note: {
	},
	phase: {
		rules: [function(val, target) {
			if ([0, 1, 2].indexOf(val) === -1) {
				return 'phase is invalid';
			}
		}],
	},
	playersPerSide: {
		setFilters: valueFilters.toNumber,
	},
	getMemberIndex(memberId) {
		for (let i = 0; i < this.playersPerSide; i ++) {
			if (this[`player${i}`] === memberId ) {
				return i;
			}
		}

		return -1;
	},
	getMemberIndexUpdatePatch(memberId: string, index: number | null) {
		const result = [];
		const existingIndex = this.getMemberIndex(memberId);

		if (index === null) {
			if (existingIndex !== -1) {
				result.push({ op: PatchOpType.remove, path: `/player${existingIndex}` });
			}
		} else if (existingIndex !== index) {
			const playerKey = `player${index}`;
			const existingMemberAtIndex = this[playerKey] as string;

			result.push({ op: PatchOpType.replace, path: `/${playerKey}`, value: memberId });

			if (existingIndex !== -1) {
				result.push({ op: existingMemberAtIndex ? PatchOpType.replace : PatchOpType.remove, path: `/player${existingIndex}`, value: existingMemberAtIndex });
			}
		}

		return result;
	},
	hasAssignedPlayers() {
		for (let i = 0; i < this.playersPerSide; i ++) {
			if (this[`player${i}`]) {
				return true;
			}
		}

		return false;
	},
	fromDocument(document, keys, filter) {
		// try to fix invalid old personnel groups
		if (document && document.name && !document.label) {
			document.label = document.name;
		}

		return this._fromDocument(document, keys, filter);
	},
}));

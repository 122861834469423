import * as React from 'react';

export function Flag30x70({ teamLabel }) {
	return <div className="field">
		<svg viewBox="0 0 1024 2304">
			<svg className="markings" x="23%" y="26.67%" width="54%" height="46.67%">
				<svg className="boundary" x="-2%" y="-1%" width="104%" height="102%">
					<rect className="endline" x="0%" y="0%" width="100%" height="1%"></rect>
					<rect className="endline" x="0%" y="99%" width="100%" height="1%"></rect>
					<rect className="sideline" x="0%" y="0%" width="2%" height="100%"></rect>
					<rect className="sideline" x="98%" y="0%" width="2%" height="100%"></rect>
				</svg>
				<svg className="endzone" x="0%" y="0%" width="100%" height="14.3%">
					<text x="48%" y="58%">{ teamLabel }</text>
					<line className="yardline" x1="0" x2="100%" y1="100%" y2="100%" />
				</svg>
				<svg className="fiveYards" x="0%" y="14.3%" width="100%" height="7.14%">
					<line className="yardline dotted" x1="0" x2="100%" y1="100%" y2="100%"></line>
				</svg>
				<svg className="fifteenYards" x="0%" y="21.44%" width="100%" height="21.42%">
					<line className="yardline" x1="49%" x2="51%" y1="46.67%" y2="46.67%" />
					<line className="yardline dotted" x1="0" x2="100%" y1="100%" y2="100%"></line>
				</svg>
				<svg className="fiveYards" x="0%" y="42.86%" width="100%" height="7.14%">
					<line className="yardline" x1="0" x2="100%" y1="100%" y2="100%"></line>
				</svg>
				<svg className="fiveYards" x="0%" y="50%" width="100%" height="7.14%">
					<line className="yardline dotted" x1="0" x2="100%" y1="100%" y2="100%"></line>
				</svg>
				<svg className="fifteenYards" x="0%" y="57.14%" width="100%" height="21.42%">
					<line className="yardline" x1="49%" x2="51%" y1="53.33%" y2="53.33%" />
					<line className="yardline dotted" x1="0" x2="100%" y1="100%" y2="100%"></line>
				</svg>
				<svg className="fiveYards" x="0%" y="78.56%" width="100%" height="7.14%">
					<line className="yardline" x1="0" x2="100%" y1="100%" y2="100%"></line>
				</svg>
				<svg className="endzone" x="0" y="85.7%" width="100%" height="14.3%">
					<text x="50%" y="58%">OPPONENT</text>
				</svg>
			</svg>
		</svg>
	</div>;
}

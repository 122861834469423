import { filter, find, map, sortBy } from 'lodash';
import * as React from 'react';
import * as actions from '../../actions';
import * as store from '../../store';
import { fetchPlays, fetchFormations, _supportAccountSummary, _supportExpireSubscription, _supportPauseSubscription, _supportUnPauseSubscription, _supportPurgeAccount, _supportRemoveTeamMember } from '../../apiGateway';
import * as playbookHelper from '../../playbookHelper';
import { IUser } from '../../models/user';
import { default as subscriptionFactory, ISubscription } from '../../models/subscription';
import { default as teamFactory, ITeam } from '../../models/team';
import { ITeamMember, TeamRole } from '../../models/teamMember';
import { default as formationFactory } from '../../models/formation';
import { default as playFactory } from '../../models/play';
import { default as playbookFactory, IPlaybook } from '../../models/playbook';
import { getPlaybookPermissions, getTeamPermissions } from '../../authorizationHelper';
import { Login } from '../login';

function openLogin() {
	actions.pushModal({
		component: Login,
		props: () => ({ viewState: store.appState().viewState, classNames: ['login'] }),
	});
}

function formatDate(date:Date) {
	if(!date) {
		return 'N/A';
	}
	return date.toLocaleDateString('en-US', { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' });
}

function UserSummary({ user, setSummary }: { user: IUser, setSummary: any}) {

	const handlePurgeClick = React.useCallback(async () => {
		if(confirm('Do you REALLY mean to purge this user?')) {
			const response = await _supportPurgeAccount(user.id);

			if(response.user || response.teams?.length) {
				setSummary(response)
			}
		}
	}, [user]);

	return user? <table className="queryTable">
		<tbody>
			<tr>
				<td className="property summary">User</td>
				<td>{ user.email} ({user.id})</td>
				<td className="buttons shrink"><a className="badge delete" onClick={handlePurgeClick}>Permanently Purge</a></td>
			</tr>
			<tr>
				<td className="property summary">Name</td>
				<td>{ [user.firstName, user.lastName].filter(n=>!!n).join(' ') }</td>
				<td className="buttons shrink"><a className="badge" onClick={openLogin}>Impersonate</a></td>
			</tr>
			<tr>
				<td className="property summary">Apps</td>
				<td dangerouslySetInnerHTML={{__html: map((user as any).apps, (version, name) => `${name} (${version})`).join('<br>') }}/>
				<td className="buttons shrink"></td>
			</tr>
		</tbody>
	</table>
		:<table className="queryTable">
			<tbody>
				<tr>
					<td className="property summary">User</td>
					<td>Not Found</td>
				</tr>
			</tbody>
		</table>;
}

function TeamMemberList({ supportUser, teamMembers, setSummary }: { supportUser: IUser, teamMembers: ITeamMember[], setSummary: any }) {
	return <table>
		<tbody>
			{
				teamMembers.map(teamMember => {
					const hasAcceptedInvite = teamMember?.inviteCode && teamMember?.userId;
					const handleRemoveClick = async () => {
						if(confirm('Do you REALLY mean to remove this member from the team?')) {
							const response = await _supportRemoveTeamMember(supportUser.id, teamMember.teamId, teamMember.id);
				
							if(response.user || response.teams?.length) {
								setSummary(response)
							}
						}
					};
					return <tr>
						<td className='role'>{ teamMember? TeamRole[teamMember.role]: 'None'}</td>
						<td className={ hasAcceptedInvite? 'invite accepted': 'invite'} title={ hasAcceptedInvite? 'Accepted': ''}>{ teamMember?.inviteCode? `${teamMember?.inviteCode}`: teamMember?.userId === teamMember?.createdById? 'Creator': 'Not invited' }</td>
						<td className='name'>{[teamMember.firstName, teamMember.lastName].filter(n=>!!n).join(' ') }</td>
						<td className='email'>{ teamMember?.email ?? 'N/A'}</td>
						<td className="buttons shrink"><a className="button" title="Remove from Team" onClick={handleRemoveClick}><span className="icon removeItem"></span></a></td>
					</tr>
				})
			}
		</tbody>
	</table>
}

function PlaybookList({ playbooks, team, user }: { playbooks:IPlaybook[], team: ITeam, user: IUser}) {
	const teamPermissions = user? getTeamPermissions({ team: teamFactory(team), user}): null;

	const exportPlaybook = async (playbook) => {
		const formationResult = await fetchFormations([playbook.id]);
		const playResult = await fetchPlays([playbook.id]);
		const formations = {};
		const plays = {}

		formationResult.formations?.forEach(f => {
			formations[f.id] = formationFactory(f);
		});

		playResult.plays?.forEach(p => {
			plays[p.id] = playFactory(p);
		});

		const model = {
			formations,
			plays,
			teams: {},
			users: {}
		} as store.IModelState
		const exportData = playbookHelper.exportPlaybook(playbook, model);

		playbookHelper.saveAsJson(exportData, `${playbook.name}.json`);
	}
	
	return <table>
		<tbody>
			{
				playbooks.map(playbook => {
					const isArchived = !!(playbook.flags & (1 << 0));
					const restore = playbook.flags & (1 << 1)? <a className="badge restore" onClick={() => actions.undeletePlaybook(playbook.id)}>Restore</a>: null;
					const download = !isArchived? <a className="button" title="Download Playbook" onClick={() => exportPlaybook(playbook)}><span className="icon playbookDownload"></span></a>: null;
					const permissions = user? getPlaybookPermissions({ playbook, teamPermissions, user }): null;
					const deletedBy = restore? <React.Fragment><span className="deletedBy nowrap">{ `Deleted by ${team.members.values.find(m => m.userId === playbook.lastModifiedById)?.email ?? playbook.lastModifiedById}` }</span><br/></React.Fragment>: null;
					let permissionsLabel = 'None';

					if(permissions?.canUpdate) {
						permissionsLabel = 'Contribute'
					}
					else if(permissions?.canView) {
						permissionsLabel = 'View'
					}

					return <tr>
						<td className="permissions">{ permissionsLabel }</td>
						<td className="playbookName">{ playbook.name }</td>
						<td className="playbookId">{ deletedBy }<span className="nowrap">{ playbook.id }</span></td>
						<td className="buttons shrink">{isArchived? <span className="badge archived">Archived</span>: ''} { restore }{ download }{playbook.deleted && !restore? 'Deleted': ''}</td>
					</tr>
				})
			}
		</tbody>
	</table>
}

function TeamSummary({ team, user, setSummary, subscriptions }: { team: ITeam, user: IUser, setSummary: any, subscriptions: ISubscription[] }) {
	const userMember = find(team.members, m => m.userId && m.userId === user?.id);
	const emailMember = userMember ?? find(team.members, m => m.email && m.email.toLowerCase() === user?.email?.toLowerCase());
	const isCreator = userMember && userMember.userId === team.createdById;
	const playbooks = map(team?.playbooks.values, playbookFactory)
	let inviteLabel = 'Not Invited';
	let expireButton = null;
	let pauseButton = null;

	const handleExpireClick = React.useCallback(async () => {
		if(confirm('Do you REALLY want to expire this subscription?')) {
			const response = await _supportExpireSubscription(user.email, team.currentSubscription.id);

			if(response.user || response.teams?.length) {
				setSummary(response)
			}
		}
	}, [user]);

	const handlePauseClick = React.useCallback(async () => {
		if(confirm('Do you really want to pause this subscription?')) {
			const response = await _supportPauseSubscription(user.email, team.currentSubscription.id);

			if(response.user || response.teams?.length) {
				setSummary(response)
			}
		}
	}, [user]);

	const handleUnPauseClick = React.useCallback(async () => {
		if(confirm('Do you really want to unpause this subscription?')) {
			const response = await _supportUnPauseSubscription(user.email, team.currentSubscription.id);

			if(response.user || response.teams?.length) {
				setSummary(response)
			}
		}
	}, [user]);

	if(team?.currentSubscription?.isActive() && team.currentSubscription.createdById === userMember?.userId) {

		pauseButton = team.currentSubscription.dueDate? <a className="badge" onClick={handlePauseClick}>Pause Subscription</a>: <a className="badge" onClick={handleUnPauseClick}>Unpause Subscription</a>;
		expireButton = <a className="badge delete" onClick={handleExpireClick}>Expire Subscription</a>;
	}

	if(isCreator) {
		inviteLabel = 'Creator';
	}
	else if(userMember) {
		inviteLabel  = `${userMember.inviteCode} (Accepted)`;
	}
	else if(emailMember) {
		const userMembers = filter(team.members.values, m => m.email && m.email.toLowerCase() === emailMember.email?.toLowerCase());
		
		if(userMembers.length > 1) {
			inviteLabel = `Unknown (multiple team members with same email address)`;
		}
		else {
			inviteLabel = `${emailMember.inviteCode} (Not Accepted)`;
		}
	}

	return <table className="queryTable">
		<tbody>
			<tr>
				<td className="property">Team</td>
				<td>{ team.name } - { team.id }</td>
			</tr>
			<tr>
				<td className="property">Role</td>
				<td>{ userMember? TeamRole[userMember.role]: 'None'}</td>
			</tr>
			<tr>
				<td className="property">Invite</td>
				<td>{ inviteLabel }</td>
			</tr>
			<tr>
				<td className="property">Subscription</td>
				<td>
					<table>
						<tbody>
							<tr>
								<td className="property summary">Plan Name</td>
								<td className={ team?.currentSubscription?.isActive()? 'active': ''}>{ team?.currentSubscription?.name ?? 'N/A' }</td>
								<td className="buttons shrink">{ pauseButton }{ expireButton }</td>
							</tr>
							<tr>
								<td className="property summary">Plan Key</td>
								<td>{ team?.currentSubscription?.settings?.planKey ?? 'N/A' }</td>
								<td className="buttons shrink"></td>
							</tr>
							<tr>
								<td className="property summary">Processor</td>
								<td>{ team?.currentSubscription? team?.currentSubscription?.settings?.iapTroll?.name ?? 'direct pay': 'N/A' }</td>
								<td className="buttons shrink"></td>
							</tr>
							<tr>
								<td className="property summary">Started</td>
								<td>{ formatDate(team?.currentSubscription?.startDate) }</td>
								<td className="buttons shrink"></td>
							</tr>
							<tr>
								<td className="property summary">Renews</td>
								<td>{ formatDate(team?.currentSubscription?.dueDate) }</td>
								<td className="buttons shrink"></td>
							</tr>
							<tr>
								<td className="property summary">Expiration</td>
								<td>{ formatDate(team?.currentSubscription?.expirationDate) }</td>
								<td className="buttons shrink"></td>
							</tr>
							<tr>
								<td className="property summary">End</td>
								<td>{ formatDate(team?.currentSubscription?.endDate) }</td>
								<td className="buttons shrink"></td>
							</tr>
						</tbody>
					</table>
				</td>
			</tr>
			<tr>
				<td className="property">History</td>
				<td>
					<table>
						<tr>
							<td className='summary'><strong>Plan Name</strong></td>
							<td><strong>Plan Key</strong></td>
							<td><strong>Processor</strong></td>
							<td><strong>Started</strong></td>
							<td><strong>Renews</strong></td>
							<td><strong>Expiration</strong></td>
							<td><strong>End</strong></td>
							<td><strong>Amount</strong></td>
						</tr>
						<tbody>
							{ sortBy(subscriptions, ['dateCreated']).map((subscription:ISubscription) => 
								<tr>
									<td className='summary'>{ subscription.name }</td>
									<td>{subscription.settings?.planKey}</td>
									<td>{subscription.settings?.iapTroll?.name ? 'direct pay': 'N/A'}</td>
									<td>{ formatDate(subscription.startDate) }</td>
									<td>{ formatDate(subscription.dueDate) }</td>
									<td>{ formatDate(subscription.expirationDate) }</td>
									<td>{ formatDate(subscription.endDate) }</td>
									<td>{ subscription.paymentAmount }</td>
								</tr>
							)}
						</tbody>
					</table>
				</td>
			</tr>
			<tr>
				<td className="property">Team Members</td>
				<td>
					<TeamMemberList supportUser={user} setSummary={setSummary} teamMembers={ sortBy(team.members.values, ['role', 'email'])} />
				</td>
			</tr>
			<tr>
				<td className="property">Playbooks</td>
				<td>
					<PlaybookList playbooks={sortBy(playbooks, ['name'])} team={team} user={ user } />
				</td>
			</tr>
		</tbody>
	</table>
}

export function SupportModal() {
	const [email, setEmail] = React.useState('');
	const [summary, setSummary] = React.useState<Record<string, any>>();
	const handleSearchClick = React.useCallback(async () => {
		const response = await _supportAccountSummary(email);

		if(response.user || response.teams?.length) {
			setSummary(response)
		}
		else {
			setSummary(null)
		}
	}, [email]);

	return <div className="view in page">
		<header>
			<div className="actions">
				<a className="button" onClick={ actions.popModal }><span className="icon close"></span></a></div>
			<div className="title">Support Tool</div>
		</header>
		<div className="content scrollable">
			<div className="inner">
				<input type="text" placeholder="Email" value={ email } onChange={ e => setEmail(e.target.value) } />
				<a className="button basic" onClick={handleSearchClick}><span>Search</span></a>

				<p>&nbsp;</p>
            
				{ /* Overview */ }
				{ summary !== undefined? <UserSummary user={summary?.user} setSummary={setSummary} />: null }

				{ summary !== undefined? <h2 className="divider">Teams</h2>: null }
            
				{ summary?.teams? map(summary.teams, t => <TeamSummary team={ teamFactory(t) } subscriptions={ map(t.subscriptions, s => subscriptionFactory(s))} user={ summary.user } setSummary={setSummary} />): null }
			</div>
		</div>
	</div>;
}

import { filter, find, map, sortBy } from 'lodash';
import * as React from 'react';
import * as actions from '../actions';
import { fetchBanners } from '../dataService';
import { current as getCurrentContext } from '../componentContext';
import { IPlaybook } from '../models/playbook';
import { ITeam } from '../models/team';
import * as store from '../store';
import { _s, StringKey } from '../strings';
import * as viewManager from '../viewManager';
import { AlertList } from './alert';
import { BillingModal } from './billing';
import { CachedImage } from './cachedImage';
import { ConnectionStatus } from './connectionStatus';
import { MainMenu } from './mainMenu';
import { Redeem } from './redeem';
import { SponsorModal } from './sponsorModal';

const SquareTileGraphics = ({teamColor}) => {
	return <svg className="border">
		<svg>
			<svg className="bracket">
				<g className="base">
					<rect width="140%" height="140%" x="-20%" y="-20%" rx="3" ry="3"></rect>
				</g>
				<g className="rotating">
					<rect width="140%" height="140%" x="-20%" y="-20%" rx="3" ry="3"></rect>
					<line x1="-35%" x2="-25%" y1="50%" y2="50%"></line>
					<line x1="125%" x2="135%" y1="50%" y2="50%"></line>
				</g>
			</svg>
		</svg>
		<rect width="100%" height="100%" rx="3" ry="3" style={{ fill: teamColor, stroke: teamColor }}></rect>
	</svg>;
};

const SponsorUpgradeTile = ({ onUpgradeClick, teamColor }) => {
	const { currentSubscription, teamPermissions } = getCurrentContext();
	const subscriptionPlan = currentSubscription.subscriptionPlan;

	if (!subscriptionPlan || currentSubscription.isProductPurchase() || !teamPermissions.canManageBilling || !currentSubscription.isActive()) {
		// only subscriptions that have plans with upgrades have their subscriptionPlan populated
		return null;
	}

	const planUpgrades = subscriptionPlan ? sortBy(subscriptionPlan.upgrades.values, 'featureSort') : [];
	let maxFeatureSort = currentSubscription.featureSort;
	const activeUpgrades = map(filter(planUpgrades, (pu) => !!find(currentSubscription.upgrades.values, { subscriptionPlanUpgradeId: pu.id })), (pu) => {
		if (pu.featureSort > maxFeatureSort) {
			maxFeatureSort = pu.featureSort;
		}
		return find(currentSubscription.upgrades.values, { subscriptionPlanUpgradeId: pu.id });
	});
	const availableUpgrades = map(filter(planUpgrades, (pu) => pu.featureSort > maxFeatureSort && !find(currentSubscription.upgrades.values, { subscriptionPlanUpgradeId: pu.id })), (pu) => {
		return pu;
	});

	if (!availableUpgrades.length) {
		return null;
	}

	return <div className="hudGraphic rectangle upgrade">
		<div className="foreground">
			<div className="border">
				<span className="icon">
					<svg>
						<g transform="translate(5,5)">
							<path style={{ stroke: teamColor }} d="M.51-3.72,4.65,3.1a.59.59,0,0,1-.2.81A.56.56,0,0,1,4.14,4H-4.14a.59.59,0,0,1-.6-.59.56.56,0,0,1,.09-.31L-.51-3.72a.61.61,0,0,1,.82-.19A.61.61,0,0,1,.51-3.72Z"/>
						</g>
					</svg>
				</span>
				<div className="label"><span className="title">{ _s(StringKey.UPGRADE) }</span></div>
			</div>
			<a className="button" onClick={ onUpgradeClick }></a>
		</div>
	</div>;
};

const UpgradeTile = ({ onUpgradeClick, teamColor }) => {
	const { currentSubscription, sponsorLogoUrl, teamPermissions } = getCurrentContext();

	if (!currentSubscription || !teamPermissions.canManageBilling) {
		return null;
	}

	const hasSponsor = !!currentSubscription.settings && !!currentSubscription.settings.sponsorTribute && currentSubscription.isActive();

	if (hasSponsor || ((currentSubscription.isPromo() || !currentSubscription.canUpgrade()) && currentSubscription.isActive())) {
		return null;
	}

	return <div className="anim side left">
		<div className="hudGraphic square">
			<div className="foreground">
				<SquareTileGraphics teamColor={ teamColor } />
				{ !currentSubscription.isActive() ? <React.Fragment><span className="icon renew"></span><div className="label"><span className="title">{ _s(StringKey.RENEW) }</span></div></React.Fragment> : null }
				{ currentSubscription.isActive() && currentSubscription.canUpgrade() ? <React.Fragment><span className="icon upgrade"></span><div className="label"><span className="title">{ _s(StringKey.UPGRADE) }</span></div></React.Fragment> : null }
				<a className="button" onClick={ onUpgradeClick }></a>
			</div>
		</div>
	</div>;
};

const SponsorTile = ({ onSponsorClick, onUpgradeClick, teamColor }) => {
	const { currentSubscription, platform, sponsorLogoUrl, sponsorLogoPrintUrl, teamPermissions } = getCurrentContext();

	if (!currentSubscription || !currentSubscription.isActive()) {
		return null;
	}

	const hasSponsor = !!currentSubscription.settings && !!currentSubscription.settings.sponsorTribute;

	if (!hasSponsor) {
		return null;
	}

	return <div className="anim side left">
		<div className="hudGraphic square">
			<div className="foreground">
				<SquareTileGraphics teamColor={ teamColor } />
				<span className="icon photo"><CachedImage key={ sponsorLogoUrl ? 'logo' : 'empty' } src={ sponsorLogoUrl } /></span>
				<div className="label"><span className="title">{ _s(StringKey.POWERED_BY) }</span></div>
				<a className="button" onClick={ onSponsorClick }></a>
			</div>
			<CachedImage key={ sponsorLogoPrintUrl ? 'logo' : 'empty' } src={ sponsorLogoPrintUrl } preloadOnly={ true } />
		</div>
		{ platform.isTrolledGarden ? null : <SponsorUpgradeTile onUpgradeClick={ onUpgradeClick } teamColor={ teamColor } /> }
	</div>;
};

const InfoBanner = ({ teamPermissions }) => {
	const { viewState } = getCurrentContext();
	const { banners, config } = viewState;
	const bannerKey = teamPermissions.canManageBilling ? 'admin' : teamPermissions.canManageMembers ? 'staff' : 'player';
	const langBanners = banners && (banners[config.language] || banners.en);

	React.useEffect(() => {
		fetchBanners();
	}, [bannerKey])

	if (!langBanners || !langBanners[bannerKey]) {
		return null;
	}

	const banner = langBanners[bannerKey];

	return <div className="animInfoBanner">
		<div className="anim">
			<div className="pointers">
				<svg className="pointer left">
					<circle className="symbol" cx="-5" cy="50%" r="5"></circle>
					<line x1="0%" x2="100%" y1="50%" y2="50%"></line>
					<svg className="endcap" x="100%" y="50%">
						<path d="M-5 -5 0 0 -5 5"></path>
					</svg>
				</svg>
				<svg className="pointer right">
					<line className="dashed" x1="0%" x2="100%" y1="50%" y2="50%"></line>
					<svg className="endcap" x="0%" y="50%">
						<path d="M0 5 0 -5"></path>
					</svg>
					<rect className="symbol" x="100%" y="0" width="10" height="10"></rect>
				</svg>
			</div>
		</div>
		<div className="hudGraphic">
			<div className="foreground">
				<div className="border">
					<img src={ banner.content } />
					<span className="icon infoSmall"></span>
				</div>
				<a className="button" onClick={ (e) => actions.browseTo(banner.url) } ></a>
			</div>
		</div>
	</div>;
};

const PlaybookAnimation = ({}) => {
	return <div className="animDiagram">
		<svg className="animPlay" viewBox="0 0 220 280">
			<path className="endcap" d="M100 162 110 152 120 162"></path>
			<path className="routeLine1" d="M110 162 83 142 83 32"></path>
			<path className="routeLine2" d="M110 162 83 142 83 98 178 34"></path>
			<path className="routeLine3" d="M110 162 83 142 83 98 20 98"></path>
			<g className="positionDefense"><path d="M100 114 120 134"></path><path d="M120 114 100 134"></path></g>
			<circle className="positionOffenseStart" cx="110" cy="162" r="4"></circle>
			<circle className="positionOffense" cx="110" cy="162" r="12"></circle>
			<g className="ball"><path transform="translate(85,160)" d="M18.82,5.18c-6.6-6.6-16-5.6-17.63-4s-2.61,11,4,17.62,16,5.6,17.62,4S25.43,11.78,18.82,5.18Z"/><path transform="translate(85,160)" d="M16,16,8,8"/></g>
		</svg>
	</div>;
};

const Tiles = ({ currentTeam, goToPlaybooks, goToPlays, goToTeamCenter, onRedeemClick, onSponsorClick, onUpgradeClick, teamColor }) => {
	const { currentSubscription, sponsorLogoUrl, teamPermissions, playbooks, hasPlaybooksPage } = getCurrentContext();
	let onPlaybookClick;
	let playbookLabel;

	if (hasPlaybooksPage) {
		onPlaybookClick = goToPlaybooks;
		playbookLabel = _s(StringKey.PLAYBOOKS);
	} else if (playbooks && playbooks.length) {
		onPlaybookClick = goToPlays.bind(this, playbooks[0]);
		playbookLabel = _s(StringKey.PLAYBOOK);
	}

	return <div className="hud">
		<InfoBanner teamPermissions={ teamPermissions } />

		<div className="anim animField">
			<div className="anim animFieldLines">
				<svg className="line group8">
					<line x1="0%" x2="100%" y1="50%" y2="50%"></line>
				</svg>
				<svg className="line group7">
					<line x1="0%" x2="100%" y1="50%" y2="50%"></line>
				</svg>
				<svg className="line group6">
					<line x1="0%" x2="100%" y1="50%" y2="50%"></line>
					<svg className="numbers" y="10px">
						<svg x="0"><g><text>G</text></g></svg>
						<svg x="100%"></svg>
					</svg>
				</svg>
				<svg className="line group5">
					<line x1="0%" x2="100%" y1="50%" y2="50%"></line>
				</svg>
				<svg className="line group4">
					<line x1="0%" x2="100%" y1="50%" y2="50%"></line>
					<svg className="numbers">
						<svg x="0" y="2px"><g><text>1 &nbsp; 0</text><polygon className="up" points="0,0 -5,0 -2.5,-10"/></g></svg>
						<svg x="100%"></svg>
					</svg>
				</svg>
				<svg className="line group3">
					<line x1="0%" x2="100%" y1="50%" y2="50%"></line>
				</svg>
				<svg className="line group2">
					<line x1="0%" x2="100%" y1="50%" y2="50%"></line>
					<svg className="numbers">
						<svg x="0"></svg>
						<svg x="100%"><g><text>2 &nbsp; 0</text><polygon className="up" points="0,0 -5,0 -2.5,-10"/></g></svg>
					</svg>
				</svg>
				<svg className="line middle">
					<line x1="0%" x2="100%" y1="50%" y2="50%"></line>
					<svg className="numbers withTeamName">
						<svg x="0"><g><text className="teamName">{ currentTeam.name }</text></g></svg>
						<svg x="100%"></svg>
					</svg>
				</svg>
				<svg className="line group2">
					<line x1="0%" x2="100%" y1="50%" y2="50%"></line>
					<svg className="numbers withTeamName">
						<svg x="0"></svg>
						<svg x="100%"></svg>
					</svg>
				</svg>
				<svg className="line group3">
					<line x1="0%" x2="100%" y1="50%" y2="50%"></line>
				</svg>
				<svg className="line group4">
					<line x1="0%" x2="100%" y1="50%" y2="50%"></line>
					<svg className="numbers">
						<svg x="0"></svg>
						<svg x="100%"><g><text>4 &nbsp; 0</text><polygon className="down" points="0,0 -5,0 -2.5,10"/></g></svg>
					</svg>
				</svg>
				<svg className="line group5">
					<line x1="0%" x2="100%" y1="50%" y2="50%"></line>
				</svg>
				<svg className="line group6">
					<line x1="0%" x2="100%" y1="50%" y2="50%"></line>
					<svg className="numbers">
						<svg x="0"><g><text>5 &nbsp; 0</text></g></svg>
						<svg x="100%"></svg>
					</svg>
				</svg>
				<svg className="line group7">
					<line x1="0%" x2="100%" y1="50%" y2="50%"></line>
				</svg>
				<svg className="line group8">
					<line x1="0%" x2="100%" y1="50%" y2="50%"></line>
				</svg>
			</div>
			<div className="anim animHash middle">
				<div className="hashes">
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
				</div>
				<div className="hashes">
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
				</div>
			</div>
			<div className="anim animHash outside">
				<div className="hashes left">
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
				</div>
				<div className="hashes right">
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
					<div className="hash"></div>
				</div>
			</div>
		</div>

		<div className="anim animSides">
			<SponsorTile onSponsorClick={ onSponsorClick } onUpgradeClick={ onUpgradeClick } teamColor={ teamColor } />
			<UpgradeTile onUpgradeClick={ onUpgradeClick } teamColor={ teamColor } />

			{ teamPermissions.canManageMembers ?
				<div className="anim side right">
					<div className="hudGraphic circle">
						<div className="foreground">
							<svg className="border" viewBox="0 0 100 100">
								<svg>
									<svg className="bracket">
										<g className="base">
											<circle cx="50" cy="50" r="70"></circle>
										</g>
										<g className="rotating">
											<circle cx="50" cy="50" r="70"></circle>
											<g className="deltas" width="140" height="140">
												<svg x="50" y="-32">
													<path transform="rotate(180)" d="M.51-3.72,4.65,3.1a.59.59,0,0,1-.2.81A.56.56,0,0,1,4.14,4H-4.14a.59.59,0,0,1-.6-.59.56.56,0,0,1,.09-.31L-.51-3.72a.61.61,0,0,1,.82-.19A.61.61,0,0,1,.51-3.72Z"/>
												</svg>
												<svg x="50" y="132">
													<path transform="translate(0,132%)" d="M.51-3.72,4.65,3.1a.59.59,0,0,1-.2.81A.56.56,0,0,1,4.14,4H-4.14a.59.59,0,0,1-.6-.59.56.56,0,0,1,.09-.31L-.51-3.72a.61.61,0,0,1,.82-.19A.61.61,0,0,1,.51-3.72Z"/>
												</svg>
											</g>
										</g>
									</svg>
								</svg>
								<circle cx="50" cy="50" r="50" style={{ fill: teamColor, stroke: teamColor }}/>
							</svg>
							<span className="icon teamCenter"></span>
							<div className="label"><span className="title">{ _s(StringKey.TEAM) }</span></div>
							<a className="button" onClick={ goToTeamCenter }></a>
						</div>
					</div>
					<div className="hudGraphic rectangle code">
						<div className="foreground">
							<div className="border">
								<span className="icon">
									<svg>
										<g transform="translate(5,5)">
											<path style={{ stroke: teamColor }} d="M3.41-3.41c-3.3-3.3-8-2.8-8.81-2s-1.31,5.51,2,8.81,8,2.8,8.81,2S6.71-.11,3.41-3.41Z"/><path style={{ stroke: teamColor }} d="M2,2-2-2"/>
										</g>
									</svg>
								</span>
								<div className="label"><span className="title">{ _s(StringKey.HAVE_A_CODE) }</span></div>
							</div>
							<a className="button" onClick={ onRedeemClick }></a>
						</div>
					</div>
				</div> : null
			}
		</div>

		<div className="animCenter">

			<div className="anim">
				<div className="brackets">
					<div className="borderBase"></div>
					<div className="bracket">
						<div></div>
					</div>
					<div className="bracket">
						<div></div>
					</div>
				</div>
				<div className="logo">
					<div className="type playmaker"></div>
					<svg className="mark"><path className="markColor" d="M1.29,5.7a.64.64,0,0,0,.63-.64V2.16L17.76,18a.59.59,0,0,0,.44.19.6.6,0,0,0,.45-.19.62.62,0,0,0,0-.89L2.82,1.27h2.9A.64.64,0,0,0,6.35.63.63.63,0,0,0,5.72,0H1.29a.69.69,0,0,0-.24.05A.6.6,0,0,0,.71.39.52.52,0,0,0,.67.6a0,0,0,0,0,0,0V5.06A.64.64,0,0,0,1.29,5.7Z"></path><path className="markColor" d="M13.39,8.59a.59.59,0,0,0,.44-.19l6.25-6.24v2.9a.63.63,0,1,0,1.26,0V.63a0,0,0,0,0,0,0,.52.52,0,0,0,0-.21A.6.6,0,0,0,21,.05a.69.69,0,0,0-.24,0H16.28a.63.63,0,0,0-.63.63.64.64,0,0,0,.63.64h2.9L12.94,7.51a.62.62,0,0,0,0,.89A.6.6,0,0,0,13.39,8.59Z"></path><path className="markColor" d="M8.11,12.34,3.35,17.1a.62.62,0,0,0,0,.89.6.6,0,0,0,.45.19A.59.59,0,0,0,4.24,18L9,13.24a.63.63,0,0,0,0-.9A.62.62,0,0,0,8.11,12.34Z"></path><path className="markColor" d="M7,16.91a.64.64,0,0,0-.63.64A2.54,2.54,0,1,1,3.8,15a.63.63,0,0,0,0-1.26,3.8,3.8,0,1,0,3.79,3.8A.64.64,0,0,0,7,16.91Z"></path><path className="markColor" d="M18.2,13.75a.63.63,0,0,0,0,1.26,2.54,2.54,0,1,1-2.53,2.54.63.63,0,1,0-1.26,0,3.8,3.8,0,1,0,3.79-3.8Z"></path></svg>
					<div className="type flag"></div>
					<div className="type tackle"></div>
				</div>
			</div>

			<div className="hudGraphic playbook">
				<div className="foreground">
					{ currentTeam.settings.logoUrl ? <span className="icon photo"><CachedImage key={ currentTeam.settings.logoUrl } src={ currentTeam.settings.logoUrl } /></span> :  <PlaybookAnimation /> }
					<svg className="border">
						<rect width="100%" height="100%"  rx="3" ry="3" style={{ fill: teamColor, stroke: teamColor }} />
					</svg>
					<div className="label">
						<span className="subtitle" style={{ color: teamColor }}>{ currentTeam.name }</span>
						<span className="title">{ _s(StringKey.PLAYBOOKS) }</span>
					</div>
					<a className="button" onClick={ onPlaybookClick }></a>
				</div>
			</div>

		</div>

	</div>;
};

const FallbackAccessDenied = () => {
	return <div className="fallback"><div className="icon lock"></div><h2>{ _s(StringKey.ACCESS_DENIED_TEAM_TITLE) }</h2><p>{ _s(StringKey.ACCESS_DENIED_TEAM_DESCRIPTION) }</p></div>;
};

const FallbackNoTeams = () => {
	return <div className="fallback">
		<h2>{ _s(StringKey.NO_TEAMS_TITLE) }</h2>
		<p>{ _s(StringKey.NO_TEAMS_DESCRIPTION) }</p>
		<p>
			<a className="button basic dark" onClick={ (e) => viewManager.reloadApp(e) }><span className="icon tryAgain"></span><span>{ _s(StringKey.TRY_AGAIN) }</span></a>
		</p>
		<p className="deemphasized">
			{ _s(StringKey.STILL_HAVING_TROUBLE) } &nbsp; <a className="link underlined" href={`mailto:support@${window.location.hostname.replace('www.', '')}` }>{ _s(StringKey.CONTACT_SUPPORT) }</a>
		</p>
	</div>;
};

const FallbackNoCurrentTeam = () => {
	return <div className="fallback"><h2>{ _s(StringKey.SELECT_TEAM) }</h2></div>;
};

const TeamList = ({ currentTeam, teams}: { currentTeam: ITeam, teams: ITeam[] }) => {

	return <a className="teams" onClick={ actions.toggleMainMenu }>
		{ map(teams, (team) => {
			const teamColor = `#${team.settings.color}`;

			return <span key={ team.id } className={ currentTeam && team.id === currentTeam.id ? 'team on' : 'team' }>
				<span className="icon photo team" style={{ backgroundColor: teamColor }}>
					<CachedImage key={ team.settings.logoUrl || 'empty' } src={ team.settings.logoUrl } />
					<span>{ team.abbreviation }</span>
				</span>
			</span>;
		})
		}
		<strong>{ _s(StringKey.TEAMS) }</strong>
		<svg className="cover">
			<defs>
				<linearGradient id="dashboardGradTeamCover" x1="0%" y1="0%" x2="0%" y2="100%">
					<stop offset="0%" style={{ stopColor: 'rgb(38, 47, 56)', stopOpacity: 0 }}></stop>
					<stop offset="100%" style={{ stopColor: 'rgb(38, 47, 56)', stopOpacity: 1 }}></stop>
				</linearGradient>
			</defs>
			<rect width="100%" height="100%" fill="url(#dashboardGradTeamCover)"></rect>
		</svg>
	</a>;
};

interface Props {
	alerts?: store.IAlert[];
	appState: store.IAppState;
}

interface State {
}

export class Dashboard extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);

		this.goToPlaybooks = this.goToPlaybooks.bind(this);
		this.goToTeamCenter = this.goToTeamCenter.bind(this);
	}

	public goToPlays(playbook: IPlaybook, e) {
		viewManager.pushPath(`/playbook/${playbook.id}`);
	}

	public goToPlaybooks(e) {
		viewManager.pushPath('/playbook/');
	}

	public goToTeamCenter(e) {
		viewManager.pushPath(`/team-center`);
	}

	public onUpgradeClick(e) {
		const { currentTeam } = getCurrentContext();
		viewManager.pushModal({
			component: BillingModal,
			props: () => ({ appState: store.appState(), teamId: currentTeam.id }),
			screenName: 'Billing',
		});
	}

	public onSponsorClick(e) {
		const { currentSubscription, sponsorLogoUrl } = getCurrentContext();

		if (currentSubscription.settings && currentSubscription.settings.sponsorTribute) {
			viewManager.pushModal({
				id: 'sponsor_modal',
				component: SponsorModal,
				props: { message: currentSubscription.settings.sponsorMessage, tribute: currentSubscription.settings.sponsorTribute, logoUrl: sponsorLogoUrl, actions: currentSubscription.settings.sponsorActions },
				screenName: 'Sponsor',
			});
		}
	}

	public onRedeemClick(e) {
		actions.pushModal({
			component: Redeem,
			props: () => ({ viewState: store.appState().viewState, classNames: ['compact'] }),
		});
	}

	public render() {
		const { alerts= [], appState } = this.props;
		const { model, viewState } = appState;
		const { variant } = viewState.config;
		const { currentSubscription, currentTeam, teamPermissions }  = getCurrentContext();
		const teams = sortBy(model.teams, ['name']);
		const accessDenied = currentTeam && (!teamPermissions.canView || !currentSubscription || (!currentSubscription.isActive() && !teamPermissions.canManageBilling));
		const teamColor = currentTeam && `#${currentTeam.settings.color}`;

		return <div className="content">
			<MainMenu appState={ appState } />
			<div className="view">
				<svg className="teamBackground">
					<defs>
						<linearGradient id="grad1" x1="0%" y1="100%" x2="50%" y2="60%">
							<stop offset="0%" style={{ stopColor: teamColor, stopOpacity: 0.8 }} />
							<stop offset="100%" style={{ stopColor: teamColor, stopOpacity: 0 }} />
						</linearGradient>
					</defs>
					<rect width="100%" height="100%" fill="url(#grad1)" />
				</svg>
				<header>
					<div className="actions">
						<a className="button" onClick={ actions.toggleMainMenu }><span className={ viewState.mainMenu.expanded ? 'icon close' : 'icon menu' }></span></a>
					</div>
					<div className="logo">
						<div className="type playmaker"></div>
						<svg className="mark">
							<path className="markColor" style={{ fill: teamColor }} d="M1.29,5.7a.64.64,0,0,0,.63-.64V2.16L17.76,18a.59.59,0,0,0,.44.19.6.6,0,0,0,.45-.19.62.62,0,0,0,0-.89L2.82,1.27h2.9A.64.64,0,0,0,6.35.63.63.63,0,0,0,5.72,0H1.29a.69.69,0,0,0-.24.05A.6.6,0,0,0,.71.39.52.52,0,0,0,.67.6a0,0,0,0,0,0,0V5.06A.64.64,0,0,0,1.29,5.7Z"/>
							<path className="markColor" style={{ fill: teamColor }} d="M13.39,8.59a.59.59,0,0,0,.44-.19l6.25-6.24v2.9a.63.63,0,1,0,1.26,0V.63a0,0,0,0,0,0,0,.52.52,0,0,0,0-.21A.6.6,0,0,0,21,.05a.69.69,0,0,0-.24,0H16.28a.63.63,0,0,0-.63.63.64.64,0,0,0,.63.64h2.9L12.94,7.51a.62.62,0,0,0,0,.89A.6.6,0,0,0,13.39,8.59Z"/>
							<path className="markColor" style={{ fill: teamColor }} d="M8.11,12.34,3.35,17.1a.62.62,0,0,0,0,.89.6.6,0,0,0,.45.19A.59.59,0,0,0,4.24,18L9,13.24a.63.63,0,0,0,0-.9A.62.62,0,0,0,8.11,12.34Z"/>
							<path className="markColor" style={{ fill: teamColor }} d="M7,16.91a.64.64,0,0,0-.63.64A2.54,2.54,0,1,1,3.8,15a.63.63,0,0,0,0-1.26,3.8,3.8,0,1,0,3.79,3.8A.64.64,0,0,0,7,16.91Z"/>
							<path className="markColor" style={{ fill: teamColor }} d="M18.2,13.75a.63.63,0,0,0,0,1.26,2.54,2.54,0,1,1-2.53,2.54.63.63,0,1,0-1.26,0,3.8,3.8,0,1,0,3.79-3.8Z"/>
						</svg>
						<div className={ `type ${variant}`}></div>
					</div>
					<div className="actions">
						<ConnectionStatus />
					</div>
				</header>
				<AlertList alerts={ alerts } />
				<div className="content">
					{
						teams.length ? <TeamList currentTeam={ currentTeam } teams={ teams } /> : <FallbackNoTeams />
					}
					{
						currentTeam && accessDenied ? <FallbackAccessDenied /> : null
					}
					{
						currentTeam && !accessDenied ? <Tiles currentTeam={ currentTeam } goToPlaybooks={ this.goToPlaybooks } goToPlays={ this.goToPlays } goToTeamCenter={ this.goToTeamCenter } onRedeemClick={ this.onRedeemClick } onSponsorClick={ this.onSponsorClick } onUpgradeClick={ this.onUpgradeClick } teamColor={ teamColor } /> : null
					}
					{
						!currentTeam && teams.length ? <FallbackNoCurrentTeam /> : null
					}
				</div>
			</div>
		</div>;
	}
}

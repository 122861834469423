import { assign } from 'lodash';
import { default as valueFilters } from 'playmaker-team-common/dist/shared/valueFilters';
import { default as coreModel, ICoreModel } from './coreModel';

export interface IRootModel extends ICoreModel {
	createdById: string;
	dateCreated: Date;
	dateLastModified: Date;
	dateLastMutated: Date;
	deleted: boolean;
	itemVersion: number;
	lastMutatorId: string;
	lastModifiedById: string;
}

export default assign({}, coreModel, {
	createdById: {
	},
	dateCreated: {
		setFilters: valueFilters.toDate,
	},
	lastModifiedById: {
	},
	dateLastModified: {
		setFilters: valueFilters.toDate,
	},
	deleted: {
		setFitlers: valueFilters.toBoolean,
		defaultValue: false,
	},
	itemVersion: {
		setFilters: valueFilters.toNumber,
		defaultValue: 0,
	},
	dateLastMutated: {
		writeOnly: true,
		nonSerialized: true,
	},
	_get_dateLastMutated() {
		return this.dateLastModified || this.dateCreated;
	},
	lastMutatorId: {
		writeOnly: true,
		nonSerialized: true,
	},
	_get_lastMutatorId() {
		return this.lastModifiedById || this.createdById;
	},
});

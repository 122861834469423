import { assign, filter, find, reduce } from 'lodash';
import * as dateHelper from 'playmaker-team-common/dist/shared/dateHelper';
import { IModel, StringDictionary } from 'playmaker-team-common/dist/shared/interfaces';
import { createFactory } from 'playmaker-team-common/dist/shared/modelFactory';
import { ModelSet } from 'playmaker-team-common/dist/shared/modelSet';
import { default as validator } from 'playmaker-team-common/dist/shared/validator';
import { default as valueFilters } from 'playmaker-team-common/dist/shared/valueFilters';
import { default as rootModel, IRootModel } from './rootModel';
import { ISubscription } from './subscription';
import { default as subscriptionPlanUpgradeFactory, ISubscriptionPlanUpgrade } from './subscriptionPlanUpgrade';

export interface ICorporateTroll {
	name: string;
	fee: number;
	productId: string;
}

export const enum SubscriptionPlanFrequency {
	year,
	month,
	week,
	day,
	fixed,
}

export const enum SystemFeature {
	print = 'print-standard',
	collaboration = 'collaboration',
	backup = 'backup',
}

export interface IIapProduct {
	id: string;
	countryCode: string;
	currrencyCode: string;
	currencySymbol: string;
	price: number;
	priceDisplay: string;
}

export interface ISubscriptionPlan extends IRootModel {
	key: string;
	name: string;
	description: string;
	features: string;
	startDate: Date;
	endDate: Date;
	iapProduct?: IIapProduct;
	nonRenewable: boolean;
	price: number;
	frequencyCount: number;
	frequencyUnit: SubscriptionPlanFrequency;
	settings?: StringDictionary;
	upgrades: ModelSet<IModel & ISubscriptionPlanUpgrade>;
	featureSort: number;
	isFreeTrial: () => boolean;
	isFixedDuration: () => boolean; // sponsored, promo, product purchase
	hasFeature: (feature: SystemFeature) => boolean;
	getProratedUpgradePrice: (upgrade: ISubscriptionPlanUpgrade, subscription?: ISubscription) => number;
	getIapTroll: () => ICorporateTroll;
}

export default createFactory<ISubscriptionPlan>('SubscriptionPlan', assign({}, rootModel, {
	key: {
		setFilters: valueFilters.clean,
	},
	name: {
		setFilters: valueFilters.clean,
	},
	description: {
		setFilters: valueFilters.clean,
	},
	features: {
		setFilters: valueFilters.clean,
		defaultValue: '',
	},
	startDate: {
		setFilters: valueFilters.toDate,
	},
	endDate: {
		setFilters: valueFilters.toDate,
	},
	iapProduct: {
	},
	nonRenewable: {
		setFilters: valueFilters.toBoolean,
		defaultValue: false,
	},
	price: {
		setFilters: valueFilters.toNumber,
		rules: validator.inRange(0, Number.MAX_VALUE),
		defaultValue: 0,
	},
	frequencyUnit: {
		setFilters: valueFilters.toNumber,
		rules: validator.inRange(1, Number.MAX_VALUE),
	},
	frequencyCount: {
		setFilters: valueFilters.toNumber,
		rules: validator.inList([SubscriptionPlanFrequency.year, SubscriptionPlanFrequency.month, SubscriptionPlanFrequency.week, SubscriptionPlanFrequency.day, SubscriptionPlanFrequency.fixed]),
	},
	settings: {
	},
	upgrades: {
		setFilters: valueFilters.toModelSet<ISubscriptionPlanUpgrade>(subscriptionPlanUpgradeFactory),
		rules: validator.validModel(subscriptionPlanUpgradeFactory.rules),
		defaultValue: {},
	},
	featureSort: {
		nonSerialized: true,
		writeable: false,
	},
	_get_featureSort() {
		if (this.hasFeature(SystemFeature.collaboration)) {
			return 3;
		}
		if (this.hasFeature(SystemFeature.print)) {
			return 2;
		}

		return 1;
	},
	isFreeTrial() {
		return !!(this.settings && this.settings.isFreeTrial);
	},
	isFixedDuration() {
		return !!(this.settings && this.settings.isFixedDuration);
	},
	hasFeature(feature: string) {
		const featureList = this.features.split(',');

		return featureList.indexOf(feature) !== -1;
	},
	getProratedUpgradePrice(upgrade: ISubscriptionPlanUpgrade, subscription: ISubscription = null) {
		if (!this.upgrades[upgrade.id]) {
			throw new Error('Invalid upgrade');
		}
		const frequencyCountFactor = subscription.frequencyCount / this.frequencyCount;
		let price = upgrade.price * frequencyCountFactor;

		if (subscription) {
			price = reduce(filter(this.upgrades.values, (u) => !!find(subscription.upgrades.values, { subscriptionPlanUpgradeId: u.id })), (total: number, u: ISubscriptionPlanUpgrade) => {
				return total - (u.price * frequencyCountFactor);
			}, price);
		}

		if (this.startDate && this.endDate) {
			const initialDays = dateHelper.diffDays(this.endDate, this.startDate);
			const remainingDays = dateHelper.diffDays(this.endDate, dateHelper.today());

			return Math.ceil((remainingDays / initialDays) * price);
		} else if (subscription && subscription.startDate && subscription.expirationDate) {
			const initialDays = dateHelper.diffDays(subscription.expirationDate, subscription.startDate);
			const remainingDays = dateHelper.diffDays(subscription.expirationDate, dateHelper.today());

			return Math.ceil((remainingDays / initialDays) * price);
		}

		return price;
	},
	getIapTroll() {
		return this.settings && this.settings.iapTroll;
	},
}));

import { map, sortBy } from 'lodash';
import * as React from 'react';
import { current as getCurrentContext } from '../componentContext';
import { IFormation } from '../models/formation';
import { IDragData, IDragSpec } from '../store';
import * as viewManager from '../viewManager';
import { DiagramControl } from './diagramControl';
import { DragDrop, IDragSourceParams, IDropTargetParams } from './dnd';
import { FormationsContext } from './formationsProvider';
import { StringKey, _s } from '../strings';


function FormationThumbnail({ 
	allowSort,
	className = 'tile formation', 
	formation, 
	formationCount,
	onInfoClick, 
	onPointerDown, 
	onTouchStart, 
	onMouseDown,
	onDrop,
	addDropTarget,
	removeDropTarget
}: 
{
	allowSort: boolean,
	className:string, 
	formation: IFormation, 
	formationCount: number,
	onInfoClick?: any, 
	onPointerDown?: any, 
	onTouchStart?: any, 
	onMouseDown?: any,
	onDrop?: any,
	addDropTarget?: any,
	removeDropTarget?: any
}) {
	const { variant } = getCurrentContext();
	const _dropTarget = React.useRef<HTMLElement>();
	const classNames = className? [className]: [];
	const canDrop = React.useCallback((data: IDragData) => {
		const result = data.type === 'thumbnail' && data.info.formation && data.info.formation.id !== formation.id;
		return result;
	}, [formation]);

	const handleDrop = React.useCallback((data: IDragData) => {
		if (onDrop) {
			onDrop(data.info.formation.id, formation.id);
		}
	}, [formation, onDrop])

	const setDropTarget = React.useCallback((el) => {
		if (!addDropTarget) {
			return;
		}

		if (_dropTarget.current) {
			removeDropTarget(_dropTarget.current);
		}

		_dropTarget.current = el;

		if (_dropTarget.current) {
			addDropTarget(_dropTarget.current, canDrop, handleDrop);
		}
	}, [addDropTarget, removeDropTarget]);

	if(formation?.color) {
		classNames.push(`color${formation.color}`);
	}

	return <div className={ classNames.join(' ') } ref={ setDropTarget } data-index={ formation.sortIndex }>
		<div className="inner">
			<a className="button" onClick={ () => viewManager.pushPath(`/playbook/${formation.playbookId}/formations/${formation.phase}/${formation.id}`) }></a>
			<div className="thumbnail">
				<DiagramControl ballLocation={ formation.ballLocation } diagram={ formation } lineOfScrimage={ 0.5 } />
			</div>
			<div className="label">{ variant === 'flag'? _s(StringKey.EDIT_TEMPLATE):  formation.label }</div>
			<div className="actions">
				{ variant !== 'flag'? <a className="button"><span className="badge">{ formationCount }</span></a>: null }
				{ allowSort? <a className="button" onPointerDown={ onPointerDown } onTouchStart={ onTouchStart } onMouseDown={ onMouseDown } style={{ touchAction: 'none' }} ><span className="icon sortItem" ></span></a>: null }
				{ variant !== 'flag'? <a className="button" onClick={ onInfoClick } id={ `formation-info.${formation.id}` }><span className="icon infoSmall"></span></a>: null }
			</div>
		</div>
	</div>;
}

function onFormationSaved(formation:IFormation, isNew: boolean) {
	if(isNew) {
		viewManager.pushPath(`/playbook/${formation.playbookId}/formations/${formation.phase}/${formation.id}`);
	}
}

export function FormationGallery() {
	const { addLabel, openInfo, openAddModal, handleFormationDrop, currentFormations, disableAdd } = React.useContext(FormationsContext);
	const { currentUser, playbookPermissions, variant } = getCurrentContext();
	

	const handleInfoClick = React.useCallback((e) => {
		const parts = e.currentTarget.id.split('.');
		const id = parts[1];

		openInfo(id);
	}, [openInfo]);

	let index = 0;
	const thumbnails = map(sortBy(currentFormations, 'sortIndex'), (formation) => {
		const formationCount = ++index;
		const lastViewed = false;

		const dragSpec: IDragSpec = {
			containerClassName: (currentUser.profile.backgroundMode === 'light') ? 'light' : '',
			component: () => <FormationThumbnail className={ `tile dragging ${formation.getClassName()}` } formation={ formation } formationCount={ formationCount } allowSort={ true } />,
			getDragLayout: (touch, currentTarget) => {
				const thumbnailElement = currentTarget.parentElement.parentElement.parentElement;
				const thumnailRect = thumbnailElement.getBoundingClientRect();
				const targetRect = currentTarget.getBoundingClientRect();

				return { x: thumnailRect.width * 0.4, y: targetRect.height * 0.4, width: thumnailRect.width * 0.8, height: thumnailRect.height * 0.8, scrollContainer: thumbnailElement.parentElement.parentElement };
			},
			data: {
				type: 'thumbnail',
				info: {
					formation
				},
			},
		};

		return <DragDrop key={ `${formation.id}` }>{
			(params: IDragSourceParams & IDropTargetParams) => {
				const { activeDragSpec, handleMouseDown, handlePointerDown, handleTouchStart, ...dragProps } = params;
				const classNames = lastViewed ? ['tile selected', formation.getClassName()] : ['tile', formation.getClassName()];

				if (activeDragSpec && activeDragSpec.data.info.formation !== formation) {
					classNames.push('dropTarget');
					classNames.push(activeDragSpec.data.info.formation.sortIndex < formation.sortIndex ? 'bottom' : 'top');
				}

				return <FormationThumbnail
					key={ `${formation.id}` }
					allowSort={ playbookPermissions?.canUpdate && variant !== 'flag' }
					className={ classNames.join(' ') }
					onInfoClick={ handleInfoClick }
					formation={ formation }
					formationCount={ formationCount }
					onDrop={ handleFormationDrop }
					onPointerDown={ handlePointerDown && handlePointerDown.bind(null, dragSpec) }
					onTouchStart={ handleTouchStart && handleTouchStart.bind(null, dragSpec) }
					onMouseDown={ handleMouseDown && handleMouseDown.bind(null, dragSpec) }
					{...dragProps } />;
			}
		}</DragDrop>;

	});

	return <div className="content scrollable">
		<div className="formations">

			{ !disableAdd ? <div className="tile formation add">
				<div className="inner">
					<a className="button" onClick={ () => openAddModal(onFormationSaved)}></a>
					<span className="icon addFormation"></span>
					<div className="label">{ addLabel }</div>
				</div>
			</div> : null }
			{ thumbnails }
		</div>
	</div>;
}
import { assign } from 'lodash';
import { IModel } from 'playmaker-team-common/dist/shared/interfaces';
import { createFactory } from 'playmaker-team-common/dist/shared/modelFactory';
import { default as validator } from 'playmaker-team-common/dist/shared/validator';
import { default as valueFilters } from 'playmaker-team-common/dist/shared/valueFilters';
import { default as rootModel, IRootModel } from './rootModel';

export enum TeamRole {
	None,
	Player,
	Staff,
	Owner,
}

export interface ITeamMember extends IRootModel {
	avatarUrl?: string;
	email: string;
	firstName: string;
	fullName: string;
	initials: string;
	inviteCode?: string;
	jerseyNumber?: string;
	lastName?: string;
	notSaved?: boolean;
	position?: string;
	role: TeamRole;
	teamId: string;
	userId?: string;
}

export default createFactory<ITeamMember>('TeamMember', assign({}, rootModel, {
	avatarUrl: {
		setFilters: valueFilters.clean,
	},
	email: {
		setFilters: valueFilters.clean,
		rules: [(val, target) => {
			const testVal = val || undefined;
			if (!validator.isEmail(testVal, target.role !== TeamRole.Owner)) {
				return 'Please enter a valid email address';
			}
		}],
	},
	firstName: {
		setFilters: valueFilters.toString,
		rules: [validator.required()],
	},
	inviteCode: {
		setFilters: valueFilters.clean,
	},
	jerseyNumber: { // only set for players, not staff\
	},
	lastName: {
		setFilters: valueFilters.toString,
	},
	notSaved: {
		setFilters: valueFilters.toBoolean,
		defaultValue: true,
	},
	position: { // title or position
	},
	role: { // admin, player, staff
		setFilters: valueFilters.toNumber,
		rules: validator.inList([TeamRole.Player, TeamRole.Staff, TeamRole.Owner]),
	},
	teamId: {
		setFilters: valueFilters.clean,
		rules: validator.required(),
	},
	userId: { // only set when accepted
		setFilters: valueFilters.clean,
	},
	fullName: {
		readOnly: true,
		nonSerialized: true,
	},
	_get_fullName() {
		return [this.firstName, (this.lastName || '')].join(' ').trim();
	},
	initials: {
		readOnly: true,
		nonSerialized: true,
	},
	_get_initials() {
		return [this.firstName && this.firstName.substr(0, 1), this.lastName && this.lastName.substr(0, 1)].join('').trim().toUpperCase();
	},
	toViewDocument(ctx?: any) {
		const result = this.toDocument();

		if (ctx && ctx.permissions) {
			assign(result, { permissions: ctx.permissions });
		}

		return result;
	},
}));

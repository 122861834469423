import * as React from 'react';

export function Flag40x100({ teamLabel }) {
	return <div className="field">
		<svg viewBox="0 0 1024 2304">
			<svg className="markings" x="19.25%" y="16.66%" width="61.5%" height="66.67%">
				<svg className="boundary" x="-3%" y="-1%" width="106%" height="102%">
					<rect className="endline" x="0%" y="0%" width="100%" height="1%"></rect>
					<rect className="endline" x="0%" y="99%" width="100%" height="1%"></rect>
					<rect className="sideline" x="0%" y="0%" width="3%" height="100%"></rect>
					<rect className="sideline" x="97%" y="0%" width="3%" height="100%"></rect>
				</svg>
				<svg className="endzone" x="0%" y="0%" height="10%">
					<text x="48%" y="58%">{ teamLabel }</text>
					<line className="yardline" x1="0" x2="100%" y1="100%" y2="100%" />
				</svg>
				<svg className="tenYards" x="0%" y="10%" height="10%">
					<svg x="14%" y="11%">
						<text transform="rotate(90 0 0)">G</text>
					</svg>
					<svg x="86%" y="11%">
						<text transform="rotate(-90 0 0)">G</text>
					</svg>
					<line className="hash" x1="43.75%" x2="56.25%" y1="30%" y2="30%" />
					<line className="hash" x1="43.75%" x2="56.25%" y1="100%" y2="100%" />
				</svg>
				<svg className="tenYards" x="0%" y="20%" height="10%">
					<svg x="14%" y="3%">
						<polygon transform="translate(-12 -25)" points="0,0 -8,0 -4,-16" className="triangle" />
						<text transform="rotate(90 0 0)">1 0</text>
					</svg>
					<svg x="86%" y="-3%">
						<polygon transform="translate(20 -25)" points="0,0 -8,0 -4,-16" className="triangle" />
						<text transform="rotate(-90 0 0)">1 0</text>
					</svg>
					<line className="yardline" x1="0" x2="100%" y1="100%" y2="100%" />
				</svg>
				<svg className="tenYards" x="0%" y="30%" height="10%">
					<svg x="14%" y="0">
						<polygon transform="translate(-12 -28)" points="0,0 -8,0 -4,-16" className="triangle" />
						<text transform="rotate(90 0 0)">2 0</text>
					</svg>
					<svg x="86%" y="0">
						<polygon transform="translate(20 -28)" points="0,0 -8,0 -4,-16" className="triangle" />
						<text transform="rotate(-90 0 0)">2 0</text>
					</svg>
				</svg>
				<svg className="tenYards" x="0%" y="40%" height="10%">
					<line className="yardline" x1="0" x2="100%" y1="100%" y2="100%" />
				</svg>
				<svg className="tenYards" x="0%" y="50%" height="10%">
					<text x="0%" y="-56%" transform="rotate(90)">4 0</text>
					<text x="0%" y="354%" transform="rotate(-90)">4 0</text>
				</svg>
				<svg className="tenYards" x="0%" y="60%" height="10%">
					<line className="yardline" x1="0" x2="100%" y1="100%" y2="100%" />
				</svg>
				<svg className="tenYards" x="0%" y="70%" height="10%">
					<svg x="14%" y="0">
						<polygon transform="translate(-12 28)" points="0,0 -8,0 -4,16" className="triangle" />
						<text transform="rotate(90 0 0)">2 0</text>
					</svg>
					<svg x="86%" y="0">
						<polygon transform="translate(20 28)" points="0,0 -8,0 -4,16" className="triangle" />
						<text transform="rotate(-90 0 0)">2 0</text>
					</svg>
					<line className="yardline" x1="43.75%" x2="56.25%" y1="100%" y2="100%" />
				</svg>
				<svg className="tenYards" x="0%" y="80%" height="10%">
					<svg x="14%" y="-3%">
						<polygon transform="translate(-12 25)" points="0,0 -8,0 -4,16" className="triangle" />
						<text transform="rotate(90 0 0)">1 0</text>
					</svg>
					<svg x="86%" y="-3%">
						<polygon transform="translate(20 25)" points="0,0 -8,0 -4,16" className="triangle" />
						<text transform="rotate(-90 0 0)">1 0</text>
					</svg>
					<line className="hash" x1="43.75%" x2="56.25%" y1="70%" y2="70%" />
					<line className="yardline" x1="0" x2="100%" y1="100%" y2="100%" />
					<svg x="14%" y="89%">
						<text transform="rotate(90 0 0)">G</text>
					</svg>
					<svg x="86%" y="89%">
						<text transform="rotate(-90 0 0)">G</text>
					</svg>
				</svg>
				<svg className="endzone" x="0%" y="90%" height="10%">
					<text x="50%" y="58%">OPPONENT</text>
				</svg>
			</svg>
		</svg>
	</div>;
}

import { assign, filter, find, forEach, get, keyBy, map, mapValues, set, some, sortBy, unset } from 'lodash';
import { BlobType, IFetchBatch, IFetchSpecItemResult, IPatchOperation, ISaveBatchResult, ISaveSpec, ISaveSpecResult, PatchOpType, StringDictionary } from 'playmaker-team-common/dist/shared/interfaces';
import * as apiGateway from './apiGateway';
import * as clientStorage from './clientStorage';
import * as logger from './logger';
import { SpecialTeamsUnit } from './models/diagramModel';
import { default as formationFactory, IFormation } from './models/formation';
import { default as paymentMethodFactory, IPaymentMethod } from './models/paymentMethod';
import { default as playFactory, IPlay } from './models/play';
import { IPlaybook } from './models/playbook';
import { PlaybookAccess } from './models/playbookMember';
import { ISubscription } from './models/subscription';
import { default as subscriptionPlanFactory, ISubscriptionPlan, SystemFeature } from './models/subscriptionPlan';
import { default as teamFactory, ITeam } from './models/team';
import { ITeamMember, TeamRole } from './models/teamMember';
import { default as userFactory, IUser } from './models/user';
import { processPlaybookImport } from './playbookHelper';
import { ProcessQueue } from './processQueue';
import * as store from './store';
import { _s, StringKey } from './strings';
import { IPlayer } from './models/player';
import vectorFactory from './models/vector';
import { storeCachedImage } from './actions';
import { IAppSaveBatch, SaveBatchIntent } from './apiGateway';
import { AUTH_TOKEN_STORAGE_KEY, CURRENT_TEAM_ID_STORAGE_KEY, CURRENT_USER_ID_STORAGE_KEY, IMPERSONATOR_TOKEN_STORAGE_KEY, UNSYNCED_ENTITY_STORAGE_KEY } from './clientStorage';
import { ITag } from './models/tag';
import { IPlayList } from './models/playList';

const NULL_CALLBACK = () => null;

const ENTITY_FACTORIES = {
	team: teamFactory,
	user: userFactory,
	formation: formationFactory,
	play: playFactory,
};

const ENTITY_PERSISTORS = {
	team_update: saveTeam,
	user_update: saveUser,
	formation_create: addFormation,
	formation_update: saveFormation,
	formation_delete: deleteFormation,
	play_create: addPlay,
	play_update: savePlay,
	play_delete: deletePlay,
};

export interface IBlobSpec {
	type: BlobType;
	url: string;
	patchPath: string;
}

export type SaveCallback = (result: ISaveBatchResult) => void;

export interface IPaymentData extends IPaymentMethod {
	providerToken: string;
}

export interface ISubscriptionData {
	couponCode?: string;
	expectedPrice?: number;
	paymentData?: IPaymentData;
	subscriptionPlanId?: string;
	upgradeId?: string;
	teamId?: string;
	team?: ITeam;
	pause?: boolean;
	trollReceipt?: StringDictionary;
}

let _canCollaborate = false;
const _changeQueue = new ProcessQueue({ autoActive: true });

export function canCollaborate() {
	return _canCollaborate;
}

export async function resync(intent = 'resync') {
	let unsyncedEntities: store.IUnsyncedEntity[] = [];

	_changeQueue.pause(); // queue processing of new changes to reduce contention for the store

	const preSyncAppState = store.appState(); 

	// populate the entity values prior to refreshing from the server
	unsyncedEntities = map(preSyncAppState.viewState.unsyncedEntities, (value: store.IUnsyncedEntity) => {
		const unsyncedValue = !value.wasDeleted ? get(preSyncAppState, value.storeKey) : undefined;

		return assign({ unsyncedValue }, value);
	});

	await _resyncData();

	// clear unsynced entities from the store
	await store.mutateAsync(async (appState) => {
		appState.viewState.unsyncedEntities = {};
	});

	_changeQueue.resume();

	const newAppState = store.appState(); // get our post-refresh app state

	for (const entity of unsyncedEntities) {
		const action = entity.wasDeleted ? 'delete' : entity.wasCreated ? 'create' : 'update';
		const currentModel = get(newAppState, entity.storeKey);

		if (action === 'delete' && currentModel) { // this client deleted it offline and it wasn't deleted elsewhere: Delete it
			await ENTITY_PERSISTORS[`${entity.entityType}_delete`]({ data: currentModel, intent });
		} else if (entity.unsyncedValue) { // we have something to save
			if (action === 'create') { // if this client created: save it
				await ENTITY_PERSISTORS[`${entity.entityType}_create`]({ data: entity.unsyncedValue, intent });
			} else if (currentModel) { // this client updated it and it wasn't deleted elsewhere: save it
				await ENTITY_PERSISTORS[`${entity.entityType}_update`]({ data: entity.unsyncedValue, intent });
			}
		}
	}

	await _updateGatewayConnection(true);
}

apiGateway.on('team.change', (data: Record<string, unknown>) => {
	_changeQueue.enqueue(async () => {
		await store.mutateAsync(async (appState) => {
			for (const item of data.specs as ISaveSpec[]) {
				try {
					const spec = item as ISaveSpec;
					const entityType = spec.type;
					const action = spec.itemId ? !spec.patch ? 'delete' : 'update' : 'create';
					const entityId = action === 'create' ? spec.data.id : spec.itemId.id || spec.itemId;
					const entityMapKey = `${entityType}s`;
					const storageKey =  `model.${entityMapKey}.${entityId}`;
					let entity;
					let patchItemVersion;
					const entityMap = appState.model[entityMapKey] = assign({}, appState.model[entityMapKey]);

					switch (action) {
					case 'delete':
						unset(entityMap, entityId);
						await clientStorage.removeItem(storageKey);
						break;
					case 'create':
						entity = ENTITY_FACTORIES[entityType](spec.data);
						set(entityMap, entityId, entity);
						await clientStorage.setItem(storageKey, entity);
						break;
					case 'update':
						entity = ENTITY_FACTORIES[entityType](get(entityMap, entityId));
						patchItemVersion = find(spec.patch, { path: '/itemVersion' });

						if (patchItemVersion && patchItemVersion.value === entity.itemVersion + 1) {
							entity.applyPatch(spec.patch);
						} else {
							entity = await _fetchEntity(entityType, spec.itemId);
						}

						if (entity) {
							set(entityMap, entityId, entity);
							await clientStorage.setItem(storageKey, entity);
						}

						break;
					default:
						console.log('unsupported team change');
						break;
					}
				} catch (err) {
					// TODO: figure out what to do with this
				}
			}
		});
	}, NULL_CALLBACK);
});

export function init(appVersion: string, env: 'development' | 'production') {
	apiGateway.init({ appVersion, env });
}

async function _resyncData() {
	const authToken = await clientStorage.getItem(AUTH_TOKEN_STORAGE_KEY);

	await fetchConfig();
	fetchBanners(); // don't await this

	if (authToken) {
		// const appState = store.appState();

		// if ((!appState.viewState.currentUserId || !appState.model.users[appState.viewState.currentUserId])) {
		await fetchUser('me'); // this sets the current user as a side-effect
		// }

		await _fetchMyTeams();
		await _populateTeamData();
	}
}

export async function fetchUser(userId: string): Promise<IUser> {
	const userResponse = await apiGateway.fetchUser(userId) as any;

	if (userId === 'me' && userResponse.user) {
		await _setCurrentUser(userResponse.user);
	}

	return userResponse;
}

export async function login(email: string, password: string, inviteCode?: string) {
	const response = await apiGateway.login(email, password, inviteCode) as any;

	if (response && response.error) {
		logger.logEvent('login-failed', 'auth');
		await clientStorage.removeItem(AUTH_TOKEN_STORAGE_KEY);
		await _setCurrentUser(null);
	} else {
		logger.logEvent('login', 'auth');

		await onSuccessfulAuth(response.user, response.token, response.impersonatorToken);
	}

	return response;
}

async function onSuccessfulAuth(user: IUser, token: string, impersonatorToken?: string) {
	await _setCurrentUser(user, token, impersonatorToken);
	await _fetchMyTeams();

	const { model } = store.appState();
	const orderedTeams = sortBy(model.teams, ['name']);

	if (orderedTeams.length) {
		await setCurrentTeam(orderedTeams[0]);
	}
}

export async function logout() {
	await _setCurrentUser(null);
	await apiGateway.logOut();
	await apiGateway.disconnect();
	logger.logEvent('logout', 'auth');
}

export async function unimpersonate() {
	const token = await clientStorage.getItem(IMPERSONATOR_TOKEN_STORAGE_KEY);
	const { viewState } = store.appState();

	if (token && viewState.isImpersonating) {
		await logout();
		await clientStorage.setItem(AUTH_TOKEN_STORAGE_KEY, token);

		const response = await apiGateway.fetchUser('me') as any;

		if (response.user) {
			await onSuccessfulAuth(response.user, token);
		}
	}
}

export async function createAccount(user: IUser, team: ITeam, password: string, inviteCode?: string): Promise<store.IAlert[]> {
	const intent = 'user-create';
	const userDoc = user.toDocument();
	const avatarUrl = user.profile && user.profile.avatarUrl;
	const needsUpload = (avatarUrl && avatarUrl.indexOf('data:') === 0);
	const resultAlerts = [];
	let avatarUploadUrl;

	if (needsUpload) {
		userDoc.profile.avatarUrl = undefined;
	}

	const accountCreateResponse = await _save({ intent, specs: [{
		specId: 0,
		type: 'user',
		data: { user: userDoc, password, inviteCode },
	}],
	});
	let userSuccess = !accountCreateResponse.results[0].isError;

	if (!userSuccess) {
		const userCreateResult = accountCreateResponse.results[0];
		let handled = false;

		if (userCreateResult.messages.length === 1) {
			const message = userCreateResult.messages[0];
			let loginResponse;

			switch (message) {
			case 'DuplicateEmail':
				loginResponse = await login(user.email, password, inviteCode);

				if (loginResponse && loginResponse.error) {
					resultAlerts.push({ message: _s(StringKey.ACCOUNT_CREATION_FAILED_EXISTING_ACCOUNT_ALERT_MESSAGE), title: _s(StringKey.ACCOUNT_CREATION_FAILED_ALERT), severity: store.AlertSeverity.error });
				} else {
					userSuccess = true;
					avatarUploadUrl = needsUpload ? loginResponse.uploadUrl : undefined;
					try {
						if (loginResponse.user.firstName !== userDoc.firstName || loginResponse.user.lastName !== userDoc.lastName || loginResponse.user.emailOptIn !== userDoc.emailOptIn) {
							const updateDoc = userFactory(assign({}, loginResponse.user, { firstName: userDoc.firstName, lastName: userDoc.lastName, emailOptIn: userDoc.emailOptIn }));

							await saveUser({data: updateDoc, intent});
						}
					} catch (err) {
						logger.logError(err, false);
					}
				}

				handled = true;

				break;

			default:
				// does nothing
				break;
			}
		}

		if (!handled) {
			resultAlerts.push({ message: userCreateResult.messages.join(' &bull; '), title: _s(StringKey.ACCOUNT_CREATION_FAILED_ALERT), severity: store.AlertSeverity.error });
		}
	} else {
		const idOp = find(accountCreateResponse.results[0].patch, { path: '/id' });

		avatarUploadUrl = accountCreateResponse.results[0].uploadUrls[idOp.value];
	}

	if (userSuccess) {
		// we may have logged in to an existing account above, if not log in to set the currentUserId
		if (!store.appState().viewState.currentUserId) {
			const loginResponse = await login(user.email, password);
			// this *really* shouldn't happen
			if (loginResponse && loginResponse.error) {
				resultAlerts.push({ message: _s(StringKey.ACCOUNT_CREATION_FAILED_ALERT_MESSAGE), title: _s(StringKey.ACCOUNT_CREATION_FAILED_ALERT), severity: store.AlertSeverity.error });
			}
		}

		if (store.appState().viewState.currentUserId) {
			// now upload an avatar for the user if needed
			if (avatarUrl && avatarUploadUrl) {
				try {
					const appState = store.appState();
					const currentUser = appState.model.users[appState.viewState.currentUserId];
					const s3AvatarUrl = await uploadImage(avatarUrl, avatarUploadUrl);
					const avatarSaveResult = await apiGateway.save({ intent: 'user-create', specs: [{
						specId: 0,
						type: 'user',
						itemId: currentUser.id,
						patch: [{ op: PatchOpType.add, path: '/profile/avatarUrl', value: s3AvatarUrl }],
					}],
					});

					if (!avatarSaveResult.results[0].isError) {
						currentUser.applyPatch(avatarSaveResult.results[0].patch);
						await _processUsers([currentUser]);
					}
				} catch (err) {
					console.log(err);
				}
			}

			if (team) {
				const { spec, blobs } = _createTeamAddSpec(team);
				const teamCreateResponse = await _save({ intent: 'user-create', specs: [spec]}, blobs);

				if (teamCreateResponse.results[0].isError) {
					resultAlerts.push({ message: _s(StringKey.TEAM_CREATION_FAILED_ALERT_MESSAGE), title: _s(StringKey.TEAM_CREATION_FAILED_ALERT), severity: store.AlertSeverity.error });
				} else {
					await processSubscriptionAssets(team.id);
					await setCurrentTeam(team);
				}
			}

			logger.logEvent(team ? 'create-account' : 'create-user', 'onboarding');
		}
	}

	return resultAlerts;
}

export async function addTeam(team: ITeam) {
	const { spec, blobs } = _createTeamAddSpec(team);

	const teamResult = await _save({ intent: 'team-create', specs: [spec],
	}, blobs);

	if (!teamResult.results[0].isError) {
		logger.logEvent('add-team', 'onboarding');
		await processSubscriptionAssets(team.id);
		await setCurrentTeam(team);
	}

	return teamResult;
}

function _createTeamAddSpec(team: ITeam) {
	const blobs = {};

	if (team.settings.logoUrl && team.settings.logoUrl.indexOf('data:') === 0) {
		blobs[team.id] = { type: BlobType.Logo, url: team.settings.logoUrl, patchPath: '/settings/logoUrl' };
		team.settings.logoUrl = undefined;
	}

	return { spec: {
		specId: 0,
		type: 'team',
		data: team,
		parentSpecId: undefined,
		blobs: mapValues(blobs, (v) => v.type),
	}, blobs };
}

export async function addSubscription(plan: ISubscriptionPlan, subscriptionData: ISubscriptionData) {
	const clientTeam = teamFactory(store.appState().model.teams[subscriptionData.teamId]);

	subscriptionData.subscriptionPlanId = plan.id;

	const saveResult = await _save({ intent: `subscription-create-${subscriptionData.teamId}`, specs: [{
		specId: 0,
		type: 'subscription',
		data: subscriptionData,
	}],
	});

	// type 'subscription' isn't processed by _save, this handler must process the result, because there is no patch on saveResult.results[0] _save ignores this
	if (!(saveResult as any).error && !saveResult.results[0].isError) {
		const serverResultTeam = teamFactory(saveResult.results[0].data);
		const patch = serverResultTeam.getPatch(clientTeam);

		clientTeam.applyPatch(filter(patch, (op) => op.path.indexOf('/currentSubscription/') === -1 && op.path.indexOf('/pendingSubscription/') === -1));
		clientTeam.currentSubscription = serverResultTeam.currentSubscription;
		clientTeam.pendingSubscription = serverResultTeam.pendingSubscription;

		try {
			if(!plan.price) {
				logger.track('select_promotion', { items: [{ item_id:  plan.key, item_name: plan.name, coupon: subscriptionData.couponCode }] });
			}
			else {
				logger.track('purchase', { currency: 'USD', value: subscriptionData.expectedPrice || plan.price, transaction_id: clientTeam.currentSubscription?.id,  items: [{ item_id:  plan.key, item_name: plan.name, coupon: subscriptionData.couponCode }] });
			}
		}
		catch {
			// does nothing
		}

		await _processTeams([clientTeam]);
		await processSubscriptionAssets(clientTeam.id);

		_updateGatewayConnection(); // don't await this to allow control to return to caller before it is executed
	}

	return saveResult;
}

export async function updateSubscription(subscriptionData: ISubscriptionData) {
	const clientTeam = teamFactory(store.appState().model.teams[subscriptionData.teamId]);
	const preSaveSubscription = clientTeam.currentSubscription;
	const saveResult = await _save({ intent: `subscription-update-${subscriptionData.teamId}`, specs: [{
		specId: 0,
		type: 'subscription',
		itemId: preSaveSubscription.id,
		patch: subscriptionData.paymentData ? [ { op: PatchOpType.add, path: '/paymentMethod', value: paymentMethodFactory(subscriptionData.paymentData).toDocument() }] : [ { op: PatchOpType.add, path: '/pause', value: !!subscriptionData.pause }],
	}],
	});

	// type 'subscription' isn't processed by _save, this handler must process the result, because there is no patch on saveResult.results[0] _save ignores this
	if (!(saveResult as any).error && !saveResult.results[0].isError) {
		// logger.logEvent(plan.price? 'buy': 'claim', 'subscription', plan.key);
		const serverResultTeam = teamFactory(saveResult.results[0].data);
		const patch = serverResultTeam.getPatch(clientTeam);

		clientTeam.applyPatch(filter(patch, (op) => op.path.indexOf('/currentSubscription/') === -1 && op.path.indexOf('/pendingSubscription/') === -1));
		clientTeam.currentSubscription = serverResultTeam.currentSubscription;
		clientTeam.pendingSubscription = serverResultTeam.pendingSubscription;

		await _processTeams([clientTeam]);
		await processSubscriptionAssets(clientTeam.id);

		_updateGatewayConnection(); // don't await this to allow control to return to caller before it is executed
	}

	return saveResult;
}

export async function upgradeSubscription(plan: ISubscriptionPlan, subscriptionData: ISubscriptionData) {
	const clientTeam = teamFactory(store.appState().model.teams[subscriptionData.teamId]);

	subscriptionData.subscriptionPlanId = plan.id;

	const saveResult = await _save({ intent: `subscription-upgrade-${subscriptionData.teamId}`, specs: [{
		specId: 0,
		type: 'subscriptionUpgrade',
		data: subscriptionData,
	}],
	});

	// type 'subscription' isn't processed by _save, this handler must process the result, because there is no patch on saveResult.results[0] _save ignores this
	if (!saveResult.results[0].isError) {
		// logger.logEvent('upgrade', 'subscription', plan.key);
		const serverResultTeam = teamFactory(saveResult.results[0].data);
		const patch = serverResultTeam.getPatch(clientTeam);

		clientTeam.applyPatch(filter(patch, (op) => op.path.indexOf('/currentSubscription/') === -1 && op.path.indexOf('/pendingSubscription/') === -1));
		clientTeam.currentSubscription = serverResultTeam.currentSubscription;
		clientTeam.pendingSubscription = serverResultTeam.pendingSubscription;

		try {
			const upgrade = plan.upgrades.values.find(u => u.id === subscriptionData.upgradeId);

			logger.track('purchase', { currency: 'USD', value: subscriptionData.expectedPrice || upgrade?.price, transaction_id: `${clientTeam.currentSubscription?.id}.${subscriptionData.upgradeId}`,  items: [{ item_id:  subscriptionData.upgradeId, item_name: upgrade?.key }] });
		}
		catch {
			// does nothing
		}

		await _processTeams([clientTeam]);
		await processSubscriptionAssets(clientTeam.id);

		_updateGatewayConnection(); // don't await this to allow control to return to caller before it is executed
	}

	return saveResult;
}

async function processSubscriptionAssets(teamId: string) {
	let team = store.appState().model.teams[teamId];
	const subscription = team && team.currentSubscription;

	if (!subscription) {
		return;
	}

	try {
		const playbooks = await fetchSubscriptionPlaybooks(subscription);

		for (const key in playbooks) {
			// be sure to have the latest version of the team
			team = store.appState().model.teams[teamId];

			// initially only "samplePlaybook" could be imported, now all keys returned from fetchSubscriptionPlaybooks will be assumed to be playbooks
			const importResult = processPlaybookImport(playbooks[key], team);

			if (importResult.playbook) {
				const mutatedTeam = teamFactory(team);
				const intent:SaveBatchIntent = `playbook-import-viasub-${subscription.teamId}`;

				mutatedTeam.playbooks.add(importResult.playbook);

				const teamUpdate = _getTeamUpdate(mutatedTeam, intent, importResult.formations, importResult.plays);
				await _save({ intent, specs: teamUpdate.specs }, teamUpdate.blobs);
			}
		}

	} catch (err) {
		console.log(err);
	}
}

async function fetchSubscriptionPlaybooks(subscription: ISubscription) {
	const result: StringDictionary = {};

	if (subscription.settings && subscription.settings.assets) {

		for (const asset of subscription.settings.assets) {
			// we assume json is a playbook
			if (asset.type === 'json') {
				try {
					const response = await fetch(asset.url);
					result[asset.key] = await response.json();
				} catch (err) {
					// TODO
				}
			}
		}
	}

	return result;
}

export async function redeemItem(code: string, data?: StringDictionary) {
	const { model, viewState } = store.appState();
	const team = model.teams[viewState.currentTeamId];
	let result = await apiGateway.redeemItem(code, assign({ teamId: team.id}, data)) as any;

	if (!result.error) {
		if (result.url) { // this was either a migration, an import, or a playpack purchase
			try {
				const fetchResponse = await fetch(result.url);

				if (fetchResponse.ok) {
					const playbookSpec = await fetchResponse.json();
					const importResult = processPlaybookImport(playbookSpec, team);
					const mutatedTeam = teamFactory(team);
					const intent: SaveBatchIntent = `playbook-create-redeem-${code}-${mutatedTeam.id}`;

					mutatedTeam.playbooks.add(importResult.playbook);

					const teamUpdate = _getTeamUpdate(mutatedTeam, intent, importResult.formations, importResult.plays);
					result = await _save({ intent, specs: teamUpdate.specs }, teamUpdate.blobs);
					try {
						logger.track('select_promotion', { promotion_id: 'redeem_playpack', items: [{ item_id:  importResult.playbook.id, item_name: importResult.playbook.name, coupon: code }] });
					}
					catch {
						// does nothing
					}
				} else {
					logger.logError(`Failed to fetch ${result.type}, code: ${code}`, false);
					result = { error: { message: _s(StringKey.REDEMPTION_FAILED_MESSAGE)}};
				}
			} catch (err) {
				logger.logError(`Failed to fetch ${result.type}, code: ${code}, ${err.message || err}`, false);
				result = { error: { message: _s(StringKey.REDEMPTION_FAILED_MESSAGE)}};
			}
		} else if (result.team) { // this was a subscription purchase or a promo/sponsor code
			const serverResultTeam = teamFactory(result.team);
			const patch = serverResultTeam.getPatch(team);

			team.applyPatch(filter(patch, (op) => op.path.indexOf('/currentSubscription/') === -1 && op.path.indexOf('/pendingSubscription/') === -1));
			team.currentSubscription = serverResultTeam.currentSubscription;
			team.pendingSubscription = serverResultTeam.pendingSubscription;

			try {
				logger.track('select_promotion', { promotion_id: 'redeem_subscription', items: [{ item_id:  team.currentSubscription.id, item_name: team.currentSubscription.settings?.planyKey, coupon: code }] });
			}
			catch {
				// does nothing
			}
			
			await _processTeams([team]);
			await processSubscriptionAssets(team.id);

			_updateGatewayConnection(); // don't await this to allow control to return to caller before it is executed
		}
	}

	return result;
}

export async function addFullPlaybook({ playbook, formations, plays, preservePermissions = false, intent = 'playbook-create-full' } : { playbook: IPlaybook, formations: IFormation[], plays: IPlay[], preservePermissions?: boolean, intent?: SaveBatchIntent }) {
	const { model } = store.appState();
	const team = model.teams[playbook.teamId];
	let result;

	if (team) {
		const mutatedTeam = teamFactory(team);

		// default teamMember permissions
		if (!preservePermissions) {
			for (const teamMember of filter(mutatedTeam.members.values, (m) => m.userId || m.inviteCode)) {
				const playbookAccess = (teamMember.role === TeamRole.Player ? PlaybookAccess.View : PlaybookAccess.Edit);

				playbook.addMember(teamMember.id, playbookAccess);
			}
		}

		mutatedTeam.playbooks.add(playbook);

		const teamUpdate = _getTeamUpdate(mutatedTeam, intent, formations, plays);
		result = await _save({ intent, specs: teamUpdate.specs }, teamUpdate.blobs);
	} else {
		logger.logError(`Failed to fetch ${result.type}, code: ${result.code}`, false);
		result = { error: { message: 'TODO: WE NEED A MESSAGE FOR THIS ERROR'}};
	}

	return result;
}

export async function archivePlaybook(playbook: IPlaybook) {
	const { model } = store.appState();
	const archiveResult = await apiGateway.archivePlaybook(playbook.id);

	if (!(archiveResult as any).error) {

		const clientTeam = teamFactory(model.teams[playbook.teamId]);

		clientTeam.applyPatch((archiveResult as any).changes);

		await _processTeams([clientTeam]);

		await store.mutateAsync(async (appState) => {
			appState.model = assign({}, appState.model);
			const storeFormations = assign({}, appState.model.formations);
			const storePlays = assign({}, appState.model.plays);

			for (const storeFormationId in storeFormations) {
				const formation = storeFormations[storeFormationId];

				if (formation.playbookId === playbook.id) {
					delete storeFormations[storeFormationId];
					await clientStorage.removeItem(`model.formations.${storeFormationId}`);
				}
			}

			for (const storePlayId in storePlays) {
				const play = storePlays[storePlayId];

				if (play.playbookId === playbook.id) {
					delete storePlays[storePlayId];
					await clientStorage.removeItem(`model.plays.${storePlayId}`);
				}
			}

			appState.model.formations = storeFormations;
			appState.model.plays = storePlays;
		});
	}

	return archiveResult;
}

export async function unarchivePlaybook(playbook: IPlaybook) {
	const { model } = store.appState();
	const unarchiveResult = await apiGateway.unarchivePlaybook(playbook.id);

	if (!(unarchiveResult as any).error) {

		const clientTeam = teamFactory(model.teams[playbook.teamId]);

		clientTeam.applyPatch((unarchiveResult as any).changes);

		await _processTeams([clientTeam]);
		await _fetchPlaybookItems([playbook.id]);
	}

	return unarchiveResult;
}

export async function saveUser({data, intent='user-create', saveCallback = NULL_CALLBACK}: {data: IUser, saveCallback?: SaveCallback, intent?: SaveBatchIntent}) {
	return new Promise((resolve) => {
		_changeQueue.enqueue(async () => {
			const preSaveUser = store.appState().model.users[data.id];
			const patch = data.getPatch(preSaveUser);
			const specs = [];
			const blobs = {};
			const profileChanges: IPatchOperation[] = filter(patch, (op) => op.path.indexOf('/profile') === 0) || [];
			const requiresSaveToServer = profileChanges.length !== patch.length && !!find(profileChanges, (op) => op.path.indexOf('/profile/avatarUrl') === 0);
			let saveResponse;

			if (patch.length === 0) {
				saveResponse = { results: [{}] }; // mock enough to fake out the profileEdit view
			}

			if (!saveResponse) {
				_appendUserSaveSpec(data, patch, specs, blobs);

				await _processUsers([data]);

				saveResponse = await _saveToCloud({ intent, specs }, blobs);
			}

			if (saveResponse.results[0].isError && requiresSaveToServer) { // reset the local user to it's pre-save state
				await _processUsers([preSaveUser]);
			}

			saveCallback(saveResponse);

		}, resolve);
	});
}

function _appendUserSaveSpec(user: IUser, patch: IPatchOperation[], specs: ISaveSpec[] = [], blobs: object = {}) {
	const avatarOp = find(patch, { path: '/profile/avatarUrl'});

	patch = filter(patch, (op) => op.path !== '/profile/avatarUrl');

	if (avatarOp) {
		if (avatarOp.value && avatarOp.value.indexOf('data:') === 0) {
			blobs[user.id] = { type: BlobType.Avatar, url: avatarOp.value, patchPath: '/profile/avatarUrl' };
		} else { // re-add this because we're wiping out the avatar
			patch.push(avatarOp);
		}
	}

	specs.push({
		specId: 0,
		type: 'user',
		itemId: user.id,
		patch,
		blobs: mapValues(blobs, (v) => v.type),
	});
}

export async function saveTeam({ data, formations = null, saveCallback = NULL_CALLBACK, intent = 'team-update'}: { data: ITeam, formations?: IFormation[], saveCallback?: SaveCallback, intent?: SaveBatchIntent}) {
	let mutatedTeam = teamFactory(data);
	return new Promise((resolve) => {
		_changeQueue.enqueue(async () => {
			const teamUpdate = _getTeamUpdate(mutatedTeam, intent, formations);
			const formationSpecs = filter(teamUpdate.specs, { type: 'formation' });
			const playSpecs = filter(teamUpdate.specs, { type: 'play' });

			mutatedTeam = teamUpdate.updatedTeam;

			// mutate our local storage
			await store.mutateAsync(async (appState) => {
				let storageKey = `model.teams.${mutatedTeam.id}`;
				const teamData = mutatedTeam.toDocument(null, ['/pendingSubscription']);

				appState.model = assign({}, appState.model);
				appState.model.teams = assign({}, appState.model.teams);
				appState.model.formations = assign({}, appState.model.formations);
				appState.model.plays = assign({}, appState.model.plays);

				_updateTeamMemberSavedState(mutatedTeam);
				appState.model.teams[mutatedTeam.id] = mutatedTeam;

				await clientStorage.setItem(storageKey, teamData);

				for (const spec of formationSpecs) {
					// formation deletes
					if (spec.itemId && !spec.patch) { // deletes
						storageKey = `model.formations.${spec.itemId.id}`;

						unset(appState, storageKey);

						await clientStorage.removeItem(storageKey);
					} else if (!spec.itemId && spec.data) { // adds
						storageKey = `model.formations.${spec.data.id}`;

						appState.model.formations[spec.data.id] = spec.data;

						await clientStorage.setItem(storageKey, spec.data);
					}
				}

				for (const spec of playSpecs) {
					storageKey = `model.plays.${spec.itemId.id}`;

					if (spec.itemId && !spec.patch) { // deletes
						unset(appState, storageKey);
						await clientStorage.removeItem(storageKey);
					} else if (spec.itemId && spec.patch) { // updates
						const play = appState.model.plays[spec.itemId.id];

						if (play) {
							const mutatedPlay = playFactory(play);

							mutatedPlay.applyPatch(spec.patch);
							appState.model.plays[mutatedPlay.id] = mutatedPlay;

							await clientStorage.setItem(storageKey, mutatedPlay);
						}
					}
				}
			});

			// if(!team.hasHadSubscription) {
			// 	teamUpdate.specs = filter(teamUpdate.specs, { type: 'team'});
			// }

			const saveResponse = await _saveToCloud({ intent, specs: teamUpdate.specs}, teamUpdate.blobs);

			saveCallback(saveResponse);

		}, resolve);
	});
}

export async function inviteMembers(team: ITeam, inviteSpecs: StringDictionary) {
	const saveResult = await _save({ intent: `member-invite-${team.id}`, specs: [{
		specId: 0,
		type: 'team',
		itemId: team.id,
		patch: [{ op: PatchOpType.replace, path: 'team-invites', value: inviteSpecs }],
	}]});

	logger.logEvent('invite-members', 'onboarding', team.currentSubscription.name, Object.keys(inviteSpecs).length);

	return saveResult;
}

export async function addFormation({data, saveCallback = NULL_CALLBACK, intent = 'formation-create'}: {data: IFormation, saveCallback?: SaveCallback, intent?: SaveBatchIntent}) {
	return new Promise((resolve) => {
		_changeQueue.enqueue(async () => {
			await _processFormations([data]);

			const saveResponse = await _saveToCloud({ intent, specs: [{
				specId: 0,
				type: 'formation',
				data,
			}]}, {});

			saveCallback(saveResponse);

		}, resolve);
	});
}

export async function addFormations({data, saveCallback = NULL_CALLBACK, intent='formation-create'}: {data: IFormation[], saveCallback?: SaveCallback, intent?: SaveBatchIntent}) {
	return new Promise((resolve) => {
		_changeQueue.enqueue(async () => {
			await _processFormations(data);

			const saveResponse = await _saveToCloud({ intent, specs: map(data, (f, i) => {
				return {
					specId: i,
					type: 'formation',
					data: f,
				};
			})}, {});

			saveCallback(saveResponse);

		}, resolve);
	});
}

export async function saveFormation({data, saveCallback = NULL_CALLBACK, intent = 'formation-update'}: {data: IFormation, saveCallback?: SaveCallback, intent?: SaveBatchIntent}) {
	return new Promise((resolve) => {
		_changeQueue.enqueue(async () => {
			const model = store.appState().model;
			const storeFormation = model.formations[data.id];
			const patch = cleanPatch(data.getPatch(storeFormation));
			const labelOp = find(patch, { path: '/label' });
			const specs = [];

			if (patch.length === 0) {
				return;
			}

			await _processFormations([data]);

			if (labelOp) {
				const oldLabel = storeFormation.label;
				const newLabel = data.label;
				const formationPlays = filter(model.plays, { formation: oldLabel, playbookId: data.playbookId });
				const updatedPlays: IPlay[] = [];
				const playSpecId = 1;

				for (const play of formationPlays) {
					const mutatedPlay = playFactory(play);

					mutatedPlay.formation = newLabel;
					updatedPlays.push(mutatedPlay);
					specs.push({
						specId: playSpecId,
						type: 'play',
						itemId: { id: play.id, playbookId: play.playbookId },
						patch: mutatedPlay.getPatch(play),
						parentSpecId: 0,
					});
				}

				_processPlays(updatedPlays);
			}

			specs.unshift({
				specId: 0,
				type: 'formation',
				itemId: { id: data.id, playbookId: data.playbookId },
				patch,
			});

			const saveResponse = await _saveToCloud({ intent, specs }, {});

			saveCallback(saveResponse);

		}, resolve);
	});
}

export async function saveFormations({data, saveCallback = NULL_CALLBACK, intent = 'formation-update-multi'}: {data: IFormation[], saveCallback?: SaveCallback, intent?: SaveBatchIntent}) {
	return new Promise((resolve) => {
		_changeQueue.enqueue(async () => {
			const model = store.appState().model;
			const specs = [];
			const playbookId = data.length? data[0].playbookId: '';
			const labelChanges: {parentSpecId: number, oldLabel: string, newLabel: string}[] = [];
			let specId = 0;

			for(const formation of data) {
				const storeFormation = model.formations[formation.id];
				const patch = cleanPatch(formation.getPatch(storeFormation));
				const labelOp = find(patch, { path: '/label' });
				

				if (patch.length === 0) {
					continue;
				}
				const formationSpecId = specId ++;

				if(labelOp) {
					labelChanges.push({ parentSpecId: formationSpecId, oldLabel: storeFormation.label, newLabel: formation.label });
				}

				specs.push({
					specId: formationSpecId,
					type: 'formation',
					itemId: { id: formation.id, playbookId: formation.playbookId },
					patch,
				});
			}

			await _processFormations(data);

			for(const labelChange of labelChanges) {
				const { parentSpecId, oldLabel, newLabel } = labelChange;
				const formationPlays = filter(model.plays, { formation: oldLabel, playbookId });
				const updatedPlays: IPlay[] = [];

				for (const play of formationPlays) {
					const mutatedPlay = playFactory(play);

					mutatedPlay.formation = newLabel;
					updatedPlays.push(mutatedPlay);
					specs.push({
						specId: specId++,
						type: 'play',
						itemId: { id: play.id, playbookId: play.playbookId },
						patch: mutatedPlay.getPatch(play),
						parentSpecId,
					});
				}

				_processPlays(updatedPlays);
			}

			const saveResponse = await _saveToCloud({ intent, specs }, {});

			saveCallback(saveResponse);

		}, resolve);
	});
}

export async function deleteFormation({data, saveCallback = NULL_CALLBACK, intent = 'formation-delete'}: {data: IFormation, saveCallback?: SaveCallback, intent?: SaveBatchIntent}) {
	return new Promise((resolve) => {
		_changeQueue.enqueue(async () => {
			const playSpecs = [];
			let specId = 1;

			await store.mutateAsync(async (appState) => {
				let storageKey = `model.formations.${data.id}`;
				const formationPlays = filter(appState.model.plays, { playbookId: data.playbookId, formation: data.label });

				appState.model = assign({}, appState.model);
				appState.model.formations = assign({}, appState.model.formations);
				appState.model.plays = assign({}, appState.model.plays);

				unset(appState, storageKey);

				await clientStorage.removeItem(storageKey);

				for (const play of formationPlays) {
					storageKey = `model.plays.${play.id}`;

					unset(appState, storageKey);

					await clientStorage.removeItem(storageKey);

					playSpecs.push({
						specId: specId ++,
						type: 'play',
						itemId: { id: play.id, playbookId: play.playbookId },
					});
				}
			});

			const state = store.appState();
			const mutatedTeam = teamFactory(find(state.model.teams, (t: ITeam) => !!t.playbooks[data.playbookId]));
			const playbook = mutatedTeam.playbooks[data.playbookId];
			const playList = find(playbook.settings.playLists.values, { relatedId: data.id });
			let teamSpecs = [];

			if (playList) {
				playbook.settings.playLists.remove(playList.id);
				teamSpecs = _getTeamUpdate(mutatedTeam, intent).specs;
			}

			const saveResponse = await _saveToCloud({ intent, specs: [{
				specId: 0,
				type: 'formation',
				itemId: { id: data.id, playbookId: data.playbookId },
			}].concat(playSpecs, teamSpecs)}, {});

			saveCallback(saveResponse);

		}, resolve);
	});
}

export async function applyFormation(formation: IFormation, options: { label: boolean, color: boolean, shading: boolean, location: boolean }) {
	const formationPlays = filter(store.appState().model.plays, { playbookId: formation.playbookId, formation: formation.label, phase: formation.phase });
	const mutatedPlays = [];

	for (const play of formationPlays) {
		const mutatedPlay = playFactory(play);

		for(const mate of mutatedPlay.mates.values) {
			const template = find(formation.mates, { sortIndex: mate.sortIndex }) as IPlayer;

			if(options.label) {
				mate.label = template.label;
			}
			if(options.color) {
				mate.color = template.color;
			}
			if(options.shading) {
				mate.shading = template.shading;
			}
			if(options.location) {
				mate.loc = vectorFactory(template.loc);
			}
		}

		mutatedPlays.push(mutatedPlay);
	}

	await savePlays({ intent: 'formation-apply', data: mutatedPlays });
}

export async function addPlay({data, saveCallback = NULL_CALLBACK, intent = 'play-create'}: {data: IPlay, saveCallback?: SaveCallback, intent?: SaveBatchIntent}) {
	return new Promise((resolve) => {
		_changeQueue.enqueue(async () => {
			if (!data.personnelGroup.playersPerSide) {
				data.personnelGroup.playersPerSide = data.playersPerSide;
			}

			await _processPlays([data]);

			const saveResponse = await _saveToCloud({intent, specs: [{
				specId: 0,
				type: 'play',
				data,
			}]}, {});

			saveCallback(saveResponse);

		}, resolve);
	});
}

export async function addPlays({data, saveCallback = NULL_CALLBACK, intent = 'play-create-multi'}: {data: IPlay[], saveCallback?: SaveCallback, intent?: SaveBatchIntent}) {
	return new Promise((resolve) => {
		_changeQueue.enqueue(async () => {

			for (const play of data) {
				if (!play.personnelGroup.playersPerSide) {
					play.personnelGroup.playersPerSide = play.playersPerSide;
				}
			}

			await _processPlays(data);

			const saveResponse = await _saveToCloud({ intent, specs: map(data, (p, i) => {
				return {
					specId: i,
					type: 'play',
					data: p,
				};
			})}, {});

			saveCallback(saveResponse);

		}, resolve);
	});
}

export async function savePlay({data, saveCallback = NULL_CALLBACK, intent = 'play-update'}: {data: IPlay, saveCallback?: SaveCallback, intent?: SaveBatchIntent}) {
	return new Promise((resolve) => {
		_changeQueue.enqueue(async () => {
			const storePlay = migratePlaySchemaVersion(store.appState().model.plays[data.id]);
			const patch = cleanPatch(data.getPatch(storePlay));

			if (patch.length === 0) {
				return;
			}

			if (!data.personnelGroup.playersPerSide) {
				data.personnelGroup.playersPerSide = data.playersPerSide;
				patch.push({ op: PatchOpType.replace, path: '/personnelGroup/playersPerSide', value: data.personnelGroup.playersPerSide });
			}

			await _processPlays([data]);

			const saveResponse = await _saveToCloud({ intent, specs: [{
				specId: 0,
				type: 'play',
				itemId: { id: data.id, playbookId: data.playbookId },
				patch,
			}]}, {});

			saveCallback(saveResponse);

		}, resolve);
	});
}

export async function savePlays({data, saveCallback = NULL_CALLBACK, intent = 'play-update-multi'}: {data: IPlay[], saveCallback?: SaveCallback, intent?: SaveBatchIntent}) {
	return new Promise((resolve) => {

		_changeQueue.enqueue(async () => {
			const specs: ISaveSpec[] = [];
			const model = store.appState().model;

			for (const play of data) {
				const storePlay = migratePlaySchemaVersion(model.plays[play.id]);
				const patch = cleanPatch(play.getPatch(storePlay));

				if (patch.length === 0) {
					break;
				}

				if (!play.personnelGroup.playersPerSide) {
					play.personnelGroup.playersPerSide = play.playersPerSide;
					patch.push({ op: PatchOpType.replace, path: '/personnelGroup/playersPerSide', value: play.personnelGroup.playersPerSide });
				}

				specs.push({
					specId: specs.length,
					type: 'play',
					itemId: { id: play.id, playbookId: play.playbookId },
					patch,
				});
			}

			await _processPlays(data);

			const saveResponse = await _saveToCloud({ intent, specs }, {});
			saveCallback(saveResponse);

		}, resolve);
	});
}

function migratePlaySchemaVersion(play: IPlay) {
	if (!play.schemaVersion || play.schemaVersion === 1) {
		// set the special teams unit - version 1 plays will work properly because of the unit getter defaulting to SpecialTeamsUnit.None
		play.schemaVersion = 2;
		play.unit = SpecialTeamsUnit.None;
	}

	return play;
}

export async function deletePlay({data, saveCallback = NULL_CALLBACK, intent = 'play-delete'}: {data: IPlay, saveCallback?: SaveCallback, intent?: SaveBatchIntent}) {

	return new Promise((resolve) => {
		_changeQueue.enqueue(async () => {
			await store.mutateAsync(async (appState) => {
				const storageKey = `model.plays.${data.id}`;

				appState.model = assign({}, appState.model);
				appState.model.plays = assign({}, appState.model.plays);
				unset(appState, storageKey);

				await clientStorage.removeItem(storageKey);
			});

			const saveResponse = await _saveToCloud({ intent, specs: [{
				specId: 0,
				type: 'play',
				itemId: { id: data.id, playbookId: data.playbookId },
			}]}, {});

			saveCallback(saveResponse);

		}, resolve);
	});
}

export async function deletePlays({data, saveCallback = NULL_CALLBACK, intent = 'play-delete-multi'}: {data: IPlay[], saveCallback?: SaveCallback, intent?: SaveBatchIntent}) {

	return new Promise((resolve) => {
		_changeQueue.enqueue(async () => {
			await store.mutateAsync(async (appState) => {
				appState.model = assign({}, appState.model);
				appState.model.plays = assign({}, appState.model.plays);

				for (const play of data) {
					const storageKey = `model.plays.${play.id}`;

					unset(appState, storageKey);

					await clientStorage.removeItem(storageKey);
				}
			});

			const saveResponse = await _saveToCloud({ intent, specs: map(data, (p, i) => {
				return {
					specId: i,
					type: 'play',
					itemId: { id: p.id, playbookId: p.playbookId },
				};
			})}, {});

			saveCallback(saveResponse);

		}, resolve);
	});
}

// store change queue shared with the _socket team.change message - ensures all async internals are run in sequence accross both change sources
async function _saveToCloud(saveBatch: IAppSaveBatch, blobs: { [id: string]: IBlobSpec } = {}) {
	let saveResponse: ISaveBatchResult;


	await store.mutate((appState) => {
		const batchTimestamp = Date.now();

		appState.viewState = assign({}, appState.viewState);
		appState.viewState.unsyncedEntities = assign({}, appState.viewState.unsyncedEntities);

		for (const spec of saveBatch.specs) {
			_trackUnsyncedEntity(appState.viewState.unsyncedEntities, spec, batchTimestamp);
		}

		clientStorage.setItem(UNSYNCED_ENTITY_STORAGE_KEY, {...appState.viewState.unsyncedEntities});
	});

	if (saveBatch.specs.length === 0) {
		saveResponse = { results: [] };
	} else if (store.appState().viewState.api.canAccessApi) {
		const successes = [];
		saveResponse = await _save(saveBatch, blobs, (spec) => {
			successes.push(spec);
		});

		if ((saveResponse as any).error) { // something went wrong
			const error = (saveResponse as any).error;
			const type = (saveResponse as any).error.type; // CONNECTION_CLOSED, SEND_FAILURE, SEND_TIMEOUT, UNKNOWN
			const results: ISaveSpecResult[] = map(saveBatch.specs, (spec) => {
				return { specId: spec.specId, isError: true, messages: [error.message], errorType: type, errorSource: error.source };
			});

			saveResponse = { results };
		}

		await store.mutate((appState) => {
			const batchTimestamp = Date.now();

			appState.viewState = {...appState.viewState};
			appState.viewState.unsyncedEntities = {...appState.viewState.unsyncedEntities};

			for (const spec of successes) {
				_trackUnsyncedEntity(appState.viewState.unsyncedEntities, spec, batchTimestamp, true); // decriment this
			}
			
			clientStorage.setItem(UNSYNCED_ENTITY_STORAGE_KEY, {...appState.viewState.unsyncedEntities});
		});
	} else {
		const results: ISaveSpecResult[] = map(saveBatch.specs, (spec) => {
			return { specId: spec.specId, isError: true, messages: ['OFFLINE'], errorType: 'OFFLINE', errorSource: 'client' };
		});

		saveResponse = { results };
	}

	return saveResponse;
}

async function _save(saveBatch: IAppSaveBatch, blobs: { [id: string]: IBlobSpec } = {}, onSpecResult: (spec: ISaveSpec, result: ISaveSpecResult) => void = null) {
	const entitiesToProcess = { formation: [], play: [], team: [], user: [] };
	const saveResponse = await apiGateway.save(saveBatch);

	if (!(saveResponse as any).error) {
		const appState = store.appState();
		const sideEffects = appendSideEffects(saveResponse);

		for (const result of saveResponse.results) {
			const spec = find(saveBatch.specs, { specId: result.specId }) as ISaveSpec;
			const entityType = spec.type;
			const specAction = spec.itemId ? !spec.patch ? 'delete' : 'update' : 'create';

			if (!result.isError) {
				if (result.patch && (specAction === 'create' || specAction === 'update')) {
					const factory = ENTITY_FACTORIES[entityType];
					const itemId = specAction === 'create' ? spec.data.id || find(result.patch, { path: '/id'}).value : spec.itemId.id || spec.itemId; // play and formation use a composite itemId
					const itemKey = `model.${entityType}s.${itemId}`;
					const storeItem = get(appState, itemKey) || spec.data; // get this out of the store, if available - adding of accounts/teams will not write to the store until a successful save
					const updated = factory(storeItem);
					const blob = blobs[updated.id];
					const uploadUrl = result.uploadUrls && result.uploadUrls[updated.id];

					updated.applyPatch(result.patch);

					if (blob && uploadUrl) {
						const downloadUrl = await uploadImage(blob.url, uploadUrl);
						const blobSaveResult = await apiGateway.save({ intent: saveBatch.intent, specs: [{
							specId: 0,
							type: spec.type,
							itemId: updated.id,
							patch: [{ op: PatchOpType.replace, path: blob.patchPath, value: downloadUrl }],
						}],
						});

						for (const resultItem of blobSaveResult.results) {
							updated.applyPatch(resultItem.patch);
						}
					}

					entitiesToProcess[entityType].push(updated);
				}

				if (onSpecResult) {
					onSpecResult(spec, result);
				}
			}
		}

		for (const spec of sideEffects) {
			const factory = ENTITY_FACTORIES[spec.type];
			const itemId = spec.itemId.id || spec.itemId; // play and formation use a composite itemId
			const itemKey = `model.${spec.type}s.${itemId}`;
			const existingUpdate = find(entitiesToProcess[spec.type], { id: itemId });
			const storeItem = !existingUpdate ? get(appState, itemKey) : null;
			const updated = existingUpdate || factory(storeItem);

			updated.applyPatch(spec.patch);

			if (!existingUpdate) {
				entitiesToProcess[spec.type].push(updated);
			}
		}

		await _processTeams(entitiesToProcess.team);
		await _processUsers(entitiesToProcess.user);
		await _processFormations(entitiesToProcess.formation);
		await _processPlays(entitiesToProcess.play);
	}

	return saveResponse;
}

function appendSideEffects(saveResult: ISaveBatchResult, sideEffects: ISaveSpec[] = []): ISaveSpec[] {
	for (const result of saveResult.results) {
		if (result.data && result.data.sideEffects) {
			for (const sideEffect of result.data.sideEffects as ISaveSpec[]) {
				sideEffects.push(sideEffect);
			}
		}
	}

	return sideEffects;
}

function _trackUnsyncedEntity(entityMap: {[entityId: string]: store.IUnsyncedEntity}, spec: ISaveSpec, batchTimestamp: number, decriment = false) {
	const entityType = spec.type;
	const action = spec.itemId ? !spec.patch ? 'delete' : 'update' : 'create';
	const entityId = spec.itemId ? spec.itemId.id || spec.itemId : spec.data.id;
	const unsyncedEntity = entityMap[entityId];

	if (!unsyncedEntity)	{
		if (!decriment) {
			const appState = store.appState();
			const storeKey = `model.${entityType}s.${entityId}`;
			const entity = get(appState, storeKey);

			entityMap[entityId] = { count: 1, entityId, entityType, timestamp: batchTimestamp, storeKey, itemVersion: entity?.itemVersion, wasCreated: (action === 'create'), wasDeleted: (action === 'delete')  };
		}
	} else if (action === 'delete' && unsyncedEntity.wasCreated) {
		delete entityMap[entityId];
	} else {
		unsyncedEntity.count = Math.max(0, unsyncedEntity.count + (decriment ? -1 : 1));

		if (unsyncedEntity.count === 0) {
			delete entityMap[entityId];
		} else {
			unsyncedEntity.timestamp = batchTimestamp;

			if (action === 'delete') {
				unsyncedEntity.wasDeleted = true;
			}
		}
	}
}

const teamMemberAvatarRegex = /members\/([^\/]*)\/avatarUrl$/;
const categoryChangeRegex = /playbooks\/([^\/]*)\/settings\/categories\/([^\/]*)/;
const playbookChangeRegex = /playbooks\/([^\/]*)$/;
const playListAddRegex = /playbooks\/([^\/]*)\/settings\/playLists\/([^\/]*)\/playIds$/;
function _getTeamUpdate(team: ITeam, intent: SaveBatchIntent, formations: IFormation[] = null, plays: IPlay[] = null): { specs: ISaveSpec[], blobs: any, updatedTeam: ITeam } {
	const isResync = intent.indexOf('resync') === 0;
	const storeTeam = store.appState().model.teams[team.id];
	const updatedTeam = teamFactory(team.toDocument());
	let patch = cleanPatch(team.getPatch(storeTeam), [/^\/currentSubscription/, /^\/pendingSubscription/]);
	const removedPatchOps:IPatchOperation[] = [];

	if (patch.length === 0) {
		return { specs: [], blobs: {}, updatedTeam };
	}

	const blobs = {};
	let specId = 1; // used for "secondary" specs
	let playSpecs = [];
	let formationSpecs = [];
	const playbooksWithRemovedCategories = [];
	const removedCategories = [];
	const userSpecs = [];
	const appState = store.appState();
	const logoOp = find(patch, (op) => op.path === '/settings/logoUrl' && op.op !== PatchOpType.remove);
	const avatarOps = filter(patch, (op) => teamMemberAvatarRegex.test(op.path) && op.op !== PatchOpType.remove);

	patch = filter(patch, (op) => op !== logoOp && avatarOps.indexOf(op) === -1);
	if (logoOp && logoOp.value.indexOf('data:') === 0) {
		blobs[team.id] = { type: BlobType.Logo, id: team.id, url: logoOp.value , patchPath: '/settings/logoUrl' };
		team.settings.logoUrl = undefined;
	}

	forEach(avatarOps, (op) => {
		if (op && op.value.indexOf('data:') === 0) {
			const teamMemberMatch = teamMemberAvatarRegex.exec(op.path);
			const teamMemberId = teamMemberMatch[1];
			const teamMember = find(team.members.values, { id: teamMemberId });
			const user = teamMember && teamMember.userId && appState.model.users[teamMember.userId];

			if (user) {
				blobs[user.id] = { type: BlobType.Avatar, url: op.value , patchPath: '/profile/avatarUrl' };
				userSpecs.push({
					specId: specId ++,
					type: 'user',
					itemId: user.id,
					patch: [],
					blobs: { [user.id]: BlobType.Avatar },
				});
			}
		}
	});

	forEach(patch, (item) => {
		if (item.op === 'remove') {
			const playbookChangeMatch = playbookChangeRegex.exec(item.path);
			const categoryChangeMatch = categoryChangeRegex.exec(item.path);

			if (playbookChangeMatch && playbookChangeMatch.length) {
				const playbookId = playbookChangeMatch[1];
				const playbookPlays = filter(appState.model.plays, { playbookId });
				const playbookFormations = filter(appState.model.formations, { playbookId });

				if(isResync) {
					removedPatchOps.push(item);
					const playbook = storeTeam.playbooks[playbookId];

					if(playbook) {
						updatedTeam.playbooks.add(playbook);
					}
				}
				else {
					formationSpecs = formationSpecs.concat(map(playbookFormations, (f) => {
						return {
							specId: specId ++,
							parentSpecId: 0,
							type: 'formation',
							itemId: { id: f.id, playbookId: f.playbookId },
						};
					}));

					playSpecs = playSpecs.concat(map(playbookPlays, (p) => {
						return {
							specId: specId ++,
							parentSpecId: 0,
							type: 'play',
							itemId: { id: p.id, playbookId: p.playbookId },
						};
					}));
				}

			}

			if (categoryChangeMatch && categoryChangeMatch.length) {
				const playbookId = categoryChangeMatch[1];
				const categoryId = categoryChangeMatch[2];

				if(isResync) {
					removedPatchOps.push(item);
					const playbook = storeTeam.playbooks[playbookId] as IPlaybook;
					const category = playbook?.settings.categories[categoryId] as ITag;
					const playList = playbook?.settings.playLists[categoryId] as IPlayList;

					if(!updatedTeam.playbooks[playbookId]) {
						updatedTeam.playbooks.add(playbook);
					}
					
					if(category) {
						updatedTeam.playbooks[playbookId].settings.categories[categoryId] = category;
					}

					if(playList) {
						updatedTeam.playbooks[playbookId].settings.playList[categoryId] = playList;
					}
				}
				else {
					if (playbooksWithRemovedCategories.indexOf(playbookId) === -1) {
						playbooksWithRemovedCategories.push(playbookId);
					}

					if (removedCategories.indexOf(categoryId) === -1) {
						removedCategories.push(categoryId);
					}
				}
			}
		} else if (item.op === 'add') {
			const playListAddOpMatch = playListAddRegex.exec(item.path);

			// HACK - if a playlist was added, it is added when the first item is added to it and doesn't properly update the model
			// rewrite the operations to properly create the populated playlist object
			if (playListAddOpMatch && playListAddOpMatch.length) {
				const playbookId = playListAddOpMatch[1];
				const playListId = playListAddOpMatch[2];
				const playbook = team.playbooks[playbookId];
				const playList = playbook && find(playbook.settings.playLists, { id: playListId });

				if (playList) {
					item.value = playList;
					item.path = item.path.replace(/\/playIds$/, '');
				}
			}
		}
	});

	// Cleanup plays associated with this category
	if (removedCategories.length) {
		const playbookPlays = filter(appState.model.plays, (p) => playbooksWithRemovedCategories.indexOf(p.playbookId) !== -1 && !find(playSpecs, (spec) => spec.itemId.id == p.id && !spec.patch)); // be sure not to included deleted plays

		forEach(playbookPlays, (play: IPlay) => {
			const catList = play.categoryList;
			let isChanged = false;

			for (const removed of removedCategories) {
				const categoryIdx = catList.indexOf(removed);
				if (categoryIdx !== -1) {
					isChanged = true;

					catList.splice(categoryIdx, 1);
				}
			}

			if (isChanged) {
				const mutatedPlay = playFactory(play);

				mutatedPlay.categories = catList.join(',');
				const playPatch = mutatedPlay.getPatch(play);

				if (playPatch.length > 0) {
					playSpecs.push( {
						specId: specId ++,
						parentSpecId: 0,
						type: 'play',
						itemId: { id: play.id, playbookId: play.playbookId },
						patch: playPatch,
					});
				}
			}
		});
	}

	if (formations) {
		formationSpecs = formationSpecs.concat(map(formations, (f) => {
			return {
				specId: specId ++,
				parentSpecId: 0,
				type: 'formation',
				data: f,
			};
		}));
	}

	if (plays) {
		playSpecs = playSpecs.concat(map(plays, (p) => {
			return {
				specId: specId ++,
				parentSpecId: 0,
				type: 'play',
				data: p,
			};
		}));
	}

	// if any operations have been removed (likely for resync), filter them out here
	patch = patch.filter(op => removedPatchOps.indexOf(op) === -1);

	return { specs: [{
		specId: 0,
		type: 'team',
		itemId: team.id,
		patch,
		blobs: mapValues(keyBy(filter(blobs, { type: BlobType.Logo }), 'id'), (v) => v.type),
	}].concat(formationSpecs, playSpecs, userSpecs), blobs, updatedTeam };
}

export async function requestPasswordReset(email: string) {
	return await apiGateway.requestPasswordReset(email);
}

export async function updatePassword(oldPassword: string, newPassword: string) {
	return await apiGateway.updatePassword(oldPassword, newPassword);
}

export async function fetchConfig() {
	const { viewState } = store.appState();

	if (viewState.api.canAccessApi) {
		const configResult = await apiGateway.fetchConfig();

		if (configResult && configResult.strings && configResult.settings) {
			await store.mutateAsync(async (appState) => {
				appState.viewState = assign({}, appState.viewState);

				appState.viewState.strings = configResult.strings;
				appState.viewState.settings = configResult.settings;

				await clientStorage.setItem('viewState.strings', configResult.strings);
				await clientStorage.setItem('viewState.settings', configResult.settings);
			});
		}
	}
}

let _lastBannersFetch = 0;
export async function fetchBanners() {
	if(Date.now() - _lastBannersFetch < (1000 * 60 * 60 * 24)) {
		return;
	}

	let fetchResult;
	try {
		fetchResult = await fetch(`https://www.wearetrue.com/playmaker/resources/_xbanners.json?lf=${_lastBannersFetch}`);
	} catch (err) {
		console.log('failed to fetch banners');
	}

	if (fetchResult && fetchResult.ok) {
		const banners = await fetchResult.json();

		await store.mutate((appState) => {
			appState.viewState = assign({}, appState.viewState);

			appState.viewState.banners = banners[appState.viewState.config.variant].banners;
			_lastBannersFetch = Date.now();
		});

	} else {
		await store.mutate((appState) => {
			appState.viewState = assign({}, appState.viewState);

			appState.viewState.banners = {};
		});
	}
}

// this is invoked during bootstrap so be very careful about blocking when disconnected from the network
export async function setCurrentTeam(team: ITeam, force = false) {
	const { viewState } = store.appState();

	if (!force && (!team || team.id === viewState.currentTeamId)) {
		return;
	}

	if (viewState.api.canAccessApi && (!!force || !find(store.appState().model.teams, { id: team.id}))) {
		await _fetchMyTeams();
	}

	await store.mutateAsync(async (appState) => {
		appState.viewState = assign({}, appState.viewState);
		appState.viewState.currentTeamId = team.id;

		await clientStorage.setItem(CURRENT_TEAM_ID_STORAGE_KEY, team.id);
	});

	if (viewState.api.canAccessApi) {
		await _populateTeamData();
	}

	await _updateGatewayConnection(true);
}

export async function clearCurrentTeam() {
	await store.mutateAsync(async (appState) => {
		appState.viewState = assign({}, appState.viewState);
		appState.viewState.currentTeamId = undefined;

		await clientStorage.setItem(CURRENT_TEAM_ID_STORAGE_KEY, null);
	});

	await _fetchMyTeams();

	const appState = store.appState();
	const orderedTeams = sortBy(appState.model.teams, ['name']);

	if (orderedTeams.length) {
		await setCurrentTeam(orderedTeams[0]);
	}
}

export async function transferTeamOwnership(teamId: string, newOwner: ITeamMember, updateStore: boolean) {
	const { viewState } = store.appState();

	try {
		if (viewState.api.canAccessApi) {
			const result = await apiGateway._supportTransferOwnership(teamId, newOwner);

			if (result.teams && updateStore) {
				_processTeams(result.teams);
			}
			
			return true;
		}
	} catch (err) {
		// just log it - not really a concern
		console.log(err);
	}

	return false;
}

// this can be invoked during bootstrap (via setCurrentTeam) so be very careful about blocking when disconnected from the network
async function _updateGatewayConnection(evalCollaboration = false) {
	const { viewState, model } = store.appState();
	const { api } = viewState;

	if(evalCollaboration) {
		const team = model.teams[viewState.currentTeamId];

		_canCollaborate = team?.currentSubscription?.isActive() && team.currentSubscription.supportsFeature(SystemFeature.collaboration);
	}

	if (api.canAccessApi) {
		if (_canCollaborate && !api.socketConnected) {
			// upgrade to a websocket connection
			await apiGateway.connect();
		} else if (!_canCollaborate && api.socketConnected) {
			await apiGateway.disconnect();
		}
	}
}

async function _populateTeamData() {
	const appState = store.appState();
	let playbookIds = [];

	await store.mutate((appState) => {
		appState.viewState = assign({}, appState.viewState);

		appState.viewState.syncInProgress = true;
	});

	try {
		forEach(appState.model.teams, (team: ITeam) => {
			if (team.hasHadSubscription) {
				playbookIds = playbookIds.concat(map(filter(team.playbooks.values, { isArchived: false }), (pb) => pb.id));
			}
		});

		for (const playbookId of playbookIds) {
			try {
				await _fetchPlaybookItems([playbookId]);
			} catch (err) {
				// swollow for now
			}
		}
	} catch (err) {
		// swallow for now
	}

	await store.mutate((appState) => {
		appState.viewState = assign({}, appState.viewState);

		appState.viewState.syncInProgress = false;
	});
}

// helpers
async function _fetchMyTeams() {
	const result = await apiGateway.fetchTeams() as any;

	if (!result.error) {
		await _processTeams(result.teams, true);
	}
}

async function _fetchPlaybookItems(playbookIds: string[]) {
	const { model, viewState: { unsyncedEntities } } = store.appState();
	const batch: IFetchBatch = { specs: [] };
	const playVersions = {};
	const formationVersions = {};

	forEach(model.plays, (play: IPlay) => {
		if (playbookIds.indexOf(play.playbookId) !== -1) {
			// force a refetch if we're unsynced
			const unsynced = unsyncedEntities[play.id];
			playVersions[play.id] = unsynced && !unsynced.wasCreated && !unsynced.wasDeleted? -1: play.itemVersion;
		}
	});

	forEach(model.formations, (formation: IFormation) => {
		if (playbookIds.indexOf(formation.playbookId) !== -1) {
			// force a refetch if we're unsynced
			const unsynced = unsyncedEntities[formation.id];
			formationVersions[formation.id] = unsynced && !unsynced.wasCreated && !unsynced.wasDeleted? -1: formation.itemVersion;
		}
	});

	batch.specs.push({
		specId: 0,
		type: 'playbookFormations',
		filter: { playbookIds },
		options: { formationVersions },

	});

	batch.specs.push({
		specId: 1,
		type: 'playbookPlays',
		filter: { playbookIds },
		options: { playVersions },
	});

	const result = await apiGateway.fetch(batch) as any;

	for (const specResult of result.results) {
		if (!specResult.isError) {
			if (specResult.specId === 0) {
				await _processFormationFetchResults(specResult.items);
			} else if (specResult.specId === 1) {
				await _processPlayFetchResults(specResult.items);
			}
		}
	}
}

async function _processPlayFetchResults(items: IFetchSpecItemResult[]) {
	await store.mutateAsync(async (appState) => {
		appState.model = assign({}, appState.model);
		const storePlays = assign({}, appState.model.plays);

		for (const item of items) {
			if (item.status === 'changed' || item.status === 'new') {
				const play = playFactory(item.data);

				storePlays[play.id] = play;

				await clientStorage.setItem(`model.plays.${play.id}`, play);
			} else if (item.status === 'missing' || item.status === 'denied') {
				delete storePlays[item.itemId];

				await clientStorage.removeItem(`model.plays.${item.itemId}`);
			}
		}

		appState.model.plays = storePlays;
	});
}

async function _processFormationFetchResults(items: IFetchSpecItemResult[]) {
	await store.mutateAsync(async (appState) => {
		appState.model = assign({}, appState.model);
		const storeFormations = assign({}, appState.model.formations);

		for (const item of items) {
			if (item.status === 'changed' || item.status === 'new') {
				const formation = formationFactory(item.data);

				storeFormations[formation.id] = formation;

				await clientStorage.setItem(`model.formations.${formation.id}`, formation);
			} else if (item.status === 'missing' || item.status === 'denied') {
				delete storeFormations[item.itemId];

				await clientStorage.removeItem(`model.formations.${item.itemId}`);
			}
		}

		appState.model.formations = storeFormations;
	});
}

async function _processPlays(plays: IPlay[] = [], doResync = false) {
	await store.mutateAsync(async (appState) => {
		appState.model = assign({}, appState.model);
		const storePlays = assign({}, appState.model.plays);

		if (doResync) {
			for (const storePlayId in storePlays) {
				if (!find(plays, { id: storePlayId })) {
					delete storePlays[storePlayId];
					await clientStorage.removeItem(`model.plays.${storePlayId}`);
				}
			}
		}

		for (let play of plays) {
			play = typeof play.toDocument === 'function' ? play : playFactory(play);

			storePlays[play.id] = play;

			await clientStorage.setItem(`model.plays.${play.id}`, play);
		}

		appState.model.plays = storePlays;
	});
}

async function _processTeams(teams: ITeam[] = [], reset = false) {
	await store.mutateAsync(async (appState) => {
		appState.model = assign({}, appState.model);
		appState.model.teams = assign({}, appState.model.teams);

		if (reset) {
			const teamsToDelete = filter(appState.model.teams, (t) => !!t.currentSubscription && !find(teams, (t2) => t2.id === t.id)); // if it doesn't have a current subscription - it is an offline team

			for (const team of teamsToDelete) {
				const storageKey = `model.teams.${team.id}`;

				delete(appState.model.teams[team.id]);
				await clientStorage.removeItem(storageKey);
			}
		}

		for (let team of teams) {
			const storageKey = `model.teams.${team.id}`;

			team = typeof team.toDocument === 'function' ? team : teamFactory(team);

			if (team.currentSubscription && team.currentSubscription.subscriptionPlan) {
				team.currentSubscription.subscriptionPlan = subscriptionPlanFactory(team.currentSubscription.subscriptionPlan);
			}

			if (team.pendingSubscription && team.pendingSubscription.subscriptionPlan) {
				team.pendingSubscription.subscriptionPlan = subscriptionPlanFactory(team.pendingSubscription.subscriptionPlan);
			}

			_updateTeamMemberSavedState(team);

			set(appState, storageKey, team);

			if (team.id === appState.viewState.currentTeamId) {
				_canCollaborate = team.currentSubscription && team.currentSubscription.isActive() && team.currentSubscription.supportsFeature(SystemFeature.collaboration);
			}

			await clientStorage.setItem(storageKey, team);
		}
	});

	// const appState = store.appState();

	// _canCollaborate = !!find(appState.model.teams, { isCollaborative: true });

	// if(_canCollaborate && !appState.viewState.api.socketConnected) {
	// 	// upgrade to a websocket connection
	// 	await apiGateway.connect();
	// }
	// else if(!_canCollaborate && appState.viewState.api.socketConnected) {
	// 	await apiGateway.disconnect();
	// }
}

function _updateTeamMemberSavedState(team: ITeam) {
	forEach(team.members.values, (member: ITeamMember) => {
		member.notSaved = false;
	});
}

async function _processFormations(formations: IFormation[] = [], doResync = false) {
	await store.mutateAsync(async (appState) => {
		appState.model = assign({}, appState.model);
		const storeFormations = assign({}, appState.model.formations);

		if (doResync) {
			for (const storeFormationId in storeFormations) {
				if (!find(formations, { id: storeFormationId })) {
					delete storeFormations[storeFormationId];
					await clientStorage.removeItem(`model.formations.${storeFormationId}`);
				}
			}
		}

		for (let formation of formations) {
			formation = typeof formation.toDocument === 'function' ? formation : formationFactory(formation);
			storeFormations[formation.id] = formation;
			await clientStorage.setItem(`model.formations.${formation.id}`, formation);
		}

		appState.model.formations = storeFormations;
	});
}

async function _processUsers(users: IUser[] = []) {
	await store.mutateAsync(async (appState) => {
		appState.model = assign({}, appState.model);
		appState.model.users = assign({}, appState.model.users);

		for (let user of users) {
			const storageKey = `model.users.${user.id}`;

			user = typeof user.toDocument === 'function' ? user : userFactory(user);
			set(appState, storageKey, user);

			await clientStorage.setItem(storageKey, user);
		}
	});
}

export async function _setCurrentUser(user: IUser, authToken?: string, impersonatorToken?: string) {
	await store.mutateAsync(async (appState) => {
		appState.viewState = assign({}, appState.viewState);
		appState.viewState.currentUserId = user ? user.id : null;

		if (user) {
			appState.viewState.api = assign({}, appState.viewState.api);
			appState.viewState.api.authFailure = null;
			appState.viewState.isImpersonating = !!impersonatorToken;
		}
	});

	if (user) {
		await _processUsers([user]);
		await clientStorage.setItem(CURRENT_USER_ID_STORAGE_KEY, user.id);
		if (authToken) {
			await clientStorage.setItem(AUTH_TOKEN_STORAGE_KEY, authToken);
		}

		if (impersonatorToken) {
			await clientStorage.setItem(IMPERSONATOR_TOKEN_STORAGE_KEY, impersonatorToken);
		}
	} else {
		_canCollaborate = false;
		localStorage.clear();
		await clientStorage.clear();
		await store.mutateAsync(async (appState) => {
			appState.model = { plays: {}, users: {}, teams: {}, formations: {} };
			appState.viewState = assign({}, appState.viewState);

			appState.viewState.currentUserId = undefined;
			appState.viewState.currentTeamId = undefined;
			appState.viewState.isImpersonating = false;
		});

	}
}

async function _fetchEntity(type: string, id: string): Promise<any>;
async function _fetchEntity(type: string, id: { id: string, playbookId: string }): Promise<any>;
async function _fetchEntity(type: string, id: any) {
	let response;

	switch (type) {
	case 'formation':
		response = await apiGateway.fetchFormation(id.id, id.playbookId);
		break;
	case 'play':
		response = await apiGateway.fetchPlay(id.id, id.playbookId);
		break;
	case 'team':
		response = await apiGateway.fetchTeam(id);
		break;
	case 'user':
		response = await apiGateway.fetchUser(id);
		break;
	default:
		return;
	}

	const document = response[type];

	return document && ENTITY_FACTORIES[type](document);
}

// from: https://stackoverflow.com/questions/12168909/blob-from-dataurl
function dataURItoBlob(dataURI: string) {
	// convert base64 to raw binary data held in a string
	// doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
	const byteString = atob(dataURI.split(',')[1]);

	// separate out the mime component
	const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

	// write the bytes of the string to an ArrayBuffer
	const ab = new ArrayBuffer(byteString.length);

	// create a view into the buffer
	const ia = new Uint8Array(ab);

	// set the bytes of the buffer to the correct values
	for (let i = 0; i < byteString.length; i++) {
		ia[i] = byteString.charCodeAt(i);
	}

	// write the ArrayBuffer to a blob, and you're done
	const blob = new Blob([ab], { type: mimeString });

	return blob;
}

async function uploadImage(base64Image: string, uploadUrl: string, storeToCache = true) {
	const blob = dataURItoBlob(base64Image);
	const uploadResult = await fetch(uploadUrl, {
		headers: {
			'Content-Type': 'image/jpg',
		},
		method: 'PUT',
		body: blob,
	});

	if (uploadResult.ok) {
		const url = `${/https:\/\/[^\/]+\/[^\?]+/.exec(uploadUrl)[0]}?${Date.now()}`; // cache buster

		if(storeToCache) {
			storeCachedImage(url, base64Image);
		}

		return url
	} else {
		// console.log('S3 status: ' + uploadResult.statusText);
		// console.log(await uploadResult.text());
		throw new Error('S3 upload failure: ' + uploadResult.statusText);
	}
}

const RESTRICTED_PATHS = [
	/\/id(\/|$)/,
	/\/createdById(\/|$)/,
	/\/dateLastModified(\/|$)/,
	/\/itemVersion(\/|$)/,
	/\/lastModifiedById(\/|$)/,
];

function cleanPatch(patch: IPatchOperation[], restrictedPaths?: RegExp[]) {
	const restricted = restrictedPaths ? RESTRICTED_PATHS.concat(restrictedPaths) : RESTRICTED_PATHS;

	return filter(patch, (op) => !some(restricted, (exp) => exp.exec(op.path)));
}

import { assign } from 'lodash';
import { IModel } from 'playmaker-team-common/dist/shared/interfaces';
import { createFactory } from 'playmaker-team-common/dist/shared/modelFactory';
import { default as validator } from 'playmaker-team-common/dist/shared/validator';
import { default as valueFilters } from 'playmaker-team-common/dist/shared/valueFilters';
import { default as coreModel, ICoreModel } from './coreModel';
import { default as userProfileFactory, IUserProfile } from './userProfile';

export interface IUser extends ICoreModel {
	email?: string;
	firstName: string;
	lastName: string;
	fullName: string;
	initials: string;
	emailOptIn: boolean;
	role: string;
	profile: IUserProfile;
}

export default createFactory<IUser>('User', assign({}, coreModel, {
	id: { // this may be set by the server
	},
	email: {
		setFilters: [valueFilters.clean, valueFilters.lowerCase],
		rules: validator.email(false),
	},
	emailOptIn: {
		defaultValue: false,
		setFilters: valueFilters.toBoolean,
	},
	firstName: {
	},
	fullName: {
		writable: false,
		nonSerialized: true,
	},
	_get_fullName() {
		return [this.firstName, this.lastName].join(' ');
	},
	initials: {
		writable: false,
		nonSerialized: true,
	},
	_get_initials() {
		const firstName = this.firstName || '';
		const lastName = this.lastName || '';

		return `${firstName && firstName[0].toUpperCase()}${lastName && lastName[0].toUpperCase()}`;
	},
	lastName: {
	},
	profile: {
		setFilters: valueFilters.toModel<IUserProfile>(userProfileFactory),
		rules: validator.validModel(userProfileFactory.rules()),
		defaultValue: {},
	},
	role: {
		setFilters: valueFilters.clean,
		writable: false,
	},
}));

import { assign } from 'lodash';
import { IModel, StringDictionary } from 'playmaker-team-common/dist/shared/interfaces';
import { createFactory } from 'playmaker-team-common/dist/shared/modelFactory';
import { default as validator } from 'playmaker-team-common/dist/shared/validator';
import { default as valueFilters } from 'playmaker-team-common/dist/shared/valueFilters';
import { GamePhase } from './diagramModel';
import { default as routedDiagramItemModel, IRoutedDiagramItem } from './routedDiagramItemModel';
import { default as sortableModel, ISortable } from './sortableModel';

export enum PlayerSymbol {
	circle,
	square,
	triangle,
	star,
	ball,
}

export enum PlayerShading {
	full,
	left,
	right,
	leftRight,
	middle,
	top,
	bottom,
	none,
}

export enum PlayerZoneShape {
	ellipse_circle,
	ellipse_vertical,
	ellipse_horizontal,
	rectangle_square,
	rectangle_vertical,
	rectangle_horizontal,
}

export const enum PlayerRoles {
	none = 0,
	line = 1 << 0,
	center = 1 << 1,
	guard = 1 << 2,
	tackle = 1 << 3,
}

export interface IPlayer extends IRoutedDiagramItem, ISortable {
	color: number;
	label: string;
	renderLabel?: string; // this is a transient only property
	note?: string;
	role: PlayerRoles;
	shading?: PlayerShading;
	symbol: PlayerSymbol;
	zoneScale?: number;
	zoneShape?: PlayerZoneShape;
	getDefaultSymbol: (phase: GamePhase) => PlayerSymbol;
	isRole: (role: PlayerRoles) => boolean;
	lineRole: () => PlayerRoles;
}

export const playerModel = assign({}, routedDiagramItemModel, sortableModel, {
	color: {
		setFilters: valueFilters.toNumber,
	},
	label: {
		setFilters: [valueFilters.clean, (val: string) => val === '' ? undefined : val, (val: string) => val && val.substring(0, 2)],
	},
	renderLabel: {

	},
	note: {
		setFilters: [(val: string) => val === '' ? undefined : val],
	},
	role: {
		setFilters: valueFilters.toNumber,
		defaultValue: PlayerRoles.none,
	},
	shading: {
		setFilters: valueFilters.toNumber,
	},
	symbol: {
		setFilters: valueFilters.toNumber,
		defaultValue: PlayerSymbol.circle,
	},
	zoneScale: {
		setFilters: valueFilters.toNumber,
	},
	zoneShape: {
		setFilters: valueFilters.toNumber,
	},
	getDefaultSymbol(posture: GamePhase) {
		if (posture === GamePhase.Defense) {
			return PlayerSymbol.triangle;
		} else if ((this.role & PlayerRoles.center) === PlayerRoles.center) {
			return PlayerSymbol.square;
		} else {
			return PlayerSymbol.circle;
		}
	},
	isRole(role: PlayerRoles) {
		return (this.role & role) === role;
	},
	lineRole() {
		return (this.role & PlayerRoles.center) === PlayerRoles.center ? PlayerRoles.center : (this.role & PlayerRoles.guard) === PlayerRoles.guard ? PlayerRoles.guard : (this.role & PlayerRoles.tackle) === PlayerRoles.tackle ? PlayerRoles.tackle : PlayerRoles.none;
	},
});

export default createFactory<IPlayer>('Player', playerModel);

import { assign } from 'lodash';
import { createFactory } from 'playmaker-team-common/dist/shared/modelFactory';
import { default as diagramModel, GamePhase, IDiagram } from './diagramModel';
import { default as sortableModel, ISortable } from './sortableModel';
import { default as valueFilters } from 'playmaker-team-common/dist/shared/valueFilters';

export interface IFormation extends IDiagram, ISortable {
	color?: number;
}

export default createFactory<IFormation>('Formation', assign({}, diagramModel, sortableModel, {
	color: {
		setFilters: [valueFilters.toNumber]
	},
	getClassName() {
		if (this.phase === GamePhase.SpecialTeams) {
			return ['formation', this.getPhaseClass(), this.getPostureClass(), this.getUnitClass()].filter(c => !!c).join(' ');
		} else {
			return ['formation', this.getPhaseClass(), this.getPostureClass(), this.getContainerOffsetClass()].filter(c => !!c).join(' ');
		}
	},
}));

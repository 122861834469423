import { IModel } from 'playmaker-team-common/dist/shared/interfaces';
import { createFactory } from 'playmaker-team-common/dist/shared/modelFactory';
import { ModelSet } from 'playmaker-team-common/dist/shared/modelSet';
import { default as validator } from 'playmaker-team-common/dist/shared/validator';
import { default as valueFilters } from 'playmaker-team-common/dist/shared/valueFilters';
import { default as playListFactory, IPlayList } from './playList';
import { default as tagFactory, ITag } from './tag';
import { default as routeTreeFactory, IRouteTree } from './routeTree';

export interface IPlaybookSettings extends IModel {
	categories: ModelSet<ITag>;
	fieldKey: string;
	playLists: ModelSet<IPlayList>;
	routeTree?: IRouteTree;
	schemaVersion?: number;
}

export default createFactory<IPlaybookSettings>('PlaybookSettings', {
	categories: {
		setFilters: valueFilters.toModelSet(tagFactory),
		rules: validator.validModel(tagFactory.rules()),
		defaultValue: {},
	},
	fieldKey: {
		setFilters: valueFilters.clean,
	},
	playLists: { // play lists with the id being the id of the formation or category
		setFilters: valueFilters.toModelSet(playListFactory),
		rules: validator.validModel(playListFactory.rules()),
		defaultValue: {},
	},
	routeTree: {
		setFilters: valueFilters.toModel<IRouteTree>(routeTreeFactory),
		rules: validator.validModel(routeTreeFactory.rules())
	},
	schemaVersion: {
		setFilters: valueFilters.toNumber,
		defaultValue: 1,
	},
});

import { filter, map, orderBy } from 'lodash';
import { default as valueFilters } from 'playmaker-team-common/dist/shared/valueFilters';
import React, {useCallback, useState} from 'react';
import { current as getCurrentContext } from '../componentContext';
import { SystemFeature } from '../models/subscriptionPlan';
import { default as teamMemberFactory, ITeamMember, TeamRole } from '../models/teamMember';
import { _s, StringKey } from '../strings';

interface Props {
	alertList: any;
	isPlayerList: boolean;
	onEditClick: (id: string) => void;
	teamMembers: ITeamMember[];
	selectedMemberIds: string[];
	saveTeamMember: (teamMember: ITeamMember) => Promise<boolean>;
	sendInvites: (teamMembers: ITeamMember[]) => Promise<void>;
	deleteMembers: (teamMembers: ITeamMember[]) => void;
	setSelectedMemberIds: (teamMemberIds: string[]) => void;
	sortKey: string;
	sortDirection: number;
	sortList: (e) => void;
}

function createPendingTeamMember() {
	return teamMemberFactory({ });
}

export function RosterList({ alertList, deleteMembers, isPlayerList, onEditClick, saveTeamMember, selectedMemberIds = [], setSelectedMemberIds, sendInvites, sortDirection, sortKey, sortList, teamMembers }: Props) {
	const [saveAttempted, setSaveAttempted] = useState(false);
	const [pendingTeamMember, setPendingTeamMember] = useState(createPendingTeamMember);
	const { currentSubscription, currentUser } = getCurrentContext();
	const sortedMembers = orderBy(teamMembers, [sortKey], [sortDirection === -1 ? 'desc' : 'asc']);
	const invitableMembers = filter(teamMembers, (tm: ITeamMember) => !tm.userId && tm.email && selectedMemberIds.indexOf(tm.id) !== -1);
	const deletableMembers = filter(teamMembers, (tm: ITeamMember) => tm.role !== TeamRole.Owner && selectedMemberIds.indexOf(tm.id) !== -1);
	const selectableMembers = filter(teamMembers, (tm) => tm.userId !== currentUser.id);

	const onChange = useCallback((e, path: string) => {
		if (path === 'email' && !currentSubscription) {
			return;
		}

		const newVal = (e.type === 'blur') ? valueFilters.clean(e.target.value) : e.target.value;
		const mutatedMember = teamMemberFactory(pendingTeamMember);

		mutatedMember.setAt(path, newVal);
		setPendingTeamMember(mutatedMember);

	}, [currentSubscription, pendingTeamMember]);

	const onAddClick = async () => {
		if (pendingTeamMember.isValid('email') && pendingTeamMember.isValid('firstName') && pendingTeamMember.isValid('lastName')) {
			if (await saveTeamMember(pendingTeamMember)) {
				setSaveAttempted(false);
				setPendingTeamMember(createPendingTeamMember());
			}
		} else {
			setSaveAttempted(true);
		}
	}

	const onInviteClick = useCallback((members?: ITeamMember[]) => {
		if (!currentSubscription || !currentSubscription.isActive() || !currentSubscription.supportsFeature(SystemFeature.collaboration)) {
			return;
		}

		members = members || invitableMembers;

		if (members && members.length) {
			sendInvites(members);
		}
	}, [currentSubscription, sendInvites, invitableMembers]);

	const onDeleteClick = useCallback((members?: ITeamMember[]) => {
		members = members || deletableMembers;

		if (members && members.length) {
			deleteMembers(members);
		}
	}, [deleteMembers, deletableMembers])

	const onQuickAddKeyUp = (e: React.KeyboardEvent) => {
		if (e.key === 'Enter') {
			onAddClick();
		}
	}

	const toggleSelectAllMembers = useCallback(() => {
		if (selectableMembers.length) {
			if (selectedMemberIds.length === selectableMembers.length) {
				setSelectedMemberIds([]);
			} else {
				setSelectedMemberIds(map(selectableMembers, (tm) => tm.id));
			}
		}
	}, [selectableMembers, selectedMemberIds, setSelectedMemberIds, teamMembers]);

	const toggleSelectedMember = useCallback((teamMember: ITeamMember) => {
		const idx = selectedMemberIds.indexOf(teamMember.id);
		const updatedMembers: string[] = [].concat(selectedMemberIds);

		if (idx === -1) {
			updatedMembers.push(teamMember.id);
		} else {
			updatedMembers.splice(idx, 1);
		}

		setSelectedMemberIds(updatedMembers);
	}, [selectedMemberIds, setSelectedMemberIds]);

	return <React.Fragment>
		{ alertList }
		<div className="content scrollable">
			<div className="row quickAdd" onKeyUp={ onQuickAddKeyUp }>
				<input type="text" placeholder={ _s(StringKey.FIRST_NAME) } value={ pendingTeamMember.firstName || '' } onChange={ (e) => onChange(e, 'firstName') } onBlur={ (e) => onChange(e, 'firstName') } />
				<input type="text" placeholder={ _s(StringKey.LAST_NAME) } value={ pendingTeamMember.lastName || '' } onChange={ (e) => onChange(e, 'lastName') } onBlur={ (e) => onChange(e, 'lastName') } />
				<input type="text" placeholder={ _s(StringKey.EMAIL) } className={ !pendingTeamMember.isValid('email') && saveAttempted ? 'invalid' : null } value={ pendingTeamMember.email || '' } onChange={ (e) => onChange(e, 'email') } onBlur={ (e) => onChange(e, 'email') } />
				<a className={ pendingTeamMember.firstName ? 'button' : 'button disabled' } onClick={ onAddClick }><span className="icon addItem"></span></a>
			</div>
			<div className="group rosterTable">
				<div className="list">
					<header>
						<div className="row sortable">
							<div className="checkbox">
								<label className={ selectableMembers.length && selectedMemberIds.length === selectableMembers.length ? 'on' : null } onClick={ toggleSelectAllMembers }><span className="icon badge checkmark"></span></label>
							</div>
							<span id="sort-firstName" className={ sortKey === 'firstName' ? 'firstName sorted' : 'firstName'} onClick={ sortList }>{ _s(StringKey.FIRST_NAME) }</span>
							<span id="sort-lastName" className={ sortKey === 'lastName' ? 'lastName sorted' : 'lastName'} onClick={ sortList }>{ _s(StringKey.LAST_NAME) }</span>
							<span id="sort-position" className={ sortKey === 'position' ? 'rosterPosition sorted' : 'rosterPosition'} onClick={ sortList }>{ isPlayerList ? _s(StringKey.POSITION) :  _s(StringKey.TITLE) }</span>
							<span className="status">{ _s(StringKey.STATUS) }</span>
							<span className="spacer"></span>
						</div>
					</header>
					{
						map(sortedMembers, (teamMember: ITeamMember) => {
							const key = `teamMember-${teamMember.id}`;
							const isCurrentUser = teamMember.userId === currentUser.id;
							let statusElement;

							if (isCurrentUser) {
								statusElement = <span className="status"><span className="badge status">{ _s(StringKey.YOU) }</span></span>;
							} else if (!teamMember.email) {
								statusElement = <span className="status"><a className="badge error" onClick={ () => onEditClick(teamMember.id) }>{ _s(StringKey.NO_EMAIL) }</a></span>;
							} else if (!teamMember.userId && !teamMember.inviteCode) {
								statusElement = <span className="status"><a className={ sendInvites ? 'badge action send' : 'badge action disabled'} onClick={ () => onInviteClick([teamMember]) }>{ _s(StringKey.SEND_INVITE) }</a></span>;
							} else if (!teamMember.userId && teamMember.inviteCode) {
								statusElement = <span className="status"><a className={ sendInvites ? 'badge action resend' : 'badge action disabled'} onClick={ () => onInviteClick([teamMember]) }>{ _s(StringKey.RESEND_INVITE) }</a></span>;
							} else if ( false ) {
							} else {
								statusElement = <span className="status"><span className="badge status">{ _s(StringKey.ACCEPTED) }</span></span>;
							}

							return <div className="row" key={ key }>
								<div className="checkbox">
									<label className={ isCurrentUser ? 'disabled' : selectedMemberIds.indexOf(teamMember.id) !== -1 ? 'on' : null } onClick={ isCurrentUser ? undefined : () => toggleSelectedMember(teamMember) }><span className="icon badge checkmark"></span></label>
								</div>
								<span className="firstName">{ isCurrentUser ? currentUser.firstName : teamMember.firstName }</span>
								<span className="lastName">{ isCurrentUser ? currentUser.lastName : teamMember.lastName }</span>
								<span className="rosterPosition">{ teamMember.position }</span>
								{ statusElement }
								<a className="button" onClick={ () => onEditClick(teamMember.id) }><span className="icon infoSmall"></span></a>
							</div>;
						})
					}
				</div>
			</div>
		</div>
		<footer className={ invitableMembers.length || deletableMembers.length ? 'multiSelect in' : 'multiSelect' }>
			<div className="row">
				<a className={ currentSubscription && currentSubscription.isActive() && currentSubscription.supportsFeature(SystemFeature.collaboration) ? 'button basic' : 'button basic locked' } onClick={ () => onInviteClick() }><span>{ `${_s(StringKey.SEND_INVITES)} (${invitableMembers.length})` }</span></a>
				<a className="button basic delete" onClick={ () => onDeleteClick() }><span>{ `${_s(StringKey.DELETE)} (${deletableMembers.length})` }</span></a>
			</div>
		</footer>
	</React.Fragment>;
}
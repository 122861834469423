import { assign, filter, forEach, map, sortBy } from 'lodash';
import * as React from 'react';
import * as actions from '../actions';
import { getPlaybookPermissions } from '../authorizationHelper';
import { current as getCurrentContext } from '../componentContext';
import { default as formationFactory, IFormation } from '../models/formation';
import { IPlaybook } from '../models/playbook';
import { PlaybookAccess } from '../models/playbookMember';
import { SystemFeature } from '../models/subscriptionPlan';
import { default as teamFactory } from '../models/team';
import { TeamRole } from '../models/teamMember';
import * as store from '../store';
import { IDragData, IDragSpec } from '../store';
import { _s, StringKey } from '../strings';
import * as viewManager from '../viewManager';
import { BillingModal } from './billing';
import { ConfirmModal } from './confirmModal';
import { ConnectionStatus } from './connectionStatus';
import { DeleteConfirmModal } from './deleteConfirmModal';
import { DragDrop, IDragSourceParams, IDropTargetParams } from './dnd';
import { OptionalWrapper } from './optionalWrapper';
import { Page, PageState } from './page';
import { PlaybookEdit } from './playbookEdit';
import { PrintModal } from './print';
import { Redeem } from './redeem';
import { Spinner } from './spinner';
import { HelpButton } from './helpButton';

class PlaybookRow extends React.Component<any, any> {
	private _dropTarget;

	constructor(props) {
		super(props);

		this.canDrop = this.canDrop.bind(this);
		this.onDrop = this.onDrop.bind(this);

		this.setDropTarget = this.setDropTarget.bind(this);
	}

	public canDrop(data: IDragData) {
		const { playbook } = this.props;

		return data.type === 'row' && data.info.playbook && data.info.playbook.id !== playbook.id;
	}

	public onDrop(data: IDragData) {
		const { playbook, onDrop } = this.props;

		if (onDrop) {
			onDrop(data.info.playbook.id, playbook.id);
		}
	}

	public setDropTarget(el) {
		const { addDropTarget, removeDropTarget } = this.props;

		if (!addDropTarget) {
			return;
		}

		if (this._dropTarget) {
			removeDropTarget(this._dropTarget);
		}

		this._dropTarget = el;

		if (this._dropTarget) {
			addDropTarget(this._dropTarget, this.canDrop, this.onDrop);
		}
	}

	public render() {
		const { teamPermissions } = getCurrentContext();
		const { className, playbook, teamColor, onRowClick, onAccessClick, onInfoClick, onPrintClick, onPointerDown, onTouchStart, onMouseDown, rowNumber } = this.props;

		return <div className={ className } ref={ this.setDropTarget }>
			<span onClick={ onRowClick }><div className="graphic" style={{ backgroundColor: teamColor }}>{ rowNumber }</div><span className="playbookName">{ playbook.name }</span></span>
			{ onPrintClick ? <a className="button" onClick={ onPrintClick }><span className="icon printSmall"></span></a> : null }
			{ onAccessClick ? <a className="button" onClick={ onAccessClick }><span className="icon teamAccess"></span></a> : null }
			{ onInfoClick ? <a className="button" onClick={ onInfoClick }><span className="icon infoSmall"></span></a> : null }
			{ teamPermissions.canAddPlaybooks ? <a className="button" onPointerDown={ onPointerDown } onTouchStart={ onTouchStart } onMouseDown={ onMouseDown } style={{ touchAction: 'none' }}><span className="icon sortItem"></span></a> : null }
		</div>;
	}
}

const ArchiveAccessAlert = () => {
	const { currentTeam } = getCurrentContext();

	const onClick = () => {
		viewManager.pushModal({
			component: BillingModal,
			props: () => ({ appState: store.appState(), teamId: currentTeam.id }),
			screenName: 'Billing',
		});
	};

	return <div className="alert upgrade interactive" onClick={ onClick }>
		<span className="icon lock"></span><span>{ _s(StringKey.UPGRADE_PLAYBOOK_ARCHIVE_ALERT) }</span>
	</div>;
};

const AccessDeniedFallback = () => {
	return <div className="fallback"><div className="icon lock"></div><h2>{ _s(StringKey.ACCESS_DENIED_PLAYBOOKS_TITLE) }</h2><p>{ _s(StringKey.ACCESS_DENIED_PLAYBOOKS_DESCRIPTION) }</p></div>;
};

interface Props {
	alerts?: store.IAlert[];
	appState: store.IAppState;
}

interface State {
	activePage: number;
	processing?: boolean;
	processingLabel?: string;
}

export class Playbooks extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);

		this.handleAddPlaybook = this.handleAddPlaybook.bind(this);
		this.handleEditPlaybook = this.handleEditPlaybook.bind(this);
		this.handleImportClick = this.handleImportClick.bind(this);
		this.handleItemDrop = this.handleItemDrop.bind(this);
		this.archivePlaybook = this.archivePlaybook.bind(this);
		this.unarchivePlaybook = this.unarchivePlaybook.bind(this);
		this.deletePlaybook = this.deletePlaybook.bind(this);
		this.savePlaybook = this.savePlaybook.bind(this);

		this.state = {
			activePage: 0,
		};
	}

	public async savePlaybook(playbook: IPlaybook, onComplete: () => void) {
		const { appState } = this.props;
		const { viewState } = appState;
		const { currentTeam, teamPermissions } = getCurrentContext();
		const existingPlaybook = currentTeam.playbooks[playbook.id];
		const mutatedTeam = teamFactory(currentTeam);
		let presetFormations: IFormation[];

		if (!existingPlaybook) {
			if (!teamPermissions.canAddPlaybooks) {
				return;
			}
			mutatedTeam.playbooks.add(playbook);
			if (playbook.playersPerSide) {
				// pre-populate formations and categories
				const settings = viewState.settings.playersPerSide[playbook.playersPerSide];

				if (settings) {
					if (settings.presetFormations && settings.presetFormations.length) {
						let formationSortIndex = 0;
						presetFormations = map(settings.presetFormations, (f) => {
							const formation = formationFactory(f);

							formation.playbookId = playbook.id;
							formation.label = _s(f.labelKey);

							formation.sortIndex = formationSortIndex ++;
							return formation;
						});
					}

					if (settings.presetCategories) {
						forEach(settings.presetCategories, (c, i) => {
							playbook.settings.categories.add(assign({ sortIndex: i }, c));
						});
					}

					// default teamMember permissions
					for (const teamMember of filter(mutatedTeam.members.values, (m) => m.userId || m.inviteCode)) {
						const playbookAccess = (teamMember.role === TeamRole.Player ? PlaybookAccess.View : PlaybookAccess.Edit);

						playbook.addMember(teamMember.id, playbookAccess);
					}
				}
			}
		} else {
			if (!getPlaybookPermissions({ playbook, teamPermissions }).canUpdate) {
				onComplete();
				return;
			}
			mutatedTeam.playbooks[playbook.id] = playbook;
		}

		if (actions.validatePlaybook(playbook)) {
			if (actions.validateTeam(mutatedTeam)) {
				await actions.saveTeam(mutatedTeam, `playbook-update-${mutatedTeam.id}`, presetFormations);
				viewManager.popModal();
				if (!existingPlaybook) {
					this.goToPlays(playbook);
				}
			}
		}

		onComplete();
	}

	public archivePlaybook(playbook: IPlaybook, onComplete: () => void) {
		const modalId = `archive-${playbook.id}`;
		const doArchive = async () => {
			actions.deleteModal(modalId);

			const success = await actions.archivePlaybook(playbook);

			if (onComplete) {
				onComplete();
			}

			if (success) {
				actions.popModal();
			}
		};

		actions.pushModal({
			id: modalId,
			component: ConfirmModal,
			props: () => {
				return {
					classNames: ['prompt'],
					title: _s(StringKey.ARCHIVE_PLAYBOOK),
					message: _s(StringKey.ARCHIVED_PLAYBOOKS_NOT_VISIBLE_MESSAGE),
					confirmAction: {
						label: _s(StringKey.ARCHIVE_PLAYBOOK),
						className: '',
						action: doArchive,
					},
					onCancelClick: () => {
						if (onComplete) {
							onComplete();
						}
						actions.deleteModal(modalId);
					},
				};
			},
		});
	}

	public unarchivePlaybook(playbook: IPlaybook) {
		const modalId = `unarchive-${playbook.id}`;

		this.setState({ processing: true, processingLabel: _s(StringKey.UNARCHIVE_PLAYBOOK_MESSAGE) }, async () => {
			const success = await actions.unarchivePlaybook(playbook);
			this.setState({ processing: false, processingLabel: '' }, () => {
				if (success) {
					actions.pushModal({
						id: modalId,
						component: ConfirmModal,
						props: () => {
							return {
								classNames: ['prompt'],
								title: _s(StringKey.ARCHIVE_PLAYBOOK),
								message: _s(StringKey.PLAYBOOK_UNARCHIVED_MESSAGE),
								cancelLabel: _s(StringKey.OK),
								onCancelClick: () => actions.deleteModal(modalId),
							};
						},
					});
				}
			});
		});
	}

	public deletePlaybook(playbook: IPlaybook, onComplete: () => void) {
		const modalId = `delete-${playbook.id}`;
		const { currentTeam, teamPermissions } = getCurrentContext();

		if (!teamPermissions.canRemovePlaybooks) {
			onComplete();
			return;
		}

		const deleteAction = async () => {
			actions.deleteModal(modalId);

			const mutatedTeam = teamFactory(currentTeam);

			mutatedTeam.playbooks.remove(playbook.id);

			if (actions.validateTeam(mutatedTeam)) {
				actions.saveTeam(mutatedTeam, `playbook-delete-${mutatedTeam.id}`);
				viewManager.popModal();
			}
			onComplete();
		};

		actions.pushModal({
			id: modalId,
			component: DeleteConfirmModal,
			props: () => {
				return {
					classNames: ['prompt'],
					title: _s(StringKey.DELETE_PLAYBOOK_QUESTION),
					message: _s(StringKey.DELETE_PLAYBOOK_PROMPT_MESSAGE),
					deleteAction: {
						label: _s(StringKey.DELETE_PLAYBOOK),
						className: 'delete',
						action: deleteAction,
					},
					onCancelClick: () => {
						actions.deleteModal(modalId);
						onComplete();
					}
				};
			},
		});
	}

	public handleAddPlaybook(e) {
		e.stopPropagation();

		const { teamPermissions } = getCurrentContext();
		if (!teamPermissions.canAddPlaybooks) {
			return;
		}

		actions.pushModal({
			component: PlaybookEdit,
			props: () => ({ appState: store.appState(), savePlaybook: this.savePlaybook, deletePlaybook: this.deletePlaybook }),
		});
	}

	public handleImportClick() {
		actions.pushModal({
			component: Redeem,
			props: () => ({ viewState: store.appState().viewState, classNames: ['compact'] }),
		});
	}

	public handleEditPlaybook(initialPage, playbook, e) {
		e.stopPropagation();

		const { teamPermissions } = getCurrentContext();
		if (!getPlaybookPermissions({ playbook, teamPermissions }).canUpdate) {
			return;
		}

		actions.pushModal({
			component: PlaybookEdit,
			props: () => ({ appState: store.appState(), initialPage, playbook, archivePlaybook: this.archivePlaybook, savePlaybook: this.savePlaybook, deletePlaybook: this.deletePlaybook }),
		});
	}

	public handlePrintClick(playbook: IPlaybook, e) {
		e.stopPropagation();

		actions.pushModal({
			component: PrintModal,
			props: () => ({ appState: store.appState(), playbookId: playbook.id }),
		});
	}

	public async handleItemDrop(sourceId: string, targetId: string) {
		const { currentTeam, teamPermissions } = getCurrentContext();
		const mutatedTeam = teamFactory(currentTeam);
		const playbooks = mutatedTeam.playbooks.values;
		const source = mutatedTeam.playbooks[sourceId];
		const target = mutatedTeam.playbooks[targetId];
		const targetSortIndex = target.sortIndex;
		let index = 0;
		const sortedMap = map(playbooks, (playbook) => {
			const sortIndex = playbook === source ? (source.sortIndex > targetSortIndex) ? targetSortIndex - 0.5 : targetSortIndex + 0.5 : playbook.sortIndex;

			return { playbook, sortIndex };
		});

		if (!teamPermissions.canUpdatePlaybooks) {
			return;
		}

		forEach(sortBy(sortedMap, 'sortIndex'), (item) => {
			item.playbook.sortIndex = index++;
		});

		await actions.saveTeam(mutatedTeam, `playbook-sort-${mutatedTeam.id}`);
	}

	public getPageState(key) {
		const { activePage } = this.state;

		if (key === activePage) {
			return PageState.IN;
		}
		// else if(key < activePage) {
		// 	return PageState.OUT;
		// }

		return PageState.OUT;
	}

	public goToPage(page: number) {
		actions.clearAlerts();
		this.setState((previousState) => {
			const newState = assign({}, previousState) as State;

			newState.activePage = page;

			return newState;
		});
	}

	public goToPlays(playbook: IPlaybook) {
		viewManager.pushPath(`/playbook/${playbook.id}`);
	}

	public render() {
		const { appState } = this.props;
		const { viewState } = appState;
		const { activePage, processing, processingLabel } = this.state;
		const { currentSubscription, currentTeam, playbooks, teamPermissions, variant } = getCurrentContext();
		const teamColor = currentTeam && `#${currentTeam.settings.color}`;
		const accessFallback = (!teamPermissions || !teamPermissions.canAddPlaybooks) && !playbooks.length ? <AccessDeniedFallback /> : null;
		const playbookRows = [];
		const archiveRows = [];
		const archiveProhibited = !currentSubscription || !currentSubscription.isActive() || !currentSubscription.supportsFeature(SystemFeature.collaboration);
		let index = 0;

		forEach(sortBy(playbooks, 'sortIndex'), (playbook: IPlaybook) => {
			const playbookPermissions = getPlaybookPermissions({ playbook, teamPermissions });

			if (playbookPermissions.canView) {
				if (playbook.isArchived) {
					archiveRows.push(<div key={playbook.id} className="row archived">
						<span>
							<div className="graphic"><span className="icon playbookArchived"></span></div>
							<span className="playbookName">{ playbook.name }</span>
						</span>
						<a className="button" onClick={ () => this.unarchivePlaybook(playbook) }><span className="unarchiveLabel">{ _s(StringKey.UNARCHIVE) }</span><span className="icon playbookUnarchive"></span></a>
					</div>);
				} else {
					const onAccessClick = playbookPermissions.canUpdate ? this.handleEditPlaybook.bind(this, 1, playbook) : undefined;
					const onInfoClick = playbookPermissions.canUpdate ? this.handleEditPlaybook.bind(this, 0, playbook) : undefined;
					const onPrintClick = this.handlePrintClick.bind(this, playbook);
					const sortIndex = index;
					const itemIndex = ++index;
					const dragSpec: IDragSpec = {
						component: () => <div className="group playbookTable"><PlaybookRow playbook={ playbook } onAccessClick={ onAccessClick } onInfoClick={ onInfoClick} onPrintClick={ onPrintClick } team={ currentTeam } teamColor={ teamColor } className="row dragging" /></div> ,
						getDragLayout: (touch, currentTarget) => {
							const parent = currentTarget.parentElement;
							const boundingRect = parent.getBoundingClientRect();

							return { x: touch.clientX - boundingRect.left, y: touch.clientY - boundingRect.top, width: boundingRect.width, height: boundingRect.height, constrain: 'x' };
						},
						data: {
							type: 'row',
							info: {
								playbook,
							},
						},
					};

					playbookRows.push(<DragDrop key={ playbook.id }>{
						(params: IDragSourceParams & IDropTargetParams) => {
							const { activeDragSpec, handleMouseDown, handlePointerDown, handleTouchStart, ...dragProps } = params;
							const classNames = ['row'];

							if (activeDragSpec && activeDragSpec.data.info.playbook !== playbook) {
								const dragSpecSortIndex = playbooks.indexOf(activeDragSpec.data.info.playbook);
								classNames.push('dropTarget');
								classNames.push(dragSpecSortIndex < sortIndex ? 'bottom' : 'top');
							}

							return <PlaybookRow
								key={ playbook.id }
								className={ classNames.join(' ') }
								playbook={ playbook }
								team={ currentTeam }
								teamColor={ teamColor }
								onAccessClick={ onAccessClick }
								onInfoClick={ onInfoClick}
								onPrintClick={ onPrintClick }
								onRowClick={ this.goToPlays.bind(this, playbook) }
								onDrop={ this.handleItemDrop }
								onPointerDown={ teamPermissions.canUpdatePlaybooks ? handlePointerDown && handlePointerDown.bind(this, dragSpec) : undefined }
								onTouchStart={ teamPermissions.canUpdatePlaybooks ? handleTouchStart && handleTouchStart.bind(this, dragSpec) : undefined }
								onMouseDown={ teamPermissions.canUpdatePlaybooks ? handleMouseDown && handleMouseDown.bind(this, dragSpec) : undefined }
								rowNumber={ itemIndex }
								{...dragProps }
							/>;
						}
					}</DragDrop>);
				}
			}
		});

		return <React.Fragment>
			{ processing ? <Spinner label={ processingLabel } /> : null }
			<header>
				<div className="actions">
					<a className="button" onClick={ viewManager.popPath }><span className="icon back"></span><span className="teamPlaybooks"><strong>{ currentTeam.name }</strong>{ _s(StringKey.PLAYBOOKS) }</span></a>
				</div>
				<div className="actions">
					<HelpButton flagUrl="https://support.wearetrue.com/hc/en-us/categories/360002082751-Flag-Football-Playmaker-X" tackleUrl="https://support.wearetrue.com/hc/en-us/categories/360002071872-Tackle-Football-Playmaker-X" />
					<ConnectionStatus />
				</div>
			</header>
			<div className="content">
				<div className="view tabbed">
					<OptionalWrapper inclusionTest={ () => teamPermissions.canManageBilling }>
						<header>
							<div className="radio tabs">
								<label className={ activePage === 0 ? 'on' : '' }><span>{ _s(StringKey.ACTIVE) }</span><input type="radio" name="playbooksMode" checked={ activePage === 0 } onChange={ this.goToPage.bind(this, 0) } /></label>
								<label className={ activePage === 1 ? 'on' : '' }><span>{ _s(StringKey.ARCHIVED) }</span><input type="radio" name="playbooksMode" checked={ activePage === 1 } onChange={ this.goToPage.bind(this, 1)} /></label>
							</div>
						</header>
					</OptionalWrapper>

					<div className="content swappable">

						<Page key="page0" pageState={ this.getPageState(0)}>
							<div className="content scrollable">
								<div className="group playbookTable">
									<div className="list">
										<OptionalWrapper inclusionTest={ () => teamPermissions.canAddPlaybooks }>
											<div className="row">
												<a className="button add" onClick={ this.handleAddPlaybook }><span className="icon addPlaybook"></span><span>{ _s(StringKey.NEW_PLAYBOOK) }</span></a>
											</div>
										</OptionalWrapper>
										<OptionalWrapper inclusionTest={ () => teamPermissions.canAddPlaybooks }>
											<div className="row">
												<a className="button import" onClick={ this.handleImportClick }><span className="icon importPlaybook"></span><span>{ _s(StringKey.IMPORT) }</span></a>
											</div>
										</OptionalWrapper>
										{ accessFallback || playbookRows }
										<OptionalWrapper inclusionTest={ () => teamPermissions.canAddPlaybooks }>
											<div className="cta">
												<a className="button basic dark flagOnly" onClick={ () => actions.browseTo(`https://${window.location.hostname}/playpacks`) }><span className="icon freePlaybookSmall"></span><span>{ _s(StringKey.FREE_PLAYBOOK_CTA) }</span><span className="icon navigate"></span></a>
												<a className="button basic dark" onClick={ () => actions.browseTo(`https://${window.location.hostname}/feedback/${variant}.html`) }><span className="icon feedbackSmall"></span><span>{ _s(StringKey.FEEDBACK_CTA) }</span><span className="icon navigate"></span></a>
											</div>
										</OptionalWrapper>
									</div>
								</div>
							</div>
						</Page>

						<Page key="page1" pageState={ this.getPageState(1)}>
							<React.Fragment>
								<div className="alerts" id="alerts">
									{ archiveProhibited ? <ArchiveAccessAlert /> : null }
									{ !archiveProhibited && !viewState.api.canAccessApi ? <div className="alert error"><span className="icon error"></span><span>{ _s(StringKey.PLAYMAKER_CONNECTION_REQUIRED_ALERT_MESSAGE) }</span></div> : null}
								</div>
								<div className="content scrollable">
									{ !archiveProhibited && !viewState.api.canAccessApi ? <div className="fallback centered">
										<div className="icon offlineLarge"></div>
									</div> : null }

									{ !archiveProhibited && viewState.api.canAccessApi && archiveRows.length === 0 ? <div className="fallback centered">
										<h2>{ _s(StringKey.NO_ARCHIVED_PLAYBOOKS) }</h2>
									</div> : null }

									{ viewState.api.canAccessApi && archiveRows.length > 0 ? <div className="group playbookTable">
										<div className="list">
											{ archiveRows }
										</div>
									</div> : null }
								</div>
							</React.Fragment>
						</Page>
					</div>
				</div>
			</div>
		</React.Fragment>;
	}
}

import { IPoint, IVector, vectorUtil } from './models/vector';

export interface ITouch {
	identifier?: number;
	clientX: number;
	clientY: number;
	sourceType: string;
}

// used to simulate touches from mouse events
class VirtualTouch implements ITouch {
	private _clientX: number;
	private _clientY: number;
	private _identifier: number;
	private _sourceType: string;

	constructor(clientX: number, clientY: number, sourceType: string, id?: number) {
		this._clientX = clientX;
		this._clientY = clientY;
		this._identifier = id;
		this._sourceType = sourceType;
	}

	get clientX(): number {
		return this._clientX;
	}
	get clientY(): number {
		return this._clientY;
	}
	get identifier(): number {
		return this._identifier;
	}
	get sourceType(): string {
		return this._sourceType;
	}
}

export const supportsPointer = typeof window !== 'undefined' && ('PointerEvent' in window);
export const supportsTouch = typeof window !== 'undefined' && ('TouchEvent' in window);

/** Finds a touch point specified by its ID in the given list of touch points.
*/
export function findTouchInList(list: TouchList | React.TouchList, id: number): Touch | React.Touch {
	let touch: Touch | React.Touch;
	const length = list.length;

	for (let i = 0; i < length; i++) {
		if (list[i].identifier === id) {
			touch = list.item(i);
			break;
		}
	}
	return touch;
}

export function touchFromEvent(e: MouseEvent | TouchEvent | PointerEvent | React.MouseEvent | React.TouchEvent | React.PointerEvent, sourceType?: string, id?: number): ITouch {
	let result: ITouch;
	const isMouse = e.type.indexOf('mouse') === 0;
	const isPointer = e.type.indexOf('pointer') === 0;

	// Check if we're dealing with a mouse event or a touch event
	if (isMouse || isPointer) {
		const evt = e as MouseEvent || e as React.MouseEvent;
		const pointerEvent = (evt as React.PointerEvent);
		const evtId = (evt as React.PointerEvent).pointerId;

		result = new VirtualTouch(evt.clientX, evt.clientY, isPointer ? pointerEvent.pointerType : e.type, isPointer ? pointerEvent.pointerId : undefined);
	} else {
		let touch: React.Touch;
		// Handle the touch event
		const touchEvent = e as TouchEvent || e as React.TouchEvent;
		if (e.type === 'touchstart') {
			touch = touchEvent.targetTouches[0]; // use the first touch point

		} else if (e.type === 'touchmove') {
			touch = findTouchInList(touchEvent.changedTouches, id) || touchEvent.targetTouches[0]; // use the first touch point because this might have started as a pointer event

		} else if (e.type === 'touchend') {
			touch = findTouchInList(touchEvent.targetTouches, id) || touchEvent.targetTouches[0]; // use the first touch point because this might have started as a pointer event
			if (!touch) {
				// If the touch is no longer active, find it in the changed touches
				touch = findTouchInList(touchEvent.changedTouches, id) || touchEvent.changedTouches[0]; // use the first touch point because this might have started as a pointer event
			}
		}

		if (touch) {
			result = new VirtualTouch(touch.clientX, touch.clientY, sourceType || e.type, id);
		}
	}

	return result;
}

export function getTouchVector(touch1: ITouch, touch2: ITouch): IVector {
	const x: number = touch2.clientX - touch1.clientX;
	const y: number = touch2.clientY - touch1.clientY;

	return vectorUtil.toVector({ x, y });
}

import { map } from 'lodash';
import * as React from 'react';
import { IAction } from '../store';
import { _s, StringKey } from '../strings';

export function ConfirmModal({ title, message, confirmAction, cancelLabel, onCancelClick }: { title: string, message: string, confirmAction?: IAction, cancelLabel?: string, onCancelClick: () => void }) {
	const cancelAction = { label: cancelLabel || _s(StringKey.CANCEL), action: onCancelClick, className: 'cancel' };
	const actions = confirmAction ? [confirmAction, cancelAction] : [cancelAction];
	const buttons = map(actions, (action: IAction) => <a key={ `action-${action.label}`} className={ `button basic ${action.className}` } onClick={ action.action } ><span>{ action.label }</span></a>);

	return <div className="view">
		<header>
			<div className="title">{ title }</div>
		</header>
		<div className="content scrollable">
			<div className="inner">
				<p>{ message }</p>

				<div className="actions">
					{ buttons }
				</div>
			</div>
		</div>
	</div>;
}

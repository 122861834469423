import { filter, first, keys, last, map, sortBy } from 'lodash';
import { IPatchOperation, PatchOpType } from 'playmaker-team-common/dist/shared/interfaces';
import * as React from 'react';
import { default as annotationFactory, IAnnotation } from '../models/annotation';
import { GamePhase } from '../models/diagramModel';
import { default as playerFactory, IPlayer } from '../models/player';
import { EndCapType, IRouteSection, LineStyle } from '../models/routeSection';

interface Props {
	diagramItem: IPlayer | IAnnotation;
	updateItem: (patch: IPatchOperation[]) => void;
}

interface State {
	isPlayer: boolean;
	factory: (doc?: any) => IPlayer | IAnnotation;
}

export class RouteTools extends React.Component<Props, State> {

	constructor(props: Props) {
		super(props);

		const isPlayer = props.diagramItem.getModelName() === 'Player';
		this.state = {
			isPlayer,
			factory: isPlayer ? playerFactory : annotationFactory,
		};
	}

	public handleLineChange(sectionId: string, e) {
		const { diagramItem, updateItem } = this.props;
		const { factory } = this.state;
		const mutatedPlayer = factory(diagramItem);
		const sortedSections = sortBy(mutatedPlayer.route.sections.values, 'sortIndex');
		const firstSection = first(sortedSections);
		const lastSection = last(sortedSections);
		const section: IRouteSection = mutatedPlayer.route.sections[sectionId];

		e.preventDefault();

		section.lineStyle = Number(e.target.value);

		if (section === firstSection) {
			mutatedPlayer.motion = (section.lineStyle === LineStyle.motion);
			section.lineStyle = (section.lineStyle === LineStyle.motion && mutatedPlayer.motion) ? undefined : section.lineStyle; // allows default to be used
		} else if (section === lastSection) {
			mutatedPlayer.lineStyle = (section.lineStyle === LineStyle.dash) ? section.lineStyle : LineStyle.fill;
			section.lineStyle = (section.lineStyle === LineStyle.dash) ? undefined : section.lineStyle; // allows default to be used
		}

		updateItem(mutatedPlayer.getPatch(diagramItem));
	}

	public handleCapChange(sectionId: string, e) {
		const { diagramItem, updateItem } = this.props;
		const { factory } = this.state;
		const mutatedPlayer = factory(diagramItem);
		const sortedSections = sortBy(mutatedPlayer.route.sections.values, 'sortIndex');
		const lastSection = last(sortedSections);
		const section: IRouteSection = mutatedPlayer.route.sections[sectionId];

		e.preventDefault();

		section.endCapType = Number(e.target.value);

		if (section === lastSection) {
			mutatedPlayer.endCapType = section.endCapType;
			section.endCapType = undefined; // allows default to be used
		}

		updateItem(mutatedPlayer.getPatch(diagramItem));
	}

	public render() {
		const { diagramItem } = this.props;
		const sortedSections = sortBy(diagramItem.route.sections.values, 'sortIndex');
		const segments = map(sortedSections, (s: IRouteSection, i: number) => {
			const lineStyle = s.lineStyle || (i === 0 && diagramItem.motion ? LineStyle.motion : (i === sortedSections.length - 1 ? diagramItem.lineStyle : LineStyle.fill));
			const endCapType = s.endCapType || (i === sortedSections.length - 1 ? diagramItem.endCapType : EndCapType.none);

			return <div key={ s.id } className="segment">
				<span>{ `${i + 1}`}</span>
				<div className="radio">
					<label className={ lineStyle === LineStyle.fill ? 'on' : '' }><span className="icon segmentRouteStraight"></span><input type="radio" onChange={ this.handleLineChange.bind(this, s.id) } checked={ lineStyle === LineStyle.fill } value={ LineStyle.fill } /></label>
					<label className={ lineStyle === LineStyle.motion ? 'on' : '' }><span className="icon segmentRouteMotion"></span><input type="radio" onChange={ this.handleLineChange.bind(this, s.id) } checked={ lineStyle === LineStyle.motion } value={ LineStyle.motion } /></label>
					<label className={ lineStyle === LineStyle.dash ? 'on' : '' }><span className="icon segmentRouteDotted"></span><input type="radio" onChange={ this.handleLineChange.bind(this, s.id) } checked={ lineStyle === LineStyle.dash } value={ LineStyle.dash } /></label>
				</div>
				<div className="radio">
					<label className={ endCapType === EndCapType.arrow ? 'on' : '' }><span className="icon segmentCapArrow"></span><input type="radio" onChange={ this.handleCapChange.bind(this, s.id) } checked={ endCapType === EndCapType.arrow } value={ EndCapType.arrow } /></label>
					<label className={ endCapType === EndCapType.bar ? 'on' : '' }><span className="icon segmentCapT"></span><input type="radio" onChange={ this.handleCapChange.bind(this, s.id) } checked={ endCapType === EndCapType.bar } value={ EndCapType.bar }/></label>
					<label className={ endCapType === EndCapType.dot ? 'on' : '' }><span className="icon segmentCapDot"></span><input type="radio" onChange={ this.handleCapChange.bind(this, s.id) } checked={ endCapType === EndCapType.dot } value={ EndCapType.dot }/></label>
					<label className={ endCapType === EndCapType.none ? 'on' : '' }><span className="icon segmentCapNone"></span><input type="radio" onChange={ this.handleCapChange.bind(this, s.id) } checked={ endCapType === EndCapType.none } value={ EndCapType.none }/></label>
				</div>
			</div>;
		});

		return <div className="list scrollable">
			{ segments }
		</div>;
	}
}

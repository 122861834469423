import { assign, filter, find, forEach, map, sortBy } from 'lodash';
import * as React from 'react';
import { current as getCurrentContext } from '../componentContext';
import { DiagramRenderFlags, GamePhase, PlayerLabelMode } from '../models/diagramModel';
import { IFormation } from '../models/formation';
import { IPersonnelGroup } from '../models/personnelGroup';
import { IPlay } from '../models/play';
import { IPlaybook } from '../models/playbook';
import { IPlayer } from '../models/player';
import { ISortable } from '../models/sortableModel';
import { ITag } from '../models/tag';
import { ITeamMember } from '../models/teamMember';
import { _s, StringKey } from '../strings';
import { getUncategorizedCategory } from './categoryButtonList';
import { DiagramControl } from './diagramControl';
import { PlayerPosition } from './playerPosition';
import { IRouteTree, routeTreePlayerFactory } from '../models/routeTree';
import { RouteTree } from './routeTree';

export interface IPrintControlRenderParams {
	className: string;
	getPersonnelInfo?: (play: IPlay, player: IPlayer & ISortable) => { teamMember: ITeamMember, label: string };
	sortPlays: (plays: (IPlay & ISortable)[], playListId: string) => (IPlay & ISortable)[];
	buildPlayLists(categories: ITag[], formations: IFormation[], plays: IPlay[]): { categoryList: any[], formationList: any[] };
}

interface Props {
	children: (params: IPrintControlRenderParams) => any;
	optionValues: {[key: string]: any};
	playbook: IPlaybook;
}

export const PrintPlay = ({className = 'printPlay', play, playCount, fieldOptions, getPersonnelInfo}: { className?: string, play: IPlay, playCount: number, fieldOptions: any, getPersonnelInfo?: any}) => {
	const renderFlags = DiagramRenderFlags.showField | DiagramRenderFlags.showAnnotations | play.renderFlags;

	return <div className={ className }>
		<div className="number">{ ++playCount }</div>
		<div className="info">
			<span className="formationName">{ play.formation }</span>
			<span className="playName">{ play.label }</span>
		</div>
		<div className="inner">
			<div className="graphics">
				<DiagramControl ballLocation={ play.ballLocation } diagram={ play } fieldOptions={ fieldOptions } getPersonnelInfo={ getPersonnelInfo } isMoving={ false } lineOfScrimage={ play.ballLocation.y } renderFlags={ renderFlags } />
			</div>
		</div>
		<div className="notes">
			{ !play.notes ? null : <div className="generalNote">{play.notes}</div> }

			<div className="positionNotes">
				{
					map(sortBy(filter(play.mates.values, (m) => !!m.note), 'sortIndex').reverse(), (mate: IPlayer) => {
						return 	<div className="positionNote" key={mate.id}>
							<div className="text">{ mate.viewValue('note') }</div>
							<div className="positionIcon">
								<svg className="icon" viewBox="0 0 30 30"><svg className="player" x="50%" y="50%"><PlayerPosition player={ mate } color={ `color${mate.color}` } active={ false } isOpponent={ false } /></svg></svg>
							</div>
						</div>;
					})
				}
			</div>
		</div>
	</div>;
};

export const PrintRouteTreeDiagram = ({className = 'printPlay', mateId, routeTree }: { className?: string, mateId?: string, routeTree: IRouteTree }) => {
	const targetMate = mateId && routeTree.mates[mateId] as IPlayer;
	const noteMates = !targetMate? routeTree.mates.values.map(mate => {
		const mapped = routeTreePlayerFactory(mate.toDocument());

		(mapped as any).renderLabel = `${mapped.sortIndex}`;

		return mapped;
	}): [];

	return <div className={ className }>
		<div className="number">{ targetMate? targetMate.sortIndex: '' }</div>
		<div className="info">
			<span className="playName">{ targetMate? targetMate.renderLabel ?? targetMate.label: routeTree.label }</span>
		</div>
		<div className="inner">
			<div className="graphics">
				<RouteTree routeTree={ routeTree } mateId={ mateId } nonInteractive={ true } />
			</div>
		</div>
		<div className="notes">
			{ !routeTree.notes || (targetMate && !targetMate.note ) ? null : <div className="generalNote">{targetMate? targetMate.viewValue('note'): routeTree.viewValue('notes')}</div> }

			{ targetMate? null : <div className="positionNotes">
				{
					map(sortBy(filter(noteMates, (m) => !!m.note), 'sortIndex'), (mate: IPlayer) => {
						return 	<div className="positionNote" key={mate.id}>
							<div className="text"><strong>{ mate.viewValue('label') }</strong> { mate.viewValue('note') }</div> 
							<div className="positionIcon">
								<svg className="icon" viewBox="0 0 30 30"><svg className="player" x="50%" y="50%"><PlayerPosition player={ mate } color={ `color${mate.color}` } active={ false } isOpponent={ false } /></svg></svg>
							</div>
						</div>;
					})
				}
			</div>
			}
		</div>
	</div>;
};

export const PrintFormation = ({formation, formationCount, fieldOptions}: {formation: IFormation, formationCount: number, fieldOptions: any}) => {
	return <div className="printPlay printFormation">
		<div className="number">{ ++formationCount }</div>
		<div className="info">
			<span className="formationName">{ formation.label }</span>
		</div>
		<div className="inner">
			<div className="graphics">
				<DiagramControl ballLocation={ formation.ballLocation } diagram={ formation } fieldOptions={ fieldOptions } isMoving={ false } lineOfScrimage={ 0.5 } renderFlags={ DiagramRenderFlags.showField | DiagramRenderFlags.showAnnotations } />
			</div>
		</div>
		<div className="notes">
			{ !formation.notes ? null : <div className="generalNote">{formation.notes}</div> }

			<div className="positionNotes">
				{
					map(sortBy(filter(formation.mates.values, (m) => !!m.note), 'sortIndex').reverse(), (mate: IPlayer) => {
						return 	<div className="positionNote" key={mate.id}>
							<div className="text">{ mate.viewValue('note') }</div>
							<div className="positionIcon">
								<svg className="icon" viewBox="0 0 30 30"><svg className="player" x="50%" y="50%"><PlayerPosition player={ mate } color={ `color${mate.color}` } active={ false } isOpponent={ false } /></svg></svg>
							</div>
						</div>;
					})
				}
			</div>
		</div>
	</div>;
};

export class PrintControl extends React.Component<Props> {
	private _rootClasses = '';

	constructor(props) {
		super(props);

		this._buildPlayLists = this._buildPlayLists.bind(this);
		this._getCategoryPlays = this._getCategoryPlays.bind(this);
		this._getFormationPlays = this._getFormationPlays.bind(this);
		this._getPersonnelInfo = this._getPersonnelInfo.bind(this);
		this._sortPlays = this._sortPlays.bind(this);

		if (typeof document !== 'undefined') {
			this._rootClasses =  document.getElementById('appRoot').className;
		}

		this._rootClasses = this._rootClasses ? this._rootClasses + ' printWindow light' : 'printWindow light';
	}

	public _getCategoryPlays(category: ITag, plays: IPlay[]) {
		const uncategorizedCategory = getUncategorizedCategory();

		return this._sortPlays(filter(plays, (p) => p.categoryList.indexOf(category.id) !== -1 || (category.id === uncategorizedCategory.id && !p.categoryList.length)), category.id);
	}

	public _getFormationPlays(formation: IFormation, plays: IPlay[]) {
		return this._sortPlays(filter(plays, (p) => p.formation === formation.label), formation.id);
	}

	public _buildPlayLists(categories: ITag[], formations: IFormation[], plays: IPlay[]) {
		let result = { categoryList: [], formationList: [] };

		if (plays.length) {

			if (categories.length === 0 && formations.length === 0) {
				const activePhase = plays[0].phase;
				const label = activePhase === GamePhase.Offense ? _s(StringKey.ALL_OFFENSIVE_PLAYS) : activePhase === GamePhase.Defense ? _s(StringKey.ALL_DEFENSIVE_PLAYS) : activePhase === GamePhase.SpecialTeams ? _s(StringKey.ALL_SPECIAL_TEAMS_PLAYS) : '';

				result = { categoryList: [{ plays: sortBy(plays, 'sortIndex'), label, sortIndex: 0, id: 0 }], formationList: [] };
			} else {
				const categoryList = sortBy(filter(map(categories, (c) => assign({ plays: this._getCategoryPlays(c, plays)}, c.toDocument())), (c) => c.plays.length), 'sortIndex');
				const formationList = sortBy(filter(map(formations, (f) => assign({ plays: this._getFormationPlays(f, plays)}, f.toDocument())), (f) => f.plays.length), 'sortIndex');

				result = { categoryList, formationList };
			}
		}

		return result;
	}

	public _getPersonnelInfo(play: IPlay, player: IPlayer & ISortable) {
		const { optionValues } = this.props;
		const { currentTeam, currentUser } = getCurrentContext();
		const activePersonnelGroup: IPersonnelGroup = find(currentTeam.settings.personnelGroups.values, { id: play.activePersonnelGroup }) || play.personnelGroup;
		const teamMember: ITeamMember = find(currentTeam.members.values, { id: activePersonnelGroup[`player${player.sortIndex}`] });

		if (!teamMember) {
			return null;
		}

		switch (currentUser.profile.playerLabelMode) {
		case PlayerLabelMode.FirstName:
			return { teamMember, label: teamMember.firstName };

		case PlayerLabelMode.LastName:
			return { teamMember, label: teamMember.lastName };

		case PlayerLabelMode.Initials:
			return { teamMember, label: teamMember.initials };

		case PlayerLabelMode.Number:
			return { teamMember, label: teamMember.jerseyNumber };

		default:
			return null;
		}
	}

	public _sortPlays(plays: (IPlay & ISortable)[], playListId: string): (IPlay & ISortable)[] {
		const { playbook } = this.props;
		const playList = playbook.getPlayList(playListId);

		if (playList) {
			const ids = playList.playIdList;
			let result = [];
			const missing = [];

			forEach(plays, (play) => {
				const index = ids.indexOf(play.id);
				if (index !== -1) {
					result.push({ index, play });
				} else {
					missing.push(play);
				}
			});

			result = map(sortBy(result, 'index'), (r) => r.play);

			return result.concat(sortBy(missing, 'sortIndex'));
		} else {
			return sortBy(plays, 'sortIndex');
		}
	}

	public render() {
		const { children, optionValues } = this.props;
		const { currentUser } = getCurrentContext();
		const enablePersonnelInfo = (optionValues.playerNames && optionValues.playerNames.className === 'playerNamesShow' && currentUser.profile.playerLabelMode !== PlayerLabelMode.None);
		const params = {
			className: this._rootClasses,
			buildPlayLists: this._buildPlayLists,
			getPersonnelInfo: enablePersonnelInfo ? this._getPersonnelInfo : null,
			sortPlays: this._sortPlays,
		};

		return children(params);
	}
}

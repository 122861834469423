import { filter, map, sortBy } from 'lodash';
import * as React from 'react';
import { IFormation } from '../models/formation';
import { IPlay } from '../models/play';

interface Props {
	activeFormations: string[];
	allFormationsActive: boolean;
	allFormationsLabel?: string;
	disableEmpty?: boolean;
	formations: IFormation[];
	onFormationClick: (formation: IFormation) => void;
	onAllFormationsClick: () => void;
	plays: IPlay[];
}

export class FormationButtonList extends React.Component<Props> {
	constructor(props: Props) {
		super(props);

		this.handleAllFormationsClick = this.handleAllFormationsClick.bind(this);
	}

	public handleFormationClick(formation: IFormation, e) {
		this.props.onFormationClick(formation);
	}

	public handleAllFormationsClick(e) {
		this.props.onAllFormationsClick();
	}

	public render() {
		const { activeFormations, allFormationsActive, allFormationsLabel, disableEmpty, formations, plays } = this.props;
		const disableAll = disableEmpty && !plays.length;
		const formationButtons = map(sortBy(formations, 'sortIndex'), (formation) => {
			const formationPlays = filter(plays, { formation: formation.label });
			const disabled = disableEmpty && !formationPlays.length;

			return <a key={ formation.id } className={ `button${disabled ? ' disabled' : ''}${activeFormations.indexOf(formation.id) !== -1 ? ' on' : ''}` } onClick={ disabled ? null : this.handleFormationClick.bind(this, formation) }><span>{ formation.label }</span><span>{ formationPlays.length }</span></a>;
		});

		if (allFormationsLabel) {
			formationButtons.unshift(<a key="all-formations" className={ `button all${disableAll ? ' disabled' : ''}${allFormationsActive ? ' on' : ''}` } onClick={ disableAll ? null : this.handleAllFormationsClick }><span>{ allFormationsLabel }</span><span>{ plays.length }</span></a>);
		}

		return <div className="list scrollable">{ formationButtons }</div>;
	}
}

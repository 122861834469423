import { assign, filter, find, forEach, map, sortBy } from 'lodash';
import { IPatchOperation, PatchOpType } from 'playmaker-team-common/dist/shared/interfaces';
import * as React from 'react';
import * as actions from '../actions';
import { current as getCurrentContext } from '../componentContext';
import { PlayerLabelMode } from '../models/diagramModel';
import { default as personnelGroupFactory, IPersonnelGroup } from '../models/personnelGroup';
import { default as playFactory, IPlay } from '../models/play';
import { IPlayer } from '../models/player';
import { ISortable } from '../models/sortableModel';
import { default as teamFactory, ITeam } from '../models/team';
import { ITeamMember, TeamRole } from '../models/teamMember';
import { IUserProfile } from '../models/userProfile';
import { AlertMode, AlertSeverity } from '../store';
import { _s, StringKey } from '../strings';
import * as viewManager from '../viewManager';
import { DragDrop, IDragSourceParams, IDropTargetParams } from './dnd';
import { PlayerPosition } from './playerPosition';
import { RosterDragLabel } from './rosterDragLabel';

const RosterContent = ({ activePersonnelGroup, play, index, member, onPersonnelDrop = undefined, activeDragSpec = undefined, onMouseDown, onPointerDown, onTouchStart, isDragging = false }) => {
	const personnelIndex = activePersonnelGroup.getMemberIndex(member.id);
	const player = find(play.mates.values, { sortIndex: personnelIndex });
	const buttonContent = !player ? <span className="icon drag" id={ index === 0 ? 'tooltip-target-playAssignPositions' : null }><span></span></span> : <svg className="icon" viewBox="0 0 30 30"><svg className="player" x="50%" y="50%"><PlayerPosition player={ player } color={ `color${player.color}` } active={ false } isOpponent={ false } onPersonnelDrop={ onPersonnelDrop } /></svg></svg>;

	return <div className="player"><a className="button" onPointerDown={ onPointerDown } onTouchStart={ onTouchStart } onMouseDown={ onMouseDown }>{ buttonContent }</a><span>{ member.fullName }</span></div>;
};

interface RosterItemProps {
	activePersonnelGroup: IPersonnelGroup;
	onPersonnelDrop: any;
	play: IPlay;
	member: ITeamMember;
	index: number;
}

class RosterItem extends React.Component<RosterItemProps, any> {
	private _dragSpec;

	constructor(props) {
		super(props);

		this._dragSpec = {
			component: RosterDragLabel,
			containerClassName: 'dragName down in',
			props: { label: props.member.fullName },
			getDragLayout: () => ({ x: 0, y: 0}),
			data: {
				type: 'position-assignment',
				info: {
					teamMember: props.member,
				},
			},
		};
	}

	public render() {
		return <DragDrop>{(params: IDragSourceParams & IDropTargetParams) => {
			const { handleMouseDown, handlePointerDown, handleTouchStart, ...dragProps } = params;

			return  <RosterContent
				onPointerDown={ handlePointerDown && handlePointerDown.bind(this, this._dragSpec) }
				onTouchStart={ handleTouchStart && handleTouchStart.bind(this, this._dragSpec) }
				onMouseDown={ handleMouseDown && handleMouseDown.bind(this, this._dragSpec) }
				{ ...this.props }
				{ ...dragProps } />;
		}}</DragDrop>;
	}
}

interface Props {
	play: IPlay;
	team: ITeam;
	userProfile: IUserProfile;
	updatePlay: (patch: IPatchOperation[]) => void;
	updateProfile: (pointer: string, value: any) => void;
	updateTeam: (patch: IPatchOperation[]) => void;
	onPersonnelDrop?: (data) => void;
	onOpen?: () => void;
}

interface State {
}

export class PlayPanelPositions extends React.Component<Props, State> {

	constructor(props) {
		super(props);

		this.goToPersonnelGroups = this.goToPersonnelGroups.bind(this);
		this.goToRoster = this.goToRoster.bind(this);
		this.handlePlayerLabelModeChange = this.handlePlayerLabelModeChange.bind(this);
		// this.handlePersonnelGroupChange = this.handlePersonnelGroupChange.bind(this);
	}

	public componentDidMount() {
		if (this.props.onOpen) {
			this.props.onOpen();
		}
	}

	public handlePlayerLabelModeChange(e) {
		const { updateProfile } = this.props;

		updateProfile('/playerLabelMode', Number(e.target.value));
	}

	// handlePersonnelGroupChange(patch: IPatchOperation[]) {
	// 	const { play, team, updatePlay, updateTeam} = this.props;
	// 	let activePersonnelGroup = play.activePersonnelGroup && team.settings.personnelGroups[play.activePersonnelGroup];

	// 	if(activePersonnelGroup) {
	// 		forEach(patch, (op: IPatchOperation) => {
	// 			op.path = `/settings/personnelGroups/${activePersonnelGroup.id}${op.path}`;
	// 		});

	// 		updateTeam(patch);
	// 	}
	// 	else {
	// 		forEach(patch, (op: IPatchOperation) => {
	// 			op.path = `/personnelGroup${op.path}`;
	// 		});

	// 		updatePlay(patch);
	// 	}
	// }

	public handlePersonnelGroupClick(id: string, e) {
		const { play, updatePlay, updateProfile } = this.props;
		const { model, currentPlaybook, currentTeam } = getCurrentContext();
		const currentPersonnelGroup = currentTeam.settings.personnelGroups[play.activePersonnelGroup];
		const groupPlays = currentPersonnelGroup ? filter(model.plays, { playbookId: currentPlaybook.id, activePersonnelGroup: play.activePersonnelGroup }) : [];
		const groupPlayCount = groupPlays.length;
		const doMainUpdate = () => {
			updatePlay([{ op: PatchOpType.replace, path: '/activePersonnelGroup', value: id }]);

			if (play.personnelGroup && play.personnelGroup.id !== id) {
				updateProfile('/defaultPersonnelGroup', id);
			}
		};

		if (groupPlayCount > 1 && id !== play.personnelGroup.id) {
			const newPersonnelGroup = currentTeam.settings.personnelGroups[id];

			actions.pushAlert({
				message: `You are changing this play from the "${currentPersonnelGroup.label}" Personnel Group to "${newPersonnelGroup.label}." There are ${groupPlayCount - 1} other plays using "${currentPersonnelGroup.name}" - do you want to change them all or just this play?`,
				mode: AlertMode.prompt,
				severity: AlertSeverity.info,
				title: 'Mass Substitution?',
				actions: [{
					label: `Change ${groupPlayCount} Plays`,
					action: async () => {
						doMainUpdate();
						await actions.updatePersonnelGroup(filter(groupPlays, (p) => p.id !== play.id), id);
						actions.popAlert();
					},
				}, {
					label: 'Just this Play',
					action: () => {
						actions.popAlert();
						doMainUpdate();
					},
				}],
			});
		} else {
			doMainUpdate();
		}
	}

	public goToPersonnelGroups(e) {
		const { play } = this.props;
		const { currentPlaybook } = getCurrentContext();
		const playbookId = currentPlaybook.id;

		actions.popPanel();
		viewManager.pushPath(`/playbook/${playbookId}/plays/${play.id}/personnel/${play.phase}`);
	}

	public goToRoster(e) {
		const { play } = this.props;
		const { currentPlaybook } = getCurrentContext();
		const playbookId = currentPlaybook.id;

		actions.popPanel();
		viewManager.pushPath(`/playbook/${playbookId}/plays/${play.id}/roster/`);
	}

	public render() {
		const { onPersonnelDrop, play, team, userProfile } = this.props;
		const { currentPlaybook } = getCurrentContext();
		const playbookId = currentPlaybook.id;
		const personnelGroups: IPersonnelGroup[] = [play.personnelGroup].concat(sortBy(filter(team.settings.personnelGroups.values, { phase: play.phase, playersPerSide: currentPlaybook.playersPerSide }), ['sortIndex']));
		const activePersonnelGroup: IPersonnelGroup = find(personnelGroups, { id: play.activePersonnelGroup }) || play.personnelGroup;
		const playerCount = filter(team.members.values, (m: ITeamMember) => m.role === TeamRole.Player).length;

		return <div className="view">
			<header>
				<div className="title">{ _s(StringKey.ASSIGN_POSITIONS) }</div>
				<div className="actions">
					<a className="button" onClick={ viewManager.popPanel }><span className="icon ok"></span></a>
				</div>
			</header>
			<div className="content assignPositions">
				<div className="inner">
					<div className="group">
						<header>
							<div className="actions">
								<a className="button" onClick={ this.goToPersonnelGroups }><span>{ _s(StringKey.PERSONNEL_GROUPS) }</span><span className="icon edit"></span></a>
							</div>
						</header>
						<div className="list scrollable">
							{
								map(personnelGroups, (group: IPersonnelGroup) => {
									const key = `pg-${group.id}`;

									return <a key={ key } onClick={ this.handlePersonnelGroupClick.bind(this, group.id) } className={ group === activePersonnelGroup ? 'button on' : 'button' }><span>{ group.label || _s(StringKey.PLAY_SPECIFIC) }</span></a>;
								})
							}
						</div>
					</div>
				</div>
				<div className="inner">
					<div className="group">
						<header>
							<div className="actions">
								<a className="button" onClick={ this.goToRoster }><span>{ _s(StringKey.PLAYERS) }</span><span className="icon edit" id="tooltip-target-rosterAddPlayers"></span></a>
							</div>
						</header>
						<div className="list scrollable">
							<div className="grid">
								{
									map(filter(sortBy(team.members.values, ['fullName']), { role: TeamRole.Player }), (member: ITeamMember, idx: number) =>  <RosterItem key={ member.id } activePersonnelGroup={ activePersonnelGroup } index={ idx } member={ member } play={ play } onPersonnelDrop={ onPersonnelDrop } />)
								}
							</div>
						</div>
					</div>
					{ playerCount === 0 ? null : <div className="radio">
						<label className={ userProfile.playerLabelMode === PlayerLabelMode.None ? 'on' : '' }><span dangerouslySetInnerHTML={{ __html: 'Hide' }}></span><input type="radio" name="playerLabelMode" onChange={ this.handlePlayerLabelModeChange } checked={ userProfile.playerLabelMode === PlayerLabelMode.None } value={ PlayerLabelMode.None } /></label>
						<label className={ userProfile.playerLabelMode === PlayerLabelMode.FirstName ? 'on' : '' }><span dangerouslySetInnerHTML={{ __html: 'First<em> Name</em>' }}></span><input type="radio" name="playerLabelMode" onChange={ this.handlePlayerLabelModeChange } checked={ userProfile.playerLabelMode === PlayerLabelMode.FirstName } value={ PlayerLabelMode.FirstName } /></label>
						<label className={ userProfile.playerLabelMode === PlayerLabelMode.LastName ? 'on' : '' }><span dangerouslySetInnerHTML={{ __html: 'Last<em> Name</em>' }}></span><input type="radio" name="playerLabelMode" onChange={ this.handlePlayerLabelModeChange } checked={ userProfile.playerLabelMode === PlayerLabelMode.LastName } value={ PlayerLabelMode.LastName } /></label>
						<label className={ userProfile.playerLabelMode === PlayerLabelMode.Initials ? 'on' : '' }><span dangerouslySetInnerHTML={{ __html: 'Initials' }}></span><input type="radio" name="playerLabelMode" onChange={ this.handlePlayerLabelModeChange } checked={ userProfile.playerLabelMode === PlayerLabelMode.Initials } value={ PlayerLabelMode.Initials } /></label>
					</div> }
				</div>
			</div>
			<a className="button" onClick={ viewManager.popPanel }><span className="icon collapse"></span></a>
		</div>;
	}
}

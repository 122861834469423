import * as React from 'react';
import { IPlayer, PlayerShading, PlayerSymbol } from '../models/player';
import { IDragData } from '../store';
import { supportsPointer, supportsTouch } from '../touchHelper';
import { DropTarget, IDropTargetParams } from './dnd';

interface Props {
	player: IPlayer;
	color: string;
	active: boolean;
	isOpponent: boolean;
	onPersonnelDrop?: any;
	onTouchStart?: any;
	clipId: string;
	symbol: any;
	labelPoint: any;
	addDropTarget: any;
	removeDropTarget: any;
	routeDuration?: { full: number, presnap: number };
}

const PlayerContent = ({ props, params, setRef }: { props: Props, params?: IDropTargetParams, setRef?: any}) => {
	const { clipId, symbol, labelPoint, player, color, active, isOpponent, onTouchStart, routeDuration } = props;

	return <g className={`position ${color} ${PlayerShading[player.shading || 0]}`} onPointerDown={ supportsPointer ? onTouchStart : undefined } onTouchStart={ !supportsPointer && supportsTouch ? onTouchStart : undefined } onMouseDown={ !supportsPointer && !supportsTouch ? onTouchStart : undefined } ref={ setRef }>
		{ clipId ? <rect className="shade" clipPath={ `url(#${clipId})` } x="-16" y="-16" width="32" height="32" /> : null }
		{ symbol }
		<text x={ labelPoint.x } y={ labelPoint.y }>{ player.renderLabel || player.label }</text>
		{ params && params.activeDragSpec ? <g className="dropTarget"><circle cx="0" cy="0" r="18"></circle></g> : null }
		{ routeDuration && routeDuration.full ? <animateMotion id={ `animMotion-${player.id}` } className="animRouteMotion" begin="indefinite" fill="freeze" dur={ `${routeDuration.full}s` }>
			<mpath xlinkHref={ `#animPath-${player.id}-${routeDuration.full}` } />
		</animateMotion> : null }
		{ onTouchStart ? <circle className="symbol hit" cx="0" cy="0" r="14"></circle> : null }
	</g>;
};

class Player extends React.Component<Props, any> {
	private _dropTarget;

	constructor(props) {
		super(props);

		this.canDrop = this.canDrop.bind(this);
		this.onDrop = this.onDrop.bind(this);

		// this.setDropTarget = this.setDropTarget.bind(this);
	}

	public canDrop(data: IDragData) {
		const { player } = this.props;
		return player.sortIndex >= 0 && data.type === 'position-assignment';
	}

	public onDrop(data: IDragData) {
		const { player, onPersonnelDrop } = this.props;
		const { teamMember } = data.info;

		if (onPersonnelDrop) {
			onPersonnelDrop({ player, teamMember });
		}
	}

	public setDropTarget(addDropTarget, removeDropTarget, el) {

		if (!addDropTarget) {
			return;
		}

		if (this._dropTarget) {
			removeDropTarget(this._dropTarget);
		}

		this._dropTarget = el;

		if (this._dropTarget) {
			addDropTarget(this._dropTarget, this.canDrop, this.onDrop);
		}
	}

	public render() {
		const { clipId, symbol, labelPoint, player, color, active, isOpponent, onPersonnelDrop, onTouchStart } = this.props;

		return  <DropTarget>{(params: IDropTargetParams) => {
			return <PlayerContent props={ this.props } params={ params } setRef={ this.setDropTarget.bind(this, params.addDropTarget, params.removeDropTarget) } />;
		}}</DropTarget>;
	}
}

export function PlayerPosition(props) {
	let { player, color, active, isOpponent, onPersonnelDrop, onTouchStart } = props;
	let clipId = isOpponent ? 'clipTriangleOpponent' : 'clipCircle';
	let symbol;
	const labelPoint = { x: 0, y: 2 };

	switch (player.symbol) {
	case PlayerSymbol.circle:
		clipId = 'clipCircle';
		symbol = <circle className="symbol" cx="0" cy="0" r="14" />;
		break;

	case PlayerSymbol.square:
		clipId = 'clipRect';
		symbol = <rect className="symbol" x="-14" y="-14" width="28" height="28" />;
		break;

	case PlayerSymbol.triangle:
		clipId = isOpponent ? 'clipTriangleOpponent' : 'clipTriangle';
		symbol = isOpponent ? <path className="symbol" d="M-1.72,12.63-15.81-10.55a2,2,0,0,1,.68-2.76,2,2,0,0,1,1-.29H14.09a2,2,0,0,1,2,2,2,2,0,0,1-.29,1L1.72,12.63A2,2,0,0,1-1,13.31,2,2,0,0,1-1.72,12.63Z"/>
			: <path className="symbol" d="M1.72-12.63,15.81,10.55a2,2,0,0,1-.68,2.76,2,2,0,0,1-1,.29H-14.09a2,2,0,0,1-2-2,2,2,0,0,1,.29-1L-1.72-12.63A2,2,0,0,1,1-13.31,2,2,0,0,1,1.72-12.63Z"/>;
		labelPoint.y = isOpponent ? -2 : 5;

		break;

	case PlayerSymbol.star:
		clipId = 'clipStar';
		symbol = <polygon id="positionStar" className="symbol" points="0 9.93 -10.7 16.47 -7.79 4.27 -17.32 -3.89 -4.82 -4.89 0 -16.47 4.82 -4.89 17.32 -3.89 7.79 4.27 10.7 16.47 0 9.93"/>;
		labelPoint.y = 4;
		break;

	case PlayerSymbol.ball:
		// special case - this is a player that is like a ball - kind of like a centaur
		color = 'ball';
		clipId = null;
		symbol = <g><path className="border" d="M0-17.32c-2.57,0-10.92,6.74-10.92,17.32S-2.58,17.32,0,17.32,10.93,10.59,10.93,0,2.58-17.32,0-17.32Z"/><path className="symbol" d="M0-17.32c-2.57,0-10.92,6.74-10.92,17.32S-2.58,17.32,0,17.32,10.93,10.59,10.93,0,2.58-17.32,0-17.32ZM.92,6.56H-.93V-6.56H.92Z"/></g>;
		break;

	default:
		break;
	}

	return props.onTouchStart ? <Player clipId={ clipId } symbol={ symbol } labelPoint={ labelPoint } {...props} /> : <PlayerContent props={ { clipId, symbol, labelPoint, ...props} } />;
}

import { Workbox } from 'workbox-window';
import * as store from './store';
import { _s, StringKey } from './strings';

let _updateInterval;
let _intervalMs = 0;
let _pushAlert: (alert: store.IAlert) => void;
let _canUseAppAlert: () => boolean;
let _serviceWorkerRegistration;

export const enum CACHE_STATUS {
	unknown,
	cached,
	updateReady,
	errored,
}

const cacheState = {
	swEnabled: false,
	acEnabled: false,
	status: CACHE_STATUS.unknown,
};

export function init(intervalMs: number, virtualRoot: string, pushAlert: (alert: store.IAlert) => void, canUseAppAlert: () => boolean) {
	if (intervalMs) {
		_intervalMs = intervalMs;
		_pushAlert = pushAlert;
		_canUseAppAlert = canUseAppAlert;

		if ('serviceWorker' in navigator) {
			cacheState.swEnabled = true;
			registerServiceWorker(virtualRoot);
		} else if ('applicationCache' in window) {
			cacheState.acEnabled = true;
			monitorAppCache(window.applicationCache);
		}
	}
}

export function checkForUpdate() {
	try {
		if ('serviceWorker' in navigator) {
			_serviceWorkerRegistration && _serviceWorkerRegistration.update();
		} else if ('applicationCache' in window) {
			const appCache = window.applicationCache as any;
			if (appCache.status === appCache.UPDATEREADY) {
				doUpdate();
			} else {
				appCache.update();
			}
		}
	} catch (err) {
		// TODO: consider if we should do something here
	}
}

export function getCacheState() {
	return cacheState;
}

function reload() {
	const initialSearch = store.appState().viewState.initialLocationSearch;
	const url = `${location.protocol}//${location.host}${location.pathname}${initialSearch}`;

	if (location.href === url) {
		location.reload();
	} else {
		location.href = url;
	}
}

function doUpdate(e?) {
	const message = _s(StringKey.APP_UPDATE_MESSAGE) || 'A new version of Playmaker X is available and will be installed automatically.';

	cacheState.status = CACHE_STATUS.updateReady;

	setTimeout(() => {
		if (!_canUseAppAlert()) {
			alert(message);
			reload();
		} else {
			_pushAlert({
				message,
				mode: store.AlertMode.prompt,
				severity: store.AlertSeverity.info,
				title: _s(StringKey.APP_UPDATE_TITLE) || 'Application Update',
				actions: [{
					label: _s(StringKey.OK),
					action: reload,
				}],
			});
		}
	}, _canUseAppAlert() ? 0 : 5000); // if we haven't bootstrapped - give a little breathing room

}

function onCacheError() {
	cacheState.status = CACHE_STATUS.errored;
}

function monitorAppCache(appCache) {
	appCache.addEventListener('error', onCacheError, false);
	appCache.addEventListener('cached', () => cacheState.status = CACHE_STATUS.cached, false);

	if (appCache.status !== appCache.UNCACHED) {
		cacheState.status = CACHE_STATUS.cached;
	}

	if (appCache.status === appCache.UPDATEREADY) {
		doUpdate();
	} else if (!_updateInterval) {
		appCache.addEventListener('updateready', doUpdate);

		_updateInterval = setInterval(() => {
			try {
				appCache.update();
			} catch (err) {
				onCacheError();
				console.log(err);
			}
		}, _intervalMs);
	}
}

function registerServiceWorker(virtualRoot: string) {
	const wb = new Workbox(`${virtualRoot}/serviceWorker.js`);

	wb.addEventListener('controlling', (event) => {
		cacheState.status = CACHE_STATUS.cached;
	});

	wb.addEventListener('activated', (event) => {
		// ensure that our markup gets cached
		fetch(location.href);
		if (event.isUpdate || event.isExternal) {
			doUpdate();
		}
	});

	// wb.addEventListener('externalactivated', (event) => {
	// 	// ensure that our markup gets cached
	// 	fetch(location.href);
	// 	doUpdate();
	// });

	if (!_updateInterval) {
		navigator.serviceWorker.ready.then((registration) => {
			_serviceWorkerRegistration = registration;

			if (registration && registration.active && registration.active.state === 'activated') {
				cacheState.status = CACHE_STATUS.cached;
			}

			_updateInterval = setInterval(() => {
				try	{
					_serviceWorkerRegistration.update();
				} catch (err) {
					onCacheError();
					console.log(err);
				}
			}, _intervalMs);
		});
	}

	wb.register();
}

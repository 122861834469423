import { assign, every, filter, find, first, map, mapValues, some, sortBy } from 'lodash';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import * as actions from '../actions';
import { current as getCurrentContext } from '../componentContext';
import * as logger from '../logger';
import { GamePhase } from '../models/diagramModel';
import { IFormation } from '../models/formation';
import { IPlay } from '../models/play';
import { IPlaybook } from '../models/playbook';
import { SystemFeature } from '../models/subscriptionPlan';
import { ITag } from '../models/tag';
import { default as userFactory } from '../models/user';
import * as store from '../store';
import { _s, StringKey } from '../strings';
import * as viewManager from '../viewManager';
import { AlertList } from './alert';
import { BillingModal } from './billing';
import { CategoryButtonList, getUncategorizedCategory } from './categoryButtonList';
import { FormationButtonList } from './formationButtonList';
import { Pageable, PageContainer } from './page';
import { PhaseTabs } from './phaseTabs';
import { PrintCallSheet } from './printCallSheet';
import { PrintCallSheetList } from './printCallSheetList';
import { PrintFormationsOverview } from './printFormationsOverview';
import { PrintPlaybook } from './printPlaybook';
import { PrintPMXCalibration, PrintPMXWristbands } from './printPMXWristbands';
import { PrintScoutCards } from './printScoutCards';
import { PrintRouteTree } from './printRouteTree';
import { PrintWristbands } from './printWristbands';
import { PrintWristbandsList } from './printWristbandsList';
import { Spinner } from './spinner';
import { getRouteTree } from './routeTreeProvider';

const enum PrintPlayFilters {
	none = 0,
	phase = 1 << 0,
	group = 1 << 1,
}

interface PlayFilterPanelProps {
	categories: ITag[];
	formations: IFormation[];
	filteredPlays: IPlay[];
	isLocked: boolean;
	plays: IPlay[];
	playbook: IPlaybook;
	options: any;
	onPlaysFiltered: (selectedCategories: ITag[], selectedFormations: IFormation[], phase: GamePhase, allInPhase?: boolean) => void;
	selectableCategories: ITag[];
	selectableFormations: IFormation[];
	selectedCategories: ITag[];
	selectedFormations: IFormation[];
}

interface PlayFilterPanelState {
	activeGroup?: string;
	activePhase: number;
}

class PlayFilterPanel extends React.Component<PlayFilterPanelProps, PlayFilterPanelState> {
	constructor(props: PlayFilterPanelProps) {
		super(props);

		const { variant } = getCurrentContext();

		this._handleCategoryClick = this._handleCategoryClick.bind(this);
		this._handleAllCategoriesClick = this._handleAllCategoriesClick.bind(this);
		this._handleFormationClick = this._handleFormationClick.bind(this);
		this._handleAllFormationsClick = this._handleAllFormationsClick.bind(this);
		this._handlePhaseChange = this._handlePhaseChange.bind(this);

		this.state = {
			activePhase: 0,
			activeGroup: variant === 'tackle' ? 'formations' : 'categories',
		};
	}

	public _handleCategoryClick(category: ITag) {
		const { onPlaysFiltered, selectedCategories } = this.props;
		const { activePhase } = this.state;
		const activeCategories = selectedCategories.concat();
		const existingIndex = activeCategories.indexOf(category);

		if (existingIndex === -1) {
			activeCategories.push(category);
		} else {
			activeCategories.splice(existingIndex, 1);
		}

		onPlaysFiltered(activeCategories, [], activePhase, activeCategories.length === 0);
	}

	public _handleAllCategoriesClick() {
		const { onPlaysFiltered } = this.props;
		const { activePhase } = this.state;
		const activeCategories = [];

		onPlaysFiltered(activeCategories, [], activePhase, true);
	}

	public _handleFormationClick(formation: IFormation) {
		const { onPlaysFiltered, selectedFormations } = this.props;
		const { activePhase } = this.state;
		const activeFormations = selectedFormations.concat();
		const existingIndex = activeFormations.indexOf(formation);
		// const phaseFormations = filter(selectableFormations, { phase: activePhase });

		if (existingIndex === -1) {
			activeFormations.push(formation);
		} else {
			activeFormations.splice(existingIndex, 1);
		}

		onPlaysFiltered([], activeFormations, activePhase, activeFormations.length === 0);
	}

	public _handleAllFormationsClick() {
		const { onPlaysFiltered } = this.props;
		const { activePhase } = this.state;
		const activeFormations = [];

		onPlaysFiltered([], activeFormations, activePhase, true);
	}

	public _handleGroupClick(group: string) {
		if (group !== this.state.activeGroup) {
			this.setState({ activeGroup: group}, () => {
				const { activePhase } = this.state;
				const { onPlaysFiltered, selectableFormations } = this.props;
				const firstFormation = first(sortBy(filter(selectableFormations, { phase: activePhase }), 'sortIndex'));
				const activeCategories = group === 'formations' ? [] : [];
				const activeFormations = group === 'formations' ? firstFormation ? [firstFormation] : [] : [];

				onPlaysFiltered(activeCategories, activeFormations, activePhase, group === 'formations' ? false : true);
			});
		}
	}

	public _handlePhaseChange(phase: GamePhase) {
		if (phase !== this.state.activePhase) {
			const { variant } = getCurrentContext();
			const activeGroup = variant !== 'flag' ? 'formations' : 'categories';

			this.setState({ activeGroup, activePhase: phase }, () => {
				const { onPlaysFiltered, selectableFormations } = this.props;

				const firstFormation = first(sortBy(filter(selectableFormations, { phase }), 'sortIndex'));
				const activeCategories = [];
				const activeFormations = variant === 'tackle' ? firstFormation ? [firstFormation] : [] : [];

				onPlaysFiltered(activeCategories, activeFormations, phase, variant === 'tackle' ? false : true);
			});
		}
	}

	public render() {
		const { categories, formations, isLocked, plays, options, selectedCategories, selectedFormations } = this.props;
		const { variant } = getCurrentContext();
		const { activeGroup, activePhase } = this.state;
		const phaseTabs = (options.filter & PrintPlayFilters.phase) ? <PhaseTabs activePhase={ activePhase } onPhaseChange={ this._handlePhaseChange } variant={ variant } /> : null;
		let groupOptions = null;
		let groupList = null;

		if (options.filter & PrintPlayFilters.group) {
			const phaseLabel = activePhase === GamePhase.Offense ? _s(StringKey.ALL_OFFENSIVE_PLAYS) : activePhase === GamePhase.Defense ? _s(StringKey.ALL_DEFENSIVE_PLAYS) : activePhase === GamePhase.SpecialTeams ? _s(StringKey.ALL_SPECIAL_TEAMS_PLAYS) : '';
			const phasePlays = filter(plays, { phase: activePhase });
			const phaseFormations = filter(formations, { phase: activePhase });
			const phaseCategories = filter(categories, { phase: activePhase }).concat(getUncategorizedCategory());

			groupOptions = <div className="radio tackleOnly">
				<label className={ activeGroup === 'formations' ? 'on' : '' } onClick={ this._handleGroupClick.bind(this, 'formations') }><span>{ _s(StringKey.FORMATIONS) }</span></label>
				<label className={ activeGroup === 'categories' ? 'on' : '' } onClick={ this._handleGroupClick.bind(this, 'categories') }><span>{ _s(StringKey.CATEGORIES) }</span></label>
			</div>;

			if (activeGroup === 'formations') {
				groupList = <div className="group checkmarks"><FormationButtonList
					activeFormations={ map(selectedFormations, (f) => f.id) }
					allFormationsLabel={ variant === 'flag' ? phaseLabel : null }
					allFormationsActive={ !selectedFormations.length }
					disableEmpty={ true }
					formations={ phaseFormations }
					plays={ phasePlays }
					onFormationClick={ this._handleFormationClick }
					onAllFormationsClick={ this._handleAllFormationsClick } />
				</div>;
			} else if (activeGroup === 'categories') {
				groupList = <div className="group checkmarks"><CategoryButtonList
					activeCategories={ map(selectedCategories, (c) => c.id) }
					allCategoriesLabel={ variant === 'flag' ? phaseLabel : null }
					allCategoriesActive={ !selectedCategories.length }
					categories={ phaseCategories }
					disableEmpty={ true }
					plays={ phasePlays }
					onCategoryClick={ this._handleCategoryClick }
					onAllCategoriesClick={ this._handleAllCategoriesClick } />
				</div>;
			} else {
				groupList = <div className="group disabled" />;
			}
		}

		return <div className={ isLocked ? 'content locked' : 'content' }>
			<div className="inner">
				{ phaseTabs }
				{ groupOptions }
				{ groupList }
			</div>
		</div>;
	}

	private _getSelectableCategories() {
		const { plays, selectableCategories } = this.props;
		const { activePhase } = this.state;
		const phaseSelectable = filter(selectableCategories, (c) => (c.phase & activePhase) === activePhase);

		if (filter(plays, (p) => p.phase === activePhase && !p.categoryList.length).length) {
			return phaseSelectable.concat(getUncategorizedCategory());
		}

		return phaseSelectable;
	}
}

interface FormationFilterPanelProps extends Pick<PlayFilterPanelProps, 'formations' | 'isLocked' | 'playbook' | 'plays' | 'options' | 'selectedFormations'> {
	onFormationsFiltered(selectedFormations: IFormation[]): void
}

function FormationFilterPanel({formations, isLocked, onFormationsFiltered, options, plays, selectedFormations }: FormationFilterPanelProps ) {
	const { variant } = getCurrentContext();
	const [activePhase, setActivePhase] = React.useState(GamePhase.Offense);

	React.useEffect(() => {
		const phaseFormations = filter(formations, { phase: activePhase });

		onFormationsFiltered(phaseFormations);
	}, [activePhase])
	
	// const { activeGroup, activePhase } = this.state;
	const handlePhaseChange = (phase) => {
		if (phase !== activePhase) {
			setActivePhase(phase);
		}
	}

	const handleFormationClick = (formation: IFormation) => {
		const activeFormations = selectedFormations.concat();
		const existingIndex = activeFormations.indexOf(formation);

		if (existingIndex === -1) {
			activeFormations.push(formation);
		} else {
			activeFormations.splice(existingIndex, 1);
		}

		onFormationsFiltered(activeFormations); // , phaseFormations.length === activeFormations.length);
	}

	const handleAllFormationsClick = () => {
		const phaseFormations = filter(formations, { phase: activePhase });

		onFormationsFiltered(selectedFormations && selectedFormations.length? []: phaseFormations);
	}
	const phaseTabs = (options.filter & PrintPlayFilters.phase) ? <PhaseTabs activePhase={ activePhase } onPhaseChange={ handlePhaseChange } variant={ variant } /> : null;
	let groupList = null;

	if (options.filter) {
		const phaseLabel = activePhase === GamePhase.Offense ? _s(StringKey.ALL_OFFENSIVE_PLAYS) : activePhase === GamePhase.Defense ? _s(StringKey.ALL_DEFENSIVE_PLAYS) : activePhase === GamePhase.SpecialTeams ? _s(StringKey.ALL_SPECIAL_TEAMS_PLAYS) : '';
		const phaseFormations = filter(formations, { phase: activePhase });

		groupList = <div className="group checkmarks"><FormationButtonList
			activeFormations={ map(selectedFormations, (f) => f.id) }
			allFormationsLabel={ variant === 'flag' ? phaseLabel : null }
			allFormationsActive={ !selectedFormations.length }
			disableEmpty={ false }
			formations={ phaseFormations }
			plays={ plays.filter(p => p.phase === activePhase) }
			onFormationClick={ handleFormationClick }
			onAllFormationsClick={ handleAllFormationsClick } />
		</div>;
	}

	return <div className={ isLocked ? 'content locked' : 'content' }>
		<div className="inner">
			{ phaseTabs }
			{ groupList }
		</div>
	</div>;
}

const PrintOption = ({ name, option, selectedValue, onChange }) => {
	const items = map(option.items, (item) => {
		//return <label key={ item.key } className={ selectedValue === item.key ? 'on' : '' }>
		return <label key={ item.key } className={ selectedValue === item.key ? [item.className, 'on'].join(' ') : item.className }>
			{ item.icon ? <span className={ ['icon', item.icon].join(' ') }></span> : null }
			{ item.label ? <span><strong>{ item.label }</strong>{ item.subLabel ? <span>{ item.subLabel }</span> : <span>&nbsp;</span> }</span> : null }
			<input type="radio" checked={ selectedValue === item.key } name={ name } value={ item.key } onChange={ onChange } />
		</label>;
	});
	const classes = ['radio'];

	if (option.className) {
		classes.push(option.className);
	}

	if (option.style === 'grid') {
		classes.push('grid');
		return <div className="group"><div className="list scrollable"><div className={ classes.join(' ') }>{ items }</div></div></div>;
	} else if (option.style === 'radioLabel') {
		return <div className={ classes.join(' ') }><strong>{ option.label }</strong>{ items }</div>;
	} else {
		return <div className={ classes.join(' ') }>{ items }</div>;
	}
};

interface IOptionValues { [s: string]: any; }
interface PrintOptionsPanelProps {
	complete: boolean;
	options: any;
	optionValues: IOptionValues;
	onOptionsChange: (optionValues: IOptionValues) => void;
}

class PrintOptionsPanel extends React.Component<PrintOptionsPanelProps> {
	constructor(props: PrintOptionsPanelProps) {
		super(props);

		this.handleOptionChange = this.handleOptionChange.bind(this);
	}

	public handleOptionChange(e) {
		const { onOptionsChange, options } = this.props;
		const optionValues = assign({}, this.props.optionValues);

		optionValues[e.target.name] = find(options[e.target.name].items, { key: e.target.value });

		onOptionsChange(optionValues);
	}

	public render() {
		const { options, optionValues } = this.props;

		return <div className="inner">{ map(options, (option, key) => <PrintOption key={ key } name={ key } option={ option } onChange={ this.handleOptionChange } selectedValue={ optionValues[key] && optionValues[key].key } />) }</div>;
	}
}

const PrintPageHeader = ({ canPrint, goBack, onPrintClick, title }) => {
	return <header>
		<div className="actions">
			<a className="button" onClick={ goBack }><span className="icon previous"></span></a>
		</div>
		<h2 className="title">{ title }</h2>
		<div className="actions">
			<a className={ `button${canPrint ? '' : ' disabled'}`} onClick={ onPrintClick }>
				<span className="printButtonLabel">{ _s(StringKey.PRINT) }</span>
				<span className="icon print white"></span>
			</a>
		</div>
	</header>;
};

const PrintRoot = ({ children }) => {
	return ReactDOM.createPortal(
		children,
		document.getElementById('printRoot'),
	);
};

class PMXWristbandsPage extends React.Component<any, any> {
	constructor(props) {
		super(props);

		this.state = { printingCalibration: false };
		this.handlePrintCalibrationClick = this.handlePrintCalibrationClick.bind(this);
		this.handlePrintClick = this.handlePrintClick.bind(this);
	}

	public handlePrintClick() {
		const { onPrintClick } = this.props;
		const { printingCalibration } = this.state;

		if (printingCalibration) {
			this.setState({ printingCalibration: false }, () => {
				setTimeout(onPrintClick, 200);
			});
		} else {
			onPrintClick();
		}
	}

	public handlePrintCalibrationClick() {
		const { onPrintClick } = this.props;
		const { printingCalibration } = this.state;

		if (!printingCalibration) {
			this.setState({ printingCalibration: true }, () => {
				setTimeout(onPrintClick, 200);
			});
		} else {
			onPrintClick();
		}
	}

	public render() {
		const { accessAlert, alertList, fieldOptions, filterProps, goBack, options, playbook, printOptionsProps, setPrintRootChildren } = this.props;
		const { printingCalibration } = this.state;
		const canPrint = !accessAlert && filterProps.filteredPlays.length && printOptionsProps.complete;
		const isLocked = !!accessAlert;

		setPrintRootChildren(printingCalibration ?
			<PrintPMXCalibration
				categories={ filterProps.selectedCategories }
				fieldOptions={ fieldOptions }
				formations={ filterProps.selectedFormations }
				optionValues={ printOptionsProps.optionValues }
				playbook={ playbook }
				plays={ filterProps.filteredPlays }  /> :
			canPrint ?
				<PrintPMXWristbands
					categories={ filterProps.selectedCategories }
					fieldOptions={ fieldOptions }
					formations={ filterProps.selectedFormations }
					optionValues={ printOptionsProps.optionValues }
					playbook={ playbook }
					plays={ filterProps.filteredPlays }  /> :
				null);

		return <React.Fragment>
			<PrintPageHeader canPrint={ canPrint } title={ _s(StringKey.WRISTBANDS) } goBack={ goBack } onPrintClick={ this.handlePrintClick } />
			{ alertList }
			<div className="alerts">
				{ accessAlert }
				{ accessAlert ? null :
					<div className="alert info interactive" onClick={ this.handlePrintCalibrationClick }>
						<span className="icon printCalibration"></span>
						<span>Tap here to calibrate your printer before printing on your perforated wristband inserts</span>
					</div>
				}
			</div>
			<Pageable classNames={ ['printSetup'] } uniquePageClasses={ ['printFilters','printLayouts','printOptions'] } labels={ [_s(StringKey.SELECT), _s(StringKey.LAYOUT), _s(StringKey.OPTIONS)] }>
				<PlayFilterPanel options={ options } { ...filterProps } isLocked={ isLocked } />
				<div className={ isLocked ? 'content locked' : 'content'}><PrintOptionsPanel options={ options.options } { ...printOptionsProps } /></div>
				<div className={ isLocked ? 'content scrollable locked' : 'content scrollable'}><PrintOptionsPanel options={ options.options } { ...printOptionsProps } /></div>
			</Pageable>
		</React.Fragment>;
	}
}

const WristbandsPage = ({ accessAlert, alertList, fieldOptions, filterProps, goBack, onPrintClick, options, playbook, printOptionsProps, setPrintRootChildren }) => {
	const canPrint = !accessAlert && filterProps.filteredPlays.length && printOptionsProps.complete;
	const isLocked = !!accessAlert;

	setPrintRootChildren(canPrint ?
		<PrintWristbands
			categories={ filterProps.selectedCategories }
			fieldOptions={ fieldOptions }
			formations={ filterProps.selectedFormations }
			optionValues={ printOptionsProps.optionValues }
			playbook={ playbook }
			plays={ filterProps.filteredPlays }  /> :
		null);

	return <React.Fragment>
		<PrintPageHeader canPrint={ canPrint } title={ _s(StringKey.WRISTBANDS) } goBack={ goBack } onPrintClick={ onPrintClick } />
		{ alertList }
		<div className="alerts" id="alerts">
			{ accessAlert }
		</div>
		<Pageable classNames={ ['printSetup'] } uniquePageClasses={ ['printFilters','printLayouts','printOptions'] } labels={ [_s(StringKey.SELECT), _s(StringKey.LAYOUT), _s(StringKey.OPTIONS)] }>
			<PlayFilterPanel options={ options } { ...filterProps } isLocked={ isLocked } />
			<div className={ isLocked ? 'content locked' : 'content'}><PrintOptionsPanel options={ options.options } { ...printOptionsProps } /></div>
			<div className={ isLocked ? 'content scrollable locked' : 'content scrollable'}><PrintOptionsPanel options={ options.options } { ...printOptionsProps } /></div>
		</Pageable>
	</React.Fragment>;
};

const WristbandsListPage = ({ accessAlert, alertList, fieldOptions, filterProps, goBack, onPrintClick, options, playbook, printOptionsProps, setPrintRootChildren }) => {
	const canPrint = !accessAlert && filterProps.filteredPlays.length && printOptionsProps.complete;
	const isLocked = !!accessAlert;

	setPrintRootChildren(canPrint ?
		<PrintWristbandsList
			categories={ filterProps.selectedCategories }
			fieldOptions={ fieldOptions }
			formations={ filterProps.selectedFormations }
			optionValues={ printOptionsProps.optionValues }
			playbook={ playbook }
			plays={ filterProps.filteredPlays }  /> :
		null);

	return <React.Fragment>
		<PrintPageHeader canPrint={ canPrint } title={ _s(StringKey.WRISTBANDS) } goBack={ goBack } onPrintClick={ onPrintClick } />
		{ alertList }
		<div className="alerts" id="alerts">
			{ accessAlert }
		</div>
		<Pageable classNames={ ['printSetup'] } uniquePageClasses={ ['printFilters','printLayouts','printOptions'] } labels={ [_s(StringKey.SELECT), _s(StringKey.LAYOUT), _s(StringKey.OPTIONS)] }>
			<PlayFilterPanel options={ options } { ...filterProps } isLocked={ isLocked } />
			<div className={ isLocked ? 'content locked' : 'content'}><PrintOptionsPanel options={ options.options } { ...printOptionsProps } /></div>
			<div className={ isLocked ? 'ccontent scrollable locked' : 'content scrollable'}><PrintOptionsPanel options={ options.options } { ...printOptionsProps } /></div>
		</Pageable>
	</React.Fragment>;
};

const CallSheetPage = ({ accessAlert, alertList, fieldOptions, filterProps, goBack, onPrintClick, options, playbook, printOptionsProps, setPrintRootChildren }) => {
	const canPrint = !accessAlert && filterProps.filteredPlays.length && printOptionsProps.complete;
	const isLocked = !!accessAlert;

	setPrintRootChildren(canPrint ?
		<PrintCallSheet
			categories={ filterProps.selectedCategories }
			fieldOptions={ fieldOptions }
			formations={ filterProps.selectedFormations }
			optionValues={ printOptionsProps.optionValues }
			playbook={ playbook }
			plays={ filterProps.filteredPlays }  /> :
		null);

	return <React.Fragment>
		<PrintPageHeader canPrint={ canPrint } title={ _s(StringKey.CALL_SHEETS) } goBack={ goBack } onPrintClick={ onPrintClick } />
		{ alertList }
		<div className="alerts" id="alerts">
			{ accessAlert }
		</div>
		<Pageable classNames={ ['printSetup'] }  uniquePageClasses={ ['printFilters','printLayouts','printOptions'] } labels={ [_s(StringKey.SELECT), _s(StringKey.LAYOUT), _s(StringKey.OPTIONS)] }>
			<PlayFilterPanel options={ options } { ...filterProps } isLocked={ isLocked } />
			<div className={ isLocked ? 'content locked' : 'content'}><PrintOptionsPanel options={ options.options } { ...printOptionsProps } /></div>
			<div className={ isLocked ? 'content scrollable locked' : 'content scrollable'}><PrintOptionsPanel options={ options.options } { ...printOptionsProps } /></div>
		</Pageable>
	</React.Fragment>;
};

const CallSheetListPage = ({ accessAlert, alertList, fieldOptions, filterProps, goBack, onPrintClick, options, playbook, printOptionsProps, setPrintRootChildren }) => {
	const canPrint = !accessAlert && filterProps.filteredPlays.length && printOptionsProps.complete;
	const isLocked = !!accessAlert;

	setPrintRootChildren(canPrint ?
		<PrintCallSheetList
			categories={ filterProps.selectedCategories }
			fieldOptions={ fieldOptions }
			formations={ filterProps.selectedFormations }
			optionValues={ printOptionsProps.optionValues }
			playbook={ playbook }
			plays={ filterProps.filteredPlays }  /> :
		null);

	return <React.Fragment>
		<PrintPageHeader canPrint={ canPrint } title={ _s(StringKey.CALL_SHEETS) } goBack={ goBack } onPrintClick={ onPrintClick } />
		{ alertList }
		<div className="alerts" id="alerts">
			{ accessAlert }
		</div>
		<Pageable classNames={ ['printSetup'] } uniquePageClasses={ ['printFilters','printLayoutsOptions'] }labels={ [_s(StringKey.SELECT), _s(StringKey.LAYOUT_AND_OPTIONS)] }>
			<PlayFilterPanel options={ options } { ...filterProps } isLocked={ isLocked } />
			<div className={ isLocked ? 'content locked' : 'content'}><PrintOptionsPanel options={ options.options } { ...printOptionsProps } /></div>
		</Pageable>
	</React.Fragment>;
};

const PlaybookPage = ({ accessAlert, alertList, fieldOptions, filterProps, goBack, onPrintClick, options, playbook, printOptionsProps, setPrintRootChildren }) => {
	const canPrint = !accessAlert && filterProps.filteredPlays.length && printOptionsProps.complete;
	const isLocked = !!accessAlert;

	setPrintRootChildren(canPrint ?
		<PrintPlaybook
			categories={ filterProps.selectedCategories }
			fieldOptions={ fieldOptions }
			formations={ filterProps.selectedFormations }
			optionValues={ printOptionsProps.optionValues }
			playbook={ playbook }
			plays={ filterProps.filteredPlays }  /> :
		null);

	return <React.Fragment>
		<PrintPageHeader canPrint={ canPrint } title={ _s(StringKey.PLAYBOOK) } goBack={ goBack } onPrintClick={ onPrintClick } />
		{ alertList }
		<div className="alerts" id="alerts">
			{ accessAlert }
		</div>
		<Pageable classNames={ ['printSetup'] }  uniquePageClasses={ ['printFilters','printLayoutsOptions'] } labels={ [_s(StringKey.SELECT), _s(StringKey.LAYOUT_AND_OPTIONS)] }>
			<PlayFilterPanel options={ options } { ...filterProps } isLocked={ isLocked } />
			<div className={ isLocked ? 'content locked' : 'content'}><PrintOptionsPanel options={ options.options } { ...printOptionsProps } /></div>
		</Pageable>
	</React.Fragment>;
};

const ScoutCardPage = ({ accessAlert, alertList, fieldOptions, filterProps, goBack, onPrintClick, options, playbook, printOptionsProps, setPrintRootChildren }) => {
	const canPrint = !accessAlert && filterProps.filteredPlays.length && printOptionsProps.complete;
	const isLocked = !!accessAlert;

	setPrintRootChildren(canPrint ?
		<PrintScoutCards
			categories={ filterProps.selectedCategories }
			fieldOptions={ fieldOptions }
			formations={ filterProps.selectedFormations }
			optionValues={ printOptionsProps.optionValues }
			playbook={ playbook }
			plays={ filterProps.filteredPlays }  /> :
		null);

	return <React.Fragment>
		<PrintPageHeader canPrint={ canPrint } title={ _s(StringKey.SCOUT_CARDS) } goBack={ goBack } onPrintClick={ onPrintClick } />
		{ alertList }
		<div className="alerts" id="alerts">
			{ accessAlert }
		</div>
		<Pageable classNames={ ['printSetup'] } uniquePageClasses={ ['printFilters','printLayoutsOptions'] } labels={ [_s(StringKey.SELECT), _s(StringKey.LAYOUT_AND_OPTIONS)] }>
			<PlayFilterPanel options={ options } { ...filterProps } isLocked={ isLocked } />
			<div className={ isLocked ? 'content locked' : 'content'}><PrintOptionsPanel options={ options.options } { ...printOptionsProps } /></div>
		</Pageable>
	</React.Fragment>;
};

const FormationsOverviewPage = ({ accessAlert, alertList, fieldOptions, filterProps, goBack, onPrintClick, options, playbook, printOptionsProps, setPrintRootChildren }) => {
	const canPrint = !accessAlert && filterProps.selectedFormations.length && printOptionsProps.complete;
	const isLocked = !!accessAlert;

	setPrintRootChildren(canPrint ?
		<PrintFormationsOverview
			fieldOptions={ fieldOptions }
			formations={ filterProps.selectedFormations }
			optionValues={ printOptionsProps.optionValues }
			playbook={ playbook }  /> :
		null);

	return <React.Fragment>
		<PrintPageHeader canPrint={ canPrint } title={ _s(StringKey.FORMATIONS) } goBack={ goBack } onPrintClick={ onPrintClick } />
		{ alertList }
		<div className="alerts" id="alerts">
			{ accessAlert }
		</div>
		<Pageable classNames={ ['printSetup'] } uniquePageClasses={ ['printFilters','printLayoutsOptions'] } labels={ [_s(StringKey.SELECT), _s(StringKey.LAYOUT_AND_OPTIONS)] }>
			<FormationFilterPanel options={ options } { ...filterProps } isLocked={ isLocked } />
			<div className={ isLocked ? 'content locked' : 'content'}><PrintOptionsPanel options={ options.options } { ...printOptionsProps } /></div>
		</Pageable>
	</React.Fragment>;
};

const RouteTreePage = ({ accessAlert, alertList, fieldOptions, goBack, onPrintClick, options, playbook, printOptionsProps, setPrintRootChildren }) => {
	const canPrint = !accessAlert && printOptionsProps.complete;
	const isLocked = !!accessAlert;

	setPrintRootChildren(canPrint ?
		<PrintRouteTree
			fieldOptions={ fieldOptions }
			optionValues={ printOptionsProps.optionValues }
			playbook={ playbook }
			routeTree={ getRouteTree(playbook) }  /> :
		null);

	return <React.Fragment>
		<PrintPageHeader canPrint={ canPrint } title={ _s(StringKey.ROUTE_TREE) } goBack={ goBack } onPrintClick={ onPrintClick } />
		{ alertList }
		<div className="alerts" id="alerts">
			{ accessAlert }
		</div>
		<Pageable classNames={ ['printSetup'] } uniquePageClasses={ ['printLayoutsOptions'] } labels={ [_s(StringKey.LAYOUT_AND_OPTIONS)] }>
			<div className={ isLocked ? 'content locked' : 'content'}><PrintOptionsPanel options={ options.options } { ...printOptionsProps } /></div>
		</Pageable>
	</React.Fragment>;
};

const HelpPage = ({ alertList, goBack }) => {
	const { variant } = getCurrentContext();

	return <React.Fragment>
		<header>
			<div className="actions">
				<a className="button" onClick={ goBack }><span className="icon previous"></span></a>
			</div>
			<h2 className="title">{ _s(StringKey.PDF_HELP) }</h2>
		</header>
		{ alertList }
		<div className="content scrollable">
			<div className="inner">

				<p className="left">{ _s(StringKey.PDF_STEPS_MESSAGE) }</p>

				<div className="platformSpecificHelp desktopHelp"></div>

				<div className="platformSpecificHelp iosHelp">
					<p className="left">{ `${_s(StringKey.USE_ON_COMPUTER_MESSAGE)} ${window.location.hostname}/app` }</p>
				</div>

				<div className="platformSpecificHelp androidHelp">
					<p className="left">{ `${_s(StringKey.USE_ON_COMPUTER_MESSAGE)} ${window.location.hostname}/app` }</p>
				</div>

				<p><a className="button basic" onClick={ () => actions.browseTo(variant === 'tackle' ? 'https://support.wearetrue.com/hc/en-us/articles/360044359672-Email-a-PDF' : 'https://support.wearetrue.com/hc/en-us/articles/360044806071-Email-a-PDF') }>{ _s(StringKey.SEE_THE_TUTORIAL_VIDEOS) }</a></p>

			</div>
		</div>
	</React.Fragment>;
};

const OptionsPage = ({ alertList, close, gotoHelp, gotoWristbands, gotoWristbandsList, gotoCallSheets, gotoCallSheetsList, gotoPlaybook, gotoScoutCards, gotoFormationsOverview, gotoRouteTree }) => {
	return <React.Fragment>
		<header>
			<div className="actions">
				<a className="button" onClick={ close }><span className="icon cancel"></span></a>
			</div>
			<h2 className="title">{ _s(StringKey.PRINT) }</h2>
		</header>
		{ alertList }
		<div className="content printLanding scrollable">
			<div className="inner">
				<div className="printNavigation">
					<a className="button printHelp" onClick={ gotoHelp }><span className="icon printPdf"></span><span><span className="tapOrClick"></span> { _s(StringKey.LEARN_HOW_TO_CREATE_PDFS) }</span></a>
					{/* { currentUser.role !== 'admin' ? null : <div className="tile printOption">
						<span className="icon printPhoto printPerforatedWristbands"></span>
						<a className="button" onClick={ gotoPerfInserts }><span><strong>{ _s(StringKey.PERFORATED_WRISTBANDS) } <span></span></strong>9 { _s(StringKey.STYLES) }</span><span className="icon navigate"></span></a>
					</div> } */}
					<div className="tile printOption">
						<span className="icon printPhoto printWristbands"></span>
						<a className="button" onClick={ gotoWristbands }><span><strong>{ _s(StringKey.WRISTBANDS) } <span>{ _s(StringKey.DIAGRAMS) }</span></strong>6 { _s(StringKey.STYLES) }</span><span className="icon navigate"></span></a>
					</div>
					<div className="tile printOption">
						<span className="icon printPhoto printWristbandsList"></span>
						<a className="button" onClick={ gotoWristbandsList }><span><strong>{ _s(StringKey.WRISTBANDS) } <span>{ _s(StringKey.LIST) }</span></strong>3 { _s(StringKey.STYLES) }</span><span className="icon navigate"></span></a>
					</div>
					<div className="tile printOption">
						<span className="icon printPhoto printCallSheets"></span>
						<a className="button" onClick={ gotoCallSheets }><span><strong>{ _s(StringKey.CALL_SHEETS) } <span>{ _s(StringKey.DIAGRAMS) }</span></strong>7 { _s(StringKey.STYLES) }</span><span className="icon navigate"></span></a>
					</div>
					<div className="tile printOption">
						<span className="icon printPhoto printCallSheetsList"></span>
						<a className="button" onClick={ gotoCallSheetsList }><span><strong>{ _s(StringKey.CALL_SHEETS) } <span>{ _s(StringKey.LIST) }</span></strong>4 { _s(StringKey.STYLES) }</span><span className="icon navigate"></span></a>
					</div>
					<div className="tile printOption">
						<span className="icon printPhoto printPlaybooks"></span>
						<a className="button" onClick={ gotoPlaybook }><span><strong>{ _s(StringKey.PLAYBOOKS) }</strong>8 { _s(StringKey.STYLES) }</span><span className="icon navigate"></span></a>
					</div>
					<div className="tile printOption">
						<span className="icon printPhoto printScoutCards"></span>
						<a className="button" onClick={ gotoScoutCards }><span><strong>{ _s(StringKey.SCOUT_CARDS) }</strong>3 { _s(StringKey.STYLES) }</span><span className="icon navigate"></span></a>
					</div>
					<a className="button printOption printOptionFormationsOverview tackleOnly" onClick={ gotoFormationsOverview }><span className="icon printFormationsOverview"></span><span><strong>{ _s(StringKey.FORMATIONS_REFERENCE) }</strong>8 { _s(StringKey.STYLES) }</span><span className="icon navigate"></span></a>
					<a className="button printOption printOptionRouteTree" onClick={ gotoRouteTree }><span className="icon printRouteTree"></span><span><strong>{ _s(StringKey.ROUTE_TREE) }</strong>3 { _s(StringKey.STYLES) }</span><span className="icon navigate"></span></a>
				</div>
				<div className="spacer"></div>
			</div>
		</div>
	</React.Fragment>;
};

const AccessAlert = () => {
	const { currentTeam, platform } = getCurrentContext();
	const onClick = platform.isTrolledGarden ? null : () => {

		viewManager.pushModal({
			component: BillingModal,
			props: () => ({ appState: store.appState(), teamId: currentTeam.id }),
			screenName: 'Billing',
		});
	};

	return <div className="alert upgrade interactive" onClick={ onClick }>
		<span className="icon lock"></span><span>{ _s(platform.isTrolledGarden ? StringKey.UPGRADE_PRINT_ACCESS_ALERT_TROLL : StringKey.UPGRADE_PRINT_ACCESS_ALERT) }</span>
	</div>;
};

interface PrintProps {
	alertList: any;
	appState: store.IAppState;
	playbookId?: string;
}

interface PrintState {
	activePage: number;
	isRendering?: boolean;
	selectedCategories: ITag[];
	selectedFormations: IFormation[];
	filteredPlays: IPlay[];
	optionValues: IOptionValues;
	printRootChildren?: any;
}

export class Print extends React.Component<PrintProps, PrintState> {
	private _gotoOptionsPage;
	private _gotoPMXWristbandsPage;
	private _gotoWristbandsPage;
	private _gotoWristbandsListPage;
	private _gotoCallSheetsPage;
	private _gotoCallSheetsListPage;
	private _gotoPlaybookPage;
	private _gotoScoutCardsPage;
	private _gotoFormationsOverviewPage;
	private _gotoRouteTreePage;
	private _gotoHelpPage;
	private _pendingPrintRootChildren: any = null;

	constructor(props) {
		super(props);

		this.state = {
			activePage: 0,
			selectedCategories: [],
			selectedFormations: [],
			filteredPlays: [],
			optionValues: {},
			printRootChildren: null,
		};

		this._handleFormationsFiltered = this._handleFormationsFiltered.bind(this);
		this._handleOptionsChanged = this._handleOptionsChanged.bind(this);
		this._handlePlaysFiltered = this._handlePlaysFiltered.bind(this);
		this._handlePrintClick = this._handlePrintClick.bind(this);

		this._filterPlays = this._filterPlays.bind(this);

		this._gotoOptionsPage = this.gotoPage.bind(this, 0);
		this._gotoPMXWristbandsPage = this.gotoPage.bind(this, 1);
		this._gotoWristbandsPage = this.gotoPage.bind(this, 2);
		this._gotoWristbandsListPage = this.gotoPage.bind(this, 3);
		this._gotoCallSheetsPage = this.gotoPage.bind(this, 4);
		this._gotoCallSheetsListPage = this.gotoPage.bind(this, 5);
		this._gotoPlaybookPage = this.gotoPage.bind(this, 6);
		this._gotoScoutCardsPage = this.gotoPage.bind(this, 7);
		this._gotoFormationsOverviewPage = this.gotoPage.bind(this, 8);
		this._gotoRouteTreePage = this.gotoPage.bind(this, 9);
		this._gotoHelpPage = this.gotoPage.bind(this, 10);

		this._setPrintRootChildren = this._setPrintRootChildren.bind(this);

		this.logScreen();
	}

	public gotoPage(page: number, e?) {
		actions.clearAlerts();

		if (e) {
			e.preventDefault();
		}

		this.setState((previousState) => {
			const { currentUser } = getCurrentContext();
			const newState = assign({}, previousState) as PrintState;
			const printOptions = this._getPrintOptions(page)?.options;
			const pageName = this._getPageName(page);
			const optionKey = pageNameToOptionKey(pageName);
			const profileValues = currentUser?.profile.printOptions[optionKey] || {};

			newState.activePage = page;
			newState.optionValues = printOptions ? mapValues(printOptions, (option) => find(option.items, i => i.isDefault) || option.items[0]) : {};

			for(const k in newState.optionValues) {
				const profileItemKey = profileValues[k]
				if(typeof profileItemKey !== 'undefined') {
					// use the current configured option based on the selected key
					const optionItem = find(printOptions[k]?.items, item => item.key === profileItemKey);
					if(optionItem){
						newState.optionValues[k] = optionItem;
					}
				}
			}

			this._setDefaultValues(newState);

			return newState;
		}, () => { this.logScreen(); });
	}

	private _getPageName(page:number) {
		switch (page) {
		case 0:
			return 'PrintLanding';
		case 1:
			return 'PrintPerfInserts';
		case 2:
			return 'PrintWristbands';
		case 3:
			return 'PrintWristbandsList';
		case 4:
			return 'PrintCallSheets';
		case 5:
			return 'PrintCallSheetsList';
		case 6:
			return 'PrintPlaybook';
		case 7:
			return 'PrintScoutCards';
		case 8:
			return 'PrintFormationsOverview';
		case 9:
			return 'PrintRouteTree';
		case 10:
			return 'PrintHelp';
		}
	}

	public logScreen() {
		const { activePage } = this.state;
		const name = this._getPageName(activePage);

		if(name) {
			logger.logScreen(name);
		}
	}

	public _setDefaultValues(state: PrintState) {
		const { appState } = this.props;
		const { model } = appState;
		const { currentPlaybook, variant } = getCurrentContext();
		const playbookId = this.props.playbookId || currentPlaybook.id;
		const firstFormation = first(sortBy(filter(model.formations, (f) => f.playbookId === playbookId && f.phase === GamePhase.Offense && this._filterPlays([], [f], GamePhase.Offense).length), 'sortIndex'));
		const categories = [];
		const formations = variant === 'tackle' ? firstFormation ? [firstFormation] : [] : [];
		const filteredPlays = this._filterPlays(categories, formations, GamePhase.Offense, variant === 'tackle' ? false : (categories.length + formations.length) === 0);

		state.selectedCategories = categories;
		state.selectedFormations = formations;
		state.filteredPlays = filteredPlays;
	}

	public _getPrintOptions(page) {
		const { appState } = this.props;

		switch (page) {
		case 1:
			return appState.viewState.settings.printOptions.perfInserts;
		case 2:
			return appState.viewState.settings.printOptions.wristbands;
		case 3:
			return appState.viewState.settings.printOptions.wristbandsList;
		case 4:
			return appState.viewState.settings.printOptions.callSheets;
		case 5:
			return appState.viewState.settings.printOptions.callSheetsList;
		case 6:
			return appState.viewState.settings.printOptions.playbook;
		case 7:
			return appState.viewState.settings.printOptions.scoutCards;
		case 8:
			return appState.viewState.settings.printOptions.formationsOverview;
		case 9:
			return appState.viewState.settings.printOptions.routeTree;

		default:
			return null;
		}
	}

	public _getPrintOptionsProps(page) {
		const { activePage, optionValues } = this.state;
		const printOptions = this._getPrintOptions(page);

		if (page === activePage) {
			return {
				complete: printOptions && every(printOptions.options, (o, k) => typeof optionValues[k] !== 'undefined'),
				optionValues,
				onOptionsChange: this._handleOptionsChanged,
			};
		} else {
			return {
				complete: false, // this ensures that non-active pages don't compete for print markup control
				optionValues: {},
				onOptionsChange: () => {}
			};
		}
	}

	public _handleOptionsChanged(optionValues: IOptionValues) {
		this.setState({ optionValues });
	}

	public _handlePlaysFiltered(selectedCategories: ITag[], selectedFormations: IFormation[], phase: GamePhase, allInPhase?: boolean) {
		const filteredPlays: IPlay[] = this._filterPlays(selectedCategories, selectedFormations, phase, allInPhase);

		this.setState({ filteredPlays, selectedCategories, selectedFormations });
	}

	public _handleFormationsFiltered(selectedFormations: IFormation[]) 
	{
		this.setState({ selectedFormations });
	}

	public _filterPlays(selectedCategories: ITag[], selectedFormations: IFormation[], phase: GamePhase, allInPhase?: boolean) {
		const { appState } = this.props;
		const { model } = appState;
		const { currentPlaybook } = getCurrentContext();
		const playbookId = this.props.playbookId || currentPlaybook.id;
		const plays = filter(model.plays, { playbookId });
		let filteredPlays: IPlay[] = allInPhase ? filter(plays, { phase }) : [];

		if (allInPhase) {
			return filteredPlays;
		}

		if (selectedCategories.length) {
			const selectedCategoryIds = map(selectedCategories, (c) => c.id);
			const uncategorizedCategory = getUncategorizedCategory();
			const uncategorizedSelected = selectedCategoryIds.indexOf(uncategorizedCategory.id) !== -1;

			filteredPlays = filter(plays, (p) => p.phase === phase && (some(p.categoryList, (c) => selectedCategoryIds.indexOf(c) !== -1) || (uncategorizedSelected && !p.categoryList.length)));
		} else if (selectedFormations.length) {
			filteredPlays = filter(plays, (p) => p.phase === phase && some(selectedFormations, (f) => f.label.toLowerCase() === p.formation.toLowerCase()));
		}

		return filteredPlays;
	}

	public _setPrintRootChildren(children) {
		if (children) {
			this._pendingPrintRootChildren = children;
		}
	}

	public _handlePrintClick() {
		// console.log('print clicked');
		const { activePage, optionValues } = this.state;
		const pageName = this._getPageName(activePage);
		const optionKey = pageNameToOptionKey(pageName);
		const { currentUser } = getCurrentContext();
		const mutatedUser = userFactory(currentUser);

		this.setState({ isRendering: true, printRootChildren: this._pendingPrintRootChildren }, () => {
			setTimeout(() => {
				this.setState({ isRendering: false}, () => {
					if(optionKey) {
						const newOptions = {...mutatedUser.profile.printOptions };

						newOptions[optionKey] = mapValues(optionValues, 'key');
						mutatedUser.profile.printOptions = newOptions;
			
						actions.saveUser(mutatedUser);
					}
					actions.print();
					// console.log('printed');
				});
			}, 50);
		});
	}

	public render() {
		const { alertList, appState } = this.props;
		const { activePage, isRendering, filteredPlays, selectedCategories, selectedFormations, printRootChildren } = this.state;
		const { model, viewState } = appState;
		const { currentPlaybook, currentSubscription, currentTeam } = getCurrentContext();
		const playbookId = this.props.playbookId || currentPlaybook.id;
		const playbook = currentTeam.playbooks[playbookId];
		const fieldOptions = find(viewState.settings.fieldOptions, { key: playbook.settings.fieldKey })
		const selectableCategories = filter(playbook.settings.categories.values, (c) => !!this._filterPlays([c], [], c.phase).length);
		const selectableFormations = filter(model.formations, (f) => f.playbookId === playbookId && !!this._filterPlays([], [f], f.phase).length);
		const filterProps = {
			categories: playbook.settings.categories.values,
			formations: filter(model.formations, { playbookId }), // get us an array
			selectableCategories,
			selectableFormations,
			selectedCategories,
			selectedFormations,
			filteredPlays,
			playbook,
			plays: filter(model.plays, { playbookId }),
			onPlaysFiltered: this._handlePlaysFiltered,
			onFormationsFiltered: this._handleFormationsFiltered
		};
		const accessDisabled = !currentSubscription || !currentSubscription.isActive() || !currentSubscription.supportsFeature(SystemFeature.print);
		const accessAlert = accessDisabled ? <AccessAlert /> : null;

		// order here matches goto indexes
		return <React.Fragment>
			{ isRendering ? <Spinner label={ _s(StringKey.LOADER_MESSAGE_RENDER) } /> : null }
			<PageContainer activePage={ activePage } commonPageClasses={ ['page'] }>
				<OptionsPage
					alertList={ alertList }
					close={ viewManager.popModal }
					gotoHelp={ this._gotoHelpPage }
					// gotoPerfInserts={ this._gotoPMXWristbandsPage }
					gotoWristbands={ this._gotoWristbandsPage }
					gotoWristbandsList={ this._gotoWristbandsListPage }
					gotoCallSheets={ this._gotoCallSheetsPage }
					gotoCallSheetsList={ this._gotoCallSheetsListPage }
					gotoPlaybook={ this._gotoPlaybookPage }
					gotoScoutCards={ this._gotoScoutCardsPage }
					gotoFormationsOverview={ this._gotoFormationsOverviewPage }
					gotoRouteTree={ this._gotoRouteTreePage }
				/>
				{ activePage === 1 ? <PMXWristbandsPage
					accessAlert={ accessAlert }
					alertList={ alertList }
					fieldOptions={ fieldOptions }
					filterProps={ filterProps }
					goBack={ this._gotoOptionsPage }
					onPrintClick={ this._handlePrintClick }
					options={ appState.viewState.settings.printOptions.perfInserts }
					playbook={ playbook }
					printOptionsProps= { this._getPrintOptionsProps(1) }
					setPrintRootChildren= { this._setPrintRootChildren }
				/> : <div></div>}
				{ activePage === 2 ? <WristbandsPage
					accessAlert={ accessAlert }
					alertList={ alertList }
					fieldOptions={ fieldOptions }
					filterProps={ filterProps }
					goBack={ this._gotoOptionsPage }
					onPrintClick={ this._handlePrintClick }
					options={ appState.viewState.settings.printOptions.wristbands }
					playbook={ playbook }
					printOptionsProps= { this._getPrintOptionsProps(2) }
					setPrintRootChildren= { this._setPrintRootChildren }
				/> : <div></div>}
				{ activePage === 3 ? <WristbandsListPage
					accessAlert={ accessAlert }
					alertList={ alertList }
					fieldOptions={ fieldOptions }
					filterProps={ filterProps }
					goBack={ this._gotoOptionsPage }
					onPrintClick={ this._handlePrintClick }
					options={ appState.viewState.settings.printOptions.wristbandsList }
					playbook={ playbook }
					printOptionsProps= { this._getPrintOptionsProps(3) }
					setPrintRootChildren= { this._setPrintRootChildren }
				/> : <div></div>}
				{ activePage === 4 ? <CallSheetPage
					accessAlert={ accessAlert }
					alertList={ alertList }
					fieldOptions={ fieldOptions }
					filterProps={ filterProps }
					goBack={ this._gotoOptionsPage }
					onPrintClick={ this._handlePrintClick }
					options={ appState.viewState.settings.printOptions.callSheets }
					playbook={ playbook }
					printOptionsProps= { this._getPrintOptionsProps(4) }
					setPrintRootChildren= { this._setPrintRootChildren }
				/> : <div></div>}
				{ activePage === 5 ? <CallSheetListPage
					accessAlert={ accessAlert }
					alertList={ alertList }
					fieldOptions={ fieldOptions }
					filterProps={ filterProps }
					goBack={ this._gotoOptionsPage }
					onPrintClick={ this._handlePrintClick }
					options={ appState.viewState.settings.printOptions.callSheetsList }
					playbook={ playbook }
					printOptionsProps= { this._getPrintOptionsProps(5) }
					setPrintRootChildren= { this._setPrintRootChildren }
				/> : <div></div>}
				{ activePage === 6 ? <PlaybookPage
					accessAlert={ accessAlert }
					alertList={ alertList }
					fieldOptions={ fieldOptions }
					filterProps={ filterProps }
					goBack={ this._gotoOptionsPage }
					onPrintClick={ this._handlePrintClick }
					options={ appState.viewState.settings.printOptions.playbook }
					playbook={ playbook }
					printOptionsProps= { this._getPrintOptionsProps(6) }
					setPrintRootChildren= { this._setPrintRootChildren }
				/> : <div></div>}
				{ activePage === 7 ? <ScoutCardPage
					accessAlert={ accessAlert }
					alertList={ alertList }
					fieldOptions={ fieldOptions }
					filterProps={ filterProps }
					goBack={ this._gotoOptionsPage }
					onPrintClick={ this._handlePrintClick }
					options={ appState.viewState.settings.printOptions.scoutCards }
					playbook={ playbook }
					printOptionsProps= { this._getPrintOptionsProps(7) }
					setPrintRootChildren= { this._setPrintRootChildren }
				/> : <div></div>}
				{ activePage === 8 ? <FormationsOverviewPage
					accessAlert={ accessAlert }
					alertList={ alertList }
					fieldOptions={ fieldOptions }
					filterProps={ filterProps }
					goBack={ this._gotoOptionsPage }
					onPrintClick={ this._handlePrintClick }
					options={ appState.viewState.settings.printOptions.formationsOverview }
					playbook={ playbook }
					printOptionsProps= { this._getPrintOptionsProps(8) }
					setPrintRootChildren= { this._setPrintRootChildren }
				/> : <div></div>}
				{ activePage === 9 ? <RouteTreePage
					accessAlert={ accessAlert }
					alertList={ alertList }
					fieldOptions={ fieldOptions }
					goBack={ this._gotoOptionsPage }
					onPrintClick={ this._handlePrintClick }
					options={ appState.viewState.settings.printOptions.routeTree }
					playbook={ playbook }
					printOptionsProps= { this._getPrintOptionsProps(9) }
					setPrintRootChildren= { this._setPrintRootChildren }
				/> : <div></div>}
				{ activePage === 10 ? <HelpPage
					alertList={ alertList }
					goBack={ this._gotoOptionsPage }
				/> : <div></div>}
			</PageContainer>
			<PrintRoot>{ printRootChildren }</PrintRoot>
		</React.Fragment>;
	}
}

interface PrintModalProps {
	alerts: store.IAlert[];
	appState: store.IAppState;
	playbookId?: string;
}

export class PrintModal extends React.Component<PrintModalProps> {
	constructor(props: PrintModalProps) {
		super(props);
	}

	public render() {
		const { appState, alerts, playbookId } = this.props;
		const alertList = <AlertList alerts={ alerts } />;

		return <div className="view">
			<Print alertList={ alertList } appState={ appState } playbookId={ playbookId }  />
		</div>;
	}

}

function pageNameToOptionKey(pageName: string) {
	const { variant } = getCurrentContext();
	return pageName && `${variant}-${pageName.substring(5).toLowerCase()}`;
}

import * as store from './store';

export const enum StringKey {
	ABBREVIATION_4_LETTERS_MAX = 'ABBREVIATION_4_LETTERS_MAX',
	ACCEPT_INVITE = 'ACCEPT_INVITE',
	ACCEPT_TEAM_INVITE_SUCCESS_TEMPLATE = 'ACCEPT_TEAM_INVITE_SUCCESS_TEMPLATE',
	ACCEPTED = 'ACCEPTED',
	ACCESS = 'ACCESS',
	ACCESS_DENIED_PLAYBOOKS_DESCRIPTION = 'ACCESS_DENIED_PLAYBOOKS_DESCRIPTION',
	ACCESS_DENIED_PLAYBOOKS_TITLE = 'ACCESS_DENIED_PLAYBOOKS_TITLE',
	ACCESS_DENIED_TEAM_DESCRIPTION = 'ACCESS_DENIED_TEAM_DESCRIPTION',
	ACCESS_DENIED_TEAM_TITLE = 'ACCESS_DENIED_TEAM_TITLE',
	ACCOUNT_CREATION_FAILED_ALERT = 'ACCOUNT_CREATION_FAILED_ALERT',
	ACCOUNT_CREATION_FAILED_ALERT_MESSAGE = 'ACCOUNT_CREATION_FAILED_ALERT_MESSAGE',
	ACCOUNT_CREATION_FAILED_EXISTING_ACCOUNT_ALERT_MESSAGE = 'ACCOUNT_CREATION_FAILED_EXISTING_ACCOUNT_ALERT_MESSAGE',
	ACTIONS = 'ACTIONS',
	ACTIVE = 'ACTIVE',
	ADD_PAYMENT_METHOD = 'ADD_PAYMENT_METHOD',
	ADD_PLAYER = 'ADD_PLAYER',
	ADD_STAFF = 'ADD_STAFF',
	ADMIN = 'ADMIN',
	ALL_DEFENSIVE_PLAYS = 'ALL_DEFENSIVE_PLAYS',
	ALL_OFFENSIVE_PLAYS = 'ALL_OFFENSIVE_PLAYS',
	ALL_SPECIAL_TEAMS_PLAYS = 'ALL_SPECIAL_TEAMS_PLAYS',
	ANNOTATIONS = 'ANNOTATIONS',
	ANOTHER_FORMATION_EXISTS_MESSAGE = 'ANOTHER_FORMATION_EXISTS_MESSAGE',
	API_VERSION = 'API_VERSION',
	APPLY_ROUTE_TEMPLATE = 'APPLY_ROUTE_TEMPLATE',
	APP_UPDATE_MESSAGE = 'APP_UPDATE_MESSAGE',
	APP_UPDATE_TITLE = 'APP_UPDATE_TITLE',
	APP_VERSION = 'APP_VERSION',
	ARCHIVE_PLAYBOOK = 'ARCHIVE_PLAYBOOK',
	ARCHIVED = 'ARCHIVED',
	ARCHIVED_PLAYBOOKS_NOT_VISIBLE_MESSAGE = 'ARCHIVED_PLAYBOOKS_NOT_VISIBLE_MESSAGE',
	ARCHIVING_PLAYBOOK_MESSAGE = 'ARCHIVING_PLAYBOOK_MESSAGE',
	ASSIGN_POSITIONS = 'ASSIGN_POSITIONS',
	BALL_ON = 'BALL_ON',
	BILLING_ACTIVE_UPGRADES = 'BILLING_ACTIVE_UPGRADES',
	BILLING_ADD_FEATURES = 'BILLING_ADD_FEATURES',
	BILLING_ADMIN = 'BILLING_ADMIN',
	BILLING_AUTO_RENEW_FAILED_MESSAGE = 'BILLING_AUTO_RENEW_FAILED_MESSAGE',
	BILLING_CARD_NAME_TEMPLATE = 'BILLING_CARD_NAME_TEMPLATE',
	BILLING_CHANGE_PLAN = 'BILLING_CHANGE_PLAN',
	BILLING_CURRENT_PLAN = 'BILLING_CURRENT_PLAN',
	BILLING_DOWNGRADE = 'BILLING_DOWNGRADE',
	BILLING_DOWNGRADE_DESCRIPTION_TEMPLATE = 'BILLING_DOWNGRADE_DESCRIPTION_TEMPLATE',
	BILLING_DOWNGRADE_FAILED_MESSAGE = 'BILLING_DOWNGRADE_FAILED_MESSAGE',
	BILLING_DOWNGRADE_SUCCESS_MESSAGE = 'BILLING_DOWNGRADE_SUCCESS_MESSAGE',
	BILLING_ENTER_CODE = 'BILLING_ENTER_CODE',
	BILLING_HAVE_AN_UPGRADE_CODE = 'BILLING_HAVE_AN_UPGRADE_CODE',
	BILLING_IOS_MANAGE_DESCRIPTION = 'BILLING_IOS_MANAGE_DESCRIPTION',
	BILLING_MANAGE_PLAN = 'BILLING_MANAGE_PLAN',
	BILLING_MANAGE_SUBSCRIPTION = 'BILLING_MANAGE_SUBSCRIPTION',
	BILLING_NAME_ON_CARD = 'BILLING_NAME_ON_CARD',
	BILLING_PAUSE_ACCOUNT = 'BILLING_PAUSE_ACCOUNT',
	BILLING_PAUSE_ACCOUNT_CONFIRM_TEMPLATE = 'BILLING_PAUSE_ACCOUNT_CONFIRM_TEMPLATE',
	BILLING_PAUSE_ACCOUNT_EXPIRING_MESSAGE = 'BILLING_PAUSE_ACCOUNT_EXPIRING_MESSAGE',
	BILLING_PAY_NOW = 'BILLING_PAY_NOW',
	BILLING_PAYMENT_DECLINED_MESSAGE = 'BILLING_PAYMENT_DECLINED_MESSAGE',
	BILLING_PAYMENT_FAILED_ADVICE = 'BILLING_PAYMENT_FAILED_ADVICE',
	BILLING_PAYMENT_FAILED_MESSAGE = 'BILLING_PAYMENT_FAILED_MESSAGE',
	BILLING_PAYMENT_METHOD = 'BILLING_PAYMENT_METHOD',
	BILLING_PAYMENT_METHOD_CHANGE_DESCRIPTION = 'BILLING_PAYMENT_METHOD_CHANGE_DESCRIPTION',
	BILLING_PAYMENT_METHOD_PROBLEM_MESSAGE = 'BILLING_PAYMENT_METHOD_PROBLEM_MESSAGE',
	BILLING_PAYMENT_METHOD_SAVED_MESSAGE = 'BILLING_PAYMENT_METHOD_SAVED_MESSAGE',
	BILLING_PENDING_DOWNGRADE_MESSAGE_TEMPLATE = 'BILLING_PENDING_DOWNGRADE_MESSAGE_TEMPLATE',
	BILLING_PLAN_EXPIRED_MESSAGE = 'BILLING_PLAN_EXPIRED_MESSAGE',
	BILLING_PLAN_EXPIRED_ON_TEMPLATE = 'BILLING_PLAN_EXPIRED_ON_TEMPLATE',
	BILLING_PLAN_EXPIRES_ON_TEMPLATE = 'BILLING_PLAN_EXPIRES_ON_TEMPLATE',
	BILLING_PLAN_PAUSED_TEMPLATE = 'BILLING_PLAN_PAUSED_TEMPLATE',
	BILLING_PLAN_PAUSED_UNPAUSE_MESSAGE = 'BILLING_PLAN_PAUSED_UNPAUSE_MESSAGE',
	BILLING_PLAN_RENEWS_ON_TEMPLATE = 'BILLING_PLAN_RENEWS_ON_TEMPLATE',
	BILLING_PLAN_UNPAUSED_MESSAGE = 'BILLING_PLAN_UNPAUSED_MESSAGE',
	BILLING_PLAN_UPGRADE_DESCRIPTION = 'BILLING_PLAN_UPGRADE_DESCRIPTION',
	BILLING_PRICE_DESCRIPTION_TEMPLATE = 'BILLING_PRICE_DESCRIPTION_TEMPLATE',
	BILLING_REDEEM = 'BILLING_REDEEM',
	BILLING_RENEW_OR_UPGRADE = 'BILLING_RENEW_OR_UPGRADE',
	BILLING_SELECT_PLAN = 'BILLING_SELECT_PLAN',
	BILLING_SPONSOR_EMAIL_PROMPT_TEMPLATE = 'BILLING_SPONSOR_EMAIL_PROMPT_TEMPLATE',
	BILLING_SPONSORED_PLAN = 'BILLING_SPONSORED_PLAN',
	BILLING_SUBSCRIPTION_SUCCESS_MESSAGE = 'BILLING_SUBSCRIPTION_SUCCESS_MESSAGE',
	BILLING_UPAUSE_ACCOUNT = 'BILLING_UNPAUSE_ACCOUNT',
	BILLING_UNCLAIMED_IAP_ACTION = 'BILLING_UNCLAIMED_IAP_ACTION',
	BILLING_UNCLAIMED_IAP_MESSAGE = 'BILLING_UNCLAIMED_IAP_MESSAGE',
	BILLING_UPGRADE_CODE = 'BILLING_UPGRADE_CODE',
	BILLING_UPGRADE_CODE_ENTRY_MESSAGE = 'BILLING_UPGRADE_CODE_ENTRY_MESSAGE',
	BILLING_UPGRADE_PRICE_DESCRIPTION_TEMPLATE = 'BILLING_UPGRADE_PRICE_DESCRIPTION_TEMPLATE',
	BOTH = 'BOTH',
	BY_CREATING_AN_ACCOUNT_YOU_AGREE_TO_THE = 'BY_CREATING_AN_ACCOUNT_YOU_AGREE_TO_THE',
	CALIBRATION = 'CALIBRATION',
	CALL_SHEETS = 'CALL_SHEETS',
	CANCEL = 'CANCEL',
	CANNOT_SAVE_FORMATION_MESSAGE = 'CANNOT_SAVE_FORMATION_MESSAGE',
	CANNOT_SAVE_PLAY_MESSAGE = 'CANNOT_SAVE_PLAY_MESSAGE',
	CATEGORIES = 'CATEGORIES',
	CATEGORY = 'CATEGORY',
	CATEGORY_NAME = 'CATEGORY_NAME',
	CHANGE_OPPONENT_FORMATION_ALERT = 'CHANGE_OPPONENT_FORMATION_ALERT',
	CHANGE_OPPONENT_FORMATION_ALERT_MESSAGE = 'CHANGE_OPPONENT_FORMATION_ALERT_MESSAGE',
	CHANGE_PASSWORD = 'CHANGE_PASSWORD',
	CHECK_FOR_UPDATE = 'CHECK_FOR_UPDATE',
	CITY = 'CITY',
	CODE = 'CODE',
	COLOR = 'COLOR',
	COLORS_AND_LABELS = 'COLORS_AND_LABELS',
	CONFIRM_EMAIL_ADDRESS = 'CONFIRM_EMAIL_ADDRESS',
	CONFIRM_NEW_PASSWORD = 'CONFIRM_NEW_PASSWORD',
	CONNECTION_INFO_CONNECTED_CONTRIBUTOR = 'CONNECTION_INFO_CONNECTED_CONTRIBUTOR',
	CONNECTION_INFO_CONNECTED_ONBOARDING = 'CONNECTION_INFO_CONNECTED_ONBOARDING',
	CONNECTION_INFO_CONNECTED_VIEWER = 'CONNECTION_INFO_CONNECTED_VIEWER',
	CONNECTION_INFO_DISCONNECTED_CONTRIBUTOR = 'CONNECTION_INFO_DISCONNECTED_CONTRIBUTOR',
	CONNECTION_INFO_DISCONNECTED_ONBOARDING = 'CONNECTION_INFO_DISCONNECTED_ONBOARDING',
	CONNECTION_INFO_DISCONNECTED_VIEWER = 'CONNECTION_INFO_DISCONNECTED_VIEWER',
	CONNECTION_INFO_NO_TEAMS = 'CONNECTION_INFO_NO_TEAMS',
	CONNECTION_INFO_RENEWAL_PROMPT = 'CONNECTION_INFO_RENEWAL_PROMPT',
	CONNECTION_INFO_SELECT_PROMPT = 'CONNECTION_INFO_SELECT_PROMPT',
	CONNECTION_INFO_UNSYNCED_PLAYBOOK = 'CONNECTION_INFO_UNSYNCED_PLAYBOOK',
	CONNECTION_INFO_UNSYNCED_PLAYBOOKS = 'CONNECTION_INFO_UNSYNCED_PLAYBOOKS',
	CONNECTION_INFO_UPGRADE_PROMPT = 'CONNECTION_INFO_UPGRADE_PROMPT',
	CONTACT_SUPPORT = 'CONTACT_SUPPORT',
	CONTINUE = 'CONTINUE',
	CONTRIBUTE = 'CONTRIBUTE',
	COPY = 'COPY',
	COPY_COMPLETE = 'COPY_COMPLETE',
	COPY_FORMATION = 'COPY_FORMATION',
	COPY_FORMATIONS = 'COPY_FORMATIONS',
	COPY_PLAY = 'COPY_PLAY',
	COPY_PLAYBOOK = 'COPY_PLAYBOOK',
	COPY_PLAYS = 'COPY_PLAYS',
	COPY_TO = 'COPY_TO',
	COUNTRY = 'COUNTRY',
	COVER = 'COVER',
	CREATE_A_TEAM = 'CREATE_A_TEAM',
	CREDIT_CARD = 'CREDIT_CARD',
	CURRENT_PASSWORD = 'CURRENT_PASSWORD',
	CUSTOM_PRINT_COLOR_CODING = 'CUSTOM_PRINT_COLOR_CODING',
	DARK_BACKGROUND = 'DARK_BACKGROUND',
	DARK_FIELD = 'DARK_FIELD',
	DASHBOARD_FALLBACK_SELECT_TEAM = 'DASHBOARD_FALLBACK_SELECT_TEAM',
	DEFENSE = 'DEFENSE',
	DEFENSIVE_CATEGORIES = 'DEFENSIVE_CATEGORIES',
	DEFENSIVE_FORMATIONS = 'DEFENSIVE_FORMATIONS',
	DEFENSIVE_PERSONNEL_GROUPS = 'DEFENSIVE_PERSONNEL_GROUPS',
	DEFENSIVE_TEMPLATES = 'DEFENSIVE_TEMPLATES',
	DELETE = 'DELETE',
	DELETE_FORMATION = 'DELETE_FORMATION',
	DELETE_FORMATION_PROMPT_MESSAGE = 'DELETE_FORMATION_PROMPT_MESSAGE',
	DELETE_FORMATION_QUESTION = 'DELETE_FORMATION_QUESTION',
	DELETE_FORMATIONS = 'DELETE_FORMATIONS',
	DELETE_FORMATIONS_PROMPT_MESSAGE = 'DELETE_FORMATIONS_PROMPT_MESSAGE',
	DELETE_FORMATIONS_QUESTION = 'DELETE_FORMATIONS_QUESTION',
	DELETE_PLAY = 'DELETE_PLAY',
	DELETE_PLAY_PROMPT_MESSAGE = 'DELETE_PLAY_PROMPT_MESSAGE',
	DELETE_PLAY_QUESTION = 'DELETE_PLAY_QUESTION',
	DELETE_PLAYBOOK = 'DELETE_PLAYBOOK',
	DELETE_PLAYBOOK_PROMPT_MESSAGE = 'DELETE_PLAYBOOK_PROMPT_MESSAGE',
	DELETE_PLAYBOOK_QUESTION = 'DELETE_PLAYBOOK_QUESTION',
	DELETE_PLAYS = 'DELETE_PLAYS',
	DELETE_PLAYS_PROMPT_MESSAGE = 'DELETE_PLAYS_PROMPT_MESSAGE',
	DELETE_PLAYS_QUESTION = 'DELETE_PLAYS_QUESTION',
	DELETE_ROUTE = 'DELETE_ROUTE',
	DELETE_ROUTE_PROMPT_MESSAGE = 'DELETE_ROUTE_PROMPT_MESSAGE',
	DELETE_ROUTE_QUESTION = 'DELETE_ROUTE_QUESTION',
	DELETE_TEAM_MEMBER = 'DELETE_TEAM_MEMBER',
	DELETE_TEAM_MEMBER_PROMPT_MESSAGE = 'DELETE_TEAM_MEMBER_PROMPT_MESSAGE',
	DELETE_TEAM_MEMBER_QUESTION = 'DELETE_TEAM_MEMBER_QUESTION',
	DELETE_TEAM_MEMBERS = 'DELETE_TEAM_MEMBERS',
	DELETE_TEAM_MEMBERS_PROMPT_MESSAGE = 'DELETE_TEAM_MEMBERS_PROMPT_MESSAGE',
	DELETE_TEAM_MEMBERS_QUESTION = 'DELETE_TEAM_MEMBERS_QUESTION',
	DIAGNOSTICS = 'DIAGNOSTICS',
	DIAGNOSTICS_RELOAD_MESSAGE = 'DIAGNOSTICS_RELOAD_MESSAGE',
	DIAGRAMS = 'DIAGRAMS',
	DO_YOU_ALREADY_HAVE_AN_ACCOUNT = 'DO_YOU_ALREADY_HAVE_AN_ACCOUNT',
	DONT_SNAP = 'DONT_SNAP',
	DRAG_ONTO_FIELD = 'DRAG_ONTO_FIELD',
	EDIT = 'EDIT',
	EDIT_CATEGORY = 'EDIT_CATEGORY',
	EDIT_PLAYBOOK = 'EDIT_PLAYBOOK',
	EDIT_TEMPLATE = 'EDIT_TEMPLATE',
	EMAIL = 'EMAIL',
	ENTER = 'ENTER',
	EMAIL_ADDRESS = 'EMAIL_ADDRESS',
	ENTER_INVITATION_CODE = 'ENTER_INVITATION_CODE',
	ENTER_NEW_CATEGORY_NAME = 'ENTER_NEW_CATEGORY_NAME',
	ENTER_NEW_PERSONNEL_GROUP_NAME = 'ENTER_NEW_PERSONNEL_GROUP_NAME',
	ENTER_PLAYMAKERX_CODE = 'ENTER_PLAYMAKERX_CODE',
	ERROR = 'ERROR',
	ERROR_TRAP_MESSAGE = 'ERROR_TRAP_MESSAGE',
	EXCHANGE = 'EXCHANGE',
	FAILED_TO_ADD_SUBSCRIPTION_ALERT = 'FAILED_TO_ADD_SUBSCRIPTION_ALERT',
	FAILED_TO_PAUSE_SUBSCRIPTION_ALERT = 'FAILED_TO_PAUSE_SUBSCRIPTION_ALERT',
	FAILED_TO_UPDATE_PAYMENT_ALERT = 'FAILED_TO_UPDATE_PAYMENT_ALERT',
	FAILED_TO_UPDATE_SUBSCRIPTION_ALERT = 'FAILED_TO_UPDATE_SUBSCRIPTION_ALERT',
	FEEDBACK = 'FEEDBACK',
	FEEDBACK_CTA = 'FEEDBACK_CTA',
	FIELD_LINES = 'FIELD_LINES',
	FIELD_OPTIONS = 'FIELD_OPTIONS',
	FIRST_NAME = 'FIRST_NAME',
	FORGOT_PASSWORD_QUESTION = 'FORGOT_PASSWORD_QUESTION',
	FORMATION = 'FORMATION',
	FORMATION_COPIED = 'FORMATION COPIED',
	FORMATION_DEFENSE_2_3_1 = 'FORMATION_DEFENSE_2_3_1',
	FORMATION_DEFENSE_2_4 = 'FORMATION_DEFENSE_2_4',
	FORMATION_DEFENSE_2_5 = 'FORMATION_DEFENSE_2_5',
	FORMATION_DEFENSE_3_2_3 = 'FORMATION_DEFENSE_3_2_3',
	FORMATION_DEFENSE_3_3 = 'FORMATION_DEFENSE_3_3',
	FORMATION_DEFENSE_3_4 = 'FORMATION_DEFENSE_3_4',
	FORMATION_DEFENSE_4_2 = 'FORMATION_DEFENSE_4_2',
	FORMATION_DEFENSE_4_2_2 = 'FORMATION_DEFENSE_4_2_2',
	FORMATION_DEFENSE_4_3 = 'FORMATION_DEFENSE_4_3',
	FORMATION_DEFENSE_4_3_1 = 'FORMATION_DEFENSE_4_3_1',
	FORMATION_DEFENSE_5_2 = 'FORMATION_DEFENSE_5_2',
	FORMATION_DEFENSE_COVER_2 = 'FORMATION_DEFENSE_COVER_2',
	FORMATION_DEFENSE_COVER_3 = 'FORMATION_DEFENSE_COVER_3',
	FORMATION_DEFENSE_NICKEL = 'FORMATION_DEFENSE_NICKEL',
	FORMATION_INFO = 'FORMATION_INFO',
	FORMATION_NAME = 'FORMATION_NAME',
	FORMATION_OFFENSE_ACE = 'FORMATION_OFFENSE_ACE',
	FORMATION_OFFENSE_I_FORMATION = 'FORMATION_OFFENSE_I_FORMATION',
	FORMATION_OFFENSE_I_RIGHT = 'FORMATION_OFFENSE_I_RIGHT',
	FORMATION_OFFENSE_SPLIT_BACKS = 'FORMATION_OFFENSE_SPLIT_BACKS',
	FORMATION_OFFENSE_SPLIT_RIGHT = 'FORMATION_OFFENSE_SPLIT_RIGHT',
	FORMATION_OFFENSE_SPREAD_BALANCED = 'FORMATION_OFFENSE_SPREAD_BALANCED',
	FORMATION_SAVE_FAILED_MESSAGE = 'FORMATION_SAVE_FAILED_MESSAGE',
	FORMATION_SPECIAL_FIELD_GOAL_PAT = 'FORMATION_SPECIAL_FIELD_GOAL_PAT',
	FORMATION_SPECIAL_FIELD_GOAL_PAT_BLOCK = 'FORMATION_SPECIAL_FIELD_GOAL_PAT_BLOCK',
	FORMATION_SPECIAL_KICK_RETURN = 'FORMATION_SPECIAL_KICK_RETURN',
	FORMATION_SPECIAL_KICKOFF = 'FORMATION_SPECIAL_KICKOFF',
	FORMATION_SPECIAL_PUNT = 'FORMATION_SPECIAL_PUNT',
	FORMATION_SPECIAL_PUNT_BLOCK_RETURN = 'FORMATION_SPECIAL_PUNT_BLOCK_RETURN',
	FORMATION_TEMPLATE = 'FORMATION_TEMPLATE',
	FORMATIONS = 'FORMATIONS',
	FORMATIONS_COPIED = 'FORMATIONS_COPIED',
	FORMATIONS_REFERENCE = 'FORMATIONS_REFERENCE',
	FREE_PLAYBOOK = 'FREE_PLAYBOOK',
	FREE_PLAYBOOK_CTA = 'FREE_PLAYBOOK_CTA',
	GET_PLAYS = 'GET_PLAYS',
	GOOD = 'GOOD',
	HAVE_A_CODE = 'HAVE_A_CODE',
	HIDE_OPPONENT = 'HIDE_OPPONENT',
	HIDE_PLAYER_NAMES = 'HIDE_PLAYER_NAMES',
	IMPORT = 'IMPORT',
	IMPORT_COMPLETE = 'IMPORT_COMPLETE',
	IMPORT_SUCCESS_MESSAGE_PLAYBOOK = 'IMPORT_SUCCESS_MESSAGE_PLAYBOOK',
	IMPORT_SUCCESS_MESSAGE_PLAYPACK = 'IMPORT_SUCCESS_MESSAGE_PLAYPACK',
	INCREASE_PLAYERS_PER_SIDE = 'INCREASE_PLAYERS_PER_SIDE',
	INFO = 'INFO',
	INVALID_CODE = 'INVALID_CODE',
	INVITE_LOOKUP_FAILED_ALERT = 'INVITE_LOOKUP_FAILED_ALERT',
	INVITE_LOOKUP_FAILED_ALERT_MESSAGE = 'INVITE_LOOKUP_FAILED_ALERT_MESSAGE',
	JERSEY_NUMBER = 'JERSEY_NUMBER',
	JOIN_A_TEAM = 'JOIN_A_TEAM',
	LAST_NAME = 'LAST_NAME',
	LAYOUT = 'LAYOUT',
	LAYOUT_AND_OPTIONS = 'LAYOUT_AND_OPTIONS',
	LEARN_HOW_TO_CREATE_PDFS = 'LEARN_HOW_TO_CREATE_PDFS',
	LESS_BACKFIELD = 'LESS_BACKFIELD',
	LIGHT = 'LIGHT',
	LIGHT_BACKGROUND = 'LIGHT_BACKGROUND',
	LIST = 'LIST',
	LOAD_OPPONENT_FORMATION = 'LOAD_OPPONENT_FORMATION',
	LOADER_MESSAGE_RENDER = 'LOADER_MESSAGE_RENDER',
	LOADER_MESSAGE_SYNC = 'LOADER_MESSAGE_SYNC',
	LOG_IN = 'LOG_IN',
	LOG_OUT = 'LOG_OUT',
	LOGIN_FAILED_ALERT = 'LOGIN_FAILED_ALERT',
	LOGIN_FAILED_ALERT_MESSAGE = 'LOGIN_FAILED_ALERT_MESSAGE',
	LOOKING_FOR_FLAG_APP = 'LOOKING_FOR_FLAG_APP',
	LOOKING_FOR_TACKLE_APP = 'LOOKING_FOR_TACKLE_APP',
	MANAGE_BILLING = 'MANAGE_BILLING',
	MODIFIED_BY_TEMPLATE = 'MODIFIED_BY_TEMPLATE',
	MONTH = 'MONTH',
	MORE_BACKFIELD = 'MORE_BACKFIELD',
	NETWORK_STATUS = 'NETWORK_STATUS',
	NEW = 'NEW',
	NEW_DEFENSIVE_FORMATION = 'NEW_DEFENSIVE_FORMATION',
	NEW_DEFENSIVE_PLAY = 'NEW_DEFENSIVE_PLAY',
	NEW_FORMATION = 'NEW_FORMATION',
	NEW_OFFENSIVE_FORMATION = 'NEW_OFFENSIVE_FORMATION',
	NEW_OFFENSIVE_PLAY = 'NEW_OFFENSIVE_PLAY',
	NEW_PASSWORD = 'NEW_PASSWORD',
	NEW_PLAY = 'NEW_PLAY',
	NEW_PLAYBOOK = 'NEW_PLAYBOOK',
	NEW_ROUTE = 'NEW_ROUTE',
	NEW_SPECIAL_TEAMS_FORMATION = 'NEW_SPECIAL_TEAMS_FORMATION',
	NEW_SPECIAL_TEAMS_PLAY = 'NEW_SPECIAL_TEAMS_PLAY',
	NO = 'NO',
	NO_EMAIL = 'NO_EMAIL',
	NONE = 'NONE',
	NOT_NOW = 'NOT_NOW',
	NO_ARCHIVED_PLAYBOOKS = 'NO_ARCHIVED_PLAYBOOKS',
	NO_NOTES = 'NO_NOTES',
	NO_TEAMS_TITLE = 'NO_TEAMS_TITLE',
	NO_TEAMS_DESCRIPTION = 'NO_TEAMS_DESCRIPTION',
	NOTES = 'NOTES',
	NUMBER = 'NUMBER',
	OFF = 'OFF',
	OFFENSE = 'OFFENSE',
	OFFENSIVE_CATEGORIES = 'OFFENSIVE_CATEGORIES',
	OFFENSIVE_FORMATIONS = 'OFFENSIVE_FORMATIONS',
	OFFENSIVE_PERSONNEL_GROUPS = 'OFFENSIVE_PERSONNEL_GROUPS',
	OFFENSIVE_TEMPLATES = 'OFFENSIVE_TEMPLATES',
	OFFLINE = 'OFFLINE',
	OK = 'OK',
	ON = 'ON',
	ONLINE = 'ONLINE',
	OPPONENT_FORMATION = 'OPPONENT_FORMATION',
	OPTIONAL_CATEGORY_NOTES = 'OPTIONAL_CATEGORY_NOTES',
	OPTIONAL_FORMATION_NOTES = 'OPTIONAL_FORMATION_NOTES',
	OPTIONAL_LOGO = 'OPTIONAL_LOGO',
	OPTIONAL_PERSONNEL_GROUP_NOTES = 'OPTIONAL_PERSONNEL_GROUP_NOTES',
	OPTIONAL_PHOTO = 'OPTIONAL_PHOTO',
	OPTIONAL_PLAY_NOTES = 'OPTIONAL_PLAY_NOTES',
	OPTIONAL_ROUTE_NOTES = 'OPTIONAL_ROUTE_NOTES',
	OPTIONAL_ROUTE_TREE_NOTES = 'OPTIONAL_ROUTE_TREE_NOTES',
	OPTIONS = 'OPTIONS',
	OTHER = 'OTHER',
	PASSWORD = 'PASSWORD',
	PASSWORD_CHANGE_FAILED_ALERT = 'PASSWORD_CHANGE_FAILED_ALERT',
	PASSWORD_CHANGE_FAILED_ALERT_MESSAGE = 'PASSWORD_CHANGE_FAILED_ALERT_MESSAGE',
	PAYMENT = 'PAYMENT',
	PDF_HELP = 'PDF_HELP',
	PDF_STEPS_MESSAGE = 'PDF_STEPS_MESSAGE',
	PERFORATED_WRISTBANDS = 'PERFORATED_WRISTBANDS',
	PERSONNEL_GROUP = 'PERSONNEL_GROUP',
	PERSONNEL_GROUP_BASE = 'PERSONNEL_GROUP_BASE',
	PERSONNEL_GROUP_CUSTOM_1 = 'PERSONNEL_GROUP_CUSTOM_1',
	PERSONNEL_GROUP_CUSTOM_2 = 'PERSONNEL_GROUP_CUSTOM_2',
	PERSONNEL_GROUP_NAME = 'PERSONNEL_GROUP_NAME',
	PERSONNEL_GROUPS = 'PERSONNEL_GROUPS',
	PHOTO = 'PHOTO',
	PLATFORM_VERSION = 'PLATFORM_VERSION',
	PLAY = 'PLAY',
	PLAY_COPIED = 'PLAY_COPIED',
	PLAY_EXCHANGE = 'PLAY_EXCHANGE',
	PLAY_INFO = 'PLAY_INFO',
	PLAY_INFO_FREE = 'PLAY_INFO_FREE',
	PLAY_INFO_NOT_CONNECTED = 'PLAY_INFO_NOT_CONNECTED',
	PLAY_NAME = 'PLAY_NAME',
	PLAY_SPECIFIC = 'PLAY_SPECIFIC',
	PLAYBOOK = 'PLAYBOOK',
	PLAYBOOK_COPIED = 'PLAYBOOK_COPIED',
	PLAYBOOK_COPIED_MESSAGE_TEMPLATE = 'PLAYBOOK_COPIED_MESSAGE_TEMPLATE',
	PLAYBOOK_COPY_FAILED_ALERT = 'PLAYBOOK_COPY_FAILED_ALERT',
	PLAYBOOK_COPY_FAILED_ALERT_MESSAGE = 'PLAYBOOK_COPY_FAILED_ALERT_MESSAGE',
	PLAYBOOK_NAME = 'PLAYBOOK_NAME',
	PLAYBOOK_UNARCHIVED_MESSAGE = 'PLAYBOOK_UNARCHIVED_MESSAGE',
	PLAYBOOKS = 'PLAYBOOKS',
	PLAYER = 'PLAYER',
	PLAYER_ACCESS = 'PLAYER_ACCESS',
	PLAYERS = 'PLAYERS',
	PLAYERS_PER_SIDE = 'PLAYERS_PER_SIDE',
	PLAYMAKER_CONNECTION_REQUIRED_ALERT = 'PLAYMAKER_CONNECTION_REQUIRED_ALERT',
	PLAYMAKER_CONNECTION_REQUIRED_ALERT_MESSAGE = 'PLAYMAKER_CONNECTION_REQUIRED_ALERT_MESSAGE',
	PLAYS_COPIED = 'PLAYS_COPIED',
	POOR = 'POOR',
	POSITION = 'POSITION',
	POSITION_COLORS = 'POSITION_COLORS',
	POSITION_ICON_SHADING = 'POSITION_ICON_SHADING',
	POSITION_LABELS = 'POSITION_LABELS',
	POSITION_NOTE = 'POSITION_NOTE',
	POSTAL_CODE = 'POSTAL_CODE',
	POWERED_BY = 'POWERED_BY',
	PRINT = 'PRINT',
	PRINTER_CALIBRATION = 'PRINTER_CALIBRATION',
	PRINTING_POWERED_BY = 'PRINTING_POWERED_BY',
	PROCESS_PAYMENT = 'PROCESS_PAYMENT',
	REDEMPTION_FAILED_MESSAGE = 'REDEMPTION_FAILED_MESSAGE',
	RELOAD = 'RELOAD',
	RELOAD_PLAYBOOKS = 'RELOAD_PLAYBOOKS',
	REMOVE = 'REMOVE',
	RENEW = 'RENEW',
	REQUEST_ACCOUNT_DELETION = 'REQUEST_ACCOUNT_DELETION',
	RESEND_INVITE = 'RESEND_INVITE',
	RESET_PASSWORD = 'RESET_PASSWORD',
	RESTART = 'RESTART',
	ROUTE = 'ROUTE',
	ROUTE_INFO = 'ROUTE_INFO',
	ROUTE_LIMIT_REACHED_ALERT = 'ROUTE_LIMIT_REACHED_ALERT',
	ROUTE_LIMIT_REACHED_MESSAGE = 'ROUTE_LIMIT_REACHED_MESSAGE',
	ROUTE_NAME = 'ROUTE_NAME',
	ROUTE_TREE = 'ROUTE_TREE',
	ROUTE_TREE_INFO = 'ROUTE_TREE_INFO',
	ROUTE_TREE_SCREEN = 'ROUTE_TREE_SCREEN',
	ROUTE_TREE_QUICK_OUT = 'ROUTE_TREE_QUICK_OUT',
	ROUTE_TREE_SLANT = 'ROUTE_TREE_SLANT',
	ROUTE_TREE_COMEBACK = 'ROUTE_TREE_COMEBACK',
	ROUTE_TREE_CURL = 'ROUTE_TREE_CURL',
	ROUTE_TREE_OUT = 'ROUTE_TREE_OUT',
	ROUTE_TREE_IN = 'ROUTE_TREE_IN',
	ROUTE_TREE_CORNER = 'ROUTE_TREE_CORNER',
	ROUTE_TREE_POST = 'ROUTE_TREE_POST',
	ROUTE_TREE_GO = 'ROUTE_TREE_GO',
	SAVE_CHANGES = 'SAVE_CHANGES',
	SCOUT_CARDS = 'SCOUT_CARDS',
	SEE_THE_TUTORIAL_VIDEOS = 'SEE_THE_TUTORIAL_VIDEOS',
	SEGMENTS = 'SEGMENTS',
	SELECT = 'SELECT',
	SELECT_A_TEAM_MEMBER = 'SELECT_A_TEAM_MEMBER',
	SELECT_PLAYBOOKS = 'SELECT_PLAYBOOKS',
	SELECT_TEAM = 'SELECT_TEAM',
	SEND_INVITE = 'SEND_INVITE',
	SEND_INVITES = 'SEND_INVITES',
	SEND_ME_EMAIL_UPDATES = 'SEND_ME_EMAIL_UPDATES',
	SHOW = 'SHOW',
	SHOW_OPPONENT = 'SHOW_OPPONENT',
	SHOW_PLAYER_NAMES = 'SHOW_PLAYER_NAMES',
	SNAP_TO_GRID = 'SNAP_TO_GRID',
	SOMETHING_WENT_WRONG_ALERT_MESSAGE = 'SOMETHING_WENT_WRONG_ALERT_MESSAGE',
	SPECIAL_TEAMS = 'SPECIAL_TEAMS',
	SPECIAL_TEAMS_CATEGORIES = 'SPECIAL_TEAMS_CATEGORIES',
	SPECIAL_TEAMS_FORMATIONS = 'SPECIAL_TEAMS_FORMATIONS',
	SPECIAL_TEAMS_PERSONNEL_GROUPS = 'SPECIAL_TEAMS_PERSONNEL_GROUPS',
	SPECIAL_TEAMS_TEMPLATES = 'SPECIAL_TEAMS_TEMPLATES',
	SPONSOR = 'SPONSOR',
	STAFF = 'STAFF',
	STAFF_ACCESS = 'STAFF_ACCESS',
	STANDARD = 'STANDARD',
	START_A_FREE_TRIAL = 'START_A_FREE_TRIAL',
	STATE_PROVINCE = 'STATE_PROVINCE',
	STATUS = 'STATUS',
	STILL_HAVING_TROUBLE = 'STILL_HAVING_TROUBLE',
	STYLES = 'STYLES',
	SUCCESS = 'SUCCESS',
	SUPPORT = 'SUPPORT',
	SYMBOL = 'SYMBOL',
	TEAM = 'TEAM',
	TEAMS = 'TEAMS',
	TEAM_CENTER = 'TEAM_CENTER',
	TEAM_COLOR = 'TEAM_COLOR',
	TEAM_CREATION_FAILED_ALERT = 'TEAM_CREATION_FAILED_ALERT',
	TEAM_CREATION_FAILED_ALERT_MESSAGE = 'TEAM_CREATION_FAILED_ALERT_MESSAGE',
	TEAM_ID = 'TEAM_ID',
	TEAM_INFO = 'TEAM_INFO',
	TEAM_INVITE_FAILED = 'TEAM_INVITE_FAILED',
	TEAM_INVITE_FAILED_MESSAGE = 'TEAM_INVITE_FAILED_MESSAGE',
	TEAM_INVITE_SENT = 'TEAM_INVITE_SENT',
	TEAM_INVITE_SENT_MESSAGE = 'TEAM_INVITE_SENT_MESSAGE',
	TEAM_MEMBER = 'TEAM_MEMBER',
	TEAM_NAME = 'TEAM_NAME',
	TEMPLATE = 'TEMPLATE',
	TEMPORARY_PASSWORD_SENT_MESSAGE = 'TEMPORARY_PASSWORD_SENT_MESSAGE',
	TERMS_AND_PRIVACY_POLICY = 'TERMS_AND_PRIVACY_POLICY',
	TEXT = 'TEXT',
	TITLE = 'TITLE',
	TOOLTIP_DIAGRAM_ADVANCED_OPTIONS = 'TOOLTIP_DIAGRAM_ADVANCED_OPTIONS',
	TOOLTIP_DIAGRAM_ASSIGN_POSITIONS = 'TOOLTIP_DIAGRAM_ASSIGN_POSITIONS',
	TOOLTIP_DIAGRAM_DRAG_TOOLBAR = 'TOOLTIP_DIAGRAM_DRAG_TOOLBAR',
	TOOLTIP_DIAGRAM_DRAW_ROUTE = 'TOOLTIP_DIAGRAM_DRAW_ROUTE',
	TOOLTIP_DIAGRAM_EDIT_POSITION = 'TOOLTIP_DIAGRAM_EDIT_POSITION',
	TOOLTIP_DIAGRAM_LOCKED = 'TOOLTIP_DIAGRAM_LOCKED',
	TOOLTIP_DIAGRAM_NOT_PERMITTED = 'TOOLTIP_DIAGRAM_NOT_PERMITTED',
	TOOLTIP_PLAYBOOK_CUSTOMIZE_CATEGORIES = 'TOOLTIP_PLAYBOOK_CUSTOMIZE_CATEGORIES',
	TOOLTIP_PLAYBOOK_CUSTOMIZE_FORMATIONS = 'TOOLTIP_PLAYBOOK_CUSTOMIZE_FORMATIONS',
	TOOLTIP_PLAYBOOK_REARRANGE_PLAYS = 'TOOLTIP_PLAYBOOK_REARRANGE_PLAYS',
	TOOLTIP_ROSTER_ADD_PLAYERS = 'TOOLTIP_ROSTER_ADD_PLAYERS',
	TOOLTIP_ROUTE_TREE_LOCKED = 'TOOLTIP_ROUTE_TREE_LOCKED',
	TRANSFER = 'TRANSFER',
	TRANSFER_OWNERSHIP = 'TRANSFER_OWNERSHIP',
	TRANSFER_OWNERSHIP_COMPLETE_TITLE = 'TRANSFER_OWNERSHIP_COMPLETE_TITLE',
	TRANSFER_OWNERSHIP_COMPLETE_MESSAGE = 'TRANSFER_OWNERSHIP_COMPLETE_MESSAGE',
	TRANSFER_OWNERSHIP_PROMPT_MESSAGE = 'TRANSFER_OWNERSHIP_PROMPT_MESSAGE',
	TRANSFER_OWNERSHIP_QUESTION = 'TRANSFER_OWNERSHIP_QUESTION',
	TRANSFER_PLAYBOOK = 'TRANSFER_PLAYBOOK',
	TRANSFER_TO = 'TRANSFER_TO',
	TRANSFER_TO_COMPLETE_MESSAGE_TEMPLATE = 'TRANSFER_TO_COMPLETE_MESSAGE_TEMPLATE',
	TRANSFER_TO_COMPLETE_TITLE = 'TRANSFER_TO_COMPLETE_TITLE',
	TRANSFER_TO_IMPOSSIBLE_MESSAGE = 'TRANSFER_TO_IMPOSSIBLE_MESSAGE',
	TRANSFER_TO_IMPOSSIBLE_TITLE = 'TRANSFER_TO_IMPOSSIBLE_TITLE',
	TRY_AGAIN = 'TRY_AGAIN',
	TYPE_DELETE_HERE = 'TYPE_DELETE_HERE',
	TYPE_TRANSFER_HERE = 'TYPE_TRANSFER_HERE',
	UNABLE_TO_COPY_FORMATION_MESSAGE = 'UNABLE_TO_COPY_FORMATION_MESSAGE',
	UNABLE_TO_COPY_FORMATION_TITLE = 'UNABLE_TO_COPY_FORMATION_TITLE',
	UNABLE_TO_COPY_PLAY_MESSAGE = 'UNABLE_TO_COPY_PLAY_MESSAGE',
	UNABLE_TO_COPY_PLAY_TITLE = 'UNABLE_TO_COPY_PLAY_TITLE',
	UNARCHIVE = 'UNARCHIVE',
	UNARCHIVE_COMPLETE = 'UNARCHIVE_COMPLETE',
	UNARCHIVE_PLAYBOOK_MESSAGE = 'UNARCHIVE_PLAYBOOK_MESSAGE',
	UNCATEGORIZED = 'UNCATEGORIZED',
	UNRECOGNIZED_EMAIL_ALERT = 'UNRECOGNIZED_EMAIL_ALERT',
	UNRECOGNIZED_EMAIL_ALERT_MESSAGE = 'UNRECOGNIZED_EMAIL_ALERT_MESSAGE',
	UPDATE_PLAYS_COUNT = 'UPDATE_PLAYS_COUNT',
	UPDATE_PLAYS_DESCRIPTION_TACKLE = 'UPDATE_PLAYS_DESCRIPTION_TACKLE',
	UPDATE_PLAYS_DESCRIPTION_FLAG = 'UPDATE_PLAYS_DESCRIPTION_FLAG',
	UPDATE_PLAYS_TITLE = 'UPDATE_PLAYS_TITLE',
	UPGRADE = 'UPGRADE',
	UPGRADE_NOW = 'UPGRADE_NOW',
	UPGRADE_PLAYBOOK_ARCHIVE_ALERT = 'UPGRADE_PLAYBOOK_ARCHIVE_ALERT',
	UPGRADE_PRINT_ACCESS_ALERT = 'UPGRADE_PRINT_ACCESS_ALERT',
	UPGRADE_PRINT_ACCESS_ALERT_TROLL = 'UPGRADE_PRINT_ACCESS_ALERT_TROLL',
	UPGRADE_TEAM_ACCESS_ALERT = 'UPGRADE_TEAM_ACCESS_ALERT',
	UPGRADE_TEAM_ACCESS_ALERT_TROLL = 'UPGRADE_TEAM_ACCESS_ALERT_TROLL',
	UPSIZE_ACTION = 'UPSIZE_ACTION',
	UPSIZE_COMPLETE_MESSAGE_TEMPLATE = 'UPSIZE_COMPLETE_MESSAGE_TEMPLATE',
	UPSIZE_COMPLETE_TITLE = 'UPSIZE_COMPLETE_TITLE',
	UPSIZE_IMPOSSIBLE_MESSAGE = 'UPSIZE_IMPOSSIBLE_MESSAGE',
	UPSIZE_IMPOSSIBLE_TITLE = 'UPSIZE_IMPOSSIBLE_TITLE',
	UPSIZE_MESSAGE = 'UPSIZE_MESSAGE',
	UPSIZED_NAME_SUFFIX = 'UPSIZED_NAME_SUFFIX',
	USE_ON_COMPUTER_MESSAGE = 'USE_ON_COMPUTER_MESSAGE',
	USER = 'USER',
	USER_AGENT = 'USER_AGENT',
	VIEW = 'VIEW',
	WE_ARE_SORRY = 'WE_ARE_SORRY',
	WRISTBANDS = 'WRISTBANDS',
	YES = 'YES',
	YOU = 'YOU',
	YOUR_INFO = 'YOUR_INFO',
}

export function _s(key: StringKey): string {
	const viewState = store.appState().viewState;

	return (viewState && viewState.strings) ? viewState.strings[key] || '' : '';
}

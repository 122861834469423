import * as React from 'react';

export function RouteTreeComposite() {
	return <div className="field">
		<svg viewBox="0 0 1024 2304">
			<svg className="markings" x="15%" y="26.67%" width="70%" height="46.67%">
				<svg className="tenYards" x="0%" y="14.3%" width="100%" height="14.3%">
					<line className="yardline" x1="0" x2="100%" y1="100%" y2="100%"></line>
					<text x="-3%" y="100%">15</text>
				</svg>
				<svg className="tenYards" x="0%" y="28.6%" width="100%" height="14.3%">
					<line className="yardline" x1="0" x2="100%" y1="50%" y2="50%"></line>
					<line className="yardline" x1="0" x2="100%" y1="100%" y2="100%"></line>
					<text x="-3%" y="50%">10</text>
					<text x="-3%" y="100%">5</text>
				</svg>
				<svg className="tenYards" x="0%" y="42.9%" width="100%" height="14.3%">
					<text x="-3%" y="50%">0</text>
				</svg>
			</svg>
		</svg>
	</div>;
}
import { assign, filter, find, forEach, map, reduce, sortBy } from 'lodash';
import { default as valueFilters } from 'playmaker-team-common/dist/shared/valueFilters';
import * as React from 'react';
import * as actions from '../actions';
import { current as getCurrentContext } from '../componentContext';
import { GamePhase } from '../models/diagramModel';
import { default as personnelGroupFactory, IPersonnelGroup } from '../models/personnelGroup';
import { ISortable } from '../models/sortableModel';
import { default as teamFactory, ITeam } from '../models/team';
import * as store from '../store';
import { IDragData } from '../store';
import { _s, StringKey } from '../strings';
import * as viewManager from '../viewManager';
import { AlertList } from './alert';
import { ConnectionStatus } from './connectionStatus';
import { DragDrop, IDragSourceParams, IDropTargetParams } from './dnd';

interface Props {
	alerts?: store.IAlert[];
	appState: store.IAppState;
}

interface State {
	pendingPersonnelGroup: any;
	selectedItems: string[];
	team: ITeam;
}

class EditPersonnelGroup extends React.Component<any, any> {
	constructor(props) {
		super(props);

		this.state = { mutatedGroup: personnelGroupFactory(props.personnelGroup.toDocument()) };
	}

	public handleChange(path, e) {
		const { mutatedGroup } = this.state;
		const group = personnelGroupFactory(mutatedGroup.toDocument());
		const newVal = e.type === 'blur' ? valueFilters.clean(e.target.value) : e.target.value;

		group.setAt(path, newVal);

		this.setState({ mutatedGroup: group });
	}

	public render() {
		const { copyGroup, deleteGroup, save, validate } = this.props;
		const { mutatedGroup } = this.state;
		const isValid = validate(mutatedGroup);

		const clickHandler = () => { if (isValid) { save(mutatedGroup); } };

		return <div className="view">
			<header>
				<div className="actions"><a className="button" onClick={ viewManager.popModal }><span className="icon cancel"></span></a></div>
				<div className="title">{ _s(StringKey.PERSONNEL_GROUP) }</div>
				<div className={ isValid ? 'actions' : 'actions disabled' }>
					<a className="button" onClick={ clickHandler }><span className="icon ok"></span></a>
				</div>
			</header>
			<div className="content">
				<div className="inner">
					<input className={ isValid ? null : 'invalid' } type="text" placeholder={ _s(StringKey.PERSONNEL_GROUP_NAME) } value={ mutatedGroup.label } onChange={ this.handleChange.bind(this, 'label') } onBlur={ this.handleChange.bind(this, 'label') } required />
					<textarea placeholder={ _s(StringKey.OPTIONAL_PERSONNEL_GROUP_NOTES) } onChange={ this.handleChange.bind(this, 'note') } onBlur={ this.handleChange.bind(this, 'note') }>{ mutatedGroup.note }</textarea>
					<div className="actions">
						<a className="button basic" onClick={ () => { copyGroup(mutatedGroup); } }><span>{ _s(StringKey.COPY) }</span></a>
						<a className="button basic delete" onClick={ () => { deleteGroup(mutatedGroup); } }><span>{ _s(StringKey.DELETE) }</span></a>
					</div>
				</div>
			</div>
		</div>;
	}
}

class PersonnelGroupRow extends React.Component<any, any> {
	private _dropTarget;

	constructor(props) {
		super(props);

		this.canDrop = this.canDrop.bind(this);
		this.onDrop = this.onDrop.bind(this);

		this.setDropTarget = this.setDropTarget.bind(this);
	}

	public canDrop(data: IDragData) {
		const { personnelGroup } = this.props;

		return data.type === 'row' && data.info.personnelGroup && data.info.personnelGroup.id !== personnelGroup.id;
	}

	public onDrop(data: IDragData) {
		const { personnelGroup, onDrop } = this.props;

		if (onDrop) {
			onDrop(data.info.personnelGroup.id, personnelGroup.id);
		}
	}

	public setDropTarget(el) {
		const { addDropTarget, removeDropTarget } = this.props;

		if (!addDropTarget) {
			return;
		}

		if (this._dropTarget) {
			removeDropTarget(this._dropTarget);
		}

		this._dropTarget = el;

		if (this._dropTarget) {
			addDropTarget(this._dropTarget, this.canDrop, this.onDrop);
		}
	}

	public render() {
		const { personnelGroup, classNames, isChecked, onChange = () => null, onInfoClick, onPointerDown, onTouchStart, onMouseDown, rowNumber } = this.props;

		return <div className={ classNames.join(' ') } ref={ this.setDropTarget }>
			<div className="checkbox">
				<label className={ isChecked ? 'on' : '' }><span className="icon badge checkmark">{ rowNumber }</span><input type="checkbox" name="groups" checked={ isChecked } value={ personnelGroup.id } onChange={ onChange } /></label>
			</div>
			<span>{ personnelGroup.label }</span>
			<a className="button" onClick={ onInfoClick }><span className="icon infoSmall"></span></a>
			<a className="button" onPointerDown={ onPointerDown } onTouchStart={ onTouchStart } onMouseDown={ onMouseDown }><span className="icon sortItem"></span></a>
		</div>;
	}
}

export class PersonnelGroups extends React.Component<Props, State> {

	constructor(props) {
		super(props);
		const { currentTeam } = getCurrentContext();
		const team: ITeam = teamFactory(currentTeam);

		this.state = {pendingPersonnelGroup: this.createPendingPersonnelGroup(team), selectedItems: [], team };

		this.handleAddClick = this.handleAddClick.bind(this);
		this.handleBackClick = this.handleBackClick.bind(this);
		this.handleItemDrop = this.handleItemDrop.bind(this);
		this.handleItemSelected = this.handleItemSelected.bind(this);
		this.handleSelectAll = this.handleSelectAll.bind(this);
		this.handleDeleteClick = this.handleDeleteClick.bind(this);
		this.handleCopyClick = this.handleCopyClick.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.validateGroup = this.validateGroup.bind(this);
		this.deleteGroup = this.deleteGroup.bind(this);
		this.copyGroups = this.copyGroups.bind(this);
		this.copyGroup = this.copyGroup.bind(this);
	}

	public getStateFromProps() {
	}

	public createPendingPersonnelGroup(team) {
		const { currentPhase, currentPlaybook } = getCurrentContext();
		const sortIndex = Math.ceil(reduce(this.getPersonnalGroups(team), (max, group) => ((group.sortIndex || 0) > max) ?  (group.sortIndex || 0) : max, 0)) + 1;

		return personnelGroupFactory({ sortIndex, phase: currentPhase, playersPerSide: currentPlaybook.playersPerSide });
	}

	public async handleBackClick() {
		const { team } = this.state;

		await actions.saveTeam(team, `personnel-update-${team.id}`);

		viewManager.popPath();
	}

	public deleteGroup(group: IPersonnelGroup) {
		const { team } = this.state;
		const { model, currentPlaybook } = getCurrentContext();
		const groupPlays = filter(model.plays, { playbookId: currentPlaybook.id, activePersonnelGroup: group.id });
		const groupPlayCount = groupPlays.length;
		const doDelete = () => {
			team.settings.personnelGroups.remove(group.id);
			// actions.updatePersonnelGroup(groupPlays, ''); // dissassociate the personnel group?
			viewManager.popModal();
		};

		if (groupPlayCount) {
			actions.pushAlert({
				message: `"${group.label}" is used by ${groupPlayCount} ${ groupPlayCount === 1 ? 'play' : 'plays'}. Are you sure you want to delete it?`,
				mode: store.AlertMode.prompt,
				severity: store.AlertSeverity.info,
				title: 'Delete Personnel Group?',
				actions: [{
					label: _s(StringKey.YES),
					action: async () => {
						doDelete();
						actions.popAlert();
					},
				}, {
					label: _s(StringKey.NO),
					action: () => {
						actions.popAlert();
					},
				}],
			});
		} else {
			doDelete();
		}
	}

	public validateGroup(group: IPersonnelGroup) {
		const { team } = this.state;
		let isValid = group.isValid();

		if (isValid) {
			const others = filter(team.settings.personnelGroups.values, (pg) => pg.label === group.label && pg.phase === group.phase && pg.playersPerSide === group.playersPerSide && pg.id !== group.id);

			isValid = others.length === 0;
		}

		return isValid;
	}

	public handleChange(e) {
		const { pendingPersonnelGroup } = this.state;
		const newVal = e.type === 'blur' ? valueFilters.clean(e.target.value) : e.target.value;
		const { currentPhase} = getCurrentContext();

		const updatedGroup = personnelGroupFactory(pendingPersonnelGroup.toDocument());

		updatedGroup.label = newVal;
		// bug trap: be sure that the pending group has a phase
		updatedGroup.phase = typeof updatedGroup.phase === 'undefined' ? currentPhase : updatedGroup.phase;

		this.setState({ pendingPersonnelGroup: updatedGroup });
	}

	public handleAddClick() {
		const { pendingPersonnelGroup, team } = this.state;

		if (this.validateGroup(pendingPersonnelGroup)) {
			team.settings.personnelGroups.add(pendingPersonnelGroup);
			this.setState({ pendingPersonnelGroup: this.createPendingPersonnelGroup(team)});
		}
	}

	public handleInfoClick(id) {
		const { team } = this.state;
		const personnelGroup = find(team.settings.personnelGroups, { id });

		viewManager.pushModal({
			component: EditPersonnelGroup,
			props: () => {
				return { personnelGroup, copyGroup: this.copyGroup, deleteGroup: this.deleteGroup, validate: this.validateGroup.bind(this), save: (group) => { team.settings.personnelGroups[id] = group; viewManager.popModal(); } };
			},
			screenName: 'EditPersonnel',
		});
	}

	public handleItemSelected(e) {
		const { selectedItems } = this.state;
		const updatedItems = selectedItems.concat();
		const itemIndex = updatedItems.indexOf(e.target.value);

		if (itemIndex === -1) {
			updatedItems.push(e.target.value);
		} else {
			updatedItems.splice(itemIndex, 1);
		}

		this.setState({ selectedItems: updatedItems });
	}

	public handleSelectAll() {
		const { selectedItems, team } = this.state;
		const personnelGroups = this.getPersonnalGroups(team);

		if (selectedItems.length < personnelGroups.length) {
			this.setState({ selectedItems: map(personnelGroups, (c) => c.id) });
		} else {
			this.setState({ selectedItems: [] });
		}
	}

	public handleItemDrop(sourceId: string, targetId: string) {
		this.setState((prevState: State) => {
			const newState: State = assign({}, prevState);
			const { team } = newState;
			const personnelGroups = team.settings.personnelGroups.values;
			const source = team.settings.personnelGroups[sourceId];
			const target = team.settings.personnelGroups[targetId];
			const targetSortIndex = target.sortIndex;
			let index = 0;

			source.sortIndex = (source.sortIndex > targetSortIndex) ? targetSortIndex - 0.5 : targetSortIndex + 0.5;

			forEach(sortBy(personnelGroups, 'sortIndex'), (pg: ISortable) => {
				pg.sortIndex = index++;
			});

			return newState;
		});
	}

	public handleDeleteClick() {
		const { selectedItems, team } = this.state;
		const { model, currentPlaybook } = getCurrentContext();
		const groupPlays = filter(model.plays, (p) => p.playbookId === currentPlaybook.id && selectedItems.indexOf(p.activePersonnelGroup) !== -1);
		const groupPlayCount = groupPlays.length;
		const doDelete = () => {
			forEach(selectedItems, (item) => {
				team.settings.personnelGroups.remove(item);
			});

			this.setState({ selectedItems: [] });
		};

		if (groupPlayCount) {
			actions.pushAlert({
				message: selectedItems.length === 1 ? `"${team.settings.personnelGroups[selectedItems[0]].label}" is used by ${groupPlayCount} ${ groupPlayCount === 1 ? 'play' : 'plays'}. Are you sure you want to delete it?` : `These personnel groups are used by ${groupPlayCount} ${ groupPlayCount === 1 ? 'play' : 'plays'}. Are you sure you want to delete them?`,
				mode: store.AlertMode.prompt,
				severity: store.AlertSeverity.info,
				title: selectedItems.length === 1 ? 'Delete Personnel Group?' : 'Delete Personnel Groups?',
				actions: [{
					label: _s(StringKey.YES),
					action: async () => {
						doDelete();
						actions.popAlert();
					},
				}, {
					label: _s(StringKey.NO),
					action: () => {
						actions.popAlert();
					},
				}],
			});
		} else {
			doDelete();
		}
	}

	public copyGroup(personnelGroup: IPersonnelGroup) {
		this.copyGroups([personnelGroup]);
		actions.popModal();
	}

	public copyGroups(personnelGroups: IPersonnelGroup[], stateUpdate: any = {}) {
		const { team } = this.state;
		const mutatedTeam = teamFactory(team);

		for (const pg of personnelGroups) {
			const clone = pg.clone();

			clone.label = `${pg.label} ${_s(StringKey.COPY)}`;
			mutatedTeam.settings.personnelGroups.add(clone);
		}

		stateUpdate.team = mutatedTeam;

		this.setState(stateUpdate);

	}

	public handleCopyClick() {
		const { selectedItems, team } = this.state;
		const groups = filter(team.settings.personnelGroups.values, (pg) => selectedItems.indexOf(pg.id) !== -1);

		this.copyGroups(groups, { selectedItems: []} );
	}

	public getPersonnalGroups(team) {
		const { currentPhase, currentPlaybook } = getCurrentContext();
		return filter(team.settings.personnelGroups.values, { phase: currentPhase, playersPerSide: currentPlaybook.playersPerSide});
	}

	public render() {
		const { alerts= [] } = this.props;
		const { pendingPersonnelGroup, selectedItems, team } = this.state;
		const { currentPhase } = getCurrentContext();
		const phaseLabelString = currentPhase === GamePhase.Offense ? _s(StringKey.OFFENSIVE_PERSONNEL_GROUPS) : currentPhase === GamePhase.Defense ? _s(StringKey.DEFENSIVE_PERSONNEL_GROUPS) : _s(StringKey.SPECIAL_TEAMS_PERSONNEL_GROUPS);

		const pendingIsValid = this.validateGroup(pendingPersonnelGroup);
		let rowNumber = 1;
		const rows = map(sortBy(this.getPersonnalGroups(team), 'sortIndex'), (personnelGroup: IPersonnelGroup & ISortable) => {
			const itemRowNumber = rowNumber ++;
			const isChecked = selectedItems.indexOf(personnelGroup.id) !== -1;
			const dragSpec = {
				component: () => <div className="group"><PersonnelGroupRow personnelGroup={ personnelGroup} classNames={ ['row', 'dragging'] } isChecked={ isChecked } rowNumber={ itemRowNumber } /></div> ,
				getDragLayout: (touch, currentTarget) => {
					const parent = currentTarget.parentElement;
					const boundingRect = parent.getBoundingClientRect();

					return { x: touch.clientX - boundingRect.left, y: touch.clientY - boundingRect.top, width: boundingRect.width, height: boundingRect.height, constrain: 'x', scrollContainer: parent.parentElement };
				},
				data: {
					type: 'row',
					info: {
						personnelGroup,
					},
				},
			};

			return <DragDrop key={ personnelGroup.id }>{
				(params: IDragSourceParams & IDropTargetParams) => {
					const { activeDragSpec, handleMouseDown, handlePointerDown, handleTouchStart, ...dragProps } = params;
					const classNames = ['row'];

					if (activeDragSpec) {
						classNames.push('dropTarget');
						classNames.push(activeDragSpec.data.info.personnelGroup.sortIndex < personnelGroup.sortIndex ? 'bottom' : 'top');
					}

					return <PersonnelGroupRow
						personnelGroup={ personnelGroup}
						classNames={ classNames }
						isChecked={ isChecked }
						rowNumber={ itemRowNumber }
						onChange={ this.handleItemSelected }
						onInfoClick={ this.handleInfoClick.bind(this, personnelGroup.id) }
						onDrop={ this.handleItemDrop }
						onPointerDown={ handlePointerDown && handlePointerDown.bind(this, dragSpec) }
						onTouchStart={ handleTouchStart && handleTouchStart.bind(this, dragSpec) }
						onMouseDown={ handleMouseDown && handleMouseDown.bind(this, dragSpec) }
						{ ...dragProps }
					/>;
				}
			}</DragDrop>;
		});

		return <React.Fragment>
			<header>
				<div className="actions">
					<a className="button" onClick={ this.handleBackClick }><span className="icon back"></span><span>{ phaseLabelString }</span></a>
				</div>
				<div className="actions">
					<ConnectionStatus />
				</div>
			</header>
			<AlertList alerts={ alerts } />
			<div className="content">
				<div className="view">
					<div className="content scrollable">
						<div className="row quickAdd">
							<input className={ !pendingPersonnelGroup.label || pendingIsValid ? null : 'invalid' } type="text" placeholder={ _s(StringKey.ENTER_NEW_PERSONNEL_GROUP_NAME) } value={ pendingPersonnelGroup.label || '' } onChange={ this.handleChange } onBlur={ this.handleChange } required />
							<a className={ pendingIsValid ? 'button' : 'button disabled'} onClick={ pendingIsValid ? this.handleAddClick : null }><span className="icon addItem"></span></a>
						</div>
						<div className="group personnelGroupTable">
							<div className="list">
								<header>
									<div className="row">
										<div className="checkbox">
											<label className={ rows.length > 0 && rows.length === selectedItems.length ? 'on' : '' } onClick={ this.handleSelectAll } ><span className="icon badge checkmark"></span></label>
										</div>
										<span>{ _s(StringKey.PERSONNEL_GROUP) }</span>
									</div>
								</header>
								{  rows }
							</div>
						</div>
					</div>

					<footer className={ selectedItems.length ? 'multiSelect in' : 'multiSelect' }>
						<div className="row">
							<a className="button basic" onClick={ this.handleCopyClick }><span>{ _s(StringKey.COPY) }</span></a>
							<a className="button basic delete" onClick={ this.handleDeleteClick }><span>{ _s(StringKey.DELETE) }</span></a>
						</div>
					</footer>
				</div>
			</div>
		</React.Fragment>;
	}
}

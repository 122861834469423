import { assign, every } from 'lodash';
import * as React from 'react';
import * as actions from '../actions';
import { current as getCurrentContext } from '../componentContext';
import * as logger from '../logger';
import { default as teamFactory, ITeam } from '../models/team';
import { default as userFactory, IUser } from '../models/user';
import * as store from '../store';
import { _s, StringKey } from '../strings';
import * as viewManager from '../viewManager';
import { AlertList } from './alert';
import { Avatar } from './avatar';
import { Page, PageState } from './page';
import { ProfileEdit } from './profileEdit';
import { Spinner } from './spinner';
import { TeamEdit } from './teamEdit';

interface Props {
	appState: store.IAppState;
	alerts: store.IAlert[];
}

interface State {
	activePage: number;
	processing?: boolean;
	team: ITeam;
	user?: IUser;
	password?: string;
}

export class AccountCreate extends React.Component<Props, State> {
	private goToProfilePage;
	private goToTeamPage;

	constructor(props: Props) {
		super(props);

		const { currentUser } = getCurrentContext();
		const defaultTeamColor = props.appState.viewState.settings.teamColors[0];
		this.state = {
			activePage: 0,
			team: teamFactory(),
			user: !currentUser ? userFactory({ emailOptIn: true }) : null,
		};

		this.state.team.settings.color = defaultTeamColor.hex || defaultTeamColor; // TODO: remove this after sufficient time for clients to upgrade to new schema

		this.createAccount = this.createAccount.bind(this);
		this.createTeam = this.createTeam.bind(this);
		this.onTeamChange = this.onTeamChange.bind(this);
		this.onUserChange = this.onUserChange.bind(this);
		this.onPasswordSet = this.onPasswordSet.bind(this);
		this.goToTeamPage = this.goToPage.bind(this, 0);
		this.goToProfilePage = this.goToPage.bind(this, 1);

		this.logScreen();
	}

	public getPageState(key) {
		const { activePage } = this.state;

		if (key === activePage) {
			return PageState.IN;
		} else if (key < activePage) {
			return PageState.OUT;
		}

		return PageState.DEFAULT;
	}

	public goToPage(page: number) {
		actions.clearAlerts();
		this.setState((previousState) => {
			const newState = assign({}, previousState) as State;

			newState.activePage = page;

			return newState;
		}, () => { this.logScreen(); });
	}

	public logScreen() {
		const { activePage } = this.state;

		if (activePage === 0) {
			logger.logScreen('AccountCreateTeam');
		} else {
			logger.logScreen('AccountCreateUser');
		}
	}

	public onTeamChange(path, value) {
		this.setState((prevState) => {
			const newState = assign({}, prevState) as State;

			// return a new instance so the child component is sure to update
			newState.team = teamFactory(newState.team);

			newState.team.setAt(path, value);

			return newState;
		});
	}

	public onUserChange(path, value) {
		this.setState((prevState) => {
			const newState = assign({}, prevState) as State;

			// return a new instance so the child component is sure to update
			newState.user = userFactory(newState.user);

			newState.user.setAt(path, value);

			return newState;
		});
	}

	public onPasswordSet(value) {
		this.setState((prevState) => {
			const newState = assign({}, prevState) as State;

			newState.password = value;

			return newState;
		});
	}

	public _fillPresetPersonnelGroups(team: ITeam) {
		const { appState } = this.props;
		let index = 0;

		if (team.settings.personnelGroups.count) {
			return team;
		} else {
			team = teamFactory(team.toDocument());
		}

		for (const group of appState.viewState.settings.presetPersonnelGroups) {
			for (const phase of group.phases) {
				for (const playersPerSide of group.playersPerSide) {
					team.settings.personnelGroups.add({ label: _s(group.nameKey), phase, playersPerSide, sortIndex: index ++ });
				}
			}
		}

		return team;
	}

	public async createAccount() {
		if (!this.state.user || !this.state.team || !this.state.password) {
			return;
		}

		this.setState({ processing: true }, async () => {
			actions.clearAlerts();
			await actions.createAccount(this.state.user, this._fillPresetPersonnelGroups(this.state.team), this.state.password);

			const alerts = store.appState().viewState.alerts;
			if (alerts.length === 0) {
				viewManager.popModal();
			}
			// else if (every(alerts, (alert:store.IAlert) => alert.severity === store.AlertSeverity.info)) {
			// 	setTimeout(actions.popModal, 4000);
			// }

			this.setState({ processing: false });
		});
	}

	public async createTeam() {
		if (!this.state.team) {
			return;
		}

		this.setState({ processing: true }, async () => {
			actions.clearAlerts();
			await actions.addTeam(this._fillPresetPersonnelGroups(this.state.team));

			const alerts = store.appState().viewState.alerts;
			if (alerts.length === 0) {
				viewManager.popModal();
			}

			this.setState({ processing: false });
		});
	}

	public render() {
		const { appState, alerts } = this.props;
		const { team, user, password, processing } = this.state;
		const alertList = <AlertList alerts={ alerts } />;

		return <React.Fragment>
			{ processing ? <Spinner /> : null }
			<Page key="page0" pageState={ this.getPageState(0) } pageClasses={ ['page'] }>
				<TeamEdit
					appState={ appState }
					alertList={ alertList }
					addMode={ true }
					cancelAction={ viewManager.popModal }
					confirmAction={ (team && team.isValid()) ? !user ? this.createTeam : this.goToProfilePage : undefined }
					confirmActionLabel={ !user ? null : _s(StringKey.CONTINUE) }
					confirmActionClassName={ !user ? 'icon ok' : 'icon next' }
					team={ team }
					onTeamChange={ this.onTeamChange }
				/>
			</Page>
			{ user ? <Page key="page1" pageState={ this.getPageState(1) } pageClasses={ ['page'] }>
				<ProfileEdit
					alertList={ alertList }
					addMode={ true }
					cancelAction={ this.goToTeamPage }
					confirmAction={ user && user.isValid() && password ? this.createAccount : undefined }
					user={ user }
					onUserChange={ this.onUserChange }
					onPasswordSet={ this.onPasswordSet }
				/>
			</Page> : null
			}
		</React.Fragment>;
	}
}

import * as React from 'react';
import * as actions from '../actions';
import { current as getCurrentContext } from '../componentContext';
import { DiagramRenderFlags } from '../models/diagramModel';
import { default as playFactory, IPlay } from '../models/play';
import * as store from '../store';
import { Diagram } from './diagram';
import { PlayerToolbarPlay } from './playerToolbarPlay';

interface Props {
	alerts?: store.IAlert[];
	appState: store.IAppState;
}

export class Play extends React.Component<Props> {

	public savePlay(mutatedPlay: IPlay) {
		actions.savePlay(mutatedPlay);
	}

	public render() {
		const { alerts= [], appState } = this.props;
		const { currentPlay: storePlay, currentPlaybook } = getCurrentContext();

		if (!storePlay || !currentPlaybook) {
			return null;
		}

		return <Diagram
			key={ storePlay.id }
			alerts={ alerts }
			fieldKey={ currentPlaybook.settings.fieldKey }
			initialLocked={ appState.viewState.lastCreatedDiagramId !== storePlay.id }
			posture={ storePlay.posture }
			storeModel={ storePlay }
			saveModel={ this.savePlay }
			modelFactory={ playFactory }
			PlayerToolbar={ PlayerToolbarPlay }
			renderFlags={ DiagramRenderFlags.showField | DiagramRenderFlags.interactive | DiagramRenderFlags.interactiveRoutes | DiagramRenderFlags.showAnnotations | storePlay.renderFlags }
		/>;
	}
}

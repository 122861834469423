import { filter, keys, map } from 'lodash';
import { IPatchOperation, PatchOpType } from 'playmaker-team-common/dist/shared/interfaces';
import * as React from 'react';
import { IAnnotation } from '../models/annotation';
import { GamePhase } from '../models/diagramModel';
import { IPlayer, PlayerShading, PlayerZoneShape } from '../models/player';

interface Props {
	posture: GamePhase;
	diagramItem: IPlayer | IAnnotation;
	updateItem: (patch: IPatchOperation[]) => void;
}

const COLORS = [1, 2, 3, 13, 5, 6, 7, 8, 10, 11, 12, 4, 14, 15, 9, 16];

export class PositionTools extends React.Component<Props> {

	constructor(props: Props) {
		super(props);

		this.handleLabelChange = this.handleLabelChange.bind(this);
		this.handleColorChange = this.handleColorChange.bind(this);
		this.handleRouteColorChange = this.handleRouteColorChange.bind(this);
		this.handleZoneShapeChange = this.handleZoneShapeChange.bind(this);
		this.handleShadingChange = this.handleShadingChange.bind(this);
	}

	public doUpdate(pointer: string, value: any) {
		const { updateItem } = this.props;

		updateItem([{ path: pointer, value, op: PatchOpType.replace }]);
	}

	public handleLabelChange(e) {
		this.doUpdate('/label', e.target.value);
	}

	public handleColorChange(e) {
		this.doUpdate('/color', e.target.value);
	}

	public handleRouteColorChange(e) {
		this.doUpdate('/routeColor', e.target.value);
	}

	public handleZoneShapeChange(e) {
		this.doUpdate('/zoneShape', e.target.value);
	}

	public handleShadingChange(e) {
		this.doUpdate('/shading', e.target.value);
	}

	public render() {
		const { posture, diagramItem } = this.props;
		const isPlayer = diagramItem.getModelName() === 'Player';
		const positionColorOptions = map(COLORS, (color) => {
			const isChecked = diagramItem && diagramItem.color === color;

			return <div key={ color } className={ `color color${color}` }>
				<label className={ isChecked ? 'on' : '' }><span></span><input type="radio" value={ color } checked={ isChecked } onChange={ this.handleColorChange } /></label>
			</div>;
		});

		const positionLineColorOptions = map(COLORS, (color) => {
			const isChecked = diagramItem && (typeof diagramItem.routeColor === 'undefined' ? diagramItem.color === color : diagramItem.routeColor === color);

			return <div key={ color } className={ `color color${color}` }>
				<label className={ isChecked ? 'on' : '' }><span></span><input type="radio" value={ color } checked={ isChecked } onChange={ this.handleRouteColorChange } /></label>
			</div>;
		});

		let shadingOptions = null;
		let zoneOptions = null;

		if (isPlayer) {
			shadingOptions = map(filter(keys(PlayerShading), (k) => !Number.isNaN(Number(k))), (k) => {
				const shadeClass = PlayerShading[k];
				const isChecked = diagramItem && (diagramItem as IPlayer).shading === Number(k);

				return <div key={ k } className={ `color ${shadeClass}` }>
					<label className={ isChecked ? 'on' : '' }><span></span><input type="radio" value={ k } checked={ isChecked } onChange={ this.handleShadingChange } /></label>
				</div>;
			});
		}

		if (posture === GamePhase.Defense) {
			zoneOptions = map(filter(keys(PlayerZoneShape), (k) => !Number.isNaN(Number(k))), (k) => {
				const shapeClass = PlayerZoneShape[k].split('_').join(' ');
				const isChecked = diagramItem && diagramItem.zoneShape === Number(k);

				return <div key={ k } className={ `color ${shapeClass}` }>
					<label className={ isChecked ? 'on' : '' }><span></span><input type="radio" value={ k } checked={ isChecked } onChange={ this.handleZoneShapeChange } /></label>
				</div>;
			});

			/** add extra .color divs to hold the space open **/
			while (zoneOptions.length < 8) {
				zoneOptions.push(<div key={ `ptf-pzs-${zoneOptions.length}`} className="color"></div>);
			}
		}

		return <div className="controls">
			{ isPlayer ? <div className='labelControl'><input type="text" placeholder=""  value={ diagramItem.viewValue('label') } onChange={ this.handleLabelChange } /></div> : null }
			
			<div className='symbolControls'>
				<div className='controls'>
					<div className="radio colors position">{ positionColorOptions }</div>
					<div className="radio colors line">{ positionLineColorOptions }</div>
				</div>

				<div className='controls'>
					<div className="radio colors shading">
						{ shadingOptions }
					</div>

					<div className="radio colors zone">
						{ zoneOptions }
					</div>
				</div>
			</div>

		</div>;
	}
}

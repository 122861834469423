import { IPatchOperation, PatchOpType } from 'playmaker-team-common/dist/shared/interfaces';
import * as React from 'react';
import * as actions from '../actions';
import { current as getCurrentContext } from '../componentContext';
import { AnnotationType, default as annotationFactory, IAnnotation } from '../models/annotation';
import { GamePhase, IDiagram } from '../models/diagramModel';
import { default as playerFactory, IPlayer, PlayerSymbol, PlayerRoles } from '../models/player';
import { EndCapType, LineStyle } from '../models/routeSection';
import { IPoint, vectorUtil } from '../models/vector';
import { _s, StringKey } from '../strings';
import * as touchHelper from '../touchHelper';
import { Page, PageState } from './page';
import { PositionTools } from './positionTools';
import { RouteTools } from './routeTools';
import { ZoneSliderControl } from './zoneSliderControl';
import { TemplateTools } from './templateTools';
import { getRouteTree } from './routeTreeProvider';
import { IPlay } from '../models/play';

enum AdvancedPage {
	symbol = 'symbol',
	segments = 'segments'
}
interface Props {
	classNames?: string[];
	diagram: IDiagram;
	modalId: string;
	offset: IPoint;
	posture: GamePhase;
	diagramItem: IPlayer | IAnnotation;
	updateItem: (patch: IPatchOperation[]) => void;
	deleteItem: (annotation: IAnnotation) => void;
	onClose: (e?) => void;
	onOpen?: () => void;
}

interface State {
	showAdvanced: boolean;
	showTemplateTools: boolean;
	activeAdvancedPage: AdvancedPage;
}

export class PlayerToolbarPlay extends React.Component<Props, State> {
	private _trackedTouch: any;
	private _view: any;

	constructor(props: Props) {
		super(props);

		this._view = React.createRef();

		this.state = {
			showAdvanced: false,
			showTemplateTools: false,
			activeAdvancedPage: AdvancedPage.symbol
		};

		this.applyRouteTemplate = this.applyRouteTemplate.bind(this);
		this.setPageState = this.setPageState.bind(this);

		this.handleDragEnd = this.handleDragEnd.bind(this);
		this.handleDragMove = this.handleDragMove.bind(this);
		this.handleDragStart = this.handleDragStart.bind(this);

		this.handleAdvancedClick = this.handleAdvancedClick.bind(this);
		this.handleCapClick = this.handleCapClick.bind(this);
		this.handleCurvedClick = this.handleCurvedClick.bind(this);
		this.handleMotionClick = this.handleMotionClick.bind(this);
		this.handleRouteRemoveClick = this.handleRouteRemoveClick.bind(this);
		this.handleTemplateToolsKeyUp = this.handleTemplateToolsKeyUp.bind(this);
		this.handleTemplateToolsClick = this.handleTemplateToolsClick.bind(this);
		this.handleStarClick = this.handleStarClick.bind(this);
		this.handleStyleClick = this.handleStyleClick.bind(this);
		this.handleZoneScaleChange = this.handleZoneScaleChange.bind(this);
	}

	public componentDidMount() {
		const { onOpen } = this.props;

		if (onOpen) {
			onOpen();
		}

		document.addEventListener('keyup', this.handleTemplateToolsKeyUp);

	}

	public componentWillUnmount(): void {
		document.removeEventListener('keyup', this.handleTemplateToolsKeyUp);
	}

	public componentDidUpdate(prevProps:Props) {
		const {posture} = this.props;

		if(posture !== prevProps.posture && this.state.showTemplateTools) {
			this.handleTemplateToolsClick();
		}
	}

	public itemFactory(doc) {
		const isPlayer = this.props.diagramItem.getModelName() === 'Player';

		return isPlayer ? playerFactory(doc) : annotationFactory(doc);
	}

	public handleDragStart(e: React.MouseEvent | React.TouchEvent) {
		const touch: touchHelper.ITouch = touchHelper.touchFromEvent(e);

		if (touch) {
			document.addEventListener('mousemove', this.handleDragMove);
			document.addEventListener('touchmove', this.handleDragMove);
			document.addEventListener('mouseup', this.handleDragEnd);
			document.addEventListener('touchend', this.handleDragEnd);

			this._trackedTouch = touch;

			actions.setRouteInteractionDisabled(true);
		}
	}

	public handleDragMove(e: MouseEvent | TouchEvent) {
		const { offset } = this.props;
		const boundingRect = this._view && this._view.current && this._view.current.getBoundingClientRect();

		const touch: touchHelper.ITouch = touchHelper.touchFromEvent(e, this._trackedTouch && this._trackedTouch.sourceType, this._trackedTouch && this._trackedTouch.identifier);

		if (touch && boundingRect) {
			const delta = touchHelper.getTouchVector(this._trackedTouch, touch);

			// delta.x = 0;

			const updatedOffset = vectorUtil.add(offset, delta);

			this._trackedTouch = touch;

			this.updateOffset(updatedOffset);
		}
	}

	public handleDragEnd(e: MouseEvent | TouchEvent) {

		// const touch: touchHelper.ITouch = touchHelper.touchFromEvent(e, this._trackedTouch && this._trackedTouch.sourceType, this._trackedTouch && this._trackedTouch.identifier);

		document.removeEventListener('mousemove', this.handleDragMove);
		document.removeEventListener('touchmove', this.handleDragMove);
		document.removeEventListener('mouseup', this.handleDragEnd);
		document.removeEventListener('touchend', this.handleDragEnd);

		this.handleDragMove(e);

		this._trackedTouch = null;

		actions.setRouteInteractionDisabled(false);
	}

	public updateOffset(offset: IPoint) {
		// eslint-disable-next-line prefer-const
		let { classNames = [], modalId } = this.props;
		const { showAdvanced, showTemplateTools } = this.state;

		if ((showAdvanced || showTemplateTools) && classNames.indexOf('advanced') === -1) {
			classNames = [].concat(classNames);
			classNames.push('advanced');
		} else if (!(showAdvanced || showTemplateTools) && classNames.indexOf('advanced') !== -1) {
			classNames = [].concat(classNames);
			classNames.splice(classNames.indexOf('advanced'), 1);
		}

		actions.updateModalProps(modalId, { offset, classNames });
		actions.setDiagramConfig({ itemModalOffset: offset });
	}

	public handleAdvancedClick() {
		const { offset } = this.props;

		this.setState({ showAdvanced: !this.state.showAdvanced, showTemplateTools: false }, () => {

			this.updateOffset(offset);
		});
	}

	public handleTemplateToolsClick() {
		const { offset } = this.props;

		this.setState({ showTemplateTools: !this.state.showTemplateTools, showAdvanced: false }, () => {
			this.updateOffset(offset);
		});
	}

	public handleCapClick() {
		const { diagramItem, updateItem } = this.props;
		const { showAdvanced, showTemplateTools } = this.state;

		if (showAdvanced || showTemplateTools) {
			return;
		}

		const mutatedItem = this.itemFactory(diagramItem);
		mutatedItem.endCapType = mutatedItem.endCapType === EndCapType.dot ? EndCapType.arrow : mutatedItem.endCapType + 1;

		if (mutatedItem.route.sections.count) {
			const lastSection = mutatedItem.route.last();

			lastSection.endCapType = undefined;
		}

		updateItem(mutatedItem.getPatch(diagramItem));
	}

	public handleMotionClick() {
		const { diagramItem, updateItem } = this.props;
		const { showAdvanced, showTemplateTools } = this.state;

		if (showAdvanced || showTemplateTools) {
			return;
		}

		const mutatedItem = this.itemFactory(diagramItem);
		mutatedItem.motion = !mutatedItem.motion;

		if (mutatedItem.route.sections.count) {
			const firstSection = mutatedItem.route.first();

			firstSection.lineStyle = undefined;
		}

		updateItem(mutatedItem.getPatch(diagramItem));
	}

	public handleStyleClick() {
		const { diagramItem, updateItem } = this.props;
		const { showAdvanced, showTemplateTools } = this.state;

		if (showAdvanced || showTemplateTools) {
			return;
		}

		const mutatedItem = this.itemFactory(diagramItem);
		mutatedItem.lineStyle = mutatedItem.lineStyle === LineStyle.dash ? LineStyle.fill : LineStyle.dash;

		if (mutatedItem.route.sections.count) {
			const lastSection = mutatedItem.route.last();

			lastSection.lineStyle = undefined;
		}

		updateItem(mutatedItem.getPatch(diagramItem));
	}

	public handleCurvedClick() {
		const { diagramItem, updateItem } = this.props;
		const mutatedItem = this.itemFactory(diagramItem);
		mutatedItem.curved = !mutatedItem.curved;

		updateItem(mutatedItem.getPatch(diagramItem));
	}

	public handleRouteRemoveClick() {
		const { deleteItem, diagramItem, updateItem } = this.props;
		const isPlayerLike = ['Player', 'RouteTreePlayer'].indexOf(diagramItem.getModelName()) !== -1;
		const mutatedItem = this.itemFactory(diagramItem);
		const removed = mutatedItem.route.popSection();

		if (removed) {
			updateItem(mutatedItem.getPatch(diagramItem));
		} else if (!isPlayerLike) {
			deleteItem(diagramItem as IAnnotation);
		}
	}

	public handleStarClick() {
		const { posture, diagramItem, updateItem } = this.props;

		updateItem([{ path: '/symbol', value: (diagramItem as IPlayer).symbol === PlayerSymbol.star ? (diagramItem as IPlayer).getDefaultSymbol(posture) : PlayerSymbol.star, op: PatchOpType.replace }]);
	}

	public handleZoneScaleChange(value: number) {
		const { diagramItem, updateItem } = this.props;
		const mutatedItem = this.itemFactory(diagramItem);
		mutatedItem.zoneScale = value;

		updateItem(mutatedItem.getPatch(diagramItem));
	}

	public handleTemplateToolsKeyUp(e: KeyboardEvent) {	
		const { diagram, posture } = this.props;

		if(diagram.getModelName() !== 'Play' || posture !== GamePhase.Offense) {
			return;
		}

		if(e.altKey || e.shiftKey || e.repeat || ['input', 'textarea'].indexOf(document.activeElement.nodeName.toLowerCase()) !== -1) {
			return;
		}

		if(['0','1','2','3','4','5','6','7','8','9'].indexOf(e.key) !== -1) {
			let key = Number(e.key);

			if(e.ctrlKey) {
				key += 10;
			}

			this.applyRouteTemplate(key);
		}
	}

	public applyRouteTemplate(index: number) {
		const { diagram, diagramItem, updateItem } = this.props;
		const { currentPlaybook } = getCurrentContext();
		const routeTree = getRouteTree(currentPlaybook);
		const template = routeTree?.mates.values.find(m => m.sortIndex === index);

		if(template) {
			const centerLoc = (diagram.phase === GamePhase.Defense? (diagram as IPlay).opponents || diagram.mates: diagram.mates).values.find(m => (m.role & PlayerRoles.center) === PlayerRoles.center)?.loc;
			const mutatedItem = this.itemFactory(diagramItem);

			mutatedItem.applyRouteTemplate(template);

			if(centerLoc && centerLoc.x < mutatedItem.loc.x) {
				mutatedItem.flipHorizontally();
			}

			if(diagram.phase === GamePhase.Defense) {
				mutatedItem.flipVertically();
			}

			updateItem(mutatedItem.getPatch(diagramItem));
		}
	}

	private getPageState(key: AdvancedPage) {
		const { activeAdvancedPage } = this.state;
		
		if (key === activeAdvancedPage) {
			return PageState.IN;
		}
	
		return PageState.OUT;
	}

	private setPageState(key: AdvancedPage) {
		this.setState({activeAdvancedPage: key});
	}

	public render() {
		const { onClose, posture, diagram, diagramItem, updateItem } = this.props;
		const { activeAdvancedPage, showAdvanced, showTemplateTools } = this.state;
		const isPlayer = diagramItem.getModelName() === 'Player';
		const isAnnotation = diagramItem.getModelName() === 'Annotation';
		const isRouteTree = diagram.getModelName() === 'RouteTree';
		const isPlayerLike = ['Player', 'RouteTreePlayer'].indexOf(diagramItem.getModelName()) !== -1;
		const isAnimatedBall = !isPlayerLike && (diagramItem as IAnnotation).subType === AnnotationType.BallAnimatable;
		const removeClassName = isPlayerLike ? 'icon toolRemove' : diagramItem.route.sections.values.length ? 'icon toolRemove' : 'icon toolDelete';
		const removeButtonClassName = isPlayerLike && !diagramItem.route.sections.values.length ? 'button disabled' : 'button';
		const classNames = ['view'];

		let endCapClass;

		switch (diagramItem.endCapType) {
		case EndCapType.arrow:
			endCapClass = 'toolCapArrow';
			break;

		case EndCapType.bar:
			endCapClass = 'toolCapT';
			break;

		case EndCapType.dot:
			endCapClass = 'toolCapDot';
			break;

		default:
			endCapClass = 'toolCapNone';
			break;
		}

		if(isRouteTree) {
			classNames.push('routeTreeTools');
		}

		if(isAnnotation) {
			classNames.push('annotationTools');
		}

		return <div className={ classNames.join(' ') } ref={ this._view }>
			<header>
				<div className="actions">
					<a className="button" onMouseDown={ this.handleDragStart } onTouchStart={ this.handleDragStart } id="tooltip-target-playDragToolbar"><span className="icon drag"></span></a>
				</div>
				<div className="actions tools">
					{ isAnimatedBall ? null :  <a className={ showAdvanced ? `button color${diagramItem.color} on` : `button color${diagramItem.color}` } id="tooltip-target-playAdvancedOptions" onClick={ this.handleAdvancedClick }><span className="icon toolAdvanced"></span></a> }
					{ diagram.getModelName() !== 'Play' || posture !== GamePhase.Offense? null:  <a className={ showTemplateTools ? 'button templateButton on' : 'button templateButton' } onClick={ this.handleTemplateToolsClick }><span className="icon toolRouteTemplates"></span></a> }
					{ isPlayer ? <a className={ (diagramItem as IPlayer).symbol === PlayerSymbol.star ? 'button on' : 'button' } onClick={ this.handleStarClick }><span className="icon toolStar"></span></a> : null }
					{ posture === GamePhase.Offense || isAnimatedBall ? null : <ZoneSliderControl onChange={ this.handleZoneScaleChange } value={ (diagramItem as IPlayer).zoneScale || 0 } /> }
					{ isAnimatedBall ? null : <a className={ diagramItem.motion ? 'button segmentButton on' : 'button segmentButton' } onClick={ this.handleMotionClick }><span className="icon toolRouteMotion"></span></a> }
					{ isAnimatedBall ? null : <a className={ diagramItem.lineStyle === LineStyle.dash ? 'button segmentButton on' : 'button segmentButton' }  onClick={ this.handleStyleClick }><span className="icon toolRouteDotted"></span></a> }
					{ isAnimatedBall ? null : <a className="button segmentButton" onClick={ this.handleCapClick }><span className={ `icon ${endCapClass}` }></span></a> }
					<a className={ diagramItem.curved ? 'button on' : 'button' } onClick={ this.handleCurvedClick }><span className="icon toolRouteRounded"></span></a>
					<a className={ removeButtonClassName } onClick={ this.handleRouteRemoveClick }><span className={ removeClassName }></span></a>
				</div>
				<div className="actions">
					<a className="button" onClick={ onClose }><span className="icon ok"></span></a>
				</div>
			</header>

			<div className={ showTemplateTools ? 'content templateTools in' : 'content templateTools' }>
				<TemplateTools applyRouteTemplate={ this.applyRouteTemplate } />
			</div>

			<div className={ showAdvanced ? 'content positionTools in' : 'content positionTools' }>
				<div className="view tabbed">
					<header>
						<div className="radio tabs">
							<label className={ activeAdvancedPage === AdvancedPage.symbol ? 'on' : '' }><span>{ _s(StringKey.SYMBOL) }</span><input type="radio" name="activePage" checked={ activeAdvancedPage === AdvancedPage.symbol } onChange={ () => this.setPageState(AdvancedPage.symbol) } /></label>
							<label className={ activeAdvancedPage === AdvancedPage.segments ? 'on' : '' }><span>{ _s(StringKey.SEGMENTS) }</span><input type="radio" name="activePage" checked={ activeAdvancedPage === AdvancedPage.segments } onChange={ () => this.setPageState(AdvancedPage.segments) } /></label>
						</div>
					</header>
					<div className="content swappable">
						<Page key="page0" pageState={ this.getPageState(AdvancedPage.symbol)}>
							<div className='content'>
								<div className='inner'>
									<PositionTools posture={ posture } diagramItem={ diagramItem } updateItem={ updateItem } />
								</div>
							</div>
						</Page>

						<Page key="page1" pageState={ this.getPageState(AdvancedPage.segments)}>
							<div className='content'>
								<div className='inner'>
									<RouteTools diagramItem={ diagramItem } updateItem={ updateItem } />
								</div>
							</div>
						</Page>
					</div>
				</div>
			</div>
		</div>;
	}
}

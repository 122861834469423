import { assign, filter, find, map, sortBy } from 'lodash';
import * as React from 'react';
import { current as getCurrentContext } from '../componentContext';
import { AnnotationType, IAnnotation } from '../models/annotation';
import { IDiagramItem } from '../models/diagramItemModel';
import { DiagramRenderFlags, GamePhase, IDiagram, SpecialTeamsUnit } from '../models/diagramModel';
import { default as playerFactory, IPlayer, PlayerRoles, PlayerSymbol, PlayerZoneShape } from '../models/player';
import { IRoutedDiagramItem } from '../models/routedDiagramItemModel';
import { EndCapType, IRouteSection, LineStyle } from '../models/routeSection';
import { ISortable } from '../models/sortableModel';
import { IPoint, ISize, IVector, vectorUtil } from '../models/vector';
import { IDragData } from '../store';
import { _s, StringKey } from '../strings';
import { pushTooltip } from '../tooltipManager';
import { ITouch, supportsPointer, supportsTouch, touchFromEvent} from '../touchHelper';
import { DropTarget, IDropTargetParams } from './dnd';
import { Arena25x66 } from './fields/arena25x66';
import { College53x120 } from './fields/college53x120';
import { Flag30x50 } from './fields/flag30x50';
import { Flag30x70 } from './fields/flag30x70';
import { Flag40x100 } from './fields/flag40x100';
import { Gridiron40x100 } from './fields/gridiron40x100';
import { Gridiron40x120 } from './fields/gridiron40x120';
import { GridironArizonaFlag40x120 } from './fields/gridironArizonaFlag40x120';
import { Gridiron53x120 } from './fields/gridiron53x120';
import { Gridiron65x150 } from './fields/gridiron65x150';
import { Lines30x70 } from './fields/lines30x70';
import { Lines40x100 } from './fields/lines40x100';
import { Lines53x120 } from './fields/lines53x120';
import { Lines65x150 } from './fields/lines65x150';
import { None30x70 } from './fields/none30x70';
import { None40x100 } from './fields/none40x100';
import { None53x120 } from './fields/none53x120';
import { None65x150 } from './fields/none65x150';
import { RouteTreeField } from './fields/routeTree';
import { RouteTreeComposite } from './fields/routeTreeComposite';
import { PlayerLabel } from './playerLabel';
import { PlayerPosition } from './playerPosition';
import { SnapGrid } from './fields/snapGrid';

interface Props {
	className?: string;
	diagram: IDiagram;
	decorations?: IDiagramItem[];
	activeItem?: IDiagramItem;
	isMoving?: boolean;
	activeRouteSection?: IRouteSection;
	ballLocation: IPoint;
	getPersonnelInfo?: (player: IPlayer & ISortable) => any;
	lineOfScrimage: number;
	fieldOptions?: any;
	onItemMove?: (vector: IVector) => void;
	onItemTouchStart?: (item: IDiagramItem) => void;
	onItemTouchEnd?: () => void;
	onRouteTouchStart?: (touchPoint: IPoint) => void;
	onRouteMove?: (touchPoint: IPoint) => void;
	onRouteTouchEnd?: (touchPoint: IPoint) => void;
	onPersonnelDrop?: (data) => void;
	renderFlags?: DiagramRenderFlags;
	routeDurations?: { presnap: number, full: number, diagramItems: { [key: string]: { presnap: number, full: number } } };
	setRouteLength?: (player: IPlayer, length: number, type: string) => void;
}

function createCrosshairs(point: IPoint, yOffset: number) {
	return !point ? null : <svg className="crosshair" x={ `${point.x * 100}%` } y={ `${(point.y + yOffset) * 100}%` }>
		<line className="horizontal" x1="-100%" x2="200%" y1="0" y2="0"/>
		<line className="vertical" x1="0" x2="0%" y1="-100%" y2="200%"/>
	</svg>;
}

function createFieldElement(fieldKey: string) {
	const { currentTeam } = getCurrentContext();

	switch (fieldKey) {
	case 'gridiron65x150':
		return <Gridiron65x150 teamLabel={ currentTeam && currentTeam.abbreviation } />;
	case 'lines65x150':
		return <Lines65x150 />;
	case 'none65x150':
		return <None65x150 />;
	case 'gridiron53x120':
		return <Gridiron53x120 teamLabel={ currentTeam && currentTeam.abbreviation } />;
	case 'college53x120':
		return <College53x120 teamLabel={ currentTeam && currentTeam.abbreviation } />;
	case 'lines53x120':
		return <Lines53x120 />;
	case 'none53x120':
		return <None53x120 />;
	case 'gridiron40x120':
		return <Gridiron40x120 teamLabel={ currentTeam && currentTeam.abbreviation } />;
	case 'gridironArizonaFlag40x120':
		return <GridironArizonaFlag40x120 teamLabel={ currentTeam && currentTeam.abbreviation } />;
	case 'gridiron40x100':
		return <Gridiron40x100 teamLabel={ currentTeam && currentTeam.abbreviation } />;
	case 'lines40x100':
		return <Lines40x100 />;
	case 'flag40x100':
		return <Flag40x100 teamLabel={ currentTeam && currentTeam.abbreviation } />;
	case 'none40x100':
		return <None40x100 />;
	case 'flag30x70':
		return <Flag30x70 teamLabel={ currentTeam && currentTeam.abbreviation } />;
	case 'lines30x70':
		return <Lines30x70 />;
	case 'none30x70':
		return <None30x70 />;
	case 'flag30x50':
		return <Flag30x50 teamLabel={ currentTeam && currentTeam.abbreviation } />;
	case 'arena25x66':
		return <Arena25x66 teamLabel={ currentTeam && currentTeam.abbreviation } />;
	case 'routeTree':
		return <RouteTreeField />;
	case 'routeTreeComposite':
		return <RouteTreeComposite />;
	default:
		return null;
	}
}

function createAnnotationPositionElement(annotation: IAnnotation, active: boolean, routeDuration: { full: number, presnap: number } = undefined) {
	let symbol;
	let icon = null;
	let label = annotation.label;

	switch (annotation.subType) {
	case AnnotationType.Option:
	case AnnotationType.Text:
		symbol = <circle className="symbol" cx="0" cy="0" r="14" />;
		break;

	case AnnotationType.Ball:
		label = '';
		symbol = [<path key="border" className="border" d="M12.24-12.25c-1.82-1.82-12.48-3-20,4.52s-6.34,18.15-4.52,20,12.49,3,20-4.51S14.07-10.42,12.24-12.25Z"></path>,
			<path key="symbol" className="symbol" d="M12.24-12.25c-1.82-1.82-12.48-3-20,4.52s-6.34,18.15-4.52,20,12.49,3,20-4.51S14.07-10.42,12.24-12.25ZM-4,5.29-5.29,4,4-5.29,5.29-4Z"></path>];

		break;

	case AnnotationType.BallAnimatable:
		label = '';
		symbol = [<path className="border" d="M12.24-12.25c-1.82-1.82-12.48-3-20,4.52s-6.34,18.15-4.52,20,12.49,3,20-4.51S14.07-10.42,12.24-12.25Z"></path>,
			<path className="symbol" d="M12.24-12.25c-1.82-1.82-12.48-3-20,4.52s-6.34,18.15-4.52,20,12.49,3,20-4.51S14.07-10.42,12.24-12.25ZM-4,5.29-5.29,4,4-5.29,5.29-4Z"></path>];
		icon = <g className="icon">
			<circle className="base" cx="5.94" cy="5.94" r="8"/>
			<circle className="border" cx="5.94" cy="5.94" r="5.5"/>
			<path className="arrow" d="M7.78,5.76,4.4,7.88A.29.29,0,0,1,4,7.77a.26.26,0,0,1,0-.15V3.38a.3.3,0,0,1,.29-.3.33.33,0,0,1,.15,0L7.78,5.24a.32.32,0,0,1,.1.42A.47.47,0,0,1,7.78,5.76Z" transform="translate(0.44 0.44)"/>
		</g>;

		break;

	default:
		break;
	}

	return  <React.Fragment>
		<g className="position">
			{ symbol }
			<text x="0" y="0">{ label }</text>
			{ routeDuration && routeDuration.full ? <animateMotion id={ `animMotion-${annotation.id}` } className="animRouteMotion" begin="indefinite" fill="freeze" dur={ `${routeDuration.full}s` }>
				<mpath xlinkHref={ `#animPath-${annotation.id}-${routeDuration.full}` } />
			</animateMotion> : null }
		</g>
		{ icon }
	</React.Fragment>;
}

function addCurvePaths(curveSections: IConcreteRouteSection[], paths: any[], endIsDashed: boolean, animationCommands?: { presnap: string[], full: string[]}) {
	if (curveSections.length > 2) {
		const sections: IConcreteRouteSection[] = [].concat(curveSections);
		let startPoint = sections.shift().concretePoint;
		let endSection: IConcreteRouteSection = sections[0];
		let endPoint;
		const midPointStart = vectorUtil.add(startPoint, vectorUtil.scale(vectorUtil.subtract(endSection.concretePoint, startPoint), 0.5));
		let d = `L ${midPointStart.x} ${midPointStart.y}`;

		paths.push(<path key={ `p${midPointStart.x}${midPointStart.y}` } d={ `M ${startPoint.x} ${startPoint.y} ${d}` } fill="transparent" className={ endSection.lineStyle === LineStyle.dash ? 'dotted' : '' } strokeLinecap="round" />);
		if (animationCommands) {
			animationCommands.full.push(d);
		}
		startPoint = midPointStart;

		while ((endSection = sections.shift())) {
			endPoint = endSection.concretePoint;

			let useDash = sections[0].lineStyle === LineStyle.dash;
			const nextPoint = sections[0].concretePoint;
			const midPointEnd = vectorUtil.add(endPoint, vectorUtil.scale(vectorUtil.subtract(nextPoint, endPoint), 0.5));
			d = `M ${startPoint.x} ${startPoint.y} Q ${endPoint.x} ${endPoint.y} ${midPointEnd.x} ${midPointEnd.y}`;

			startPoint = midPointEnd;

			if (sections.length === 1) {
				endSection = sections.shift();
				endPoint = endSection.concretePoint;
				useDash = useDash || typeof endSection.lineStyle === 'undefined' && endIsDashed;

				d += `M ${startPoint.x} ${startPoint.y} L ${endPoint.x} ${endPoint.y}`;
			}

			paths.push(<path key={ `p${paths.length}` } d={ d } fill="transparent" className={ useDash ? 'dotted' : '' } strokeLinecap="round" />);

			if (animationCommands) {
				animationCommands.full.push(d);
			}
		}
	}
}

function addMotionPath(startPoint: IPoint, endPoint: IPoint, paths: any[], segmentLength = 10) {
	if (vectorUtil.areEqual(startPoint, endPoint)) {
		return;
	}

	const commands = [`M ${startPoint.x} ${startPoint.y}`];
	const lineRadians = vectorUtil.angle(vectorUtil.normalize(vectorUtil.subtract(endPoint, startPoint)));
	const zigStart = vectorUtil.add(startPoint, vectorUtil.rotate({ x: segmentLength, y: 0 }, lineRadians));
	const zigEnd = vectorUtil.add(endPoint, vectorUtil.rotate({ x: -segmentLength, y: 0 }, lineRadians));
	let zigRadians = 45 * Math.PI / 180;
	let isFirstZig = true;
	let current: IPoint = zigStart;

	commands.push(`L ${zigStart.x} ${zigStart.y}`);

	while (commands.length < 200 && vectorUtil.distance(current, zigEnd) > segmentLength) {
		const currentSegmentLength = isFirstZig ? segmentLength / 2 : segmentLength;
		current = vectorUtil.add(current, vectorUtil.rotate({ x: currentSegmentLength, y: 0 }, lineRadians + zigRadians));

		commands.push(`L ${current.x} ${current.y}`);

		zigRadians = -zigRadians;
		isFirstZig = false;
	}

	commands.push(`L ${zigEnd.x} ${zigEnd.y}`);
	commands.push(`L ${endPoint.x} ${endPoint.y}`);

	paths.push(<path key={ `p${endPoint.x}${endPoint.y}` } d={ commands.join(' ') } fill="transparent" strokeLinecap="round" />);
}

function createEndCap(points: IPoint[], type: EndCapType) {
	const startPoint = points[0];
	const endPoint = points[1];
	const radians = vectorUtil.angle(vectorUtil.normalize(vectorUtil.subtract(endPoint, startPoint)));
	const degrees = (radians * 180) / Math.PI;
	const transform = `translate(${endPoint.x} ${endPoint.y}) rotate(${degrees})`;
	const key = `cap${endPoint.x}${endPoint.y}`;

	switch (type) {
	case EndCapType.arrow:
		return <g key={ key } className="endcaps"><path d="M-8 -8 0 0 -8 8" transform={ transform } strokeLinecap="round"/></g>;

	case EndCapType.bar:
		return <g key={ key } className="endcaps"><path d="M0 -8 0 8" transform={ transform } strokeLinecap="round"/></g>;

	case EndCapType.dot:
		return <g key={ key } className="endcaps"><circle className="symbol" cx="0" cy="0" r="3" transform={ transform }/></g>;

	default:
		return null;
	}
}

function createZone(zonable: IPlayer | IAnnotation, lastRoutePoint: IPoint) {
	let result = null;
	const fullSize = 1024;
	const scale = zonable.zoneScale;
	const size = fullSize * scale;
	const offset = size / 2;

	if (zonable && zonable.zoneScale && zonable.zoneShape !== undefined) {
		switch (zonable.zoneShape) {
		case PlayerZoneShape.rectangle_horizontal:
			result = <rect className="zone" x={ lastRoutePoint.x - (2 * offset) } y={ lastRoutePoint.y - offset } width={ size * 2 } height={ size } />;
			break;
		case PlayerZoneShape.rectangle_square:

			result = <rect className="zone" x={ lastRoutePoint.x - offset } y={ lastRoutePoint.y - offset} width={ size } height={ size } />;
			break;
		case PlayerZoneShape.rectangle_vertical:
			result = <rect className="zone" x={ lastRoutePoint.x - offset } y={ lastRoutePoint.y - (2 * offset)} width={ size } height={ size * 2 } />;
			break;

		case PlayerZoneShape.ellipse_horizontal:
			result = <ellipse className="zone" cx={ lastRoutePoint.x } cy={ lastRoutePoint.y } rx={ size * 2 } ry={ size } />;
			break;
		case PlayerZoneShape.ellipse_vertical:
			result = <ellipse className="zone" cx={ lastRoutePoint.x } cy={ lastRoutePoint.y } rx={ size } ry={ size * 2 } />;
			break;
		default:
			result = <ellipse className="zone" cx={ lastRoutePoint.x } cy={ lastRoutePoint.y } rx={ size } ry={ size } />;
			break;
		}

	}

	return result;
}

interface IConcreteRouteSection extends IRouteSection {
	concretePoint?: IPoint;
}

function extractCurve(sections: IRouteSection[], fieldSize: ISize, viewboxScale: number, startSection: IConcreteRouteSection): IConcreteRouteSection[] {
	const results: IConcreteRouteSection[] = [];

	while (sections.length && sections[0].lineStyle !== LineStyle.motion) {
		const section: IConcreteRouteSection = sections.shift();

		section.concretePoint = vectorUtil.scale(vectorUtil.toConcretePoint(section.loc, fieldSize), viewboxScale);

		results.push(section);
	}

	return results.length ? [startSection].concat(results) : null;
}

function processRouteSections({ routedItem, fieldSize, paths, endcaps, animationCommands }: { routedItem: IRoutedDiagramItem, fieldSize: ISize, paths: any[], endcaps: any[], animationCommands?: { presnap: string[], full: string[] } }): IConcreteRouteSection[] {
	const sections: IRouteSection[] = routedItem.route && sortBy(routedItem.route.sections.values, 'sortIndex');
	const isStaticBallAnnotation = (routedItem as IAnnotation).subType === AnnotationType.Ball;
	let concreteSections;

	if (fieldSize && fieldSize.width && routedItem.loc && sections && sections.length) {
		const viewboxScale = fieldSize && 1024 / fieldSize.width;
		const processSections: IRouteSection[] = [].concat(sections);
		let curveResult: IConcreteRouteSection[];
		let previousSection: IConcreteRouteSection;
		let endCapType;
		let presnapIndex = 0;

		concreteSections = [{ id: 'start', loc: { x: 0, y: 0 }, concretePoint: { x: 0, y: 0 } }];

		while (processSections.length) {
			curveResult = undefined;
			previousSection = concreteSections[concreteSections.length - 1];

			if (routedItem.curved && processSections.length > 1 && processSections[1].lineStyle !== LineStyle.motion && (!routedItem.motion || concreteSections.length > 1)) {
				curveResult = extractCurve(processSections, fieldSize, viewboxScale, previousSection);
			}

			if (curveResult) {
				endCapType = curveResult[curveResult.length - 1].endCapType;
				if (typeof endCapType !== 'undefined') {
					const endCapStartPoint = curveResult[curveResult.length - 2].concretePoint;
					const endCapEndPoint = curveResult[curveResult.length - 1].concretePoint;

					endcaps.push(createEndCap([endCapStartPoint, endCapEndPoint], endCapType));
				}

				addCurvePaths(curveResult, paths, !processSections.length && routedItem.lineStyle === LineStyle.dash, animationCommands);

				concreteSections = concreteSections.concat(curveResult);
			} else {
				const section: IConcreteRouteSection = processSections.shift();
				// (DR) forcing isDashed for static ball annotations after dashed rendering mysteriously stopped working
				const isDashed = section.lineStyle === LineStyle.dash || isStaticBallAnnotation || (!processSections.length && routedItem.lineStyle === LineStyle.dash) ? true : false;
				const isMotion = section.lineStyle === LineStyle.motion || (concreteSections.length === 1 && routedItem.motion);
				const isPreSnap = isMotion && paths.length === presnapIndex;

				section.concretePoint = vectorUtil.scale(vectorUtil.toConcretePoint(section.loc, fieldSize), viewboxScale);

				if (typeof(section.endCapType) !== 'undefined') {
					endcaps.push(createEndCap([previousSection.concretePoint, section.concretePoint], section.endCapType));
				}

				if (isMotion) {
					addMotionPath(previousSection.concretePoint, section.concretePoint, paths);
				} else {
					paths.push(<path key={ `p${paths.length}` } d={ `M ${previousSection.concretePoint.x} ${previousSection.concretePoint.y} L ${section.concretePoint.x} ${section.concretePoint.y}` } fill="transparent" className={ isDashed ? 'dotted' : '' } strokeLinecap="round" />);
				}

				concreteSections.push(section);

				if (animationCommands) {

					animationCommands.full.push(`L ${section.concretePoint.x} ${section.concretePoint.y}`);
					if (isPreSnap) {
						animationCommands.presnap.push(`L ${section.concretePoint.x} ${section.concretePoint.y}`);
						presnapIndex = paths.length;
					}
				}
			}
		}
	}

	return concreteSections as IConcreteRouteSection[];
}

function createPlayerElement({ player, yOffset, isEditing, fieldSize, onTouchStart, personnelInfo, onPersonnelDrop, active = false, isOpponent = false, showAnimations = false, routeDurations = { presnap: 0, full: 0, diagramItems: {}}, setRouteLength}:
	{ player: IPlayer, yOffset: number, isEditing: boolean, fieldSize: ISize, personnelInfo?: any, onTouchStart: any, onPersonnelDrop?: any, active?: boolean, isOpponent?: boolean, showAnimations?: boolean, routeDurations: { presnap: number, full: number, diagramItems: { [key: string]: { presnap: number, full: number } } }, setRouteLength: (player: IRoutedDiagramItem, length: number, type: string) => void }) {
	const { viewState: { globalDiagramFlags } } = getCurrentContext();
	const renderDiagnostics = (globalDiagramFlags && DiagramRenderFlags.renderDiagnotics) === DiagramRenderFlags.renderDiagnotics;
	const color = `color${player.color}`;
	const routeColor = typeof player.routeColor === 'undefined' ? color : `color${player.routeColor}`;
	const touchHandler = onTouchStart ? (e) => onTouchStart(player, e) : null;
	const endcaps = [];
	const paths = [];
	const animationCommands = { presnap: [], full: [] };
	const concreteSections = processRouteSections({ routedItem: player, fieldSize, paths, endcaps, animationCommands });
	const lastSection = concreteSections && concreteSections[concreteSections.length - 1];
	const lastRoutePoint: IPoint = (lastSection && lastSection.concretePoint) || { x: 0, y: 0 };
	const isBall = player.symbol === PlayerSymbol.ball;
	const classNames = isBall ? ['ball'] : ['player'];
	const routeDuration = routeDurations.diagramItems && routeDurations.diagramItems[player.id] || { full: 0, presnap: 0 };
	let endpoint;

	if (isEditing) {
		classNames.push('editing');
	}

	if (lastSection && typeof(lastSection.endCapType) === 'undefined') {
		endcaps.push(createEndCap([concreteSections[concreteSections.length - 2].concretePoint, lastRoutePoint], player.endCapType));
	}

	if(lastSection && player.endpointLabel) {
		const startPoint = concreteSections.length > 1? concreteSections[concreteSections.length - 2].concretePoint: { x: 0, y: 0 };
		const endPoint = lastSection.concretePoint;
		const sectionRadians = vectorUtil.angle(vectorUtil.normalize(vectorUtil.subtract(endPoint, startPoint)));
		const endpointLabelLoc = vectorUtil.add(endPoint, vectorUtil.rotate({ x: 12, y: 0 }, sectionRadians));

		endpoint = <g className="endpoint"><text x={ endpointLabelLoc.x } y={ endpointLabelLoc.y }>{ player.endpointLabel }</text></g>;
	}

	if (showAnimations) {
		classNames.push('animating');
	}

	return !player.loc.hasData() ? null :  <React.Fragment key={ `${player.id}-${routeDuration.full}-${showAnimations}` }>
		<svg className={ classNames.join(' ') } x={ `${player.loc.x * 100}%` } y={ `${(player.loc.y + yOffset) * 100}%` }>
			<g className={ `lines ${routeColor}`}>
				{ endpoint }
				{ createZone(player, lastRoutePoint) }
				{ paths }
				{ endcaps }
			</g>
			<PlayerPosition player={ player } color={ color } active={ active } isOpponent={ isOpponent } onPersonnelDrop={ onPersonnelDrop } onTouchStart={ touchHandler } />
			{ personnelInfo && <PlayerLabel info={ assign({ isLabel: true }, personnelInfo) } /> }
			{ isEditing ? <g className="selected" transform="rotate(134.57)">
				<circle cx="0" cy="0" r="18"></circle>
				<circle cx="0" cy="0" r="18"></circle>
				<animateTransform attributeName="transform" type="rotate" dur="0.75s" values="0 0 0;360 0 0" repeatCount="indefinite"></animateTransform>
			</g> : null
			}
			{ renderDiagnostics? <text className="positionDiagnostics" y="24">{player.sortIndex} ({(player.loc.x * 100).toFixed(5)} , {(player.loc.y * 100).toFixed(5)})</text>: null }
		</svg>
		{ showAnimations ? <svg id={ `player-${player.id}` } className="player animatedDiagramItem" x={ `${player.loc.x * 100}%` } y={ `${(player.loc.y + yOffset) * 100}%` }>
			<PlayerPosition player={ player } color={ color } active={ active } isOpponent={ isOpponent } routeDuration={ routeDuration } />

			<path id={ `animPath-${player.id}-${routeDuration.full}` } d={ `M 0 0 ${animationCommands.full.join(' ')}` } fill="transparent" stroke="transparent" style={{ pointerEvents: 'none' }} ref={ (el) => { if (el && setRouteLength) { setRouteLength(player, el.getTotalLength(), 'full'); } } } />
			<path d={ `M 0 0 ${animationCommands.presnap.join(' ')}` } fill="transparent" stroke="transparent" style={{ pointerEvents: 'none' }} ref={ (el) => { if (el && setRouteLength) { setRouteLength(player, el.getTotalLength(), 'presnap'); } } } />
		</svg> : null }
	</React.Fragment>;
}

function createAnnotationElement({ annotation, yOffset, isEditing, fieldSize, onTouchStart, active = false, showAnimations = false, routeDurations = { presnap: 0, full: 0, diagramItems: {}}, setRouteLength}:
	{ annotation: IAnnotation, yOffset: number, isEditing: boolean, fieldSize: ISize, onTouchStart: any, active?: boolean, showAnimations?: boolean, routeDurations: { presnap: number, full: number, diagramItems: { [key: string]: { presnap: number, full: number } } }, setRouteLength: (annotation: IRoutedDiagramItem, length: number, type: string) => void } ) {
	const subTypeName = AnnotationType[annotation.subType];
	const routeColor = annotation.subType === AnnotationType.Ball ? '' : `color${annotation.routeColor}`;
	const touchHandler = onTouchStart ? (e) => onTouchStart(annotation, e) : null;
	const endcaps = [];
	const paths = [];
	const animationCommands = { presnap: [], full: [] };
	const concreteSections = processRouteSections({ routedItem: annotation, fieldSize, paths, endcaps, animationCommands });
	const lastSection = concreteSections && concreteSections[concreteSections.length - 1];
	const lastRoutePoint: IPoint = (lastSection && lastSection.concretePoint) || { x: 0, y: 0 };
	const subtypeClassName = annotation.subType === AnnotationType.BallAnimatable ? 'ball animatable' : subTypeName.toLowerCase();
	const classNames = [subtypeClassName];
	const routeDuration = routeDurations.diagramItems && routeDurations.diagramItems[annotation.id] || { full: 0, presnap: 0 };

	if (isEditing) {
		classNames.push('editing');
	}

	if (showAnimations) {
		classNames.push('animating');
	}

	if (lastSection) {
		if (annotation.subType === AnnotationType.Ball) {
			endcaps.push(<g className="endpoint" key="endpoint">
				<circle cx={ lastRoutePoint.x } cy={ lastRoutePoint.y } r="7"></circle>
				<text x={ lastRoutePoint.x } y={ lastRoutePoint.y + 1 }>{ annotation.label }</text>
			</g>);
		} else if (annotation.subType === AnnotationType.BallAnimatable) {
			endcaps.push(<g className="endpoint"><circle cx={ lastRoutePoint.x } cy={ lastRoutePoint.y } r="2"></circle><text x="0" y="-50"></text></g>);
		} else if (typeof(lastSection.endCapType) === 'undefined') {
			endcaps.push(createEndCap([concreteSections[concreteSections.length - 2].concretePoint, lastRoutePoint], annotation.endCapType));
		}
	}

	return !annotation.loc.hasData() ? null :  <React.Fragment key={ `${annotation.id}-${routeDuration.full}-${showAnimations}` }>
		<svg key={ annotation.id } className={ classNames.join(' ')  } x={ `${annotation.loc.x * 100}%` } y={ `${(annotation.loc.y + yOffset) * 100}%` } onPointerDown={ supportsPointer ? touchHandler : undefined } onTouchStart={ !supportsPointer && supportsTouch ? touchHandler : undefined } onMouseDown={ !supportsPointer && !supportsTouch ? touchHandler : undefined }>
			<g className={ `lines ${routeColor}`} strokeLinecap="round">
				{ createZone(annotation, lastRoutePoint) }
				{ paths }
				{ endcaps }
			</g>
			{ createAnnotationPositionElement(annotation, active) }
			{ isEditing ? <g className="selected" transform="rotate(134.57)">
				<circle cx="0" cy="0" r="18"></circle>
				<circle cx="0" cy="0" r="18"></circle>
				<animateTransform attributeName="transform" type="rotate" dur="0.75s" values="0 0 0;360 0 0" repeatCount="indefinite"></animateTransform>
			</g> : null
			}
		</svg>
		{ showAnimations && annotation.subType === AnnotationType.BallAnimatable ? <svg id={ `annotation-${annotation.id}` } className={ `${subtypeClassName} animatedDiagramItem` } x={ `${annotation.loc.x * 100}%` } y={ `${(annotation.loc.y + yOffset) * 100}%` }>

			{ createAnnotationPositionElement(annotation, false, routeDuration) }

			<path id={ `animPath-${annotation.id}-${routeDuration.full}` } d={ `M 0 0 ${animationCommands.full.join(' ')}` } fill="transparent" stroke="transparent" style={{ pointerEvents: 'none' }} ref={ (el) => { if (el && setRouteLength) { setRouteLength(annotation, el.getTotalLength(), 'full'); } } } />
			<path d={ `M 0 0 ${animationCommands.presnap.join(' ')}` } fill="transparent" stroke="transparent" style={{ pointerEvents: 'none' }} ref={ (el) => { if (el && setRouteLength) { setRouteLength(annotation, el.getTotalLength(), 'presnap'); } } } />
		</svg> : null }
	</React.Fragment>;
}

interface DiagramProps {
	decorations?: IDiagramItem[];
	annotations?: IDiagramItem[];
	mates: IPlayer[];
	opponents?: IPlayer[];
}

class Diagram extends React.Component<Props & DiagramProps & IDropTargetParams> {
	private _boundsDiv: Element;
	private _diagramDiv: Element;
	private _trackedItemTouch: ITouch;
	private _trackedRouteTouch: ITouch;

	constructor(props: Props & DiagramProps & IDropTargetParams) {
		super(props);

		this.canDrop = this.canDrop.bind(this);
		this.onDrop = this.onDrop.bind(this);

		this.handleItemTouchLocked = this.handleItemTouchLocked.bind(this);
		this.handleItemTouchNotPermitted = this.handleItemTouchNotPermitted.bind(this);
		this.handleItemTouchEnd = this.handleItemTouchEnd.bind(this);
		this.handleItemTouchMove = this.handleItemTouchMove.bind(this);
		this.handleItemTouchStart = this.handleItemTouchStart.bind(this);
		this.handleRoutesTouchStart = this.handleRoutesTouchStart.bind(this);
		this.handleRoutesTouchEnd = this.handleRoutesTouchEnd.bind(this);
		this.handleRoutesTouchMove = this.handleRoutesTouchMove.bind(this);

		this.setBoundsDiv = this.setBoundsDiv.bind(this);
		this.setDiagramDiv = this.setDiagramDiv.bind(this);
	}

	public componentWillUnmount() {
		const { removeDropTarget } = this.props;

		if (this._boundsDiv) {
			removeDropTarget(this._boundsDiv);
		}
	}

	public handleItemTouchLocked(item: IDiagramItem) {
		const { mates } = this.props;
		const { currentPlaybook } = getCurrentContext();
		let text = _s(StringKey.TOOLTIP_DIAGRAM_LOCKED);

		if(item.getModelName() === 'RouteTreePlayer' && mates.length > currentPlaybook.playersPerSide) {
			text = _s(StringKey.TOOLTIP_ROUTE_TREE_LOCKED);
		}
		
		pushTooltip({
			id: 'playLocked',
			className: 'up red',
			text,
			requiresTarget: true,
			initialDelay: 0,
		});

		pushTooltip({
			id: 'playLockedMobile',
			className: 'down red',
			text,
			requiresTarget: true,
			initialDelay: 0,
		});
	}

	public handleItemTouchNotPermitted() {
		pushTooltip({
			id: 'playEditNotPermitted',
			className: 'floating',
			text: _s(StringKey.TOOLTIP_DIAGRAM_NOT_PERMITTED),
			requiresTarget: false,
			initialDelay: 0,
		});
	}

	public handleItemTouchStart(item: IDiagramItem, e: React.TouchEvent | React.PointerEvent | React.MouseEvent) {
		const { onItemTouchStart } = this.props;
		const touch: ITouch = touchFromEvent(e);

		e.stopPropagation();

		if (touch && onItemTouchStart && !this._trackedItemTouch) {
			// console.log(e.type);

			if (e.type === 'mousedown' || (e as React.PointerEvent).pointerType === 'mouse') {
				document.addEventListener('mousemove', this.handleItemTouchMove);
				document.addEventListener('mouseup', this.handleItemTouchEnd);
			} else {
				e.nativeEvent.stopPropagation();

				document.addEventListener('touchmove', this.handleItemTouchMove);
				document.addEventListener('touchend', this.handleItemTouchEnd);
			}

			this._trackedItemTouch = touch;

			onItemTouchStart(item);
		}
	}

	public handleItemTouchMove(e: MouseEvent | TouchEvent) {
		const { ballLocation, activeItem, lineOfScrimage, onItemMove } = this.props;
		const touch: ITouch = this._trackedItemTouch = touchFromEvent(e, this._trackedItemTouch && this._trackedItemTouch.sourceType, this._trackedItemTouch && this._trackedItemTouch.identifier);
		const yOffset = lineOfScrimage - ballLocation.y;

		if (touch && activeItem && this._boundsDiv) {
			console.log(yOffset);
			console.log(touch);

			const bounds = this._boundsDiv.getBoundingClientRect();
			const touchPoint: IVector = vectorUtil.toVector({ x: touch.clientX - bounds.left, y: touch.clientY - bounds.top });
			// const itemLoc = vectorUtil.toConcretePoint(activeItem.loc, bounds);

			this._trackedItemTouch = touch;

			if (onItemMove) {
				// console.log(touchPoint);
				// convert delta into an item location
				touchPoint.makeAbstract(bounds);
				// console.log(touchPoint);
				touchPoint.y -= yOffset;
				onItemMove(touchPoint);
			}
		}
	}

	public handleItemTouchEnd(e: MouseEvent | TouchEvent) {
		const { onItemTouchEnd } = this.props;
		const touch: ITouch = this._trackedItemTouch = touchFromEvent(e, this._trackedItemTouch && this._trackedItemTouch.sourceType, this._trackedItemTouch && this._trackedItemTouch.identifier);

		console.log(e.type);
		console.log(touch);

		document.removeEventListener('mousemove', this.handleItemTouchMove);
		document.removeEventListener('mouseup', this.handleItemTouchEnd);
		document.removeEventListener('touchmove', this.handleItemTouchMove);
		document.removeEventListener('touchend', this.handleItemTouchEnd);

		this._trackedItemTouch = null;

		if (onItemTouchEnd) {
			onItemTouchEnd();
		}
		// console.log('item touch end');
	}

	public handleRoutesTouchStart(e: React.PointerEvent | React.TouchEvent | React.MouseEvent) {
		const { activeItem, onRouteTouchStart } = this.props;
		const touch: ITouch = touchFromEvent(e);

		e.stopPropagation();

		if (activeItem && !this._trackedItemTouch && !this._trackedRouteTouch && onRouteTouchStart) {

			if (e.type === 'mousedown' || (e as React.PointerEvent).pointerType === 'mouse') {
				document.addEventListener('mousemove', this.handleRoutesTouchMove);
				document.addEventListener('mouseup', this.handleRoutesTouchEnd);
			} else {
				document.addEventListener('touchmove', this.handleRoutesTouchMove);
				document.addEventListener('touchend', this.handleRoutesTouchEnd);
			}

			this._trackedRouteTouch = touch;

			if (touch) {
				this.handleRoutesTouch(touch, onRouteTouchStart);
			}
		}
		// console.log(e.target);
	}

	public handleRoutesTouchMove(e: MouseEvent | TouchEvent) {
		const { onRouteMove } = this.props;
		const touch: ITouch = this._trackedRouteTouch = touchFromEvent(e, this._trackedItemTouch && this._trackedRouteTouch.sourceType, this._trackedItemTouch && this._trackedRouteTouch.identifier);

		if (touch) {
			this.handleRoutesTouch(touch, onRouteMove);
		}
	}

	public handleRoutesTouchEnd(e: MouseEvent | TouchEvent) {
		const { onRouteTouchEnd } = this.props;
		const touch: ITouch = this._trackedRouteTouch = touchFromEvent(e, this._trackedItemTouch && this._trackedRouteTouch.sourceType, this._trackedItemTouch && this._trackedRouteTouch.identifier);

		document.removeEventListener('mousemove', this.handleRoutesTouchMove);
		document.removeEventListener('mouseup', this.handleRoutesTouchEnd);
		document.removeEventListener('touchmove', this.handleRoutesTouchMove);
		document.removeEventListener('touchend', this.handleRoutesTouchEnd);

		this._trackedRouteTouch = null;

		if (touch) {
			this.handleRoutesTouch(touch, onRouteTouchEnd);
		}
	}

	public handleRoutesTouch(touch: ITouch, callback: (point: IPoint) => void) {
		const { activeItem, ballLocation, lineOfScrimage } = this.props;

		if (this._boundsDiv) {
			const bounds = this._boundsDiv.getBoundingClientRect();
			const itemConcrete = vectorUtil.toConcretePoint(activeItem.loc, bounds);
			const fieldConcrete = vectorUtil.subtract({ x: touch.clientX, y: touch.clientY }, { x: bounds.left, y: bounds.top });
			const itemOriented = vectorUtil.subtract(fieldConcrete, itemConcrete);
			const touchPoint = vectorUtil.toAbstractPoint(itemOriented, bounds);
			const yOffset = lineOfScrimage - ballLocation.y;

			touchPoint.y -= yOffset;

			callback(touchPoint);
		}
	}

	public canDrop(data: IDragData) {
		return data.type === 'annotation' || data.type === 'position-assignment';
	}

	public onDrop(data: IDragData) {
		if (data.type === 'annotation') {
			const { ballLocation, lineOfScrimage } = this.props;
			const yOffset = lineOfScrimage - ballLocation.y;

			data.info = assign({ fieldBounds: this._boundsDiv && this._boundsDiv.getBoundingClientRect(), yOffset }, data.info);
		} else {
			const { onPersonnelDrop } = this.props;

			data.info = assign({ player: { sortIndex: null } }, data.info); // null sortIndex clears from the personnel group

			onPersonnelDrop(data.info);
		}
	}

	public setBoundsDiv(el) {
		this._boundsDiv = el;

		if (this._boundsDiv) {
			this.forceUpdate();
		}
	}

	public setDiagramDiv(el) {
		const { addDropTarget, removeDropTarget } = this.props;

		if (this._diagramDiv) {
			removeDropTarget(this._diagramDiv);
		}

		this._diagramDiv = el;

		if (this._diagramDiv) {
			addDropTarget(this._diagramDiv, this.canDrop, this.onDrop);
		}
	}

	/*
		Append the 'alert' class to indicate an issue with a play (ex. it is out-of-sync)
		Append the 'selected' class to the play that was previously viewed before returning to this playbook view
	*/

	public render() {
		const { activeItem, activeDragSpec, activeRouteSection, annotations, decorations, ballLocation, fieldOptions, getPersonnelInfo, isMoving, lineOfScrimage, mates, opponents, onPersonnelDrop, renderFlags = DiagramRenderFlags.none, routeDurations, setRouteLength = () => null } = this.props;
		const { variant } = getCurrentContext();
		const diagramY = 0.5 - lineOfScrimage;
		const yOffset = lineOfScrimage - ballLocation.y; // NOTE: this allows diagrams to be drawn at the 50 yard line for thumbnails even though they are oriented latitudinally at some other place on the field
		const interactive = !!(renderFlags & DiagramRenderFlags.interactive);
		const interactiveRoutes = !!(renderFlags & DiagramRenderFlags.interactiveRoutes);
		const isLocked = !!(renderFlags & DiagramRenderFlags.locked);
		const showAnimations = !!(renderFlags & DiagramRenderFlags.showAnimations);
		const { playbookPermissions } = getCurrentContext();
		const onTouchStart = !playbookPermissions || !playbookPermissions.canUpdate ? this.handleItemTouchNotPermitted : interactive ? this.handleItemTouchStart : isLocked ? this.handleItemTouchLocked : null;
		const fieldSize = this._boundsDiv && this._boundsDiv.getBoundingClientRect();
		const activeMate = activeItem && find(mates, { id: activeItem.id });
		const activeOpponent = activeMate ? null : activeItem && find(opponents, { id: activeItem.id });
		const activeAnnotation = activeMate || activeOpponent ? null : activeItem && find(annotations, { id: activeItem.id });
		const mateElements = map(filter(mates, (player) => !activeMate || (player.id !== activeItem.id)), (player) => createPlayerElement({ player, yOffset, onTouchStart, fieldSize, isEditing: false, personnelInfo: getPersonnelInfo && getPersonnelInfo(player), onPersonnelDrop, routeDurations, setRouteLength, showAnimations }));
		const opponentElements = map(filter(opponents, (player) => !activeOpponent || (player.id !== activeItem.id)), (player) => createPlayerElement({ player, yOffset, onTouchStart: !activeMate ? onTouchStart : undefined, fieldSize, isEditing: activeItem && (player.id === activeItem.id), isOpponent: true, routeDurations, setRouteLength, showAnimations }));
		const annotationElements = map(filter(annotations, (annotation) => !activeAnnotation || (annotation.id !== activeItem.id)), (annotation) => createAnnotationElement({ annotation, yOffset, onTouchStart: !activeMate ? onTouchStart : undefined, fieldSize, isEditing: activeItem && (annotation.id === activeItem.id), routeDurations, setRouteLength, showAnimations }));
		const decorationElements = map(decorations, (decoration) => createAnnotationElement({ annotation: decoration, onTouchStart: undefined, yOffset, fieldSize, isEditing: false, routeDurations, setRouteLength: undefined, showAnimations: false }));
		const field = ((renderFlags & DiagramRenderFlags.showField) === DiagramRenderFlags.showField) ? createFieldElement(fieldOptions.key) : null;
		const snapGrid = ((renderFlags & DiagramRenderFlags.showSnapGrid) === DiagramRenderFlags.showSnapGrid) ? <SnapGrid /> : null;
		const diagramClasses = ['diagram'];
		let crosshairPoint: IPoint;

		// console.log(`diagram render flags: ${renderFlags}, mateCount ${mates.length}`);

		// push the selected item to the top of its list for visual overlay purposes
		if (activeMate) {
			mateElements.push(createPlayerElement({ player: activeMate, yOffset, onTouchStart, fieldSize, isEditing: true, personnelInfo: getPersonnelInfo && getPersonnelInfo(activeMate), onPersonnelDrop, routeDurations, setRouteLength, showAnimations }));
		}

		if (activeOpponent) {
			opponentElements.push(createPlayerElement({ player: activeOpponent, yOffset, onTouchStart, fieldSize, isEditing: true, isOpponent: true, routeDurations, setRouteLength, showAnimations }));
		}

		if (activeAnnotation) {
			annotationElements.push(createAnnotationElement({ annotation: activeAnnotation, yOffset, onTouchStart, fieldSize, isEditing: true, routeDurations, setRouteLength, showAnimations }));
		}

		if (activeItem || activeRouteSection) {
			crosshairPoint = activeRouteSection ? vectorUtil.add(activeItem.loc, activeRouteSection.loc) : isMoving ? activeItem.loc : null;
		}

		if (activeItem) {
			diagramClasses.push('editing');
		}

		if (isLocked) {
			diagramClasses.push('playLocked');
		}

		if (activeDragSpec) {
			diagramClasses.push('dragOver');
		}

		return <div className={ diagramClasses.join(' ') } style={{ transform: `translateY(${diagramY * 100 }%)` }} ref={ interactive && this.setDiagramDiv } onPointerDown={ (interactive && interactiveRoutes && supportsPointer) ? this.handleRoutesTouchStart : undefined } onTouchStart={ (interactive && interactiveRoutes && !supportsPointer && supportsTouch) ? this.handleRoutesTouchStart : undefined } onMouseDown={ (interactive && interactiveRoutes && !supportsPointer && !supportsTouch) ? this.handleRoutesTouchStart : undefined } >
			<div className="drawingArea"></div>
			{ snapGrid }
			{ field }
			<div className="routes" ref={ this.setBoundsDiv }>
				<svg viewBox="0 0 1024 2304">
					{ /* SVG Bounding Container */ }
					<g>
						{ createCrosshairs(crosshairPoint, yOffset) }

						{ /* Line of Scrimmage: */ }
						<svg className="lineOfScrimmage" x="0%" y={ `${lineOfScrimage * 100}%` }>
							
							{ snapGrid ? <text className="diagramOverlayTitle" x="50%" y="-4.75%">{ variant === 'tackle'? _s(StringKey.FORMATION_TEMPLATE): _s(StringKey.TEMPLATE) }</text>  : null }
							<line x1="0%" x2="100%" y1="0" y2="0"/>
						</svg>

						{ /* Decorations (currently classed as annotations) */ }
						<g className="annotations">
							{ decorationElements }
						</g>

						{ /* Opponent diagram */ }
						<g className="opponent">
							{ opponentElements }
						</g>

						{ /* My team diagram */ }
						<g className="myTeam">
							{ mateElements }
						</g>

						{ /* Annotations */ }
						<g className="annotations">
							{ annotationElements }
						</g>
					</g>

				</svg>
			</div>
		</div>;
	}
}

export const BallLocationPlayer = playerFactory({ color: 0, symbol: PlayerSymbol.ball, role: PlayerRoles.center, sortIndex: -1 });
class PlayContainer extends React.PureComponent<Props> {
	public render() {
		const { className, fieldOptions, decorations, diagram, renderFlags } = this.props;
		let annotations = (renderFlags & DiagramRenderFlags.showAnnotations) && (diagram as any).annotations ?  sortBy((diagram as any).annotations.values, 'sortIndex') : null;
		const mates: IPlayer[] = (renderFlags & DiagramRenderFlags.hideMates) ? [] : sortBy(diagram.mates.values, 'sortIndex');
		const opponents = (renderFlags & DiagramRenderFlags.showOpponents) && (diagram as any).opponents ? sortBy((diagram as any).opponents.values, 'sortIndex') : null;

		if ((renderFlags & DiagramRenderFlags.hideNonRouteAnnotations) && annotations) {
			annotations = filter(annotations, (a: IAnnotation) => a.subType === AnnotationType.Option || a.subType === AnnotationType.Ball || a.subType === AnnotationType.BallAnimatable);
		}

		// Add a fake "player" to mates so that defensive plays can be oriented on the field
		if (((diagram.posture === GamePhase.Defense && (!opponents || !opponents.length)) || diagram.unit === SpecialTeamsUnit.Kick) && mates.length && !diagram.mates[BallLocationPlayer.id]) {
			BallLocationPlayer.loc.x = diagram.ballLocation.x;
			BallLocationPlayer.loc.y = diagram.ballLocation.y;

			const player = playerFactory(BallLocationPlayer.toDocument());

			mates.unshift(player);
		}

		const innerProps: DiagramProps = { mates, annotations, decorations, opponents };
		const containerClasses = ['playContainer', `players${filter(innerProps.mates, (m) => m.symbol !== PlayerSymbol.ball).length}`, diagram.getClassName(), className];

		if (fieldOptions) {
			containerClasses.push(fieldOptions.key);
		}

		return  <div className={ containerClasses.filter(c => !!c).join(' ') }>
			<DropTarget>{ (params: IDropTargetParams) => {
				return <Diagram { ... this.props } { ...innerProps} { ...params } />;
			}}
			</DropTarget>
		</div>;
	}
}

export { PlayContainer as DiagramControl };

import { forEach, last, map, sortBy } from 'lodash';
import * as React from 'react';
import * as actions from '../actions';
import { current as getCurrentContext } from '../componentContext';
import { default as routeTreeFactory, IRouteTree } from '../models/routeTree';
import { default as teamFactory } from '../models/team';
import * as store from '../store';
import * as viewManager from '../viewManager';
import { RouteTreeInfoModal } from './routeInfo';
import { StringKey, _s } from '../strings';
import { IPlaybook } from '../models/playbook';
import { getPlaybookPermissions } from '../authorizationHelper';

interface Props {
	appState: store.IAppState;
    children: any
}

type SaveCallback = (routeTree: IRouteTree)=>void;

interface RouteTreeContextState {
    currentRouteTree: IRouteTree;
    openInfo({mateId, isNewRoute}: {mateId?: string, isNewRoute?: boolean});
    handleRouteTreeDrop(sourceId: string, targetId: string): void;
    saveRouteTree(routeTree: IRouteTree, callback?:SaveCallback): Promise<void>;
	deleteRoute(mateId: string, callback?:SaveCallback): Promise<void>;
}

const NO_OP = () => undefined;
const ASYNC_NO_OP = () => Promise.resolve();
const NULL_CONTEXT = {
	currentRouteTree: undefined,
	openInfo: NO_OP,
	handleRouteTreeDrop: NO_OP,
	saveRouteTree: ASYNC_NO_OP,
	deleteRoute: ASYNC_NO_OP
};

function fillPlaceholderRouteTree(routeTree: IRouteTree, assignReadOnlyIds: boolean) {
	for(const mate of routeTree.mates) {
		const labelKey = (mate as any).labelKey;
		if(!mate.label && labelKey) {
			mate.label = _s(labelKey)
		}
		if(assignReadOnlyIds) {
			(mate as any).id = `i${mate.sortIndex}`;
		}
	}

	routeTree.label = _s(StringKey.ROUTE_TREE);
	if(assignReadOnlyIds) {
		routeTree.id = `placeholderRouteTree`;
	}

	return routeTree;
}

export function getRouteTree(playbook: IPlaybook) {
	const { currentUser, teamPermissions, viewState: { settings } } = getCurrentContext();
	const playbookPermissions = getPlaybookPermissions({ playbook, teamPermissions, user: currentUser });
	const routeTree = routeTreeFactory(playbook.settings?.routeTree ?? fillPlaceholderRouteTree(settings.routeTree, !playbookPermissions.canUpdate));

	routeTree.playbookId = playbook.id;
	
	return routeTree;
}

export const RouteTreeContext = React.createContext<RouteTreeContextState>(NULL_CONTEXT);

export function RouteTreeProvider({ appState, children }: Props) {
	const [contextState, setContextState] = React.useState<RouteTreeContextState>(NULL_CONTEXT);
	const { viewState: { settings } } = appState;
	const { currentPlaybook, currentTeam, playbookPermissions } = getCurrentContext();
	const currentPlaybookId = currentPlaybook?.id;
	const { playersPerSide: playersPerSideSettings } = settings;

	React.useEffect(() => {
		const canUpdate = !!playbookPermissions?.canUpdate;
		const currentRouteTree = getRouteTree(currentPlaybook);

		const newState = {
			currentRouteTree,
			openInfo({mateId, isNewRoute}:{mateId?: string, isNewRoute?: boolean}) {
				viewManager.pushModal({
					component: RouteTreeInfoModal,
					props: () => ({ 
						appState: store.appState(), 
						mateId, 
						isNewRoute, 
						routeTree: currentRouteTree, 
						saveRouteTree: canUpdate? (rt, onComplete: () => void) => { 
							newState.saveRouteTree(rt, () => {
								if(onComplete) {
									onComplete();
								}
								actions.popModal(); 
								if(isNewRoute) {
									const newMate = last(sortBy(rt.mates.values, 'sortIndex'));
									actions.setLastCreatedDiagramId(newMate.id);
									viewManager.pushPath(`/playbook/${rt.playbookId}/routeTree/${rt.phase}/${newMate.id}`);
								}
							})
						}: undefined, 
						deleteRoute: canUpdate? (id, onComplete: () => void) => {
							newState.deleteRoute(id, () => {
								if(onComplete) {
									onComplete();
								}
								actions.popModal();
							});
						}: undefined }),
					supportsAlerts: true,
					screenName: 'RouteTreeInfo',
				})
			},
			handleRouteTreeDrop(sourceId: string, targetId: string) {
				if(!canUpdate) {
					return;
				}

				const source = currentRouteTree.mates[sourceId];
				const target = currentRouteTree.mates[targetId];
				const targetSortIndex = target.sortIndex;
				let index = 0;
				const sortedMap = map(currentRouteTree.mates.values, (mate) => {
					const sortIndex = mate === source ? (source.sortIndex > targetSortIndex) ? targetSortIndex - 0.5 : targetSortIndex + 0.5 : mate.sortIndex;

					return { id: mate.id, sortIndex };
				});

				forEach(sortBy(sortedMap, 'sortIndex'), (item) => {
					const mate = currentRouteTree.mates[item.id];

					mate.sortIndex = index++;
				});

				newState.saveRouteTree(currentRouteTree)
			},
			async saveRouteTree(routeTree:IRouteTree, callback?: SaveCallback) {
				const mutatedTeam = teamFactory(currentTeam);
				const mutatedPlaybook = mutatedTeam.playbooks[currentPlaybookId];

				routeTree.playbookId = mutatedPlaybook.id;
				routeTree.teamId = mutatedTeam.id;

				mutatedPlaybook.settings.routeTree = routeTree;

				if (actions.validatePlaybook(mutatedPlaybook)) {
					if (actions.validateTeam(mutatedTeam)) {
						actions.saveTeam(mutatedTeam, `routetree-update-${mutatedTeam.id}`);
						if(callback) {
							callback(routeTree);
						}
					}
				}
				
			},
			async deleteRoute(mateId:string, callback?: SaveCallback) {
				const mutatedRouteTree = routeTreeFactory(currentRouteTree);

				mutatedRouteTree.mates.remove(mateId);

				await newState.saveRouteTree(mutatedRouteTree, callback);
			}
		};

		if(!currentPlaybook.settings?.routeTree && playbookPermissions?.canUpdate) {
			newState.saveRouteTree(currentRouteTree);
		}

		setContextState(newState);

	}, [currentPlaybook, playersPerSideSettings, playbookPermissions, currentPlaybook.settings?.routeTree]);

	return <RouteTreeContext.Provider value={ contextState }>
		{ children }
	</RouteTreeContext.Provider>
}

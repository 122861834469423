import { assign, filter, find } from 'lodash';
import { StringDictionary } from 'playmaker-team-common/dist/shared/interfaces';
import * as React from 'react';
import * as actions from '../actions';
import { current as getCurrentContext } from '../componentContext';
import { PlaybookAccess } from '../models/playbookMember';
import { SystemFeature } from '../models/subscriptionPlan';
import { default as teamFactory } from '../models/team';
import { default as teamMemberFactory, ITeamMember, TeamRole } from '../models/teamMember';
import * as store from '../store';
import { IAlert, IAppState } from '../store';
import { _s, StringKey } from '../strings';
import * as viewManager from '../viewManager';
import { AlertList } from './alert';
import { BillingModal } from './billing';
import { ConnectionStatus } from './connectionStatus';
import { Page, PageState } from './page';
import { RosterGallery } from './rosterGallery';
import { RosterList } from './rosterList';
import { TeamMemberEditModal } from './teamMemberEdit';

interface Props {
	alerts?: IAlert[];
	appState: IAppState;
}

interface State {
	activePage: number;
	playerSortKey: string;
	playerSortDirection: number;
	showStaff: boolean;
	selectedMemberIds: string[];
	staffSortKey: string;
	staffSortDirection: number;
}

const AccessAlert = () => {
	const { currentSubscription, currentTeam, platform } = getCurrentContext();
	const accessDisabled = !currentSubscription || !currentSubscription.isActive() || !currentSubscription.supportsFeature(SystemFeature.collaboration);

	if (!accessDisabled) {
		return null;
	}

	const onClick = platform.isTrolledGarden ? null : () => {
		viewManager.pushModal({
			component: BillingModal,
			props: () => ({ appState: store.appState(), teamId: currentTeam.id }),
			screenName: 'Billing',
		});
	};

	return <div className="alert upgrade interactive" onClick={ onClick }>
		<span className="icon lock"></span><span>{ _s(platform.isTrolledGarden ? StringKey.UPGRADE_TEAM_ACCESS_ALERT_TROLL : StringKey.UPGRADE_TEAM_ACCESS_ALERT) }</span>
	</div>;
};

const TEAM_MEMBER_EDIT_MODAL_ID = 'team-member-edit-modal';
export class TeamCenter extends React.Component<Props, State> {
	private goToStaffPage;
	private goToPlayerPage;

	constructor(props: Props) {
		super(props);

		const { currentPlaybook } = getCurrentContext();
		const showStaff = !currentPlaybook;

		this.state = {
			activePage: 0,
			playerSortDirection: 1,
			playerSortKey: 'firstName',
			selectedMemberIds: [],
			staffSortDirection: 1,
			staffSortKey: 'firstName',
			showStaff,
		};

		this.handleAdd = this.handleAdd.bind(this);
		this.handleEdit = this.handleEdit.bind(this);
		this.goToPlayerPage = this.goToPage.bind(this, 0);
		this.goToStaffPage = this.goToPage.bind(this, 1);
		this.saveTeamMember = this.saveTeamMember.bind(this);
		this.sendInvites = this.sendInvites.bind(this);
		this.setSelectedMemberIds = this.setSelectedMemberIds.bind(this);
		this.handleSortClick = this.handleSortClick.bind(this);
	}

	public getPageState(key) {
		const { activePage } = this.state;

		if (key === activePage) {
			return PageState.IN;
		}
		// else if(key < activePage) {
		// 	return PageState.OUT;
		// }

		return PageState.OUT;
	}

	public goToPage(page: number) {
		actions.clearAlerts();
		this.setState((previousState) => {
			const newState = assign({}, previousState) as State;

			newState.activePage = page;
			newState.selectedMemberIds = [];

			return newState;
		});
	}

	public handleAdd() {
		const { activePage } = this.state;
		const { currentSubscription } = getCurrentContext();
		const sendInvites = currentSubscription && currentSubscription.isActive() && currentSubscription.supportsFeature(SystemFeature.collaboration) ? this.sendInvites : undefined;

		viewManager.pushModal({
			id: TEAM_MEMBER_EDIT_MODAL_ID,
			component: TeamMemberEditModal,
			props: () => ({ appState: store.appState(), accessAlert: <AccessAlert />, saveTeamMember: this.saveTeamMember, teamMember: teamMemberFactory({ role: activePage === 0 ? TeamRole.Player : TeamRole.Staff }), sendInvites, modalId: TEAM_MEMBER_EDIT_MODAL_ID }),
			screenName: 'TeamMemberEdit',
		});
	}

	public handleEdit(memberId: string) {
		const { currentSubscription, currentTeam, teamPermissions } = getCurrentContext();
		const teamMember: ITeamMember = currentTeam.members[memberId];
		const sendInvites = currentSubscription && currentSubscription.isActive() && currentSubscription.supportsFeature(SystemFeature.collaboration) ? this.sendInvites : undefined;
		const deleteMembers = !teamMember.notSaved && (teamMember.role !== TeamRole.Owner || teamPermissions.canChangeAdmins) ? this.deleteMembers : undefined;

		viewManager.pushModal({
			id: TEAM_MEMBER_EDIT_MODAL_ID,
			component: TeamMemberEditModal,
			props: () => ({ appState: store.appState(), accessAlert: <AccessAlert />, saveTeamMember: this.saveTeamMember, teamMember, deleteMembers, sendInvites, modalId: TEAM_MEMBER_EDIT_MODAL_ID }),
			screenName: 'TeamMemberEdit',
		});
	}

	public setSelectedMemberIds(teamMemberIds: string[]) {
		this.setState({ selectedMemberIds: teamMemberIds });
	}

	public async sendInvites(teamMembers: ITeamMember[]) {
		const { currentTeam } = getCurrentContext();
		const mutatedTeam = teamFactory(currentTeam);
		const inviteSpecs: StringDictionary = {};
		const count = teamMembers.length;

		if (count === 0) {
			return;
		}

		for (const member of teamMembers) {
			let canSendInvite = true;
			const existingMember = mutatedTeam.members[member.id];

			// this came as an Add or Edit from TeamMemberEdit
			if (!existingMember || member.getPatch(existingMember).length) {
				canSendInvite = await this.saveTeamMember(member);
			}

			if (canSendInvite) {
				const memberPermissions = [];
				for (const playbook of mutatedTeam.playbooks.values) {
					memberPermissions.push({ playbookId: playbook.id, access: (member.role === TeamRole.Player ? PlaybookAccess.View : PlaybookAccess.Edit) });
				}

				inviteSpecs[member.id] = memberPermissions;
			}
		}

		await actions.inviteMembers(mutatedTeam, inviteSpecs);
	}

	public async deleteMembers(teamMembers: ITeamMember[]) {
		return new Promise((resolve) => {
			const alertId = 'delete-team-members';
			const count = teamMembers.length;
			const { currentTeam } = getCurrentContext();

			if (count === 0) {
				return resolve(true);
			}

			const deleteAction = async () => {
				actions.deleteAlert(alertId);

				const mutatedTeam = teamFactory(currentTeam);

				for (const teamMember of teamMembers) {
					mutatedTeam.members.remove(teamMember.id);
				}

				if (actions.validateTeam(mutatedTeam)) {
					await actions.saveTeam(mutatedTeam, `member-delete-${mutatedTeam.id}`);
				}

				resolve(true);
			};

			const cancelAction = async () => {
				actions.deleteAlert(alertId);
				resolve(false);
			};

			actions.pushAlert({
				id: alertId,
				title: count === 1 ? _s(StringKey.DELETE_TEAM_MEMBER_QUESTION) : _s(StringKey.DELETE_TEAM_MEMBERS_QUESTION),
				message:  count === 1 ? _s(StringKey.DELETE_TEAM_MEMBER_PROMPT_MESSAGE) : _s(StringKey.DELETE_TEAM_MEMBERS_PROMPT_MESSAGE),
				mode: store.AlertMode.prompt,
				severity: store.AlertSeverity.confirmation,
				actions: [{
					label: count === 1 ? _s(StringKey.DELETE_TEAM_MEMBER) : _s(StringKey.DELETE_TEAM_MEMBERS),
					className: 'delete',
					action: deleteAction,
				}, {
					label: _s(StringKey.CANCEL),
					className: 'cancel',
					action: cancelAction,
				}],
			});
		});
	}

	public async saveTeamMember(teamMember: ITeamMember) {
		const { currentTeam } = getCurrentContext();
		const mutatedTeam = teamFactory(currentTeam);
		const { activePage } = this.state;
		const existingMember: ITeamMember = mutatedTeam.members[teamMember.id];

		teamMember.teamId = mutatedTeam.id;

		if (typeof teamMember.role === 'undefined') {
			teamMember.role = activePage === 0 ? TeamRole.Player : TeamRole.Staff;
		}

		if (!existingMember) {
			mutatedTeam.members.add(teamMember);
		} else {
			if (existingMember.role !== teamMember.role) {
				// reset teamMember permissions
				for (const playbook of mutatedTeam.playbooks.values) {
					const playbookMember = find(playbook.permissions.values, { teamMemberId: teamMember.id });

					if (playbookMember) {
						playbookMember.access = (teamMember.role === TeamRole.Player ? PlaybookAccess.View : PlaybookAccess.Edit);
					}
				}
			}
			mutatedTeam.members[teamMember.id] = teamMember;
		}

		actions.clearAlerts();

		if (actions.validateTeamMember(teamMember)) {
			if (actions.validateTeam(mutatedTeam)) {
				await actions.saveTeam(mutatedTeam, `member-update-${mutatedTeam.id}`);
				return true;
			}
		}

		return false;
	}

	public handleSortClick(e) {
		const { activePage } = this.state;
		const parts = e.currentTarget.id.split('-');
		const list = activePage === 0 ? 'player' : 'staff';
		const newSortKey = parts[1];

		this.setState((prevState) => {
			const newState = assign({}, prevState);
			const sortProp = `${list}SortKey`;
			const dirProp = `${list}SortDirection`;
			const currentSortKey = prevState[sortProp];
			const currentSortDir = prevState[dirProp];

			if (currentSortKey === newSortKey) {
				newState[dirProp] = -currentSortDir;
			} else {
				newState[sortProp] = newSortKey;
				newState[dirProp] = 1;
			}

			return newState;
		});
	}

	public render() {
		const { alerts = [], appState } = this.props;
		const { viewState } = appState;
		const { activePage, playerSortDirection, playerSortKey, selectedMemberIds, staffSortDirection, staffSortKey, showStaff } = this.state;
		const { currentSubscription, currentTeam } = getCurrentContext();
		const alertList = <AlertList alerts={ alerts } />;
		const staffList = showStaff ? filter(currentTeam.members, (teamMember: ITeamMember) => (teamMember.role === TeamRole.Staff || teamMember.role === TeamRole.Owner)) : [];
		const playerList = filter(currentTeam.members, (teamMember: ITeamMember) => teamMember.role === TeamRole.Player);
		const sendInvites = currentSubscription && currentSubscription.isActive() && currentSubscription.supportsFeature(SystemFeature.collaboration) ? this.sendInvites : undefined;

		return <React.Fragment>
			<header>
				<div className="actions">
					<a className="button" onClick={ viewManager.popPath }><span className="icon back"></span><span className="teamCenter"><strong>{ currentTeam.name }</strong>{ _s(StringKey.TEAM_CENTER) }</span></a>
				</div>
				<div className="actions">
					<a className={ `button ${viewState.showRosterAsAvatar ? '' : 'on'}` } onClick={ actions.toggleShowRosterAsAvatar }><span className="icon listView"></span></a>
				</div>
				<div className="actions">
					<ConnectionStatus />
				</div>
			</header>
			<div className="content">
				<div className="view tabbed">
					<header>
						<div className="radio tabs">
							<label className={ activePage === 0 ? 'on' : '' }><span>{ _s(StringKey.PLAYERS) }</span><input type="radio" name="rosterMode" checked={ activePage === 0 } onChange={ this.goToPlayerPage } /></label>
							{ showStaff ? <label className={ activePage === 1 ? 'on' : '' }><span>{ _s(StringKey.STAFF) }</span><input type="radio" name="rosterMode" checked={ activePage === 1 } onChange={ this.goToStaffPage } /></label> : null }
						</div>
					</header>

					<div className="content swappable">

						<Page key="page0" pageState={ this.getPageState(0)}>
							{ viewState.showRosterAsAvatar ? <RosterGallery alertList={ alertList } isPlayerList={ true } onAddClick={ this.handleAdd } onEditClick={ this.handleEdit } teamMembers={ playerList }  /> :
								<RosterList
									alertList={ alertList }
									deleteMembers={ this.deleteMembers }
									isPlayerList={ true }
									saveTeamMember={ this.saveTeamMember }
									selectedMemberIds={ selectedMemberIds }
									setSelectedMemberIds={ this.setSelectedMemberIds }
									sendInvites={ sendInvites }
									sortDirection={ playerSortDirection }
									sortKey={ playerSortKey }
									sortList={ this.handleSortClick }
									onEditClick={ this.handleEdit }
									teamMembers={ playerList }  /> }
						</Page>

						{ showStaff ? <Page key="page1" pageState={ this.getPageState(1)}>
							{ viewState.showRosterAsAvatar ? <RosterGallery alertList={ alertList } isPlayerList={ false } onAddClick={ this.handleAdd } onEditClick={ this.handleEdit } teamMembers={ staffList }  /> :
								<RosterList
									alertList={ alertList }
									deleteMembers={ this.deleteMembers }
									isPlayerList={ false }
									saveTeamMember={ this.saveTeamMember }
									selectedMemberIds={ selectedMemberIds }
									setSelectedMemberIds={ this.setSelectedMemberIds }
									sendInvites={ sendInvites }
									sortDirection={ staffSortDirection }
									sortKey={ staffSortKey }
									sortList={ this.handleSortClick }
									onEditClick={ this.handleEdit }
									teamMembers={ staffList }  />  }
						</Page> : null }
					</div>
				</div>
			</div>
			<footer>
				<div className="actions">
					<a className={ `button ${viewState.showRosterAsAvatar ? '' : 'on'}` } onClick={ actions.toggleShowRosterAsAvatar }><span className="icon listView"></span></a>
				</div>
			</footer>
		</React.Fragment>;
	}
}

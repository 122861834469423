import { filter } from 'lodash';
import * as React from 'react';
import { Transition } from 'react-transition-group';
import { ITooltip } from '../store';

interface Props extends ITooltip {
	children: any;
	index?: number;
}

interface State {

}

export class TooltipTransition extends React.Component<Props, State> {
	private _hasEntered = false;

	constructor(props) {
		super(props);
	}

	public render() {
		const {children, ...props} = this.props;
		const { initialDelay = 400 } = props;

		return (<Transition timeout={{ enter: initialDelay, exit: 210 }} appear={ true } { ...props }>{ (state) => {
			const { id, className = '', helpSwitch, index = 0, requiresTarget, text = ''} = props;
			const classNames = [className];
			const targetElement = typeof document !== 'undefined' ? document.getElementById(`tooltip-target-${id}`) : null;
			let display: string = null;
			let top: string = null;
			let left: string = null;

			if (state === 'entered') {
				this._hasEntered = true;
			}

			if ((!targetElement && requiresTarget) || (targetElement && targetElement.offsetParent === null)) {
				display = 'none';
			} else {
				classNames.unshift('tooltip');

				if (['entered'].indexOf(state) !== -1) {
					classNames.push('in');
				} else if (['exiting', 'exited'].indexOf(state) !== -1) {
					classNames.push('out');
				}

				if (requiresTarget && this._hasEntered) {
					const boundingRect = targetElement.getBoundingClientRect();

					top = `${(boundingRect.top + (boundingRect.height / 2))}px`;
					left = `${(boundingRect.left + (boundingRect.width / 2))}px`;
				}
			}

			return 	<div className={ classNames.join(' ') } style={{ zIndex: 200 + index, top, left, display }}>
				<div className="inner">
					<div className="arrow"></div>
					<div className="box">
						<span>{ text }</span>
					</div>
				</div>
			</div>;
		} }</Transition>);
	}
}

import { assign } from 'lodash';
import { IModel, StringDictionary } from 'playmaker-team-common/dist/shared/interfaces';
import { createFactory } from 'playmaker-team-common/dist/shared/modelFactory';
import { default as validator } from 'playmaker-team-common/dist/shared/validator';
import { default as valueFilters } from 'playmaker-team-common/dist/shared/valueFilters';
import { default as diagramItemModel, IDiagramItem } from './diagramItemModel';
import { default as routeFactory, IRoute } from './route';
import { EndCapType, LineStyle } from './routeSection';

export enum AnimationSpeed {
	default,
	minimum,
	maximum,
}

export interface IRoutedDiagramItem extends IDiagramItem {
	animationSpeed?: AnimationSpeed;
	curved: boolean;
	endCapType: EndCapType;
	endpointLabel?: string; // this is a transient only property
	lineStyle: LineStyle;
	maxRouteSections: number;
	motion: boolean;
	routeColor: number;
	route: IRoute;
	applyRouteTemplate(template: IRoutedDiagramItem):void;
	flipHorizontally: () => void;
	flipVertically: () => void;
}

export default assign({}, diagramItemModel, {
	animationSpeed: {
		setFilters: valueFilters.toNumber,
	},
	curved: {
		defaultValue: false,
		setFilters: valueFilters.toBoolean,
	},
	endCapType: {
		defaultValue: EndCapType.none,
		setFilters: valueFilters.toNumber,
	},
	endpointLabel: {
	},
	lineStyle: {
		defaultValue: LineStyle.dash,
		setFilters: valueFilters.toNumber,
	},
	motion: {
		defaultValue: false,
		setFilters: valueFilters.toBoolean,
	},
	routeColor: {
		setFilters: valueFilters.toNumber,
	},
	route: {
		setFilters: valueFilters.toModel<IRoute>(routeFactory),
		rules: validator.validModel(routeFactory.rules()),
		defaultValue: {},
	},
	maxRouteSections: {
		nonSerialized: true,
		writable: false,
		defaultValue: -1, // -1 means unlimited
	},
	flipHorizontally() {
		if (this.route) {
			for (const section of this.route.sections) {
				section.loc.x = -section.loc.x;
			}
		}
	},
	flipVertically() {
		if (this.route) {
			for (const section of this.route.sections) {
				section.loc.y = -section.loc.y;
			}
		}
	},
	applyRouteTemplate(template: IRoutedDiagramItem) {
		this.route.sections = template.route.sections.toDocuments(undefined, ['id']);
		this.curved = template.curved;
		this.endCapType = template.endCapType;
		this.lineStyle = template.lineStyle;
		this.motion = template.motion;
	}
});

import * as React from 'react';

export function RosterDragLabel({ label = '' }) {
	return <div className="inner">
		<div className="arrow"></div>
		<div className="box">
			<span>{ label }</span>
		</div>
	</div>;
}

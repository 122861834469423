import { assign, merge } from 'lodash';
import { IRouteMatchState } from 'playmaker-team-common/dist/client/router';
import { Emitter } from 'playmaker-team-common/dist/shared/emitter';
import { IModel, StringDictionary } from 'playmaker-team-common/dist/shared/interfaces';
import { IFormation } from './models/formation';
import { IPlay } from './models/play';
import { ISortable } from './models/sortableModel';
import { ISubscriptionPlan } from './models/subscriptionPlan';
import { ITeam } from './models/team';
import { IUser } from './models/user';
import { ProcessQueue } from './processQueue';
import { ITouch } from './touchHelper';
import { DiagramRenderFlags } from './models/diagramModel';
import { IPoint } from './models/vector';

const _mutateQueue = new ProcessQueue({ autoActive: true });

export const SCHEMA_VERSION = 1;

export interface IAppConfig {
	appStoreUrl?: string;
	environment?: string;
	enableTracking?: boolean;
	language?: string;
	updateCheckInterval?: number;
	preventBoot?: boolean;
	signingKey?: string;
	stripeKey?: string;
	version?: string;
	variant?: 'flag' | 'tackle';
	virtualRoot?: string;
	animation?: { defaultVelocity: number, minFactor: number, maxFactor: number };
}

export interface IPlatformInfo {
	type: string;
	os?: string;
	version?: string;
	userAgent?: string;
	isWrapped?: boolean;
	supportsIap?: boolean;
	isTrolledGarden?: boolean;
}

export interface INetworkInfo {
	availableVersion?: string;
	authFailure?: any;
	canAccessApi: boolean;
	currentVersion: string;
	socketConnected: boolean;
	initialized: boolean;
	isOnline: boolean;
	pingMs?: number;
	updateReady?: boolean;
}

export interface IMainMenu {
	expanded: boolean;
}

export interface IModal {
	id?: string;
	component: any;
	props?: { [s: string]: any } | (() => { [s: string]: any });
	inClass?: string;
	outClass?: string;
	supportsAlerts?: boolean;
	screenName?: string;
}

export interface IAction {
	id?: string;
	className?: string;
	label: string;
	action: () => void;
}

export interface IDragLayout {
	x?: number;
	y?: number;
	width?: number;
	height?: number;
	constrain?: 'x' | 'y';
	scrollContainer?: HTMLElement;
	scrollInterval?: any;
}

export interface IDragData {
	clientX?: number;
	clientY?: number;
	layout?: IDragLayout;
	info?: any;
	type: 'annotation' | 'position-assignment' | 'row' | 'thumbnail';
}

export interface IDragSpec {
	component: any;
	containerClassName?: string;
	data: IDragData;
	dragEnd?: (didDrop: boolean, data: IDragData) => void;
	dragStart?: () => void;
	getDragLayout?: (touch: ITouch, currentTarget: Element) => IDragLayout;
	props?: { [s: string]: any } | (() => { [s: string]: any });
}

export enum AlertSeverity {
	confirmation,
	error,
	info,
	warning,
}

export enum AlertMode {
	default,
	prompt,
}

export interface IAlert {
	id?: string;
	title?: string;
	message: string;
	mode?: AlertMode;
	severity: AlertSeverity;
	actions?: IAction[];
}

export interface ITooltip {
	id: string;
	className: string;
	text: string;
	helpSwitch?: HelpSwitches;
	requiresTarget: boolean;
	initialDelay?: number;
}

export interface IBoundingRect {
	top: number;
	left: number;
	height: number;
	width: number;
}

export interface IBrowserContext {
	windowSize?: IBoundingRect;
	isVisible: boolean;
}

export interface IUnsyncedEntity {
	count: number;
	entityId: string;
	entityType: string;
	timestamp: number;
	wasCreated: boolean;
	wasDeleted: boolean;
	storeKey?: string;
	itemVersion?: number;
	unsyncedValue?: any;
}

export const enum HelpSwitches {
	none = 0,
	playbookCustomizeFormationsAndCategories = 1 << 0,
	playbookRearrangePlays = 1 << 1,
	playEditPosition = 1 << 2,
	playDrawRoute = 1 << 3,
	playDragToolbar = 1 << 4,
	playAdvancedOptions = 1 << 5,
	playAssignPositions = 1 << 6,
	rosterAddPlayers = 1 << 7,
}

export interface IDiagramConfig {
	itemModalOffset?: IPoint,
	animationModalOffset?: IPoint
}

export interface IViewState {
	banners: any;
	bootstrapping?: boolean;
	bootstrapMessage: string;
	bootstrapStage?: 'init' | 'cacheUpdate'| 'fetch';
	config: IAppConfig;
	mainMenu: IMainMenu;
	api: INetworkInfo;
	dragSpec?: IDragSpec;
	platform: IPlatformInfo;
	decorations: IModal[];
	modals: IModal[];
	panels: IModal[];
	tooltips: ITooltip[];
	alerts: IAlert[];
	currentRoute: IRouteMatchState;
	currentUserId: string;
	currentTeamId: string;
	isImpersonating?: boolean;
	lastCreatedDiagramId: string;
	lastViewedPlayId: string;
	browserContext: IBrowserContext;
	showFormationsAsList: boolean;
	showPlaysAsList: boolean;
	showRosterAsAvatar: boolean;
	settings: any;
	strings: any;
	diagramConfig: IDiagramConfig;
	disableRouteInteraction: boolean;
	helpSwitches: number;
	unsyncedEntities: { [id: string]: IUnsyncedEntity };
	availableSubscriptionPlans: ISubscriptionPlan[];
	initParams: StringDictionary;
	initialLocationSearch: string;
	orphanedIaps?: [];
	syncInProgress?: boolean;
	globalDiagramFlags: DiagramRenderFlags
}

interface IModelMap<T> { [s: string]: T; }

export interface IModelState {
	formations: IModelMap<IModel & IFormation & ISortable>;
	plays: IModelMap<IModel & IPlay & ISortable>;
	users: IModelMap<IModel & IUser>;
	teams: IModelMap<IModel & ITeam>;
}

export interface IAppState {
	viewState: IViewState;
	model: IModelState;
}

const _emitter = new Emitter();
const _initialState: IAppState = {
	viewState: {
		banners: {},
		bootstrapMessage: '',
		config: {},
		mainMenu: {
			expanded: false,
		},
		api: {
			canAccessApi: false,
			currentVersion: '',
			socketConnected: false,
			initialized: false,
			isOnline: false,
		},
		platform: {
			type: 'browser',
		},
		decorations: [],
		modals: [],
		panels: [],
		tooltips: [],
		alerts: [],
		currentRoute: null,
		currentTeamId: null,
		currentUserId: null,
		lastCreatedDiagramId: null,
		lastViewedPlayId: null,
		browserContext: {
			isVisible: true,
		},
		showFormationsAsList: false,
		showPlaysAsList: false,
		showRosterAsAvatar: false,
		settings: {},
		strings: {},
		disableRouteInteraction: false,
		diagramConfig: {},
		helpSwitches: 0,
		unsyncedEntities: {},
		availableSubscriptionPlans: [],
		initParams: {},
		initialLocationSearch: '',
		globalDiagramFlags: 0
	},
	model: {
		users: {},
		teams: {},
		plays: {},
		formations: {},
	},
};

let _appState: IAppState = merge({}, _initialState);

export const MUTATING = 'store.mutating';
export const MUTATED = 'store.mutated';

export function populate(appState: IAppState) {
	_appState = merge({}, appState);
}

export function reset() {
	populate(_initialState);
}

export async function mutateAsync(mutator: (appState: IAppState) => PromiseLike<void>, path: string = null) {
	return new Promise((resolve) => {
		_mutateQueue.enqueue(async () => {
			const newData = assign({}, _appState);

			await mutator(newData);
			_emitter.trigger(formatPathMessage(path, MUTATING), { oldState: _appState, newState: newData });
			_appState = newData;
			_emitter.trigger(formatPathMessage(path));
		}, resolve);
	});
}

export async function mutate(mutator: (appState: IAppState) => void, path: string = null) {
	return new Promise((resolve) => {
		_mutateQueue.enqueue(() => {
			const newData = assign({}, _appState);

			mutator(newData);
			_emitter.trigger(formatPathMessage(path, MUTATING), { oldState: _appState, newState: newData });
			_appState = newData;
			_emitter.trigger(formatPathMessage(path));

			return Promise.resolve();
		}, resolve);
	});

	// _mutateQueue.enqueue(() => {
	// 	const newData = assign({}, _appState);

	// 	mutator(newData);
	// 	_emitter.trigger(formatPathMessage(path, MUTATING), { oldState: _appState, newState: newData });
	// 	_appState = newData;
	// 	_emitter.trigger(formatPathMessage(path));

	// 	return Promise.resolve();
	// });
}

// eslint-disable-next-line @typescript-eslint/ban-types
export function off(handler: Function) {
	_emitter.off(handler);
}

// eslint-disable-next-line @typescript-eslint/ban-types
export function on(message: string, handler: Function) {
	_emitter.on(message, handler);
}

export function appState(): IAppState {
	return _appState;
}

export function initialState(): IAppState {
	return _initialState;
}

export function formatPathMessage(path: string, base: string = MUTATED) {
	return path ? `${base}.${path}` : base;
}

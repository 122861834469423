import * as React from 'react';
import { StringKey, _s } from '../strings';
import { current as getCurrentContext } from '../componentContext';
import { getRouteTree } from './routeTreeProvider';


function RouteGroup({startIndex, routeCount, onClick}: { startIndex: number, routeCount: number, onClick: (e: React.MouseEvent<HTMLAnchorElement>) => void}) {
	if(startIndex >= routeCount) {
		return null;
	}
	const upperBound = Math.min(startIndex + 5, routeCount)
	const buttons = [];

	for(let idx = startIndex; idx < upperBound; idx++) {
		buttons.push(<a className="button basic dark" onClick={ onClick } data-index={idx}><span>{idx}</span></a>)	
	}

	return <div className="subGroup">{ buttons }</div>
}

export function TemplateTools({ applyRouteTemplate }: { applyRouteTemplate: (index: number)=>void}) {
	const { currentPlaybook } = getCurrentContext();
	const routeTree = getRouteTree(currentPlaybook);
	const routeCount = routeTree.mates.count;

	const handleClick = React.useCallback((e:React.MouseEvent<HTMLAnchorElement>) => {
		const index = Number(e.currentTarget.dataset.index);
		applyRouteTemplate(index);
	}, [applyRouteTemplate]);

	return <div className="inner">
		<h3>{ _s(StringKey.APPLY_ROUTE_TEMPLATE) }</h3>
		<div className="buttonGroup">
			<RouteGroup startIndex={0} routeCount={routeCount} onClick={handleClick} />
			<RouteGroup startIndex={5} routeCount={routeCount} onClick={handleClick} />
			<RouteGroup startIndex={10} routeCount={routeCount} onClick={handleClick} />
			<RouteGroup startIndex={15} routeCount={routeCount} onClick={handleClick} />
		</div>
	</div>
}
import { assign, filter, find, findIndex, map, set, some, sortBy } from 'lodash';
import { createId } from 'playmaker-team-common/dist/shared/createId';
import { StringDictionary } from 'playmaker-team-common/dist/shared/interfaces';
import * as apiGateway from './apiGateway';
import * as clientStorage from './clientStorage';
import { SponsorModal } from './components/sponsorModal';
import * as connectionMonitor from './connectionMonitor';
import * as dataService from './dataService';
import * as logger from './logger';
import { default as formationFactory, IFormation } from './models/formation';
import { default as playFactory, IPlay } from './models/play';
import { IPlaybook } from './models/playbook';
import { ISortable } from './models/sortableModel';
import { ISubscription } from './models/subscription';
import { default as subscriptionPlanFactory, ISubscriptionPlan } from './models/subscriptionPlan';
import { default as tagFactory } from './models/tag';
import { default as teamFactory, ITeam } from './models/team';
import { ITeamMember } from './models/teamMember';
import { default as userFactory, IUser } from './models/user';
import * as nativeService from './nativeService';
import * as store from './store';
import { _s, StringKey } from './strings';
import * as upgradeHelper from './upgradeHelper';
import { DiagramRenderFlags } from './models/diagramModel';
import { SaveBatchIntent } from './apiGateway';

const HELP_SWITCHES_KEY = 'pmt-help-switches';
let _isDev = false;

/**
 * Bootstrap the application in preparation for first render. Resolves null or hangs

 *
 * @param      {store.IAppConfig}  config      The configuration
 * @param      {StringDictionary}  initParams  The initialize parameters
 */
export async function bootstrap(config: store.IAppConfig, initParams: StringDictionary) {
	_isDev = config.environment === 'development';
	// this is placed first as a hacky work-around to a problem on iOS devices (version 14) where the indexedDB connection never seems to resolve if it is initialized later
	clientStorage.init();

	await store.mutate((appState) => {
		const viewState: store.IViewState = assign({}, appState.viewState);

		viewState.bootstrapping = true;
		viewState.bootstrapStage = 'init';
		viewState.bootstrapMessage = _isDev ? 'Apple Sucks' : 'Initializing';
		viewState.initParams = initParams;
		viewState.initialLocationSearch = location.search;
		appState.viewState = viewState;
	});

	upgradeHelper.init(config.updateCheckInterval, config.virtualRoot, pushAlert, () => {
		const appState = store.appState();

		// this lets upgradeHelper know whether or not app UI is available or if native alerts should be used
		return !appState.viewState.bootstrapping || ['init', 'cacheUpdate'].indexOf(appState.viewState.bootstrapStage) === -1;
	});

	connectionMonitor.init({ pushModal });

	// eslint-disable-next-line no-async-promise-executor
	const bootstrapPromise = new Promise(async (resolve) => {
		let finishCalled = false;
		const finishHandler = async () => {
			if (!finishCalled) {
				finishCalled = true;
				store.off(finishHandler);

				const apiState = store.appState().viewState.api;

				if (_isDev) {
					await store.mutate((appState) => {
						appState.viewState.bootstrapMessage = `api initialized ${apiState.initialized}`;
					});
				}

				if (apiState.initialized) {

					_finishBootstrap();

					resolve(null);
				}
			}
		};

		await store.mutate((appState) => {
			const viewState: store.IViewState = assign({}, appState.viewState);
			const platform = (config as any).platform;

			viewState.platform = assign({}, viewState.platform);

			if (platform) {
				delete (config as any).platform;

				viewState.platform.os = platform.os;
				viewState.platform.isWrapped = platform.isWrapped;
			}

			viewState.config = config;
			viewState.platform.userAgent = navigator.userAgent;

			appState.viewState = viewState;
		});

		// the api mutates viewState.api.initialized
		store.on(store.MUTATED, finishHandler);

		logger.init(config);
		dataService.init(config.version, config.environment as 'development' | 'production');
		nativeService.init(config.version);
	});

	return bootstrapPromise;
}

export async function fetchOrphanedIaps(force = false) {
	const viewState = store.appState().viewState;
	const { platform } = viewState;

	if (platform.supportsIap || force) {
		const orphanedIapResult = await nativeService.fetchOrphanedIaps();

		if (orphanedIapResult.error) {
			logger.logError(orphanedIapResult, false);
		} else {
			await store.mutate((appState) => {
				// nativeService.debug(`bootstrap orphaned iaps`);

				appState.viewState = assign({}, appState.viewState);

				appState.viewState.orphanedIaps = orphanedIapResult.transactions;
			});
		}
	}

}

async function _finishBootstrap() {
	const hasWindow = typeof window !== 'undefined';

	if (await nativeService.waitForChannel()) {
		// nativeService.debug(`bootstrap nativeService`);
		const metadata = nativeService.getMetadata();

		await store.mutateAsync(async (appState) => {
			nativeService.debug(`bootstrap native platform`);

			appState.viewState = assign({}, appState.viewState);
			appState.viewState.platform = assign({}, appState.viewState.platform);

			appState.viewState.platform.type = 'mobile';
			appState.viewState.platform.isWrapped = true;
			appState.viewState.platform.os = metadata.os;
			appState.viewState.platform.version = metadata.version;
			appState.viewState.platform.supportsIap = !!metadata.supportsIap;
			appState.viewState.platform.isTrolledGarden = !!metadata.supportsIap; // DAR - not sure we need both supportsIap and isTrolledGarden. leaving for now

			if (_isDev) {
				appState.viewState.bootstrapMessage = 'connected to native wrapper';
			}

			// logger.set({ native_version: metadata.version });

			apiGateway.updateMetadata({ platform: metadata.os });

			nativeService.notifyBootstrapped();
		});

		if (metadata.supportsIap) {
			fetchOrphanedIaps(true);
		}
	}

	await store.mutate((appState) => {
		appState.viewState = assign({}, appState.viewState);

		appState.viewState.bootstrapStage = 'cacheUpdate';

		if (_isDev) {
			appState.viewState.bootstrapMessage = 'updating offline cache';
		}
	});

	const { currentTeam } =  await _updateOfflineCache();
	const apiState = store.appState().viewState.api;

	if (apiState.canAccessApi) {
		// await dataService.fetchConfig();
		// dataService.fetchBanners();  // don't await this

		// if (authToken && !currentUser) {
		// 	await dataService.fetchUser('me'); // this sets the current user as a side-effect
		// }
		await fetchPlans();
	}

	await store.mutate((appState) => {
		nativeService.debug(`bootstrap helpSwitches`);
		const switches = Number(localStorage.getItem(HELP_SWITCHES_KEY) || '0');

		appState.viewState = assign({}, appState.viewState);

		appState.viewState.helpSwitches = switches;
		appState.viewState.bootstrapStage = 'fetch';
		appState.viewState.bootstrapMessage = _s(StringKey.LOADER_MESSAGE_SYNC);
	});

	if (!apiState.canAccessApi && currentTeam) {
		await dataService.setCurrentTeam(currentTeam, true);
	}
	else if(apiState.canAccessApi) {
		if(currentTeam) {
			await store.mutateAsync(async (appState) => {
				appState.viewState = assign({}, appState.viewState);
				appState.viewState.currentTeamId = currentTeam.id;
			});
		}

		// be sure to persist any unsynced data and load 
		await dataService.resync('resync-bootstrap');
	}

	// logger.set({ api_version: apiState.currentVersion });

	await store.mutate((appState) => {
		// nativeService.debug(`bootstrap finalize`);
		if (_isDev) {
			appState.viewState.bootstrapMessage = 'finishing bootstrap';
		}
		const viewState: store.IViewState = assign({}, appState.viewState);

		viewState.browserContext = assign({}, viewState.browserContext);
		viewState.browserContext.windowSize = hasWindow ? { width: window.innerWidth, height: window.innerHeight, top: 0, left: 0 } : undefined;

		viewState.bootstrapStage = null;
		viewState.bootstrapping = false;
		appState.viewState = viewState;
	});

	if (hasWindow) {
		window.removeEventListener('resize', monitorWindowSize); // just in case something has caused the bootstap process to run more than once
		window.addEventListener('resize', monitorWindowSize);
	}
}

async function monitorWindowSize() {
	await store.mutate((appState) => {
		const viewState: store.IViewState = assign({}, appState.viewState);

		viewState.browserContext = assign({}, viewState.browserContext);
		viewState.browserContext.windowSize = { width: window.innerWidth, height: window.innerHeight, top: 0, left: 0 };

		appState.viewState = viewState;
	});
}

export async function fetchPlan(code: string): Promise<ISubscriptionPlan | boolean> {
	if (!_assertCanAccessApi()) {
		return false;
	}

	const response = await apiGateway.fetchPlan(code);
	const data = (response as any).subscriptionPlan;

	return data && subscriptionPlanFactory(data);
}

// fetchPlans caches availableSubscriptionPlans so that offline users can see the candy in the store
export async function fetchPlans() {
	const viewState = store.appState().viewState;
	const { platform } = viewState;
	const apiState = viewState.api;

	if (apiState.canAccessApi) {
		const response = await apiGateway.fetchPlans();
		const data = (response as any).subscriptionPlans;
		const sortedResponse = sortBy(data, 'price');
		const results = [];
		const iapProductIds = [];

		for (const plan of sortedResponse) {
			const planModel: ISubscriptionPlan = subscriptionPlanFactory(plan);
			const iapTroll = planModel.getIapTroll();

			results.push(planModel);

			if (iapTroll) {
				iapProductIds.push(iapTroll.productId);
			}
		}

		if (nativeService.isConnected() && platform.supportsIap) {
			const nativeProductResult = await nativeService.fetchIapProducts(iapProductIds);

			for (const iapProduct of nativeProductResult.products) {
				const plan = find(results, (p) => p.settings && p.settings.iapTroll && p.settings.iapTroll.productId === iapProduct.id);
				if (plan) {
					plan.iapProduct = iapProduct;
				}
			}
		}

		await clientStorage.setItem('viewState.availableSubscriptionPlans', sortedResponse);

		await store.mutate((appState) => {
			nativeService.debug(`bootstrap available plans`);
			appState.viewState = assign({}, appState.viewState);

			appState.viewState.availableSubscriptionPlans = results;
			if (_isDev) {
				appState.viewState.bootstrapMessage = 'plans fetched';
			}
		});
	}
}

export async function fetchTeamByInviteCode(code: string) {
	if (!_assertCanAccessApi()) {
		return false;
	}

	const result = await apiGateway.fetchInvite(code);
	const data = (result as any).team;

	if (data) {
		logger.logEvent('accept-invite', 'onboarding', data.currentSubscription && data.currentSubscription.name);
	} else {
		logger.logEvent('accept-invite-failed', 'onboarding');
		pushAlert({ message: _s(StringKey.INVITE_LOOKUP_FAILED_ALERT_MESSAGE), title: _s(StringKey.INVITE_LOOKUP_FAILED_ALERT), severity: store.AlertSeverity.error });
	}

	return data && teamFactory(data);
}

export async function redeemCode(code: string) {
	if (!_assertCanAccessApi()) {
		return false;
	}

	const result = await dataService.redeemItem(code);
	const error = (result as any).error;

	if (error) {
		if (error.message === 'Unsupported redemption code') {
			error.message = _s(StringKey.REDEMPTION_FAILED_MESSAGE); // TODO: consider a special message for team invite failures here
		} else {
			error.message = _s(StringKey.REDEMPTION_FAILED_MESSAGE);
		}
	} else if ((result as any).type === 'migration' && !(result as any).url) {
		(result as any).error = {
			message: _s(StringKey.REDEMPTION_FAILED_MESSAGE),
		};
	}

	return result;
}

export function processRedemptionResult(result) {
	if (result.error) {
		pushAlert({ message: _s(StringKey.REDEMPTION_FAILED_MESSAGE), title: _s(StringKey.INVALID_CODE), severity: store.AlertSeverity.error });
	} else {
		if (result.type === 'purchase') {
			if (result.subType === 'playpack') {
				pushAlert({ title: _s(StringKey.IMPORT_COMPLETE), message: _s(StringKey.IMPORT_SUCCESS_MESSAGE_PLAYPACK), severity: store.AlertSeverity.confirmation, mode: store.AlertMode.prompt });
			} else if (result.subType === 'subscription') {
				pushAlert({ title: _s(StringKey.SUCCESS), message: _s(StringKey.BILLING_SUBSCRIPTION_SUCCESS_MESSAGE), severity: store.AlertSeverity.confirmation, mode: store.AlertMode.prompt });
			}
		} else if (result.type === 'subscription') { // this is a sponsored plan
			const { currentSubscription } = result.team;
			const logoAsset = find(currentSubscription.settings.assets, { key: 'sponsorLogo'});

			pushModal({
				id: 'sponsor_modal',
				component: SponsorModal,
				props: { message: currentSubscription.settings.sponsorMessage, tribute: currentSubscription.settings.sponsorTribute, logoUrl: logoAsset && logoAsset.url, actions: currentSubscription.settings.sponsorActions },
			});
		} else {
			pushAlert({ title: _s(StringKey.IMPORT_COMPLETE), message: _s(StringKey.IMPORT_SUCCESS_MESSAGE_PLAYBOOK), severity: store.AlertSeverity.confirmation, mode: store.AlertMode.prompt });
		}
	}
}

export async function isValidIapPlan(plan: ISubscriptionPlan) {
	const iapTroll = plan.getIapTroll();
	if (iapTroll) {
		const productId = iapTroll.productId;
		const result = await nativeService.fetchIapProducts([productId]);

		return !result.invalidIds || result.invalidIds.indexOf(productId) === -1;
	} else {
		return false;
	}
}

export async function makeIap(productId: string, teamId: string): Promise<{ transactionId?: string, productId?: string, teamId?: string, receipt?: string, error?: string }> {
	return await nativeService.makeIap(productId, teamId);
}

// call after receipt
export async function finishIap(transactionId: string) {
	await nativeService.finishIap(transactionId);
}

export async function login(email: string, password: string, inviteCode?: string) {
	if (!_assertCanAccessApi()) {
		return false;
	}

	const response = await dataService.login(email, password, inviteCode);

	if (response) {
		if (response.error) {
			if ((response.error as any).status === 401) {
				pushAlert({ message: _s(StringKey.LOGIN_FAILED_ALERT_MESSAGE), title: _s(StringKey.LOGIN_FAILED_ALERT), severity: store.AlertSeverity.error });
			} else {
				pushAlert({ message: _s(StringKey.SOMETHING_WENT_WRONG_ALERT_MESSAGE), title: _s(StringKey.LOGIN_FAILED_ALERT_MESSAGE), severity: store.AlertSeverity.error });
			}
		}
	}

	// const { model, viewState } = store.appState();
	// const currentTeam = model.teams[viewState.currentTeamId];
	// const currentUser = model.users[viewState.currentUserId];

	// if (currentUser && currentTeam) {
	// 	const teamMember: ITeamMember = find(currentTeam.members.values, { userId: currentUser.id });
	// 	const plan_name = currentTeam.currentSubscription.name;
	// 	const plan_level = currentTeam.currentSubscription.getPlanLevel();
	// 	const team_id = currentTeam.id;
	// 	const team_role = currentTeam.createdById === currentUser.id ? 'Owner' : TeamRole[teamMember.role];

	// 	logger.set( { plan_name, plan_level, team_id, team_role} );
	// }

	return response;
}

export async function logout() {
	await dataService.logout();
	// logger.set( { plan_name: '', plan_level: '', team_id: '', team_role: ''} );
}

export async function unimpersonate() {
	if (!_assertCanAccessApi()) {
		return false;
	}

	await dataService.unimpersonate();
}

export function print() {
	logger.logEvent('print-preview', 'feature');
	if (nativeService.isConnected()) {
		nativeService.print();
	} else {
		window.print();
	}
}

export function browseTo(url: string) {
	if (nativeService.isConnected()) {
		nativeService.browseTo(url);
	} else {
		window.open(url, '_blank');
	}
}

// Requires viewState.api.canAccessApi
export async function createAccount(user: IUser, team: ITeam, password: string, inviteCode?: string) {
	if (!_assertCanAccessApi()) {
		return false;
	}

	const alerts = await dataService.createAccount(user, team, password, inviteCode);

	if (alerts.length) {
		for (const alert of alerts) {
			pushAlert(alert);
		}
	}
}

export async function saveUser(user: IUser, saveCallback?: dataService.SaveCallback) {
	if (!user.isValid()) {
		return;
	}

	dataService.saveUser({ data: user, saveCallback });
}

// Requires viewState.api.canAccessApi
export async function addTeam(team: ITeam) {
	if (!_assertCanAccessApi()) {
		return false;
	}

	if (!team.isValid()) {
		return;
	}

	await dataService.addTeam(team);
}

export async function inviteMembers(team: ITeam, inviteSpecs: StringDictionary) {
	if (!_assertCanAccessApi()) {
		return false;
	}

	const response = await dataService.inviteMembers(team, inviteSpecs);

	if (find(response.results, { isError: true })) {
		const errorMessage = response.results[0].messages.join(' &bull; ');
		pushAlert({ message: errorMessage, title: _s(StringKey.TEAM_INVITE_FAILED), severity: store.AlertSeverity.error, mode: store.AlertMode.prompt });
	} else {
		pushAlert({ message: _s(StringKey.TEAM_INVITE_SENT_MESSAGE), title: _s(StringKey.TEAM_INVITE_SENT), severity: store.AlertSeverity.confirmation, mode: store.AlertMode.prompt });
	}
}

// Requires viewState.api.canAccessApi
export async function addSubscription(plan: ISubscriptionPlan, subscriptionData: dataService.ISubscriptionData) {
	if (!_assertCanAccessApi()) {
		return false;
	}

	const response = await dataService.addSubscription(plan, subscriptionData);

	if ((response as any).error) {
		return [_s(StringKey.FAILED_TO_ADD_SUBSCRIPTION_ALERT)];
	}

	if (find(response.results, { isError: true })) {
		return response.results[0].messages;
		// pushAlert({ message: response.results[0].messages.join(' &bull; '), title: 'Subscription Processing Failed', severity: store.AlertSeverity.error });
	}

	return [];
}

// Requires viewState.api.canAccessApi
export async function updateSubscriptionPayment(subscription: ISubscription, paymentData: dataService.IPaymentData) {
	if (!_assertCanAccessApi()) {
		return false;
	}

	const response = await dataService.updateSubscription({ teamId: subscription.teamId, paymentData });

	if ((response as any).error) {
		return [_s(StringKey.FAILED_TO_UPDATE_PAYMENT_ALERT)];
	}

	if (find(response.results, { isError: true })) {
		return response.results[0].messages;
		// pushAlert({ message: response.results[0].messages.join(' &bull; '), title: 'Subscription Update Failed', severity: store.AlertSeverity.error });
	}

	return [];
}

// Requires viewState.api.canAccessApi
export async function pauseSubscription(subscription: ISubscription, pendingSubscription: ISubscription, pause: boolean) {
	if (!_assertCanAccessApi()) {
		return false;
	}

	if ((pause && subscription.expirationDate && !pendingSubscription) || !pause && subscription.dueDate) {
		return [];
	}

	const response = await dataService.updateSubscription({ teamId: subscription.teamId, pause: !!pause });

	if ((response as any).error) {
		return [_s(StringKey.FAILED_TO_PAUSE_SUBSCRIPTION_ALERT)];
	}

	if (find(response.results, { isError: true })) {
		return response.results[0].messages;
		// pushAlert({ message: response.results[0].messages.join(' &bull; '), title: 'Subscription Update Failed', severity: store.AlertSeverity.error });
	}

	return [];
}

// Requires viewState.api.canAccessApi
export async function upgradeSubscription(plan: ISubscriptionPlan, subscriptionData: dataService.ISubscriptionData) {
	if (!_assertCanAccessApi()) {
		return false;
	}

	const response = await dataService.upgradeSubscription(plan, subscriptionData);

	if ((response as any).error) {
		return [_s(StringKey.FAILED_TO_UPDATE_SUBSCRIPTION_ALERT)];
	}

	if (find(response.results, { isError: true })) {
		return response.results[0].messages;
		// pushAlert({ message: response.results[0].messages.join(' &bull; '), title: 'Subscription Upgrade Failed', severity: store.AlertSeverity.error });
	}

	return [];
}

export async function archivePlaybook(playbook: IPlaybook) {
	if (!_assertCanAccessApi(store.AlertMode.prompt)) {
		return false;
	}

	const result = await dataService.archivePlaybook(playbook);

	if (result && result.error) {
		pushAlert({ message: _s(StringKey.SOMETHING_WENT_WRONG_ALERT_MESSAGE), title: _s(StringKey.ERROR), mode: store.AlertMode.prompt, severity: store.AlertSeverity.error});
		return false;
	}

	return true;
}

export async function unarchivePlaybook(playbook: IPlaybook) {
	if (!_assertCanAccessApi(store.AlertMode.prompt)) {
		return false;
	}

	const result = await dataService.unarchivePlaybook(playbook);

	if (result && result.error) {
		pushAlert({ message: _s(StringKey.SOMETHING_WENT_WRONG_ALERT_MESSAGE), title: _s(StringKey.ERROR), mode: store.AlertMode.prompt, severity: store.AlertSeverity.error});
		return false;
	}

	return true;
}

export async function saveTeam(team: ITeam, intent: SaveBatchIntent, formations: IFormation[] = null) {
	if (!team.isValid()) {
		return;
	}

	await dataService.saveTeam({ data: team, formations, intent });
}

export function validatePlaybook(playbook: IPlaybook, messageFilter?: (string) => boolean) {
	const validationMessages = playbook.test();

	if (validationMessages && validationMessages.length) {
		const filtered = messageFilter ? filter(validationMessages, messageFilter) : validationMessages;

		if (filtered.length) {
			pushAlert({ message: filtered.join(' &bull; '), title: 'Playbook Save Failed', severity: store.AlertSeverity.error });
			return false;
		}
	}

	return true;
}

export function validateTeam(team: ITeam) {
	const validationMessages = team.test();

	if (validationMessages && validationMessages.length) {
		pushAlert({ message: validationMessages.join(' &bull; '), title: 'Team Save Failed', severity: store.AlertSeverity.error });
		return false;
	}

	return true;
}

export function validateTeamMember(teamMember: ITeamMember) {
	const validationMessages = teamMember.test();

	if (validationMessages && validationMessages.length) {
		pushAlert({ message: validationMessages.join(' &bull; '), title: 'Team Member Save Failed', severity: store.AlertSeverity.error });
		return false;
	}

	return true;
}

export function validateFormation(formation: IFormation, alert = true) {
	const validationMessages: string[] = [];
	const existingWithSameLabel = find(store.appState().model.formations, (f) => f.playbookId === formation.playbookId && f.phase === formation.phase && f.label && formation.label && f.label.toLowerCase() === formation.label.toLowerCase() );

	if (existingWithSameLabel && existingWithSameLabel.id !== formation.id) {
		validationMessages.push(_s(StringKey.ANOTHER_FORMATION_EXISTS_MESSAGE));
	}

	formation.test(null, validationMessages);

	if (validationMessages.length) {
		if (alert) {
			pushAlert({ message: validationMessages.join(' &bull; '), title: _s(StringKey.CANNOT_SAVE_FORMATION_MESSAGE), severity: store.AlertSeverity.error });
		}
		return false;
	}

	return true;
}

export function validatePlay(play: IPlay, alert = true) {
	const validationMessages: string[] = [];
	// const existingWithSameLabel = find(model.plays, { playbookId: play.playbookId, phase: play.phase, label: play.label });

	// if (existingWithSameLabel && existingWithSameLabel.id !== play.id) {
	// 	validationMessages.push('Another play exists with this name');
	// }

	play.test(null, validationMessages);

	if (validationMessages.length) {
		if (alert) {
			pushAlert({ message: validationMessages.join(' &bull; '), title: _s(StringKey.CANNOT_SAVE_PLAY_MESSAGE), severity: store.AlertSeverity.error });
		}
		return false;
	}

	return true;
}

export async function addFormation(formation: IFormation) {
	const validationMessages = formation.test();

	if (validationMessages && validationMessages.length) {
		pushAlert({ message: validationMessages.join(' &bull; '), title: _s(StringKey.FORMATION_SAVE_FAILED_MESSAGE), severity: store.AlertSeverity.error });
		return;
	}

	await dataService.addFormation({ data: formation });
}

export async function saveFormation(formation: IFormation) {
	if (!formation.isValid()) {
		return;
	}

	await dataService.saveFormation({ data: formation });
}

export async function saveFormations(formations: IFormation[]) {
	if(!formations?.length || !formations.every(f => f.isValid())) {
		return;
	}

	await dataService.saveFormations({ data: formations });
}

export async function deleteFormation(formation: IFormation) {
	await dataService.deleteFormation({ data: formation });
}

export async function applyFormation(formation: Parameters<typeof dataService.applyFormation>[0], options: Parameters<typeof dataService.applyFormation>[1]) {
	if (!formation.isValid()) {
		return;
	}

	await dataService.applyFormation(formation, options);
}

export async function addPlay(play: IPlay) {
	if (!play.isValid()) {
		return;
	}

	setLastCreatedDiagramId(play.id);

	await dataService.addPlay({data: play});
}

export async function savePlay(play: IPlay) {
	if (!play.isValid()) {
		return;
	}

	await dataService.savePlay({data: play});
}

export async function savePlays(plays: IPlay[]) {
	for (const play of plays) {
		if (!play.isValid()) {
			return;
		}
	}

	await dataService.savePlays({data: plays});
}

function normalizeString(val: string) {
	return val ? val.toLowerCase() : '';
}

export async function copyPlaybook(playbook: IPlaybook) {
	const { model } = store.appState();
	const targetTeam: ITeam = find(model.teams, { id: playbook.teamId });

	if (targetTeam.id === playbook.teamId) { // be sure we actually found a team
		const formations = filter(model.formations, { playbookId: playbook.id });
		const plays = filter(model.plays, { playbookId: playbook.id });
		const clone = playbook.clone();
		let playbookName = clone.name;
		let playbookNameCount = 1;

		if (find(targetTeam.playbooks, { name: clone.name })) {
			playbookName += ` ${_s(StringKey.COPY)}`;
			clone.name = playbookName;
		}

		while (find(targetTeam.playbooks.values, (p) => normalizeString(p.name) === normalizeString(playbookName))) {
			playbookNameCount++;
			playbookName = `${clone.name} ${playbookNameCount}`;
		}

		clone.name = playbookName;

		const copiedFormations = await copyFormations(formations, [clone], true);
		const copiedPlays = await copyPlays(plays, [clone], copiedFormations, true);

		await dataService.addFullPlaybook({ playbook: clone, formations: copiedFormations, plays: copiedPlays, preservePermissions: true, intent: 'playbook-copy' });

		pushAlert({
			title: _s(StringKey.PLAYBOOK_COPIED),
			message: _s(StringKey.PLAYBOOK_COPIED_MESSAGE_TEMPLATE).replace('{name}', clone.name),
			mode: store.AlertMode.prompt,
			severity: store.AlertSeverity.confirmation,
			actions: [{
				label: _s(StringKey.OK),
				className: '',
				action: popModal,
			}],
		});
	} else {
		pushAlert({
			title: _s(StringKey.PLAYBOOK_COPY_FAILED_ALERT),
			message: _s(StringKey.PLAYBOOK_COPY_FAILED_ALERT_MESSAGE),
			mode: store.AlertMode.prompt,
			severity: store.AlertSeverity.warning,
			actions: [{
				label: _s(StringKey.OK),
				className: '',
				action: popModal,
			}],
		});
	}
}

// NOTE: does not handle import - use playbookHelper
// NOTE: assumes that target playbooks are already in the store
export async function copyFormations(formations: IFormation[], playbooks: IPlaybook[], bypassSave = false) {
	const { model } = store.appState();
	const sortedFormations = sortBy(formations, 'sortIndex');
	const copied = [];
	const exisingFormations: IFormation[] = filter(model.formations, () => true); // create an array

	for (const targetPlaybook of playbooks) {
		for (const formation of sortedFormations) {
			const phaseFormations = filter(exisingFormations, { playbookId: targetPlaybook.id, phase: formation.phase });
			const clone: IFormation & ISortable = formation.clone();
			let formationLabel = clone.label;
			let copyCount = 0;

			clone.playbookId = targetPlaybook.id;
			clone.teamId = targetPlaybook.teamId;
			clone.sortIndex = phaseFormations.length;

			while (find(phaseFormations, { playbookId: targetPlaybook.id, label: formationLabel })) {
				if (copyCount === 0) {
					formationLabel = `${clone.label} ${_s(StringKey.COPY)}`;
				} else {
					formationLabel = `${clone.label} ${_s(StringKey.COPY)} ${copyCount + 1}`;
				}

				copyCount++;
			}

			clone.label = formationLabel;

			copied.push(clone);
			exisingFormations.push(clone);
		}
	}

	if (!bypassSave) {
		await dataService.addFormations({ data: copied, intent: 'formation-copy-multi' });
	}

	return copied;
}

// NOTE: does not handle import - use playbookHelper
// any formations added as a side effect will be pushed into existingFormations
export async function copyPlays(plays: IPlay[], playbooks: IPlaybook[], exisingFormations= undefined, isPlaybookCopy= false) {
	const { model } = store.appState();
	const mutatedTeams = {}; // these only exist if copying between playbooks or into a new playbook
	const existingPlays = filter(model.plays, () => true); // copy any array of values
	const sortedPlays = sortBy(plays, 'sortIndex');
	const formationsToAdd = [];
	const playsToAdd = [];
	const storeFormations = filter(model.formations, () => true); // copy any array of values;

	const getMutatingTeam = (targetPlaybook: IPlaybook) => {
		const mutatedTeam: ITeam = mutatedTeams[targetPlaybook.teamId] || teamFactory(find(model.teams, { id: targetPlaybook.teamId }));

		mutatedTeams[targetPlaybook.teamId] = mutatedTeam;

		if (!mutatedTeam.playbooks[targetPlaybook.id]) {
			mutatedTeam.playbooks[targetPlaybook.id] = targetPlaybook;
		}

		return mutatedTeam;
	};

	const updatePlaylist = (play, clone, sourcePlaylist, mutatingPlaybook, targetRelatedId) => {
		if (sourcePlaylist && sourcePlaylist.playIds) {
			let targetPlaylist = find(mutatingPlaybook.settings.playLists.values, (pl) => pl.relatedId === targetRelatedId);

			if (!targetPlaylist) {
				targetPlaylist = mutatingPlaybook.settings.playLists.createNew({ relatedId: targetRelatedId, playIds: sourcePlaylist.playIds });
				mutatingPlaybook.settings.playLists.add(targetPlaylist);
			}

			if (isPlaybookCopy) {
				targetPlaylist.playIds = targetPlaylist.playIds.replace(play.id, clone.id);
			} else {
				targetPlaylist.push(clone.id);
			}
		}
	};

	for (const targetPlaybook of playbooks) {

		for (const play of sortedPlays) {
			// wait until we've ensured this play can be copied to the playbook before initiating any mutations
			if (targetPlaybook.playersPerSide !== play.playersPerSide) {
				console.warn('attempt to copy incompatible play');
				continue;
			}

			const sourceTeam: ITeam = find(model.teams, (t: ITeam) => some(t.playbooks, { id: play.playbookId }));
			const sourcePlaybook = sourceTeam.playbooks[play.playbookId];
			// const sourceSortedCategories = sortBy(sourcePlaybook.settings.categories.values, 'sortIndex');
			const mutatingTeam = getMutatingTeam(targetPlaybook); // if the playbook isn't a part of the mutated team it will be added
			const mutatingPlaybook: IPlaybook = mutatingTeam.playbooks[targetPlaybook.id];
			const mutatingPlaybookInitialCategoryCount = mutatingPlaybook.settings.categories.values.length;
			const phasePlays = filter(existingPlays, { playbookId: targetPlaybook.id, phase: play.phase });
			const clone: IPlay = play.clone();
			let playLabel = clone.label;
			let copyCount = 0;

			clone.playbookId = targetPlaybook.id;
			clone.teamId = targetPlaybook.teamId;
			clone.sortIndex = phasePlays.length;

			while (find(phasePlays, { playbookId: mutatingPlaybook.id, label: playLabel })) {
				if (copyCount === 0) {
					playLabel = `${clone.label} ${_s(StringKey.COPY)}`;
				} else {
					playLabel = `${clone.label} ${_s(StringKey.COPY)} ${copyCount + 1}`;
				}

				copyCount++;
			}

			clone.label = playLabel;

			// this is copying between playbooks, which may not exist in the store
			if (clone.playbookId !== play.playbookId) {
				const searchFormations = exisingFormations ? storeFormations.concat(exisingFormations, formationsToAdd) : storeFormations.concat(formationsToAdd);
				const sourceFormation = find(searchFormations, (f) => f.playbookId === play.playbookId && f.phase === play.phase && f.label.toLowerCase() === play.formation.toLowerCase());
				const sourceFormationPlaylist = sourceFormation && find(sourcePlaybook.settings.playLists.values, (pl) => pl.relatedId === sourceFormation.id);
				let targetFormation: IFormation = find(searchFormations, (f) => f.playbookId === clone.playbookId && f.phase === clone.phase && f.label.toLowerCase() === clone.formation.toLowerCase() );

				// ensure the existence of a valid formation in the targetPlaybook and migrate any playlist
				if (!targetFormation) {
					if (sourceFormation) {
						const copiedFormations = await copyFormations([sourceFormation], [targetPlaybook], true);

						targetFormation = copiedFormations[0];

					} else {
						targetFormation = formationFactory({ playbookId: clone.playbookId, label: clone.formation, phase: clone.phase, teamId: clone.teamId, mates: map(clone.mates.values, (mate) => mate.toDocument(['/id', '/itemVersion', '/createdById', '/dateCreated', '/lastModifiedById', '/dateLastModified', '/deleted', '/route']))});
					}

					formationsToAdd.push(targetFormation);
					if (exisingFormations) {
						exisingFormations.push(targetFormation);
					}

				}

				// be sure that the clone properly maps to the target formation
				clone.formation = targetFormation.label;
				updatePlaylist(play, clone, sourceFormationPlaylist, mutatingPlaybook, targetFormation.id);

				// transfer categories and ensure category playLists
				const cloneCategories: string[] = [];

				for (const categoryId of clone.categoryList) {
					const originalCategory = find(sourcePlaybook.settings.categories.values, { id: categoryId });
					const sourceCategoryPlaylist = originalCategory && find(sourcePlaybook.settings.playLists.values, (pl) => pl.relatedId === originalCategory.id);
					let targetCategory;

					if (originalCategory) {
						targetCategory = find(mutatingPlaybook.settings.categories.values, { label: originalCategory.label, phase: originalCategory.phase });

						if (!targetCategory) {
							targetCategory = tagFactory({ label: originalCategory.label, phase: originalCategory.phase, sortIndex: mutatingPlaybookInitialCategoryCount + originalCategory.sortIndex });
							mutatingPlaybook.settings.categories.add(targetCategory);
						}

						cloneCategories.push(targetCategory.id);
					}

					updatePlaylist(play, clone, sourceCategoryPlaylist, mutatingPlaybook, targetCategory.id);
				}

				clone.categories = cloneCategories.length ? cloneCategories.join(',') : undefined;
			}

			existingPlays.push(clone);

			playsToAdd.push(clone);
		}
	}

	if (!isPlaybookCopy) {
		const mutatedTeamList = filter(mutatedTeams, () => true);

		for (const t of mutatedTeamList) {
			await dataService.saveTeam({data: t, intent: 'play-copy-multi'});
		}

		await dataService.addFormations({ data: formationsToAdd, intent: 'play-copy-multi' });
		await dataService.addPlays({ data: playsToAdd, intent: 'play-copy-multi' });
	}

	return playsToAdd;
}

export async function addFullPlaybook(playbook: IPlaybook, formations: IFormation[], plays: IPlay[], intent: SaveBatchIntent) {
	await dataService.addFullPlaybook({ playbook, formations, plays, intent });
}

export async function updatePersonnelGroup(plays: IPlay[], personnelGroupId: string) {
	for (const play of plays) {
		const mutated = playFactory(play.toDocument());

		mutated.activePersonnelGroup = personnelGroupId;
		await dataService.savePlay({ data: mutated, intent: 'personnel-update' });
	}
}

export async function deletePlay(play: IPlay) {
	await dataService.deletePlay({ data: play });
}

export async function deletePlays(plays: IPlay[]) {
	await dataService.deletePlays({ data: plays });
}

export async function updatePassword(oldPassword: string, newPassword: string) {
	if (!_assertCanAccessApi()) {
		return false;
	}

	const updateResponse = await dataService.updatePassword(oldPassword, newPassword);

	if ((updateResponse as any).error) {
		pushAlert({ message: _s(StringKey.PASSWORD_CHANGE_FAILED_ALERT_MESSAGE), title: _s(StringKey.PASSWORD_CHANGE_FAILED_ALERT), severity: store.AlertSeverity.error });
	}
}

export async function requestPasswordReset(email: string) {
	if (!_assertCanAccessApi()) {
		return false;
	}
	const result = await dataService.requestPasswordReset(email);

	if ((result as any).error && (result as any).error.message === 'UnrecognizedAccount') {
		pushAlert({ message: _s(StringKey.UNRECOGNIZED_EMAIL_ALERT_MESSAGE), title: _s(StringKey.UNRECOGNIZED_EMAIL_ALERT), severity: store.AlertSeverity.error });
	}
}

export function toggleMainMenu() {
	store.mutate((appState) => {
		appState.viewState = assign({}, appState.viewState);
		appState.viewState.mainMenu = assign({}, appState.viewState.mainMenu);
		appState.viewState.mainMenu.expanded = !appState.viewState.mainMenu.expanded;
	});
}

export function setGlobalDiagramFlags(val: DiagramRenderFlags) {
	store.mutate((appState) => {
		appState.viewState = assign({}, appState.viewState);
		appState.viewState.globalDiagramFlags = val;
	});
}

export async function setCurrentTeam(team: ITeam) {
	await dataService.setCurrentTeam(team);
}

export async function clearCurrentTeam() {
	await dataService.clearCurrentTeam();
}

export function setLastViewedPlayId(playId: string) {
	store.mutate((appState) => {
		appState.viewState = assign({}, appState.viewState);
		appState.viewState.lastViewedPlayId = playId;
	});
}

export function setLastCreatedDiagramId(playId: string) {
	store.mutate((appState) => {
		appState.viewState = assign({}, appState.viewState);
		appState.viewState.lastCreatedDiagramId = playId;
	});
}

export function pushModal(modal: store.IModal, state?: store.IAppState) {
	const mutator = (appState) => {
		appState.viewState = assign({}, appState.viewState);
		appState.viewState.modals = [].concat(appState.viewState.modals);

		if (appState.viewState.mainMenu.expanded) {
			appState.viewState.mainMenu = assign({}, appState.viewState.mainMenu);
			appState.viewState.mainMenu.expanded = false;
		}

		const newModal = assign({ id: modal.id || createId(), inClass: 'in', outClass: 'out', props: modal.props || {}, supportsAlerts: true }, modal);

		appState.viewState.modals = appState.viewState.modals.concat();
		appState.viewState.modals.push(newModal);

		// clear any tooltips without flagging them as viewed:
		appState.viewState.tooltips = [];
	};

	if (!state) {
		return store.mutate(mutator);
	} else {
		mutator(state);
	}
}

export function popModal(e?) {
	if (e?.preventDefault) { e.preventDefault(); }
	return store.mutate((appState) => {
		appState.viewState = assign({}, appState.viewState);
		appState.viewState.modals = appState.viewState.modals.concat();
		appState.viewState.alerts = [];

		appState.viewState.modals.pop();
	});
}

export function pushDecoration(modal: store.IModal) {
	return store.mutate((appState) => {
		appState.viewState = assign({}, appState.viewState);
		appState.viewState.decorations = appState.viewState.decorations.concat();

		if (appState.viewState.mainMenu.expanded) {
			appState.viewState.mainMenu = assign({}, appState.viewState.mainMenu);
			appState.viewState.mainMenu.expanded = false;
		}

		const newModal = assign({ id: modal.id || createId(), inClass: 'in', outClass: 'out', props: modal.props || {}, supportsAlerts: false }, modal);

		appState.viewState.decorations.push(newModal);

		// clear any tooltips without flagging them as viewed:
		appState.viewState.tooltips = [];
	});
}

export function deleteDecoration(id: string) {
	return store.mutate((appState) => {
		appState.viewState = assign({}, appState.viewState);
		appState.viewState.decorations = appState.viewState.decorations.concat();

		const index = findIndex(appState.viewState.decorations, { id });

		if (index !== -1) {
			appState.viewState.decorations.splice(index, 1);
		}
	});
}

export function getDecoration(id: string) {
	return find(store.appState().viewState.decorations, { id });
}

export function updateDecorationProps(id: string, props: any) {
	store.mutate((appState) => {
		appState.viewState = assign({}, appState.viewState);
		appState.viewState.decorations = appState.viewState.decorations.concat();

		const decorations: store.IModal = find(appState.viewState.decorations, { id });

		if (decorations) {
			decorations.props = assign(decorations.props, props);
		}
	});
}

export function getModal(id: string) {
	return find(store.appState().viewState.modals, { id });
}

export function deleteModal(id: string) {
	return store.mutate((appState) => {
		appState.viewState = assign({}, appState.viewState);
		appState.viewState.modals = appState.viewState.modals.concat();

		const index = findIndex(appState.viewState.modals, { id });

		if (index !== -1) {
			appState.viewState.modals.splice(index, 1);
		}
	});
}

export function updateModalProps(id: string, props: any) {
	store.mutate((appState) => {
		appState.viewState = assign({}, appState.viewState);
		appState.viewState.modals = appState.viewState.modals.concat();

		const modal: store.IModal = find(appState.viewState.modals, { id });

		if (modal) {
			modal.props = assign(modal.props, props);
		}
	});
}

export function getPanel(id: string) {
	return find(store.appState().viewState.panels, { id });
}

export function pushPanel(panel: store.IModal) {
	return store.mutate((appState) => {
		appState.viewState = assign({}, appState.viewState);
		appState.viewState.panels = appState.viewState.panels.concat();

		const newPanel = assign({ id: panel.id || createId(), inClass: 'in', outClass: 'out', props: panel.props || {} }, panel);

		appState.viewState.panels.push(newPanel);

		// clear any tooltips without flagging them as viewed:
		appState.viewState.tooltips = [];
	});
}

export function popPanel(e?) {
	if (e) { e.preventDefault(); }
	return store.mutate((appState) => {
		appState.viewState = assign({}, appState.viewState);
		appState.viewState.panels = appState.viewState.panels.concat();
		appState.viewState.alerts = [];

		appState.viewState.panels.pop();
	});
}

export function deletePanel(id: string) {
	return store.mutate((appState) => {
		appState.viewState = assign({}, appState.viewState);
		appState.viewState.panels = appState.viewState.panels.concat();

		const index = findIndex(appState.viewState.panels, { id });

		if (index !== -1) {
			appState.viewState.panels.splice(index, 1);
		}
	});
}

export function updatePanelProps(id: string, props: any) {
	store.mutate((appState) => {
		appState.viewState = assign({}, appState.viewState);
		appState.viewState.panels = appState.viewState.panels.concat();

		const panel: store.IModal = find(appState.viewState.panels, { id });

		if (panel) {
			panel.props = assign(panel.props, props);
		}
	});
}

export function pushAlert(alert: store.IAlert) {
	store.mutate((appState) => {
		appState.viewState = assign({}, appState.viewState);
		appState.viewState.alerts = appState.viewState.alerts.concat();

		const newAlert = assign({ id: alert.id || createId(), mode: store.AlertMode.default }, alert);

		appState.viewState.alerts.push(newAlert);
	});
}

export function popAlert(e?) {
	if (e) { e.preventDefault(); }
	store.mutate((appState) => {
		appState.viewState = assign({}, appState.viewState);
		appState.viewState.alerts = appState.viewState.alerts.concat();

		appState.viewState.alerts.pop();
	});
}

export function deleteAlert(id: string) {
	store.mutate((appState) => {
		appState.viewState = assign({}, appState.viewState);
		appState.viewState.alerts = appState.viewState.alerts.concat();

		const index = findIndex(appState.viewState.alerts, { id });

		if (index !== -1) {
			appState.viewState.alerts.splice(index, 1);
		}
	});
}

export function clearAlerts() {
	store.mutate((appState) => {
		appState.viewState = assign({}, appState.viewState);
		appState.viewState.alerts = [];
	});
}

export function deleteTooltip(id: string) {
	store.mutate((appState) => {
		appState.viewState = assign({}, appState.viewState);
		appState.viewState.tooltips = appState.viewState.tooltips.concat();

		const index = findIndex(appState.viewState.tooltips, { id });

		if (index !== -1) {
			const tooltip = appState.viewState.tooltips[index];

			appState.viewState.tooltips.splice(index, 1);

			if (tooltip && tooltip.helpSwitch) {
				appState.viewState.helpSwitches = (appState.viewState.helpSwitches | tooltip.helpSwitch);
				localStorage.setItem(HELP_SWITCHES_KEY, appState.viewState.helpSwitches.toString());
			}
		}
	});
}

export function toggleShowFormationsAsList() {
	store.mutate((appState) => {
		appState.viewState = assign({}, appState.viewState);

		appState.viewState.showFormationsAsList = !appState.viewState.showFormationsAsList;
	});
}

export function toggleShowPlaysAsList() {
	store.mutate((appState) => {
		appState.viewState = assign({}, appState.viewState);

		appState.viewState.showPlaysAsList = !appState.viewState.showPlaysAsList;
	});
}

export function toggleShowRosterAsAvatar() {
	store.mutate((appState) => {
		appState.viewState = assign({}, appState.viewState);

		appState.viewState.showRosterAsAvatar = !appState.viewState.showRosterAsAvatar;
	});
}

// allows dragging of windows without events triggering route drawing
export function setRouteInteractionDisabled(disabled: boolean) {
	store.mutate((appState) => {
		appState.viewState = assign({}, appState.viewState);

		appState.viewState.disableRouteInteraction = disabled;
	});
}

export function setDragSpec(spec: store.IDragSpec) {
	store.mutate((appState) => {
		appState.viewState = assign({}, appState.viewState);

		appState.viewState.dragSpec = spec || undefined;
	}, 'appState.viewState.dragSpec');
}

export function getCachedImage(url: string) {
	return localStorage.getItem(url);
}

export function storeCachedImage(url: string, dataUrl: string) {
	const urlParts = url?.split('?');

	// be sure that an old, cache busted url doesn't overwrite a new one
	if(urlParts?.length === 2) {
		const storageKeys = Object.keys(localStorage);
		for(const key of storageKeys) {
			const keyParts = key.split('?');
			if(keyParts.length === 2 && keyParts[0] === urlParts[0] && Number(keyParts[1]) > Number(urlParts[1])) {
				return;
			}
		}
	}
	localStorage.setItem(url, dataUrl);
}

export function clearCachedImage(url: string) {
	localStorage.removeItem(url);
}

export function setHelpSwitch(val: store.HelpSwitches) {
	store.mutate((appState) => {
		appState.viewState = assign({}, appState.viewState);
		appState.viewState.helpSwitches = appState.viewState.helpSwitches | val;
		localStorage.setItem(HELP_SWITCHES_KEY, appState.viewState.helpSwitches.toString());
	});
}

export function setDiagramConfig(val: Partial<store.IDiagramConfig>) {
	store.mutate((appState) => {
		appState.viewState = {...appState.viewState};
		appState.viewState.diagramConfig = {...appState.viewState.diagramConfig, ...val};
		// localStorage.setItem(HELP_SWITCHES_KEY, appState.viewState.helpSwitches.toString());
	});
}

export async function undeletePlaybook(id: string) {
	if (!_assertCanAccessApi()) {
		return false;
	}

	const response = await apiGateway.undelete(id) as any;

	if (response.error) {
		pushAlert({ message: response.error.status === 404 ? 'There was nothing to undelete' : 'There was an error', title: 'Not Restored', severity: store.AlertSeverity.error, mode: store.AlertMode.prompt });
	} else {
		pushAlert({ message: 'Playbook Restored', title: 'Restored', severity: store.AlertSeverity.info, mode: store.AlertMode.prompt });
	}
}

// helpers
async function _updateOfflineCache() {
	try {
		if (_isDev) {
			await store.mutate((appState) => {
				appState.viewState.bootstrapMessage = 'get schema and authToken from storage';
			});
		}

		const cacheSchemaVersion = await clientStorage.getItem(clientStorage.SCHEMA_VERSION_STORAGE_KEY);
		const authToken = await clientStorage.getItem(clientStorage.AUTH_TOKEN_STORAGE_KEY);
		const impersonatorToken = await clientStorage.getItem(clientStorage.IMPERSONATOR_TOKEN_STORAGE_KEY);
		let currentTeam;
		let currentUser;

		if (!cacheSchemaVersion || cacheSchemaVersion === store.SCHEMA_VERSION) {
			nativeService.debug(`populating store from client storage`);
			const currentUserId = await clientStorage.getItem(clientStorage.CURRENT_USER_ID_STORAGE_KEY);
			const currentTeamId = await clientStorage.getItem(clientStorage.CURRENT_TEAM_ID_STORAGE_KEY);
			const storageKeys = await clientStorage.getKeys();
			const modelKeys = filter(storageKeys,  (k) => k.indexOf('model.') === 0);
			const entityFactories = {
				teams: teamFactory,
				users: userFactory,
				formations: formationFactory,
				plays: playFactory,
			};

			// restore from offline storage
			await store.mutateAsync(async (appState) => {
				if (_isDev) {
					appState.viewState.bootstrapMessage = 'restoring from offline storage';
				}
				appState.model = assign({}, appState.model);
				appState.viewState = assign({}, appState.viewState);

				appState.viewState.currentUserId = currentUserId;

				for (const key of modelKeys) {
					const item = await clientStorage.getItem(key);
					const factory = entityFactories[key.split('.')[1]];

					set(appState, key, factory(item));
				}

				currentUser = currentUserId && appState.model.users[currentUserId];
				currentTeam = currentTeamId && appState.model.teams[currentTeamId];

				const settings = await clientStorage.getItem('viewState.settings');
				const strings = await clientStorage.getItem('viewState.strings');
				const availablePlans = await clientStorage.getItem('viewState.availableSubscriptionPlans');
				const helpSwitches = localStorage.getItem(HELP_SWITCHES_KEY);
				// const unsyncedEntities = await clientStorage.getItem(clientStorage.UNSYNCED_ENTITY_STORAGE_KEY); // TODO: evaluate if this can be safely
				const availableSubscriptionPlans = [];

				if (availablePlans) {
					for (const plan of availablePlans) {
						availableSubscriptionPlans.push(subscriptionPlanFactory(plan));
					}
				}

				appState.viewState.settings = settings;
				appState.viewState.strings = strings;
				appState.viewState.availableSubscriptionPlans = availableSubscriptionPlans;
				appState.viewState.helpSwitches = helpSwitches ? Number(helpSwitches) : 0;
				appState.viewState.isImpersonating = !!impersonatorToken;
				// appState.viewState.unsyncedEntities = unsyncedEntities || {}; // TODO: evaluate if this can be safely
			});
		} else {
			// await clientStorage.clear();
			// await clientStorage.setItem(dataService.AUTH_TOKEN_STORAGE_KEY, authToken);
		}

		await clientStorage.setItem(clientStorage.SCHEMA_VERSION_STORAGE_KEY, store.SCHEMA_VERSION);

		return { authToken, currentTeam, currentUser };
	} catch (err) {
		return {};
	}
}

function _assertCanAccessApi(alertMode = store.AlertMode.default, message = _s(StringKey.PLAYMAKER_CONNECTION_REQUIRED_ALERT_MESSAGE), title = _s(StringKey.PLAYMAKER_CONNECTION_REQUIRED_ALERT)) {
	if (!store.appState().viewState.api.canAccessApi) {
		pushAlert({message, title, severity: store.AlertSeverity.error, mode: alertMode });
		return false;
	}

	return true;
}

import { assign, find } from 'lodash';
import * as apiGateway from './apiGateway';
import { Login } from './components/login';
import { canCollaborate, fetchConfig, resync } from './dataService';
import * as store from './store';

let _offlineTime;
// const apiVersionTest = /1\./;
let _isInit = false;

export function init(actions) {
	if (_isInit) {
		return;
	}
	_isInit = true;
	// logger.set({ connection: 'online'});

	apiGateway.on('api.status', async (data: Record<string, unknown>) => {
		let needsResync = false;

		await store.mutate((appState) => {
			const lastApiState = appState.viewState.api;

			appState.viewState = assign({}, appState.viewState);
			appState.viewState.api = assign({}, lastApiState, data);

			const apiState = appState.viewState.api;

			if (apiState.canAccessApi && !apiState.socketConnected && canCollaborate() && appState.viewState.browserContext.isVisible) {
				apiGateway.connect();
			}

			if (_offlineTime) { // I was online then offline, now see if I'm back online
				if (canCollaborate()) { // socket connection triggers resync if the user can collaborate
					if (apiState.socketConnected && !lastApiState.socketConnected) {
						needsResync = true;
						_offlineTime = undefined;
					}
				} else if (apiState.canAccessApi && !lastApiState.canAccessApi) {
					needsResync = true;
					_offlineTime = undefined;
				}
				// logger.set({ connection: 'online'});
			} else if (!apiState.canAccessApi && lastApiState.canAccessApi) {
				_offlineTime = Date.now();
				// logger.set({ connection: 'offline'});
			}

			if (apiState.authFailure === 401 && !find(appState.viewState.modals, { id: 'cm-auth-failure-login'})) {
				actions.pushModal({
					id: 'cm-auth-failure-login',
					component: Login,
					props: () => ({ viewState: store.appState().viewState, classNames: ['compact'] }),
				}, appState);
			}

			// if (apiState.updateReady && !appState.viewState.bootstrapping && apiState.availableVersion && apiVersionTest.test(apiState.availableVersion)) {
			// 	apiGateway.updateApi();
			// }

			if (lastApiState.currentVersion !== apiState.currentVersion && !appState.viewState.bootstrapping) {
				// logger.set({ api_version: apiState.currentVersion });
				fetchConfig();
			}
		});

		if (needsResync) {
			resync('resync-apistatus');
		}
	});

	window.addEventListener('load', () => {
		// this disconnects our socket when we are no long visible
		document.addEventListener('visibilitychange', () => {
			store.mutateAsync(async (appState) => {
				const isVisible = document.visibilityState !== 'hidden';
				const visibilityChanged = appState.viewState.browserContext.isVisible !== isVisible;

				// console.log(`isVisible: ${isVisible}`);

				appState.viewState = assign({}, appState.viewState);
				appState.viewState.browserContext = assign({}, appState.viewState.browserContext, { isVisible });

				if (visibilityChanged) {
					if (isVisible) {
						if (appState.viewState.api.canAccessApi) {
							if (canCollaborate()) {
								apiGateway.connect(); // this will trigger a resync
							} else {
								_offlineTime = undefined;
								resync('resync-visibilitychange');
							}
						}
					} else {
						_offlineTime = Date.now();
						if (appState.viewState.api.socketConnected) {
							apiGateway.disconnect();
						}
					}
				}
			});
		});
	});
}

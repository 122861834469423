import { filter, find, forEach, map, sortBy } from 'lodash';
import * as React from 'react';
import * as actions from '../actions';
import { current as getCurrentContext } from '../componentContext';
import { default as formationFactory, IFormation } from '../models/formation';
import { IPlaybook } from '../models/playbook';
import { ISortable } from '../models/sortableModel';
import * as store from '../store';
import { _s, StringKey } from '../strings';
import * as viewManager from '../viewManager';
import { FormationInfoModal } from './formationInfo';
import { GamePhase } from '../models/diagramModel';
import { Spinner } from './spinner';

interface Props {
	model: store.IModelState;
	viewState: store.IViewState;
    children: any
}

type SaveCallback = (formation: IFormation, isNew: boolean)=>void;

interface FormationsContextState {
	addLabel: string;
	currentPhase: GamePhase;
    currentFormations: IFormation[];
	disableAdd: boolean;
	isProcessing: boolean;
    openAddModal(savedCallback?: SaveCallback): void;
    openInfo(formationId: string);
    handleFormationDrop(sourceId: string, targetId: string): void;
    saveFormation(formation: IFormation & ISortable, callback?:SaveCallback): Promise<void>;
    deleteFormations(formations: IFormation[]): Promise<void>;
    copyFormationsTo(selectedFormations: IFormation[], targetPlaybooks: IPlaybook[], callback?:()=>void): Promise<void>;
}

const NO_OP = () => undefined;
const ASYNC_NO_OP = () => Promise.resolve();
const NULL_CONTEXT = {
	addLabel: '',
	currentPhase: GamePhase.Offense,
	currentFormations: [],
	disableAdd: true,
	isProcessing: false,
	openAddModal: NO_OP,
	openInfo: NO_OP,
	handleFormationDrop: NO_OP,
	saveFormation: ASYNC_NO_OP,
	deleteFormations: ASYNC_NO_OP,
	copyFormationsTo: ASYNC_NO_OP
};
export const FormationsContext = React.createContext<FormationsContextState>(NULL_CONTEXT);

export function FormationsProvider({ model, viewState, children }: Props) {
	const [isProcessing, setIsProcessing] = React.useState(false);
	const [contextState, setContextState] = React.useState<FormationsContextState>(NULL_CONTEXT);
	const { currentPhase, currentPlaybook, currentTeam, playbookPermissions, variant } = getCurrentContext();
	const currentPlaybookId = currentPlaybook?.id;
	const currentTeamId = currentTeam?.id;

	React.useEffect(() => {
		const { formations } = model;
		const { settings: { playersPerSide: playersPerSideSettings } } = viewState;
		// const { model: { formations }, viewState: { settings: { playersPerSide: playersPerSideSettings } } } = appState;
		const currentFormations = filter(formations, { playbookId: currentPlaybookId, phase: currentPhase });
		const settings = playersPerSideSettings[currentPlaybook?.playersPerSide];
		const addLabel = currentPhase === GamePhase.Offense ? _s(StringKey.NEW_OFFENSIVE_FORMATION) : currentPhase === GamePhase.Defense ? _s(StringKey.NEW_DEFENSIVE_FORMATION) : _s(StringKey.NEW_SPECIAL_TEAMS_FORMATION);
		const disableAdd = variant === 'flag' || !playbookPermissions.canUpdate || currentPhase === GamePhase.SpecialTeams;

		// console.log('EFFECT', JSON.stringify(currentFormations && currentFormations.map(f => { return { label: f.label, id: f.id };  })));

		const newState = {
			addLabel,
			currentPhase,
			currentFormations,
			disableAdd,
			isProcessing,
			// NOTE: this will only work properly for offense and defense because phase and posture are aligned
			openAddModal(savedCallback?: SaveCallback) {
				if(disableAdd) {
					return;
				}

				const formation = formationFactory(find(settings.baseFormations, { phase: currentPhase, posture: currentPhase }));
				const callback = (formation, isNew) => {
					actions.popModal();
					if(savedCallback) {
						savedCallback(formation, isNew);
					}
				}

				formation.playbookId = currentPlaybook.id;
				formation.label = `${_s(StringKey.FORMATION)} ${currentFormations.length + 1}`;

				viewManager.pushModal({
					component: FormationInfoModal,
					props: () => {
						return {
							formation,
							isNew: true,
							saveFormation: (f, onComplete: () => void) => {
								newState.saveFormation(f, (formation, isNew) => {
									onComplete();
									callback(formation, isNew);
								});
							},
						};
					},
					screenName: 'FormationInfo',
				});
			},
			openInfo(formationId) {
				const formation = formations[formationId];

				viewManager.pushModal({
					component: FormationInfoModal,
					props: () => ({ 
						appState: store.appState(), 
						formation, 
						isNew: false, 
						saveFormation: (f, onComplete: () => void) => { 
							newState.saveFormation(f, () => {
								onComplete();
								actions.popModal;
							});
						}
					}),
					supportsAlerts: true,
					screenName: 'FormationInfo',
				});
			},
			handleFormationDrop(sourceId: string, targetId: string) {
				const { model: { formations : storeFormations } } = store.appState();
				const source = storeFormations[sourceId];
				const target = storeFormations[targetId];
				const filteredFormations = filter(storeFormations, f => f.playbookId === source.playbookId && f.phase === source.phase);
				const targetSortIndex = target.sortIndex;
				const mutated: IFormation[] = [];
				let index = 0;
				const sortedMap = map(filteredFormations, (formation) => {
					const sortIndex = formation === source ? (source.sortIndex > targetSortIndex) ? targetSortIndex - 0.5 : targetSortIndex + 0.5 : formation.sortIndex;

					return { id: formation.id, sortIndex };
				});

				// console.log('dropping', { source: toConsoleShape(source), target: toConsoleShape(target) });
				
				forEach(sortBy(sortedMap, 'sortIndex'), (item) => {
					const formation = storeFormations[item.id];
					const f = formationFactory(formation.toDocument());

					f.sortIndex = index++;
					
					mutated.push(f);
				});

				actions.saveFormations(mutated);
			},
			async saveFormation(formation:IFormation, callback: SaveCallback) {
				const existingFormation: IFormation = currentFormations.find(f => f.id === formation.id);

				formation.playbookId = currentPlaybookId;
				formation.teamId = currentTeamId;

				if (!existingFormation) {
					formation.sortIndex = filter(currentFormations).length;
					await actions.addFormation(formation);
				} else {
					await actions.saveFormation(formation);
				}
				if(callback) {
					callback(formation, !existingFormation);
				}
			},
			async deleteFormations(toDelete: IFormation[]) {
				for (const formation of toDelete) {
					await actions.deleteFormation(formation);
				}
			},
			async copyFormationsTo(selectedFormations: IFormation[], targetPlaybooks: IPlaybook[], callback: () => void) {
				setIsProcessing(true);
				await actions.copyFormations(selectedFormations, targetPlaybooks);
				setIsProcessing(false);
				callback();
			}
		};

		setContextState(newState);

	}, [model, viewState, currentPhase, currentPlaybookId, isProcessing]);

	return <FormationsContext.Provider value={ contextState }>
		{ isProcessing ? <Spinner /> : null }	
		{ children }
	</FormationsContext.Provider>
}

// function toConsoleShape(formation: IFormation) {
// 	return { label: formation.label, sortIndex: formation.sortIndex };
// }
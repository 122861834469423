import { sign } from 'tweetnacl';
import { decodeBase64, decodeUTF8} from 'tweetnacl-util';
import * as store from './store';

export function verify(message: string, signature: string) {
	try {
		const { viewState } = store.appState();
		const decodedMessage = decodeUTF8(message);
		const decodedSignature = decodeBase64(signature);

		return sign.detached.verify(decodedMessage, decodedSignature, decodeBase64(viewState.config.signingKey));
	} catch (err) {
		return false;
	}
}

import { assign, forEach, map, startCase } from 'lodash';
import { default as valueFilters } from 'playmaker-team-common/dist/shared/valueFilters';
import * as React from 'react';
import * as actions from '../actions';
import * as logger from '../logger';
import { default as userFactory, IUser } from '../models/user';
import * as store from '../store';
import { _s, StringKey } from '../strings';
import * as viewManager from '../viewManager';
import { AlertList } from './alert';
import { Avatar } from './avatar';
import { Page, PageState } from './page';
import { Spinner } from './spinner';

interface Props {
	addMode: boolean;
	alertList: any;
	cancelAction?: () => void;
	confirmActionClassName?: string;
	confirmAction: () => void;
	user: IUser;
	onUserChange: (path: string, val: any) => void;
	onPasswordSet?: (val: string) => void;
	goToPassword?: () => void;
}

interface State {
	email: string;
	password: string;
	confirmedEmail: string;
	invalidEmailMatch: boolean;
}

export class ProfileEdit extends React.Component<Props, State> {
	private onEmailChange;
	private onConfirmedEmailChange;
	private onEmailOptInChange;
	private onFirstNameChange;
	private onLastNameChange;
	private onPasswordChange;

	constructor(props) {
		super(props);

		this.state = {
			email: props.user?.email || '',
			confirmedEmail: '',
			password: '',
			invalidEmailMatch: false,
		};

		forEach(['emailOptIn', 'firstName', 'lastName'], (path) => {
			const pathParts = map(path.split('/'), (part) => {
				return startCase(part).replace(/\s/g, '');
			});
			const handlerName = `on${pathParts.join('')}Change`;
			// console.log(handlerName);

			this[handlerName] = this.onChange.bind(this, path);
		});

		forEach(['password'], (key) => {
			const handlerName = `on${startCase(key)}Change`.replace(/\s/g, '');
			// console.log(handlerName);

			this[handlerName] = this.handlePasswordChange.bind(this, key);
		});
		
		forEach(['email', 'confirmedEmail'], (key) => {
			const handlerName = `on${startCase(key)}Change`.replace(/\s/g, '');
			// console.log(handlerName);

			this[handlerName] = this.handleEmailChange.bind(this, key);
		});

		this.onAvatar = this.onAvatar.bind(this);
		this.onAvatarRemoved = this.onAvatarRemoved.bind(this);
	}

	public onAvatar(dataUrl: string) {
		const { user } = this.props;
		
		actions.clearCachedImage(user?.profile?.avatarUrl);
		this.props.onUserChange('/profile/avatarUrl', dataUrl);
	}

	public onAvatarRemoved() {
		const { user } = this.props;

		actions.clearCachedImage(user?.profile?.avatarUrl);
		this.props.onUserChange('/profile/avatarUrl', undefined);
	}

	public onChange(path, e) {
		let newVal;

		if (e.target.name === 'emailOptIn') {
			if (e.target.checked) {
				newVal = e.target.value;
			} else {
				return;
			}
		} else {
			newVal = (e.type === 'blur') ? valueFilters.clean(e.target.value) : e.target.value;
		}

		this.props.onUserChange(path, newVal);
	}

	public handlePasswordChange(key, e) {
		const newVal = (e.type === 'blur') ? valueFilters.clean(e.target.value) : e.target.value;

		this.setState((prevState) => {
			const newState = assign({}, prevState) as State;

			newState[key] = newVal;

			if (newState.password) {
				this.props.onPasswordSet(newState.password);
			}

			return newState;
		});
	}

	public handleEmailChange(key, e) {
		const { addMode } = this.props;
		const newVal = (e.type === 'blur') ? valueFilters.clean(e.target.value) : e.target.value;

		this.setState((prevState) => {
			const newState = assign({}, prevState) as State;

			newState[key] = newVal;

			newState.invalidEmailMatch = !!(addMode && newState.email !== newState.confirmedEmail);

			if (newState.email) {
				// disabled until we decide whether or not we want to require double email entry
				// if (newState.invalidEmailMatch) {
				// 	this.props.onUserChange('/email', null);
				// } else {
				this.props.onUserChange('/email', newState.email);
				// }
			}

			return newState;
		});
	}

	public render() {
		const { addMode, alertList, cancelAction, confirmActionClassName= 'icon previous', confirmAction, goToPassword, user } = this.props;
		const { email, confirmedEmail, invalidEmailMatch, password } = this.state;

		return <React.Fragment>
			<header>
				{ cancelAction && <div className="actions"><a className="button" onClick={ cancelAction }><span className={ confirmActionClassName }></span></a></div> }
				<div className="title">{ _s(StringKey.YOUR_INFO) }</div>
				<div className="actions">
					<a className={ confirmAction ? 'button' : 'button disabled'} onClick={ confirmAction }><span className="icon ok"></span></a>
				</div>
			</header>
			{ alertList }
			<div className="content scrollable">
				<div className="inner compact">
					<input type="text" placeholder={ _s(StringKey.FIRST_NAME) } value={ user.viewValue('firstName') } onChange={ this.onFirstNameChange } onBlur={ this.onFirstNameChange } required />
					<input type="text" placeholder={ _s(StringKey.LAST_NAME) } value={ user.viewValue('lastName') } onChange={ this.onLastNameChange } onBlur={ this.onLastNameChange } required />
					<input type="email" placeholder={ _s(StringKey.EMAIL_ADDRESS) } value={ email } onChange={ this.onEmailChange } onBlur={ this.onEmailChange } required />
					{ /** addMode ? <input type="email" className={ invalidEmailMatch ? 'invalid' : null } placeholder={ _s(StringKey.CONFIRM_EMAIL_ADDRESS) } value={ confirmedEmail } onChange={ this.onConfirmedEmailChange } onBlur={ this.onConfirmedEmailChange } onPaste={ e => e.preventDefault()} required /> : null **/ }
					{ addMode ? <input type="password" placeholder={ _s(StringKey.NEW_PASSWORD) } value={ password } onChange={ this.onPasswordChange } onBlur={ this.onPasswordChange } required /> : null }
					{ addMode ? null : <a className="button basic" onClick={ goToPassword }><span>{ _s(StringKey.CHANGE_PASSWORD) }</span></a> }
					<h2>{ _s(StringKey.SEND_ME_EMAIL_UPDATES) }</h2>

					<div className="radio">
						<label className={ user.emailOptIn ? 'on' : ''}><span>{ _s(StringKey.YES) }</span><input type="radio" name="emailOptIn" value="1" onChange={ this.onEmailOptInChange } checked={ user.emailOptIn } /></label>
						<label className={ !user.emailOptIn ? 'on' : ''}><span>{ _s(StringKey.NO) }</span><input type="radio" name="emailOptIn" value="" onChange={ this.onEmailOptInChange } checked={ !user.emailOptIn } /></label>
						<span></span>
					</div>
					{ addMode ? <p>{ _s(StringKey.BY_CREATING_AN_ACCOUNT_YOU_AGREE_TO_THE) } <a onClick={ () => actions.browseTo(`https://${window.location.hostname}/terms`) }>{ _s(StringKey.TERMS_AND_PRIVACY_POLICY) }</a></p> : null }
					<h2>{ _s(StringKey.OPTIONAL_PHOTO) }</h2>
					<Avatar canEdit={ true } className="profile" onImage={ this.onAvatar } onRemoveImage={ this.onAvatarRemoved } imageWidth={ 256 } imageHeight={ 256 } imageUrl={ user.profile.avatarUrl } />
					{ addMode ? null: <a className="link accountDeletion" onClick={ () => actions.browseTo(`https://${window.location.hostname}/account-deletion`) }>{ _s(StringKey.REQUEST_ACCOUNT_DELETION) }</a> }
				</div>
			</div>
		</React.Fragment>;
	}
}

const ChangePasswordPage = ({ alertList, cancelAction, confirmAction, passwords, mismatch, update }) => {
	return <React.Fragment>
		<header>
			<div className="actions">
				<a className="button" onClick={ cancelAction }><span className="icon previous"></span></a>
			</div>
			<div className="title">{ _s(StringKey.CHANGE_PASSWORD) }</div>
			<div className="actions">
				<a className="button" onClick={ confirmAction }><span className="icon ok"></span></a>
			</div>
		</header>
		{ alertList }
		<div className="content scrollable">
			<div className="inner compact">
				<input type="password" placeholder={ _s(StringKey.CURRENT_PASSWORD) } value={ passwords.current || '' } onChange={ (e) => update('current', e.target.value) } required />
				<input type="password" placeholder={ _s(StringKey.NEW_PASSWORD) } value={ passwords.new || '' } onChange={ (e) => update('new', e.target.value) } required />
				<input type="password" className={ mismatch ? 'invalid' : null } placeholder={ _s(StringKey.CONFIRM_NEW_PASSWORD) } value={ passwords.confirm || '' } onChange={ (e) => update('confirm', e.target.value) } required />
			</div>
		</div>
	</React.Fragment>;
};

interface ModalProps {
	alerts: store.IAlert[];
	appState: store.IAppState;
	user: IUser;
}

interface ModalState {
	activePage: number;
	mutatedUser: IUser;
	changePasswords: any;
	saving?: boolean;
}

export class ProfileEditModal extends React.Component<ModalProps, ModalState> {
	private goToProfilePage;
	private goToPasswordPage;

	constructor(props: ModalProps) {
		super(props);

		this.state = {
			activePage: 0,
			mutatedUser: userFactory(props.user),
			changePasswords: { current: '', new: '', confirm: '' },
		};

		this.onChangePassword = this.onChangePassword.bind(this);
		this.updateChangePasswords = this.updateChangePasswords.bind(this);
		this.onUserChange = this.onUserChange.bind(this);
		this.save = this.save.bind(this);
		this.goToProfilePage = this.goToPage.bind(this, 0);
		this.goToPasswordPage = this.goToPage.bind(this, 1);

		this.logScreen();
	}

	public getPageState(key) {
		const { activePage } = this.state;

		if (key === activePage) {
			return PageState.IN;
		} else if (key < activePage) {
			return PageState.OUT;
		}

		return PageState.DEFAULT;
	}

	public goToPage(page: number) {
		actions.clearAlerts();
		this.setState((previousState) => {
			const newState = assign({}, previousState) as ModalState;

			newState.activePage = page;

			return newState;
		}, () => { this.logScreen(); });
	}

	public logScreen() {
		const { activePage } = this.state;

		if (activePage === 0) {
			logger.logScreen('ProfileEdit');
		} else {
			logger.logScreen('ChangePassword');
		}
	}

	public onUserChange(path, value) {
		this.setState((prevState) => {
			const newState = assign({}, prevState) as ModalState;

			// return a new instance so the child component is sure to update
			newState.mutatedUser = userFactory(newState.mutatedUser);
			newState.mutatedUser.setAt(path, value);

			return newState;
		});
	}

	public updateChangePasswords(key: string, val: string) {
		this.setState((prevState) => {
			const newState = assign({}, prevState) as ModalState;

			// return a new instance so the child component is sure to update
			newState.changePasswords = assign({}, prevState.changePasswords);
			newState.changePasswords[key] = val;

			return newState;
		});
	}

	public async onChangePassword(e) {
		const { changePasswords } = this.state;

		if (changePasswords.current && changePasswords.new && changePasswords.new === changePasswords.confirm) {
			actions.clearAlerts();

			await actions.updatePassword(changePasswords.current, changePasswords.new);

			const alerts = store.appState().viewState.alerts;
			if (alerts.length === 0) {
				viewManager.popModal();
			}
		}
	}

	public async save() {
		const { mutatedUser, saving } = this.state;

		if (!saving) {
			this.setState({ saving: true}, () => {
				actions.clearAlerts();

				actions.saveUser(mutatedUser, (response) => {
					this.setState({ saving: false });

					if (response.results[0].isError) {
						actions.pushAlert({ message: response.results[0].messages.join(' &bull; '), title: 'User Save Failed', severity: store.AlertSeverity.error });
					} else {
						viewManager.popModal();
					}
				});
			});
		}

	}

	public render() {
		const { appState, alerts } = this.props;
		const { mutatedUser, changePasswords, saving } = this.state;
		const alertList = <AlertList alerts={ alerts } />;

		return <React.Fragment>
			{ saving ? <Spinner /> : null }
			<Page key="page0" pageState={ this.getPageState(0) } pageClasses={ ['page'] }>
				<ProfileEdit alertList={ alertList } addMode={ false } cancelAction={ viewManager.popModal } confirmActionClassName="icon cancel" confirmAction={ this.save } user={ mutatedUser } onUserChange={ this.onUserChange } goToPassword={ this.goToPasswordPage } />
			</Page>
			<Page key="page1" pageState={ this.getPageState(1) } pageClasses={ ['page'] }>
				<ChangePasswordPage alertList={ alertList } passwords={ changePasswords } cancelAction={ this.goToProfilePage } mismatch={ changePasswords.new && (changePasswords.new !== changePasswords.confirm )} confirmAction={ this.onChangePassword } update={ this.updateChangePasswords } />
			</Page>
		</React.Fragment>;
	}
}
import { assign, filter, find, forEach, map, orderBy } from 'lodash';
import { IPatchOperation, PatchOpType } from 'playmaker-team-common/dist/shared/interfaces';
import { default as valueFilters } from 'playmaker-team-common/dist/shared/valueFilters';
import { Component, Fragment, useState } from 'react';
import * as React from 'react';
import * as actions from '../actions';
import { getPlaybookPermissions, getTeamPermissions, IPlaybookPermissions, ITeamPermissions } from '../authorizationHelper';
import { current as getCurrentContext } from '../componentContext';
import * as logger from '../logger';
import { default as playbookFactory, IPlaybook } from '../models/playbook';
import { default as playbookMemberFactory, IPlaybookMember, PlaybookAccess } from '../models/playbookMember';
import { ISortable } from '../models/sortableModel';
import { SystemFeature } from '../models/subscriptionPlan';
import { ITeam } from '../models/team';
import { ITeamMember, TeamRole } from '../models/teamMember';
import * as playbookHelper from '../playbookHelper';
import * as store from '../store';
import { IAlert, IAppState } from '../store';
import { _s, StringKey } from '../strings';
import * as viewManager from '../viewManager';
import { AlertList } from './alert';
import { BillingModal } from './billing';
import { Page, PageState } from './page';
import { Spinner } from './spinner';

interface PlaybookInfoProps {
	alertList: any;
	fieldOptions: any[];
	isNew: boolean;
	onChange: (path: string, e) => void;
	playbook: IPlaybook;
	playersPerSide: number[];
}

type ButtonAction = () => void;

class PlaybookInfoPage extends Component<PlaybookInfoProps> {
	private onFieldChange;
	private onNameChange;
	private onPlayersPerSideChange;

	constructor(props: PlaybookInfoProps) {
		super(props);

		const { onChange } = props;

		this.onFieldChange = onChange.bind(this, 'settings/fieldKey');
		this.onNameChange = onChange.bind(this, 'name');
		this.onPlayersPerSideChange = onChange.bind(this, 'playersPerSide');

		this.state = { isCopying: false };
	}

	public render() {
		const { alertList, fieldOptions, isNew, playbook, playersPerSide } = this.props;
		const playersPerSideOptions = map(playersPerSide, (players) => {
			return <label key={ players } className={ playbook.playersPerSide === players ? 'on' : '' }><span>{ players }</span><input type="radio" name="playersPerSide" value={ players } checked={ playbook.playersPerSide === players } onChange={ this.onPlayersPerSideChange } /></label>;
		});
		const filteredFieldOptions = filter(fieldOptions, (option) => option.validPlayerCount.indexOf(playbook.playersPerSide) !== -1);
		const fieldRadios = map(filteredFieldOptions, (option) => {
			return <label key={ option.key } className={ playbook.settings.fieldKey === option.key ? 'on' : '' }><span className={ `icon ${option.key}` }></span><input type="radio" name="fieldLines" value={ option.key } checked={ playbook.settings.fieldKey === option.key } onChange={ this.onFieldChange } /></label>;
		});

		return <Fragment>
			{ alertList }
			<div className="content playbookEdit scrollable">
				<div className="inner compact">
					<h2>{ _s(StringKey.PLAYBOOK_NAME) }</h2>
					<input type="text" name="" placeholder={ _s(StringKey.PLAYBOOK_NAME) } value={ playbook.viewValue('name') } onChange={ this.onNameChange } onBlur={ this.onNameChange } required />

					<h2>{ _s(StringKey.PLAYERS_PER_SIDE) }</h2>
					<div className={ isNew ? 'radio required' : 'radio disabled'}>
						{ playersPerSideOptions }
						<span></span>
					</div>

					<h2>{ _s(StringKey.FIELD_LINES) }</h2>
					<div className="list">
						<div className="radio grid fieldLineOptions">
							{ fieldRadios }
						</div>
					</div>
				</div>
			</div>
		</Fragment>;
	}
}

const MemberAccessRow = ({ member, onMemberAccessChange }: { member: any, onMemberAccessChange: any }) => {
	return <div className="row">
		<span className="firstName">{ member.firstName }</span>
		<span className="lastName">{ member.lastName }</span>
		<span className="rosterPosition">{ member.position }</span>
		<span className="access">{ member.accessLabel }</span>
		<div className="options">
			<div className="radio">
				<label className={ member.access === PlaybookAccess.None ? 'on' : '' }><span className="icon accessNone"></span><input type="radio" readOnly={ true } checked={ member.access === PlaybookAccess.None } onClick={ onMemberAccessChange } value={ `${member.id}.${PlaybookAccess.None }` } /></label>
				<label className={ member.access === PlaybookAccess.View ? 'on' : '' }><span className="icon accessView"></span><input type="radio" readOnly={ true } checked={ member.access === PlaybookAccess.View } onClick={ onMemberAccessChange } value={ `${member.id}.${PlaybookAccess.View}` } /></label>
				<label className={ member.access === PlaybookAccess.Edit ? 'on' : '' }><span className="icon accessEdit"></span><input type="radio" readOnly={ true } checked={ member.access === PlaybookAccess.Edit } onClick={ onMemberAccessChange } value={ `${member.id}.${PlaybookAccess.Edit}` } /></label>
			</div>
		</div>
	</div>;
};

const AccessAlert = ({ accessDisabled }) => {
	if (!accessDisabled) {
		return null;
	}
	const { currentTeam, platform } = getCurrentContext();
	const onClick = platform.isTrolledGarden ? null : () => {

		viewManager.pushModal({
			component: BillingModal,
			props: () => ({ appState: store.appState(), teamId: currentTeam.id }),
			screenName: 'Billing',
		});
	};

	return <div className="alert upgrade interactive" onClick={ onClick }>
		<span className="icon lock"></span><span>{ _s(platform.isTrolledGarden ? StringKey.UPGRADE_TEAM_ACCESS_ALERT_TROLL : StringKey.UPGRADE_TEAM_ACCESS_ALERT) }</span>
	</div>;
};

const PlaybookAccessPage = ({ onBulkAccessChange, onMemberAccessChange, onSortClick, playbook, playerSortDirection, playerSortField, staffSortDirection, staffSortField }: { onBulkAccessChange: any, onMemberAccessChange: any, onSortClick: any, playbook: IPlaybook, playerSortDirection: string, playerSortField: string, staffSortDirection: string, staffSortField: string }) => {
	let staffAccess = -1;
	const { currentTeam: team, currentSubscription, currentUser } = getCurrentContext();
	const accessDisabled = !currentSubscription || !currentSubscription.isActive() || !currentSubscription.supportsFeature(SystemFeature.collaboration);
	const rosterClass = accessDisabled ? 'group rosterTable locked' : 'group rosterTable';
	const staff = map(filter(team.members.values, (teamMember: ITeamMember) => teamMember.userId !== currentUser.id && (teamMember.role === TeamRole.Staff || teamMember.role === TeamRole.Owner)), (m) => {
		const playbookMember: IPlaybookMember = find(playbook.permissions.values, { teamMemberId: m.id });
		const access: PlaybookAccess = m.role === TeamRole.Owner ? PlaybookAccess.Edit : playbookMember ? playbookMember.access : PlaybookAccess.None;
		const accessLabel = access === PlaybookAccess.Edit ? _s(StringKey.CONTRIBUTE) : access === PlaybookAccess.View ? _s(StringKey.VIEW) : _s(StringKey.NONE);

		if (m.role !== TeamRole.Owner) {
			if (staffAccess === -1) {
				staffAccess = access;
			} else if (staffAccess !== access) {
				staffAccess = -2;
			}
		}

		return assign({ access, accessLabel }, m.toDocument());
	});
	let playerAccess = -1;
	const players = map(filter(team.members.values, (teamMember: ITeamMember) => teamMember.userId !== currentUser.id && teamMember.role === TeamRole.Player), (m) => {
		const playbookMember: IPlaybookMember = find(playbook.permissions.values, { teamMemberId: m.id });
		const access: PlaybookAccess = m.role === TeamRole.Owner ? PlaybookAccess.Edit : playbookMember ? playbookMember.access : PlaybookAccess.None;
		const accessLabel = access === PlaybookAccess.Edit ? _s(StringKey.CONTRIBUTE) : access === PlaybookAccess.View ? _s(StringKey.VIEW) : _s(StringKey.NONE);

		if (m.role !== TeamRole.Owner) {
			if (playerAccess === -1) {
				playerAccess = access;
			} else if (playerAccess !== access) {
				playerAccess = -2;
			}
		}

		return assign({ access, accessLabel }, m.toDocument());
	});
	const staffList = orderBy(staff, [staffSortField], [staffSortDirection]);
	const playerList = orderBy(players, [playerSortField], [playerSortDirection]);

	return <Fragment>
		<div className="alerts" id="alerts">
			<AccessAlert accessDisabled={ accessDisabled } />
		</div>
		<div className="content scrollable">
			<div className="inner">

				<h2>{ _s(StringKey.STAFF_ACCESS) }</h2>

				{ /* Staff Table Here */ }
				<div className={ rosterClass }>
					<header>
						<div className="row sortable">
							<span id="sort-staff-firstName" onClick={ onSortClick} className={ staffSortField === 'firstName' ? 'firstName sorted' : 'firstName' }>{ _s(StringKey.FIRST_NAME) }</span>
							<span id="sort-staff-lastName" onClick={ onSortClick} className={ staffSortField === 'lastName' ? 'lastName sorted' : 'lastName' }>{ _s(StringKey.LAST_NAME) }</span>
							<span id="sort-staff-position" onClick={ onSortClick} className={ staffSortField === 'position' ? 'staffTitle sorted' : 'staffTitle' }>{ _s(StringKey.TITLE) }</span>
							<span id="sort-staff-accessLabel" onClick={ onSortClick} className={ staffSortField === 'accessLabel' ? 'access sorted' : 'access' }>{ _s(StringKey.ACCESS) }</span>
							<div className="options">
								<div className="radio">
									<label className={ staffAccess === PlaybookAccess.None ? 'on' : '' }><span className="icon accessNone"></span><input type="radio" readOnly={ true } checked={ staffAccess === PlaybookAccess.None } onClick={ accessDisabled ? undefined : onBulkAccessChange } value={ `staff.${PlaybookAccess.None}` } /></label>
									<label className={ staffAccess === PlaybookAccess.View ? 'on' : '' }><span className="icon accessView"></span><input type="radio" readOnly={ true } checked={ staffAccess === PlaybookAccess.View } onClick={ accessDisabled ? undefined : onBulkAccessChange } value={ `staff.${PlaybookAccess.View}` } /></label>
									<label className={ staffAccess === PlaybookAccess.Edit ? 'on' : '' }><span className="icon accessEdit"></span><input type="radio" readOnly={ true } checked={ staffAccess === PlaybookAccess.Edit } onClick={ accessDisabled ? undefined : onBulkAccessChange } value={ `staff.${PlaybookAccess.Edit}` } /></label>
								</div>
							</div>
						</div>
					</header>
					<div className="list">
						{ map(staffList, (m: ITeamMember) => <MemberAccessRow key={ `access-${m.id}`} member={ m } onMemberAccessChange={ accessDisabled ? undefined :  onMemberAccessChange } /> ) }
					</div>
				</div>

				<h2>{ _s(StringKey.PLAYER_ACCESS) }</h2>

				{ /* Player Table Here */ }
				<div className={ rosterClass }>
					<header>
						<div className="row sortable">
							<span id="sort-player-firstName" onClick={ onSortClick} className={ playerSortField === 'firstName' ? 'firstName sorted' : 'firstName' }>{ _s(StringKey.FIRST_NAME) }</span>
							<span id="sort-player-lastName" onClick={ onSortClick} className={ playerSortField === 'lastName' ? 'lastName sorted' : 'lastName' }>{ _s(StringKey.LAST_NAME) }</span>
							<span id="sort-player-position" onClick={ onSortClick} className={ playerSortField === 'position' ? 'playerPosition sorted' : 'playerPosition' }>{ _s(StringKey.POSITION) }</span>
							<span id="sort-player-accessLabel" onClick={ onSortClick} className={ playerSortField === 'accessLabel' ? 'access sorted' : 'access' }>{ _s(StringKey.ACCESS) }</span>
							<div className="options">
								<div className="radio">
									<label className={ playerAccess === PlaybookAccess.None ? 'on' : '' }><span className="icon accessNone"></span><input type="radio" readOnly={ true } onClick={ accessDisabled ? undefined : onBulkAccessChange } value={ `player.${PlaybookAccess.None}` } /></label>
									<label className={ playerAccess === PlaybookAccess.View ? 'on' : '' }><span className="icon accessView"></span><input type="radio" readOnly={ true } onClick={ accessDisabled ? undefined : onBulkAccessChange } value={ `player.${PlaybookAccess.View}` } /></label>
									<label className={ playerAccess === PlaybookAccess.Edit ? 'on' : '' }><span className="icon accessEdit"></span><input type="radio" readOnly={ true } onClick={ accessDisabled ? undefined : onBulkAccessChange } value={ `player.${PlaybookAccess.Edit}` } /></label>
								</div>
							</div>
						</div>
					</header>
					<div className="list">
						{ map(playerList, (m: ITeamMember) => <MemberAccessRow key={ `access-${m.id}`} member={ m } onMemberAccessChange={ accessDisabled ? undefined :  onMemberAccessChange } /> ) }
					</div>
				</div>

			</div>
		</div>
	</Fragment>;
};

function CopyToTeamModal({ teams, copyToTeam }: { teams: ITeam[], copyToTeam: (teamId: string) => void }) {
	const [selected, setSelected] = useState('');

	return <div className="view">
		<header>
			<div className="title">{ _s(StringKey.TRANSFER_PLAYBOOK) }</div>
		</header>
		<div className="content scrollable">
			<div className="inner">
				<p>{ _s(StringKey.SELECT_TEAM) }</p>
				<div className="group" style={ { height: '130px', marginBottom: '20px'} }>
					<div className="list scrollable">
						{ map(teams, (team) => <div className="checkbox"><label className={ team.id === selected ? 'on' : '' } onClick={ () => setSelected(team.id) }><span className="icon checkmark"></span><span>{ team.name }</span></label></div>) }
					</div>
				</div>
				<div className="actions">
					<a className="button basic cancel" onClick={ actions.popModal }><span>{ _s(StringKey.CANCEL) }</span></a>
					<a className={ selected ? 'button basic' : 'button basic disabled' } onClick={ () => copyToTeam(selected) }><span>{ _s(StringKey.TRANSFER) }</span></a>
				</div>
			</div>
		</div>
	</div>;
}

function IncreaseSizeModal({ playersPerSide, upsizePlaybook }: { playersPerSide: number[], upsizePlaybook: (playersPerSide: number) => void }) {
	const [selected, setSelected] = useState(0);

	const playersPerSideOptions = map(playersPerSide, (players) => {
		return <label className={ players === selected ? 'on' : ''} key={ players }><span>{ players }</span><input type="radio" name="playersPerSide" value={ players } checked={ selected === players } onChange={ () => setSelected(players) } /></label>;
	});
	return <div className="view">
		<header>
			<div className="title">{ _s(StringKey.INCREASE_PLAYERS_PER_SIDE) }</div>
		</header>
		<div className="content scrollable">
			<div className="inner">
				<p>{ _s(StringKey.UPSIZE_MESSAGE) }</p>

				<div className="radio">
					{ playersPerSideOptions }
					<span></span>
				</div>

				<div className="actions">
					<a className="button basic cancel" onClick={ actions.popModal }><span>{ _s(StringKey.CANCEL) }</span></a>
					<a className={ selected ? 'button basic' : 'button basic disabled' } onClick={ () => selected ? upsizePlaybook(selected) : null }><span>{ _s(StringKey.UPSIZE_ACTION) }</span></a>
				</div>
			</div>
		</div>
	</div>;
}

function PlaybookActionsPage({alertList, onCopy, onCopyToTeam, onExport, onUpsize, isNew, onDelete, onArchive, playbookPermissions, teamPermissions }: { alertList: any, onCopy: ButtonAction, onCopyToTeam: ButtonAction, onExport: ButtonAction, onUpsize: ButtonAction, isNew: boolean, onDelete: ButtonAction, onArchive: ButtonAction, playbookPermissions: IPlaybookPermissions, teamPermissions: ITeamPermissions }) {
	const { currentSubscription, currentTeam, currentUser, model, variant, viewState: { isImpersonating } } = getCurrentContext();
	const upsizeTargetTeams = filter(model.teams, (team: ITeam) => team.id !== currentTeam.id && (!!team.currentSubscription?.isFreeTrial && !team.currentSubscription.isFreeTrial())) as ITeam[];

	return <Fragment>
		{ alertList }
		<div className="content scrollable">
			<div className="inner compact">
				<div className="actionSheet">
					{ !isNew && teamPermissions.canAddPlaybooks && playbookPermissions.canView ? <a className="button basic hasIcon" onClick={ onCopy }><span className="icon playbookCopy"></span><span>{ _s(StringKey.COPY_PLAYBOOK) }</span></a> : null }
					{ !isNew && teamPermissions.canTransferPlaybooks && !currentSubscription.isFreeTrial() && playbookPermissions.canUpdate && upsizeTargetTeams.length ? <a className="button basic hasIcon" onClick={ onCopyToTeam }><span className="icon playbookTransfer"></span><span>{ _s(StringKey.TRANSFER_TO) }</span></a> : null }
					{ !isNew && teamPermissions.canManageBilling && currentSubscription.supportsFeature(SystemFeature.collaboration) && playbookPermissions.canUpdate ? <a className="button basic hasIcon" onClick={ onArchive }><span className="icon playbookArchive"></span><span>{ _s(StringKey.ARCHIVE_PLAYBOOK) }</span></a> : null }
					{ !isNew && variant === 'flag' && teamPermissions.canAddPlaybooks && playbookPermissions.canView ? <a className="button basic hasIcon" onClick={ onUpsize }><span className="icon playbookIncrease"></span><span>{ _s(StringKey.INCREASE_PLAYERS_PER_SIDE) }</span></a> : null }
					{ !isNew && teamPermissions.canRemovePlaybooks && playbookPermissions.canView ? <a className="button basic hasIcon delete" onClick={ onDelete }><span className="icon playbookDelete"></span><span>{ _s(StringKey.DELETE_PLAYBOOK) }</span></a> : null }
					{ !isNew && (currentUser && (currentUser.role === 'admin' || isImpersonating)) ? <a className="button basic hasIcon trueOnly" onClick={ onExport }><span className="icon playbookDownload"></span><span>DOWNLOAD JSON</span></a> : null }
				</div>
			</div>
		</div>
	</Fragment>;
}

interface Props {
	alerts?: IAlert[];
	appState: IAppState;
	initialPage?: number;
	playbook?: IPlaybook & ISortable;
	archivePlaybook: (playbook: IPlaybook, onComplete: () => void) => void;
	savePlaybook: (playbook: IPlaybook, onComplete: () => void) => void;
	deletePlaybook: (playbook: IPlaybook, onComplete: () => void) => void;
}

interface State {
	activePage: number;
	playerSortKey: string;
	playerSortDirection: number;
	processing?: boolean;
	processingLabel?: string;
	mutatedPlaybook: IPlaybook & ISortable;
	staffSortKey: string;
	staffSortDirection: number;
}

export class PlaybookEdit extends Component<Props, State> {
	private goToInfoPage;
	private goToAccessPage;
	private goToActionsPage;

	constructor(props: Props) {
		super(props);

		this.state = {
			activePage: props.initialPage || 0,
			playerSortKey: 'firstName',
			playerSortDirection: 1,
			mutatedPlaybook: playbookFactory(props.playbook || {}), // if there is no playbook, we're creating a new one
			staffSortKey: 'firstName',
			staffSortDirection: 1,
		};

		this.copyPlaybook = this.copyPlaybook.bind(this);
		this.exportPlaybook = this.exportPlaybook.bind(this);
		this.upsizePlaybook = this.upsizePlaybook.bind(this);
		this.goToInfoPage = this.goToPage.bind(this, 0);
		this.goToAccessPage = this.goToPage.bind(this, 1);
		this.goToActionsPage = this.goToPage.bind(this, 2);
		this.handleArchive = this.handleArchive.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
		this.handleSave = this.handleSave.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleMemberAccessChange = this.handleMemberAccessChange.bind(this);
		this.handleBulkAccessChange = this.handleBulkAccessChange.bind(this);
		this.handleAccessSortClick = this.handleAccessSortClick.bind(this);
		this.onUpsize = this.onUpsize.bind(this);
		this.onCopyToTeam = this.onCopyToTeam.bind(this);
		this.copyToTeam = this.copyToTeam.bind(this);

		this.logScreen();
	}

	public logScreen() {
		const { activePage } = this.state;
		let screen = 'PlaybookEdit';

		switch (activePage) {
		case 1:
			screen = 'PlaybookEditAccess';
			break;
		case 2:
			screen = 'PlaybookActions';
			break;
		default:
			screen = 'PlaybookEdit';
			break;

		}

		logger.logScreen(screen);
	}

	public getPageState(key) {
		const { activePage } = this.state;

		if (key === activePage) {
			return PageState.IN;
		} else if (key < activePage) {
			return PageState.OUT;
		}

		return PageState.DEFAULT;
	}

	public goToPage(page: number) {
		actions.clearAlerts();

		if (page !== this.state.activePage) {
			this.setState((previousState) => {
				const newState = assign({}, previousState) as State;

				newState.activePage = page;

				return newState;
			}, () => {
				this.logScreen();
			});
		}
	}

	public handleSave() {
		const {  savePlaybook  } = this.props;
		const { mutatedPlaybook, processing } = this.state;
		const { currentTeam, viewState } = getCurrentContext();
		const isNew = !currentTeam || !currentTeam.playbooks[mutatedPlaybook.id];

		if (!processing) {
			mutatedPlaybook.teamId = currentTeam.id;
			if (isNew) {
				mutatedPlaybook.sortIndex = currentTeam.playbooks.count;

				if (!mutatedPlaybook.settings.fieldKey) {
					const filteredFieldOptions = filter(viewState.settings.fieldOptions, (option) => option.validPlayerCount.indexOf(mutatedPlaybook.playersPerSide) !== -1);

					if (filteredFieldOptions.length) {
						mutatedPlaybook.settings.fieldKey = filteredFieldOptions[0].key;
					}
				}
			}
			this.setState({ processing: true, processingLabel: null }, async () => {
				savePlaybook(mutatedPlaybook, () => {
					this.setState({ processing: false, processingLabel: '' });
				});
			});
		}
	}

	public handleArchive() {
		const { archivePlaybook } = this.props;
		const { mutatedPlaybook } = this.state;

		this.setState({ processing: true, processingLabel: _s(StringKey.ARCHIVING_PLAYBOOK_MESSAGE) }, async () => {
			archivePlaybook(mutatedPlaybook, () => {
				this.setState({ processing: false, processingLabel: '' });
			});
		});
	}

	public handleDelete() {
		const { deletePlaybook } = this.props;
		const { mutatedPlaybook, processing } = this.state;

		if (!processing) {this.setState({ processing: true, processingLabel: null }, async () => {
			deletePlaybook(mutatedPlaybook, () => {
				this.setState({ processing: false, processingLabel: '' });
			});
		});
		}
	}

	public copyPlaybook() {
		const { mutatedPlaybook, processing } = this.state;

		if (!processing) {
			this.setState({ processing: true}, async () => {
				await actions.copyPlaybook(mutatedPlaybook);

				this.setState({ processing: false });
			});
		}
	}

	public exportPlaybook() {
		const { mutatedPlaybook, processing } = this.state;
		const { currentUser } = getCurrentContext();

		if (!currentUser || currentUser.role !== 'admin' || !!processing) {
			return;
		}

		this.setState({ processing: true}, () => {
			const { model } = getCurrentContext();
			const exportData = playbookHelper.exportPlaybook(mutatedPlaybook, model);

			playbookHelper.saveAsJson(exportData, `${mutatedPlaybook.name}.json`);

			this.setState({ processing: false });
		});
	}

	public upsizePlaybook(playersPerSide: number) {
		const { currentTeam, variant} = getCurrentContext();
		const { mutatedPlaybook, processing } = this.state;

		if (variant === 'flag' && !processing) {
			this.setState({ processing: true}, async () => {
				let error = null;
				let newName = '';

				try {
					const { model } = getCurrentContext();
					const exportData = playbookHelper.exportPlaybook(mutatedPlaybook, model);

					actions.popModal();

					exportData.name = `${exportData.name}${_s(StringKey.UPSIZED_NAME_SUFFIX)}`;

					playbookHelper.upsizeFlagPlaybook(exportData, playersPerSide);

					const importResult = playbookHelper.processPlaybookImport(exportData, currentTeam);

					newName = importResult.playbook.name;

					await actions.addFullPlaybook(importResult.playbook, importResult.formations, importResult.plays, `playbook-upsize-${importResult.playbook.teamId}`);
				} catch (err) {
					error = err;
				}

				this.setState({ processing: false }, () => {
					if (!error) {
						actions.pushAlert({ title: _s(StringKey.UPSIZE_COMPLETE_TITLE), message: _s(StringKey.UPSIZE_COMPLETE_MESSAGE_TEMPLATE).replace('{name}', newName), mode: store.AlertMode.prompt, severity: store.AlertSeverity.info });
					}
				});
			});
		}
	}

	public handleMemberAccessChange(e) {
		const { currentTeam } = getCurrentContext();
		const { mutatedPlaybook } = this.state;
		const parts = e.currentTarget.value.split('.');
		const teamMemberId: string = parts[0];
		const teamMember: ITeamMember = currentTeam.members[teamMemberId];
		const playbookMember: IPlaybookMember = find(mutatedPlaybook.permissions.values, { teamMemberId }) || playbookMemberFactory({ playbookId: mutatedPlaybook.id, teamMemberId });

		playbookMember.access = teamMember.role === TeamRole.Owner ? PlaybookAccess.Edit : Number(parts[1]) as PlaybookAccess;

		this.updatePlaybook([{ op: PatchOpType.replace, path: `/permissions/${playbookMember.id}`, value: playbookMember }]);
	}

	public handleBulkAccessChange(e) {
		const { currentTeam } = getCurrentContext();
		const { mutatedPlaybook } = this.state;
		const parts = e.currentTarget.value.split('.');
		const group: string = parts[0];
		const members = group === 'staff' ? filter(currentTeam.members.values, { role: TeamRole.Staff }) : filter(currentTeam.members.values, { role: TeamRole.Player });
		const patch = [];

		forEach(members, (teamMember: ITeamMember) => {
			const playbookMember: IPlaybookMember = find(mutatedPlaybook.permissions.values, { teamMemberId: teamMember.id }) || playbookMemberFactory({ playbookId: mutatedPlaybook.id, teamMemberId: teamMember.id });

			playbookMember.access = Number(parts[1]) as PlaybookAccess;

			patch.push({ op: PatchOpType.replace, path: `/permissions/${playbookMember.id}`, value: playbookMember });
		});

		this.updatePlaybook(patch);
	}

	public handleAccessSortClick(e) {
		const parts = e.currentTarget.id.split('-');
		const list = parts[1];
		const newSortKey = parts[2];

		this.setState((prevState) => {
			const newState = assign({}, prevState);
			const sortProp = `${list}SortKey`;
			const dirProp = `${list}SortDirection`;
			const currentSortKey = prevState[sortProp];
			const currentSortDir = prevState[dirProp];

			if (currentSortKey === newSortKey) {
				newState[dirProp] = -currentSortDir;
			} else {
				newState[sortProp] = newSortKey;
				newState[dirProp] = 1;
			}

			return newState;
		});
	}

	public handleChange(path, e) {
		const { viewState } = getCurrentContext();
		const patch: IPatchOperation[] = [];
		let value;

		if (path === 'color') {
			if (e.target.checked) {
				value = e.target.value;
			} else {
				return;
			}
		} else {
			value = (e.type === 'blur') ? valueFilters.clean(e.target.value) : e.target.value;
		}

		if (path === 'playersPerSide') {
			const filteredFieldOptions = filter(viewState.settings.fieldOptions, (option) => option.validPlayerCount.indexOf(Number(value)) !== -1);
			const selectedFieldKey = filteredFieldOptions.length ? filteredFieldOptions[0].key : undefined;

			patch.push({op: PatchOpType.replace, path: '/settings/fieldKey', value: selectedFieldKey});
		}

		patch.push({op: PatchOpType.replace, path, value});

		this.updatePlaybook(patch);
	}

	public updatePlaybook(patch: IPatchOperation[]) {
		this.setState((prevState) => {
			const newState = assign({}, prevState) as State;

			// clone to be sure children updated based on props changing
			newState.mutatedPlaybook = playbookFactory(newState.mutatedPlaybook);
			newState.mutatedPlaybook.applyPatch(patch);

			return newState;
		});
	}

	public render() {
		const { alerts = [] } = this.props;
		const { currentTeam, teamPermissions, viewState } = getCurrentContext();
		const { activePage, playerSortDirection, playerSortKey, mutatedPlaybook, processing, processingLabel, staffSortDirection, staffSortKey } = this.state;
		const isNew = !currentTeam || !currentTeam.playbooks[mutatedPlaybook.id];
		const playbookPermissions = getPlaybookPermissions({ playbook: mutatedPlaybook, teamPermissions });
		const alertList = <AlertList alerts={ alerts } />;

		return <div className="view">
			{ processing ? <Spinner label={ processingLabel } delayMs={150} /> : null }
			<header>
				<div className="actions">
					<a className="button" onClick={ viewManager.popModal }><span className="icon cancel"></span></a>
				</div>
				<div className="title">{ isNew ? _s(StringKey.NEW_PLAYBOOK) : _s(StringKey.EDIT_PLAYBOOK) }</div>
				<div className="actions">
					<a className="button" onClick={ this.handleSave }><span className="icon ok"></span></a>
				</div>
			</header>
			<div className="content">
				<div className="view tabbed">
					<header>
						{ isNew ? null :
							<div className="radio tabs">
								{ playbookPermissions.canUpdate ? <label className={ activePage === 0 ? 'on' : '' } onClick={ this.goToInfoPage }><span>{ _s(StringKey.INFO) }</span></label> : null }
								{ playbookPermissions.canManageMembers ? <label className={ activePage === 1 ? 'on' : ''} onClick={ this.goToAccessPage }><span>{ _s(StringKey.ACCESS) }</span></label> : null }
								{ playbookPermissions.canUpdate ? <label className={ activePage === 2 ? 'on' : ''} onClick={ this.goToActionsPage }><span>{ _s(StringKey.ACTIONS) }</span></label> : null }
							</div>
						}
					</header>
					<div className="content swappable">
						{  (isNew && teamPermissions.canAddPlaybooks) || playbookPermissions.canUpdate ? <Page key="page0" pageState={ this.getPageState(0)}>
							<PlaybookInfoPage
								alertList={ alertList }
								fieldOptions={ viewState.settings.fieldOptions }
								isNew={ isNew }
								playbook={ mutatedPlaybook }
								playersPerSide={ map(viewState.settings.playersPerSide, (v, k) => Number(k)) }
								onChange={ this.handleChange }
							/>
						</Page> : null }

						{ playbookPermissions.canManageMembers ? <Page key="page1" pageState={ this.getPageState(1)}>
							<PlaybookAccessPage onBulkAccessChange={ this.handleBulkAccessChange } onMemberAccessChange={ this.handleMemberAccessChange } onSortClick={ this.handleAccessSortClick } playbook={ mutatedPlaybook } playerSortDirection={ playerSortDirection === 1 ? 'asc' : 'desc' } playerSortField={ playerSortKey } staffSortDirection={ staffSortDirection === 1 ? 'asc' : 'desc' } staffSortField={ staffSortKey } />
						</Page> : null }

						{ !isNew ? <Page key="page2" pageState={ this.getPageState(2)}>
							<PlaybookActionsPage alertList={ alertList } isNew={ isNew } onCopy={ this.copyPlaybook } onExport={ this.exportPlaybook } onUpsize={ this.onUpsize } onDelete={ this.handleDelete } onCopyToTeam={ this.onCopyToTeam } onArchive={ this.handleArchive } teamPermissions={ teamPermissions } playbookPermissions={ playbookPermissions }  />
						</Page> : null }
					</div>
				</div>
			</div>
		</div>;
	}

	private onCopyToTeam() {
		const { currentSubscription, currentTeam, currentUser, model } = getCurrentContext();
		const upsizeTargetTeams = filter(model.teams, (team: ITeam) => team.id !== currentTeam.id && team.currentSubscription.isActive() && !team.currentSubscription.isFreeTrial() && getTeamPermissions({ team, user: currentUser }).canTransferPlaybooks) as ITeam[];

		if (!upsizeTargetTeams.length || currentSubscription.isFreeTrial()) {
			actions.pushAlert({ title: _s(StringKey.TRANSFER_TO_IMPOSSIBLE_TITLE), message: _s(StringKey.TRANSFER_TO_IMPOSSIBLE_MESSAGE), mode: store.AlertMode.prompt, severity: store.AlertSeverity.info });
		} else {
			actions.pushModal({ component: CopyToTeamModal, props: { copyToTeam: this.copyToTeam, teams: upsizeTargetTeams, classNames: ['prompt'] } } );
		}
	}

	private copyToTeam(teamId: string) {

		this.setState({ processing: true}, async () => {
			let error = null;
			let targetTeam: ITeam;
			try {
				const { model } = getCurrentContext();
				const { mutatedPlaybook } = this.state;
				targetTeam = find(model.teams, { id: teamId} ) as ITeam;

				if (targetTeam) {
					const exportData = playbookHelper.exportPlaybook(mutatedPlaybook, model);

					actions.popModal();

					const importResult = playbookHelper.processPlaybookImport(exportData, targetTeam);

					await actions.addFullPlaybook(importResult.playbook, importResult.formations, importResult.plays, `playbook-copy-to-team-${targetTeam.id}`);
				}
			} catch (err) {
				error = err;
			}

			this.setState({ processing: false }, () => {
				if (!error) {
					actions.pushAlert({ title: _s(StringKey.TRANSFER_TO_COMPLETE_TITLE), message: _s(StringKey.TRANSFER_TO_COMPLETE_MESSAGE_TEMPLATE).replace('{name}', targetTeam.name), mode: store.AlertMode.prompt, severity: store.AlertSeverity.info });
				}
			});
		});
	}

	private onUpsize() {
		const { mutatedPlaybook } = this.state;
		const { viewState } = getCurrentContext();
		const playersPerSide = Object.keys(viewState.settings.playersPerSide || {}).map((v) => Number(v)).filter((v) => v > mutatedPlaybook.playersPerSide);

		if (!playersPerSide.length) {
			actions.pushAlert({ title: _s(StringKey.UPSIZE_IMPOSSIBLE_TITLE), message: _s(StringKey.UPSIZE_IMPOSSIBLE_MESSAGE), mode: store.AlertMode.prompt, severity: store.AlertSeverity.info });
		} else {
			actions.pushModal({ component: IncreaseSizeModal, props: { upsizePlaybook: this.upsizePlaybook, playersPerSide, classNames: ['prompt'] } } );
		}
	}
}

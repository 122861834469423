import { assign, find } from 'lodash';
import { StringDictionary } from 'playmaker-team-common/dist/shared/interfaces';
import * as validator from 'playmaker-team-common/dist/shared/validator';
import * as React from 'react';
import * as actions from '../actions';
import * as logger from '../logger';
import { IAlert } from '../store';
import * as store from '../store';
import { _s, StringKey } from '../strings';
import * as viewManager from '../viewManager';
import { AlertList } from './alert';
import { Page, PageState } from './page';
import { Spinner } from './spinner';

interface IRedemptionInfo {
	code: string;
	data?: StringDictionary;
}

interface Props {
	alerts: store.IAlert[];
	viewState: store.IViewState;
}

interface State {
	redemptionInfo: IRedemptionInfo;
	activePage: number;
	processing?: boolean;
}

export const EntryPage = ({ alertContainer, cancelClass= 'icon cancel', redemptionInfo, onCancel = viewManager.popModal, onRedeemClick, onKeyPress, updateInfo }) => {
	const enabled = !!redemptionInfo.code;

	return <React.Fragment>
		<header>
			<div className="actions">
				<a className="button" onClick={ onCancel }><span className={ cancelClass }></span></a>
			</div>
			<div className="title">{ _s(StringKey.CODE) }</div>
		</header>
		{ alertContainer }
		<div className="content scrollable">
			<div className="inner" onKeyPress={ onKeyPress }>
				<input type="text" value={ redemptionInfo.code } placeholder={ _s(StringKey.ENTER_PLAYMAKERX_CODE) } onChange={ (e) => updateInfo('code', e.target.value) } required />
				<div className="actions">
					<a className={ enabled ? 'button basic' : 'button basic disabled' } onClick={ onRedeemClick }><span>{ _s(StringKey.OK) }</span></a>
				</div>
			</div>
		</div>
	</React.Fragment>;
};

export class Redeem extends React.Component<Props, State> {
	constructor(props) {
		super(props);

		this.state = { redemptionInfo: { code: '' }, activePage: 0 };

		this.onRedeemClick = this.onRedeemClick.bind(this);
		this.updateInfo = this.updateInfo.bind(this);
		this.onEnterKeyPress = this.onEnterKeyPress.bind(this);

		this.logScreen();
	}

	public getPageState(key) {
		const { activePage } = this.state;

		if (key === activePage) {
			return PageState.IN;
		} else if (key < activePage) {
			return PageState.OUT;
		}

		return PageState.DEFAULT;
	}

	public logScreen() {
		const { activePage } = this.state;

		switch (activePage) {
		case 0:
			logger.logScreen('Redeem');
			break;
		default:
			// code...
			break;
		}
	}

	public updateInfo(key: string, value: string) {
		this.setState((previousState) => {
			const newState = assign({}, previousState) as State;

			newState.redemptionInfo[key] = value;

			return newState;
		});
	}

	public async onRedeemClick(e) {
		const { redemptionInfo } = this.state;

		if (redemptionInfo.code) {
			this.setState({ processing: true }, async () => {
				actions.clearAlerts();
				const result = await actions.redeemCode(redemptionInfo.code);

				if (result && !result.error) {
					viewManager.popModal();
				}

				this.setState({ processing: false }, () => {
					if (result) {
						actions.processRedemptionResult(result);
					}
				});
			});
		}
	}

	public onEnterKeyPress(e) {
		const { activePage } = this.state;

		if (e.key === 'Enter') {
			if (activePage === 0) {
				this.onRedeemClick(e);
			}
		}
	}

	public goToPage(page: number) {
		this.setState((previousState) => {
			const newState = assign({}, previousState) as State;

			newState.activePage = page;

			return newState;
		}, () => { this.logScreen(); });
	}

	public render() {
		const { alerts } = this.props;
		const { redemptionInfo, processing } = this.state;
		const alertContainer = <AlertList alerts={ alerts } />;

		return <React.Fragment>
			{ processing ? <Spinner /> : null }
			<Page key="page0" pageClasses={['page']} pageState={ this.getPageState(0) }>
				<EntryPage alertContainer={ alertContainer } redemptionInfo={ redemptionInfo } onRedeemClick={ this.onRedeemClick } updateInfo={ this.updateInfo }  onKeyPress={ this.onEnterKeyPress } />
			</Page>
		</React.Fragment>;
	}
}

import * as React from 'react';
import * as actions from '../actions';
import * as localImage from '../localImage';
import * as store from '../store';
import { CachedImage } from './cachedImage';

interface Props {
	canEdit?: boolean;
	className?: string;
	imageWidth: number;
	imageHeight: number;
	imageUrl?: string;
	letterbox?: boolean;
	onImage: (string) => void;
	onRemoveImage: () => void;
}

interface State {
}

export class Avatar extends React.Component<Props, State> {
	constructor(props) {
		super(props);

		this.handleFileChange = this.handleFileChange.bind(this);
		this.handleRemoveClick = this.handleRemoveClick.bind(this);
	}

	public handleFileChange(e) {
		e.preventDefault();
		const file = e.target.files[0];

		if (file) {
			localImage.create(file).fitToBounds(this.props.imageWidth, this.props.imageHeight, this.props.letterbox).then((result) => {
				this.props.onImage(result);
			});
		}
	}

	public handleRemoveClick(e) {
		e.preventDefault();

		this.props.onRemoveImage();
	}

	public render() {
		const style = {};
		const { className, imageUrl, canEdit } = this.props;
		const classNames = className ? ['tile photo', className] : ['tile photo'];

		return (
			<div className="photoControl">
				<div className={ classNames.join(' ') }>
					{ canEdit ? <div className="button"><span className="icon camera"></span></div> : null }
					{ imageUrl && <CachedImage key={ imageUrl || 'empty' } src={ imageUrl } /> }
					{ canEdit ? <input type="file" onChange={this.handleFileChange} accept="image/*" /> : null }
				</div>
				{ (imageUrl && canEdit) ? <a className="button" onClick={ this.handleRemoveClick }><span className="icon removeItem"></span><span>Remove</span></a> : null }
			</div>
		);
	}
}

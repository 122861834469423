import { filter } from 'lodash';
import * as React from 'react';
import { Transition } from 'react-transition-group';
import { IPoint, vectorUtil } from '../models/vector';

interface Props {
	children: any;
	classNames?: string[];
	index?: number;
}

interface State {

}

export class DecorationTransition extends React.Component<Props, State> {
	constructor(props) {
		super(props);
	}

	public render() {
		const {children, ...props} = this.props;

		return (<Transition timeout={{ enter: 10, exit: 210 }} appear={ true } { ...props }>{ (state) => {
			let { classNames= [], index = 0 } = props;

			classNames = filter(classNames, (c) => ['decoration', 'in', 'out'].indexOf(c) === -1);
			classNames.unshift('decoration');

			// console.log(state);

			if (['entered'].indexOf(state) !== -1) {
				classNames.push('in');
			} else {
				classNames.push('out');
			}

			return <div className={ classNames.join(' ') } style={{ zIndex: 200 + index }}>
				{ !!children && React.cloneElement(children as any, props)}
			</div>;
		} }</Transition>);
	}
}

import * as React from 'react';

export function Arena25x66({ teamLabel }) {

	return <div className="field">
		<svg viewBox="0 0 1024 2304">
			<svg className="markings" x="10%" y="6%" width="80%" height="88%">
				<svg className="boundary" x="-3%" y="-1%" width="106%" height="102%">
					<rect className="endline" x="0%" y="0%" width="100%" height="1%"></rect>
					<rect className="endline" x="0%" y="99%" width="100%" height="1%"></rect>
					<rect className="sideline" x="0%" y="0%" width="3%" height="100%"></rect>
					<rect className="sideline" x="97%" y="0%" width="3%" height="100%"></rect>
				</svg>
				<svg className="endzone" x="0%" y="0%" width="100%" height="12.125%">
					<text x="48%" y="58%">{ teamLabel }</text>
					<line className="yardline" x1="0" x2="100%" y1="100%" y2="100%" />
				</svg>
				<svg className="tenYards" x="0%" y="12.125%" height="15.15%">
					<line className="hash" x1="43.53%" x2="44.71%" y1="10%" y2="10%" />
					<line className="hash" x1="55.29%" x2="56.47%" y1="10%" y2="10%" />
					<line className="hash" x1="43.53%" x2="44.71%" y1="20%" y2="20%" />
					<line className="hash" x1="49.41%" x2="50.59%" y1="20%" y2="20%" />
					<line className="hash" x1="55.29%" x2="56.47%" y1="20%" y2="20%" />
					<line className="hash" x1="43.53%" x2="44.71%" y1="30%" y2="30%" />
					<line className="hash" x1="55.29%" x2="56.47%" y1="30%" y2="30%" />
					<line className="hash" x1="43.53%" x2="44.71%" y1="40%" y2="40%" />
					<line className="hash" x1="55.29%" x2="56.47%" y1="40%" y2="40%" />
					<svg x="22%" y="44%">
						<polygon transform="translate(-16 -20)" points="0,0 -10,0 -5,-20" className="triangle" />
						<text transform="rotate(90 0 0)">5</text>
					</svg>
					<line className="hash" x1="34.12%" x2="34.12%" y1="47%" y2="53%" />
					<line className="hash" x1="65.88%" x2="65.88%" y1="47%" y2="53%" />
					<svg x="78%" y="44%">
						<polygon transform="translate(26 -20)" points="0,0 -10,0 -5,-20" className="triangle" />
						<text transform="rotate(-90 0 0)">5</text>
					</svg>
					<line className="yardline" x1="0" x2="100%" y1="50%" y2="50%" />
					<line className="hash" x1="43.53%" x2="44.71%" y1="60%" y2="60%" />
					<line className="hash" x1="55.29%" x2="56.47%" y1="60%" y2="60%" />
					<line className="hash" x1="43.53%" x2="44.71%" y1="70%" y2="70%" />
					<line className="hash" x1="55.29%" x2="56.47%" y1="70%" y2="70%" />
					<line className="hash" x1="43.53%" x2="44.71%" y1="80%" y2="80%" />
					<line className="hash" x1="55.29%" x2="56.47%" y1="80%" y2="80%" />
					<line className="hash" x1="43.53%" x2="44.71%" y1="90%" y2="90%" />
					<line className="hash" x1="55.29%" x2="56.47%" y1="90%" y2="90%" />
					<line className="yardline" x1="0" x2="100%" y1="100%" y2="100%" />
				</svg>
				<svg className="tenYards" x="0%" y="27.25%" height="15.15%">
					<svg x="22%" y="1.5%">
						<polygon transform="translate(-16 -30)" points="0,0 -10,0 -5,-20" className="triangle" />
						<text transform="rotate(90 0 0)">1 0</text>
					</svg>
					<line className="hash" x1="34.12%" x2="34.12%" y1="-3%" y2="3%" />
					<line className="hash" x1="65.88%" x2="65.88%" y1="-3%" y2="3%" />
					<svg x="78%" y="-1%">
						<polygon transform="translate(26 -30)" points="0,0 -10,0 -5,-20" className="triangle" />
						<text transform="rotate(-90 0 0)">1 0</text>
					</svg>
					<line className="hash" x1="43.53%" x2="44.71%" y1="10%" y2="10%" />
					<line className="hash" x1="55.29%" x2="56.47%" y1="10%" y2="10%" />
					<line className="hash" x1="43.53%" x2="44.71%" y1="20%" y2="20%" />
					<line className="hash" x1="55.29%" x2="56.47%" y1="20%" y2="20%" />
					<line className="hash" x1="43.53%" x2="44.71%" y1="30%" y2="30%" />
					<line className="hash" x1="55.29%" x2="56.47%" y1="30%" y2="30%" />
					<line className="hash" x1="43.53%" x2="44.71%" y1="40%" y2="40%" />
					<line className="hash" x1="55.29%" x2="56.47%" y1="40%" y2="40%" />
					<svg x="22%" y="51.5%">
						<polygon transform="translate(-16 -30)" points="0,0 -10,0 -5,-20" className="triangle" />
						<text transform="rotate(90 0 0)">1 5</text>
					</svg>
					<line className="hash" x1="34.12%" x2="34.12%" y1="47%" y2="53%" />
					<line className="hash" x1="65.88%" x2="65.88%" y1="47%" y2="53%" />
					<svg x="78%" y="49%">
						<polygon transform="translate(26 -30)" points="0,0 -10,0 -5,-20" className="triangle" />
						<text transform="rotate(-90 0 0)">1 5</text>
					</svg>
					<line className="yardline" x1="0" x2="100%" y1="50%" y2="50%" />
					<line className="hash" x1="43.53%" x2="44.71%" y1="60%" y2="60%" />
					<line className="hash" x1="55.29%" x2="56.47%" y1="60%" y2="60%" />
					<line className="hash" x1="43.53%" x2="44.71%" y1="70%" y2="70%" />
					<line className="hash" x1="55.29%" x2="56.47%" y1="70%" y2="70%" />
					<line className="hash" x1="43.53%" x2="44.71%" y1="80%" y2="80%" />
					<line className="hash" x1="55.29%" x2="56.47%" y1="80%" y2="80%" />
					<line className="hash" x1="43.53%" x2="44.71%" y1="90%" y2="90%" />
					<line className="hash" x1="55.29%" x2="56.47%" y1="90%" y2="90%" />
					<line className="yardline" x1="0" x2="100%" y1="100%" y2="100%" />
				</svg>
				<svg className="tenYards" x="0%" y="42.425%" height="15.15%">
					<svg x="22%" y="0%">
						<polygon transform="translate(-16 -35)" points="0,0 -10,0 -5,-20" className="triangle" />
						<text transform="rotate(90 0 0)">2 0</text>
					</svg>
					<line className="hash" x1="34.12%" x2="34.12%" y1="-3%" y2="3%" />
					<line className="hash" x1="65.88%" x2="65.88%" y1="-3%" y2="3%" />
					<svg x="78%" y="0%">
						<polygon transform="translate(26 -35)" points="0,0 -10,0 -5,-20" className="triangle" />
						<text transform="rotate(-90 0 0)">2 0</text>
					</svg>
					<line className="hash" x1="43.53%" x2="44.71%" y1="10%" y2="10%" />
					<line className="hash" x1="55.29%" x2="56.47%" y1="10%" y2="10%" />
					<line className="hash" x1="43.53%" x2="44.71%" y1="20%" y2="20%" />
					<line className="hash" x1="55.29%" x2="56.47%" y1="20%" y2="20%" />
					<line className="hash" x1="43.53%" x2="44.71%" y1="30%" y2="30%" />
					<line className="hash" x1="55.29%" x2="56.47%" y1="30%" y2="30%" />
					<line className="hash" x1="43.53%" x2="44.71%" y1="40%" y2="40%" />
					<line className="hash" x1="55.29%" x2="56.47%" y1="40%" y2="40%" />
					<svg x="22%" y="50%">
						<text transform="rotate(90 0 0)">2 5</text>
					</svg>
					<line className="hash" x1="34.12%" x2="34.12%" y1="47%" y2="53%" />
					<line className="hash" x1="65.88%" x2="65.88%" y1="47%" y2="53%" />
					<svg x="78%" y="50%">
						<text transform="rotate(-90 0 0)">2 5</text>
					</svg>
					<line className="yardline" x1="0" x2="100%" y1="50%" y2="50%" />
					<line className="hash" x1="43.53%" x2="44.71%" y1="60%" y2="60%" />
					<line className="hash" x1="55.29%" x2="56.47%" y1="60%" y2="60%" />
					<line className="hash" x1="43.53%" x2="44.71%" y1="70%" y2="70%" />
					<line className="hash" x1="55.29%" x2="56.47%" y1="70%" y2="70%" />
					<line className="hash" x1="43.53%" x2="44.71%" y1="80%" y2="80%" />
					<line className="hash" x1="55.29%" x2="56.47%" y1="80%" y2="80%" />
					<line className="hash" x1="43.53%" x2="44.71%" y1="90%" y2="90%" />
					<line className="hash" x1="55.29%" x2="56.47%" y1="90%" y2="90%" />
					<line className="yardline" x1="0" x2="100%" y1="100%" y2="100%" />
				</svg>
				<svg className="tenYards" x="0%" y="57.575%" height="15.15%">
					<svg x="22%" y="0%">
						<polygon transform="translate(-16 35)" points="0,0 -10,0 -5,20" className="triangle" />
						<text transform="rotate(90 0 0)">2 0</text>
					</svg>
					<line className="hash" x1="34.12%" x2="34.12%" y1="-3%" y2="3%" />
					<line className="hash" x1="65.88%" x2="65.88%" y1="-3%" y2="3%" />
					<svg x="78%" y="0%">
						<polygon transform="translate(26 35)" points="0,0 -10,0 -5,20" className="triangle" />
						<text transform="rotate(-90 0 0)">2 0</text>
					</svg>
					<line className="hash" x1="43.53%" x2="44.71%" y1="10%" y2="10%" />
					<line className="hash" x1="55.29%" x2="56.47%" y1="10%" y2="10%" />
					<line className="hash" x1="43.53%" x2="44.71%" y1="20%" y2="20%" />
					<line className="hash" x1="55.29%" x2="56.47%" y1="20%" y2="20%" />
					<line className="hash" x1="43.53%" x2="44.71%" y1="30%" y2="30%" />
					<line className="hash" x1="55.29%" x2="56.47%" y1="30%" y2="30%" />
					<line className="hash" x1="43.53%" x2="44.71%" y1="40%" y2="40%" />
					<line className="hash" x1="55.29%" x2="56.47%" y1="40%" y2="40%" />
					<svg x="22%" y="51.5%">
						<polygon transform="translate(-16 30)" points="0,0 -10,0 -5,20" className="triangle" />
						<text transform="rotate(90 0 0)">1 5</text>
					</svg>
					<line className="hash" x1="34.12%" x2="34.12%" y1="47%" y2="53%" />
					<line className="hash" x1="65.88%" x2="65.88%" y1="47%" y2="53%" />
					<svg x="78%" y="49%">
						<polygon transform="translate(26 30)" points="0,0 -10,0 -5,20" className="triangle" />
						<text transform="rotate(-90 0 0)">1 5</text>
					</svg>
					<line className="yardline" x1="0" x2="100%" y1="50%" y2="50%" />
					<line className="hash" x1="43.53%" x2="44.71%" y1="60%" y2="60%" />
					<line className="hash" x1="55.29%" x2="56.47%" y1="60%" y2="60%" />
					<line className="hash" x1="43.53%" x2="44.71%" y1="70%" y2="70%" />
					<line className="hash" x1="55.29%" x2="56.47%" y1="70%" y2="70%" />
					<line className="hash" x1="43.53%" x2="44.71%" y1="80%" y2="80%" />
					<line className="hash" x1="55.29%" x2="56.47%" y1="80%" y2="80%" />
					<line className="hash" x1="43.53%" x2="44.71%" y1="90%" y2="90%" />
					<line className="hash" x1="55.29%" x2="56.47%" y1="90%" y2="90%" />
					<line className="yardline" x1="0" x2="100%" y1="100%" y2="100%" />
				</svg>
				<svg className="tenYards" x="0%" y="72.725%" height="15.15%">
					<svg x="22%" y="1.5%">
						<polygon transform="translate(-16 30)" points="0,0 -10,0 -5,20" className="triangle" />
						<text transform="rotate(90 0 0)">1 0</text>
					</svg>
					<line className="hash" x1="34.12%" x2="34.12%" y1="-3%" y2="3%" />
					<line className="hash" x1="65.88%" x2="65.88%" y1="-3%" y2="3%" />
					<svg x="78%" y="-1%">
						<polygon transform="translate(26 30)" points="0,0 -10,0 -5,20" className="triangle" />
						<text transform="rotate(-90 0 0)">1 0</text>
					</svg>
					<line className="hash" x1="43.53%" x2="44.71%" y1="10%" y2="10%" />
					<line className="hash" x1="55.29%" x2="56.47%" y1="10%" y2="10%" />
					<line className="hash" x1="43.53%" x2="44.71%" y1="20%" y2="20%" />
					<line className="hash" x1="55.29%" x2="56.47%" y1="20%" y2="20%" />
					<line className="hash" x1="43.53%" x2="44.71%" y1="30%" y2="30%" />
					<line className="hash" x1="55.29%" x2="56.47%" y1="30%" y2="30%" />
					<line className="hash" x1="43.53%" x2="44.71%" y1="40%" y2="40%" />
					<line className="hash" x1="55.29%" x2="56.47%" y1="40%" y2="40%" />
					<svg x="22%" y="56%">
						<polygon transform="translate(-16 20)" points="0,0 -10,0 -5,20" className="triangle" />
						<text transform="rotate(90 0 0)">5</text>
					</svg>
					<line className="hash" x1="34.12%" x2="34.12%" y1="47%" y2="53%" />
					<line className="hash" x1="65.88%" x2="65.88%" y1="47%" y2="53%" />
					<svg x="78%" y="56%">
						<polygon transform="translate(26 20)" points="0,0 -10,0 -5,20" className="triangle" />
						<text transform="rotate(-90 0 0)">5</text>
					</svg>
					<line className="yardline" x1="0" x2="100%" y1="50%" y2="50%" />
					<line className="hash" x1="43.53%" x2="44.71%" y1="60%" y2="60%" />
					<line className="hash" x1="55.29%" x2="56.47%" y1="60%" y2="60%" />
					<line className="hash" x1="43.53%" x2="44.71%" y1="70%" y2="70%" />
					<line className="hash" x1="55.29%" x2="56.47%" y1="70%" y2="70%" />
					<line className="hash" x1="43.53%" x2="44.71%" y1="80%" y2="80%" />
					<line className="hash" x1="49.41%" x2="50.59%" y1="80%" y2="80%" />
					<line className="hash" x1="55.29%" x2="56.47%" y1="80%" y2="80%" />
					<line className="hash" x1="43.53%" x2="44.71%" y1="90%" y2="90%" />
					<line className="hash" x1="55.29%" x2="56.47%" y1="90%" y2="90%" />
					<line className="yardline" x1="0" x2="100%" y1="100%" y2="100%" />
				</svg>
				<svg className="endzone" x="0" y="85.7%" width="100%" height="12.125%">
					<text x="48%" y="75%">OPPONENT</text>
				</svg>
			</svg>
		</svg>
	</div>;
}

import { clear as clearDb, createStore, del, get, keys, set } from 'idb-keyval';

const KEY_PREFIX = 'pmt-';

export const SCHEMA_VERSION_STORAGE_KEY = 'schema-version';
export const AUTH_TOKEN_STORAGE_KEY = 'auth-token';
export const CURRENT_USER_ID_STORAGE_KEY = 'current-user-id';
export const CURRENT_TEAM_ID_STORAGE_KEY = 'current-team-id';
export const IMPERSONATOR_TOKEN_STORAGE_KEY = 'impersonator-token';
export const UNSYNCED_ENTITY_STORAGE_KEY = 'unsynced-entities'

const _useLocalStorage = false; // !window.indexedDB; // this was to work around iOS security that prevents "crossdomain" indexedDB
let _apiStore = !_useLocalStorage ? createStore('playmaker-team', 'keyval') : undefined;

function _prepOptions(keyOrOptions: string | Record<string, unknown>, valueOrNull?) { // allows key to be a key or more detailed object
	if (typeof keyOrOptions === 'string') {
		return { key: keyOrOptions, value: valueOrNull };
	}
	return keyOrOptions;
}

export function init() {
	_apiStore = !_useLocalStorage ? createStore('playmaker-team', 'keyval') : undefined;
}

export async function getItem(keyOrOptions: string | Record<string, unknown>) {
	const options = _prepOptions(keyOrOptions);

	return await getStorageItem(options.key);
}

export async function getKeys(): Promise<string[]> {
	let items;

	if (_useLocalStorage) {
		items = [];
		for (let i = 0; i < localStorage.length; i++) {
			items.push(localStorage.key(i).substr(KEY_PREFIX.length));
		}
	} else {
		items = (await keys(_apiStore))??[];
		items = items.map((val) => val.substr(KEY_PREFIX.length));
	}

	return Promise.resolve(items);
}

export async function setItem(keyOrOptions: string | Record<string, unknown>, valueOrNull) {
	if (valueOrNull && typeof valueOrNull.toDocument === 'function') {
		valueOrNull = valueOrNull.toDocument();
	}

	const options = _prepOptions(keyOrOptions, valueOrNull);

	await setStorageItem(options.key, options.value);
}

export async function removeItem(keyOrOptions: string | Record<string, unknown>) {
	const options = _prepOptions(keyOrOptions);

	if (_useLocalStorage) {
		localStorage.removeItem(getStorageKey(options.key));
	} else {
		await del(getStorageKey(options.key), _apiStore);
	}

	return Promise.resolve();
}

export async function hasItem(keyOrOptions: string | Record<string, unknown>) {
	const options = _prepOptions(keyOrOptions);
	let result;

	if (_useLocalStorage) {
		result = localStorage.getItem(getStorageKey(options.key));
	} else {
		result = await getStorageItem(options.key);
	}

	return Promise.resolve(!!result);
}

export async function clear() {
	if (_useLocalStorage) {
		localStorage.clear();
	} else {
		clearDb(_apiStore);
	}
	return Promise.resolve();
}

function getStorageKey(key) {
	return `${KEY_PREFIX}${key}`;
}

async function getStorageItem(key) {
	if (_useLocalStorage) {
		const result = localStorage.getItem(getStorageKey(key));

		return JSON.parse(result);
	} else {
		const storageKey = getStorageKey(key);
		// nativeService.debug(`get ${storageKey} from storage`);
		return await get(storageKey, _apiStore);
	}

}

async function setStorageItem(key, value) {
	if (_useLocalStorage) {
		localStorage.setItem(getStorageKey(key), JSON.stringify(value));
		_logStorageSize();
	} else {
		await set(getStorageKey(key), value, _apiStore);
	}
}

function _logStorageSize() {
	let total = 0;
	for (const key in localStorage) {
		let itemLength = 0;
		if (key.indexOf(KEY_PREFIX) === 0) {
			itemLength = ((localStorage[key].length + key.length) * 2);
			total += itemLength;
			console.log(key.substr(0, 50) + ' = ' + (itemLength / 1024).toFixed(2) + ' KB');
		}
	}

	console.log('Total = ' + (total / 1024).toFixed(2) + ' KB');
}

import { assign, first, last, sortBy } from 'lodash';
import { createFactory } from 'playmaker-team-common/dist/shared/modelFactory';
import { ModelSet } from 'playmaker-team-common/dist/shared/modelSet';
import { default as validator } from 'playmaker-team-common/dist/shared/validator';
import { default as valueFilters } from 'playmaker-team-common/dist/shared/valueFilters';
import { default as coreModel, ICoreModel } from './coreModel';
import { default as routeSectionFactory, IRouteSection } from './routeSection';

export interface IRoute extends ICoreModel {
	label: string;
	sections: ModelSet<IRouteSection>;
	popSection: () => IRouteSection;
	first: () => IRouteSection;
	last: () => IRouteSection;
}

export default createFactory<IRoute>('Route', assign({}, coreModel, {
	label: {
	},
	sections: {
		setFilters: valueFilters.toModelSet(routeSectionFactory),
		rules: validator.validModel(routeSectionFactory.rules()),
		defaultValue: {},
	},
	popSection() {
		const orderedSections: IRouteSection[] = sortBy(this.sections.values, 'sortIndex');
		let removed: IRouteSection;

		if (orderedSections.length) {
			removed = orderedSections.pop();

			this.sections.remove(removed.id);
		}

		return removed;
	},
	first() {
		return first(sortBy(this.sections.values, 'sortIndex'));
	},
	last() {
		return last(sortBy(this.sections.values, 'sortIndex'));
	},
}));

import { createId } from 'playmaker-team-common/dist/shared/createId';
import { createFactory } from 'playmaker-team-common/dist/shared/modelFactory';
import { default as validator } from 'playmaker-team-common/dist/shared/validator';

export interface IPlayList {
	id: string;
	relatedId: string; // the category or formation id that owns this play list
	playIds: string;
	playIdList: string[]; // comma separated, ordered list of play ids
	push: any;
	pop: any;
	splice: any;
	remove: any;
}

export default createFactory<IPlayList>('PlayList', {
	id: {
		writable: false,
		defaultValue: () => {
			const id = createId();

			return `i${id.replace(/-/g, '')}`;
		},
	},
	relatedId: {
	},
	playIds: {
		setFilters: [(val) => {
			return val === '' || val === null ? undefined : val;
		}],
	},
	playIdList: {
		writable: false,
		nonSerialized: true,
	},
	_get_playIdList() {
		const ids = this.playIds || '';
		return (this.playIds && this.playIds.split(',')) || [];
	},
	pop() {
		const ids = this.playIdList;
		const result = Array.prototype.pop.apply(ids, arguments);

		this.playIds = ids.join(',');

		return result;
	},
	push() {
		const ids = this.playIdList;
		const result = Array.prototype.push.apply(ids, arguments);

		this.playIds = ids.join(',');

		return result;
	},
	splice() {
		const ids = this.playIdList;
		const result = Array.prototype.splice.apply(ids, arguments);

		this.playIds = ids.join(',');

		return result;
	},
	remove(id) {
		const ids = this.playIdList;
		const result = Array.prototype.splice.apply(ids, [id, 1]);

		this.playIds = ids.join(',');

		return result;
	},
});

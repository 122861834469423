import { default as validator } from 'playmaker-team-common/dist/shared/validator';
import { default as valueFilters } from 'playmaker-team-common/dist/shared/valueFilters';

export interface ISortable {
	sortIndex: number;
}

export default {
	sortIndex: {
		setFilters: valueFilters.toNumber,
		defaultValue: 0,
	},
};

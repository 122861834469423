import { map } from 'lodash';
import * as React from 'react';
import * as systemActions from '../actions';
import { AlertSeverity, IAction, IAlert } from '../store';
import { _s, StringKey } from '../strings';

// Button className variations include cancel, save, delete
export function Prompt({ id, message, severity, title, actions }: {id?: string, message: string, severity: AlertSeverity, title: string, actions?: IAction[]}) {
	actions = actions || [ { label: _s(StringKey.OK), action: () => { systemActions.deleteAlert(id); }}];
	const buttons = map(actions, (action: IAction) => <a key={ `action-${action.label}`} className={ `button basic ${action.className}` } onClick={ action.action } ><span>{ action.label }</span></a>);

	return <div className="view">
		<header>
			<div className="title">{ title }</div>
		</header>
		<div className="content scrollable">
			<div className="inner">
				<p>{ message }</p>

				<div className="actions">
					{ buttons }
				</div>
			</div>
		</div>
	</div>;
}

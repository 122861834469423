import { filter, forEach, sortBy } from 'lodash';
import * as React from 'react';
import { current as getCurrentContext } from '../componentContext';
import { DiagramRenderFlags, IDiagram } from '../models/diagramModel';
import { IRouteTree, default as routeTreeFactory } from '../models/routeTree';
import * as store from '../store';
import { Diagram } from './diagram';
import { PlayerToolbarPlay } from './playerToolbarPlay';
import { default as annotationFactory,  AnnotationType, IAnnotation } from '../models/annotation';
import { DiagramControl } from './diagramControl';
import { IPlayer } from '../models/player';
import { RouteTreeContext, RouteTreeProvider } from './routeTreeProvider';

interface RouteTreeProps {
	alerts?: store.IAlert[];
	appState?: store.IAppState;
	mateId?: string;
	routeTree: IDiagram;
	nonInteractive?: boolean;
	useRenderLabels?: boolean;
	saveRoute?: (mutatedDiagram: IRouteTree) => void;
}

function MutableRouteTree(props: Omit<RouteTreeProps, 'routeTree' | 'saveRoute'>) {
	const { mateId } = props;
	const { currentRouteTree, saveRouteTree } = React.useContext(RouteTreeContext);

	const saveRoute = React.useCallback((mutatedDiagram: IRouteTree) => {
		const targetRouteTree = routeTreeFactory(currentRouteTree);

		if(mutatedDiagram.mates.count === 1) {
			const changeMate = mutatedDiagram.mates.values[0];

			targetRouteTree.mates[changeMate.id] = changeMate;
		}
		else if(mutatedDiagram.mates.count === 0 && !!mateId) {
			targetRouteTree.mates.remove(mateId);
		}
		else {
			targetRouteTree.notes = mutatedDiagram.notes;
		}
		saveRouteTree(targetRouteTree);
	}, [saveRouteTree, mateId]);
	return <RouteTree { ...props } routeTree={ currentRouteTree } saveRoute={ saveRoute } />
}

export function RouteTree({ alerts, mateId, routeTree, nonInteractive, saveRoute, useRenderLabels }: RouteTreeProps) {
	const { viewState: { lastCreatedDiagramId }} = getCurrentContext();
	const isComposite = !mateId;
	const fieldKey = isComposite? 'routeTreeComposite': 'routeTree';
	const decorations: IAnnotation[] = [];
	let routeTreeModel = routeTreeFactory(routeTree);
	let renderFlags = DiagramRenderFlags.showField | routeTreeModel.renderFlags;

	if (!routeTree) {
		return null;
	}

	// be sure sortIndexes are clean, whole numbers
	forEach(sortBy(routeTreeModel.mates.values, 'sortIndex'), (mate: IPlayer, index) => {
		mate.sortIndex = index;
	});

	// transform the routeTree for rendering
	if(isComposite) {
		const doc = routeTreeModel.toDocument();

		const flippedMates = [];
		for(const id in doc.mates) {
			const mate = doc.mates[id];

			mate.loc = { x: 0.325, y: mate.loc.y };

			flippedMates.push({...mate, ...{ id: `flipped-${mate.id}`, sortIndex: mate.sortIndex + 20}});
		}

		routeTreeModel = routeTreeFactory(doc);
		routeTreeModel.flipHorizontally();

		for(const flipped of flippedMates) {
			routeTreeModel.mates.add(flipped)
		}

		forEach(sortBy(routeTreeModel.mates.values, 'sortIndex'), (mate: IPlayer) => {
			mate.endpointLabel = `${mate.sortIndex >= 20? (mate.sortIndex - 20): mate.sortIndex}`;
		});

		decorations.push(annotationFactory({
			subType: AnnotationType.Ball,
			loc: {
				x: 0.5,
				y: 0.5
			}
		}));
	}
	else {
		routeTreeModel.mates = forEach(filter(routeTreeModel.mates, m => m.id === mateId), m => m.renderLabel = useRenderLabels? undefined: `${m.sortIndex}`);
		if(!nonInteractive) {
			renderFlags = renderFlags | DiagramRenderFlags.interactive | DiagramRenderFlags.interactiveRoutes;
			// console.log(`render flags: ${renderFlags}`);
		}

		decorations.push(annotationFactory({
			subType: AnnotationType.Ball,
			loc: {
				x: 0.615,
				y: 0.5
			}
		}));
	}

	if(routeTreeModel.mates.count === 0) {
		console.log('no mates mate', routeTree);
	}

	return nonInteractive? 
		<DiagramControl 
			ballLocation={ routeTreeModel.ballLocation } 
			className={ isComposite? 'linesRouteTreeComposite': 'routeTreeRoute linesRouteTree' } 
			diagram={ routeTreeModel } 
			decorations={ decorations } 
			lineOfScrimage={ 0.5 } 
			renderFlags={ renderFlags } 
			fieldOptions={ { key: fieldKey }} /> :
		<Diagram
			key={ routeTreeModel.id }
			alerts={ alerts }
			className={ isComposite? 'linesRouteTreeComposite': 'routeTreeRoute linesRouteTree' } 
			fieldKey={ fieldKey }
			initialLocked={ !lastCreatedDiagramId || lastCreatedDiagramId !== mateId }
			posture={ routeTreeModel.posture }
			storeModel={ routeTreeModel }
			decorations={ decorations }
			saveModel={ saveRoute }
			modelFactory={ routeTreeFactory }
			PlayerToolbar={ PlayerToolbarPlay }
			renderFlags={ renderFlags }
		/>;
}

export function RouteTreeRoute({ alerts, appState }: { alerts: store.IAlert[], appState: store.IAppState}) {
	const { viewState: { currentRoute } } = getCurrentContext();
	const currentMateId = currentRoute.params.mateid;

	return <RouteTreeProvider appState={appState}>
		<MutableRouteTree alerts={ alerts} appState={ appState } mateId={ currentMateId } />
	</RouteTreeProvider>
}

import { createId } from 'playmaker-team-common/dist/shared/createId';
import { IModel } from 'playmaker-team-common/dist/shared/interfaces';
import { default as valueFilters } from 'playmaker-team-common/dist/shared/valueFilters';
import { IUser } from './user';

export interface ICoreModel extends IModel {
	id: string; // GUID or ObjectID
	toViewDocument: (ctx?: object) => {[key: string]: any};
}

export default {
	id: {
		writable: false,
		defaultValue: createId,
	},
};

export function stripDashes(val: string) {
	if (val) {
		return `i${val.replace(/-/g, '')}`;
	}
	return val;
}

import React, { useCallback, useState } from 'react';
import { map, orderBy } from 'lodash'
import { current as getCurrentContext } from '../componentContext';
import { transferTeamOwnership } from '../dataService';
import { pushModal } from '../actions';
import * as viewManager from '../viewManager';
import { StringKey, _s } from '../strings';
import { ITeamMember, TeamRole } from '../models/teamMember';

function TransferCompleteModal() {
	const { currentSubscription } = getCurrentContext();
	const isActiveSubscription = currentSubscription?.isActive();

	return <div className="view">
		<header>
			<div className="title">{_s(StringKey.TRANSFER_OWNERSHIP_COMPLETE_TITLE)}</div>
		</header>
		<div className="content scrollable">
			<div className="inner">
				<p>{ _s(StringKey.TRANSFER_OWNERSHIP_COMPLETE_MESSAGE) }</p>
				<div className="actions">
					<a className="button basic" onClick={isActiveSubscription? viewManager.popModal: viewManager.reloadApp}><span>{_s(StringKey.OK)}</span></a>
				</div>
			</div>
		</div>
	</div>;
}

export function TransferOwnershipModal({newOwner, onCancel}: { newOwner: ITeamMember, onCancel?: () => void }) {
	const [confirmText, setConfirmText] = useState('');
	const { currentSubscription, currentTeam } = getCurrentContext();
	const isConfirmed = confirmText.toLowerCase() === _s(StringKey.TRANSFER).toLowerCase();

	const doTransfer = useCallback(async () => {
		// not really worrying about a failure here - they can contact support
		const result = await transferTeamOwnership(currentTeam.id, newOwner, currentSubscription?.isActive());

		viewManager.popModal();

		if(result) {
			pushModal({
				component: TransferCompleteModal,
				props: { classNames: ['prompt'] }
			});
		}

	}, [currentSubscription, currentTeam, newOwner]);

	return  <div className="view riskConfirmation warning">
		<header>
			<div className="title">{_s(StringKey.TRANSFER_OWNERSHIP_QUESTION)}</div>
		</header>
		<div className="content scrollable">
			<div className="inner">
				<div className="icon attention transferOwnership"></div>
				<p>{ _s(StringKey.TRANSFER_OWNERSHIP_PROMPT_MESSAGE) }</p>
				<input type="text" placeholder={_s(StringKey.TYPE_TRANSFER_HERE)} required value={confirmText} onChange={e => setConfirmText(e.target.value) } />
				<div className="actions">
					<a className={ isConfirmed? 'button basic warning': 'button basic warning disabled' } onClick={ isConfirmed? () => doTransfer(): undefined}><span>{_s(StringKey.TRANSFER_OWNERSHIP)}</span></a>
					<a className="button basic" onClick={onCancel || viewManager.popModal}><span>{_s(StringKey.CANCEL)}</span></a>
				</div>
			</div>
		</div>
	</div>;
}

export function TransferOwnershipTeamMemberSelector() {
	const [selectedItem, setSelectedItem] = useState<ITeamMember>(null);
	const { currentTeam } = getCurrentContext();
	const teamMembers = orderBy(currentTeam?.members.values.filter(tm => tm.userId && tm.role !== TeamRole.Owner), ['role', 'fullName'], ['desc', 'asc']);
	const openTransferModal = useCallback(() => {
		viewManager.popModal();
		viewManager.pushModal({
			component: TransferOwnershipModal,
			props: { classNames: ['prompt'], newOwner: selectedItem },
			screenName: 'TransferOwnershipFromMemberSelector'
		});
	}, [selectedItem]);

	return <div className="view">
		<header>
			<div className="title">{ _s(StringKey.SELECT_A_TEAM_MEMBER) }</div>
		</header>
		<div className="content scrollable">
			<div className="inner">
				<div className="group" style={ { height: '130px', marginBottom: '20px'} }>
					<div className="list scrollable">
						{
							map(teamMembers, (member: ITeamMember) => {
								const isSelected = selectedItem === member;

								return <div key={ member.id } className="checkbox" onClick={ () => setSelectedItem(isSelected? null: member) }><label className={ isSelected ? 'on' : null }><span className="icon checkmark"></span><span>{ member.fullName }</span></label></div>;
							})
						}
					</div>
				</div>

				<div className="actions">
					<a className="button basic cancel" onClick={ viewManager.popModal }><span>{ _s(StringKey.CANCEL) }</span></a>
					<a className={ selectedItem ? 'button basic' : 'button basic disabled'} onClick={ openTransferModal }><span>{ _s(StringKey.TRANSFER_OWNERSHIP) }</span></a>
				</div>
			</div>
		</div>
	</div>;
}
import { forEach, map } from 'lodash';
import * as React from 'react';
import { current as getCurrentContext } from '../componentContext';
import { GamePhase } from '../models/diagramModel';
import { IFormation } from '../models/formation';
import { IPlay } from '../models/play';
import { IPlaybook } from '../models/playbook';
import { SystemFeature } from '../models/subscriptionPlan';
import { ITag } from '../models/tag';
import { _s, StringKey } from '../strings';
import { CachedImage } from './cachedImage';
import { PrintControl, PrintPlay } from './printControl';

interface Props {
	categories: ITag[];
	fieldOptions: any;
	formations: IFormation[];
	optionValues: {[key: string]: any};
	playbook: IPlaybook;
	plays: IPlay[];
}

const SponsorContent = () => {
	const { currentSubscription, sponsorLogoPrintUrl } = getCurrentContext();

	if (!sponsorLogoPrintUrl || !currentSubscription.hasFeature(SystemFeature.print)) {
		return null;
	}

	return <div className="sponsor">
		<div className="tribute">{ _s(StringKey.PRINTING_POWERED_BY) }</div>
		<span className="icon photo"><CachedImage src={ sponsorLogoPrintUrl } /></span>
	</div>;
};

export class PrintPlaybook extends React.Component<Props> {
	constructor(props) {
		super(props);
	}

	public render() {
		const { categories, fieldOptions, formations, optionValues, playbook, plays } = this.props;

		return <PrintControl optionValues={ optionValues } playbook={ playbook }>{ (params) => {
			const { className, buildPlayLists, getPersonnelInfo } = params;
			const classNames = [className, 'playbook'];
			const groupSize = optionValues.layout.groupSize;
			const playColorCoding = optionValues.playColorCoding?.key;
			const phase = plays[0].phase;
			const phaseLabel = phase === GamePhase.Offense ? _s(StringKey.OFFENSE) : phase === GamePhase.Defense ? _s(StringKey.DEFENSE) : phase === GamePhase.SpecialTeams ? _s(StringKey.SPECIAL_TEAMS) : '';
			const { currentTeam } = getCurrentContext();
			const logoImg = currentTeam.settings.logoUrl ? <CachedImage className="logo"  key={ currentTeam.settings.logoUrl || 'empty' } src={ currentTeam.settings.logoUrl } /> : null;
			const teamColor = currentTeam && `#${currentTeam.settings.color}`;
			const { categoryList, formationList } = buildPlayLists(categories, formations, plays);
			const printPages = [];
			let playCount = 0;

			if (categoryList.length) {
				forEach(categoryList, (c) => {
					const groupPlays = c.plays;
					const playPageCount = Math.ceil(groupPlays.length / groupSize);
					const groupClasses = ['printPage'];
					let colorClass:string = undefined;

					if(playColorCoding === 'custom' && c.color) {
						colorClass = `color${c.color}`;
						groupClasses.push(colorClass);
					}

					printPages.push(<div key={ c.id } className={`${groupClasses.join(' ')} cover`}>
						<div className="title titleCategory">
							<div className="property">{ _s(StringKey.CATEGORY) }</div>
							<div className="value" style={{ backgroundColor: teamColor }}>{ c.label }</div>
							<div className="titleField">
								<div className="titleHash">
									<div></div>
									<div></div>
								</div>
								<div className="titleHash">
									<div></div>
									<div></div>
								</div>
								<div className="titleHash">
									<div></div>
									<div></div>
								</div>
								<div className="titleHash">
									<div></div>
									<div></div>
								</div>
								<div className="titleLine mid">
									<div></div>
								</div>
								<div className="titleHash">
									<div></div>
									<div></div>
								</div>
								<div className="titleHash">
									<div></div>
									<div></div>
								</div>
								<div className="titleHash">
									<div></div>
									<div></div>
								</div>
								<div className="titleHash">
									<div></div>
									<div></div>
								</div>
							</div>
						</div>
					</div>);

					for (let i = 0; i < playPageCount; i ++) {
						const pagePlays = groupPlays.slice(i * groupSize, (i + 1) * groupSize);
						const playContent = map(pagePlays, (play: IPlay) =>  <PrintPlay key={ play.id } play={ play } playCount={ playCount++ } className={colorClass} fieldOptions={ fieldOptions} getPersonnelInfo={ getPersonnelInfo && getPersonnelInfo.bind(this, play) } />);

						printPages.push(<div key={ `${c.label}_${i}`} className={groupClasses.join(' ')}><section>{ playContent }</section><SponsorContent /></div>);
					}

				});
			} else if (formationList.length) {
				forEach(formationList, (f) => {
					const groupPlays = f.plays;
					const playPageCount = Math.ceil(groupPlays.length / groupSize);
					const groupClasses = ['printPage'];
					let colorClass:string = undefined;

					if(playColorCoding === 'custom' && f.color) {
						colorClass = `color${f.color}`;
						groupClasses.push(colorClass);
					}

					printPages.push(<div key={ f.id } className={`${groupClasses.join(' ')} cover`}>
						<div className="title titleFormation">
							<div className="property">{ _s(StringKey.FORMATION) }</div>
							<div className="value" style={{ backgroundColor: teamColor }}>{ f.label }</div>
							<div className="titleField">
								<div className="titleHash">
									<div></div>
									<div></div>
								</div>
								<div className="titleHash">
									<div></div>
									<div></div>
								</div>
								<div className="titleHash">
									<div></div>
									<div></div>
								</div>
								<div className="titleHash">
									<div></div>
									<div></div>
								</div>
								<div className="titleLine mid">
									<div></div>
								</div>
								<div className="titleHash">
									<div></div>
									<div></div>
								</div>
								<div className="titleHash">
									<div></div>
									<div></div>
								</div>
								<div className="titleHash">
									<div></div>
									<div></div>
								</div>
								<div className="titleHash">
									<div></div>
									<div></div>
								</div>
							</div>
						</div>
					</div>);

					for (let i = 0; i < playPageCount; i ++) {
						const pagePlays = groupPlays.slice(i * groupSize, (i + 1) * groupSize);
						const playContent = map(pagePlays, (play: IPlay) =>  <PrintPlay key={ play.id } play={ play } playCount={ playCount++ } className={colorClass} fieldOptions={ fieldOptions} getPersonnelInfo={ getPersonnelInfo && getPersonnelInfo.bind(this, play) } />);

						printPages.push(<div key={ `${f.label}_${i}`} className={groupClasses.join(' ')}><section>{ playContent }</section><SponsorContent /></div>);
					}

				});
			}

			// console.log(`groupSize ${groupSize}`);
			classNames.push(map(optionValues, (v) => v.className).join(' '));

			return <div className={ classNames.join(' ') }>

				<div className="printPage cover">
					<div className="title">
						<div className="logo">
							{ logoImg }
							<div className="border"></div>
						</div>
						<div className="teamName" style={{ backgroundColor: teamColor }}>{ currentTeam.name }</div>
						<div className="phase">{ phaseLabel }</div>
						<div className="titleField">
							<div className="titleHash">
								<div></div>
								<div></div>
							</div>
							<div className="titleHash">
								<div></div>
								<div></div>
							</div>
							<div className="titleHash">
								<div></div>
								<div></div>
							</div>
							<div className="titleHash">
								<div></div>
								<div></div>
							</div>
							<div className="titleLine">
								<div></div>
							</div>
							<div className="titleHash">
								<div></div>
								<div></div>
							</div>
							<div className="titleHash">
								<div></div>
								<div></div>
							</div>
							<div className="titleHash">
								<div></div>
								<div></div>
							</div>
							<div className="titleHash">
								<div></div>
								<div></div>
							</div>
							<div className="titleLine mid">
								<div></div>
							</div>
							<div className="titleHash">
								<div></div>
								<div></div>
							</div>
							<div className="titleHash">
								<div></div>
								<div></div>
							</div>
							<div className="titleHash">
								<div></div>
								<div></div>
							</div>
							<div className="titleHash">
								<div></div>
								<div></div>
							</div>
							<div className="titleLine">
								<div></div>
							</div>
							<div className="titleHash">
								<div></div>
								<div></div>
							</div>
							<div className="titleHash">
								<div></div>
								<div></div>
							</div>
							<div className="titleHash">
								<div></div>
								<div></div>
							</div>
							<div className="titleHash">
								<div></div>
								<div></div>
							</div>
						</div>
					</div>
					<footer>
						<div className="playmaker"></div>
						<div className="timestamp">{ new Date().toLocaleString() }</div>
					</footer>
				</div>

				{ printPages }

			</div>;
		}}</PrintControl>;

	}
}

import { assign, every, find } from 'lodash';
import { default as valueFilters } from 'playmaker-team-common/dist/shared/valueFilters';
import * as React from 'react';
import * as actions from '../actions';
import { current as getCurrentContext } from '../componentContext';
import * as logger from '../logger';
import { ITeam } from '../models/team';
import { ITeamMember } from '../models/teamMember';
import { default as userFactory, IUser } from '../models/user';
import * as store from '../store';
import { _s, StringKey } from '../strings';
import * as viewManager from '../viewManager';
import { AlertList } from './alert';
import { CachedImage } from './cachedImage';
import { ICredentials, LoginPage, ResetPasswordPage } from './login';
import { Page, PageState } from './page';
import { ProfileEdit } from './profileEdit';
import { Spinner } from './spinner';

interface Props {
	alerts: store.IAlert[];
	viewState: store.IViewState;
}

interface State {
	credentials: ICredentials;
	activePage: number;
	inviteCode?: string;
	user?: IUser;
	team?: ITeam;
	password?: string;
	processing?: boolean;
}

const InviteCodePage = ({ alertContainer, inviteCode = '', onContinueClick, onKeyPress, onInviteCodeChange }) => {

	return <React.Fragment>
		<header>
			<div className="actions">
				<a className="button" onClick={ viewManager.popModal }><span className="icon cancel"></span></a>
			</div>
			<div className="title">{ _s(StringKey.ACCEPT_INVITE) }</div>
		</header>
		{ alertContainer }
		<div className="content scrollable">
			<div className="inner compact" onKeyPress={ onKeyPress }>
				<div className="appVersion acceptInvite">
					<div className="logoAcceptInvite"></div>
				</div>
				<input type="text" placeholder={ _s(StringKey.ENTER_INVITATION_CODE) } value={ inviteCode } onChange={ onInviteCodeChange } onBlur={ onInviteCodeChange } required />
				<div className="actions">
					<a className={ inviteCode ? 'button basic fullWidth' : 'button basic fullWidth disabled' } onClick={ onContinueClick }><span className="icon"></span><span>{ _s(StringKey.CONTINUE) }</span><span className="icon navigate"></span></a>
				</div>
				<a className="link otherApp acceptInvite tackle" onClick={ (e) => actions.browseTo(`https://www.tacklefootballplaymaker.com/app`) }><span>{ _s(StringKey.LOOKING_FOR_TACKLE_APP) }</span></a>
				<a className="link otherApp acceptInvite flag" onClick={ (e) => actions.browseTo(`https://www.flagfootballplaymaker.com/app`) }><span>{ _s(StringKey.LOOKING_FOR_FLAG_APP) }</span></a>
			</div>
		</div>
	</React.Fragment>;
};

const TeamDisplayPage = ({ alertContainer, onBackClick, onNoClick, onYesClick, team}) => {
	const { currentUser } = getCurrentContext();
	const member: ITeamMember = currentUser && find(team.members.values, { userId: currentUser.id });

	return <React.Fragment>
		<header>
			<div className="actions">
				<a className="button" onClick={ member ? viewManager.popModal :  onBackClick }><span className={ member ? 'icon cancel' : 'icon previous'}></span></a>
			</div>
			<div className="title">{ team.name }</div>
		</header>
		{ alertContainer }
		<div className="content scrollable">
			<div className="inner compact">
				<span className="icon photo team" style={{ backgroundColor: team.settings.color ? `#${team.settings.color}` : null }}>
					<span>{ team.abbreviation }</span>
					{ team.settings.logoUrl ? <CachedImage key={ team.settings.logoUrl } src={ team.settings.logoUrl } /> : null }
				</span>
				<p>{ member ? _s(StringKey.ACCEPT_TEAM_INVITE_SUCCESS_TEMPLATE).replace('{teamName}', team.name) : _s(StringKey.DO_YOU_ALREADY_HAVE_AN_ACCOUNT) }</p>
				<div className="actions">
					{ member ? <a className="button basic" onClick={ viewManager.popModal }><span>{ _s(StringKey.OK) }</span></a> : null }
					{ !member ? <a className="button basic no" onClick={ onNoClick }><span>{ _s(StringKey.NO) }</span></a> : null }
					{ !member ? <a className="button basic" onClick={ onYesClick }><span>{ _s(StringKey.YES) }</span></a> : null }
				</div>
			</div>
		</div>
	</React.Fragment>;
};

export class InviteModal extends React.Component<Props, State> {
	constructor(props) {
		super(props);

		this.state = { credentials: { emailAddress: '', password: '' }, activePage: 0 };

		this.onLoginClick = this.onLoginClick.bind(this);
		this.updateCredentials = this.updateCredentials.bind(this);
		this.resetPassword = this.resetPassword.bind(this);
		this.onEnterKeyPress = this.onEnterKeyPress.bind(this);
		this.onUserChange = this.onUserChange.bind(this);
		this.onPasswordSet = this.onPasswordSet.bind(this);
		this.onInviteCodeChange = this.onInviteCodeChange.bind(this);
		this.createAccount = this.createAccount.bind(this);
		this.onFetchClaimClick = this.onFetchClaimClick.bind(this);

		this.logScreen();
	}

	public getPageState(key) {
		const { activePage } = this.state;

		if (key === activePage) {
			return PageState.IN;
		} else if (key < activePage) {
			return PageState.OUT;
		}

		return PageState.DEFAULT;
	}

	public logScreen() {
		const { activePage } = this.state;

		switch (activePage) {
		case 0:
			logger.logScreen('AcceptInviteCode');
			break;
		case 1:
			logger.logScreen('AcceptInviteTeam');
			break;
		case 2:
			logger.logScreen('Login');
			break;
		case 3:
			logger.logScreen('ResetPassword');
			break;
		case 4:
			logger.logScreen('EnterTempPassword');
			break;
		case 5:
			logger.logScreen('ProfileEdit');
			break;

		default:
			// code...
			break;
		}
	}

	public updateCredentials(key: string, value: string) {
		this.setState((previousState) => {
			const newState = assign({}, previousState) as State;

			newState.credentials[key] = value;

			return newState;
		});
	}

	public async resetPassword(e) {
		const { credentials } = this.state;

		if (credentials.emailAddress) {
			actions.clearAlerts();
			await actions.requestPasswordReset(credentials.emailAddress);

			const alerts = store.appState().viewState.alerts;
			if (alerts.length === 0) {
				this.goToPage(2);
			}
		}
	}

	public async onLoginClick(e) {
		const { credentials, inviteCode } = this.state;

		if (credentials.emailAddress && credentials.password) {
			actions.clearAlerts();
			await actions.login(credentials.emailAddress, credentials.password, inviteCode);

			if (store.appState().viewState.currentUserId) {
				viewManager.popModal();
			}
		}
	}

	public onUserChange(path, value) {
		this.setState((prevState) => {
			const newState = assign({}, prevState) as State;

			// return a new instance so the child component is sure to update
			newState.user = userFactory(newState.user);

			newState.user.setAt(path, value);

			return newState;
		});
	}

	public onPasswordSet(value) {
		this.setState((prevState) => {
			const newState = assign({}, prevState) as State;

			newState.password = value;

			return newState;
		});
	}

	public onInviteCodeChange(e) {
		const newValue = e.type === 'blur' ? valueFilters.trim(e.target.value) : e.target.value;

		this.setState({ inviteCode: newValue });
	}

	public onFetchClaimClick(e) {
		this.setState({ processing: true }, async () => {
			actions.clearAlerts();
			const inviteCode = this.state.inviteCode && this.state.inviteCode.toUpperCase();
			const team = await actions.fetchTeamByInviteCode(inviteCode);

			if (team) {
				const { currentUser } = getCurrentContext();
				const member: ITeamMember = find(team.members.values, { inviteCode });

				if (currentUser && member.userId === currentUser.id) {
					this.setState({ activePage: 1, processing: false, team, user: currentUser }, () => {
						actions.setCurrentTeam(team);
					});
				} else {
					const user: IUser = userFactory();
					const credentionals: ICredentials = assign({});

					user.email = member.email;
					user.firstName = member.firstName;
					user.lastName = member.lastName;

					this.setState({ activePage: 1, credentials: { emailAddress: user.email, password: '' }, processing: false, team, user });
				}
			} else {
				this.setState({ credentials: { emailAddress: '', password: '' }, processing: false, user: userFactory()});
			}
		});
	}

	public async createAccount() {
		const { user, password, inviteCode, team } = this.state;

		if (!user || !password || !inviteCode || !team) {
			return;
		}

		this.setState({ processing: true }, async () => {
			actions.clearAlerts();
			await actions.createAccount(this.state.user, null, this.state.password, this.state.inviteCode);

			const alerts = store.appState().viewState.alerts;
			const hasAlerts = alerts.length > 0;
			const areAllInfoAlerts = every(alerts, (alert: store.IAlert) => alert.severity === store.AlertSeverity.info);

			if (!hasAlerts || areAllInfoAlerts) {
				await actions.setCurrentTeam(team);
			}

			if (!hasAlerts) {
				viewManager.popModal();
			} else if (areAllInfoAlerts) {
				setTimeout(viewManager.popModal, 4000);
			}

			this.setState({ processing: false });
		});
	}

	public onEnterKeyPress(e) {
		const { activePage } = this.state;

		if (e.key === 'Enter') {
			if (activePage === 2) {
				this.onLoginClick(e);
			} else if (activePage === 3) {
				this.resetPassword(e);
			}
		}
	}

	public goToPage(page: number) {
		this.setState((previousState) => {
			const newState = assign({}, previousState) as State;

			newState.activePage = page;

			return newState;
		});
	}
	public render() {
		const { alerts } = this.props;
		const { credentials, inviteCode, password, processing, team, user } = this.state;
		const alertContainer = <AlertList alerts={ alerts } />;

		return <React.Fragment>
			{ processing ? <Spinner /> : null }
			<Page key="page0" pageClasses={['page']} pageState={ this.getPageState(0) }>
				<InviteCodePage alertContainer={ alertContainer } inviteCode={ inviteCode } onKeyPress={ this.onEnterKeyPress } onContinueClick={ this.onFetchClaimClick } onInviteCodeChange={ this.onInviteCodeChange } />
			</Page>
			<Page key="page1" pageClasses={['page']} pageState={ this.getPageState(1) }>
				<TeamDisplayPage alertContainer={ alertContainer } onBackClick={ this.goToPage.bind(this, 0) } onNoClick={ this.goToPage.bind(this, 5)} onYesClick={ this.goToPage.bind(this, 2) } team={ team } />
			</Page>
			<Page key="page2" pageClasses={['page']} pageState={ this.getPageState(2) }>
				<LoginPage alertContainer={ alertContainer } cancelClass="icon previous" credentials={ credentials } onCancel={ this.goToPage.bind(this, 1) } onLogin={ this.onLoginClick } updateCredentials={ this.updateCredentials } onForgotPassword={ this.goToPage.bind(this, 3) } onKeyPress={ this.onEnterKeyPress } />
			</Page>
			<Page key="page3" pageClasses={['page']} pageState={ this.getPageState(3) }>
				<ResetPasswordPage alertContainer={ alertContainer } credentials={ credentials } onBack={ this.goToPage.bind(this, 2) } onResetPassword={ this.resetPassword } updateCredentials={ this.updateCredentials } onKeyPress={ this.onEnterKeyPress } />
			</Page>
			<Page key="page4" pageClasses={['page']} pageState={ this.getPageState(4) }>
				<LoginPage alertContainer={ alertContainer } cancelClass="icon previous" credentials={ credentials } onLogin={ this.onLoginClick } updateCredentials={ this.updateCredentials } message={ _s(StringKey.TEMPORARY_PASSWORD_SENT_MESSAGE) } onKeyPress={ this.onEnterKeyPress } />
			</Page>
			<Page key="page5" pageState={ this.getPageState(5) } pageClasses={ ['page'] }>
				<ProfileEdit alertList={ alertContainer } addMode={ true } cancelAction={ this.goToPage.bind(this, 1) } confirmAction={ user && password && user.isValid() ? this.createAccount : undefined } user={ user } onUserChange={ this.onUserChange } onPasswordSet={ this.onPasswordSet } />
			</Page>
		</React.Fragment>;
	}
}

import { assign } from 'lodash';
import { IModel } from 'playmaker-team-common/dist/shared/interfaces';
import { createFactory} from 'playmaker-team-common/dist/shared/modelFactory';
import { default as validator} from 'playmaker-team-common/dist/shared/validator';
import { default as valueFilters} from 'playmaker-team-common/dist/shared/valueFilters';
import { default as rootModel, IRootModel } from './rootModel';

export type PaymentType = 'credit' | 'debit' | 'prepaid' | 'check';

export interface IPaymentMethod extends IRootModel {
	name?: string;
	paymentType: PaymentType;
	brand?: string;
	last4?: string;
	processorName: string;
	nameOnMethod?: string;
	expirationMonth?: number;
	expirationYear?: number;
	address1?: string;
	address2?: string;
	city?: string;
	region?: string;
	country?: string;
	postalCode?: string;
	providerToken?: string;
	hasExpired: () => boolean;
}

// export interface IPaymentMethodData extends IPaymentMethod {
// 	providerToken?: string;
// }

export default createFactory<IPaymentMethod>('PaymentMethod', assign({}, rootModel, {
	name: {
		setFilters: valueFilters.clean,
	},
	paymentType: {
		setFilters: valueFilters.clean,
		rules: validator.inList(['credit', 'debit', 'check', 'prepaid']),
	},
	brand: {
		setFilters: valueFilters.clean,
	},
	last4: {
		setFilters: valueFilters.clean,
	},
	processorName: {
		setFilters: valueFilters.clean,
	},
	nameOnMethod: {
		setFilters: valueFilters.clean,
	},
	expirationMonth: {
		setFilters: valueFilters.toNumber,
		rules: validator.inRange(1, 12),
	},
	expirationYear: {
		setFilters: valueFilters.toNumber,
	},
	address1: {
		setFilters: valueFilters.clean,
	},
	address2: {
		setFilters: valueFilters.clean,
	},
	city: {
		setFilters: valueFilters.clean,
	},
	region: {
		setFilters: valueFilters.clean,
	},
	country: {
		setFilters: valueFilters.clean,
	},
	postalCode: {
		setFilters: valueFilters.clean,
	},
	providerToken: {
	},
	hasExpired() {
		if (!this.expirationMonth || !this.expirationYear) {
			return false;
		}

		const now = new Date();
		const nowYear = now.getFullYear();

		if (nowYear > this.expirationYear) {
			return true;
		} else if (nowYear === this.expirationYear && (now.getMonth() + 1) > this.expirationMonth) {
			return true;
		}

		return false;
	},
}));

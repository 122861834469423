import { assign, find } from 'lodash';
import { IModel } from 'playmaker-team-common/dist/shared/interfaces';
import { createFactory } from 'playmaker-team-common/dist/shared/modelFactory';
import { ModelSet } from 'playmaker-team-common/dist/shared/modelSet';
import { default as validator } from 'playmaker-team-common/dist/shared/validator';
import { default as valueFilters } from 'playmaker-team-common/dist/shared/valueFilters';
import { default as playbookMemberFactory, IPlaybookMember, PlaybookAccess } from './playbookMember';
import { default as playbookSettingsFactory, IPlaybookSettings } from './playbookSettings';
import { default as playListFactory, IPlayList } from './playList';
import { default as rootModel, IRootModel } from './rootModel';
import { default as sortableModel, ISortable } from './sortableModel';

const enum PlaybookFlags {
	none = 0,
	isArchived = 1 << 0,
}

export interface IPlaybook extends IRootModel, ISortable {
	name: string;
	isArchived: boolean;
	readonly flags: number;
	playersPerSide: number;
	permissions: ModelSet<IModel & IPlaybookMember>;
	settings: IPlaybookSettings;
	teamId: string;
	addMember: (teamMemberId: number, access: PlaybookAccess) => IPlaybookMember;
	getPlayList: (relatedId: string, create?: boolean) => IPlayList;
}

export default createFactory<IPlaybook>('Playbook', assign({}, rootModel, sortableModel, {
	name: {
		rules: validator.required(),
	},
	isArchived: {
		nonSerialized: true,
		setFilters: valueFilters.toBoolean,
	},
	_set_isArchived(val: boolean) {
		if (val) {
			this.flags = ((this.flags) | PlaybookFlags.isArchived);
		} else {
			this.flags = ((this.flags) & ~PlaybookFlags.isArchived);
		}
	},
	_get_isArchived() {
		return !!(this.flags & PlaybookFlags.isArchived);
	},
	flags: {
		setFilters: valueFilters.toNumber,
		defaultValue: PlaybookFlags.none,
	},
	playersPerSide: {
		setFilters: valueFilters.toNumber,
		rules: validator.inList([4, 5, 6, 7, 8, 9, 11, 12]),
	},
	permissions: {
		setFilters: valueFilters.toModelSet(playbookMemberFactory),
		rules: validator.validModel(playbookMemberFactory.rules()),
		defaultValue: {},
	},
	settings: {
		setFilters: valueFilters.toModel<IPlaybookSettings>(playbookSettingsFactory),
		rules: validator.validModel(playbookSettingsFactory.rules()),
		defaultValue: {},
	},
	teamId: {
		setFilters: valueFilters.clean,
		rules: validator.required(),
	},
	addMember(teamMemberId: number, access: PlaybookAccess) {
		let member: IPlaybookMember = find(this.permissions.values, { teamMemberId });

		if (!member) {
			member = playbookMemberFactory({ playbookId: this.id, teamMemberId });
			this.permissions.add(member);
		}

		member.access = access;

		return member;
	},
	getPlayList(relatedId: string, create = false) {
		let result = relatedId && find(this.settings.playLists.values, { relatedId });

		if (!result && relatedId && create) {
			result = playListFactory({ relatedId, playIds: '' });

			this.settings.playLists.add(result);
		}

		return result;
	},
}));

export type QueueProcess = () => Promise<any>;

export class ProcessQueue {
	private _items: { process: QueueProcess, callback? }[];
	private _active: boolean;
	private _activeItem;

	constructor({ autoActive }: { autoActive: boolean }) {
		this._items = [];
		this._active = autoActive;
	}
	public enqueue(process: QueueProcess, callback?) {
		this._items.push({ process, callback });
		if (this._active) {
			this._process();
		}
	}
	public resume() {
		this._active = true;
		this._process();
	}
	public pause() {
		this._active = false;
	}
	public async _process() {
		while (this._active && this._items.length > 0 && !this._activeItem) {
			this._activeItem = this._items.shift();

			const result = await this._activeItem.process();

			if (this._activeItem.callback) {
				this._activeItem.callback(result);
			}

			this._activeItem = null;
		}
	}
}

import * as React from 'react';

export function Gridiron65x150({ teamLabel }) {
	return <div className="field">
		<svg viewBox="0 0 1024 2304">
			<svg className="markings" x="0%" y="0%" width="100%" height="100%">
				<svg className="boundary" x="-2%" y="-1%" width="104%" height="102%">
					<rect className="endline" x="0%" y="0%" width="100%" height="1%"></rect>
					<rect className="endline" x="0%" y="99%" width="100%" height="1%"></rect>
					<rect className="sideline" x="0%" y="0%" width="2%" height="100%"></rect>
					<rect className="sideline" x="98%" y="0%" width="2%" height="100%"></rect>
				</svg>
				<svg className="endzone" x="0%" y="0%" height="13.315%">
					<text x="48%" y="56%">{ teamLabel }</text>
					<line className="yardline" x1="0" x2="100%" y1="100%" y2="100%" />
				</svg>
				<svg className="tenYards" x="0%" y="13.315%" height="6.67%">
					<svg x="17.5%" y="11%">
						<text transform="rotate(90 0 0)">G</text>
					</svg>
					<svg x="82.5%" y="11%">
						<text transform="rotate(-90 0 0)">G</text>
					</svg>
					<line className="hash" x1="36.92%" x2="38.07%" y1="10%" y2="10%" />
					<line className="hash" x1="61.93%" x2="63.08%" y1="10%" y2="10%" />
					<line className="hash" x1="36.92%" x2="38.07%" y1="20%" y2="20%" />
					<line className="hash" x1="61.93%" x2="63.08%" y1="20%" y2="20%" />
					<line className="hash" x1="36.92%" x2="38.07%" y1="30%" y2="30%" />
					<line className="hash" x1="61.93%" x2="63.08%" y1="30%" y2="30%" />
					<line className="hash" x1="36.92%" x2="38.07%" y1="40%" y2="40%" />
					<line className="hash" x1="61.93%" x2="63.08%" y1="40%" y2="40%" />
					<line className="yardline" x1="0" x2="100%" y1="50%" y2="50%" />
					<line className="hash" x1="36.92%" x2="38.07%" y1="60%" y2="60%" />
					<line className="hash" x1="61.93%" x2="63.08%" y1="60%" y2="60%" />
					<line className="hash" x1="36.92%" x2="38.07%" y1="70%" y2="70%" />
					<line className="hash" x1="61.93%" x2="63.08%" y1="70%" y2="70%" />
					<line className="hash" x1="36.92%" x2="38.07%" y1="80%" y2="80%" />
					<line className="hash" x1="61.93%" x2="63.08%" y1="80%" y2="80%" />
					<line className="hash" x1="36.92%" x2="38.07%" y1="90%" y2="90%" />
					<line className="hash" x1="61.93%" x2="63.08%" y1="90%" y2="90%" />
					<line className="yardline" x1="0" x2="100%" y1="100%" y2="100%" />
				</svg>
				<svg className="tenYards" x="0%" y="19.985%" height="6.67%">
					<svg x="18%" y="3%">
						<polygon transform="translate(-16 -30)" points="0,0 -10,0 -5,-20" className="triangle" />
						<text transform="rotate(90 0 0)">1 0</text>
					</svg>
					<svg x="82%" y="-3%">
						<polygon transform="translate(26 -30)" points="0,0 -10,0 -5,-20" className="triangle" />
						<text transform="rotate(-90 0 0)">1 0</text>
					</svg>
					<line className="hash" x1="36.92%" x2="38.07%" y1="10%" y2="10%" />
					<line className="hash" x1="61.93%" x2="63.08%" y1="10%" y2="10%" />
					<line className="hash" x1="36.92%" x2="38.07%" y1="20%" y2="20%" />
					<line className="hash" x1="61.93%" x2="63.08%" y1="20%" y2="20%" />
					<line className="hash" x1="36.92%" x2="38.07%" y1="30%" y2="30%" />
					<line className="hash" x1="61.93%" x2="63.08%" y1="30%" y2="30%" />
					<line className="hash" x1="36.92%" x2="38.07%" y1="40%" y2="40%" />
					<line className="hash" x1="61.93%" x2="63.08%" y1="40%" y2="40%" />
					<line className="yardline" x1="0" x2="100%" y1="50%" y2="50%" />
					<line className="hash" x1="36.92%" x2="38.07%" y1="60%" y2="60%" />
					<line className="hash" x1="61.93%" x2="63.08%" y1="60%" y2="60%" />
					<line className="hash" x1="36.92%" x2="38.07%" y1="70%" y2="70%" />
					<line className="hash" x1="61.93%" x2="63.08%" y1="70%" y2="70%" />
					<line className="hash" x1="36.92%" x2="38.07%" y1="80%" y2="80%" />
					<line className="hash" x1="61.93%" x2="63.08%" y1="80%" y2="80%" />
					<line className="hash" x1="36.92%" x2="38.07%" y1="90%" y2="90%" />
					<line className="hash" x1="61.93%" x2="63.08%" y1="90%" y2="90%" />
					<line className="yardline" x1="0" x2="100%" y1="100%" y2="100%" />
				</svg>
				<svg className="tenYards" x="0%" y="26.655%" height="6.67%">
					<svg x="18%" y="0">
						<polygon transform="translate(-16 -35)" points="0,0 -10,0 -5,-20" className="triangle" />
						<text transform="rotate(90 0 0)">2 0</text>
					</svg>
					<svg x="82%" y="0">
						<polygon transform="translate(26 -35)" points="0,0 -10,0 -5,-20" className="triangle" />
						<text transform="rotate(-90 0 0)">2 0</text>
					</svg>
					<line className="hash" x1="36.92%" x2="38.07%" y1="10%" y2="10%" />
					<line className="hash" x1="61.93%" x2="63.08%" y1="10%" y2="10%" />
					<line className="hash" x1="36.92%" x2="38.07%" y1="20%" y2="20%" />
					<line className="hash" x1="61.93%" x2="63.08%" y1="20%" y2="20%" />
					<line className="hash" x1="36.92%" x2="38.07%" y1="30%" y2="30%" />
					<line className="hash" x1="61.93%" x2="63.08%" y1="30%" y2="30%" />
					<line className="hash" x1="36.92%" x2="38.07%" y1="40%" y2="40%" />
					<line className="hash" x1="61.93%" x2="63.08%" y1="40%" y2="40%" />
					<line className="yardline" x1="0" x2="100%" y1="50%" y2="50%" />
					<line className="hash" x1="36.92%" x2="38.07%" y1="60%" y2="60%" />
					<line className="hash" x1="61.93%" x2="63.08%" y1="60%" y2="60%" />
					<line className="hash" x1="36.92%" x2="38.07%" y1="70%" y2="70%" />
					<line className="hash" x1="61.93%" x2="63.08%" y1="70%" y2="70%" />
					<line className="hash" x1="36.92%" x2="38.07%" y1="80%" y2="80%" />
					<line className="hash" x1="61.93%" x2="63.08%" y1="80%" y2="80%" />
					<line className="hash" x1="36.92%" x2="38.07%" y1="90%" y2="90%" />
					<line className="hash" x1="61.93%" x2="63.08%" y1="90%" y2="90%" />
					<line className="yardline" x1="0" x2="100%" y1="100%" y2="100%" />
				</svg>
				<svg className="tenYards" x="0%" y="33.325%" height="6.67%">
					<svg x="18%" y="0">
						<polygon transform="translate(-16 -35)" points="0,0 -10,0 -5,-20" className="triangle" />
						<text transform="rotate(90 0 0)">3 0</text>
					</svg>
					<svg x="82%" y="0">
						<polygon transform="translate(26 -35)" points="0,0 -10,0 -5,-20" className="triangle" />
						<text transform="rotate(-90 0 0)">3 0</text>
					</svg>
					<line className="hash" x1="36.92%" x2="38.07%" y1="10%" y2="10%" />
					<line className="hash" x1="61.93%" x2="63.08%" y1="10%" y2="10%" />
					<line className="hash" x1="36.92%" x2="38.07%" y1="20%" y2="20%" />
					<line className="hash" x1="61.93%" x2="63.08%" y1="20%" y2="20%" />
					<line className="hash" x1="36.92%" x2="38.07%" y1="30%" y2="30%" />
					<line className="hash" x1="61.93%" x2="63.08%" y1="30%" y2="30%" />
					<line className="hash" x1="36.92%" x2="38.07%" y1="40%" y2="40%" />
					<line className="hash" x1="61.93%" x2="63.08%" y1="40%" y2="40%" />
					<line className="yardline" x1="0" x2="100%" y1="50%" y2="50%" />
					<line className="hash" x1="36.92%" x2="38.07%" y1="60%" y2="60%" />
					<line className="hash" x1="61.93%" x2="63.08%" y1="60%" y2="60%" />
					<line className="hash" x1="36.92%" x2="38.07%" y1="70%" y2="70%" />
					<line className="hash" x1="61.93%" x2="63.08%" y1="70%" y2="70%" />
					<line className="hash" x1="36.92%" x2="38.07%" y1="80%" y2="80%" />
					<line className="hash" x1="61.93%" x2="63.08%" y1="80%" y2="80%" />
					<line className="hash" x1="36.92%" x2="38.07%" y1="90%" y2="90%" />
					<line className="hash" x1="61.93%" x2="63.08%" y1="90%" y2="90%" />
					<line className="yardline" x1="0" x2="100%" y1="100%" y2="100%" />
				</svg>
				<svg className="tenYards" x="0%" y="39.995%" height="6.67%">
					<svg x="18%" y="0">
						<polygon transform="translate(-16 -35)" points="0,0 -10,0 -5,-20" className="triangle" />
						<text transform="rotate(90 0 0)">4 0</text>
					</svg>
					<svg x="82%" y="0">
						<polygon transform="translate(26 -35)" points="0,0 -10,0 -5,-20" className="triangle" />
						<text transform="rotate(-90 0 0)">4 0</text>
					</svg>
					<line className="hash" x1="36.92%" x2="38.07%" y1="10%" y2="10%" />
					<line className="hash" x1="61.93%" x2="63.08%" y1="10%" y2="10%" />
					<line className="hash" x1="36.92%" x2="38.07%" y1="20%" y2="20%" />
					<line className="hash" x1="61.93%" x2="63.08%" y1="20%" y2="20%" />
					<line className="hash" x1="36.92%" x2="38.07%" y1="30%" y2="30%" />
					<line className="hash" x1="61.93%" x2="63.08%" y1="30%" y2="30%" />
					<line className="hash" x1="36.92%" x2="38.07%" y1="40%" y2="40%" />
					<line className="hash" x1="61.93%" x2="63.08%" y1="40%" y2="40%" />
					<line className="yardline" x1="0" x2="100%" y1="50%" y2="50%" />
					<line className="hash" x1="36.92%" x2="38.07%" y1="60%" y2="60%" />
					<line className="hash" x1="61.93%" x2="63.08%" y1="60%" y2="60%" />
					<line className="hash" x1="36.92%" x2="38.07%" y1="70%" y2="70%" />
					<line className="hash" x1="61.93%" x2="63.08%" y1="70%" y2="70%" />
					<line className="hash" x1="36.92%" x2="38.07%" y1="80%" y2="80%" />
					<line className="hash" x1="61.93%" x2="63.08%" y1="80%" y2="80%" />
					<line className="hash" x1="36.92%" x2="38.07%" y1="90%" y2="90%" />
					<line className="hash" x1="61.93%" x2="63.08%" y1="90%" y2="90%" />
					<line className="yardline" x1="0" x2="100%" y1="100%" y2="100%" />
				</svg>
				<svg className="tenYards" x="0%" y="46.665%" height="3.335%">
					<svg x="18%" y="0">
						<polygon transform="translate(-16 -35)" points="0,0 -10,0 -5,-20" className="triangle" />
						<text transform="rotate(90 0 0)">5 0</text>
					</svg>
					<svg x="82%" y="0">
						<polygon transform="translate(26 -35)" points="0,0 -10,0 -5,-20" className="triangle" />
						<text transform="rotate(-90 0 0)">5 0</text>
					</svg>
					<line className="hash" x1="36.92%" x2="38.07%" y1="20%" y2="20%" />
					<line className="hash" x1="61.93%" x2="63.08%" y1="20%" y2="20%" />
					<line className="hash" x1="36.92%" x2="38.07%" y1="40%" y2="40%" />
					<line className="hash" x1="61.93%" x2="63.08%" y1="40%" y2="40%" />
					<line className="hash" x1="36.92%" x2="38.07%" y1="60%" y2="60%" />
					<line className="hash" x1="61.93%" x2="63.08%" y1="60%" y2="60%" />
					<line className="hash" x1="36.92%" x2="38.07%" y1="80%" y2="80%" />
					<line className="hash" x1="61.93%" x2="63.08%" y1="80%" y2="80%" />
					<line className="yardline" x1="0" x2="100%" y1="100%" y2="100%" />
				</svg>
				<svg className="tenYards" x="0%" y="50%" height="3.335%">
					<svg x="18%" y="0">
						<text transform="rotate(90 0 0)">C</text>
					</svg>
					<svg x="82%" y="0">
						<text transform="rotate(-90 0 0)">C</text>
					</svg>
					<line className="hash" x1="36.92%" x2="38.07%" y1="20%" y2="20%" />
					<line className="hash" x1="61.93%" x2="63.08%" y1="20%" y2="20%" />
					<line className="hash" x1="36.92%" x2="38.07%" y1="40%" y2="40%" />
					<line className="hash" x1="61.93%" x2="63.08%" y1="40%" y2="40%" />
					<line className="hash" x1="36.92%" x2="38.07%" y1="60%" y2="60%" />
					<line className="hash" x1="61.93%" x2="63.08%" y1="60%" y2="60%" />
					<line className="hash" x1="36.92%" x2="38.07%" y1="80%" y2="80%" />
					<line className="hash" x1="61.93%" x2="63.08%" y1="80%" y2="80%" />
					<line className="yardline" x1="0" x2="100%" y1="100%" y2="100%" />
				</svg>
				<svg className="tenYards" x="0%" y="53.335%" height="6.67%">
					<svg x="18%" y="0">
						<polygon transform="translate(-16 35)" points="0,0 -10,0 -5,20" className="triangle" />
						<text transform="rotate(90 0 0)">5 0</text>
					</svg>
					<svg x="82%" y="0">
						<polygon transform="translate(26 35)" points="0,0 -10,0 -5,20" className="triangle" />
						<text transform="rotate(-90 0 0)">5 0</text>
					</svg>
					<line className="hash" x1="36.92%" x2="38.07%" y1="10%" y2="10%" />
					<line className="hash" x1="61.93%" x2="63.08%" y1="10%" y2="10%" />
					<line className="hash" x1="36.92%" x2="38.07%" y1="20%" y2="20%" />
					<line className="hash" x1="61.93%" x2="63.08%" y1="20%" y2="20%" />
					<line className="hash" x1="36.92%" x2="38.07%" y1="30%" y2="30%" />
					<line className="hash" x1="61.93%" x2="63.08%" y1="30%" y2="30%" />
					<line className="hash" x1="36.92%" x2="38.07%" y1="40%" y2="40%" />
					<line className="hash" x1="61.93%" x2="63.08%" y1="40%" y2="40%" />
					<line className="yardline" x1="0" x2="100%" y1="50%" y2="50%" />
					<line className="hash" x1="36.92%" x2="38.07%" y1="60%" y2="60%" />
					<line className="hash" x1="61.93%" x2="63.08%" y1="60%" y2="60%" />
					<line className="hash" x1="36.92%" x2="38.07%" y1="70%" y2="70%" />
					<line className="hash" x1="61.93%" x2="63.08%" y1="70%" y2="70%" />
					<line className="hash" x1="36.92%" x2="38.07%" y1="80%" y2="80%" />
					<line className="hash" x1="61.93%" x2="63.08%" y1="80%" y2="80%" />
					<line className="hash" x1="36.92%" x2="38.07%" y1="90%" y2="90%" />
					<line className="hash" x1="61.93%" x2="63.08%" y1="90%" y2="90%" />
					<line className="yardline" x1="0" x2="100%" y1="100%" y2="100%" />
				</svg>
				<svg className="tenYards" x="0%" y="60.005%" height="6.67%">
					<svg x="18%" y="0">
						<polygon transform="translate(-16 35)" points="0,0 -10,0 -5,20" className="triangle" />
						<text transform="rotate(90 0 0)">4 0</text>
					</svg>
					<svg x="82%" y="0">
						<polygon transform="translate(26 35)" points="0,0 -10,0 -5,20" className="triangle" />
						<text transform="rotate(-90 0 0)">4 0</text>
					</svg>
					<line className="hash" x1="36.92%" x2="38.07%" y1="10%" y2="10%" />
					<line className="hash" x1="61.93%" x2="63.08%" y1="10%" y2="10%" />
					<line className="hash" x1="36.92%" x2="38.07%" y1="20%" y2="20%" />
					<line className="hash" x1="61.93%" x2="63.08%" y1="20%" y2="20%" />
					<line className="hash" x1="36.92%" x2="38.07%" y1="30%" y2="30%" />
					<line className="hash" x1="61.93%" x2="63.08%" y1="30%" y2="30%" />
					<line className="hash" x1="36.92%" x2="38.07%" y1="40%" y2="40%" />
					<line className="hash" x1="61.93%" x2="63.08%" y1="40%" y2="40%" />
					<line className="yardline" x1="0" x2="100%" y1="50%" y2="50%" />
					<line className="hash" x1="36.92%" x2="38.07%" y1="60%" y2="60%" />
					<line className="hash" x1="61.93%" x2="63.08%" y1="60%" y2="60%" />
					<line className="hash" x1="36.92%" x2="38.07%" y1="70%" y2="70%" />
					<line className="hash" x1="61.93%" x2="63.08%" y1="70%" y2="70%" />
					<line className="hash" x1="36.92%" x2="38.07%" y1="80%" y2="80%" />
					<line className="hash" x1="61.93%" x2="63.08%" y1="80%" y2="80%" />
					<line className="hash" x1="36.92%" x2="38.07%" y1="90%" y2="90%" />
					<line className="hash" x1="61.93%" x2="63.08%" y1="90%" y2="90%" />
					<line className="yardline" x1="0" x2="100%" y1="100%" y2="100%" />
				</svg>
				<svg className="tenYards" x="0%" y="66.675%" height="6.67%">
					<svg x="18%" y="0">
						<polygon transform="translate(-16 35)" points="0,0 -10,0 -5,20" className="triangle" />
						<text transform="rotate(90 0 0)">3 0</text>
					</svg>
					<svg x="82%" y="0">
						<polygon transform="translate(26 35)" points="0,0 -10,0 -5,20" className="triangle" />
						<text transform="rotate(-90 0 0)">3 0</text>
					</svg>
					<line className="hash" x1="36.92%" x2="38.07%" y1="10%" y2="10%" />
					<line className="hash" x1="61.93%" x2="63.08%" y1="10%" y2="10%" />
					<line className="hash" x1="36.92%" x2="38.07%" y1="20%" y2="20%" />
					<line className="hash" x1="61.93%" x2="63.08%" y1="20%" y2="20%" />
					<line className="hash" x1="36.92%" x2="38.07%" y1="30%" y2="30%" />
					<line className="hash" x1="61.93%" x2="63.08%" y1="30%" y2="30%" />
					<line className="hash" x1="36.92%" x2="38.07%" y1="40%" y2="40%" />
					<line className="hash" x1="61.93%" x2="63.08%" y1="40%" y2="40%" />
					<line className="yardline" x1="0" x2="100%" y1="50%" y2="50%" />
					<line className="hash" x1="36.92%" x2="38.07%" y1="60%" y2="60%" />
					<line className="hash" x1="61.93%" x2="63.08%" y1="60%" y2="60%" />
					<line className="hash" x1="36.92%" x2="38.07%" y1="70%" y2="70%" />
					<line className="hash" x1="61.93%" x2="63.08%" y1="70%" y2="70%" />
					<line className="hash" x1="36.92%" x2="38.07%" y1="80%" y2="80%" />
					<line className="hash" x1="61.93%" x2="63.08%" y1="80%" y2="80%" />
					<line className="hash" x1="36.92%" x2="38.07%" y1="90%" y2="90%" />
					<line className="hash" x1="61.93%" x2="63.08%" y1="90%" y2="90%" />
					<line className="yardline" x1="0" x2="100%" y1="100%" y2="100%" />
				</svg>
				<svg className="tenYards" x="0%" y="73.345%" height="6.67%">
					<svg x="18%" y="0">
						<polygon transform="translate(-16 35)" points="0,0 -10,0 -5,20" className="triangle" />
						<text transform="rotate(90 0 0)">2 0</text>
					</svg>
					<svg x="82%" y="0">
						<polygon transform="translate(26 35)" points="0,0 -10,0 -5,20" className="triangle" />
						<text transform="rotate(-90 0 0)">2 0</text>
					</svg>
					<line className="hash" x1="36.92%" x2="38.07%" y1="10%" y2="10%" />
					<line className="hash" x1="61.93%" x2="63.08%" y1="10%" y2="10%" />
					<line className="hash" x1="36.92%" x2="38.07%" y1="20%" y2="20%" />
					<line className="hash" x1="61.93%" x2="63.08%" y1="20%" y2="20%" />
					<line className="hash" x1="36.92%" x2="38.07%" y1="30%" y2="30%" />
					<line className="hash" x1="61.93%" x2="63.08%" y1="30%" y2="30%" />
					<line className="hash" x1="36.92%" x2="38.07%" y1="40%" y2="40%" />
					<line className="hash" x1="61.93%" x2="63.08%" y1="40%" y2="40%" />
					<line className="yardline" x1="0" x2="100%" y1="50%" y2="50%" />
					<line className="hash" x1="36.92%" x2="38.07%" y1="60%" y2="60%" />
					<line className="hash" x1="61.93%" x2="63.08%" y1="60%" y2="60%" />
					<line className="hash" x1="36.92%" x2="38.07%" y1="70%" y2="70%" />
					<line className="hash" x1="61.93%" x2="63.08%" y1="70%" y2="70%" />
					<line className="hash" x1="36.92%" x2="38.07%" y1="80%" y2="80%" />
					<line className="hash" x1="61.93%" x2="63.08%" y1="80%" y2="80%" />
					<line className="hash" x1="36.92%" x2="38.07%" y1="90%" y2="90%" />
					<line className="hash" x1="61.93%" x2="63.08%" y1="90%" y2="90%" />
					<line className="yardline" x1="0" x2="100%" y1="100%" y2="100%" />
				</svg>
				<svg className="tenYards" x="0%" y="80.015%" height="6.67%">
					<svg x="18%" y="3">
						<polygon transform="translate(-16 30)" points="0,0 -10,0 -5,20" className="triangle" />
						<text transform="rotate(90 0 0)">1 0</text>
					</svg>
					<svg x="82%" y="-3">
						<polygon transform="translate(26 30)" points="0,0 -10,0 -5,20" className="triangle" />
						<text transform="rotate(-90 0 0)">1 0</text>
					</svg>
					<line className="hash" x1="36.92%" x2="38.07%" y1="10%" y2="10%" />
					<line className="hash" x1="61.93%" x2="63.08%" y1="10%" y2="10%" />
					<line className="hash" x1="36.92%" x2="38.07%" y1="20%" y2="20%" />
					<line className="hash" x1="61.93%" x2="63.08%" y1="20%" y2="20%" />
					<line className="hash" x1="36.92%" x2="38.07%" y1="30%" y2="30%" />
					<line className="hash" x1="61.93%" x2="63.08%" y1="30%" y2="30%" />
					<line className="hash" x1="36.92%" x2="38.07%" y1="40%" y2="40%" />
					<line className="hash" x1="61.93%" x2="63.08%" y1="40%" y2="40%" />
					<line className="yardline" x1="0" x2="100%" y1="50%" y2="50%" />
					<line className="hash" x1="36.92%" x2="38.07%" y1="60%" y2="60%" />
					<line className="hash" x1="61.93%" x2="63.08%" y1="60%" y2="60%" />
					<line className="hash" x1="36.92%" x2="38.07%" y1="70%" y2="70%" />
					<line className="hash" x1="61.93%" x2="63.08%" y1="70%" y2="70%" />
					<line className="hash" x1="36.92%" x2="38.07%" y1="80%" y2="80%" />
					<line className="hash" x1="61.93%" x2="63.08%" y1="80%" y2="80%" />
					<line className="hash" x1="36.92%" x2="38.07%" y1="90%" y2="90%" />
					<line className="hash" x1="61.93%" x2="63.08%" y1="90%" y2="90%" />
					<line className="yardline" x1="0" x2="100%" y1="100%" y2="100%" />
					<svg x="17.5%" y="89%">
						<text transform="rotate(90 0 0)">G</text>
					</svg>
					<svg x="82.5%" y="89%">
						<text transform="rotate(-90 0 0)">G</text>
					</svg>
				</svg>
				<svg className="endzone" x="0%" y="86.685%" height="13.315%">
					<text x="48%" y="54%">OPPONENT</text>
				</svg>
			</svg>
		</svg>
	</div>;
}


import * as React from 'react';
import {browseTo} from '../actions';
import { current as getCurrentContext } from '../componentContext';

export function HelpButton({ flagUrl, tackleUrl}: {flagUrl: string, tackleUrl: string}) {
	const { variant } = getCurrentContext();
	const url = variant === 'flag' ? flagUrl : tackleUrl
    
	return url? <a className="button" onClick={ () => browseTo(url) }><span className="icon support"></span></a>: null;
}
import { assign, find, map, sortBy } from 'lodash';
import { default as router } from 'playmaker-team-common/dist/client/router';
import { IPatchOperation, PatchOpType } from 'playmaker-team-common/dist/shared/interfaces';
import { default as valueFilters } from 'playmaker-team-common/dist/shared/valueFilters';
import * as React from 'react';
import * as actions from '../actions';
import { current as getCurrentContext } from '../componentContext';
import { IFormation } from '../models/formation';
import { default as playFactory, IPlay } from '../models/play';
import { ITag } from '../models/tag';
import { AlertMode, AlertSeverity, IAlert, INetworkInfo } from '../store';
import { _s, StringKey } from '../strings';
import { getCacheState } from '../upgradeHelper';
import { checkForUpdate } from '../upgradeHelper';
import * as viewManager from '../viewManager';
import { AlertList } from './alert';
import { NetworkContext } from './contexts';
import { Page, PageState } from './page';
import { DiagramRenderFlags } from '../models/diagramModel';

interface Props {
}

interface State {
	activePage: number;
}

class NucularPage extends React.Component<any, any> {
	public render() {
		const { goBack } = this.props;

		return <React.Fragment>
			<header>
				<div className="actions">
					<a className="button" onClick={ goBack }><span className="icon previous"></span></a>
				</div>
				<div className="title">{ _s(StringKey.RELOAD_PLAYBOOKS) }</div>
			</header>
			<div className="content scrollable">
				<div className="inner">

					<p className="left">{ _s(StringKey.DIAGNOSTICS_RELOAD_MESSAGE) }</p>

					<table>
						<thead>
							<tr>
								<td>{ _s(StringKey.PLAYBOOK) }</td>
								<td></td>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td className="property">Fall 2018</td>
								<td className="right"><a><span>{ _s(StringKey.RELOAD) }</span></a></td>
							</tr>
							<tr>
								<td className="property">Spring 2018</td>
								<td className="right"><a><span>{ _s(StringKey.RELOAD) }</span></a></td>
							</tr>
							<tr>
								<td className="property">Winter 2017</td>
								<td className="right"><a><span>{ _s(StringKey.RELOAD) }</span></a></td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</React.Fragment>;
	}
}

class StatusPage extends React.Component<any, any> {
	public static contextType = NetworkContext;
	public context!: React.ContextType<typeof NetworkContext>;

	public render() {
		const { gotoNucular } = this.props;
		const networkInfo = this.context;
		const { currentTeam, currentUser, viewState } = getCurrentContext();
		const apiVersion = networkInfo.currentVersion;
		const scriptVersion = viewState.config.version;
		const cacheState = getCacheState();
		const userAgent = (viewState.platform.userAgent || '-') + ` (${cacheState.acEnabled ? 'ac' : cacheState.swEnabled ? 'sw' : 'nc'}, ${cacheState.status})`;
		const { globalDiagramFlags } = viewState;
		let status = _s(StringKey.OFFLINE);


		if (networkInfo.canAccessApi) {
			const ping = networkInfo.pingMs;
			const connected = networkInfo.socketConnected;

			if (ping < 250) {
				status = `${ping}ms, ${connected ? 'two way' : 'one way'}`;
			} else {
				status = `${ping}ms, ${connected ? 'two way' : 'one way'}`;
			}
		}

		// <a className="link" onClick={ gotoNucular }><span>{ _s(StringKey.RELOAD_PLAYBOOKS) }</span></a>
		return <React.Fragment>
			<header>
				<div className="actions">
					<a className="button" onClick={ viewManager.popModal }><span className="icon cancel"></span></a>
				</div>
				<div className="title">{ _s(StringKey.DIAGNOSTICS) }</div>
			</header>
			<div className="content scrollable">
				<div className="inner">
					<table className="diagnosticsTable">
						<tbody>
							<tr>
								<td className="property">{ _s(StringKey.USER) }</td>
								<td className="selectable">{ currentUser ? currentUser.email : '-' }</td>
							</tr>
							<tr>
								<td className="property">{ _s(StringKey.TEAM_ID) }</td>
								<td className="selectable">{ currentTeam ? currentTeam.id : '-' }</td>
							</tr>
							<tr>
								<td className="property">{ _s(StringKey.NETWORK_STATUS) }</td>
								<td>{ status }</td>
							</tr>
							<tr>
								<td className="property">{ _s(StringKey.PLATFORM_VERSION) }</td>
								<td>{ scriptVersion || '-' } <a className="button basic small" onClick={ checkForUpdate }>{ _s(StringKey.CHECK_FOR_UPDATE)}</a></td>
							</tr>
							{ viewState.platform.type !== 'browser' ? <tr>
								<td className="property">{ _s(StringKey.APP_VERSION) }</td>
								<td>{ viewState.platform.os } { viewState.platform.version }</td>
							</tr> : null }
							<tr>
								<td className="property">{ _s(StringKey.USER_AGENT) }</td>
								<td>{ userAgent }</td>
							</tr>
							{ currentUser?.role === 'admin' || viewState.isImpersonating? <tr>
								<td className='property'>Diagram Diagnostics</td>
								<td>
									<div className="radio">
										<label className={ (globalDiagramFlags & DiagramRenderFlags.renderDiagnotics) === DiagramRenderFlags.renderDiagnotics? '': 'on'}><span>{ _s(StringKey.OFF) }</span><input type="radio" checked={(globalDiagramFlags & DiagramRenderFlags.renderDiagnotics) !== DiagramRenderFlags.renderDiagnotics } readOnly={ true} onClick={ e => actions.setGlobalDiagramFlags(DiagramRenderFlags.none) } /></label>
										<label className={ (globalDiagramFlags & DiagramRenderFlags.renderDiagnotics) === DiagramRenderFlags.renderDiagnotics? 'on': ''}><span>{ _s(StringKey.ON) }</span><input type="radio" checked={(globalDiagramFlags & DiagramRenderFlags.renderDiagnotics) === DiagramRenderFlags.renderDiagnotics } readOnly={ true } onClick={ e => actions.setGlobalDiagramFlags(DiagramRenderFlags.renderDiagnotics) } /></label>
										<span></span>
									</div>
								</td>
							</tr>: null }
						</tbody>
					</table>
				</div>
			</div>
		</React.Fragment>;
	}
}

export class DiagnosticsModal extends React.Component<Props, State> {
	private gotoStatusPage;
	private gotoNucularPage;

	constructor(props: Props) {
		super(props);

		this.state = { activePage: 0 };

		this.gotoStatusPage = this.goToPage.bind(this, 0);
		this.gotoNucularPage = this.goToPage.bind(this, 1);
	}

	public getPageState(key) {
		const { activePage } = this.state;

		if (key === activePage) {
			return PageState.IN;
		} else if (key < activePage) {
			return PageState.OUT;
		}

		return PageState.DEFAULT;
	}

	public goToPage(page: number) {
		actions.clearAlerts();
		this.setState((previousState) => {
			const newState = assign({}, previousState) as State;

			newState.activePage = page;

			return newState;
		});
	}

	public render() {

		return <React.Fragment>
			<Page key="page0" pageClasses={ ['page'] } pageState={ this.getPageState(0)}>
				<StatusPage gotoNucular={ this.gotoNucularPage } />
			</Page>

			<Page key="page1" pageClasses={ ['page'] } pageState={ this.getPageState(1)}>
				<NucularPage goBack={ this.gotoStatusPage } />
			</Page>
		</React.Fragment>;
	}
}

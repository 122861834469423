import { assign, filter, find, forEach, map, sortBy } from 'lodash';
import { AnnotationType, IAnnotation } from './models/annotation';
import { DiagramRenderFlags, GamePhase, SpecialTeamsUnit } from './models/diagramModel';
import { default as formationFactory, IFormation } from './models/formation';
import { default as playFactory, IPlay } from './models/play';
import { default as playbookFactory, IPlaybook } from './models/playbook';
import { PlaybookAccess } from './models/playbookMember';
import { IPlayer, PlayerShading, PlayerSymbol, PlayerZoneShape } from './models/player';
import { EndCapType, LineStyle } from './models/routeSection';
import { ITeam } from './models/team';
import { TeamRole } from './models/teamMember';
import { IVector } from './models/vector';
import { appState as getAppState, IModelState } from './store';
import { _s } from './strings';

export interface IImportDiagramItem {
	loc: IVector;
}

export interface IImportRouteSection extends IImportDiagramItem {
	endCapType: EndCapType;
	lineStyle: LineStyle;
}

export interface IImportRoute {
	label: string;
	sections: IImportRouteSection[];
}

export interface IRoutedDiagramImportItem extends IImportDiagramItem {
	curved: boolean;
	endCapType: EndCapType;
	lineStyle: LineStyle;
	maxRouteSections: number;
	motion: boolean;
	routeColor: number;
	route: IImportRoute;
}

export interface IImportPlayer extends IRoutedDiagramImportItem  {
	color: number;
	label: string;
	note?: string;
	shading?: PlayerShading;
	symbol: PlayerSymbol;
	zoneScale?: number;
	zoneShape?: PlayerZoneShape;
}

export interface IImportAnnotation extends IRoutedDiagramImportItem {
	color: number;
	label: string;
	subType: AnnotationType;
	zoneScale?: number;
	zoneShape?: PlayerZoneShape;
}

export interface IImportDiagram {
	annotations?: IImportAnnotation[];
	ballLocation: IVector;
	mates: IImportPlayer[];
	label: string;
	notes: string;
	phase: GamePhase;
	posture: GamePhase;
	unit?: SpecialTeamsUnit;
	renderFlags: DiagramRenderFlags;
	originalId: string;
}

export interface IImportPlay extends IImportDiagram {
	categories: string[];
	formation: string;
	opponents: IImportPlayer[];
	playersPerSide: number;
	personnelGroup?: any;
}

export interface IImportPlayList {
	originalId: string;
	relatedId: string; // the category or formation id that owns this play list
	playIds: string;
}

export interface IImportTag {
	originalId: string;
	label: string;
	note: string;
	phase: GamePhase;
	sortIndex: number;
}

export interface IImportPlaybookSettings {
	categories: IImportTag[];
	fieldKey: string;
	playLists: IImportPlayList[];
	schemaVersion?: number;
}

export interface IImportPlaybook {
	name: string;
	playersPerSide: number;
	settings: IImportPlaybookSettings;
	formations: IImportDiagram[];
	plays: IImportPlay[];
}

const PLAYER_INDEX_MAP_FLAG = {
	players4: {
		players5: [0, 1, 2, null, 3],
		players6: [0, 1, 2, null, null, 3],
		players7: [0, 1, 2, null, null, null, 3],
		players8: [0, 1, 2, null, null, null, null, 3],
		players9: [0, 1, 2, null, null, null, null, null, 3],
	},
	players5: {
		players6: [0, 1, 2, 3, null, 4],
		players7: [0, 1, 2, 3, null, null, 4],
		players8: [0, 1, 2, 3, null, null, null, 4],
		players9: [0, 1, 2, 3, null, null, null, null, 4],
	},
	players6: {
		players7: [0, 1, 2, 3, 4, null, 5],
		players8: [0, 1, 2, 3, 4, null, null, 5],
		players9: [0, 1, 2, 3, 4, null, null, null, 5],
	},
	players7: {
		players8: [0, 1, 2, 3, 4, 5, null, 6],
		players9: [0, 1, 2, 3, 4, 5, null, null, 6],
	},
	players8: {
		players9: [0, 1, 2, 3, 4, 5, 6, null, 7],
	},
};

// only works for flag playbooks
export function upsizeFlagPlaybook(playbookSpec: IImportPlaybook, newPlayersPerSide: number) {
	const appState = getAppState();
	const { viewState } = appState;
	const settings = viewState.settings.playersPerSide[newPlayersPerSide];

	if (settings && settings.presetFormations) {
		const playerMap = PLAYER_INDEX_MAP_FLAG[`players${playbookSpec.playersPerSide}`][`players${newPlayersPerSide}`];

		if (playerMap) {
			const presetFormations = {};
			const offensiveFormation = find(playbookSpec.formations, { phase: GamePhase.Offense });
			const defensiveFormation = find(playbookSpec.formations, { phase: GamePhase.Defense });

			presetFormations[GamePhase.Offense] = find(settings.presetFormations, { phase: GamePhase.Offense });
			presetFormations[GamePhase.Defense] = find(settings.presetFormations, { phase: GamePhase.Defense });

			if (presetFormations[GamePhase.Offense] && presetFormations[GamePhase.Defense] && offensiveFormation && defensiveFormation) {
				playbookSpec.playersPerSide = newPlayersPerSide;

				assign(offensiveFormation, {
					annotations: (presetFormations[GamePhase.Offense].annotations || []).concat(),
					mates: (presetFormations[GamePhase.Offense].mates || []).concat(),
				});
				assign(defensiveFormation, {
					annotations: (presetFormations[GamePhase.Defense].annotations || []).concat(),
					mates: (presetFormations[GamePhase.Defense].mates || []).concat(),
				});

				forEach(playbookSpec.plays, (playSpec: IImportPlay) => {
					const presetFormation = presetFormations[playSpec.phase];
					const ballLocationOffset = { x: playSpec.ballLocation.x - presetFormation.ballLocation.x, y: playSpec.ballLocation.y - presetFormation.ballLocation.y };

					playSpec.playersPerSide = newPlayersPerSide;
					playSpec.personnelGroup = { playersPerSide: playSpec.playersPerSide, phase: playSpec.phase };

					for (let i = newPlayersPerSide; i--; i >= 0) {
						const mappedIndex = playerMap[i];
						let mate;

						if (mappedIndex === null) {
							mate = assign({}, find(presetFormation.mates, { sortIndex: i }));
							mate.loc = { x: mate.loc.x + ballLocationOffset.x, y: mate.loc.y + ballLocationOffset.y };
							playSpec.mates.push(mate);
						} else {
							mate = find(playSpec.mates, { sortIndex: mappedIndex });
						}

						mate.sortIndex = i;
					}
				});
			}
		}

	}
}

export function processPlaybookImport(playbookSpec: IImportPlaybook, team: ITeam) {
	// TODO: validate schemaVersion
	const appState = getAppState();
	const { viewState } = appState;
	const filteredFieldOptions = filter(viewState.settings.fieldOptions, (option) => option.validPlayerCount.indexOf(playbookSpec.playersPerSide) !== -1);
	const mutatableSpec: IImportPlaybook = assign({}, playbookSpec);
	let playbookName = mutatableSpec.name;
	let playbookNameCount = 0;

	while (find(team.playbooks.values, (p) => normalizeString(p.name) === normalizeString(playbookName))) {
		playbookNameCount++;
		playbookName = `${mutatableSpec.name} ${playbookNameCount}`;
	}

	mutatableSpec.name = playbookName;

	// ensure category labels
	for (const categorySpec of mutatableSpec.settings.categories) {
		categorySpec.label = (categorySpec.label && categorySpec.label.trim()) || categorySpec.originalId;
	}
	// ensure formation labels
	for (const formationSpec of mutatableSpec.formations) {
		formationSpec.label = (formationSpec.label && formationSpec.label.trim()) || formationSpec.originalId;
	}

	const playbook = playbookFactory(mutatableSpec);
	const plays = [];
	const formations = [];

	playbook.teamId = team.id;

	for (const categorySpec of mutatableSpec.settings.categories) {
		const playList = find(playbook.settings.playLists.values, { relatedId: categorySpec.originalId });
		const category = find(playbook.settings.categories.values, { label: categorySpec.label, phase: categorySpec.phase });

		if (playList && category) {
			playList.relatedId = category.id;
		}
	}

	// default teamMember permissions
	for (const teamMember of filter(team.members.values, (m) => m.userId || m.inviteCode)) {
		const playbookAccess = (teamMember.role === TeamRole.Player ? PlaybookAccess.View : PlaybookAccess.Edit);

		playbook.addMember(teamMember.id, playbookAccess);
	}

	for (const formationSpec of mutatableSpec.formations) {
		// if this is a formation created prior to the special teams rollout, ignore it - it is invalid
		if (formationSpec.phase === GamePhase.SpecialTeams && !formationSpec.unit) {
			break;
		}

		const phaseFormations = filter(formations, { phase: formationSpec.phase });
		const formation = formationFactory(formationSpec);
		const playList = find(playbook.settings.playLists.values, { relatedId: formationSpec.originalId });

		if (playList) {
			playList.relatedId = formation.id;
		}

		formation.playbookId = playbook.id;
		formation.teamId = playbook.teamId;
		formation.sortIndex = phaseFormations.length;

		if (!find(formations, (f) => f.phase === formation.phase && normalizeString(f.label) === normalizeString(formation.label))) {
			formations.push(formation);
		}
	}

	for (const playSpec of mutatableSpec.plays) {
		const phasePlays = filter(plays, { phase: playSpec.phase });
		const specCategories = playSpec.categories || [];
		playSpec.categories = [];
		const play = playFactory(playSpec);
		const matchingCategories = filter(playbook.settings.categories.values, (pc) => pc.phase === play.phase && !!find(specCategories, (c) => normalizeString(pc.label) === normalizeString(c)));

		play.playbookId = playbook.id;
		play.teamId = playbook.teamId;
		play.sortIndex = phasePlays.length;
		play.categories = map(matchingCategories, (c) => c.id).join(',');

		// TODO: find the formation by normalized label and set play.formation to formation.label
		play.formation = play.formation || 'None';

		if (!find(formations, (f) => f.phase === play.phase && normalizeString(f.label) === normalizeString(play.formation))) {
			formations.push(formationFactory({ playbookId: play.playbookId, teamId: playbook.teamId, label: play.formation, phase: play.phase, mates: map(play.mates.values, (m) => assign({}, m.toDocument(), { route: {}})) }));
		}

		plays.push(play);
		for (const playList of playbook.settings.playLists.values) {
			if (playList.playIds) {
				playList.playIds = playList.playIds.replace(playSpec.originalId, play.id);
			}
		}
	}

	// be sure that the playbook has valid field options
	const currentFieldOptions = find(filteredFieldOptions, (option) => option.key === playbook.settings.fieldKey);
	if (!currentFieldOptions && filteredFieldOptions.length) {
		playbook.settings.fieldKey = filteredFieldOptions[0].key;
	}

	// add special teams formations if missing
	if (!filter(formations, { phase: GamePhase.SpecialTeams }).length) {
		const settings = viewState.settings.playersPerSide[playbookSpec.playersPerSide];

		if (settings) {
			const presets = settings.presetFormations && filter(settings.presetFormations, { phase: GamePhase.SpecialTeams });
			if (presets && presets.length) {
				let formationSortIndex = formations.length;

				for (const preset of presets) {
					const formation = formationFactory(preset);

					formation.playbookId = playbook.id;
					formation.label = _s(preset.labelKey);

					formation.sortIndex = formationSortIndex ++;

					formations.push(formation);
				}
			}
		}
	}

	return { playbook, formations, plays };
}

function normalizeString(val: string) {
	return val ? val.toLowerCase() : '';
}

export function exportPlaybook(playbook: IPlaybook, model: IModelState): IImportPlaybook {
	const playbookSpec: IImportPlaybook = {
		name: playbook.name,
		playersPerSide: playbook.playersPerSide,
		settings: {
			categories: map(sortBy(playbook.settings.categories.values, ['phase', 'sortIndex']), (c) => ({ label: c.label, note: c.note, phase: c.phase, sortIndex: c.sortIndex, originalId: c.id })),
			fieldKey: playbook.settings.fieldKey,
			playLists: map(playbook.settings.playLists.values, (pl) => ({ originalId: pl.id, relatedId: pl.relatedId, playIds: pl.playIds })),
			schemaVersion: playbook.settings.schemaVersion,
		},
		formations: [],
		plays: [],
	};
	const { formations, plays } = model;
	const playbookFormations: IFormation[] = sortBy(filter(formations, { playbookId: playbook.id }), ['phase', 'sortIndex']);
	const playbookPlays: IPlay[] = sortBy(filter(plays, { playbookId: playbook.id }), ['phase', 'sortIndex']);

	for (const formation of playbookFormations) {
		playbookSpec.formations.push({
			annotations: map(formation.annotations.values, exportAnnotation),
			ballLocation: (formation.ballLocation as any).toDocument(),
			label: formation.label,
			mates: map(formation.mates.values, exportPlayer),
			notes: formation.notes,
			phase: formation.phase,
			posture: formation.posture,
			unit: formation.unit,
			renderFlags: formation.renderFlags,
			originalId: formation.id,
		});
	}

	for (const play of playbookPlays) {
		playbookSpec.plays.push({
			annotations: map(play.annotations.values, exportAnnotation),
			ballLocation: (play.ballLocation as any).toDocument(),
			categories: map(play.categoryList, (c) => find(playbook.settings.categories.values, { id: c }).label),
			formation: play.formation,
			label: play.label,
			mates: map(play.mates.values, exportPlayer),
			opponents: [], // map(play.opponents, exportPlayer),
			notes: play.notes,
			phase: play.phase,
			playersPerSide: play.playersPerSide,
			posture: play.posture,
			renderFlags: play.renderFlags,
			originalId: play.id,
			unit: play.unit,
		});
	}

	return playbookSpec;
}

export function saveAsJson(importSpec: IImportPlaybook, fileName: string) {
	const a = document.createElement('a');
	a.setAttribute('href', 'data:text/plain;charset=utf-u,' + encodeURIComponent(JSON.stringify(importSpec)));
	a.setAttribute('download', fileName);
	a.click();
}

function exportPlayer(player: IPlayer): IImportPlayer {
	const filterFunc = function(ctx) { return ['id', 'itemVersion', 'createdById', 'dateCreated', 'lastModifiedById', 'dateLastModified', 'deleted', 'sections'].indexOf(ctx.property) === -1; };
	const result = player.toDocument(undefined, filterFunc) as IImportPlayer;

	result.route.sections = map(player.route.sections.values, (section) => section.toDocument(undefined, ['/id', '/itemVersion', '/createdById', '/dateCreated', '/lastModifiedById', '/dateLastModified', '/deleted']));

	return result;
}

function exportAnnotation(annotation: IAnnotation): IImportAnnotation {
	const filterFunc = function(ctx) { return ['id', 'itemVersion', 'createdById', 'dateCreated', 'lastModifiedById', 'dateLastModified', 'deleted', 'sections'].indexOf(ctx.property) === -1; };
	const result = annotation.toDocument(undefined, filterFunc) as IImportAnnotation;

	if (result.route) {
		result.route.sections = map(annotation.route.sections.values, (section) => section.toDocument(undefined, ['/id', '/itemVersion', '/createdById', '/dateCreated', '/lastModifiedById', '/dateLastModified', '/deleted']));
	}

	return result;
}

import { find, map, orderBy } from 'lodash';
import { IPatchOperation, PatchOpType } from 'playmaker-team-common/dist/shared/interfaces';
import { default as valueFilters } from 'playmaker-team-common/dist/shared/valueFilters';
import * as React from 'react';
import * as actions from '../actions';
import { current as getCurrentContext } from '../componentContext';
import { AnnotationType } from '../models/annotation';
import { IPlay } from '../models/play';
import { AnimationSpeed } from '../models/routedDiagramItemModel';
import { IPoint, vectorUtil } from '../models/vector';
import * as touchHelper from '../touchHelper';
import { PlayerPosition } from './playerPosition';

interface Props {
	classNames?: string[];
	state: 'playing' | 'paused' | 'ended' | 'initial';
	modalId: string;
	offset: IPoint;
	play: IPlay;
	begin: () => void;
	reset: () => void;
	pause: () => void;
	unpause: () => void;
	onClose: (e?) => void;
	onOpen: () => void;
	updatePlay: (patch: IPatchOperation[]) => void;
	showOpponents: boolean;
}

interface State {
	showAdvanced: boolean;
}

const BallIcon = ({}) => {
	return <svg className="ball" x="50%" y="50%">
		<g className="position">
			<path className="border"
				d="M12.24-12.25c-1.82-1.82-12.48-3-20,4.52s-6.34,18.15-4.52,20,12.49,3,20-4.51S14.07-10.42,12.24-12.25Z">
			</path>
			<path className="symbol"
				d="M12.24-12.25c-1.82-1.82-12.48-3-20,4.52s-6.34,18.15-4.52,20,12.49,3,20-4.51S14.07-10.42,12.24-12.25ZM-4,5.29-5.29,4,4-5.29,5.29-4Z">
			</path><text x="0" y="0"></text>
		</g>
	</svg>;
};

const PositionRowContent = ({ item, onAnimationSpeedChange, isOpponent = false, isBall= false }) => {
	return <div className="position">
		<div className="positionIcon"><svg className="icon" viewBox="0 0 30 30">{ isBall ? <BallIcon /> : <svg className="player" x="50%" y="50%"><PlayerPosition player={ item } color={ `color${item.color}` } active={ false } isOpponent={ isOpponent } /></svg> }</svg></div>
		<div className="radio">
			<label className={ item.animationSpeed === AnimationSpeed.minimum ? 'on' : '' }><span className="icon animationSpeedSlow"></span><input type="radio" readOnly={ true } checked={ item.animationSpeed === AnimationSpeed.minimum } onClick={ onAnimationSpeedChange } value={ `${item.id}.${AnimationSpeed.minimum}` } /></label>
			<label className={ !item.animationSpeed ? 'on' : '' }><span className="icon animationSpeedMedium"></span><input type="radio" readOnly={ true } checked={ !item.animationSpeed } onClick={ onAnimationSpeedChange } value={ `${item.id}.${AnimationSpeed.default}` } /></label>
			<label className={ item.animationSpeed === AnimationSpeed.maximum ? 'on' : '' }><span className="icon animationSpeedFast"></span><input type="radio" readOnly={ true } checked={ item.animationSpeed === AnimationSpeed.maximum } onClick={ onAnimationSpeedChange } value={ `${item.id}.${AnimationSpeed.maximum}` } /></label>
		</div>
	</div>;
};

export class AnimationToolbar extends React.Component<Props, State> {
	private _trackedTouch: any;
	private _dragTarget: any;

	constructor(props: Props) {
		super(props);

		this.handleAdvancedClick = this.handleAdvancedClick.bind(this);
		this.handleAnimationSpeedChange = this.handleAnimationSpeedChange.bind(this);
		this.handleDragEnd = this.handleDragEnd.bind(this);
		this.handleDragMove = this.handleDragMove.bind(this);
		this.handleDragStart = this.handleDragStart.bind(this);
		this.handlePlayPauseClick = this.handlePlayPauseClick.bind(this);
		this.handleResetClick = this.handleResetClick.bind(this);
		this.handleReplayClick = this.handleReplayClick.bind(this);

		this._dragTarget = React.createRef();

		this.state = { showAdvanced: false };
	}

	public componentDidMount() {
		if (this.props.onOpen) {
			this.props.onOpen();
		}
	}

	public handleDragStart(e: React.MouseEvent | React.TouchEvent) {
		const touch: touchHelper.ITouch = touchHelper.touchFromEvent(e);

		if (touch) {
			document.addEventListener('mousemove', this.handleDragMove);
			document.addEventListener('touchmove', this.handleDragMove);
			document.addEventListener('mouseup', this.handleDragEnd);
			document.addEventListener('touchend', this.handleDragEnd);

			this._trackedTouch = touch;

			actions.setRouteInteractionDisabled(true);
		}
	}

	public handleDragMove(e: MouseEvent | TouchEvent) {
		const { modalId, offset } = this.props;
		const touch: touchHelper.ITouch = touchHelper.touchFromEvent(e, e.type, this._trackedTouch && this._trackedTouch.identifier);

		if (touch && this._dragTarget && this._dragTarget.current) {
			const delta = touchHelper.getTouchVector(this._trackedTouch, touch);
			const updatedOffset = vectorUtil.add(offset, delta);

			this._trackedTouch = touch;

			actions.updateModalProps(modalId, { offset: updatedOffset });
			actions.setDiagramConfig({ animationModalOffset: updatedOffset });
		}
	}

	public handleDragEnd(e: MouseEvent | TouchEvent) {
		// const touch: touchHelper.ITouch = touchHelper.touchFromEvent(e, this._trackedTouch.sourceType, this._trackedTouch.identifier);

		document.removeEventListener('mousemove', this.handleDragMove);
		document.removeEventListener('touchmove', this.handleDragMove);
		document.removeEventListener('mouseup', this.handleDragEnd);
		document.removeEventListener('touchend', this.handleDragEnd);

		this.handleDragMove(e);

		this._trackedTouch = null;

		actions.setRouteInteractionDisabled(false);
	}

	public updateOffset(offset: IPoint) {
		let { classNames = [], modalId } = this.props;
		const { showAdvanced } = this.state;

		if (showAdvanced && classNames.indexOf('advanced') === -1) {
			classNames = [].concat(classNames);
			classNames.push('advanced');
		} else if (!showAdvanced && classNames.indexOf('advanced') !== -1) {
			classNames = [].concat(classNames);
			classNames.splice(classNames.indexOf('advanced'), 1);
		}

		actions.updateModalProps(modalId, { offset, classNames });
	}

	public handleAdvancedClick(e) {
		const { playbookPermissions } = getCurrentContext();
		const { offset } = this.props;

		if (!playbookPermissions.canUpdate) {
			return;
		}

		this.setState({ showAdvanced: !this.state.showAdvanced }, () => {

			this.updateOffset(offset);
		});
	}

	public handleResetClick(e) {
		const { reset, state } = this.props;

		if (['playing', 'paused', 'ended'].indexOf(state) !== -1) {
			reset();
		}
	}

	public handlePlayPauseClick(e) {
		const { begin, pause, unpause, state } = this.props;

		if (state === 'paused') {
			unpause();
		} else if (state === 'playing') {
			pause();
		} else if (['ended', 'initial'].indexOf(state) !== -1) {
			begin();
		}
	}

	public handleReplayClick(e) {
		const { begin, state } = this.props;

		if (state !== 'initial') {
			begin();
		}
	}

	public handleAnimationSpeedChange(e) {
		const { play, updatePlay } = this.props;
		const parts = e.currentTarget.value.split('.');
		const itemId: string = parts[0];
		const isOpponent = !!(play.opponents && find(play.opponents, { id: itemId }));
		const isAnnotation = !!(play.opponents && find(play.annotations, { id: itemId }));

		updatePlay([{ op: PatchOpType.replace, path: `/${isOpponent ? 'opponents' : isAnnotation ? 'annotations' : 'mates'}/${itemId}/animationSpeed`, value: Number(parts[1]) }]);
	}

	public render() {
		const { state, onClose, play, showOpponents } = this.props;
		const { showAdvanced } = this.state;
		const { playbookPermissions } = getCurrentContext();
		const animatedBall = find(play.annotations, { subType: AnnotationType.BallAnimatable });
		const advancedButtonClasses = ['button'];

		if (!playbookPermissions.canUpdate) {
			advancedButtonClasses.push('disabled');
		} else if (showAdvanced) {
			advancedButtonClasses.push('on');
		}

		return <div className="view">
			<header>
				<div className="actions">
					<a className="button" onMouseDown={ this.handleDragStart } onTouchStart={ this.handleDragStart } ref={ this._dragTarget }><span className="icon drag"></span></a>
				</div>
				<div className="actions tools">
					<a className={ advancedButtonClasses.join(' ') } onClick={ this.handleAdvancedClick }><span className="icon toolAdvanced"></span></a>
					<a className={ `button${['playing', 'paused', 'ended'].indexOf(state) !== -1 ? '' : ' disabled' }` } onClick={ this.handleResetClick }><span className="icon toolAnimationReset"></span></a>
					<a className="button" onClick={ this.handlePlayPauseClick }><span className={ `icon ${['initial', 'paused', 'ended'].indexOf(state) !== -1 ? 'toolAnimationPlay' : 'toolAnimationPause'}` }></span></a>
					<a className={ `button${['playing', 'paused', 'ended'].indexOf(state) !== -1 ? '' : ' disabled' }` } onClick={ this.handleReplayClick }><span className="icon toolAnimationReplay"></span></a>
				</div>
				<div className="actions">
					<a className="button" onClick={ onClose }><span className="icon ok"></span></a>
				</div>
			</header>
			<div className={ showAdvanced ? 'content animationTools in' : 'content animationTools' }>
				<h3>Animation Speed</h3>
				<div className="inner">
					<div className="columns">
						<div className="group">
							<div className="list scrollable">
								{ animatedBall ? <PositionRowContent key={ animatedBall.id } item={ animatedBall } isOpponent={ false } isBall={ true } onAnimationSpeedChange={ this.handleAnimationSpeedChange } /> : null }
								{
									map(orderBy(play.mates.values, ['sortIndex'], ['desc']), (player) => <PositionRowContent key={player.id} item={ player } isOpponent={ false } onAnimationSpeedChange={ this.handleAnimationSpeedChange } />)
								}
							</div>
						</div>

						{ showOpponents ?
							<div className="group">
								<div className="list scrollable">
									{
										map(orderBy(play.opponents.values, ['sortIndex'], ['desc']), (player) => <PositionRowContent key={player.id} item={ player } isOpponent={ true } onAnimationSpeedChange={ this.handleAnimationSpeedChange } />)
									}
								</div>
							</div>
							: null
						}
					</div>
				</div>
			</div>
		</div>;
	}
}

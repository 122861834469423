import { IModel } from 'playmaker-team-common/dist/shared/interfaces';
import { createFactory } from 'playmaker-team-common/dist/shared/modelFactory';
import { ModelSet } from 'playmaker-team-common/dist/shared/modelSet';
import { default as validator } from 'playmaker-team-common/dist/shared/validator';
import { default as valueFilters } from 'playmaker-team-common/dist/shared/valueFilters';
import { default as personnelGroupFactory, IPersonnelGroup } from './personnelGroup';

export interface ITeamSettings extends IModel {
	color: string;
	logoUrl: string;
	personnelGroups: ModelSet<IModel & IPersonnelGroup>;
	stripeCustomerId: string;
}

export default createFactory<ITeamSettings>('TeamSettings', {
	color: {
		setFilters: valueFilters.clean,
		rules: [validator.match(/[\da-f]{6}/i, false)],
	},
	logoUrl: {
		setFilters: valueFilters.clean,
	},
	personnelGroups: {
		setFilters: valueFilters.toModelSet(personnelGroupFactory),
		rules: validator.validModel(personnelGroupFactory.rules()),
		defaultValue: {},
	},
	stripeCustomerId: {
		setFilters: valueFilters.clean,
	},
});

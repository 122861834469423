export enum PatchOpType {
	add = 'add',
	remove = 'remove',
	replace = 'replace',
}

export interface IPatchOperation {
	op: PatchOpType;
	path: string;
	value?: any;
}

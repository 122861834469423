import * as React from 'react';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';

// import { CardElement, injectStripe } from 'react-stripe-elements';
import { _s, StringKey } from '../strings';
import { Spinner } from './spinner';

interface Props {
	buttonLabel: string;
	buttonClass?: string;
	onChange?: (e) => void;
	onButtonClick: (tokenCreator: () => Promise<any>) => void;
}

interface State {
	nameOnCard: string;
	cardComplete?: boolean;
	cardDirty?: boolean;
}

export function CardForm({ buttonLabel, buttonClass, onChange, onButtonClick }: Props) {
	const buttonClasses = ['button basic payment'];
	const [ state, setState ] = React.useState<State>(() => { return { nameOnCard: '' }});
	const [ changeEvent, setChangeEvent] = React.useState<Record<string, any>>(() => { return {}; });
	const stripe = useStripe();
	const elements = useElements();

	React.useEffect(() => {
		if (onChange) {
			onChange(changeEvent);
		}
	}, [changeEvent]);

	const handleCardChange = (e) => {
		setState(Object.assign({}, state, {
			cardComplete: e.complete,
			cardDirty: !e.empty
		}));

		setChangeEvent({ source: 'card', complete: e.complete, empty: e.empty });
	}

	const handleNameChange = (e) => {
		setState(Object.assign({}, state, { nameOnCard: e.target.value }));
		setChangeEvent({ source: 'name', value: e.target.value  });
	}

	const handlePaymentClick = () => {
		const createToken = async () => {
			const result = await stripe.createToken(elements.getElement(CardElement), { name: state.nameOnCard });

			if (result.error) {
				throw result.error;
			}

			return result.token;
		};

		onButtonClick(createToken);
	}

	if (!state.cardComplete || !state.nameOnCard) {
		buttonClasses.push('disabled');
	}

	if (buttonClass) {
		buttonClasses.push(buttonClass);
	}

	return !stripe? <Spinner />: <React.Fragment>
		<input type="text" placeholder={ _s(StringKey.BILLING_NAME_ON_CARD) } value={ state.nameOnCard } onChange={ handleNameChange } />
		<CardElement onChange={ handleCardChange }
			options={ { 
				style: {
					base: {
						'color': '#fff',
						'fontFamily': '"Barlow Condensed", Helvetica, sans-serif',
						'fontSize': '16px',
						'::placeholder': {
							color: '#959da1',
						},
						'fontSmoothing': 'auto',
						'iconColor': '#959da1',
					},
					invalid: {
						color: '#e50101',
						iconColor: '#e50101',
					},
				}
			} }
		/>
		<a className={ buttonClasses.join(' ') } onClick={ handlePaymentClick }><span>{ buttonLabel }</span></a>
	</React.Fragment>;
}
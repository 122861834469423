import { forEach, map } from 'lodash';
import * as React from 'react';
import { current as getCurrentContext } from '../componentContext';
import { GamePhase } from '../models/diagramModel';
import { IFormation } from '../models/formation';
import { IPlay } from '../models/play';
import { IPlaybook } from '../models/playbook';
import { SystemFeature } from '../models/subscriptionPlan';
import { ITag } from '../models/tag';
import { _s, StringKey } from '../strings';
import { CachedImage } from './cachedImage';
import { PrintControl, PrintPlay } from './printControl';

interface Props {
	categories: ITag[];
	fieldOptions: any;
	formations: IFormation[];
	optionValues: {[key: string]: any};
	playbook: IPlaybook;
	plays: IPlay[];
}

const SponsorContent = () => {
	const { currentSubscription, sponsorLogoPrintUrl } = getCurrentContext();

	if (!sponsorLogoPrintUrl || !currentSubscription.hasFeature(SystemFeature.print)) {
		return null;
	}

	return <div className="sponsor">
		<span className="icon photo"><CachedImage src={ sponsorLogoPrintUrl } /></span>
	</div>;
};

function PrintPage({ date, phase, children }) {
	const phaseLabel = phase === GamePhase.Offense ? _s(StringKey.OFFENSE) : phase === GamePhase.Defense ? _s(StringKey.DEFENSE) : phase === GamePhase.SpecialTeams ? _s(StringKey.SPECIAL_TEAMS) : '';
	const { currentTeam } = getCurrentContext();
	const logoImg = currentTeam.settings.logoUrl ? <CachedImage className="logo"  key={ currentTeam.settings.logoUrl || 'empty' } src={ currentTeam.settings.logoUrl } /> : null;
	const teamColor = currentTeam && `#${currentTeam.settings.color}`;

	return <div className="printPage">
		<div className="title">
			<div className="team">
				<div className="logo">
					{ logoImg }
					<div className="border" style={{ backgroundColor: teamColor }}></div>
				</div>
				<div className="info">{ currentTeam.name }<span>{ phaseLabel }</span></div>
			</div>
			<div className="timestamp"><div className="playmaker"></div>{ date.toLocaleString() }</div>
			<SponsorContent />
		</div>
		<section>
			{ children }
		</section>
	</div>;
}

export class PrintCallSheet extends React.Component<Props> {
	constructor(props) {
		super(props);
	}

	public render() {
		const { categories, fieldOptions, formations, optionValues, playbook, plays } = this.props;

		return <PrintControl optionValues={ optionValues } playbook={ playbook }>{ (params) => {
			const { className, buildPlayLists } = params;
			const classNames = [className, 'callSheet'];
			const groupSize = optionValues.layout.groupSize;
			const playColorCoding = optionValues.playColorCoding?.key;
			// const type = optionValues.layout.type;
			const date = new Date();
			const phase = plays[0].phase;
			const pages = [];
			const { categoryList, formationList } = buildPlayLists(categories, formations, plays);
			let playCount = 0;
			const items = [];

			if (categoryList.length) {
				let autoColor = 0;

				forEach(categoryList, (c) => {
					const color = playColorCoding === 'auto'? autoColor: c.color || 0;

					if (categoryList.length > 1) {
						items.push({ categoryName: c.label, className: `category color${color}` });
					}

					forEach(c.plays, (p) => items.push({ className: `printPlay color${color}`, play: p }));

					if (categoryList.length > 1) {
						autoColor = autoColor === 8 ? 0 : autoColor + 1;
					}
				});
			} else if (formationList.length) {
				let autoColor = 0;

				forEach(formationList, (f) => {
					const color = playColorCoding === 'auto'? autoColor: f.color || 0;

					forEach(f.plays, (p) => items.push({ className: `printPlay color${color}`, play: p }));

					if (formationList.length > 1) {
						autoColor = autoColor === 8 ? 0 : autoColor + 1;
					}
				});
			}

			const pageCount = Math.ceil(items.length / groupSize);

			for (let i = 0; i < pageCount; i ++) {
				const pageItems = items.slice(i * groupSize, (i + 1) * groupSize);
				const playContent = map(pageItems, (item) => {
					if (item.categoryName) {
						return <div key={ item.categoryName }className={ item.className }><div className="info"><span>{ item.categoryName }</span></div></div>;
					} else {
						const play = item.play as IPlay;

						return  <PrintPlay className={ item.className } key={ playCount } play={ play } playCount={ playCount++ } fieldOptions={ fieldOptions} />;
					}

				});

				pages.push(<PrintPage key={ i } date={ date } phase={ phase }>{ playContent }</PrintPage>);
			}

			// console.log(`groupSize ${groupSize}`);
			classNames.push(map(optionValues, (v) => v.className).join(' '));

			return <div className={ classNames.join(' ') }>
				{ pages }
			</div>;
		}}</PrintControl>;

	}
}

import { assign } from 'lodash';
import { default as router } from 'playmaker-team-common/dist/client/router';
import * as React from 'react';
import * as actions from '../actions';
import { current as getCurrentContext } from '../componentContext';
import { DiagramRenderFlags } from '../models/diagramModel';
import { default as formationFactory, IFormation } from '../models/formation';
import { IPlaybook } from '../models/playbook';
import { ITeam } from '../models/team';
import { IUser } from '../models/user';
import * as store from '../store';
import { Diagram } from './diagram';
import { PlayerToolbarFormation } from './playerToolbarFormation';

interface Props {
	alerts?: store.IAlert[];
	appState: store.IAppState;
}

export class Formation extends React.Component<Props> {

	public saveFormation(mutatedFormation: IFormation) {
		actions.saveFormation(mutatedFormation);
	}

	public render() {
		const { alerts= [], appState } = this.props;
		const { currentFormation, currentPlaybook} = getCurrentContext();

		if (!currentFormation) {
			return null;
		}

		return <Diagram
			key={ currentFormation.id }
			alerts={ alerts }
			fieldKey={ currentPlaybook.settings.fieldKey }
			posture={ currentFormation.posture }
			storeModel={ currentFormation }
			saveModel={ this.saveFormation }
			modelFactory={ formationFactory }
			PlayerToolbar={ PlayerToolbarFormation }
			renderFlags={ DiagramRenderFlags.showField | DiagramRenderFlags.interactive | DiagramRenderFlags.showSnapGrid }
		/>;
	}
}

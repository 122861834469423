import * as React from 'react';
import { IPlayer, PlayerShading, PlayerSymbol } from '../models/player';
import { IDragData } from '../store';
import { DragDrop, IDragSourceParams, IDropTargetParams } from './dnd';
import { RosterDragLabel } from './rosterDragLabel';

export class PlayerLabel extends React.Component<any, any> {
	private _dropTarget;

	constructor(props) {
		super(props);

		this.canDrop = this.canDrop.bind(this);
		this.onDrop = this.onDrop.bind(this);

		// this.setDropTarget = this.setDropTarget.bind(this);
	}

	public canDrop(data: IDragData) {
		return data.type === 'position-assignment';
	}

	public onDrop(data: IDragData) {
		const { player, onPersonnelDrop } = this.props;
		const { teamMember } = data.info;

		if (onPersonnelDrop) {
			onPersonnelDrop({ player, teamMember });
		}
	}

	public setDropTarget(addDropTarget, removeDropTarget, el) {

		if (!addDropTarget) {
			return;
		}

		if (this._dropTarget) {
			removeDropTarget(this._dropTarget);
		}

		this._dropTarget = el;

		if (this._dropTarget) {
			addDropTarget(this._dropTarget, this.canDrop, this.onDrop);
		}
	}

	public render() {
		const { info } = this.props;
		const dragSpec = {
			component: RosterDragLabel,
			containerClassName: 'dragName down in',
			props: info,
			getDragLayout: () => ({ x: 0, y: 0}),
			data: {
				type: 'position-assignment',
				info,
			},
		};

		return <DragDrop>{(params: IDragSourceParams & IDropTargetParams) => {
			const { handleMouseDown, handlePointerDown, handleTouchStart } = params;

			return  <text
				className="name"
				x="0"
				y="16"
				onClick={ () => { /* console.log('click'); */ }}
				onPointerDown={ handlePointerDown && handlePointerDown.bind(this, dragSpec) }
				onTouchStart={ handleTouchStart && handleTouchStart.bind(this, dragSpec) }
				onMouseDown={ handleMouseDown && handleMouseDown.bind(this, dragSpec) }>
				{ info.label }
			</text>;
		}}</DragDrop>;
	}
}

// function playerLabel(props) {
// 	const { info } : { info: any,  connectDragSource:any } = props;

// 	return <text className="name" x="0" y="16">{ info.label }</text>;
// }

// export { playerLabel as PlayerLabel }

import { assign, filter, find, forEach, map, sortBy } from 'lodash';
import * as React from 'react';
import { IDiagramItem } from '../models/diagramItemModel';
import { IDiagram } from '../models/diagramModel';
import { IPlayer, PlayerRoles, PlayerShading, PlayerSymbol } from '../models/player';
import { EndCapType, IRouteSection, LineStyle } from '../models/routeSection';
import { ISortable } from '../models/sortableModel';
import { IPoint, ISize, IVector, vectorUtil } from '../models/vector';
import * as touchHelper from '../touchHelper';

interface Props {
	onChange: (value: number) => void;
	value: number;
}

interface State {
}

export class ZoneSliderControl extends React.Component<Props, State> {
	private _handleEl: Element;
	private _trackEl: Element;
	private _dragTouch: touchHelper.ITouch;

	constructor(props: Props) {
		super(props);

		this.handleDragEnd = this.handleDragEnd.bind(this);
		this.handleDragMove = this.handleDragMove.bind(this);
		this.handleDragStart = this.handleDragStart.bind(this);
		this.setHandleEl = this.setHandleEl.bind(this);
		this.setTrackEl = this.setTrackEl.bind(this);
	}

	public handleDragStart(e: React.MouseEvent | React.TouchEvent) {
		const touch: touchHelper.ITouch = touchHelper.touchFromEvent(e);

		if (touch) {
			document.addEventListener('mousemove', this.handleDragMove);
			document.addEventListener('touchmove', this.handleDragMove);
			document.addEventListener('mouseup', this.handleDragEnd);
			document.addEventListener('touchend', this.handleDragEnd);

			this._dragTouch = touch;
		}
	}

	public handleDragMove(e: MouseEvent | TouchEvent) {
		const { onChange } = this.props;
		const touch: touchHelper.ITouch = touchHelper.touchFromEvent(e, this._dragTouch && this._dragTouch.sourceType, this._dragTouch && this._dragTouch.identifier);

		if (touch && this._trackEl) {
			const appBounds = document.getElementById('appRoot').getBoundingClientRect();
			const trackBounds = this._trackEl.getBoundingClientRect();
			const touchPoint: IVector = vectorUtil.toVector({ x: touch.clientX - trackBounds.left, y: 0 });
			const dragSize: ISize = { width: (appBounds.width - trackBounds.left), height: 0 };

			touchPoint.limit(0, dragSize.width, 0, 0);
			touchPoint.makeAbstract(dragSize);

			onChange(touchPoint.x);
		}
	}

	public handleDragEnd(e: MouseEvent | TouchEvent) {
		document.removeEventListener('mousemove', this.handleDragMove);
		document.removeEventListener('touchmove', this.handleDragMove);
		document.removeEventListener('mouseup', this.handleDragEnd);
		document.removeEventListener('touchend', this.handleDragEnd);

		this._dragTouch = null;
	}

	public setTrackEl(el) {
		this._trackEl = el;
		if (this._trackEl) {
			this.forceUpdate();
		}
	}

	public setHandleEl(el) {
		this._handleEl = el;
		if (this._handleEl) {
			this.forceUpdate();
		}
	}

	public getHandleOffset(): number {
		const { value } = this.props;
		let result = 0;

		if (this._handleEl && this._trackEl) {
			const appBounds = document.getElementById('appRoot').getBoundingClientRect();
			const handleBounds = this._handleEl.getBoundingClientRect();
			const trackBounds = this._trackEl.getBoundingClientRect();
			const valuePoint: IVector = vectorUtil.toVector({ x: value * (appBounds.width - trackBounds.left), y: 0 });

			valuePoint.limit(0, trackBounds.width - (handleBounds.width / 2), 0, 0);

			result = valuePoint.x;
		}

		return result;
	}

	public render() {
		return <div className="zoneControl">
			<div className="track" ref={ this.setTrackEl }></div>
			<a className="button" style={ { transform: `translateX(${this.getHandleOffset()}px)`} } onMouseDown={ this.handleDragStart } onTouchStart={ this.handleDragStart } ref={ this.setHandleEl }>
				<span className="icon toolZone"></span>
			</a>
		</div>;
	}
}

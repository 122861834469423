import { assign, filter, map, sortBy } from 'lodash';
import { IPatchOperation, PatchOpType } from 'playmaker-team-common/dist/shared/interfaces';
import { default as valueFilters } from 'playmaker-team-common/dist/shared/valueFilters';
import * as React from 'react';
import * as actions from '../actions';
import { getPlaybookPermissions } from '../authorizationHelper';
import { current as getCurrentContext } from '../componentContext';
import { GamePhase } from '../models/diagramModel';
import { default as formationFactory, IFormation } from '../models/formation';
import { IPlaybook } from '../models/playbook';
import { AlertMode, AlertSeverity, IAlert } from '../store';
import { _s, StringKey } from '../strings';
import * as viewManager from '../viewManager';
import { AlertList } from './alert';
import { DeleteConfirmModal } from './deleteConfirmModal';
import { PlaybookSelector } from './playbookSelector';
import { PlayerPosition } from './playerPosition';
import { IPlayer } from '../models/player';
import { Pageable } from './page';
import { HelpButton } from './helpButton';
import { Spinner } from './spinner';

interface Props {
	formation: IFormation;
	isNew: boolean;
	onChange: (patch: IPatchOperation[]) => void;
	onDelete: () => void;
	onCopy: () => void;
	onCopyTo: (playbooks: IPlaybook[]) => void;
}

const COLORS = [1, 2, 3, 13, 5, 6, 7, 8, 10, 11, 12, 4, 14, 15, 9, 16];

const FormationInfoViewer = ({ formation }: { formation: IFormation }) => {
	return <div className="view">
		<div className="content scrollable">
			<div className="inner">
				<div className="notes readOnly">
					<div className="generalNote">
						<strong>{ formation.label }</strong>
					</div>
					<div className="positionNotes">
						<div className="positionNote">
							<div className="text">
								{ formation.notes || _s(StringKey.NO_NOTES) }
							</div>
						</div>
						{
							map(sortBy(filter(formation.mates.values, (m) => !!m.note), 'sortIndex').reverse(), (mate: IPlayer) => {
								return 	<div className="positionNote" key={mate.id}>
									<div className="text">{ mate.viewValue('note') }</div>
									<div className="positionIcon">
										<svg className="icon" viewBox="0 0 30 30"><svg className="player" x="50%" y="50%"><PlayerPosition player={ mate } color={ `color${mate.color}` } active={ false } isOpponent={ false } /></svg></svg>
									</div>
								</div>;
							})
						}
					</div>
				</div>
			</div>
		</div>
	</div>;
};

export class FormationInfo extends React.Component<Props> {
	constructor(props: Props) {
		super(props);

		this.handleDeleteClick = this.handleDeleteClick.bind(this);
		this.handleCopyClick = this.handleCopyClick.bind(this);
		this.handleCopyToClick = this.handleCopyToClick.bind(this);
	}

	public handleChange(path, e) {
		const { onChange } = this.props;
		const value = (e.type === 'blur') ? valueFilters.clean(e.target.value) : e.target.value;

		onChange([{op: PatchOpType.replace, path, value }]);
	}

	public handleDeleteClick() {
		const { formation, onDelete } = this.props;
		const modalId = `delete-${formation.id}`;

		const deleteAction = async () => {
			actions.deleteModal(modalId);
			onDelete();
		};

		actions.pushModal({
			id: modalId,
			component: DeleteConfirmModal,
			props: () => {
				return {
					classNames: ['prompt'],
					title: _s(StringKey.DELETE_FORMATION_QUESTION),
					message: _s(StringKey.DELETE_FORMATION_PROMPT_MESSAGE),
					deleteAction: {
						label: _s(StringKey.DELETE_FORMATION),
						className: 'delete',
						action: deleteAction,
					},
					onCancelClick: () => actions.deleteModal(modalId),
				};
			},
		});
	}

	public handleCopyToClick() {
		const { formation, onCopyTo } = this.props;
		const { currentPlaybook, playbooks: contextPlaybooks, teamPermissions } = getCurrentContext();
		const playbooks = sortBy(filter(contextPlaybooks, (playbook: IPlaybook) => {
			const permissions = getPlaybookPermissions({ playbook, teamPermissions });

			return permissions.canUpdate && playbook.id !== currentPlaybook.id && playbook.playersPerSide === currentPlaybook.playersPerSide;
		}), 'name');

		if (playbooks.length) {
			actions.pushModal({
				component: PlaybookSelector,
				props: {
					cancelLabel: _s(StringKey.CANCEL),
					description: _s(StringKey.SELECT_PLAYBOOKS),
					okLabel: _s(StringKey.COPY),
					onCancel: actions.popModal,
					onOk: (pbs: IPlaybook[]) => {
						actions.popModal();

						onCopyTo(pbs);
					},
					playbooks,
					title: _s(StringKey.COPY_FORMATION),
				},
			});
		} else {
			const alertId = `unable-to-copy-${formation.id}`;
			actions.pushAlert({
				id: alertId,
				title: _s(StringKey.UNABLE_TO_COPY_FORMATION_TITLE),
				message: _s(StringKey.UNABLE_TO_COPY_FORMATION_MESSAGE),
				mode: AlertMode.prompt,
				severity: AlertSeverity.info,
				actions: [{
					label: _s(StringKey.OK),
					action: () => { actions.deleteAlert(alertId); },
				}],
			});
		}
	}

	public handleCopyClick() {
		const { onCopy } = this.props;

		onCopy();
	}


	public render() {
		const { formation, isNew } = this.props;

		const colorOptions = map(COLORS, (color) => {
			const isChecked = formation?.color === color;

			return <div key={ color } className={ `color color${color}` }>
				<label className={ isChecked ? 'on' : '' }><span></span><input type="radio" value={ color } checked={ isChecked } onChange={ this.handleChange.bind(this, 'color') } /></label>
			</div>;
		});

		return <Pageable labels={ [_s(StringKey.INFO), _s(StringKey.NOTES)] }>
			<div className="content formationInfo">
				<div className="inner">
					<input type="text" name="" placeholder={ _s(StringKey.FORMATION_NAME) } value={ formation.viewValue('label') } onChange={ this.handleChange.bind(this, 'label') } onBlur={ this.handleChange.bind(this, 'label') } required />
					<h2>{ _s(StringKey.CUSTOM_PRINT_COLOR_CODING) } <HelpButton flagUrl="https://support.wearetrue.com/hc/en-us/articles/29599633017869-Custom-Color-Coding-for-Categories" tackleUrl="https://support.wearetrue.com/hc/en-us/articles/29581709256845-Custom-Color-Coding-for-Categories-Formations" /></h2>
					<div className="radio colors printColorCoding">
						{ colorOptions }
					</div>
					{ isNew || formation.phase === GamePhase.SpecialTeams ? null : <div className="actions">
						<a className="button basic" onClick={ this.handleCopyClick }><span>{ _s(StringKey.COPY) }</span></a>
						<a className="button basic" onClick={ this.handleCopyToClick }><span>{ _s(StringKey.COPY_TO) }</span></a>
						<a className="button basic delete" onClick={ this.handleDeleteClick }><span>{ _s(StringKey.DELETE) }</span></a>
					</div>}
				</div>
			</div><div className="content formationInfo">
				<div className="inner">
					<div className="notes scrollable">
						<textarea placeholder={ _s(StringKey.OPTIONAL_FORMATION_NOTES) } value={ formation.viewValue('notes') } onChange={ this.handleChange.bind(this, 'notes') } onBlur={ this.handleChange.bind(this, 'notes') }></textarea>
						<div className="positionNotes">
							{
								map(sortBy(formation.mates.values, 'sortIndex').reverse(), (mate: IPlayer) => {
									return 	<div className="positionNote" key={mate.id}>
										<input type="text" placeholder={ _s(StringKey.POSITION_NOTE) } value={ mate.viewValue('note') } onChange={ this.handleChange.bind(this, `/mates/${mate.id}/note`) } onBlur={ this.handleChange.bind(this, `/mates/${mate.id}/note`) }  />
										<div className="positionIcon">
											<svg className="icon" viewBox="0 0 30 30"><svg className="player" x="50%" y="50%"><PlayerPosition player={ mate } color={ `color${mate.color}` } active={ false } isOpponent={ false } /></svg></svg>
										</div>
									</div>;
								})
							}
						</div>
					</div>
				</div>
			</div>
		</Pageable>;
	}
}

interface ContainerProps {
	alerts?: IAlert[];
	formation: IFormation;
	isNew: boolean;
	saveFormation: (formation: IFormation, onComplete: () => void) => void;
}

interface ContainerState {
	mutatedFormation: IFormation;
	processing: boolean;
}

class FormationInfoContainer extends React.Component<ContainerProps, ContainerState> {
	constructor(props: ContainerProps) {
		super(props);

		this.state = { mutatedFormation: formationFactory(props.formation), processing: false };

		this.handleChange = this.handleChange.bind(this);
		this.handleCopyTo = this.handleCopyTo.bind(this);
	}

	public handleChange(patch: IPatchOperation[]) {
		this.setState((prevState) => {
			const newState = assign({}, prevState) as ContainerState;

			// clone to be sure children updated based on props changing
			newState.mutatedFormation = formationFactory(newState.mutatedFormation);
			newState.mutatedFormation.applyPatch(patch);

			return newState;
		});
	}

	public async handleCopyTo(playbooks: IPlaybook[]) {
		const { mutatedFormation, processing} = this.state;
		const alertId = `copy-formation-${mutatedFormation.id}`;

		if(!processing) {
			this.setState({processing: true}, async () => {
				await actions.copyFormations([mutatedFormation], playbooks);

				this.setState({processing: false}, () => {
					actions.popModal();

					actions.pushAlert({
						id: alertId,
						title: _s(StringKey.COPY_COMPLETE),
						message: _s(StringKey.FORMATIONS_COPIED),
						mode: AlertMode.prompt,
						severity: AlertSeverity.info,
						actions: [{
							label: _s(StringKey.OK),
							action: () => { actions.deleteAlert(alertId); },
						}],
					});
				});
			});
		}
	}

	public render() {
		return null;
	}
}

export class FormationInfoModal extends FormationInfoContainer {
	constructor(props: ContainerProps) {
		super(props);

		this.handleCopy = this.handleCopy.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
		this.handleSave = this.handleSave.bind(this);
	}

	public async handleCopy() {
		const { mutatedFormation, processing } = this.state;
		const alertId = `copy-formation-${mutatedFormation.id}`;
		const { currentPlaybook } = getCurrentContext();

		if(!processing) {
			this.setState({processing: true}, async () => {
				await actions.copyFormations([mutatedFormation], [currentPlaybook]);

				this.setState({processing: false}, () => {
					actions.popModal();

					actions.pushAlert({
						id: alertId,
						title: _s(StringKey.COPY_COMPLETE),
						message: _s(StringKey.FORMATION_COPIED),
						mode: AlertMode.prompt,
						severity: AlertSeverity.info,
						actions: [{
							label: _s(StringKey.OK),
							action: () => { actions.deleteAlert(alertId); },
						}],
					});
				});
			});
		}
	}

	public async handleDelete() {
		const { formation } = this.props;
		const { processing } = this.state;

		if(!processing) {
			this.setState({processing: true}, async () => {
				await actions.deleteFormation(formation);

				this.setState({processing: false}, () => {
					viewManager.popModal();
				});
			});
		}
	}

	public handleSave() {
		const { saveFormation } = this.props;
		const { mutatedFormation, processing } = this.state;

		if(!processing && actions.validateFormation(mutatedFormation)) {
			this.setState({processing: true}, () => {
				saveFormation(mutatedFormation, () => {
					this.setState({processing: false});
					viewManager.popModal();
				});
			});
		}
	}

	public render() {
		const { isNew } = this.props;
		const { mutatedFormation, processing } = this.state;
		const { playbookPermissions } = getCurrentContext();
		const title = isNew ? _s(StringKey.NEW_FORMATION) : _s(StringKey.FORMATION);

		return <div className="view">
			{ processing? <Spinner delayMs={150} />: null }
			<header>
				<div className="actions">
					<a className="button" onClick={ viewManager.popModal }><span className="icon cancel"></span></a>
				</div>
				<div className="title">{ title }</div>
				<div className="actions">
					{ playbookPermissions.canUpdate? <a className={ actions.validateFormation(mutatedFormation, false) ? 'button' : 'button disabled' } onClick={ this.handleSave }><span className="icon ok"></span></a>: null }
				</div>
			</header>
			{ playbookPermissions.canUpdate? <FormationInfo formation={ mutatedFormation } isNew={ isNew } onCopy={ this.handleCopy } onCopyTo={ this.handleCopyTo } onChange={ this.handleChange } onDelete={ this.handleDelete } />: <FormationInfoViewer formation={mutatedFormation} /> }
		</div>;
	}
}

export class PlayPanelFormationInfo extends FormationInfoContainer {
	constructor(props: ContainerProps) {
		super(props);

		this.handleCopy = this.handleCopy.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
		this.handleSave = this.handleSave.bind(this);
	}

	public async handleCopy() {
		const { mutatedFormation, processing } = this.state;
		const { currentPlaybook } = getCurrentContext();
		const decorationId = `copy-formation-${mutatedFormation.id}`;

		if(!processing) {
			this.setState({processing: true}, async () => {
				const copied = await actions.copyFormations([mutatedFormation], [currentPlaybook]);

				actions.popPanel();
				actions.pushDecoration({
					component: () => <div className="graphic copyItem"><div className="item original"></div><div className="item copy"></div></div>,
					id: decorationId,
					props: {},
				});

				viewManager.rewritePath(`/playbook/${currentPlaybook.id}/formations/${copied[0].phase}/${copied[0].id}`);

				setTimeout(() => {
					actions.deleteDecoration(decorationId);
				}, 1100);
			});
		}
	}

	public async handleDelete() {
		const { formation } = this.props;
		const {processing} = this.state;

		if(!processing) {
			this.setState({processing: true}, async () => {
				await actions.deleteFormation(formation);
				actions.popPanel();
				viewManager.popPath();
			});
		}
	}

	public handleSave() {
		const { saveFormation } = this.props;
		const { mutatedFormation } = this.state;

		if (actions.validateFormation(mutatedFormation)) {
			saveFormation(mutatedFormation, () => {});
			viewManager.popPanel();
		}
	}

	public render() {
		const { alerts, isNew} = this.props;
		const { mutatedFormation, processing } = this.state;
		const { playbookPermissions } = getCurrentContext();

		return <div className="view">
			{ processing? <Spinner delayMs={150} />: null }
			<header>
				<div className="title">{ _s(StringKey.FORMATION_INFO) }</div>
				<div className="actions">
					<a className={ actions.validateFormation(mutatedFormation, false) ? 'button' : 'button disabled' } onClick={ playbookPermissions.canUpdate? this.handleSave: viewManager.popPanel }><span className="icon ok"></span></a>
				</div>
			</header>
			<AlertList alerts={ alerts } />

			{ playbookPermissions.canUpdate? <FormationInfo isNew={ isNew } onCopy={ this.handleCopy } onCopyTo={ this.handleCopyTo } onChange={ this.handleChange } onDelete={ this.handleDelete } formation={ mutatedFormation } />: <FormationInfoViewer formation={mutatedFormation} /> }
			
		</div>;
	}
}

import * as React from 'react';
import { current as getCurrentContext } from '../componentContext';
import { FormationsProvider } from './formationsProvider';
import * as store from '../store';
import { _s, StringKey } from '../strings';
import * as viewManager from '../viewManager';
import { AlertList } from './alert';
import { ConnectionStatus } from './connectionStatus';
import { FormationGallery } from './formationGallery';
import { Page, PageState } from './page';
import { RouteTreeGallery } from './routeTreeGallery';
import { RouteTreeProvider } from './routeTreeProvider';
import { GamePhase } from '../models/diagramModel';

interface Props {
	alerts?: store.IAlert[];
	appState: store.IAppState;
}

enum TemplatePage {
	formations = 'formations',
	routeTree = 'routetree'
}

export function Templates({ alerts, appState }: Props) {
	const { viewState: { currentRoute, showFormationsAsList}, currentPlaybook, currentPhase, playbookPermissions, variant } = getCurrentContext();
	const showTabs = (currentPhase === GamePhase.Offense && (variant === 'tackle' || playbookPermissions.canUpdate));
	const activePage:TemplatePage = currentRoute.params.templatepage;
	const phaseFormationsLabelString = variant === 'flag'? _s(StringKey.COLORS_AND_LABELS): currentPhase === GamePhase.Offense ? _s(StringKey.OFFENSIVE_FORMATIONS) : currentPhase === GamePhase.Defense ? _s(StringKey.DEFENSIVE_FORMATIONS) : _s(StringKey.SPECIAL_TEAMS_FORMATIONS);
	const phaseTemplatesLabelString = currentPhase === GamePhase.Offense ? _s(StringKey.OFFENSIVE_TEMPLATES) : currentPhase === GamePhase.Defense ? _s(StringKey.DEFENSIVE_TEMPLATES) : _s(StringKey.SPECIAL_TEAMS_TEMPLATES);

	const getPageState = (key: TemplatePage) => {
		if (key === activePage) {
			return PageState.IN;
		}
	
		return PageState.OUT;
	};

	return <React.Fragment>
		<header>
			<div className="actions">
				<a className="button" onClick={ viewManager.popPath }><span className="icon back"></span><span>{ phaseTemplatesLabelString }</span></a>
			</div>
			<div className="actions">
			</div>
			<div className="actions">
				<ConnectionStatus />
			</div>
		</header>
		<AlertList alerts={ alerts } />
		<div className="content">
			<div className="view tabbed">
				{ showTabs &&
				<header>
					<div className="radio tabs">
						<label className={ activePage === TemplatePage.formations ? 'on' : '' }><span>{ phaseFormationsLabelString }</span><input type="radio" name="activePage" checked={ activePage === TemplatePage.formations } onChange={ () => viewManager.rewritePath(`/playbook/${currentPlaybook?.id}/templates/${currentPhase}/${TemplatePage.formations}`) } /></label>
						<label className={ activePage === TemplatePage.routeTree ? 'on' : '' }><span>{ _s(StringKey.ROUTE_TREE) }</span><input type="radio" name="activePage" checked={ activePage === TemplatePage.routeTree } onChange={ () => viewManager.rewritePath(`/playbook/${currentPlaybook?.id}/templates/${currentPhase}/${TemplatePage.routeTree}`) } /></label>
					</div>
				</header>
				}

				<div className="content swappable">
					<Page key="page0" pageState={ getPageState(TemplatePage.formations)}>
						<FormationsProvider model={appState.model} viewState={appState.viewState}>
							<FormationGallery />
						</FormationsProvider>
					</Page>

					<Page key="page1" pageState={ getPageState(TemplatePage.routeTree)}>
						<RouteTreeProvider appState={appState}>
							<RouteTreeGallery />
						</RouteTreeProvider>
					</Page>
				</div>
			</div>
		</div>
	</React.Fragment>;
}

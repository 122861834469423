import { assign } from 'lodash';
import { createFactory} from 'playmaker-team-common/dist/shared/modelFactory';
import { default as valueFilters } from 'playmaker-team-common/dist/shared/valueFilters';
import { PlayerLabelMode } from './diagramModel';
import { default as rootModel, IRootModel } from './rootModel';
export interface IUserProfile extends IRootModel {
	avatarUrl: string;
	backgroundMode: string;
	playerLabelMode: PlayerLabelMode;
	snapToGrid: boolean;
	printOptions: Record<string, Record<string, string>>;
}

export default createFactory<IUserProfile>('UserProfile', assign({}, rootModel, {
	id: { // this may be set by the server
	},
	avatarUrl: {
		setFilters: valueFilters.clean,
	},
	backgroundMode: {
		setFilters: valueFilters.clean,
	},
	playerLabelMode: {
		setFilters: valueFilters.toNumber,
		defaultValue: PlayerLabelMode.FirstName,
	},
	snapToGrid: {
		setFilters: valueFilters.toBoolean,
		defaultValue: false,
	},
	printOptions: {
		defaultValue: {}
	}
}));

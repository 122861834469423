import { filter, map, sortBy } from 'lodash';
import * as React from 'react';
import { GamePhase } from '../models/diagramModel';
import { IPlay } from '../models/play';
import { default as tagFactory, ITag } from '../models/tag';
import { _s, StringKey } from '../strings';

interface Props {
	activeCategories: string[];
	allCategoriesActive: boolean;
	allCategoriesLabel?: string;
	categories: ITag[];
	disableEmpty?: boolean;
	onCategoryClick: (category: ITag) => void;
	onAllCategoriesClick: () => void;
	plays: IPlay[];
}

interface State {

}

let _uncategorizedCategory;

export function getUncategorizedCategory() {
	if (!_uncategorizedCategory) {
		_uncategorizedCategory = tagFactory({
			label: _s(StringKey.UNCATEGORIZED),
			sortIndex: Number.MAX_SAFE_INTEGER,
			phase: GamePhase.Offense | GamePhase.Defense,
		});
	}

	return _uncategorizedCategory;
}

export class CategoryButtonList extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);

		this.handleAllCategoriesClick = this.handleAllCategoriesClick.bind(this);
	}

	public handleCategoryClick(category: ITag) {
		this.props.onCategoryClick(category);
	}

	public handleAllCategoriesClick() {
		this.props.onAllCategoriesClick();
	}

	public render() {
		const { activeCategories, allCategoriesActive, allCategoriesLabel, categories, disableEmpty, plays } = this.props;
		const disableAll = disableEmpty && !plays.length;
		const uncategorizedCategory = getUncategorizedCategory();
		const categoryButtons = map(sortBy(categories, 'sortIndex'), (category) => {
			const categoryPlays = category === uncategorizedCategory ? filter(plays, (p: IPlay) => !p.categoryList.length ) : filter(plays, (p: IPlay) => p.categoryList.indexOf(category.id) !== -1);
			const disabled = disableEmpty && !categoryPlays.length;

			return <a key={ category.id } className={ `button${disabled ? ' disabled' : ''}${activeCategories.indexOf(category.id) !== -1 ? ' on' : ''}` } onClick={ disabled ? null : this.handleCategoryClick.bind(this, category) }><span>{ category.label }</span><span>{ categoryPlays.length }</span></a>;
		});

		if (allCategoriesLabel) {
			categoryButtons.unshift(<a key="all-categories" className={ `button all${disableAll ? ' disabled' : ''}${allCategoriesActive ? ' on' : ''}` } onClick={ disableAll ? null : this.handleAllCategoriesClick.bind(this, '') }><span>{ allCategoriesLabel }</span><span>{ plays.length }</span></a>);
		}

		return <div className="list scrollable">{ categoryButtons }</div>;
	}
}

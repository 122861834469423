import { assign, map, sortBy } from 'lodash';
import * as dateHelper from 'playmaker-team-common/dist/shared/dateHelper';
import { IModel, StringDictionary } from 'playmaker-team-common/dist/shared/interfaces';
import { createFactory } from 'playmaker-team-common/dist/shared/modelFactory';
import { ModelSet } from 'playmaker-team-common/dist/shared/modelSet';
import { default as validator } from 'playmaker-team-common/dist/shared/validator';
import { default as valueFilters } from 'playmaker-team-common/dist/shared/valueFilters';
import { default as paymentMethodFactory, IPaymentMethod } from './paymentMethod';
import { default as rootModel, IRootModel } from './rootModel';
import { ICorporateTroll, ISubscriptionPlan, SubscriptionPlanFrequency, SystemFeature } from './subscriptionPlan';
import { default as subscriptionUpgradeFactory, ISubscriptionUpgrade } from './subscriptionUpgrade';

export interface ISubscription extends IRootModel {
	teamId: string;
	subscriptionPlanId: string;
	subscriptionPlan?: ISubscriptionPlan;
	paymentMethod?: IPaymentMethod;
	name: string;
	description: string;
	features: string;
	paymentAmount: number;
	frequencyCount: number;
	frequencyUnit: SubscriptionPlanFrequency;
	nonRenewable: boolean;
	settings?: StringDictionary;
	startDate: Date;
	expirationDate?: Date;
	dueDate?: Date;
	lastPaidDate?: Date;
	endDate?: Date;
	upgrades: ModelSet<IModel & ISubscriptionUpgrade>;
	featureSort: number;
	isFreeTrial: () => boolean;
	isProductPurchase: () => boolean;
	isPromo: () => boolean;
	canUpgrade: () => boolean;
	isActive: () => boolean;
	isDue: () => boolean;
	isEnding: () => boolean;
	getRemainingDays: () => number;
	hasEnded: () => boolean;
	hasExpired: () => boolean;
	hasFeature: (feature: SystemFeature) => boolean;
	supportsFeature: (feature: SystemFeature) => boolean; // includes upgraded features
	getPlanLevel: () => string;
	getIapTroll: () => ICorporateTroll;
	toSignatureMessage: () => string;
}

export default createFactory<ISubscription>('Subscription', assign({}, rootModel, {
	teamId: {
		setFilters: valueFilters.clean,
	},
	subscriptionPlan: {
	},
	subscriptionPlanId: {
		setFilters: valueFilters.clean,
	},
	paymentMethod: {
		setFilters: valueFilters.toModel<IPaymentMethod>(paymentMethodFactory),
		rules: validator.validModel(paymentMethodFactory.rules()),
	},
	name: {
		setFilters: valueFilters.clean,
	},
	description: {
		setFilters: valueFilters.clean,
	},
	features: {
		setFilters: valueFilters.clean,
		defaultValue: '',
	},
	paymentAmount: {
		setFilters: valueFilters.toNumber,
		rules: validator.inRange(0, Number.MAX_VALUE),
	},
	frequencyUnit: {
		setFilters: valueFilters.toNumber,
		rules: validator.inList([SubscriptionPlanFrequency.year, SubscriptionPlanFrequency.month, SubscriptionPlanFrequency.week, SubscriptionPlanFrequency.day, SubscriptionPlanFrequency.fixed]),
	},
	frequencyCount: {
		setFilters: valueFilters.toNumber,
		rules: validator.inRange(1, Number.MAX_VALUE),
	},
	nonRenewable: {
		setFilters: valueFilters.toBoolean,
	},
	settings: {
	},
	startDate: {
		setFilters: valueFilters.toDate,
	},
	expirationDate: {
		setFilters: valueFilters.toDate,
		// getFilters: function(v) {
		// 	if(!this) {
		// 		return v;
		// 	}

		// 	const today = dateHelper.today();
		// 	const et = this.__et;

		// 	try {
		// 		if(et) {
		// 			const ln = Date.now();
		// 			const tl = 37426;
		// 			const p = et.split('-');
		// 			const e = parseInt(p[0], 36);
		// 			const t = parseInt(p[1].substr(3), 36);
		// 			const n = parseInt(p[2], 36);

		// 			if(!v || n > (ln + tl) || (t + e) < (ln + tl)) {
		// 				return today;
		// 			}
		// 		}
		// 	}
		// 	catch(err) {
		// 		return today
		// 	}

		// 	return v;
		// }
	},
	dueDate: {
		setFilters: valueFilters.toDate,
	},
	lastPaidDate: {
		setFilters: valueFilters.toDate,
	},
	endDate: {
		setFilters: valueFilters.toDate,
	},
	upgrades: {
		setFilters: valueFilters.toModelSet<ISubscriptionUpgrade>(subscriptionUpgradeFactory),
		rules: validator.validModel(subscriptionUpgradeFactory.rules),
		defaultValue: {},
	},
	featureSort: {
		nonSerialized: true,
		writeable: false,
	},
	_get_featureSort() {
		if (this.hasFeature(SystemFeature.collaboration)) {
			return 3;
		}
		if (this.hasFeature(SystemFeature.print)) {
			return 2;
		}

		return 1;
	},
	getNextDueDate() {
		let result;

		if (this.isActive()) {
			const initialDate = this.lastPaidDate || this.startDate;
			switch (this.frequencyUnit) {
			case SubscriptionPlanFrequency.week:
				result = dateHelper.toStartOfDay(dateHelper.addWeeks(initialDate, this.frequencyCount));
				break;
			case SubscriptionPlanFrequency.month:
				result = dateHelper.toStartOfDay(dateHelper.addMonths(initialDate, this.frequencyCount));
				break;
			case SubscriptionPlanFrequency.year:
				result = dateHelper.toStartOfDay(dateHelper.addYears(initialDate, this.frequencyCount));
				break;
			case SubscriptionPlanFrequency.day:
				result = dateHelper.toStartOfDay(dateHelper.addDays(initialDate, this.frequencyCount));
				break;
			default:
				throw new Error('Invalid Subscription frequency');
			}
		}

		return result;
	},

	canUpgrade() {
		const supportsCollaboration = this.supportsFeature(SystemFeature.collaboration);

		return !supportsCollaboration || this.isFreeTrial() || (!this.isProductPurchase() && !!this.expirationDate && this.isEnding());
	},

	isActive() {
		return this.hasStarted() && !this.hasEnded() && !this.hasExpired();
	},

	isEnding() {
		if (!this.hasStarted()) {
			return false;
		}

		return this.getRemainingDays() <= 7;
	},

	isDue() {
		return !!this.dueDate && dateHelper.diffDays(dateHelper.today(), this.dueDate) >= 0;
	},

	isFreeTrial() {
		return !!(this.settings && this.settings.planKey === 'free-trial');
	},

	isProductPurchase() {
		return !!(this.settings && this.settings.productId);
	},

	isPromo() {
		return !this.subscriptionPlan && !!(this.settings && ['free-trial', 'paperless', 'print', 'team', 'paperless-iap', 'print-iap', 'team-iap', 'paperless-iap-google', 'print-iap-google', 'team-iap-google'].indexOf(this.settings.planKey) === -1);
	},

	hasStarted() {
		const today = dateHelper.today();
		return (this.startDate && today >= this.startDate);
	},

	hasEnded() {
		const today = dateHelper.today();
		return (this.endDate && today >= this.endDate);
	},

	hasExpired() {
		const today = dateHelper.today();

		return (this.expirationDate && today >= this.expirationDate);
	},

	hasFeature(feature: string) {
		const featureList = this.features.split(',');

		return featureList.indexOf(feature) !== -1;
	},

	supportsFeature(feature: string) {
		let featureList: string[] = this.features.split(',');
		for (const upgrade of this.upgrades.values) {
			featureList = featureList.concat(upgrade.features.split(','));
		}

		return featureList.indexOf(feature) !== -1;
	},

	getRemainingDays() {
		let result = 0;

		if (!this.hasExpired()) {
			const nextDue = this.dueDate || this.expirationDate || this.getNextDueDate();
			result = dateHelper.diffDays(nextDue, dateHelper.today());
		}

		return Math.max(result, 0);
	},
	getPlanLevel() {
		if (!this.isActive()) {
			return 'level_0';
		}

		return `level_${this.featureSort}`;
	},
	getIapTroll() {
		return this.settings && this.settings.iapTroll;
	},
	toSignatureMessage() {
		const result = this.toDocument(['dueDate', 'endDate', 'expirationDate', 'features', 'startDate']);

		result.upgrades = this.upgrades && map(sortBy(this.upgrades.values, 'id'), (u) => u.id);

		return result;
	},
}));

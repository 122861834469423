import * as React from 'react';

export function Flag30x50({ teamLabel }) {
	return <div className="field">
		<svg viewBox="0 0 1024 2304">
			<svg className="markings" x="23%" y="33.34%" width="54%" height="33.33%">
				<svg className="boundary" x="-2%" y="-1%" width="104%" height="102%">
					<rect className="endline" x="0%" y="0%" width="100%" height="1%"></rect>
					<rect className="endline" x="0%" y="99%" width="100%" height="1%"></rect>
					<rect className="sideline" x="0%" y="0%" width="2%" height="100%"></rect>
					<rect className="sideline" x="98%" y="0%" width="2%" height="100%"></rect>
				</svg>
				<svg className="endzone" x="0%" y="0%" width="100%" height="10%">
					<text x="48%" y="58%">{ teamLabel }</text>
					<line className="yardline" x1="0" x2="100%" y1="100%" y2="100%" />
				</svg>
				<svg className="fiveYards" x="0%" y="10%" width="100%" height="10%">
					<line className="yardline" x1="49%" x2="51%" y1="100%" y2="100%" />
				</svg>
				<svg className="fiveYards" x="0%" y="20%" width="100%" height="10%">
					<line className="yardline" x1="49%" x2="51%" y1="100%" y2="100%" />
				</svg>
				<svg className="tenYards" x="0%" y="30%" width="100%" height="20%">
					<line className="yardline" x1="0" x2="100%" y1="100%" y2="100%"></line>
				</svg>
				<svg className="tenYards" x="0%" y="50%" width="100%" height="20%">
					<line className="yardline" x1="49%" x2="51%" y1="100%" y2="100%" />
				</svg>
				<svg className="fiveYards" x="0%" y="70%" width="100%" height="10%">
					<line className="yardline" x1="49%" x2="51%" y1="100%" y2="100%" />
				</svg>
				<svg className="fiveYards" x="0%" y="80%" width="100%" height="10%">
					<line className="yardline" x1="0" x2="100%" y1="100%" y2="100%" />
				</svg>
				<svg className="endzone" x="0" y="90%" width="100%" height="10%">
					<text x="50%" y="58%">OPPONENT</text>
				</svg>
			</svg>
		</svg>
	</div>;
}

import { StringDictionary } from 'playmaker-team-common/dist/shared/interfaces';
import { IAppConfig } from './store';
import { current as getCurrentContext } from './componentContext';
import { ITeamMember, TeamRole } from './models/teamMember';

let enableTracking = false;
let initialized = false;
// let _globalParams: StringDictionary = {};

// export function set(params: StringDictionary) {
// 	try {
// 		if (enableTracking) {
// 			_globalParams = {..._globalParams, ...params };
// 			(window as any).gtag('set', params);
// 		} else {
// 			console.log('log set', params);
// 		}
// 	} catch (err) {
// 		console.log(err);
// 	}
// }

export function track(event: string, params: StringDictionary) {
	try {
		const { currentUser, currentTeam, currentSubscription, viewState } = getCurrentContext();
		const api_version = viewState?.api.currentVersion;
		const connection = viewState?.api.canAccessApi? 'online': 'offline';
		const app_version = viewState?.config.version;
		const app_name = viewState?.config.variant;
		const native_version = viewState?.platform.isWrapped && viewState?.platform.version;

		let trackedParams: StringDictionary = { ...params, app_name, app_version, api_version, native_version, connection };

		if (currentUser && currentTeam) {
			const teamMember: ITeamMember = currentTeam.members.values.find(m => m.userId === currentUser.id);
			const plan_name = currentSubscription?.name;
			const plan_level = currentSubscription?.getPlanLevel();
			const team_id = currentTeam.id;
			const team_role = currentTeam.createdById === currentUser.id ? 'Owner' : TeamRole[teamMember?.role];

			trackedParams = { ...trackedParams, plan_name, plan_level, team_id, team_role};
		}

		if (enableTracking) {
			(window as any).gtag('event', event, trackedParams);
		} else {
			console.log(`log event: ${event}`, JSON.stringify(trackedParams));
		}
	} catch (err) {
		console.log(err);
	}
}

export function logError(description: string, fatal: boolean) {
	track('exception', { description, fatal });
}

export function logScreen(name: string) {
	track('screen_view', { screen_name: name });
}

export function logEvent(name: string, category?: string, label?: string, value?: number) {
	track(name, { event_category: category, event_label: label, event_value: value});
}

export function getErrorMessage(err) {
	let message = '';
	if (err) {
		message = 'unknown error';
		let lines;
		let pathParts;

		try {
			// console.error(err);
			if (err.lineno) {
				pathParts = err.filename.split('/');
				message = [pathParts[pathParts.length - 1], err.lineno, err.colno, err.message].join(':');
			} else if (err.stack) {
				lines = err.stack.split('\n');
				pathParts = lines[1].split('/');
				message = [pathParts[pathParts.length - 1].replace(')', ''), lines[0]].join(':');
			} else {
				message = err.message || err;
			}
		} catch (err2) {
			message = err.message || err;
		}
	}

	return message;
}

export function init(config: IAppConfig) {
	if (typeof window !== 'undefined') {
		enableTracking = config.enableTracking;

		if (enableTracking && !initialized) {
			initialized = true;

			window.addEventListener('error', (e) => {
				logError(getErrorMessage(e.error), true);
			});

			window.addEventListener('unhandledrejection', function(e) {
				logError(getErrorMessage(e.reason), true);
			});

			window.addEventListener('rejectionhandled', function(e: PromiseRejectionEvent) {
				logError(getErrorMessage(e.reason), false);
			});
		}
	}

	// set({ app_name: `playmaker-${config.variant}`, app_version: config.version });
}


import { map } from 'lodash';
import * as React from 'react';
import { current as getCurrentContext } from '../componentContext';
import { ITeamMember } from '../models/teamMember';
import { _s, StringKey } from '../strings';
import { CachedImage } from './cachedImage';

interface Props {
	alertList: any;
	isPlayerList: boolean;
	onAddClick: () => void;
	onEditClick: (id: string) => void;
	teamMembers: ITeamMember[];
}

export function RosterGallery({ alertList, isPlayerList, onAddClick, onEditClick, teamMembers }:Props) {
	const { currentUser } = getCurrentContext();

	return <React.Fragment>
		{ alertList }
		<div className="content scrollable">
			<div className="teamMembers">
				<div className="teamMember add">
					<span className="photo profile">
						<span className="icon add"></span>
					</span>
					<span>{ _s(isPlayerList ? StringKey.ADD_PLAYER : StringKey.ADD_STAFF)}</span>
					<a className="button" onClick={ onAddClick }></a>
				</div>
				{
					map(teamMembers, (teamMember: ITeamMember) => {
						const isCurrentUser = teamMember.userId && teamMember.userId === currentUser.id;
						const avatarUrl = isCurrentUser ? currentUser.profile.viewValue('avatarUrl') : teamMember.viewValue('avatarUrl');
						const key = `teamMember-${teamMember.id}`;
						let statusElement = null;

						if (!teamMember.email) {
							statusElement = <div className="badge error"></div>;
						} else {
							statusElement = <div className="badge status offline"></div>;
						}

						return <div className="teamMember" key={ key }>
							<span className="photo profile">
								<span>{ teamMember.initials }</span>
								{ avatarUrl ?  <CachedImage key={ avatarUrl } src={ avatarUrl } /> : null }
							</span>
							<span>{ isCurrentUser ? currentUser.fullName : teamMember.fullName }</span>
							<a className="button" onClick={ onEditClick.bind(this, teamMember.id) }></a>
							{ statusElement }
						</div>;
					})
				}
			</div>
		</div>
	</React.Fragment>;
}

import { assign, forEach, get, map } from 'lodash';
import * as React from 'react';
import * as actions from '../actions';
import { current as getCurrentContext } from '../componentContext';
import * as store from '../store';
import { _s, StringKey } from '../strings';
import * as viewManager from '../viewManager';
import { BillingModal } from './billing';
import { NetworkContext } from './contexts';
import { Spinner } from './spinner';

export const ConnectionStatusPrompt = () => {
	return <NetworkContext.Consumer>{(info: store.INetworkInfo) => {
		const { viewState } = getCurrentContext();
		const className = getIconClassName();

		return <div className="view">
			<header>
				{ viewState.syncInProgress ? <Spinner /> : null }
				<div className="title"><span className={ className }></span> { getPromptTitle() }</div>
			</header>
			<div className="content scrollable">
				<div className="inner">
					<p>{ getPromptMessage() }</p>
					<div className="actions">
						{ getPromptButtons() }
					</div>
				</div>
			</div>
		</div>;
	}}</NetworkContext.Consumer>;
};

interface Props {
}

interface State {
}

export class ConnectionStatus extends React.Component<Props, State> {
	constructor(props) {
		super(props);

		this.showStatus = this.showStatus.bind(this);
	}

	public showStatus(e) {
		viewManager.pushModal({
			component: ConnectionStatusPrompt,
			props: { classNames: ['prompt'] },
			screenName: 'ConnectionStatus',
		});
	}

	public render() {
		const { viewState } = getCurrentContext();

		return <NetworkContext.Consumer>{(info: store.INetworkInfo) => {
			const className = getIconClassName();

			return <a className="button" onClick={ this.showStatus }>{ viewState.syncInProgress ? <Spinner /> : null }<span className={ className }></span></a>;
		}}</NetworkContext.Consumer>;

	}
}

function openBilling(e) {
	const { currentTeam } = getCurrentContext();

	viewManager.popModal();

	viewManager.pushModal({
		component: BillingModal,
		props: () => ({ appState: store.appState(), teamId: currentTeam.id }),
		screenName: 'Billing',
	});
}

function getIconClassName() {
	const { currentSubscription, currentTeam, currentUser, viewState } = getCurrentContext();
	const connected = viewState.api.canAccessApi;

	if (currentUser && (!connected || !currentTeam || !currentSubscription || !currentSubscription.isActive())) {
		return 'icon cloud disconnected';
	} else if (!connected) {
		return 'icon cloud disconnected';
	}

	return 'icon cloud';
}

function getPromptTitle() {
	const { currentTeam, currentSubscription , currentUser, teamPermissions, model, platform, playbookPermissions, viewState } = getCurrentContext();
	const canAccessApi = viewState.api.canAccessApi;
	const isContributor = (playbookPermissions && playbookPermissions.canUpdate) || (teamPermissions && teamPermissions.canUpdate);

	if (currentSubscription && currentSubscription.isActive() && isContributor && !canAccessApi) {
		const unsynced = viewState.unsyncedEntities;
		const unsyncedPlaybooks = {};
		const unsyncedEntities = map(unsynced, (value: store.IUnsyncedEntity) => {
			const storeKey = `${value.entityType}s.${value.entityId}`;
			const unsyncedValue = !value.wasDeleted ? get(model, storeKey) : undefined;

			return { storeKey, unsyncedValue, entityType: value.entityType };
		});

		forEach(unsyncedEntities, (value: store.IUnsyncedEntity) => {
			if (['play', 'formation'].indexOf(value.entityType) !== -1) {

				const playbookId = value.unsyncedValue.playbookId;
				let unsyncedPlaybook = unsyncedPlaybooks[playbookId];

				if (!unsyncedPlaybook) {
					unsyncedPlaybook = unsyncedPlaybooks[playbookId] = { plays: 0, formations: 0 };
				}

				if (value.entityType === 'play') {
					unsyncedPlaybook.plays += 1;
				} else {
					unsyncedPlaybook.formations += 1;
				}
			}
		});

		const changedPlaybooks = Object.keys(unsyncedPlaybooks).length;

		return changedPlaybooks === 1 ? _s(StringKey.CONNECTION_INFO_UNSYNCED_PLAYBOOK) : _s(StringKey.CONNECTION_INFO_UNSYNCED_PLAYBOOKS).replace('{count}', changedPlaybooks.toString());
	}

	return null;
}

function getPromptMessage() {
	const { currentTeam, currentSubscription , currentUser, teamPermissions, platform, playbookPermissions, viewState } = getCurrentContext();
	const canAccessApi = viewState.api.canAccessApi;
	const isContributor = (playbookPermissions && playbookPermissions.canUpdate) || (teamPermissions && teamPermissions.canUpdate);

	if (!currentUser) {
		return !canAccessApi ? _s(StringKey.CONNECTION_INFO_DISCONNECTED_ONBOARDING) :  _s(StringKey.CONNECTION_INFO_CONNECTED_ONBOARDING);
	}

	if (!currentTeam || !currentSubscription) {
		return _s(StringKey.CONNECTION_INFO_NO_TEAMS);
	}

	if (!currentSubscription.isActive()) {
		const expirationDate = (currentSubscription.endDate || currentSubscription.expirationDate).toLocaleDateString();
		let template;

		if (!teamPermissions || !teamPermissions.canManageBilling) {
			return _s(StringKey.ACCESS_DENIED_TEAM_DESCRIPTION);
		} else if (currentSubscription.isFreeTrial()) {
			template = _s(StringKey.CONNECTION_INFO_UPGRADE_PROMPT);
		} else if (currentSubscription.nonRenewable) {
			template = _s(StringKey.CONNECTION_INFO_SELECT_PROMPT);
		} else {
			template = _s(StringKey.CONNECTION_INFO_RENEWAL_PROMPT);
		}

		return template.replace('{expiration_date}', expirationDate);
	}

	if (viewState.syncInProgress) {
		return _s(StringKey.LOADER_MESSAGE_SYNC);
	} else if (canAccessApi) {
		return isContributor ? _s(StringKey.CONNECTION_INFO_CONNECTED_CONTRIBUTOR) : _s(StringKey.CONNECTION_INFO_CONNECTED_VIEWER);
	} else {
		return isContributor ? _s(StringKey.CONNECTION_INFO_DISCONNECTED_CONTRIBUTOR) : _s(StringKey.CONNECTION_INFO_DISCONNECTED_VIEWER);
	}
}

function getPromptButtons() {
	const { currentSubscription, currentTeam, currentUser, playbookPermissions, teamPermissions, viewState } = getCurrentContext();
	const canAccessApi = viewState.api.canAccessApi;
	const isContributor = (playbookPermissions && playbookPermissions.canUpdate) || (teamPermissions && teamPermissions.canUpdate);
	const okButton = <a key="ok" className="button basic" onClick={ viewManager.popModal }><span>{ _s(StringKey.OK) }</span></a>;

	if (!currentUser || !currentTeam) {
		return [okButton];
	}

	if (!currentSubscription || !currentSubscription.isActive()) {
		const buttons = [];

		if (!teamPermissions || !teamPermissions.canManageBilling) {
			buttons.push(okButton);
		} else {
			buttons.push(<a key="cancel" className="button basic cancel" onClick={ viewManager.popModal }><span>{ _s(StringKey.NOT_NOW) }</span></a>);
			buttons.push(<a key="renew" className="button basic" onClick={ openBilling }><span>{ _s(StringKey.RENEW) }</span></a>);
		}

		return buttons;
	}

	return [okButton];
}
